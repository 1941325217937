import React from "react";
import "./index.less";

interface IProps extends React.HTMLAttributes<HTMLElement> {
  total: number;
  active?: number;
}

const StepsBizi = (props: IProps) => {
  const { total, active } = props;

  const getLine = (active?: boolean) => (
    <div className={`bizi-steps-line ${active ? "active" : ""}`}>
      <div className={`bizi-steps-line_inside ${active ? "active" : ""}`} />
    </div>
  );
  const getCilrcle = (active?: boolean) => (
    <div className={`bizi-steps-circle ${active ? "active" : ""}`} />
  );
  const getStep = (active?: boolean) => (
    <div
      key={Math.random()}
      className={"bizi-steps-step"}
      style={{ width: `${100 / total}%` }}
    >
      {getLine(active)}
      {getCilrcle(active)}
    </div>
  );

  return (
    <div className={"bizi-steps"}>
      {getCilrcle(true)}
      {Array(total)
        .fill("")
        .map((el, index) => {
          return getStep((active || 0) > index);
        })}
    </div>
  );
};

export default StepsBizi;
