import React from "react";
import "./index.less";
import { withRouter, RouteComponentProps, useHistory } from "react-router-dom";
import { LayoutProps } from "antd/lib/layout";
import formLogo from "./../../assets/bizi_lady.svg";
import { Header } from "antd/lib/layout/layout";
import logo from "../../assets/logo.svg";
import LingualBlock from "components/Layout/LingualBlock";
import Cookies from "js-cookie";
import keycloak from "helpers/keycloak";
import { bizIcons } from "constants/bizConstants";
import { useTranslation } from "react-i18next";
import { setConfigAxios } from "helpers/requestApi";

interface ILayoutPageProps extends LayoutProps {
  formLogo?: any;
  disableLogoClick?: boolean;
}

const LayoutPage = (props: ILayoutPageProps) => {
  const { children, disableLogoClick } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const _formLogo = props.formLogo || formLogo;

  const handleLogout = () => {
    Cookies.set("react-token", "undefined");
    setConfigAxios();
    keycloak.logout().then(() => {
      keycloak.login();
    });
  };

  const handleClickLogo = () => {
    if (disableLogoClick) return;
    history.push("/kidzbiz");
  };

  return (
    <>
      <Header className="site-layout-sub-header-background layout-header">
        <div className={`left-side`}>
          <img
            src={logo}
            alt={"logo-header"}
            className={disableLogoClick ? "" : "cursor-pointer"}
            onClick={handleClickLogo}
          />
        </div>

        <div className={`right-side`}>
          {/* <LingualBlock className={"ml-16"} /> */}
          <div onClick={handleLogout} className="flex-align-items">
            <i
              className={`${bizIcons.LOGOUT}`}
              style={{ fontSize: 18, marginRight: 8 }}
            />
            {t("bizBase.logout", { defaultValue: "Logout" })}
          </div>
        </div>
      </Header>
      <div className="layout_form">
        <div className="lf-background">
          <div className="lf-logo">
            {<img src={_formLogo} alt="form-logo" />}
          </div>
        </div>
        <div className="lf-form">{children}</div>
      </div>
    </>
  );
};

export const LayoutForm = (props: ILayoutPageProps) => {
  return (
    <LayoutPage {...props} className="layout">
      {props.children}
    </LayoutPage>
  );
};
