import {
  Button,
  Divider,
  Form,
  Input,
  message,
  notification,
  Radio,
  Select,
  Space,
} from "antd";
import { State, City, Country } from "country-state-city";
import { ErrorText } from "components/ErrorText";
import ImageUploader from "components/ImageUploader";
import { COUNTRY_DATA } from "components/PhoneCode";
import keycloak from "helpers/keycloak";
import { setConfigAxios } from "helpers/requestApi";
import Cookies from "js-cookie";
import { inject, observer } from "mobx-react";
import { FieldData } from "rc-field-form/lib/interface";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useCurrentUser } from "scripts/custom-hooks/useCurrentUser";
import { getLanguage } from "scripts/custom-hooks/useLanguage";
import helpers from "scripts/helpers";
import { Languages } from "scripts/mobx/languageStore";
import { UserStore } from "scripts/mobx/userStore";
import StepsBizi from "../../../components/StepsBizi";
import { CITY_DATA, SINGAPORE_CITY_DATA } from "../interface";
import { COUNTRY_CODE_LOCAL_STORAGE } from "constants/commons";
import { getCommonConfiguration, IConfiguration } from "scripts/apis/settings";
const { Option } = Select;

interface IPropsWrapper extends RouteComponentProps {
  nextStep: () => void;
}

const fieldsRequired = {
  organizationLegalName: true,
};

interface IStoreProps {
  userStore?: UserStore;
  nextStep: () => void;
  history: any;
}
const Step3Form = inject("userStore")(
  observer((props: IStoreProps) => {
    const { t } = useTranslation("base");
    const lang = getLanguage();
    const user = useCurrentUser();

    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [errorText, setErrorText] = useState<string>();
    const [imageUrl, setImageUrl] = useState<any>();
    const [imageOrganization, setImageOrganization] = useState<any>();
    const [accountInvities, setAccountInvities] = useState<boolean>(true);
    const [followSignUp, setFollowSignUp] = React.useState(1);
    const [tradeName, setTradeName] = React.useState(
      user?.accountInvites?.[0]?.invitationId,
    );

    useEffect(() => {
      keycloak
        .updateToken(-1)
        .success(() => {
          setConfigAxios(keycloak.token);
          Cookies.set("react-token", keycloak.token);
        })
        .error((error: any) => {
          console.log("error", error);
        });
    }, []);

    const [form] = Form.useForm();
    const { nextStep, userStore } = props;

    // const [supportedSubdirectories, setSupportedSubdirectories] = useState<
    //   Array<IConfiguration>
    // >([]);
    // useEffect(() => {
    //   const loadSupportedSubdirectories = async () => {
    //     try {
    //       const data = await getCommonConfiguration("SUBDIRECTORY");
    //       setSupportedSubdirectories(data);
    //     } catch (error) {
    //       // TODO:
    //     }
    //   };

    //   loadSupportedSubdirectories();
    // }, []);

    const country = useMemo(() => {
      const countryCode =
        localStorage.getItem(COUNTRY_CODE_LOCAL_STORAGE) || "HK";
      const selectedCountry = Country.getCountryByCode(countryCode);
      return selectedCountry;
    }, []);

    // interpolate states by country
    const states = useMemo(() => {
      if (!country) return [];
      if (country.isoCode === "HK")
        return CITY_DATA.map((item) => ({
          name: item.value,
          value: item.value,
        }));

      if (country.isoCode === "SG") return SINGAPORE_CITY_DATA;
      return State.getStatesOfCountry(country.isoCode).map((item) => ({
        name: item.name,
        value: item.isoCode,
      }));
    }, [country]);

    // set default state/area according to phonecode
    useEffect(() => {
      if (states.length) {
        form.setFieldsValue({
          area: states[0].value,
        });
        setSelectedStateCode(states[0].value);
      }
    }, [country, states]);

    const [selectedStateCode, setSelectedStateCode] = useState<string>();

    const handleStateChanged = (value: string) => setSelectedStateCode(value);

    // interpolate cities by state
    const cities = useMemo(() => {
      if (!country) return [];
      if (country.isoCode === "HK")
        return (
          CITY_DATA.find((el) => el.value === selectedStateCode)?.suburbs ?? []
        );

      if (country.isoCode === "SG") return SINGAPORE_CITY_DATA[0].suburbs;

      if (!selectedStateCode) return [];
      return City.getCitiesOfState(country.isoCode, selectedStateCode).map(
        (item) => ({ value: item.name }),
      );
    }, [country, selectedStateCode]);

    // set default suburb after changing state
    useEffect(() => {
      if (cities.length && selectedStateCode) {
        form.setFieldsValue({
          suburb: cities[0].value,
        });
      }
    }, [selectedStateCode, cities]);

    const onFinish = async (values: any) => {
      setIsLoading(true);
      try {
        await form.validateFields(Object.keys(values));
        const formData = new FormData();
        formData.append("legalName", values.legalName || "");
        if (followSignUp === 1 && user?.accountInvites) {
          if (user?.accountInvites?.length > 1) {
            formData.append("invitationId", values.flTradeName || "");
          } else {
            formData.append("invitationId", tradeName || "");
          }
        } else {
          formData.append("tradeName", values.tradeName || "");
        }
        formData.append("country", country?.name ?? "Hong Kong");

        if (values.area) {
          formData.append("area", values.area);
        }
        if (values.suburb) {
          formData.append("suburb", values.suburb);
        }
        if (imageOrganization) {
          formData.append("logoFile", imageOrganization);
        }

        // interpolate subdirectory(Singapore, Hong Kong, Other) from phone number
        let subdirectory = "Other";

        if ((user?.userAttributes?.mobile || "").includes("+852"))
          subdirectory = "Hong Kong";
        if ((user?.userAttributes?.mobile || "").includes("+65"))
          subdirectory = "Singapore";

        formData.append("subdirectory", subdirectory);

        if (followSignUp === 1 && user?.accountInvites) {
          userStore
            ?.createSatellite(formData)
            .then(() => {
              setErrorText("");
              nextStep();
            })
            .catch((error) => {
              helpers.throwErrors(
                t,
                [
                  "Forbidden",
                  "com.bizibuz.core.service.user.UserExistsException",
                  "com.bizibuz.core.service.provider.LegalNameExistsException",
                  "com.bizibuz.core.service.provider.UserNotFoundException",
                  "com.bizibuz.core.service.provider.BusinessUnitNotFoundException",
                  "com.bizibuz.core.service.provider.ProviderNotFoundException",
                  "com.bizibuz.core.service.user.EmailInUsedException",
                ],
                error?.data?.error,
                "",
              );
            });
        } else {
          userStore
            ?.createMaster(formData)
            .then(() => {
              setErrorText("");
              nextStep();
            })
            .catch((error) => {
              helpers.throwErrors(
                t,
                [
                  "Forbidden",
                  "com.bizibuz.core.service.user.UserExistsException",
                  "com.bizibuz.core.service.provider.LegalNameExistsException",
                  "com.bizibuz.core.service.provider.UserNotFoundException",
                  "com.bizibuz.core.service.provider.BusinessUnitNotFoundException",
                  "com.bizibuz.core.service.provider.ProviderNotFoundException",
                  "com.bizibuz.core.service.user.EmailInUsedException",
                ],
                error?.data?.error,
                "",
              );
            });
        }
        setIsLoading(false);
      } catch {
        setIsLoading(false);
      }
    };

    const onErrorsCheck = (fields: FieldData[], allfields: FieldData[]) => {
      const hasErrors = allfields.some(
        (field: FieldData) =>
          (field.errors && field.errors?.length > 0) ||
          (fieldsRequired[field.name[0]] && field.value === undefined),
      );

      setIsDisabled(hasErrors);
    };

    const onUploadChanged = (file: File) => {
      setImageOrganization(file);
      helpers.getBase64(file).then((res) => {
        setImageUrl(res);
        form.setFieldsValue({
          logoUrl: res,
        });
      });
    };

    const onCheckBeforeUpload = (messages: string[]) => {
      messages.map((m) => message.error(m));
    };

    const onChange = (value: any) => {
      setFollowSignUp(value?.target?.value);
      if (value?.target?.value === 1) {
        setAccountInvities(true);
      } else {
        setAccountInvities(false);
      }
    };

    const onChangeTradeName = (value: any) => {
      setTradeName(value?.target?.value);
    };

    const areaLabel =
      country?.isoCode === "HK"
        ? t("signUp.step2.enterArea", {
            defaultValue: "Enter Area",
          })
        : t("signUp.step2.enterState", {
            defaultValue: "Enter State",
          });

    return (
      <Form
        form={form}
        name="normal_login"
        className="login-form tsl-4 signup-step3"
        initialValues={{
          legalName: "",
          flTradeName: user?.accountInvites?.[0]?.invitationId,
          tradeName: "",
          orgImage: "",
          city: "Hong Kong",
          area: undefined,
          suburb: undefined,
          subdirectory: "Hong Kong",
        }}
        onFieldsChange={onErrorsCheck}
        onFinish={onFinish}
        encType="multipart/form-data"
        method="post"
      >
        <div className="formtext-wrapper tsl-4 bsl-4">
          {t("signUp.step3.desc", {
            defaultValue: "Add info about your Kidzbiz.",
          })}
        </div>
        {user?.accountInvites && (
          <div>
            <Radio.Group onChange={onChange} value={followSignUp}>
              <Space direction="vertical">
                <Radio value={1}>
                  {t("signUp.step3.accountInvites_radio1", {
                    defaultValue: "Join the following trade name",
                  })}
                </Radio>
                <Radio value={2}>
                  {t("signUp.step3.accountInvites_radio2", {
                    defaultValue: "Setup a new trade name",
                  })}
                </Radio>
              </Space>
            </Radio.Group>
            <Divider />
          </div>
        )}
        <>
          <Form.Item className="bsl-4">
            <div className="label-wrapper">
              <label htmlFor="legalName">
                {t("signUp.step4.legalName", {
                  defaultValue: "Legal name",
                })}
                *
              </label>
            </div>
            <Form.Item
              name="legalName"
              rules={[
                {
                  required: true,
                  message: t("messages.required_field", {
                    defaultValue: "Please fill in all required fields",
                  }),
                },
              ]}
            >
              <Input.TextArea
                id={"legalName"}
                maxLength={45}
                placeholder={t("signUp.step4.enterLegalName", {
                  defaultValue: "Enter legal name",
                })}
                autoComplete="off"
              />
            </Form.Item>
          </Form.Item>

          <div className="bsl-4">
            <div className="label-wrapper tsl-4">
              <label htmlFor="tradeName">
                {t("signUp.step3.tradeName", {
                  defaultValue: "Trade name",
                })}
                *
              </label>
            </div>

            {accountInvities && user?.accountInvites ? (
              <>
                {user?.accountInvites?.length > 1 ? (
                  <Form.Item
                    name="flTradeName"
                    rules={[
                      {
                        required: true,
                        message: t("messages.required_field", {
                          defaultValue: "Please fill in all required fields",
                        }),
                      },
                    ]}
                  >
                    <Select>
                      {user?.accountInvites?.map((item: any) => {
                        return (
                          <Option
                            key={item?.invitationId}
                            value={item?.invitationId}
                          >
                            {item?.tradeName}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                ) : (
                  <Input
                    placeholder={user?.accountInvites?.[0]?.tradeName}
                    disabled
                  />
                )}
              </>
            ) : (
              <Form.Item
                name="tradeName"
                rules={[
                  {
                    required: true,
                    message: t("messages.required_field", {
                      defaultValue: "Please fill in all required fields",
                    }),
                  },
                ]}
              >
                <Input.TextArea
                  id={"tradeName"}
                  placeholder={t("signUp.step3.enterTradeName", {
                    defaultValue: "Enter trade name",
                  })}
                  autoSize={{ minRows: 2, maxRows: 3 }}
                  maxLength={60}
                  autoComplete="off"
                />
              </Form.Item>
            )}
          </div>
          <Form.Item
            help={t("signUp.step4.markerLogo", {
              defaultValue: "Jpeg, jpg, png. Min 1240px width. Max 1 MB.",
            })}
            className={"bsl-4"}
            rules={[
              {
                required: true,
                message: t("messages.required_field", {
                  defaultValue: "Please fill in all required fields",
                }),
              },
            ]}
            name="logoUrl"
          >
            <div className="label-wrapper tsl-4">
              <label htmlFor="orgImage">
                {t("signUp.step4.logo", {
                  defaultValue: "Logo",
                })}
                *
              </label>
            </div>

            <ImageUploader
              name="image"
              imageUrl={imageUrl}
              onCheckBeforeUpload={onCheckBeforeUpload}
              onUploadChanged={onUploadChanged}
            />
          </Form.Item>

          <Form.Item className="bsl-4">
            <div className="label-wrapper">
              <label htmlFor="area">{areaLabel}*</label>
            </div>
            <Form.Item
              name="area"
              rules={[
                {
                  required: true,
                  message: t("messages.required_field", {
                    defaultValue: "Please fill in all required fields",
                  }),
                },
              ]}
            >
              <Select
                placeholder={areaLabel}
                suffixIcon={<i className="ri-arrow-drop-down-line fs-20" />}
                onChange={handleStateChanged}
              >
                {states.map((city) => {
                  return (
                    <Option key={city.name} value={city.value}>
                      {city.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Form.Item>

          {cities.length ? (
            <Form.Item className="bsl-4">
              <div className="label-wrapper">
                <label htmlFor="suburb">
                  {t("signUp.step2.enterSuburb", {
                    defaultValue: "Enter Suburb",
                  })}
                  *
                </label>
              </div>
              <Form.Item
                name="suburb"
                rules={[
                  {
                    required: true,
                    message: t("messages.required_field", {
                      defaultValue: "Please fill in all required fields",
                    }),
                  },
                ]}
              >
                <Select
                  placeholder={t("signUp.step2.enterSuburb", {
                    defaultValue: "Enter suburb",
                  })}
                  suffixIcon={<i className="ri-arrow-drop-down-line fs-20" />}
                  disabled={form.getFieldValue("area") ? false : true}
                >
                  {cities.map((city) => {
                    return (
                      <Option key={city.value} value={city.value}>
                        {city.value}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Form.Item>
          ) : null}

          {/* <Form.Item className="bsl-4">
            <div className="label-wrapper">
              <label htmlFor="subdirectory">Subdirectory*</label>
            </div>
            <Form.Item
              name="subdirectory"
              rules={[
                {
                  required: true,
                  message: t("messages.required_field", {
                    defaultValue: "Please fill in all required fields",
                  }),
                },
              ]}
            >
              <Select
                placeholder="Subdirectory"
                suffixIcon={<i className="ri-arrow-drop-down-line fs-20" />}
              >
                {supportedSubdirectories.map((item) => {
                  return (
                    <Option key={item.value} value={item.value}>
                      {item.value}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Form.Item> */}

          <Form.Item>
            <Button
              className="full-width fs-16 tsl-4"
              type="primary"
              htmlType="submit"
              loading={isLoading}
              disabled={isLoading}
              icon={
                <div className={"flex-center"}>
                  {t("signUp.nextStep", {
                    defaultValue: "Next step",
                  })}
                  <i className="ri-arrow-right-line ml-8 fs-20" />
                </div>
              }
            />
          </Form.Item>
          <ErrorText msg={errorText} />
        </>
        {/* } */}
      </Form>
    );
  }),
);

const Step3FormWrapper = withRouter((props: IPropsWrapper) => {
  const { nextStep, history } = props;
  const { t } = useTranslation("base");

  return (
    <div className="auth">
      <div className="content-wrapper">
        <div className="auth-header bsl-4">
          <div>
            <span className={"auth-header_title"}>
              {t("signUp.step3.title", {
                defaultValue: "Kidzbiz details",
              })}
            </span>
          </div>
        </div>
        <div className="auth-steps bsl-4">
          <StepsBizi total={4} active={2} />
        </div>
        <div className="auth-form">
          <Step3Form nextStep={nextStep} history={history} />
        </div>
      </div>
    </div>
  );
});

export default Step3FormWrapper;
