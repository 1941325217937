import { Button, Modal, Spin, ModalProps, notification } from "antd";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { StripeElementChangeEvent } from "@stripe/stripe-js";
import { CloseCircleOutlined } from "@ant-design/icons";
import {
  deletePaymentMethod,
  getPaymentMethods,
  PaymentMethodInfomation,
  postPaymentMethod,
} from "scripts/apis/payment";
import ButtonLink from "components/ButtonLink";
import { AppRoutes } from "helpers/app.routes";
import { useHistory } from "react-router-dom";
import { ButtonWhite } from "components/ButtonWhite";
import { observer } from "mobx-react-lite";
import { userStoreContext } from "scripts/mobx/userStore";

const stripeOptions = {
  style: {
    base: {
      fontSize: "14px",
      color: "#424770",
      letterSpacing: "0.025em",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#9e2146",
    },
  },
};

interface IStripeFormElements extends HTMLFormControlsCollection {
  name: HTMLInputElement;
}

interface IStripeForm extends HTMLFormElement {
  readonly elements: IStripeFormElements;
}

export interface IAddPaymentModalProps extends ModalProps {}

const AddPaymentModal: React.FC<IAddPaymentModalProps> = (props) => {
  const { t } = useTranslation();

  const stripe = useStripe();
  const elements = useElements();

  const userStore = useContext(userStoreContext);

  const paymentMethods = userStore.paymentMethods;

  const history = useHistory();

  const nameInputRef = useRef<HTMLInputElement>(null);

  const [cardId, setCardId] = useState<string>();

  const [cardNumberCheck, setCardNumberCheck] = useState<boolean>(true);
  const [cardExpiryCheck, setCardExpiryCheck] = useState<boolean>(true);
  const [cardCvcCheck, setCardCvcCheck] = useState<boolean>(true);

  const [loading, setLoading] = useState<boolean>(false);

  const loadPaymentMethods = useCallback(async () => {
    try {
      setLoading(true);
      await userStore.getPaymentMethods();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, []);

  const onChange = (element: StripeElementChangeEvent) => {
    if (element?.elementType === "cardNumber") {
      setCardNumberCheck(!element?.complete);
    }
    if (element?.elementType === "cardExpiry") {
      setCardExpiryCheck(!element?.complete);
    }
    if (element?.elementType === "cardCvc") {
      setCardCvcCheck(!element?.complete);
    }
  };

  const handleSubmit = async (e: React.FormEvent<IStripeForm>) => {
    e.preventDefault();

    if (!stripe) return;
    const cardElement = elements?.getElement(CardNumberElement);
    const cardExpiryElement = elements?.getElement(CardExpiryElement);
    const cardCVCElement = elements?.getElement(CardCvcElement);

    if (!cardElement) return;

    const target = e.currentTarget.elements;
    if (!target?.name?.value) return;

    try {
      setLoading(true);

      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: {
          name: target.name.value,
        },
      });

      if (error) {
        notification.error({
          message: error?.message ?? t("server_errors." + "Bad Request"),
        });
      }

      if (paymentMethod) {
        await postPaymentMethod(
          paymentMethod.id,
          userStore.isSGProvider ? "Singapore" : "Hong Kong",
        );
        loadPaymentMethods();

        notification.success({
          message: t("payments.add_payment_successful", {
            defaultValue: "Your payment method has been added successfully!",
          }),
        });
      }

      // clear input value
      cardElement.clear();
      cardCVCElement?.clear();
      cardExpiryElement?.clear();
      if (nameInputRef.current) nameInputRef.current.value = "";
    } catch (error: any) {
      notification.error({
        message: error?.data?.message ?? t("server_errors." + "Bad Request"),
      });
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveCard = (id: string) => async () => {
    try {
      setLoading(true);

      await deletePaymentMethod(id);

      await loadPaymentMethods();
    } catch (error: any) {
      notification.error({
        message: error?.data?.message ?? t("server_errors." + "Bad Request"),
      });
    } finally {
      setLoading(false);
    }
  };

  const handleOpenPricingFAQs = () => {
    history.push(AppRoutes.faqDetail("pricing1"));
  };

  return (
    <Modal
      centered
      title=""
      closable={false}
      footer={false}
      wrapClassName="biz-payment"
      {...props}
    >
      <div>
        <h3>
          {t("payment.paymentMethods", { defaultValue: "Payment method" })}
        </h3>

        <div
          style={{
            position: "absolute",
            top: -8,
            right: -6,
          }}
          role="button"
          onClick={props.onCancel}
        >
          <CloseCircleOutlined
            style={{
              color: "rgba(34, 34, 34, 0.55)",
              backgroundColor: "white",
              borderRadius: 8,
              fontSize: 16,
            }}
          />
        </div>

        <Spin spinning={loading}>
          {Boolean(paymentMethods.length) ? (
            <>
              {paymentMethods.map((item) => (
                <div key={item.id} className="tab_header">
                  <Button
                    type={item.id === cardId ? "primary" : "default"}
                    onClick={() => setCardId(item.id)}
                    className="card-item-info"
                    style={{ position: "relative" }}
                  >
                    <h4>{item?.cardBrand}</h4>
                    <div>
                      <p>{"**** **** **** " + item?.lastDigits}</p>
                      <span>
                        {item?.expMonth}/{item?.expYear}
                      </span>
                    </div>

                    {/* <div
                      style={{
                        position: "absolute",
                        top: -8,
                        right: -6,
                      }}
                      role="button"
                      onClick={handleRemoveCard(item.id)}
                    >
                      <CloseCircleOutlined
                        style={{
                          color: "rgba(34, 34, 34, 0.55)",
                          backgroundColor: "white",
                          borderRadius: 8,
                        }}
                      />
                    </div> */}
                  </Button>
                </div>
              ))}

              <ButtonLink onClick={handleOpenPricingFAQs} className="bsl-4">
                {t("courses.clickToGoToPricingFAQs", {
                  defaultValue: "Click to go to pricing FAQs",
                })}
              </ButtonLink>

              <div className="flex-align-items tsl-4">
                <ButtonWhite
                  className="mr-16"
                  style={{
                    width: "auto",
                    minWidth: "auto",
                    paddingLeft: 20,
                    paddingRight: 20,
                  }}
                  type="primary"
                  onClick={props?.onCancel}
                >
                  <div className={"flex-center"}>{t("bizBase.cancel")}</div>
                </ButtonWhite>
                <Button
                  className="ant-btn-pay full-width"
                  onClick={handleRemoveCard(paymentMethods?.[0]?.id)}
                >
                  <i className="ri-delete-bin-line mr-8" />
                  {t("payment.removeCard", {
                    defaultValue: "Remove card",
                  })}
                </Button>
              </div>
            </>
          ) : (
            <form onSubmit={handleSubmit} className="form">
              <label>
                {t("payment.cardInformation", {
                  defaultValue: "Card Information",
                })}
              </label>
              <div className="card-item card-number">
                <div className="card_input">
                  <CardNumberElement
                    options={stripeOptions}
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className="card-item w-50 card-expiry">
                <div className="card_input">
                  <CardExpiryElement
                    options={stripeOptions}
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className="card-item w-50 card-cvc">
                <div className="card_input">
                  <CardCvcElement options={stripeOptions} onChange={onChange} />
                </div>
              </div>

              <div className="card-item">
                <label>
                  {t("payment.nameOnCard", {
                    defaultValue: "Name on card",
                  })}
                  <div className="card_input_name">
                    <input name="name" ref={nameInputRef} />
                  </div>
                </label>
              </div>
              <p className="payment_disclaimer">
                {t("payment.payment_disclaimer", {
                  defaultValue:
                    "Your credit card information is handled securely and directly by Stripe",
                })}
              </p>

              <ButtonLink onClick={handleOpenPricingFAQs} className="bsl-4">
                {t("courses.clickToGoToPricingFAQs", {
                  defaultValue: "Click to go to pricing FAQs",
                })}
              </ButtonLink>

              <div className="flex-align-items tsl-4">
                <ButtonWhite
                  className="mr-16"
                  style={{
                    width: "auto",
                    minWidth: "auto",
                    paddingLeft: 20,
                    paddingRight: 20,
                  }}
                  type="primary"
                  onClick={props?.onCancel}
                >
                  <div className={"flex-center"}>{t("bizBase.cancel")}</div>
                </ButtonWhite>
                <Button
                  htmlType="submit"
                  className="ant-btn-pay full-width"
                  disabled={Boolean(
                    cardNumberCheck || cardExpiryCheck || cardCvcCheck,
                  )}
                >
                  <i className="ri-shopping-bag-fill mr-8" />
                  {t("payment.addPaymentMethod", {
                    defaultValue: "Add payment method",
                  })}
                </Button>
              </div>
            </form>
          )}
        </Spin>
      </div>
    </Modal>
  );
};

export default observer(AddPaymentModal);
