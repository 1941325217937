import { createContext } from "react";
import { action, computed, makeObservable, observable } from "mobx";
import requestApi from "helpers/requestApi";
import { DETAIL_API_URL } from "constants/apiUrl";

export interface LocationType {
  id?: number;
  name: string;
  address: any;
  email: string;
  mobile: string;
  deleted: boolean;
}

export class LocationsStore {
  constructor() {
    makeObservable(this);
  }

  @observable
  public locationsData: any = [];

  @observable
  public errorHandler: string = "";

  @action
  public async getLocation() {
    const result = await requestApi.get(DETAIL_API_URL.USERS_GET_LOCATION());
    if (result?.status === 200 || !!result?.data) {
      const data = result?.data;
      this.locationsData = data;
      return Promise.resolve(data);
    } else {
      this.errorHandler = result?.data?.message;
      return Promise.reject();
    }
  }

  public async putLocation(param: any) {
    const result = await requestApi.put(
      DETAIL_API_URL.USERS_GET_LOCATION(),
      param,
    );
    if (result) {
      this.getLocation();
    } else {
      this.locationsData = { ...this.locationsData };
    }
  }

  @computed
  public get getLocationData() {
    return this.locationsData;
  }
}

const locationsStore = new LocationsStore();

export const locationStoreContext = createContext(locationsStore);
export default locationsStore;
