export enum ResponseStatusCode {
  SUCCESS = 200,
  ERRROR = 400,
  UNAUTHORIZATED = 401,
  SERVER = 500,
}

export const UPLOAD_ALLOW_TYPES_OF_IMAGE = ["jpeg", "jpg", "png"];
export const UPLOAD_ALLOW_TYPES_IMPORT = ["csv", "xls", "xlsx"]; //"xls", "csv"

export const IMAGE_LIMIT_SIZE = 1024 * 1024; //1Mb
export const FILE_LIMIT_SIZE = 1024 * 1024 * 5; //5MB

export const FORMAT_DATE = {
  query: "YYYY-MM-DD",
  times: "HH:mm:ss",
  time: "HH:mm",
  date: "DD/MM/YYYY",
  dateDot: "DD.MM.YY",
  full: "HH:mm DD.MM.YYYY",
  datetime: "HH:mm:ss DD.MM.YYYY",
  queryFull: "YYYY-MM-DD HH:mm:ss",
  common: "DD-MMM-YY",
  commonDateTime: "HH:mm:ss DD-MMM-YY",
};

export const COUNTRY_CODE_LOCAL_STORAGE = "countryCode";
