import { notification } from "antd";
import { FORMAT_DATE } from "constants/commons";
import * as FileSaver from "file-saver";
import { keys, uniq } from "lodash";
import moment from "moment-timezone";
import * as XLSX from "xlsx";

export default {
  b64toBlob: (b64Data: string, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  },

  getBase64: (img: any) => {
    return new Promise(
      (
        resolve: (res: string | ArrayBuffer | null) => void,
        reject: () => void,
      ) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => resolve(reader.result));
        reader.addEventListener("error", () => reject());
        reader.readAsDataURL(img);
      },
    );
  },

  getPhoneWithChCode: (num: number | string) => {
    if (!num || num.toString().length < 8) {
      return;
    }
    return "+852" + num?.toString().slice(-8);
  },

  getNumberWithSpaces: (num: number | string | undefined) => {
    if (num === 0 || num === undefined || typeof Number(num) !== "number") {
      return 0;
    }
    let _roundedNum: number | string = Math.ceil(Number(num) * 10) / 10;

    _roundedNum = _roundedNum.toFixed(1);
    const decArr = String(_roundedNum).split(".");

    return (
      Number(decArr[0]).toLocaleString("en-EN") +
      (decArr[1] ? "." + decArr[1] : "")
    );
  },

  getFileType: (filename: string) => {
    const type =
      filename.substring(filename.lastIndexOf(".") + 1, filename.length) ||
      filename;
    return type.toLocaleLowerCase();
  },

  formatNumber: (number: number, sperator?: string) => {
    return number
      ? number
          ?.toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${sperator || ","}`)
      : 0;
  },

  userStatus: (t?: any, status?: string) => {
    if (status === "BLOCKED") {
      return t("filters.blocked");
    } else if (status === "VERIFIED") {
      return t("filters.verified");
    } else if (status === "REJECTED") {
      return t("filters.rejected");
    } else if (status === "UNVERIFIED_STRIPE") {
      return t("filters.pendingStripe");
    } else if (status === "UNVERIFIED_BIZIBUZ") {
      return t("filters.pendingVerification");
    }
    return "";
  },

  getArrayDates: (data: string[]) => {
    const dates = [...data]?.map((el) => moment(el).format(FORMAT_DATE.query));
    const unipDates = uniq(dates);
    const newDates = unipDates?.map((el: string) => {
      const newDate = new Date(moment(el, FORMAT_DATE.query)?.format());
      return newDate;
    });
    return newDates;
  },

  getDurationTimes: (startTime: string, endTime: string) => {
    const start = startTime?.split(":");
    const end = endTime?.split(":");
    const hours = (Number(end?.[0]) - Number(start?.[0])) * 60;
    const minutes = Number(end?.[1]) - Number(start?.[1]);
    return hours + minutes;
  },

  getServerDate: (date: any[]) => {
    if (!date) return undefined;
    if (!date?.[0]) return undefined;
    const dates = date?.map((d: number) => {
      return d >= 10 ? d : "0" + d;
    });
    return dates?.join("-");
  },

  throwErrors: (
    t: any,
    errorType: string[],
    key: string,
    messageText?: string,
  ) => {
    if (errorType.includes(key)) {
      notification.error({
        message: t("server_errors." + key),
      });
    } else {
      notification.error({
        message: messageText || t("server_errors." + "Bad Request"),
      });
    }
    return true;
  },
  exportToCSV: (csvData: any, fileName: string, extension?: string) => {
    let fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    if (extension) {
      fileType = "data:application/octet-stream,text/csv;encoding:utf-8";
    }
    const fileExtension = extension || ".xlsx";
    // const header: any[] = Object.values(csvData[0])?.map((el, index) => {
    //   return el || Object.keys(csvData[0])[index];
    // });
    // console.log(header);

    // const wscols: any[] = [];
    // for (let i: number = 0; i < header.length; i++) {
    //   // columns length added
    //   wscols.push({ wch: header[i].length + 5 || 25 });
    // }
    const ws = XLSX.utils.json_to_sheet(csvData);
    // ws["!cols"] = wscols;

    ws["!cols"] = Array(30)
      .fill(0)
      ?.map(() => {
        return {
          wch: 25,
        };
      });

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  },
  exportCourseCustomers: (dataCourse: any, csvData: any, fileName: string) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const workbook = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet([{}], { skipHeader: false });
    XLSX.utils.sheet_add_json(
      ws,
      [
        {
          [`${dataCourse?.labelName}`]: "",
        },
        { [`${dataCourse?.name}`]: "" },
      ],
      {
        skipHeader: false,
        origin: "A1",
      },
    );
    XLSX.utils.sheet_add_json(
      ws,
      [
        {
          [`${dataCourse?.labelCode}`]: "",
        },
        { [`${dataCourse?.code}`]: "" },
      ],
      {
        skipHeader: false,
        origin: "A2",
      },
    );
    XLSX.utils.sheet_add_json(
      ws,
      [
        {
          [`${dataCourse?.labelStatus}`]: "",
        },
        { [`${dataCourse?.status}`]: "" },
      ],
      {
        skipHeader: false,
        origin: "A3",
      },
    );

    XLSX.utils.sheet_add_json(ws, csvData, {
      skipHeader: false,
      origin: "A5",
    });

    ws["!cols"] = Array(30)
      .fill(0)
      ?.map(() => {
        return {
          wch: 25,
        };
      });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    XLSX.utils.book_append_sheet(workbook, ws, wb.SheetNames["data"]);
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  },
  formatDate: (date: moment.MomentInput): string => {
    return moment(moment.utc(date).toISOString())
      .tz(moment.tz.guess())
      .format(FORMAT_DATE.common);
  },
  formatDateTime: (date: moment.MomentInput): string => {
    return moment(moment.utc(date).toISOString())
      .tz(moment.tz.guess())
      .format(FORMAT_DATE.commonDateTime);
  },
};

// rm from array by value
// @ts-ignore
Array.prototype.remove = function () {
  const a = arguments;
  let what;
  let L = a.length;

  while (L && this.length) {
    what = a[--L];

    while (this.indexOf(what) !== -1) {
      this.splice(this.indexOf(what), 1);
    }
  }
  return this;
};
