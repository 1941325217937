import React, { useEffect } from "react";
import { Button, Divider, Modal, Typography } from "antd";
import { bizIcons } from "../../../../constants/bizConstants";
import ButtonLink from "../../../../components/ButtonLink";
import { createStatus } from "../index";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { HeadSeo } from "components/HeadSeo";
import {
  useCurrentUser,
  useCurrentVerify,
} from "scripts/custom-hooks/useCurrentUser";
import { ButtonWhite } from "components/ButtonWhite";
import courseStore, { pageable } from "scripts/mobx/courseStore";
import { CourseTab } from "constants/type.interface";
import AddPaymentMethod from "../AddPaymentMethod";

const { Title } = Typography;

interface IProps {
  backToCourseHandler: () => void;
  setStatusHandler: (_status: createStatus) => void;
  goToFAQHandler: () => void;
  isLoading: boolean;
}

export const CreateCourseBaseComponent = (props: IProps) => {
  const { backToCourseHandler, setStatusHandler, goToFAQHandler } = props;
  const { t } = useTranslation();
  const user = useCurrentUser();
  const isVerified = useCurrentVerify();
  const [isConfirm, setConfirm] = React.useState<boolean>(false);

  const togglePaymentMethodsModal = () => {
    setStatusHandler(createStatus.ADD_PAYMENT_METHOD);
  };

  const numOfCourses = user?.transactionSummary?.numOfCourses || 0;
  const numOfDraftCourses = user?.transactionSummary?.numOfDraftCourses || 0;
  const numOfPastCourses = user?.transactionSummary?.numOfPastCourses || 0;
  const count = numOfCourses + numOfDraftCourses + numOfPastCourses;

  return (
    <>
      <HeadSeo
        title={t("courses.createACourse", { defaultValue: "Create a course" })}
      />
      <div className={"create-courses content-with-table"}>
        <div className={"back-block  bsl-4"}>
          <ButtonLink onClick={backToCourseHandler}>
            <i
              style={{ fontSize: "20px", paddingRight: "8px" }}
              className={bizIcons.ARROWLEFT}
            />
            {t("courses.backToCourses", {
              defaultValue: "Back to Courses",
            })}
          </ButtonLink>
        </div>
        <div className={"create-courses-links"}>
          <Title style={{ marginBottom: "32px" }} level={1}>
            {t("courses.createACourse", { defaultValue: "Create a course" })}
          </Title>

          <div
            className={"create-courses-single-link no-select"}
            onClick={() => {
              courseStore?.clearDateCreateCourse();
              setStatusHandler(createStatus.MANUALLY);
            }}
          >
            <div className={"flex-space-between"}>
              <div className={"flex-center mr-16"}>
                <div className={"align-self-start"}>
                  <i
                    style={{ fontSize: "20px", paddingRight: "8px" }}
                    className={bizIcons.EDIT2}
                  />
                </div>
                <div style={{ height: "100%" }}>
                  <Title level={3}>
                    {t("courses.createManually", {
                      defaultValue: "Create manually",
                    })}
                  </Title>
                </div>
              </div>
              <div>
                <i
                  style={{ fontSize: "16px" }}
                  className={bizIcons.ARROWRIGHT}
                />
              </div>
            </div>

            <Divider />
          </div>
          {isVerified && (
            <div
              className={"create-courses-single-link no-select"}
              onClick={() => {
                // if (count > 0) {
                setStatusHandler(createStatus.BYCOPY);
                // } else {
                //   setConfirm((prev) => !prev);
                // }
              }}
            >
              <div className={"flex-space-between"}>
                <div className={"flex-center mr-16"}>
                  <div className={"align-self-start"}>
                    <i
                      style={{ fontSize: "20px", paddingRight: "8px" }}
                      className={bizIcons.COPY}
                    />
                  </div>
                  <div style={{ height: "100%" }}>
                    <Title level={3}>
                      {t("courses.copyACourse", {
                        defaultValue: "Copy a course",
                      })}
                    </Title>
                  </div>
                </div>
                <div>
                  <i
                    style={{ fontSize: "16px" }}
                    className={bizIcons.ARROWRIGHT}
                  />
                </div>
              </div>

              <Divider />
            </div>
          )}
          {isVerified && (
            <div
              className={"create-courses-single-link no-select"}
              onClick={() => setStatusHandler(createStatus.BYXLS)}
            >
              <div className={"flex-space-between"}>
                <div className={"flex-center mr-16"}>
                  <div className={"align-self-start"}>
                    <i
                      style={{ fontSize: "20px", paddingRight: "8px" }}
                      className={bizIcons.EXCEL}
                    />
                  </div>
                  <div style={{ height: "100%" }}>
                    <Title level={3}>
                      {t("courses.createFromXLS", {
                        defaultValue: "Create from XLS (bulk)",
                      })}
                    </Title>
                  </div>
                </div>
                <div>
                  <i
                    style={{ fontSize: "16px" }}
                    className={bizIcons.ARROWRIGHT}
                  />
                </div>
              </div>

              <Divider />
            </div>
          )}
        </div>

        <div style={{ marginTop: "32px" }}>
          {/* <div className={"text-color-secondary"}>
          For the bulk upload are you going to include a template here for them
          to be able to download? Should also add links to template in the FAQs.
        </div> */}
        </div>
        <div className={"tsl-4 bsl-4 flex-space-between"}>
          <ButtonLink onClick={goToFAQHandler}>
            {t("courses.clickHereToGoToGAQs", {
              defaultValue: "Click here to go to FAQs",
            })}
          </ButtonLink>

          <ButtonLink onClick={togglePaymentMethodsModal}>
            {t("courses.clickToCreateFreeTrials", {
              defaultValue: "Click to create free trials",
            })}
          </ButtonLink>
        </div>
      </div>

      <Modal
        centered
        title=""
        visible={isConfirm}
        closable={false}
        onCancel={() => {
          setConfirm((prev) => !prev);
        }}
        footer={false}
        wrapClassName="courses-modal-confirm-verified"
      >
        <div className="bsl-4">
          <h3>{t("courses.confirm_verify_copy")}</h3>
        </div>
        <div className="flex-space-between">
          <ButtonWhite
            className="fs-16 tsl-4 flex-1 mr-25"
            onClick={() => {
              setConfirm((prev) => !prev);
            }}
            style={{
              width: "auto",
              minWidth: "auto",
              paddingLeft: 20,
              paddingRight: 20,
            }}
            type="primary"
          >
            <div className={"flex-center"}>{t("bizBase.cancel")}</div>
          </ButtonWhite>
          <Button
            className="fs-16 tsl-4 flex-1"
            type="primary"
            style={{ width: "auto", paddingLeft: 20, paddingRight: 20 }}
            htmlType="button"
            onClick={() => {
              setConfirm((prev) => !prev);
              Modal.destroyAll();
              setStatusHandler(createStatus.MANUALLY);
            }}
            icon={
              <div className={"flex-center"}>
                <>{t("bizBase.ok")}</>
              </div>
            }
          />
        </div>
      </Modal>
    </>
  );
};
