import { AutoComplete, Button, Form, Input, message, notification } from "antd";
import ButtonLink from "components/ButtonLink";
import SaveButtonText from "components/SaveButtonText";
import { bizIcons } from "constants/bizConstants";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Languages, languageStoreContext } from "scripts/mobx/languageStore";
import linkedAccountStore from "scripts/mobx/linkedAccountStore";

interface IAddLinkedKidzbiz extends RouteComponentProps {
  onBack: () => void;
  onAdd: (data?: any) => void;
  providerStore?: any;
}

enum FIELDS {
  EMAIL = "EMAIL",
  KIDZBIZ = "KIDZBIZ",
}

const AddLinkedKidzbiz = inject("languageStore")(
  observer((props: IAddLinkedKidzbiz) => {
    const { history, onBack, onAdd, providerStore } = props;
    const { t } = useTranslation("base");
    const language = useContext(languageStoreContext);

    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [form] = Form.useForm();
    const [activeField, setActiveField] = useState<FIELDS | undefined>();

    useEffect(() => {
      providerStore?.getProvidersList();
    }, []);

    const setActive = useCallback(
      (field?: FIELDS) => {
        setActiveField(field);
      },
      [activeField],
    );

    const onFinish = (values: any) => {
      setIsLoading(true);
      form
        .validateFields(Object.keys(values))
        .then(async () => {
          linkedAccountStore
            .sendInvitation({ email: values?.email })
            .then(() => {
              message.success(
                t("messages.send_an_email_invitation", {
                  defaultValue: "Linked account invitation sent",
                }),
              );
              onAdd();
            })
            .catch((error) => {
              if (
                error?.data?.error ===
                "com.bizibuz.core.service.user.EmailInUsedException"
              ) {
                notification.error({
                  message: t(
                    "server_errors.com.bizibuz.core.service.user.EmailInUsedException",
                    { email: values?.email },
                  ),
                });
              } else {
                notification.error({
                  message:
                    error?.data?.message || t("server_errors." + "Bad Request"),
                });
              }
            });
          setIsLoading(false);
        })
        .catch((e) => {
          const { _message } = e;
          console.log(e);
          message.error(
            _message ||
              t("messages.some_error_occurred", {
                defaultValue: "Some error occurred",
              }),
          );
          setIsLoading(false);
        });
    };

    const validateForm = useCallback(
      (field?: string) => {
        form
          .validateFields(field ? [field] : undefined)
          .then(
            (r) => {
              setIsDisabled(false);
            },
            (rej) => {
              setIsDisabled(true);
            },
          )
          .catch((e) => {
            const { _message } = e;
            message.error(
              _message ||
                t("messages.some_error_occurred", {
                  defaultValue: "Some error occurred",
                }),
            );
          });
      },
      [isDisabled],
    );

    return (
      <div className="content-wrapper content-with-table">
        <ButtonLink onClick={() => onBack()}>
          <i className={`${bizIcons.ARROWLEFT} fs-20 mr-8`} />
          {t("kidzbiz.backToKidzbiz")}
        </ButtonLink>
        <div className="auth-header bsl-4">
          <div>
            <span className={"auth-header_title"}>
              {t("kidzbiz.linkAccount")}
            </span>
          </div>
        </div>
        <div className="auth-form">
          <Form
            form={form}
            onLoad={() => validateForm()}
            name="normal_login"
            className="login-form"
            onFinish={onFinish}
          >
            <Form.Item className="bsl-4">
              <div className="label-wrapper">
                <label htmlFor="email">{t("kidzbiz.saev")}</label>
              </div>
              <Form.Item
                name="email"
                rules={[
                  {
                    pattern: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
                    message: t("messages.invalid_email_format"),
                  },
                ]}
              >
                <Input
                  id={"email"}
                  maxLength={45}
                  placeholder={t("kidzbiz.enterEmail")}
                  prefix={<i className={`${bizIcons.MAILSEND} mr-10`} />}
                  disabled={activeField && activeField !== FIELDS.EMAIL}
                  onChange={(e) => {
                    if (e.target.value && activeField !== FIELDS.EMAIL) {
                      setActive(FIELDS.EMAIL);
                      validateForm("email");
                    } else if (!e.target.value) {
                      setActive(undefined);
                    }
                  }}
                  onBlur={() => validateForm("email")}
                  onPressEnter={() => validateForm("email")}
                  size={"large"}
                />
              </Form.Item>
            </Form.Item>
            <Form.Item>
              <Button
                className="full-width fs-16 tsl-4"
                type="primary"
                htmlType="submit"
                loading={isLoading}
                disabled={isLoading}
                icon={
                  <SaveButtonText withIcon={false}>
                    {t("kidzbiz.addSatellite")}
                  </SaveButtonText>
                }
              />
            </Form.Item>
          </Form>
        </div>
        {language?.lang === Languages.ZH ? (
          <div className={"text-color-secondary"} style={{ marginTop: "32px" }}>
            You can invite another organization to become a linked account. This
            feature is typically used by franchisors to link to franchisees. It
            allows the invited account to create, manage and accept payment for
            courses under your branding. As the inviter, you will have read-only
            visibility over the invited account's Bizibuz activity including
            their courses, customers, bookings, course promotions and payments -
            ZH.
          </div>
        ) : (
          <div className={"text-color-secondary"} style={{ marginTop: "32px" }}>
            You can invite another organization to become a linked account. This
            feature is typically used by franchisors to link to franchisees. It
            allows the invited account to create, manage and accept payment for
            courses under your branding. As the inviter, you will have read-only
            visibility over the invited account's Bizibuz activity including
            their courses, customers, bookings, course promotions and payments.
          </div>
        )}
      </div>
    );
  }),
);

export default withRouter(AddLinkedKidzbiz);
