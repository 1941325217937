import userStore from "scripts/mobx/userStore";

const formatNumber = (value: number, sperator?: string) =>
  value
    ? value
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${sperator || ","}`)
    : 0;

const isFloat = (n: number) => {
  return Number(n) === n && n % 1 !== 0;
};

export const formatPrice = (
  price: number,
  options?: { currency?: string; isReduce?: boolean; isNotUnit?: boolean },
) => {
  const currency = options?.currency ?? userStore.currency;
  let prefix = `${currency ?? "HKD"} `;
  if (options?.isReduce) prefix = `${currency ?? "HKD"} -`;

  if (options?.isNotUnit) prefix = "";

  if (isFloat(price)) {
    return `${prefix}${formatNumber(Math.trunc(price * 10) / 10)}`;
  } else {
    return `${prefix}${formatNumber(price)}.0`;
  }
};
