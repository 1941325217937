import React from "react";
import { ContentWithTableCourses } from "../../ContentWithTableCourses";

interface IProps {
  backToCourseCreateHandler: () => void;
  isLoading: boolean;
  copyCourseHandler: (id: number) => void;
}

export const CreateCourseByCopy = (props: IProps) => {
  const { backToCourseCreateHandler, isLoading, copyCourseHandler } = props;

  return (
    <div className={"create-courses"}>
      <ContentWithTableCourses
        isLoading={isLoading}
        copyCourseHandler={copyCourseHandler}
        forCopy={true}
        backToCourseCreateHandler={backToCourseCreateHandler}
      />
    </div>
  );
};
