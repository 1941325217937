export const filterModels = {
    age: [
        { value: "0-2", placeholder: "0-2" },
        { value: "3-5", placeholder: "3-5" },
        { value: "6-8", placeholder: "6-8" },
        { value: "9-11", placeholder: "9-11" },
        { value: "12-14", placeholder: "12-14" },
        { value: "15-18", placeholder: "15-18" },
    ],
    transactionVolume: [
        { value: "0-10000", placeholder: "0 - 10,000" },
        { value: "10000-50000", placeholder: "10,000 - 50,000" },
        { value: "50000-100000", placeholder: "50,000 - 100,000" },
        { value: "100000-200000", placeholder: "100,000 - 200,000" },
        { value: "200000-500000", placeholder: "200,000 - 500,000" },
        { value: "500000-100000000000", placeholder: "500,000+" },
    ],
};