import {
  Button,
  Col,
  DatePicker,
  Drawer,
  Form,
  InputNumber,
  message,
  notification,
  Row,
  TimePicker,
} from "antd";
import ButtonLink from "components/ButtonLink";
import TableComponent from "components/TableComponent/TableComponent";
import { FORMAT_DATE } from "constants/commons";
import { CourseStatus, DayOfWeek } from "constants/type.interface";
import { uniq } from "lodash";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import moment from "moment";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import courseStore from "scripts/mobx/courseStore";
import { userStoreContext } from "scripts/mobx/userStore";
import { ButtonWhite } from "../../../../../components/ButtonWhite";
import { bizIcons } from "../../../../../constants/bizConstants";
import helpers from "../../../../../scripts/helpers";
import { FormDate } from "./FormDate";
import { tableScheduleColumn } from "./tableGenerator";
import { TimePickerComponent } from "./TimePicker";

interface IProps extends RouteComponentProps {
  nextStep: () => void;
  prevStep: () => void;
  history: any;
  data?: any;
  publishChecked: boolean;
  dataCourse: any;
  courseStatus: CourseStatus;
}
const { RangePicker } = TimePicker;

export const ManCourseCreateStep2 = withRouter(
  observer((props: IProps) => {
    const { dataCourse, courseStatus, prevStep, nextStep } = props;

    const userStore = useContext(userStoreContext);

    const { t } = useTranslation("base");
    const isDisabled = [CourseStatus.Draft, CourseStatus.Pending].includes(
      courseStatus,
    )
      ? false
      : true;

    const excludedDates = toJS(courseStore?.excludedDates);
    const scheduleClass = toJS(courseStore?.scheduleClass);
    const dayOfWeekArr = toJS(courseStore?.dayOfWeekArr);
    // console.log(scheduleClass);

    const daysOfWeekObject = (day: DayOfWeek) => {
      const arr = dayOfWeekArr?.filter((el: any) => el.dayOfWeek === day);
      return arr?.[0];
    };

    const [isLoading, setIsLoading] = useState(false);
    const [isSchedule, setIsSchedule] = useState<boolean>(false);

    const removeExDateHandler = (val: Date) => {
      courseStore?.clearGenDates();
      const _tempExArr = [...excludedDates];
      // @ts-ignore
      _tempExArr.remove(val);
      courseStore.updateExcludedDates(_tempExArr);
    };

    const [form] = Form.useForm();
    const onFinish = async (values: any) => {
      courseStore.updateCourseCreateData({
        step2: values,
      });
      await nextStep();
    };

    const onBack = () => {
      const values = form.getFieldsValue();
      const a = courseStore?.dayOfWeekArr;
      const _val = { ...values, daysOfWeek: a };
      courseStore?.updateCourseCreateData(_val);
      prevStep();
    };

    const updateSchedule = (day: DayOfWeek, range?: [string, string]) => {
      courseStore?.clearGenDates();
      courseStore?.updateDaysOfWeek(day, range);
      if (courseStore?.dayOfWeekArr?.length > 0) {
        form.setFieldsValue({
          daysOfWeek: 1,
        });
      } else {
        form.setFieldsValue({
          daysOfWeek: null,
        });
      }
    };

    const onGenerateDates = () => {
      const fields = form.getFieldsValue();
      const weeklySchedules = dayOfWeekArr?.map((el) => {
        return {
          dayOfWeek: el.dayOfWeek?.toUpperCase(),
          startTime: el.timeStart,
          endTime: el.timeEnd,
        };
      });
      const newExcludedDates = excludedDates?.map((el) =>
        moment(el).format(FORMAT_DATE.query),
      );

      const params = {
        fromDate: moment(fields?.startDate).format(FORMAT_DATE.query),
        numOfClasses: Number(fields?.numberOfClasses || 0),
        weeklySchedules,
        excludedDates: newExcludedDates,
      };

      const durations: number[] = [];
      weeklySchedules?.forEach((el) => {
        durations.push(helpers.getDurationTimes(el.startTime, el.endTime));
      });

      if (uniq(durations)?.length > 1) {
        courseStore?.getDuration(0);
        notification.error({
          message: t("courses.same_classes"),
        });
      } else {
        courseStore?.getDuration(uniq(durations)?.[0]);
        courseStore?.generateDates(params).then((res) => {
          const startDate = moment(form.getFieldValue("startDate")).format(
            FORMAT_DATE.query,
          );
          if (res?.[0]?.date !== startDate) {
            notification.error({
              message: t("courses.same_date_from", {
                date: `"${t("courses.date_from")}"`,
              }),
            });
            setIsSchedule(false);
            // courseStore?.clearGenDates();
          } else {
            setIsSchedule((prev) => !prev);
          }
        });
      }
    };

    const ActionButton = (record: any) => {
      return (
        <div className="flex-align-items">
          <div
            onClick={() => courseStore?.updateClassDate(record)}
            style={{ marginRight: 8 }}
          >
            <i
              className={bizIcons.EDIT}
              style={{ fontSize: "16px", marginRight: 6 }}
            />
          </div>
          <div
            onClick={() => {
              const _tempExArr = [...scheduleClass];
              // @ts-ignore
              _tempExArr.remove(record);
              courseStore.updateClassDates(_tempExArr);
            }}
          >
            <i
              className={bizIcons.DELETE}
              style={{ fontSize: "16px", marginRight: 6, color: "red" }}
            />
          </div>
        </div>
      );
    };

    const onDraweShedule = () => {
      setIsSchedule((prev) => !prev);
      courseStore?.updateClassDate(null);
      form.setFieldsValue({
        numberOfClasses: scheduleClass?.length,
        startDate: moment(scheduleClass?.[0]?.date),
      });
      // const holidays = excludedDates?.map((el: any) => {
      //   const newDate = new Date(
      //     moment(helpers.getServerDate(el), FORMAT_DATE.query).format(),
      //   );
      //   return newDate;
      // });
      // const newHolidays = helpers.getArrayDates([
      //   ...excludedDates,
      //   ...holidays,
      // ]);
      // courseStore?.updateHKHolidays(
      //   newHolidays?.filter(
      //     (day) =>
      //       moment(day)?.isAfter(
      //         moment(scheduleClass?.[0]?.date, FORMAT_DATE.query),
      //       ) &&
      //       moment(day)?.isBefore(
      //         moment(
      //           scheduleClass?.[scheduleClass?.length - 1]?.date,
      //           FORMAT_DATE.query,
      //         ),
      //       ),
      //   ),
      // );
    };

    return (
      <>
        <Form
          form={form}
          name="course-create-step-2"
          className="course-create-form tsl-3 form-grid-layout"
          initialValues={{
            daysOfWeek: dayOfWeekArr?.length > 0 ? 1 : null,
            startDate: dataCourse?.step2?.startDate || moment(),
            numberOfClasses: dataCourse?.step2?.numberOfClasses || undefined,
          }}
          onFinish={onFinish}
          onValuesChange={() => courseStore?.clearGenDates()}
        >
          <Row gutter={24}>
            <Col xs={24} lg={12}>
              <Form.Item className="bsl-4">
                <div className="label-wrapper">
                  <label htmlFor="startDate">{t("courses.dateFrom")}*</label>
                </div>

                <Form.Item
                  name="startDate"
                  rules={[
                    {
                      required: true,
                      message: t("messages.required_field", {
                        defaultValue: "Please fill in all required fields",
                      }),
                    },
                  ]}
                >
                  <DatePicker
                    id={"startDate"}
                    style={{ width: "100%" }}
                    showToday={false}
                    format={"DD.MM.YY"}
                    disabledDate={(current) => {
                      return current && current < moment().startOf("day");
                    }}
                    placeholder={t("courses.enterStartDate")}
                    size={"large"}
                    disabled={isDisabled}
                  />
                </Form.Item>
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item className="bsl-3">
                <div className="label-wrapper">
                  <label htmlFor="numberOfClasses">
                    {t("courses.numberOfClasses")}*
                  </label>
                </div>

                <Form.Item
                  name="numberOfClasses"
                  rules={[
                    {
                      required: true,
                      message: t("messages.required_field", {
                        defaultValue: "Please fill in all required fields",
                      }),
                    },
                  ]}
                >
                  <InputNumber
                    id={"numberOfClasses"}
                    placeholder={t("courses.enterNumberofClasses")}
                    style={{ width: "100%" }}
                    readOnly={isLoading}
                    min={1}
                    max={100000}
                    size={"large"}
                    disabled={isDisabled}
                  />
                </Form.Item>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col xs={24}>
              <Form.Item
                className="bsl-4"
                name="daysOfWeek"
                rules={[
                  {
                    required: scheduleClass?.length > 0 ? false : true,
                    message: t("messages.required_field", {
                      defaultValue: "Please fill in all required fields",
                    }),
                  },
                ]}
              >
                <div className="label-wrapper">
                  <label>
                    {t("courses.schedule")}&nbsp;(
                    <i style={{ fontSize: 12 }}>
                      {t("courses.assume_location_timezone", {
                        location: userStore.isSGProvider ? "SG" : "HK",
                      })}
                    </i>
                    )&nbsp;*
                  </label>
                </div>
                {/* {!courseStore?.courseCreateDataVal?.daysOfWeek?.length && (
                  <div style={{ color: "#ff0000" }}>
                    Please, pick a course working hours
                  </div>
                )} */}

                <Row gutter={24}>
                  <Col xs={24} md={12}>
                    <Form.Item>
                      <div className={"flex-space-between bsl-4"}>
                        <div
                          className={
                            daysOfWeekObject(DayOfWeek.Monday)?.timeStart &&
                            daysOfWeekObject(DayOfWeek.Monday)?.timeEnd
                              ? "text-color"
                              : "text-color-secondary"
                          }
                        >
                          Mon
                        </div>
                        <TimePickerComponent
                          form={form}
                          name={DayOfWeek.Monday}
                          onChange={(start, end) => {
                            if (start && end) {
                              updateSchedule(DayOfWeek.Monday, [start, end]);
                            } else {
                              updateSchedule(DayOfWeek.Monday);
                            }
                          }}
                          value={{
                            startTime:
                              daysOfWeekObject(DayOfWeek.Monday)?.timeStart ||
                              "",
                            endTime:
                              daysOfWeekObject(DayOfWeek.Monday)?.timeEnd || "",
                          }}
                          disabled={isDisabled}
                        />
                      </div>
                    </Form.Item>

                    <Form.Item>
                      <div className={"flex-space-between bsl-4"}>
                        <div
                          className={
                            daysOfWeekObject(DayOfWeek.Tuesday)?.timeStart &&
                            daysOfWeekObject(DayOfWeek.Tuesday)?.timeEnd
                              ? "text-color"
                              : "text-color-secondary"
                          }
                        >
                          Tue
                        </div>
                        <TimePickerComponent
                          form={form}
                          name={DayOfWeek.Tuesday}
                          onChange={(start, end) => {
                            if (start && end) {
                              updateSchedule(DayOfWeek.Tuesday, [start, end]);
                            } else {
                              updateSchedule(DayOfWeek.Tuesday);
                            }
                          }}
                          value={{
                            startTime:
                              daysOfWeekObject(DayOfWeek.Tuesday)?.timeStart ||
                              "",
                            endTime:
                              daysOfWeekObject(DayOfWeek.Tuesday)?.timeEnd ||
                              "",
                          }}
                          disabled={isDisabled}
                        />
                      </div>
                    </Form.Item>

                    <Form.Item>
                      <div className={"flex-space-between bsl-4"}>
                        <div
                          className={
                            daysOfWeekObject(DayOfWeek.Wednesday)?.timeStart &&
                            daysOfWeekObject(DayOfWeek.Wednesday)?.timeEnd
                              ? "text-color"
                              : "text-color-secondary"
                          }
                        >
                          Wed
                        </div>
                        <TimePickerComponent
                          form={form}
                          name={DayOfWeek.Wednesday}
                          onChange={(start, end) => {
                            if (start && end) {
                              updateSchedule(DayOfWeek.Wednesday, [start, end]);
                            } else {
                              updateSchedule(DayOfWeek.Wednesday);
                            }
                          }}
                          value={{
                            startTime:
                              daysOfWeekObject(DayOfWeek.Wednesday)
                                ?.timeStart || "",
                            endTime:
                              daysOfWeekObject(DayOfWeek.Wednesday)?.timeEnd ||
                              "",
                          }}
                          disabled={isDisabled}
                        />
                      </div>
                    </Form.Item>

                    <Form.Item>
                      <div className={"flex-space-between bsl-4"}>
                        <div
                          className={
                            daysOfWeekObject(DayOfWeek.Thursday)?.timeStart &&
                            daysOfWeekObject(DayOfWeek.Thursday)?.timeEnd
                              ? "text-color"
                              : "text-color-secondary"
                          }
                        >
                          Thu
                        </div>
                        <TimePickerComponent
                          form={form}
                          name={DayOfWeek.Thursday}
                          onChange={(start, end) => {
                            if (start && end) {
                              updateSchedule(DayOfWeek.Thursday, [start, end]);
                            } else {
                              updateSchedule(DayOfWeek.Thursday);
                            }
                          }}
                          value={{
                            startTime:
                              daysOfWeekObject(DayOfWeek.Thursday)?.timeStart ||
                              "",
                            endTime:
                              daysOfWeekObject(DayOfWeek.Thursday)?.timeEnd ||
                              "",
                          }}
                          disabled={isDisabled}
                        />
                      </div>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item>
                      <div className={"flex-space-between bsl-4"}>
                        <div
                          className={
                            daysOfWeekObject(DayOfWeek.Friday)?.timeStart &&
                            daysOfWeekObject(DayOfWeek.Friday)?.timeEnd
                              ? "text-color"
                              : "text-color-secondary"
                          }
                        >
                          Fri
                        </div>
                        <TimePickerComponent
                          form={form}
                          name={DayOfWeek.Friday}
                          onChange={(start, end) => {
                            if (start && end) {
                              updateSchedule(DayOfWeek.Friday, [start, end]);
                            } else {
                              updateSchedule(DayOfWeek.Friday);
                            }
                          }}
                          value={{
                            startTime:
                              daysOfWeekObject(DayOfWeek.Friday)?.timeStart ||
                              "",
                            endTime:
                              daysOfWeekObject(DayOfWeek.Friday)?.timeEnd || "",
                          }}
                          disabled={isDisabled}
                        />
                      </div>
                    </Form.Item>

                    <Form.Item>
                      <div className={"flex-space-between bsl-4"}>
                        <div
                          className={
                            daysOfWeekObject(DayOfWeek.Saturday)?.timeStart &&
                            daysOfWeekObject(DayOfWeek.Saturday)?.timeEnd
                              ? "text-color"
                              : "text-color-secondary"
                          }
                        >
                          Sat
                        </div>
                        <TimePickerComponent
                          form={form}
                          name={DayOfWeek.Saturday}
                          onChange={(start, end) => {
                            if (start && end) {
                              updateSchedule(DayOfWeek.Saturday, [start, end]);
                            } else {
                              updateSchedule(DayOfWeek.Saturday);
                            }
                          }}
                          value={{
                            startTime:
                              daysOfWeekObject(DayOfWeek.Saturday)?.timeStart ||
                              "",
                            endTime:
                              daysOfWeekObject(DayOfWeek.Saturday)?.timeEnd ||
                              "",
                          }}
                          disabled={isDisabled}
                        />
                      </div>
                    </Form.Item>

                    <Form.Item>
                      <div className={"flex-space-between bsl-4"}>
                        <div
                          className={
                            daysOfWeekObject(DayOfWeek.Sunday)?.timeStart &&
                            daysOfWeekObject(DayOfWeek.Sunday)?.timeEnd
                              ? "text-color"
                              : "text-color-secondary"
                          }
                        >
                          Sun
                        </div>
                        <TimePickerComponent
                          form={form}
                          name={DayOfWeek.Sunday}
                          onChange={(start, end) => {
                            if (start && end) {
                              updateSchedule(DayOfWeek.Sunday, [start, end]);
                            } else {
                              updateSchedule(DayOfWeek.Sunday);
                            }
                          }}
                          value={{
                            startTime:
                              daysOfWeekObject(DayOfWeek.Sunday)?.timeStart ||
                              "",
                            endTime:
                              daysOfWeekObject(DayOfWeek.Sunday)?.timeEnd || "",
                          }}
                          disabled={isDisabled}
                        />
                      </div>
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col xs={24}>
              <Form.Item className="bsl-4">
                <div className="flex-space-between label-wrapper">
                  <label htmlFor="_excludedDates">
                    {t("courses.excludedDates")}
                  </label>
                  <ButtonLink
                    disabled={
                      dayOfWeekArr?.length > 0 &&
                      !!form.getFieldValue("numberOfClasses")
                        ? false
                        : true
                    }
                    type="button"
                    onClick={() => {
                      const fields = form.getFieldsValue();
                      const weeklySchedules = dayOfWeekArr?.map((el) => {
                        return {
                          dayOfWeek: el.dayOfWeek?.toUpperCase(),
                          startTime: el.timeStart,
                          endTime: el.timeEnd,
                        };
                      });

                      const params = {
                        fromDate: moment(fields?.startDate).format(
                          FORMAT_DATE.query,
                        ),
                        numOfClasses: Number(fields?.numberOfClasses || 0),
                        weeklySchedules,
                        subdirectory: userStore.isSGProvider
                          ? "Singapore"
                          : "Hong Kong",
                      };

                      courseStore?.getHKHolidays(params);
                      courseStore?.clearGenDates();
                    }}
                  >
                    {userStore.isSGProvider
                      ? t("courses.buttonExcludeRelevantSGHolidays")
                      : t("courses.buttonExcludeRelevantHKHolidays")}
                  </ButtonLink>
                </div>
                <div className="course-exclude-date-wrap">
                  <div className="flex-1">
                    <Form.Item noStyle shouldUpdate>
                      {() => {
                        return (
                          <Form.Item name="_excludedDates">
                            <DatePicker
                              id={"_excludedDates"}
                              style={{ width: "100%" }}
                              showToday={false}
                              format={"DD.MM.YY"}
                              disabledDate={(current) => {
                                return (
                                  (current &&
                                    current <
                                      moment().add(1, "days").startOf("day")) ||
                                  (current &&
                                    current <
                                      moment(form.getFieldValue("startDate"))
                                        .add(1, "days")
                                        .startOf("day")) ||
                                  excludedDates.find(
                                    (date: string) =>
                                      moment(date).format(FORMAT_DATE.query) ===
                                      moment(current).format(FORMAT_DATE.query),
                                  )
                                );
                              }}
                              placeholder={t("courses.pickupExcludedDate")}
                              value={null}
                              onChange={(e) => {
                                if (moment.isMoment(e)) {
                                  const newExcludedArr = excludedDates || [];
                                  if (
                                    newExcludedArr.filter((el: any) => {
                                      return (
                                        helpers.formatDate(el) ===
                                        helpers.formatDate(e?.toDate())
                                      );
                                    }).length
                                  ) {
                                    message.warn(t("courses.alreadyIncludes"));
                                    return;
                                  }
                                  newExcludedArr.push(e?.toDate());
                                  console.log(newExcludedArr, "newExcludedArr");

                                  courseStore.updateExcludedDates(
                                    newExcludedArr,
                                  );
                                  courseStore?.clearGenDates();
                                }
                              }}
                              size={"large"}
                              disabled={isDisabled}
                            />

                            {!!excludedDates?.length && (
                              <div
                                className={"flex"}
                                style={{
                                  flexWrap: "wrap",
                                  gap: "16px",
                                  marginTop: "8px",
                                }}
                              >
                                {excludedDates?.map((el: Date) => {
                                  const days = dayOfWeekArr?.map((d) =>
                                    d?.dayOfWeek?.toLocaleLowerCase(),
                                  );

                                  if (
                                    !days?.includes(
                                      moment(el)
                                        .format("dddd")
                                        ?.toLocaleLowerCase(),
                                    )
                                  )
                                    return false;
                                  return (
                                    <div
                                      className={"flex cursor-pointer"}
                                      key={`${el}_`}
                                      onClick={() => {
                                        !isDisabled && removeExDateHandler(el);
                                      }}
                                    >
                                      <div
                                        className={
                                          "text-color-secondary flex-center"
                                        }
                                      >
                                        {helpers.formatDate(el)}
                                      </div>
                                      {!isDisabled && (
                                        <div style={{ marginLeft: "4px" }}>
                                          <i
                                            className={`${bizIcons.CLOSE} fs-16`}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                          </Form.Item>
                        );
                      }}
                    </Form.Item>
                  </div>
                  <Form.Item shouldUpdate noStyle>
                    {() => {
                      return (
                        <Button
                          disabled={
                            scheduleClass?.length > 0
                              ? false
                              : dayOfWeekArr?.length > 0 &&
                                !!form.getFieldValue("numberOfClasses")
                              ? false
                              : true
                          }
                          className="btn-generate"
                          type="primary"
                          style={{
                            width: "auto",
                            paddingLeft: 20,
                            paddingRight: 20,
                          }}
                          htmlType="button"
                          loading={isLoading}
                          icon={
                            <div className={"flex-center"}>
                              <>
                                {!scheduleClass || scheduleClass?.length === 0
                                  ? t("courses.generateDates")
                                  : t("courses.classDates")}
                              </>
                            </div>
                          }
                          onClick={() => {
                            if (!scheduleClass || scheduleClass?.length === 0) {
                              courseStore?.clearGenDates();
                              onGenerateDates();
                            } else {
                              setIsSchedule((prev) => !prev);
                            }
                          }}
                        />
                      );
                    }}
                  </Form.Item>
                </div>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col xs={24} md={12}>
              <Form.Item>
                <div className="flex-align-items tsl-4">
                  <ButtonWhite
                    className="fs-16 tsl-4 mr-25"
                    onClick={onBack}
                    style={{
                      width: "auto",
                      minWidth: "auto",
                      paddingLeft: 20,
                      paddingRight: 20,
                    }}
                    type="primary"
                  >
                    <div className={"flex-center"}>
                      <i className="ri-arrow-left-line mr-8 fs-20" />
                      {t("bizBase.back")}
                    </div>
                  </ButtonWhite>
                  <Button
                    className="fs-16 tsl-4"
                    type="primary"
                    style={{
                      width: "auto",
                      paddingLeft: 20,
                      paddingRight: 20,
                    }}
                    htmlType="submit"
                    loading={isLoading}
                    icon={
                      <div className={"flex-center"}>
                        <>
                          {t("bizBase.nextStep")}
                          <i className="ri-arrow-right-line ml-8 fs-20" />
                        </>
                      </div>
                    }
                    disabled={
                      !scheduleClass || scheduleClass?.length === 0
                        ? true
                        : false
                    }
                  />
                </div>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Drawer
          placement="right"
          onClose={() => {
            onDraweShedule?.();
          }}
          visible={isSchedule}
          className="drawer-form-add-locations"
        >
          {toJS(courseStore?.classDate) ? (
            <>
              <h2>{t("courses.editClassDates")}</h2>
              <FormDate
                excludedDates={excludedDates}
                scheduleClass={scheduleClass}
                data={toJS(courseStore?.classDate)}
                onClear={() => courseStore?.updateClassDate(null)}
              />
            </>
          ) : (
            <>
              <h2>{t("courses.classDates")}</h2>
              <TableComponent
                propsTableColumns={tableScheduleColumn(t, ActionButton)}
                propsTableData={scheduleClass || []}
                pagination={false}
              />
              <Button
                className="fs-16 tsl-4 bsl-4"
                type="primary"
                style={{
                  width: "auto",
                  paddingLeft: 30,
                  paddingRight: 30,
                }}
                htmlType="button"
                icon={
                  <div className={"flex-center"}>
                    <>{t("bizBase.save")}</>
                  </div>
                }
                onClick={() => {
                  onDraweShedule?.();
                }}
              />
            </>
          )}
        </Drawer>
      </>
    );
  }),
);
