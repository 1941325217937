import moment from "moment";
export const formatTime = "HH:mm";
export const getSelectionTime = () => {
  const hours = [];
  for (let hour = 8; hour < 23; hour++) {
    hours.push(moment({ hour }).format(formatTime));
    hours.push(
      moment({
        hour,
        minute: 15,
      }).format(formatTime),
    );
    hours.push(
      moment({
        hour,
        minute: 30,
      }).format(formatTime),
    );

    hours.push(
      moment({
        hour,
        minute: 45,
      }).format(formatTime),
    );
  }
  return hours;
};

export const checkTime = (start?: string, end?: string) => {
  let check = false;
  const startTime = start ? moment(start, formatTime) : "";
  const endTime = end ? moment(end, formatTime) : "";

  if (startTime && endTime && startTime.isAfter(endTime)) {
    check = true;
  }
  return check;
};
