import React from "react";
import "./index.less";
import { ChkBlock, IChkBlockProps } from "./ChkBlock";

interface IProps {
  data: IChkBlockProps;
  inline?: boolean;
  onChange?: (data: any, index?: number) => void;
}

export const CheckBoxes = (props: IProps) => {
  const { data, inline, onChange } = props;

  return (
    <div className={`biz-checkBoxes ${inline ? "inline" : ""}`}>
      {(Object.keys(data) || [])
        .filter((propName: string) => !!data[propName].controlsList?.length)
        .map((propName: string) => (
          <ChkBlock
            fullData={data}
            blockData={{
              [propName]: data[propName],
            }}
            key={propName}
            onChange={onChange}
          />
        ))}
    </div>
  );
};
