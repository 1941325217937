import AccountSettings from "pages/accountSettings";
import ChangeEmail from "pages/accountSettings/ChangeEmail";
import ChangePassword from "pages/accountSettings/ChangePassword";
import Courses from "pages/courses";
import Reports from "pages/reports";
import viewCourse from "pages/courses/viewCourse";
import Customers from "pages/customers";
import viewCustomer from "pages/customers/viewCustomer";
import StripeAccount from "pages/stripeAccountOnboard";
import NoAccess from "../pages/403";
import NoMatch from "../pages/404";
import NotResponding from "../pages/500";
import BiziPolicy from "../pages/biziPolicy";
import Bookings from "../pages/bookings";
import FAQs from "../pages/faqs";
import KidzBiz from "../pages/kidzBiz";
import Payments from "../pages/payments";
import SignUp from "../pages/signup";
import VerifyPage from "../pages/verifyPage";
import Promotions from "../pages/promotions";
import AddPromotions from "../pages/promotions/addBulk";
import AddPromotionsCourse from "../pages/promotions/addCourse";
import Reviews from "../pages/reviews";
import ViewReview from "../pages/reviews/viewReview";
import { AppRoutes } from "helpers/app.routes";
import Notifications from "pages/notifications";
import Tutorial from "../pages/tutorial";
import PayViewCustomerPage from "pages/payments/payViewCustomerPage";
import PayViewKidzBizPage from "pages/payments/payViewKidzbizPage";

export const OrganizationsRoute = {
  component: KidzBiz,
  exact: true,
  path: "/kidzbiz",
};

export const HomeRoute = {
  component: KidzBiz,
  exact: true,
  path: "/",
};

export const BiziPolicyRoute = {
  component: BiziPolicy,
  path: "/bizibuz-policies/:item?",
};

export const NoAccessRoute = {
  component: NoAccess,
  path: "/403",
};

export const NotRespondingRoute = {
  component: NotResponding,
  path: "/500",
};

export const NoMatchRoute = {
  component: NoMatch,
};

export const SignUpRoute = {
  component: SignUp,
  exact: true,
  path: "/signup",
};

export const AccountSettingsRoute = {
  component: AccountSettings,
  exact: true,
  path: "/account-settings",
};

export const ChangeEmailRoute = {
  component: ChangeEmail,
  exact: true,
  path: "/account-settings/change-email",
};

export const ChangePasswordRoute = {
  component: ChangePassword,
  exact: true,
  path: "/account-settings/change-password",
};

export const CoursesRoute = {
  component: Courses,
  exact: true,
  path: "/courses/:action?/:id?",
};

export const ReportsRoute = {
  component: Reports,
  exact: true,
  path: "/reports/:action?/:id?",
};

export const ViewCourseRoute = {
  component: viewCourse,
  exact: true,
  path: "/view-course/:id?",
};

export const VerifyRoute = {
  component: VerifyPage,
  exact: true,
  path: "/verifying",
};

export const PaymentsRoute = {
  component: Payments,
  exact: true,
  path: "/payments",
};

export const FAQsRoute = {
  component: FAQs,
  path: "/faqs",
};

export const BookingsRoute = {
  component: Bookings,
  exact: true,
  path: "/bookings",
};

export const CustomersRoute = {
  component: Customers,
  exact: true,
  path: "/customers",
  // withVerifying: true,
};

export const viewCustomerRoute = {
  component: viewCustomer,
  path: "/view-customer/:id?",
  // withVerifying: true,
};

export const StripeAccountRoute = {
  component: StripeAccount,
  exact: true,
  path: "/stripeAccountOnboard",
};

export const PromotionsRoute = {
  component: Promotions,
  exact: true,
  path: "/promotions",
  withVerifying: true,
};

export const AddPromotionsBulkRoute = {
  component: AddPromotions,
  exact: true,
  path: "/promotions/add/bulk",
  withVerifying: true,
};

export const AddPromotionsCourseRoute = {
  component: AddPromotionsCourse,
  exact: true,
  path: "/promotions/add/course",
  withVerifying: true,
};

export const EditPromotionsBulkRoute = {
  component: AddPromotions,
  exact: true,
  path: "/promotions/edit-bulk/:id?",
  withVerifying: true,
};

export const ViewPromotionsBulkRoute = {
  component: AddPromotions,
  exact: true,
  path: "/promotions/view-bulk/:id?",
  withVerifying: true,
};

export const EditPromotionsCourseRoute = {
  component: AddPromotionsCourse,
  exact: true,
  path: "/promotions/edit-course/:id?",
  withVerifying: true,
};

export const ViewPromotionsCourseRoute = {
  component: AddPromotionsCourse,
  exact: true,
  path: "/promotions/view-course/:id?",
  withVerifying: true,
};

export const ReviewsRoute = {
  component: Reviews,
  exact: true,
  path: AppRoutes.reviews,
  withVerifying: true,
};

export const ViewReviewRoute = {
  component: ViewReview,
  exact: true,
  path: "/review/:id",
  withVerifying: true,
};

export const NotificationsRoute = {
  component: Notifications,
  exact: true,
  path: "/account-settings/notifications",
};

export const TutorialRoute = {
  component: Tutorial,
  path: "/tutorial",
};

export const PaymentViewCustomerRoute = {
  component: PayViewCustomerPage,
  path: "/payment-customer-details/:id?",
  withVerifying: true,
};

export const PaymentViewKidzbizRoute = {
  component: PayViewKidzBizPage,
  path: "/payment-kidzbiz-details/:id?/:code?",
  withVerifying: true,
};
