import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  notification,
  Row,
} from "antd";
import { CourseStatus } from "constants/type.interface";
import { AppRoutes } from "helpers/app.routes";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  useCurrentUser,
  useCurrentVerify,
  userBlocked,
} from "scripts/custom-hooks/useCurrentUser";
import helpers from "scripts/helpers";
import courseStore from "scripts/mobx/courseStore";
import { userStoreContext } from "scripts/mobx/userStore";
import { ButtonWhite } from "../../../../../components/ButtonWhite";
import { Link } from "../../../../../components/Link";
import SaveButtonText from "../../../../../components/SaveButtonText";

interface IProps extends RouteComponentProps {
  nextStep: (publishChecked?: boolean) => void;
  prevStep: () => void;
  setPublishChecked: (pub: boolean) => void;
  publishChecked: boolean;
  isEdit?: boolean;
  dataCourse: any;
  courseStatus: CourseStatus;
}

export const ManCourseCreateStep3 = withRouter(
  observer((props: IProps) => {
    const { dataCourse, courseStatus, nextStep, prevStep, isEdit } = props;
    const userStore = useContext(userStoreContext);
    const { t } = useTranslation("base");
    const user = useCurrentUser();
    const verified = useCurrentVerify();
    const isBlocked = userBlocked();
    const isDisabled = [CourseStatus.Draft, CourseStatus.Pending].includes(
      courseStatus,
    )
      ? false
      : true;

    const [isLoading, setIsLoading] = useState(false);
    const [checked, setChecked] = useState<boolean>(
      dataCourse?.step3?.publishACourse || false,
    );
    const scheduleClass = toJS(courseStore?.scheduleClass);

    const [form] = Form.useForm();
    const onFinish = async (values?: any) => {
      setIsLoading(true);
      form
        .validateFields(checked ? [Object.keys(values)] : [])
        .then(async () => {
          courseStore.updateCourseCreateData({
            step3: { ...values, publishACourse: checked },
          });
          await nextStep();
          setTimeout(() => {
            setIsLoading(false);
          }, 300);
        })
        .catch((e) => {
          setTimeout(() => {
            setIsLoading(false);
          }, 300);
        });
    };

    const onFinishFailed = (val: any) => {
      const errorMessage = (val?.errorFields?.[0]?.errors || []).join(", ");
      notification.error({
        message: errorMessage,
      });
    };

    const onBack = () => {
      const values = form.getFieldsValue();
      //Update course
      prevStep();
    };

    console.log({ dataCourse });

    const initialValues = useMemo(() => {
      return {
        briefDescription: dataCourse?.step3?.briefDescription ?? "",
        minClassesForSale: dataCourse?.step3?.minClassesForSale || 1,
        availableClassHeadcount:
          dataCourse?.step3?.availableClassHeadcount || 1,
        totalPrice: dataCourse?.step3?.totalPrice ?? "",
        externalSignups: dataCourse?.step3?.externalSignups ?? "",
        publishACourse: isBlocked ? false : checked,
      };
    }, [dataCourse, checked, isBlocked]);

    return (
      <Form
        form={form}
        name="course-create-step-3"
        className="course-create-form tsl-4 form-grid-layout"
        initialValues={initialValues}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row gutter={24}>
          <Col xs={24}>
            <Form.Item className="bsl-4">
              <div className="label-wrapper">
                <label htmlFor="briefDescription">
                  {t("courses.courseDescription")}*
                </label>
                <div className={"text-color-secondary small"}>
                  {t("signUp.step4.pleaseEnterLang")}
                </div>
              </div>
              <Form.Item
                name={"briefDescription"}
                rules={[
                  {
                    required: true,
                    message: t("messages.required_field", {
                      defaultValue: "Please fill in all required fields",
                    }),
                  },
                  {
                    max: 1500,
                    message: t("courses.course_description_exceeds_limit"),
                  },
                ]}
              >
                <Input.TextArea
                  id={"briefDescription"}
                  placeholder={t("courses.enterCourseDescription")}
                  autoSize={{ minRows: 3, maxRows: 3 }}
                  disabled={isDisabled}
                />
              </Form.Item>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col xs={24} md={12}>
            <Form.Item className="bsl-4">
              <div className="label-wrapper">
                <label htmlFor="totalPrice">
                  {t("courses.perClassPrice")}*
                </label>
              </div>
              <div className="course-total-price">
                <span className="course-total-price-unit">
                  {userStore.currency}
                </span>
                <Form.Item
                  name="totalPrice"
                  rules={[
                    {
                      required: true,
                      message: t("courses.please_input_per_class_price"),
                    },
                  ]}
                >
                  <InputNumber
                    id={"totalPrice"}
                    placeholder={t("courses.perClassPrice")}
                    style={{ width: "100%" }}
                    readOnly={isLoading}
                    min={0}
                    max={100000}
                    size={"large"}
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) =>
                      value !== undefined
                        ? value.replace(/\$\s?|(,*)/g, "")
                        : ("" as any)
                    }
                    precision={2}
                    disabled={isDisabled}
                  />
                </Form.Item>
              </div>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item className="bsl-4">
              <div className="label-wrapper flex-space-between">
                <label>{t("courses.totalCoursePrice")}</label>
              </div>

              <Form.Item shouldUpdate>
                {() => {
                  const totalPrice = Number(
                    form.getFieldValue("totalPrice") *
                      (scheduleClass?.length || 1),
                  );
                  return (
                    <InputNumber
                      value={helpers.formatNumber(
                        Number(totalPrice.toFixed(2)),
                      )}
                      readOnly={true}
                      size={"large"}
                      disabled={true}
                      precision={2}
                      style={{ width: "100%" }}
                    />
                  );
                }}
              </Form.Item>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col xs={24} md={12}>
            <Form.Item className="bsl-4">
              <div className="label-wrapper flex-space-between">
                <label htmlFor="minClassHeadcount">
                  {t("courses.minClassesForSale")}*
                </label>
                <Link to={AppRoutes.faqDetail("courses4")} target="_blank">
                  {t("kidzbiz.goToFAQs")}
                </Link>
              </div>

              <Form.Item
                name="minClassesForSale"
                rules={[
                  {
                    required: true,
                    message: t("courses.please_input_min_classes"),
                  },
                  {
                    type: "integer",
                    message: t("courses.validMinClasses"),
                  },
                ]}
              >
                <InputNumber
                  id={"minClassesForSale"}
                  placeholder={t("courses.enterMinClasses")}
                  style={{ width: "100%" }}
                  readOnly={isLoading}
                  min={1}
                  max={Number(
                    courseStore?.courseCreateDataVal?.step2?.numberOfClasses ||
                      1,
                  )}
                  size={"large"}
                  disabled={isDisabled}
                />
              </Form.Item>
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item className="bsl-4">
              <div className="label-wrapper">
                <label htmlFor="availableClassHeadcount">
                  {t("courses.maxClassHeadcount")}*
                </label>
              </div>

              <Form.Item
                name="availableClassHeadcount"
                rules={[
                  {
                    required: true,
                    message: t("courses.please_input_max_class_headcount"),
                  },
                  {
                    type: "integer",
                    message: t("courses.validMinClasses"),
                  },
                ]}
              >
                <InputNumber
                  id={"availableClassHeadcount"}
                  placeholder={t("courses.enterMaxClassHeadcount")}
                  style={{ width: "100%" }}
                  readOnly={isLoading}
                  min={0}
                  max={100000}
                  size={"large"}
                  // disabled={isDisabled}
                />
              </Form.Item>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24}>
            <Form.Item noStyle shouldUpdate>
              {() => {
                return (
                  <Form.Item name="publishACourse">
                    <div className="tsl-4 flex">
                      <Checkbox
                        id={"publishACourse"}
                        className="mr-16"
                        checked={checked}
                        disabled={isBlocked ? true : !verified || isDisabled}
                        onChange={async (e) => {
                          //Update course for back
                          setChecked(e.target.checked);
                          form.setFieldsValue({
                            publishACourse: e.target.checked,
                          });
                        }}
                      />
                      <div>
                        <div>
                          <label
                            htmlFor="publishACourse"
                            className={`${
                              !verified ? "text-color-secondary" : ""
                            }`}
                          >
                            {t("courses.publishACourse")}
                          </label>
                        </div>
                      </div>
                    </div>
                    {/* {!verified && !isBlocked && (
                  <div style={{ color: "#F5222D" }}>
                    {t("courses.noteVerify")}{" "}
                    <ButtonLink
                      type="button"
                      onClick={() => {
                        userStore
                          .setProviderVerified()
                          .then(() => setChecked(true));
                      }}
                    >
                      {t("courses.requestVerification")}{" "}
                    </ButtonLink>
                  </div>
                )} */}
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col xs={24} md={12}>
            <Form.Item>
              <div className="flex-align-items tsl-4">
                <ButtonWhite
                  className="fs-16 tsl-4 mr-25"
                  onClick={onBack}
                  style={{
                    width: "auto",
                    minWidth: "auto",
                    paddingLeft: 20,
                    paddingRight: 20,
                  }}
                  type="primary"
                >
                  <div className={"flex-center"}>
                    <i className="ri-arrow-left-line mr-8 fs-20" />
                    {t("bizBase.back")}
                  </div>
                </ButtonWhite>
                <Button
                  className="fs-16 tsl-4"
                  type="primary"
                  style={{ width: "auto", paddingLeft: 20, paddingRight: 20 }}
                  htmlType="submit"
                  loading={isLoading}
                  icon={
                    isEdit ? (
                      <SaveButtonText withIcon={checked}>
                        {checked
                          ? t("bizBase.save")
                          : t("bizBase.saveAsDraft", {
                              defaultValue: "Save as draft",
                            })}
                      </SaveButtonText>
                    ) : (
                      <SaveButtonText withIcon={checked}>
                        {checked
                          ? t("courses.publishTheCourse", {
                              defaultValue: "Publish the course",
                            })
                          : t("bizBase.saveAsDraft", {
                              defaultValue: "Save as draft",
                            })}
                      </SaveButtonText>
                    )
                  }
                />
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }),
);
