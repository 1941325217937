import React, { useEffect, useState } from "react";
import {
  CHECKBLOCKTYPES,
  countOfValuesEnum,
  IChkBlockProps,
  IControlProps,
} from "components/CheckBoxes/ChkBlock";
import { Filters } from "../../../components/Filters";
import { observer } from "mobx-react";
import ButtonLink from "../../../components/ButtonLink";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { TFunction, useTranslation } from "react-i18next";
import providerStore from "scripts/mobx/providerStore";
import { toJS } from "mobx";
import bookingsStore from "scripts/mobx/bookingsStore";

const getTableFiltersData: (
  userType: boolean,
  providers: any,
  t: TFunction<"base">,
) => IChkBlockProps = (userType, providers, t) => {
  return {
    dateFromTo: {
      title: t("bookings.dateFromTo"),
      combined: true,
      controlsList: [
        {
          value: "",
          active: true,
          default: true,
          placeholder: "bizBase.all",
        },
        {
          value: "",
          markId: "dateFrom",
          type: CHECKBLOCKTYPES.DATE,
          dependsOn: {
            field: {
              name: "dateFromTo",
              id: 2,
            },
            condition: (data) => {
              const { value, current } = data;
              return value && current && current > value?.startOf("day");
            },
          },
          placeholder: "bookings.dateFrom",
        },
        {
          value: "",
          markId: "dateTo",
          type: CHECKBLOCKTYPES.DATE,
          placeholder: "bookings.dateTo",
          dependsOn: {
            field: {
              name: "dateFromTo",
              id: 1,
            },
            condition: (data) => {
              const { value, current } = data;
              return value && current && current < value?.startOf("day");
            },
          },
        },
      ],
    },
    providerId: {
      title: "menu.kidzbiz",
      countOfValues: countOfValuesEnum.Array,
      controlsList: userType && providers,
    },
  };
};
interface ICoursesFiltersProps extends RouteComponentProps {
  userType: boolean;
  onApply: (data: any) => void;
}
export const BookingsFilters = withRouter(
  observer((props: ICoursesFiltersProps): React.ReactElement => {
    const { userType, onApply } = props;
    const { t } = useTranslation("base");

    const [tableFiltersData, setTableFiltersData] = useState<IChkBlockProps>(
      toJS(bookingsStore?.dataFilters),
    );

    useEffect(() => {
      (async () => {
        await providerStore?.getProviders();
        const tableFiltersData = getTableFiltersData(
          userType,
          toJS(providerStore?.providerList)?.map((el: any) => {
            return {
              value: el?.id,
              placeholder: el?.legalName,
              active: false,
              default: false,
            };
          }),
          t,
        );
        // tslint:disable-next-line:forin
        for (const filterKey in tableFiltersData) {
          /** ADDING 'ALL' BUTTONS */
          if (
            tableFiltersData[filterKey]?.controlsList &&
            // @ts-ignore
            !tableFiltersData[filterKey]?.controlsList[0]?.default
          ) {
            // @ts-ignore
            tableFiltersData[filterKey].controlsList.unshift({
              value: "",
              placeholder: "bizBase.all",
              active: true,
              default: true,
            });
          }

          setTableFiltersData(tableFiltersData);
        }
      })();
    }, []);

    const demapFilterData = (data: any): any => {
      const _data = {};

      Object.keys(data).forEach((key) => {
        if (!data[key].combined) {
          _data[key] = data[key].controlsList?.map(
            (el: IControlProps) => el.value,
          );
        } else {
          data[key].controlsList?.forEach((el: IControlProps) => {
            if (el.markId) {
              _data[el.markId] = el.value;
            }
          });
        }
      });

      return _data;
    };

    const onApplyFilters = (data: any) => {
      const _data = { ...demapFilterData(data) };
      onApply(_data);
    };

    const onSetFilter = (data: any) => {
      setTableFiltersData({ ...tableFiltersData, ...data });
      bookingsStore?.setFilters({ ...tableFiltersData, ...data });
    };

    return tableFiltersData ? (
      <Filters
        onSetFilter={onSetFilter}
        onApply={onApplyFilters}
        onReset={() => bookingsStore?.setFilters()}
        filterBlocks={tableFiltersData}
        filterModel={toJS(bookingsStore?.dataFilters)}
      />
    ) : (
      <div>
        <ButtonLink disabled={true}>
          {t("bizBase.filters", {
            defaultValue: "Filters",
          })}
          <i
            className={"ri-arrow-drop-down-line"}
            style={{ fontSize: "20px" }}
          />
        </ButtonLink>
      </div>
    );
  }),
);
