import { createContext } from "react";
import { action, computed, makeObservable, observable } from "mobx";
import requestApi from "helpers/requestApi";
import { DETAIL_API_URL } from "constants/apiUrl";

export class LinkedAccountStore {
  constructor() {
    makeObservable(this);
  }

  @observable
  public linkedAccountData: any;
  public masterSummary: any;
  public satelliteSummary: any;

  @action
  public async getLinkedAccount() {
    const result = await requestApi.get(DETAIL_API_URL.GET_LINKED_ACCOUNT);
    if (result) {
      this.linkedAccountData = result?.data;
      return Promise.resolve(result?.data);
    } else {
      this.linkedAccountData = { ...this.linkedAccountData };
      return Promise.reject();
    }
  }

  public async getMasterSummary() {
    const result = await requestApi.get(DETAIL_API_URL.GET_MASTER_SUMMARY);
    if (result) {
      this.masterSummary = result?.data;
      return Promise.resolve(result?.data);
    } else {
      this.masterSummary = { ...this.masterSummary };
      return Promise.reject();
    }
  }

  public async getSatelliteSummary(providerId: number) {
    const result = await requestApi.get(
      DETAIL_API_URL.GET_SATELLITE_SUMMARY(providerId),
    );
    if (result) {
      this.satelliteSummary = result?.data;
      return Promise.resolve(result?.data);
    } else {
      this.satelliteSummary = { ...this.satelliteSummary };
      return Promise.reject();
    }
  }

  public async sendInvitation(data: { email: string }) {
    await requestApi.put(DETAIL_API_URL.SEND_INVITATION, data);
  }

  @action
  public async delinkAccount(providerId: number) {
    const result = await requestApi.put(
      DETAIL_API_URL.DELINK_ACCOUNT(providerId),
    );
    try {
      this.getLinkedAccount();
      return Promise.resolve(result?.data);
    } catch {
      return Promise.reject();
    }
  }

  @computed
  public get getLocationData() {
    return this.linkedAccountData;
  }
}

const linkedAccountStore = new LinkedAccountStore();

export const linkedAccountStoreContext = createContext(linkedAccountStore);
export default linkedAccountStore;
