import {
  Button,
  Col,
  Form,
  Input,
  message,
  notification,
  Row,
  Select,
} from "antd";
import ButtonLink from "components/ButtonLink";
import { COUNTRY_DATA, PhoneCode } from "components/PhoneCode";
import SaveButtonText from "components/SaveButtonText";
import { bizIcons } from "constants/bizConstants";
import { Country } from "country-state-city";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useCurrentUser } from "scripts/custom-hooks/useCurrentUser";
import helpers from "scripts/helpers";
import employeeStore, { EmployeeType } from "scripts/mobx/employeeStore";
import { Roles } from "scripts/mobx/userStore";

const { Option } = Select;

interface IAddLinkedKidzbiz extends RouteComponentProps {
  onBack: () => void;
}

const AddEmployees = inject()(
  observer((props: IAddLinkedKidzbiz) => {
    const { onBack } = props;
    const { t } = useTranslation("base");

    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [form] = Form.useForm();
    const user = useCurrentUser();

    const onFinish = (values: any) => {
      setIsLoading(true);
      const country = Country.getCountryByCode(values?.codeMobile);
      const codeMobile = values?.codeMobile ? `+${country?.phonecode}` : "+852";
      const param: EmployeeType = {
        accessType: values?.accessType || Roles.PARTIAL,
        email: values?.email || "",
        firstName: values?.firstName || "",
        lastName: values?.lastName || "",
        mobile: values?.mobile ? codeMobile + values?.mobile : "",
      };
      form
        .validateFields(Object.keys(values))
        .then(async () => {
          employeeStore
            .addEmployee(param)
            .then(() => {
              message.success(
                t("messages.employee_account_created", {
                  defaultValue: "Employee account created",
                }),
              );
              onBack();
            })
            .catch((error) => {
              helpers.throwErrors(
                t,
                ["com.bizibuz.core.service.user.EmailInUsedException"],
                error?.data?.error,
                error?.data?.message,
              );
            });
          setIsLoading(false);
        })
        .catch((e) => {
          const { _message } = e;
          console.log(e);
          message.error(_message || t("server_errors." + "Bad Request"));
          setIsLoading(false);
        });
    };

    const validateForm = useCallback(
      (field?: string) => {
        form
          .validateFields(field ? [field] : undefined)
          .then(
            (r) => {
              setIsDisabled(false);
            },
            (rej) => {
              setIsDisabled(true);
            },
          )
          .catch((e) => {
            const { _message } = e;
            message.error(
              _message ||
                t("messages.some_error_occurred", {
                  defaultValue: "Some error occurred",
                }),
            );
          });
      },
      [isDisabled],
    );

    const initialValues = useMemo(() => {
      const country = COUNTRY_DATA.find((item) =>
        item.name.includes(user?.providerAttributes?.country),
      );

      const mCountryCode = country?.isoCode ?? "HK";

      return {
        codeMobile: mCountryCode,
      };
    }, [user]);

    return (
      <div>
        <div className="content-wrapper content-with-table">
          <ButtonLink onClick={() => onBack()}>
            <i className={`${bizIcons.ARROWLEFT} fs-20 mr-8`} />
            {t("kidzbiz.backToKidzbiz")}
          </ButtonLink>
          <div className="auth-header bsl-4">
            <div>
              <span className={"auth-header_title"}>
                {t("kidzbiz.employees")}
              </span>
            </div>
          </div>
          <div className="auth-form">
            <Form
              form={form}
              onLoad={() => validateForm()}
              name="normal_login"
              className="form-grid-layout"
              initialValues={initialValues}
              onFinish={onFinish}
            >
              <Row gutter={24}>
                <Col xs={24} md={12}>
                  <div className="bsl-4">
                    <div className="label-wrapper">
                      <label htmlFor="firstName">
                        {t("kidzbiz.firstName")}*
                      </label>
                    </div>

                    <Form.Item
                      name="firstName"
                      rules={[
                        {
                          required: true,
                          message: t("messages.required_field"),
                        },
                        {
                          pattern: /^[a-zA-Z ]+$/,
                          message: t("messages.contain_only_letters", {
                            name: "First name",
                          }),
                        },
                      ]}
                    >
                      <Input
                        id={"firstName"}
                        maxLength={45}
                        placeholder={t("kidzbiz.firstName")}
                        readOnly={isLoading}
                        onBlur={() => validateForm("firstName")}
                        onPressEnter={() => validateForm("firstName")}
                        size={"large"}
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col xs={24} md={12}>
                  <div className="bsl-4">
                    <div className="label-wrapper">
                      <label htmlFor="lastName">{t("kidzbiz.lastName")}*</label>
                    </div>

                    <Form.Item
                      name="lastName"
                      rules={[
                        {
                          required: true,
                          message: t("messages.required_field"),
                        },
                        {
                          pattern: /^[a-zA-Z ]+$/,
                          message: t("messages.contain_only_letters", {
                            name: "Last name",
                          }),
                        },
                      ]}
                    >
                      <Input
                        id={"lastName"}
                        maxLength={45}
                        placeholder={t("kidzbiz.lastName")}
                        readOnly={isLoading}
                        onBlur={() => validateForm("lastName")}
                        onPressEnter={() => validateForm("lastName")}
                        size={"large"}
                      />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={24} md={12}>
                  <div className="bsl-4">
                    <div className="label-wrapper">
                      <label htmlFor="email">{t("kidzbiz.email")}*</label>
                    </div>

                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: t("messages.required_field"),
                        },
                        {
                          pattern:
                            /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
                          message: t("messages.invalid_email_format"),
                        },
                      ]}
                    >
                      <Input
                        id={"email"}
                        maxLength={45}
                        placeholder={t("kidzbiz.email")}
                        readOnly={isLoading}
                        size={"large"}
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item className="bsl-4">
                    <div className="label-wrapper">
                      <label htmlFor="mobile">
                        {t("kidzbiz.mobilePhone")}*
                      </label>
                    </div>

                    <Form.Item
                      name="mobile"
                      rules={[
                        {
                          required: true,
                          message: t("messages.required_field", {
                            defaultValue: "Please fill in all required fields",
                          }),
                        },
                        {
                          min: 8,
                          message: t("messages.employee_mobile_invalid", {
                            min: 8,
                          }),
                        },
                      ]}
                    >
                      <Input
                        maxLength={8}
                        placeholder={t("signUp.step2.enterMobilePhone", {
                          defaultValue: "Enter mobile phone",
                        })}
                        readOnly={isLoading}
                        onKeyPress={(e: any) => {
                          if (e.key) {
                            if (!new RegExp(/^[0-9]$/g).test(e.key)) {
                              e.preventDefault();
                            }
                          }
                        }}
                        prefix={
                          <>
                            <i className={`${bizIcons.SMARTPHONE} mr-6`} />
                            <PhoneCode name="codeMobile" disabled />
                          </>
                        }
                        size={"large"}
                      />
                    </Form.Item>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={24} md={12}>
                  <div className="bsl-4">
                    <div
                      className="auth-header label-wrapper"
                      style={{ marginBottom: 5 }}
                    >
                      <label htmlFor="accessType">
                        {t("kidzbiz.accessType")}
                      </label>
                      {/* <a className="link" onClick={goToFAQHandler} style={{color: "#222222", lineHeight: "20px"}}>
                      {t("kidzbiz.goToFAQs")}
                      </a> */}
                    </div>
                    <Form.Item
                      name="accessType"
                      rules={[
                        {
                          required: true,
                          message: t("messages.required_field", {
                            defaultValue: "Please fill in all required fields",
                          }),
                        },
                      ]}
                    >
                      <Select
                        placeholder={t("kidzbiz.accessType")}
                        optionFilterProp="children"
                      >
                        <Option value={Roles.FULL}>{t("kidzbiz.full")}</Option>
                        <Option value={Roles.PARTIAL}>
                          {t("kidzbiz.partial")}
                        </Option>
                      </Select>
                    </Form.Item>
                  </div>
                </Col>
              </Row>

              <Row gutter={24}>
                <Col xs={24} md={12}>
                  <Form.Item>
                    <Button
                      className="full-width fs-16 tsl-4"
                      type="primary"
                      htmlType="submit"
                      loading={isLoading}
                      icon={
                        <SaveButtonText>
                          {t("kidzbiz.addEmployee")}
                        </SaveButtonText>
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    );
  }),
);

export default withRouter(AddEmployees);
