import { useCallback, useEffect, useState } from "react";

export const useIsCollapsed = () => {
  const [collapsed, setCollapsed] = useState(false);

  const onResize = useCallback(() => {
    if (!collapsed && window.innerWidth <= 1100) {
      setCollapsed(true);
    }
    if (collapsed && window.innerWidth > 1100) {
      setCollapsed(false);
    }
  }, [collapsed]);

  useEffect(() => {
    onResize();
    addEventListener("resize", onResize);

    return () => removeEventListener("resize", onResize);
  }, [collapsed]);

  return collapsed;
};