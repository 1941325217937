import { AppRoutes } from "helpers/app.routes";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { CourseStore } from "scripts/mobx/courseStore";
import { LayoutPage } from "../../components/Layout";
import { ContentWithTableCourses } from "./ContentWithTableCourses";
import { CreateCoursesWrapper } from "./CreateCoursesWrapper";
import "./index.less";

interface IProps extends RouteComponentProps {
  coursesStore?: CourseStore;
}
//coursesStore
const Courses = inject("userStore")(
  observer((props: IProps) => {
    const { match, history, coursesStore } = props;
    // @ts-ignore
    const { action, id } = match.params;

    const createCourseHandler = () => {
      history.push(AppRoutes.courseCreate);
    };

    const backToCourseHandler = () => {
      history.push(AppRoutes.courses);
    };
    const goToFAQHandler = () => {
      // history.push(AppRoutes.faqDetail("courses1"));
      window.open(AppRoutes.faqDetail("courses1"), "_blank");
    };

    return (
      <LayoutPage defMenuItem={"courses"} className="layout-courses">
        {!action && (
          <ContentWithTableCourses createCourseHandler={createCourseHandler} />
        )}

        {!!action && (action === "create" || action === "edit") && (
          <CreateCoursesWrapper
            backToCourseHandler={backToCourseHandler}
            goToFAQHandler={goToFAQHandler}
            isEdit={action === "edit"}
            editId={action === "edit" ? id : undefined}
            isLoading={false}
          />
        )}
      </LayoutPage>
    );
  }),
);

export default withRouter(Courses);
