import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Divider, Typography } from "antd";
import TableComponent from "../../../components/TableComponent/TableComponent";
import {
  tablePayViewCustomerColumns,
  tablePayViewCustomerCourseColumns,
  tablePayViewCustomerCourseData,
  tablePayViewCustomerData,
} from "../tableGenerator";
import ButtonLink from "../../../components/ButtonLink";
import { bizIcons } from "../../../constants/bizConstants";
import { PayViewCustomerCourseComponent } from "./Course";
import { useRoles } from "scripts/custom-hooks/useCurrentUser";
import { Roles } from "scripts/mobx/userStore";
import "./index.less";
const { Title } = Typography;

interface IProps {
  t: any;
  _data: any;
  exportHandler: () => void;
}

const PayViewCustomerComponent = observer((props: IProps) => {
  const { t, _data, exportHandler } = props;
  const roles = useRoles();
  return (
    <div className="">
      <div className="bsl-4 flex">
        <div>
          <div>
            <Title level={2}>{_data?.customerName}</Title>
          </div>
        </div>
      </div>
      <div style={{ paddingTop: "13px", marginTop: "16px", fontWeight: 500 }}>
        {roles !== Roles.PARTIAL && (
          <ButtonLink onClick={exportHandler}>
            <i
              className={`${bizIcons.FILEDOWNLOAD}`}
              style={{ marginRight: "8px", fontSize: "16px" }}
            />
            {t("payments.export_details")}
          </ButtonLink>
        )}
      </div>
      <Divider style={{ marginBottom: "0px" }} />
      <div className="biz-payment-info bsl-4">
        <TableComponent
          broken={true}
          grid={true}
          pagination={false}
          propsTableColumns={tablePayViewCustomerColumns(t)}
          propsTableData={tablePayViewCustomerData(_data)}
        />
      </div>
      {/* {_data?.courses?.map((el: any) => (
        <div key={el.id}>
          <PayViewCustomerCourseComponent
            t={t}
            data={el}
            type={_data?.transactionType}
          />
          <Divider />
        </div>
      ))} */}
      <div className="tsl-3">
        <PayViewCustomerCourseComponent
          t={t}
          data={_data}
          type={_data?.transactionType}
        />
        <Divider />
      </div>
    </div>
  );
});

export default PayViewCustomerComponent;
