import React from "react";

let bizcharts: any;
// @ts-ignore
if (process.browser) {
  // tslint:disable-next-line:no-var-requires
  bizcharts = require("bizcharts");
}

interface IProps {
  colors?: string[];
  data?: any;
  autoFit?: boolean;
}

const CustomerCharacteristicsChart = (props: IProps) => {
  const { data } = props;

  // const averageData = data?.averageNumberOfCoursesPurchasedPerMonth;
  const averageData = data?.averageNumberOfCoursesPurchasedPerMonth?.map(
    (el: any) => {
      // return { numberOfCourses: Math.random() * 1200, ...el };
      return { numberOfCourses: el.value, ...el };
    },
  );
  const _data = data?.averageTenureAndAverageSpendByMonth;
  const max = data?.averageTenureAndAverageSpendByMonth?.reduce(
    (a: any, b: any) => {
      return Math.max(a, b?.value || 0);
    },
    0,
  );

  const scale = {
    numberOfCourses: {
      alias: "Average number of courses purchased per month",
      min: 0,
      max,
    },
    value: {
      min: 0,
      max,
    },
  };

  const colors = props.colors || ["#5D7092", "#6DC8EC", "#5D7092", "#F6BD16"];
  const chartProps =
    props.autoFit === true
      ? {
          autoFit: true,
          key: "fit",
        }
      : {
          key: "no-fit",
          width: 600,
          forceFit: true,
        };

  return (
    <div className={"chart-wrapper"}>
      {/*// @ts-ignore*/}
      {process.browser && (
        <bizcharts.Chart
          {...chartProps}
          height={400}
          containerStyle={{}}
          scale={scale}
        >
          <bizcharts.Legend visible={false} />
          <bizcharts.Tooltip shared={true} />
          <bizcharts.View data={_data}>
            <bizcharts.Interval
              adjust={[
                {
                  type: "dodge",
                  marginRatio: 0,
                  dodgeBy: "item",
                },
              ]}
              color={"item"}
              position="axisValue*value"
            />
            <bizcharts.Legend visible={false} />
          </bizcharts.View>
          <bizcharts.View data={averageData}>
            <bizcharts.Line
              position="axisValue*numberOfCourses"
              color={colors[2]}
              // shape="smooth"
              size={3}
            />
            <bizcharts.Point
              position="axisValue*numberOfCourses"
              color={colors[2]}
              size={5}
              shape="circle"
            />
          </bizcharts.View>
        </bizcharts.Chart>
      )}
    </div>
  );
};

export default CustomerCharacteristicsChart;
