import { Badge, Divider, Layout, Menu, Popover, Spin } from "antd";
import { LayoutProps } from "antd/lib/layout";
import { bizIcons } from "constants/bizConstants";
import { AppRoutes } from "helpers/app.routes";
import { setConfigAxios } from "helpers/requestApi";
import Cookies from "js-cookie";
import { inject, observer } from "mobx-react";
import { NotificationsBlock } from "pages/notifications/NotificationsBlock";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import {
  useCurrentUser,
  useCurrentVerify,
  userBlocked,
  useRoles,
} from "scripts/custom-hooks/useCurrentUser";
import bookingsStore from "scripts/mobx/bookingsStore";
import courseStore from "scripts/mobx/courseStore";
import customersStore from "scripts/mobx/customersStore";
import notificationsStore from "scripts/mobx/notificationsStore";
import reviewsStore from "scripts/mobx/reviewsStore";
import userStore, { Roles } from "scripts/mobx/userStore";
import logoHeader from "../../assets/logo-header.svg";
import logo from "../../assets/logo.svg";
import userIcon from "../../assets/user-icon.svg";
import keycloak from "../../helpers/keycloak";
import { useIsCollapsed } from "../../scripts/custom-hooks/useCollapseByWidth";
import { UpScrollArrow } from "../UpScrollArrow";
import "./index.less";
import LingualBlock from "./LingualBlock";

const { Header, Content, Footer, Sider } = Layout;

interface ILayoutPageProps extends LayoutProps, RouteComponentProps {
  defMenuItem?: string;
  loading?: boolean;
  className?: string;
}

export const LayoutPage = withRouter(
  inject("userStore")(
    observer((props: ILayoutPageProps) => {
      const { t } = useTranslation("base");
      const user = useCurrentUser();
      const roles = useRoles();
      const isVerified = useCurrentVerify();
      const isBlocked = userBlocked();
      // userStore?.setProviderVerified({
      //   providerId: user?.providerAttributes?.id,
      //   providerStatus: "VERIFIED",
      // });
      const {
        children,
        history,
        defMenuItem,
        loading = false,
        className,
      } = props;
      const collapsedByWidth = useIsCollapsed();
      const [collapsed, setCollapsed] = useState(collapsedByWidth);

      const [faqCounter, setFaqCounter] = useState(
        Cookies.get("faq-read") ? 0 : 1,
      );

      const menuClickHandler = useCallback(() => {
        setCollapsed(!collapsed);
      }, [collapsed]);

      const menuButton = () => {
        return (
          <div className={"menu_button"} onClick={menuClickHandler}>
            <i className="ri-menu-2-fill" />
          </div>
        );
      };

      const clearFilter = (url: string) => {
        courseStore?.setFilters();
        bookingsStore?.setFilters();
        if (url === "/customers") {
          customersStore?.setFilters();
          customersStore?.clearFilter();
        }

        if (url === "/reviews") {
          reviewsStore?.setFilters();
          reviewsStore?.clearFilter();
        }

        if (url === "/") {
          userStore?.getUserProfile();
        }
      };

      const menuItemNavigate = (url: string) => () => {
        clearFilter(url);
        history.push(url);
        if (collapsed !== collapsedByWidth && !collapsedByWidth) {
          setCollapsed(collapsedByWidth);
        }
      };

      //ReadTutorial
      const readTutorial = () => {
        history.push("/tutorial");
      };
      const readFAQ = () => {
        if (faqCounter) {
          Cookies.set("faq-read", "true", { sameSite: "strict" });

          setFaqCounter(0);
        }
        history.push("/faqs");
      };

      useEffect(() => {
        if (collapsed !== collapsedByWidth) {
          setCollapsed(collapsedByWidth);
        }
      }, [collapsedByWidth]);

      useEffect(() => {
        if (collapsedByWidth && !collapsed) {
          document.body.style.position = "fixed";
          document.body.style.top = `-${window.scrollY}px`;
        } else if (!collapsedByWidth || (collapsedByWidth && collapsed)) {
          document.body.style.position = "";
          document.body.style.top = "";
        }
      }, [collapsed]);

      const handleLogout = () => {
        Cookies.set("react-token", "undefined");
        setConfigAxios();
        keycloak.logout();
      };
      const firstName = user?.userAttributes?.firstName || "";
      const lastName = user?.userAttributes?.lastName || "";

      const fullName = firstName + " " + lastName;

      return (
        <>
          {collapsedByWidth && !collapsed && (
            <div
              className={"layout-overlay"}
              onClick={() => setCollapsed(true)}
            />
          )}
          <Sider
            breakpoint="xl"
            className={`layout-sider ${
              !collapsedByWidth ? "pos-relative" : "pos-absolute"
            }`}
            collapsedWidth="0"
            theme={"light"}
            trigger={null}
            width="256px"
            collapsible={true}
            collapsed={collapsed}
            onCollapse={(collapsed, type) => {
              // console.log(collapsed, type);
            }}
          >
            <div className="sider-logo">
              <img
                src={logo}
                alt="logo"
                className={"cursor-pointer"}
                onClick={() => {
                  history.push("/");
                }}
              />
            </div>
            <Menu mode="inline" defaultSelectedKeys={[defMenuItem || ""]}>
              <Menu.Item
                key="kidzbiz"
                icon={<i className="ri-building-4-line mr-10" />}
                onClick={menuItemNavigate("/")}
              >
                {t("menu.kidzbiz")}
              </Menu.Item>
              <Menu.Item
                key="courses"
                icon={<i className="ri-book-open-line mr-10" />}
                onClick={menuItemNavigate("/courses")}
              >
                {t("menu.courses")}
              </Menu.Item>
              {(isVerified || isBlocked) && (
                <Menu.Item
                  key="bookings"
                  icon={<i className="ri-shopping-bag-2-line mr-10" />}
                  onClick={menuItemNavigate("/bookings")}
                >
                  {t("menu.bookings")}
                </Menu.Item>
              )}
              {(isVerified || isBlocked) && (
                <Menu.Item
                  key="customers"
                  icon={<i className="ri-team-line mr-10" />}
                  onClick={menuItemNavigate("/customers")}
                >
                  {t("menu.customers")}
                </Menu.Item>
              )}
              {isVerified && (
                <Menu.Item
                  key="promotions"
                  icon={<i className="ri-gift-2-line mr-10" />}
                  onClick={menuItemNavigate("/promotions")}
                >
                  {t("menu.promotions")}
                </Menu.Item>
              )}
              {isVerified && (
                <Menu.Item
                  key="reviews"
                  icon={<i className="ri-shield-star-line mr-10" />}
                  onClick={menuItemNavigate(AppRoutes.reviews)}
                >
                  {t("menu.reviews")}
                </Menu.Item>
              )}
              {(isVerified || isBlocked) && (
                <Menu.Item
                  key="payments"
                  icon={<i className="ri-bank-card-line mr-10" />}
                  onClick={menuItemNavigate("/payments")}
                >
                  {t("menu.payments")}
                </Menu.Item>
              )}
              {/* {isVerified && roles !== Roles.PARTIAL && (
                <Menu.Item
                  key="reports"
                  icon={<i className="ri-folder-chart-line mr-10" />}
                  onClick={menuItemNavigate("/reports")}
                >
                  {t("menu.reports")}
                </Menu.Item>
              )} */}
            </Menu>
            {/* <LingualBlock className={"sider-language ml-16"} /> */}
          </Sider>
          <Layout>
            <Header className="site-layout-sub-header-background layout-header">
              <div className={`left-side ${collapsed ? "collapsed" : ""}`}>
                {collapsedByWidth && (
                  <img
                    src={logoHeader}
                    alt={"logo-header"}
                    className={"cursor-pointer"}
                    onClick={() => {
                      history.push("/kidzbiz");
                    }}
                  />
                )}
                {collapsedByWidth && menuButton()}
              </div>

              <div className={`right-side`}>
                {/* <LingualBlock className={"sider-language ml-16"} /> */}

                <div className={"flex-center"} onClick={readTutorial}>
                  <Badge count={0}>
                    <div className="head-item" onClick={readTutorial}>
                      <i className="ri-movie-line" />
                      {!collapsedByWidth && (
                        <span className={"header-email"}>
                          {t("menu.tutorial")}
                        </span>
                      )}
                    </div>
                  </Badge>
                </div>
                {isVerified && (
                  <div className={"flex-center"} onClick={readFAQ}>
                    <Badge count={0}>
                      <div
                        className="head-item"
                        // onClick={readFAQ}
                        style={{ fontSize: "22px" }}
                      >
                        <i className="ri-question-line" />
                        {!collapsedByWidth && (
                          <span className={"header-email"}>
                            {t("menu.faqs")}
                          </span>
                        )}
                      </div>
                    </Badge>
                  </div>
                )}

                {isVerified && <NotificationsBlock />}
                <Popover
                  placement="bottomRight"
                  content={
                    <div style={{ width: "184px" }}>
                      <div className={"popover-button"}>
                        <Link to="/account-settings">
                          <i
                            className="ri-settings-3-line mr-10"
                            style={{ fontSize: 18 }}
                          />
                          {t("accountSettings.accountSettings2", {
                            defaultValue: "Account settings",
                          })}
                        </Link>
                      </div>
                      <Divider style={{ margin: 0 }} />
                      <div className={"popover-button"}>
                        <Link to="/account-settings/notifications">
                          <i
                            className="ri-notification-2-line mr-10"
                            style={{ fontSize: 17 }}
                          />
                          {t("notifications.title")}
                        </Link>
                      </div>
                      <Divider style={{ margin: 0 }} />
                      <div className={"popover-button"}>
                        <div
                          onClick={handleLogout}
                          className="flex-align-items"
                        >
                          <i
                            className={`${bizIcons.LOGOUT} mr-10`}
                            style={{ fontSize: 18 }}
                          />
                          {t("bizBase.logout", { defaultValue: "Logout" })}
                        </div>
                      </div>
                    </div>
                  }
                  trigger="click"
                >
                  <div className={"icon-block"} style={{ marginLeft: "10px" }}>
                    <img src={userIcon} alt={"user-logo-header"} />
                    {!collapsedByWidth && (
                      <span className={"header-email"}>{fullName}</span>
                    )}
                  </div>
                </Popover>
              </div>
            </Header>
            <Content
              className={`layout-content layout-content-with-menu ${className}`}
            >
              <Spin spinning={!!loading}>
                <div className="site-layout-background layout-content-bg">
                  {children}
                </div>
              </Spin>
            </Content>
            <Footer className="layout-footer">
              <div className={"bizi-footer-texts"}>
                {` bizibuz.com © ${new Date().getFullYear()}. All rights reserved. `}
              </div>
              <div className={"bizi-footer-links"}>
                <Link
                  style={{
                    color: "#FFFFFF",
                    opacity: 0.65,
                    marginLeft: "16px",
                  }}
                  to="/bizibuz-policies"
                  target="_blank"
                >
                  {t("footer.bizibuzPolicies", {
                    defaultValue: "Bizibuz Policies",
                  })}
                </Link>{" "}
                <Link
                  style={{
                    color: "#FFFFFF",
                    opacity: 0.65,
                    marginLeft: "16px",
                  }}
                  to="/bizibuz-policies/contact-us"
                >
                  {t("footer.contactUs", { defaultValue: "Contact Us" })}
                </Link>
              </div>
              <div className="layout-footer-follow">
                <a
                  href="https://twitter.com/bizibuz"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className={`${bizIcons.TWITTER} mr-16 ml-16 fs-22`} />
                </a>
                <a
                  href="https://www.facebook.com/Bizibuz-106830877941018"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className={`${bizIcons.FACEBOOK} mr-16 fs-22`} />
                </a>
                <a
                  href="https://www.instagram.com/bizibuz/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className={`${bizIcons.INSTAGRAM} mr-16 fs-22`} />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCjtyRR-AdkzYPMLF_txrohw"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className={`${bizIcons.YOUTUBE} mr-16 fs-22`} />
                </a>
                <a
                  href="https://www.linkedin.com/company/69669897/admin/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className={`${bizIcons.LINKEDIN} fs-22`} />
                </a>
              </div>
              <UpScrollArrow />
            </Footer>
          </Layout>
        </>
      );
    }),
  ),
);
export const LayoutBase = (props: LayoutProps) => {
  return <Layout className="layout">{props.children}</Layout>;
};
