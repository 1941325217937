import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { userStoreContext } from "scripts/mobx/userStore";

let bizcharts: any;
// @ts-ignore
if (process.browser) {
  // tslint:disable-next-line:no-var-requires
  bizcharts = require("bizcharts");
}

interface IProps {
  colors?: string[];
  data?: any[];
  autoFit?: boolean;
}

const AgeSpentRatioChart = (props: IProps) => {
  const { data } = props;
  const userStore = useContext(userStoreContext);

  const chartProps =
    props.autoFit === true
      ? {
          autoFit: true,
          key: "fit",
        }
      : {
          key: "no-fit",
          width: 600,
          forceFit: true,
        };

  return (
    <div className={"chart-wrapper"}>
      {/*// @ts-ignore*/}
      {process.browser && (
        <bizcharts.Chart
          {...chartProps}
          height={400}
          padding="auto"
          data={data}
          // scale={getScale()}
          // data={data}
        >
          {/*<bizcharts.Axis name={'numOfCustomers'} label={null} />*/}
          <bizcharts.Legend visible={false} />
          <bizcharts.Tooltip shared={true} name={""} value={false} />
          <bizcharts.Geom
            type="interval"
            position="month*value"
            color="item"
            // label={[
            //   "value",
            //   {
            //     position: "middle",
            //     offset: 0,
            //     // style: { fill: "#fff" },
            //     // layout: { type: "limit-in-shape" },
            //   },
            // ]}
            tooltip={[
              "month*value*item*percent",
              (month: any, value: string, item: any, percent: string) => {
                return {
                  name: item,
                  value: percent + "% / " + value + userStore.currency,
                };
              },
            ]}
            adjust={[
              {
                type: "stack",
              },
            ]}
          />
        </bizcharts.Chart>
      )}
    </div>
  );
};

export default observer(AgeSpentRatioChart);
