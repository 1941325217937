import { Typography } from "antd";
import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

interface IProps {
  title: string;
  seoKeyword?: string[];
  seoDescription?: string;
  ogImage?: string;
}

export const HeadSeo = (props: IProps) => {
  const { title, seoDescription, seoKeyword, ogImage } = props;
  const history = useHistory();
  return (
    <Helmet>
      <title>{title}</title>
      <link
        rel="canonical"
        href={window?.location?.href || history?.location?.pathname}
      />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content="vi_VN" />
      <meta property="og:site_name" content="Bảo Hiểm Nhân Thọ MB Ageas Life" />
      <meta name="robots" content="index,follow" />
      <meta name="keywords" content={seoKeyword?.join(", ") || ""} />
      <meta name="description" content={seoDescription || ""} />
      <meta
        property="og:url"
        content={window?.location?.href || history?.location?.pathname || ""}
      />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={seoDescription || ""} />
      <meta property="og:image" content={ogImage || ""} />
    </Helmet>
  );
};
