import React, { useContext } from "react";
import { LayoutPage } from "../../components/Layout";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { providerStoreContext } from "../../scripts/mobx/providerStore";
import { ContentWithTableReviews } from "./ContentWithTableReviews";
import { ProviderReviewsStore } from "scripts/mobx/providerReviewsStore";

interface IProps {
  providerReviewsStore?: ProviderReviewsStore;
}
const Reviews = inject("providerReviewsStore")(
  observer((props: IProps) => {
    return (
      <LayoutPage defMenuItem={"reviews"}>
        <ContentWithTableReviews />
      </LayoutPage>
    );
  }),
);

export default Reviews;
