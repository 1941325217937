import React from "react";
import { Typography } from "antd";
const { Title } = Typography;

const TermsAndConditions = () => {
  return (
    <>
      <Title>Terms and conditions</Title>
      <div className={"bizibuz-policies-lists"}>
        <ol>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
              Your agreement
            </Title>
            <ol>
              <li>
              Bizibuz Hong Kong Limited and its affiliated companies (“we”, “us”, “our” or “Bizibuz”) is the owner and operator of www.bizibuz.com (the “Website”), its various subdomains including but not limited to the Kidzbiz subdomain located at kidzbiz.bizibuz.com (the “Kidzbiz Portal”) and potentially also other Bizibuz branded apps (together, the “Bizibuz Platform”). Please read these Terms and Conditions carefully before using the Bizibiz Platform and the services offered by Bizibuz or the third party education companies or operators that have a Bizibuz login (the “Activity Providers”) (together, the “Services”). “User” when used in these Terms and Conditions refers to any person (excluding Activity Providers) that accesses the Bizibuz Platform. “You” and “Your” refer collectively to Activity Providers and Users. Any violation of these Terms and Conditions may result in, among other things, termination or suspension of your rights to use the Bizibuz Platform. These Terms and Conditions apply to all Bizibuz Activity Provider and Users worldwide.
              </li>
            </ol>
          </li>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
              Change of Terms and Conditions
            </Title>
            <ol>
              <li>
              Bizibuz reserves the right, at its sole discretion, to modify any part of these Terms and Conditions at any time without prior notice. If the Terms and Conditions change, Bizibuz will post the modifications to this page and will indicate at the bottom of this page the date on which these Terms and Conditions were last revised. It is your responsibility to regularly check the Terms and Conditions to ascertain if there are any modifications to these Terms and Conditions and to review such changes.
              </li>

              <li>
              Your continued use of the Bizibuz Platform after such modifications have been made constitutes your acceptance of the revised Terms and Conditions. If you do not agree to abide by the revised Terms and Conditions, do not use or access or continue to use the Bizibuz Platform and/or Services.
              </li>
              <li>
              When using the Bizibuz Platform and Services, you shall be subject to additional terms applicable to such Services that may be posted on pages relating to such Services from time to time and the privacy policy adopted by Bizibuz (the “Privacy Policy”). All such terms are hereby expressly incorporated by reference in these Terms and Conditions.
              </li>
            </ol>
          </li>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
              Description of Services
            </Title>
            <ol>
              <li>
              Bizibuz is a digital marketplace for children’s extracurricular activities. The Bizibuz Platform allows Users to search for Activity Providers by utilizing a range of filters, compare Activity Providers across various qualitative and quantitative factors, book and purchase educational activities offered for sale by Activity Providers, and centrally manage payments and schedules. The Bizibuz Platform allows Activity Providers to post information regarding their company and/or educational activities with the goal of raising awareness, attracting new customers and selling educational activities.
              </li>

              <li>
              The Services include, but are not limited to, activities performed on the Bizibuz Platform by Bizibuz or Activity Providers as well as educational activities purchased on the Bizibuz Platform but performed by Activities Providers in a forum away from the Bizibuz Platform. 
              </li>
            </ol>
          </li>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
              Use of the Bizibuz Platform and Services
            </Title>
            <ol>
              <li>
              As a condition of the use of the Bizibuz Platform and its Services, you warrant as follows: <br />
              (i)  all information supplied by you on this Website is true, accurate, current and complete;<br />
(ii)  if you register a Bizibuz account, you shall safeguard your account information and shall supervise and be completely responsible for any use of such account by anyone other than you;<br />
(iii)  you are 18 years of age or older to register for an account and contribute to our Website; and<br />
(iv)  you possess the legal authority to enter into this Agreement and to use this Website in accordance with these Terms and Conditions herein.
              </li>

              <li>
              You are not eligible to use the Website unless you are at least eighteen (18) years old and otherwise have the legal capacity to enter into a binding contract. In event that a minor or any other individual conducts any interactions on our Website through the means of your account, it will render you liable. If you authorize a third person to use your account, you are responsible for ensuring that they are aware of these Terms and Conditions and other applicable terms and conditions for the Services, and that they comply with them.
              </li>
              <li>
              As a condition of the use of the Bizibuz Platform and its Services, you further agree not to: <br />
              (i)  access, monitor or copy any content or information of this Website using any robot, spider, scraper or other automated means or any manual process for any purpose without our express written permission; <br />
(ii)  violate the restrictions in any robot exclusion headers on this Website or bypass or circumvent other measures employed to prevent or limit access to this Website; <br />
(iii)  take any action that imposes, or may impose, in our discretion, an unreasonable or disproportionately large load on our infrastructure; <br />
(iv)  deep-link to any portion of this Website for any purpose without our express written permission; <br />
(v)  "frame", "mirror" or otherwise incorporate any part of this Website into any other website without our prior written authorization; or <br />
(vi)  attempt to modify, translate, adapt, edit, decompile, disassemble, or reverse engineer any software programs used by Bizibuz in connection with the Website or the Services. <br />

              </li>
              <li>
              In addition, Bizibuz reserves the right to require prior written consent before you are allowed to link to the Bizibuz Platform, its content and information.
              </li>
              <li>
              Bizibuz reserves the right, at its sole discretion, to change, suspend or discontinue access to the Website and/or any Services at any time, including the availability of any feature, database or content. Bizibuz does not guarantee that the Services will always be available or uninterrupted, and will not be liable to you if for any reason the Services are unavailable at any time or for any period. Bizibuz may also impose limits or conditions on certain Services or restrict your access to this Website and the Services, at any time and for any reason, without notice or liability. Bizibuz reserves the right to refuse registration of or to cancel a Bizibuz account at its sole discretion. Upon account cancellation or blockage, your right to use the Services, access the Bizibuz Platform, and any content will immediately cease. All provisions of these Terms and Conditions which, by their nature, should survive termination, shall survive termination, including but not limited to ownership provisions, warranty disclaimers and limitations and exemptions of liability.
              </li>
            </ol>
          </li>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
              Bizibuz Platform and Content
            </Title>
            <ol>
              <li>
              The content and information on the Bizibuz Platform that pertains to Bizibuz or Activity Providers (including, but not limited to, messages, data, information, articles, images, illustrations, text, sound, photos, graphics, video, maps, icons, software, the look and feel of the Website including all page headers, custom graphics, button icons and scripts, service marks, trademarks and/or trade dress of Bizibuz, code and/or other material), as well as the infrastructure used to provide such content and information, is protected by copyright and other intellectual property rights as collective works and/or compilations, pursuant to applicable copyright laws, international conventions and other intellectual property laws. All intellectual property rights subsisting in respect of the Bizibuz Platform belong to Bizibuz or have been licensed to Bizibuz for use on the Bizibuz Platform. The copying, transmission, reproduction, replication, translation, publication, broadcast, modification, licensing, sale, creation of derivative works from, display, posting, storage or redistribution of the Website, its contents or any portion thereof is strictly prohibited without the prior written permission of Bizibuz or the express written consent of the respective intellectual property owners identified by copyright notice.
              </li>

              <li>
              Bizibuz cannot guarantee the identity of any other users with whom you may interact with in the course of using the Bizibuz Platform.  Bizibuz does not guarantee, represent, warrant or promise (whether expressly or impliedly) that any information howsoever provided is or remains accurate, complete and up-to-date, or fit or suitable for any purpose at any time. Bizibuz shall not be held responsible for the authenticity, quality or the accuracy of information provided by other Users and Activity Providers on the Website as such information is provided for convenience and information purposes only. Any reliance you place on the information on the Website is at your own risk and you will be solely responsible for any damage or loss to any party resulting therefrom.
              </li>
              <li>
              Bizibuz takes no responsibility and under no circumstances will Bizibuz be liable in any way for any activity by a User or Activity Provider on the Bizibuz Platform or content whatsoever posted, stored, uploaded by you or any third party, including but not limited to any errors or omissions, mistakes, defamation, slander, libel, omissions, falsehoods, obscenity, pornography or profanity in any content, or any loss or damage of the content ,or any loss or damage of any kind incurred in connection with the use of or exposure to any content posted, emailed, accessed, transmitted, or otherwise made available via the Bizibuz Platform.
              </li>
            </ol>
          </li>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
              User Submissions and Behaviour
            </Title>
            <ol>
              <li>
              A User who submits content on this Website, by email, postings reviews or comments, or uploading information on this Website or otherwise, including any reviews, photos, videos, questions, comments, reviews, suggestions, ideas or the like contained in any submissions (collectively, the “Submission”), grants Bizibuz and its affiliates a nonexclusive, worldwide, royalty-free, perpetual, transferable, irrevocable and fully sub-licensable right to use, reproduce, modify, adapt, translate, distribute, publish, create derivative works from and publicly display and perform such Submissions throughout the world in any media, now known or hereafter devised, for any purpose. The User agrees and authorizes Bizibuz to use relevant personal data in accordance with the Bizibuz Privacy Policy in effect from time to time. The User acknowledges and agrees that any Submission you have made on the Bizibuz Platform is non-confidential and non-proprietary. Bizibuz is under no obligation to offer you any payment for content that you submit or the opportunity to edit, delete or otherwise modify content once it has been submitted to the Bizibuz Platform. Bizibuz shall have no duty to attribute authorship of content to you.
              </li>

              <li>
              Specifically in relation to the posting of reviews by Users, you hereby grant each Activity Provider of the Bizibuz Platform a non-exclusive license to access any reviews you have posted through the Bizibuz Platform and to use, modify, reproduce, distribute, prepare derivative works of and display such reviews. Bizibuz shall not be obligated to enforce any form of attribution by third parties in relation to such reviews.
              </li>
              <li>
              You further grant Bizibuz the right to pursue at law any person or entity that violates your or Bizibuz’s rights in the Submission by a breach of these Terms and Conditions. Bizibuz may, if necessary, participate in the defense of any claim or action and any negotiations for settlement. You will not make any settlement that may adversely affect the rights or obligations of Bizibuz without Bizibuz’s prior written approval. Bizibuz reserves the right, at its expense and upon notice to you to assume exclusive defense and control of any claim or action.
              </li>
              <li>
              Bizibuz does not edit or have any obligation to vet any Submissions a User or Activity Provider uploads, posts or distributes on the Bizibuz Platform including through any chat rooms, bulletin boards or other communications forums, and will not be in any way responsible or liable for such information or opinions expressed. Bizibuz nevertheless reserves the right for any reason in its sole discretion to review the Submissions before posting on the Website, and remove any information, user messages and/or site content without notice to users or relevant Activity Providers.
              </li>
              <li>Use of the Bizibuz Platform including, but not limited to, use of discussion forums, bulletin boards, review services or other forums in which you or third parties may post or respond to reviews of the Activity Providers or other content, messages, and materials, is at your own risk. You are solely responsible for all Submissions that you upload, post, email, transmit or otherwise disseminate and are solely responsible for the consequences of posting or publishing your Submissions on the Bizibuz Platform. </li>
              <li>
              Bizibuz is not liable for any statements, representations or content provided by its Users or Activity Providers on the Bizibuz Platform. As a condition of the use of the Bizibuz Platform and its Services, you expressly agree not to post, upload to, transmit, distribute, store, create or otherwise publish through the Bizibuz Platform any Submissions that would: 
                <br />
                (i)  Infringe or violate the intellectual property rights or proprietary rights, rights of publicity or privacy, or other rights of any third party; <br />
(ii) Violate any law, statute, ordinance or regulation; <br />
(iii) Be false, misleading, harmful, patently offensive, fraudulent, deceptive, threatening, abusive, harassing, tortious, defamatory, vulgar, obscene, libellous, otherwise objectionable;  <br />
(iv) Involve contests, sweepstakes, barter, and pyramid schemes; <br />
(v) Constitute libel, or impersonation of any person or entity, including but not limited to other users or any employee or representative of Bizibuz;  <br />
(vi) Contain a virus, corrupted data, trojan horse, worm, time bomb, or other harmful computer code, file or program; <br />
(vii) Contain restricted content on hidden pages; or <br />
(viii) Contain content or links to content that, in the sole judgment of Bizibuz, (a) violates the previous subsections herein, (b) is objectionable, (c) is irrelevant, (d) which restricts or inhibits any other person from using or enjoying this Website, or (e) which may expose Bizibuz, its affiliates or users to any harm or liability of any type.

              </li>
              <li>
              Bizibuz shall not be liable under any circumstances for any direct, indirect, special, incidental or consequential damages, including and without limitation to, loss of data or profit, arising out of the use of or reliance on, or the inability to use, the Website, its contents, the Services, advice, products or any portion thereof, or for any mistakes, defamation, libel, slander, omissions, falsehoods, or profanity contained therewith. To the fullest extent possible pursuant to the applicable law, we disclaim all warranties, express or implied, with respect to the Website, its contents, the Services and each portion thereof, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose non-infringement or other violation of rights. We also do not endorse any opinion, recommendation, or advice given by users or Activity Providers.
              </li>
              <li>
              Bizibuz shall not be liable for any act or omission by Users or Activity Providers on the Bizibuz Platform or in connection with its Services. You represent, warrant and undertake to Bizibuz that you shall not: <br />
              (i) Conduct any fraudulent, abusive or otherwise illegal activity which may be grounds for termination of your rights to access or use the Bizibuz Platform and/or the Services; <br />
(ii) Post or transmit, or cause to be posted or transmitted any communication or solicitation designed or intended to obtain password, account, or private information from any other user of the Bizibuz Platform; <br />
(iii) Violate the security of any computer network, crack passwords or security encryption codes, transfer or store illegal material (including material that may be considered threatening or obscene), or engage in any kind of illegal activity that is expressly prohibited; <br />
(iv) Run maillist, listserv, or any other form of auto-responder, or “spam” on the Bizibuz Platform, or any processes that run or are activated while you are not logged on to the Bizibuz Platform, or that would otherwise interfere with the proper working of or place an unreasonable load on the Bizibuz Platform’s infrastructure; <br />
(v) Use manual or automated software, devices, or other processes to “crawl”, “scrape”, or “spider” any page of the Bizibuz Platform; or <br />
(vi) Decompile, reverse engineer, or otherwise attempt to obtain the source code of the Bizibuz Platform.  

              </li>
            </ol>
          </li>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
            Performance of Services by Activity Providers 
            </Title>
            <ol>
              <li>
              Bizibuz shall not be liable for any act or omission by Activity Providers in connection with the performance or delivery of Services purchased on the Bizibuz Platform wheresoever performance or delivery occurs. Bizibuz shall not be held responsible for the suitability, authenticity, safety or quality of such Services. Users are solely responsible for conducting their own due diligence on Activity Providers and selecting suitable educational activities for purchase on the Bizibuz Platform. 
              </li>

              <li>
              Users acknowledge that Bizibuz shall not be liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the performance or delivery of Services purchased on the Bizibuz Platform wheresoever performance or delivery occurs; or for any shortcomings of regulatory, legal or administrative standards of safety, licensing including employment qualifications, mistakes, defamation, libel, slander, omissions, falsehoods, or profanity by the Activity Provider or connected with their performance or delivery of Services.
              </li>
              <li>
              Whilst Activity Providers are required to provide Bizibuz with accurate, updated prices and availability information of the Services, Bizibuz cannot guarantee that all these data points are accurate and updated at all times.
              </li>
            </ol>
          </li>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
              Further Correspondence
            </Title>
            <ol>
              <li>
              By completing a booking of an educational activity on the Bizibuz Platform, a User agrees to receive confirmation messages of the booking and its acceptance, rejection or cancellation (in the form of emails, Whatsapp/SMS messages and/or app notifications), as well as an invitation to complete a course review after an activity is completed. Leaving a review is optional. For clarity, the confirmation and review emails are transactional and are not part of the newsletters or marketing mails from which you can unsubscribe. 
              </li>

              <li>
              Although we endeavour to deliver all correspondence including the confirmation and review messages, Bizibuz does not guarantee the delivery of all messages and is not liable, directly or indirectly, for any damage or loss caused or alleged to be caused in connection with the failure to deliver or receive such correspondence to either Users or Activity Providers. 
              </li>
            </ol>
          </li>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
            Additional User Representations, Warranties and Undertakings
            </Title>
            <ol>
              <li>
              You will be solely responsible for withholding, filing, and reporting all taxes, duties and other governmental assessments associated with your activity in connection with using the Bizibuz Platform and/or its Services.
              </li>
              <li>
              You will be solely responsible for creating backup copies of and replacing any content you post or store on this Website at your cost and expense.
              </li>
              <li>
              You will be solely responsible for maintaining the confidentiality of your password. You must notify Bizibuz immediately of any unauthorized use of your account, username or password and any other breach of security, and ensure that you log out of your account at the end of each session. You must take steps to ensure that others do not gain access to your password and account. Bizibuz is not responsible for any loss or damage arising from your failure to comply with this requirement or as a result of use of your account, username or password, either with or without your knowledge. However, you could be held liable for losses incurred by us or another party due to someone else using your account, username or password.
              </li>
              <li>
              You agree that no joint venture, agency, partnership, employment relationship, fiduciary duty, or special relationship exists between you and Bizibuz, its holding company, subsidiaries and/or affiliates as a result of these Terms and Conditions or for accessing and using the Bizibuz Platform.
              </li>
            </ol>
          </li>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
              Privacy Policy
            </Title>
            <ol>
              <li>
              Bizibuz believes in protecting the privacy of Users and Activity Providers. Please review Bizibuz’s current Privacy Policy which is hereby incorporated with reference to these Terms and Conditions. Your acceptance of these Terms and Conditions constitutes your acceptance and agreement to be bound by our Privacy Policy.
              </li>
            </ol>
          </li>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
              Indemnity
            </Title>
            <ol>
              <li>
              You will indemnify and hold Bizibuz, our holding companies, subsidiaries, affiliates, agents, partners, licensors, representatives, officers, directors and employees, third party providers (including our affiliates' respective officers, directors, agents, partners, employees, licensors, representatives, third party providers and Activity Providers), harmless from, including but not limited to all damages, liabilities, settlements, costs and attorney’s fees, claims or demands made by any third party due to or arising out of your access to the Bizibuz Platform, use of the Bizibuz Platform or its Services, your violation of these Terms and Conditions, or the infringement of any intellectual property or other right of any person or entity by you or any third party using your Bizibuz login. Bizibuz may, if necessary, participate in the defense of any claim or action and any negotiations for settlement. You will not make any settlement that may adversely affect the rights or obligations of Bizibuz without Bizibuz’s prior written approval. Bizibuz reserves the right, at its expense and upon notice to you, to assume exclusive defense and control of any claim or action.
              </li>
              <li>
              Activity Providers agree to hold Bizibuz harmless and indemnify against any comments, reviews or actions of users. You agree to indemnify Bizibuz from and against liability caused by any Activity Providers negligent performance of Services. We reserve the right, at our own expense, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, and in such case, you agree to fully cooperate with such defense and in asserting any available defenses
              </li>
            </ol>
          </li>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
              Exemption of Liability
            </Title>
            <ol>
              <li>
              In no event will Bizibuz, the Bizibuz Platform, its holding companies, subsidiaries, affiliates, officers, directors and/or employees be liable for any loss of profits or any indirect, consequential, special, incidental, or punitive damages arising out of, based on, or resulting from: (a) The Bizibuz Platform; or (b) These Terms and Conditions; or (c) Any breach of these Terms and Conditions by you or a third party; or (d) Use of the Bizibuz Platform or Services provided on the Bizibuz Platform by Bizibuz or any third party; or (e) Any actual or attempted communication or transaction between users, in each case, even if we have been advised of the possibility of such damages. 
              </li>
              <li>
              These limitations and exclusions apply without regard to whether the damages arise from:  <br />
              (i) Breach of contract; <br />
ii) Breach of warranty; <br />
(iii) Strict liability; <br />
(iv) Tort; <br />
(v) Negligence; or <br />
(vi) Any other cause of action, to the maximum extent that such exclusion and limitations are not prohibited by applicable law. 

              </li>
              <li>
              This Bizibuz Platform, including all content, software, functions, materials and information made available on or accessed through the Bizibuz Platform, is provided on an "as is" basis. To the fullest extent permissible by applicable law, Bizibuz makes no representations or warranties of any kind, either express or implied, including but not limited to the content on the Bizibuz Platform or the materials, information and functions made accessible through the Bizibuz Platform, for any of the Services or hypertext links to third parties or for any breach of security associated with the transmission of sensitive information through the Bizibuz Platform.
              </li>
              <li>
              You acknowledge and agree that any transmission to and from the Bizibuz Platform is not confidential and your communications may be read or intercepted by others. You further acknowledge and agree that by submitting communications to Bizibuz and by posting information on the Bizibuz Platform, no confidential, fiduciary, contractually implied or other relationship is created between you and Bizibuz other than pursuant to these Terms and Conditions. 
              </li>
              <li>
              You acknowledge and agree that you will not hold or seek to hold Bizibuz responsible for the content provided by any third party and you further acknowledge and agree that Bizibuz is not a party to any transaction in relation to the Services provided by any Activity Provider or third party. Bizibuz has no control over and does not guarantee the legality or safety of the performance of any Services provided by any Activity Provider or third party on the Bizibuz Platform. 
              </li>
            </ol>
          </li>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
              Remedies
            </Title>
            <ol>
              <li>
              If you are not satisfied with the Bizibuz Platform, do not agree with any part of these Terms and Conditions, or have any other dispute or claim with or against Bizibuz or another user of the Bizibuz Platform with respect to these Terms and Conditions or the Bizibuz Platform, your sole and exclusive remedy against Bizibuz is to discontinue use of the Bizibuz Platform. 
              </li>
            </ol>
          </li>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
              Interaction with Third Parties
            </Title>
            <ol>
              <li>
              The Website may contain hyperlinks or references to external third party websites including and without limitation to Activity Providers. Any such hyperlinks or reference is provided for your convenience only and does not constitute an endorsement or recommendation by Bizibuz of such third party websites or contents therein. We have no control over third party websites and accept no responsibility or liability for any content, material or information contained therein. Bizibuz shall not be liable for any material, links or information provided by Activity Providers, or for any content on external websites and the promotions or advertisements by third party providers who advertise on the Bizibuz Platform.
              </li>
              <li>
              You further acknowledge and agree that we shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, advertising, products, services or other materials available on or through any third party websites or for any mistakes, defamation, libel, slander, omissions, falsehoods, obscenity, pornography, or profanity contained therein. Your use of a third party site may however, be governed by the terms and conditions of that third party site. You should conduct whatever investigation you feel necessary or appropriate before proceeding with any online or offline transaction with any of these third parties.
              </li>
              <li>
              If there is a dispute between Users on this Website or between Users and any third party including Activity Providers, you understand and agree that Bizibuz is under no obligation to become involved. In the event that you have a dispute  with one or more other users or third parties, you hereby release Bizibuz, its holding companies, subsidiaries, affiliates, officers, directors, employees, agents, and successors in rights from claims, demands, and damages (actual and consequential), known and unknown, suspected or unsuspected, disclosed or undisclosed, arising out of or in any way related to such disputes.
              </li>
            </ol>
          </li>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
              Governing Law and Dispute Resolutions
            </Title>
            <ol>
            These Terms and Conditions shall be governed by and construed in accordance with the laws of the Hong Kong Special Administrative Region of the People’s Republic of China. Any disputes arising from this Agreement shall be resolved by arbitration administered by the Hong Kong International Arbitration Centre, under the Hong Kong International Arbitration Centre Administered Arbitration Rules then in force. The arbitration proceeds shall be conducted in the English language and the seat of arbitration is Hong Kong. 
            </ol>
          </li>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
              Miscellaneous
            </Title>
            <ol>
              <li>
              If any provision of these Terms and Conditions is found to be unenforceable or invalid, that provision shall be limited or eliminated to the minimum extent necessary so that these Terms and Conditions shall otherwise remain in full force and effect and as such, be enforceable.
              </li>
              <li>
              These Terms and Conditions are not assignable, transferable or sub-licensable by you expect with Bizibuz’s prior written consent, Bizibuz may transfer, assign or delegate these Terms and Conditions and its rights and obligations without prior notice to you.
              </li>
              <li>
              These Terms and Conditions have been drafted and shall be construed, in the English language. Any translation of these Terms and Conditions is solely for reference only. In the event of inconsistency between the English language version and a translated version, the English language version of these Terms and Conditions shall always prevail.
              </li>
            </ol>
          </li>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
              Contact Information
            </Title>
            <ol>
              <li>
              Please contact Bizibuz at info@bizibuz.com to report any violations of these Terms and Conditions or to ask questions relating to these Terms and Conditions.
              </li>
            </ol>
          </li>
        </ol>

        <div className={"tsl-3 bsl-3"}>Last updated on: 22 April 2021.</div>
      </div>
    </>
  );
};

export default TermsAndConditions;
