import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Typography } from "antd";
import TableComponent from "../../../components/TableComponent/TableComponent";
import { tableColumns, tableData } from "./tableGenerator";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { inject } from "mobx-react";
import reportsStore from "../../../scripts/mobx/reportsStore";
import { LocationSummaryFilters } from "./filters";
import { useTranslation } from "react-i18next";

const { Title } = Typography;

const Top3ByCustomersTableBlock = inject("reportsStore")(
  observer((props: RouteComponentProps) => {
    const { history } = props;
    const { t } = useTranslation();
    const [data, setData] = useState<any | undefined>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
      reportsStore.getTop3CustomersTable({}).then((el) => {
        // @ts-ignore
        setData(el.items);
      });
    }, []);

    const updateFilter = (filterObject?: any) => {
      setIsLoading(true);
      reportsStore
        ?.getTop3CustomersTable({ ...filterObject })
        .then((el) => {
          // @ts-ignore
          setData(el.items);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    return (
      <div className={"tab-table-block"}>
        <div className={"tab-table-block-head no-search"}>
          <Title className={"title"} level={2}>
            {t("reports.top_3_courses_by_amount_of_customers", {
              defaultValue:
                "Top 3 courses by amount of customers summary table",
            })}
          </Title>
          <LocationSummaryFilters t={t} onApply={updateFilter} />
        </div>
        <TableComponent
          broken={true}
          pagination={false}
          propsTableColumns={tableColumns(t)}
          propsTableData={tableData(data || [])}
        />
      </div>
    );
  }),
);

export default withRouter(Top3ByCustomersTableBlock);
