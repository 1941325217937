import React, { useContext, useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { ReportsStore } from "../../../../scripts/mobx/reportsStore";
import GreySquare from "../../../../components/GreySquare";
import emptyTableSVG from "../../../../assets/courses-empty.svg";
import { ChartFilters } from "./filters";
import OfflineOnlineSpendSplitByCategoryReport from "./OfflineOnlineSpendSplitByCategoryReport";
import { useTranslation } from "react-i18next";
import { languageStoreContext } from "scripts/mobx/languageStore";

interface IProps {
  autoFit?: boolean;
  colors?: string[];
  reportsStore?: ReportsStore;
}

const OfflineOnlineSpendSplitByCourseCategory = inject("reportsStore")(
  observer((props: IProps) => {
    const { autoFit } = props;
    const { reportsStore } = props;
    const { t } = useTranslation();
    const [data, setData] = useState<any[] | undefined>();
    // const [data, setData] = useState<ProviderReportCustomersAndRevenueListItemModel[] | undefined>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const language = useContext(languageStoreContext);
    const onHanleData = (data: any[]) => {
      setData(
        data?.map((el: any) => {
          return {
            ...el,
            month: `${el.year}-${String(el.month).padStart(2, "0")}`,
            item: t("categories." + el?.item),
            key: t("categories." + el?.key),
            axisValue: t("categories." + el?.axisValue),
          };
        }),
      );
    };

    useEffect(() => {
      reportsStore
        ?.getReportOfflineOnlineSpendSplitByCourseCategory({})
        .then((el) => {
          onHanleData(el?.items);
        });
    }, [language?.lang]);

    const updateFilter = (filterObject?: any) => {
      setIsLoading(true);
      reportsStore
        ?.getReportOfflineOnlineSpendSplitByCourseCategory({ ...filterObject })
        .then((el) => {
          onHanleData(el?.items);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    return (
      <div className={"chart-block tsl-4 bsl-4"}>
        <div className={"chart-block-header bsl-3"}>
          <div className={"chart-block-title title"}>
            {t("reports.customer_spend_for_offline_and_online_by_category", {
              defaultValue: "Customer spend for offline and online by category",
            })}
          </div>
          <ChartFilters t={t} onApply={updateFilter} />
        </div>
        <div className={"chart-wrapper"}>
          {data?.length && (
            <OfflineOnlineSpendSplitByCategoryReport
              autoFit={autoFit}
              data={data}
            />
          )}
          {!data?.length && <GreySquare imageSrc={emptyTableSVG} />}
        </div>
      </div>
    );
  }),
);

export default OfflineOnlineSpendSplitByCourseCategory;
