import React from "react";
import { Typography } from "antd";
const { Title } = Typography;

const ZhPrivacyPolicy = () => {
  return (
    <>
      <Title>隱私政策</Title>
      <div className={"bizibuz-policies-lists"}>
        <ol>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
            您的協議
            </Title>
            <ol>
              <li>
              Bizibuz香港有限公司及其附屬公司(“我們”、“我們的”或“Bizibuz”) 是www.bizibuz.com(“網站”)的所有者和經營者,其多個子域名包括但不限於kidzbiz.bizibuz .com(“Kidzbiz平台”)中的Kidzbiz子域名和潛在的其他Bizibuz品牌應用(統稱“Bizibuz平台”)，承諾保障並尊重您的個人數據隱私，並遵守適用法律的數據保護原則及條款。
              </li>
              <li>
              當您使用本隱私政策中所述的Bizibuz平台和由Bizibuz或第三方教育公司或運營商(統稱“活動提供商”)提供的服務(統稱“服務”)時，我們可能會收集、處理、使用和披露您的信息。在本隱私政策中使用的“您”、“您的”和“用戶”包括任何瀏覽Bizibuz平台或使用本服務的人員。
              </li>
              <li>
              本隱私政策闡明了在您瀏覽Bizibuz平台和/或使用服務時，Bizibuz收集、處理、使用和/或披露從您處所獲信息的基礎和條款。此信息可能包括與(a)特定個人有關或關聯的個人信息，如姓名、居住地址、電話號碼、電子郵件地址、家庭成員的出生日期、醫療狀況或任何我們要求且您已通過Bizibuz平台提供的個性化信息(“個人信息”)。

              </li>
              <li>
              請仔細閱讀此隱私政策。瀏覽Bizibuz平台，即表示您同意本隱私政策中所述的收集、處理、使用和披露您的個人信息。

              </li>
            </ol>
          </li>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
            條款範圍
            </Title>
            <ol>
              <li>
              Bizibuz有權更新、修訂或修改本隱私政策條款或其部分條款而不另行通知,您繼續瀏覽Bizibuz平台或使用服務即代表您接受更新、修訂或修改的隱私政策,僅在因更改而導致您權利降低時，我方將尋求您的同意。如果您不同意本隱私政策的所有條款和條件和/或任何後續更新、修訂或修改，您必須停止瀏覽或停止使用Bizibuz平台和服務。因此，如您希望瀏覽和查看本隱私政策的當前版本，請瀏覽本頁面。
              </li>
            </ol>
          </li>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
            信息收集
            </Title>
            <ol>
              <li>
              我們可能會收集您在使用Bizibuz平台時向我們提供的有關您的個人信息，以及有關您如何使用Bizibuz平台的信息，包括您何時設置帳戶（“用戶帳戶”）、瀏覽Bizibuz平台或預訂教育活動或使用其他服務。向Bizibuz提供您的個人信息始終是自願的。但是，如果您選擇不向我們提供您的個人信息，我們可能無法為您提供某些服務。例如，如果我們無法使用您輸入的孩子的出生日期來確認該活動的年齡適合性，那麼我們就無法為該活動的預訂提供便利。
              </li>
              <li>
              當您在Bizibuz平台上開立用戶帳戶或修改與您的用戶帳戶相關的任何信息時，我們可能會收集您的個人信息，如您的姓名、電子郵件地址、用戶名、密碼和電話號碼。
              </li>
              <li>
              當您瀏覽Bizibuz平台(即便沒有設置一個用戶帳戶或登錄)、預訂教育活動或使用服務時,我們可能收集和處理某些信息(可能包含您的個人信息或可能含有鏈接到您的個人信息得非個人可識別信息)，包括但不限於下面內容： <br />
              (i) 您與我們之間以及您與活動提供商之間的通信副本(無論是通過電子郵件、即時或個人消息或其他方式); <br />
(ii) 與活動提供商運營人完成教育活動預訂所需的您的信息(包括但不限於參與兒童的出生日期、相關醫療狀況、家長聯絡資料); <br />
(iii) 您使用Bizibuz平台的詳細信息(包括交通數據、位置數據和用戶課程時長); <br />
(iv) 對可能由Bizibuz發布、傳閱或分發的，針對服務和資訊相關的調研的反饋和回應; <br />
(v) 自動收集的信息和通過使用或瀏覽Bizibuz平台而儲存在我們的服務器或第三方服務提供商的服務器的信息(包括您用戶帳戶的登錄名稱和密碼、您計算機的互聯網協議(IP)地址、瀏覽器類型、瀏覽器信息、設備信息(包括唯一設備標識符)、瀏覽的頁面、之前或之後瀏覽的站點)。 <br />


              </li>
              <li>
              當您使用您的移動設備瀏覽Bizibuz平台時，我們可能會按如上所述收集您和您移動設備的相關信息。如您同意並配置了您的移動設備向我們發送這些信息，此項會包括位置信息。該位置信息將按照本隱私政策中規定的方式和目的使用。例如，我們可能使用您的位置建議附近的服務或提供個性化內容。您可以隨時更改移動設備的隱私設置，禁用共享位置信息的功能。

              </li>
              <li>鑑於您正在通過Bizibuz平台為您的孩子購買教育活動，我們可能會詢問您孩子的個人信息。在向Bizibuz提供個人資料之前，您應獲得其本人的同意，並確保其了解、理解和接受本隱私政策。</li>
            </ol>
          </li>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
            信息存儲
            </Title>
            <ol>
              <li>
              我們收集到的您的個人信息和其他數據可能會被轉移、處理和存儲在我們的服務器或我們的第三方服務提供商的服務器中。為了滿足本隱私政策中所述的任何目的，或為了遵守(i)提供服務所需的合同要求，以及(ii)法律、稅務或會計要求，您的個人信息將被存儲。<br/>
當我們無需再處理您的個人信息時，我們將刪除或將數據匿名處理，或，在不可能的情況下(例如，因為您的個人信息已經存儲在備份歸檔中)，那麼我們將安全地存儲您的個人信息。如果我們計劃將您的數據用於分析目的或趨勢分析，我們將盡力匿名或聚合您的數據。

              </li>
              <li>
              Bizibuz將盡合理努力維護恰當的物理、電子和組織程序，以確保您的個人信息和其他數據得到安全處理，並符合本隱私政策，並保護此類數據不受未經授權的瀏覽或未經授權的修改、披露或銷毀。<br/>
在我們收到您的信息時，我們將使用嚴格的程序和安全功能努力防止未經授權的瀏覽。 Bizibuz不提供任何陳述、保證或許諾您提供給我們的個人信息在任何時候都是安全的，只要Bizibuz履行了其義務，在任何情況下，Bizibuz均不承擔因未經授權瀏覽或使用您的個人信息，您可能遭受或招致的任何損失、損害、成本和開支。所有經我們或我們所選的第三方支付處理服務提供商處理的支付交易將使用在線加密技術進行加密。您有責任保密您選擇的密碼，不與任何第三方共享您的密碼。

              </li>
            </ol>
          </li>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
            信息使用
            </Title>
            <ol>
              <li>
              我們會在必要時根據我們的合法利益處理收集到的個人信息，以便向您提供服務，以及用於對服務的進一步改進和直接營銷之目的。例如，Bizibuz會使用通過Bizibuz平台或在購買服務時收集的個人信息和其他數據完成您的服務預訂和購買、持續改進Bizibuz平台和服務，並針對服務聯繫您。這包括使用您的個人信息或其他數據，實現更快的預訂接受、更少的預訂取消、更好地為客戶提供支持，並及時通知您新的服務和特別優惠。
              </li>
              <li>
              我們還可能不時地利用您的個人信息與您聯繫，獲取您使用Bizibuz平台的反饋，用於幫助我們改進Bizibuz平台，或在您同意接受此類通信時為您提供特別優惠或促銷。如果您不希望收到特別優惠或促銷的通知，則可以通過此通知中提供的超鏈接答复我們，選擇不接收此項內容。
              </li>
            </ol>
          </li>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
            信息披露
            </Title>
            <ol>
              <li>
              我們可能會不時向第三方共享和披露您的個人信息和其他數據。此共享和披露可包括但不限於以下情況: <br />
              (i) 用於成功完成您的預訂請求或執行我們的使用條款。我們可能會與您在Bizibuz平台購買服務的活動提供商共享您的信息。<br />
(ii) 如您是活動提供商，則向您提供服務的任何用戶提供。 <br />
(iii) 對於我們與其合作，允許其代表我們執行某些服務的第三方服務提供商（包括谷歌分析），例如網絡託管服務、數據分析、市場營銷、市場研究以及用其他方式為您提供客戶服務的提供商。<br />
(iv) 如按照任何適用法律、法院命令或任何政府當局要求披露此信息。<br />
(v) 在Bizibuz集團公司內。在與本公司的銷售、合併、收購或其他公司改組或重組有關的公司交易中，您的個人信息可能會被披露、共享或轉讓給新的控股實體或其授權的第三方，以進行本公司的業務。<br />
(vi) 披露給我們的顧問、代理或其他有關方，以保護Bizibuz的權利和財產。<br />
(vii) 在經您事先書面同意後，可轉讓給任何第三方。

              </li>
              <li>
              我們也可能與包括廣告商在內的相關第三方共享匯總或匿名信息。此類信息不包含任何個人信息，也不會識別您的個人身份。
              </li>
              <li>
              在Bizibuz平台上可能存在導致您離開Bizibuz平台和/或被轉至其他第三方網站的鏈接。您應注意,您提供給第三方網站的任何個人信息不受本隱私政策約束,且對於因您向第三方網站提供個人信息或其他數據而可能遭受或招致的任何損失、損害、成本或開支， Bizibuz概不負責。
              </li>
            </ol>
          </li>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
              Cookies
            </Title>
            <ol>
              <li>
              Cookies被廣泛用於網站及其高效工作。當您瀏覽我們的網站時，我們會收集由您的瀏覽器通過cookies傳送給我們的部分個人信息。這讓您能夠瀏覽Bizibuz平台，並幫助我們為您創造更好的用戶體驗。您可在我們的cookies政策中找到更多關於cookies和我們使用的類似技術的詳細信息。
              </li>
            </ol>
          </li>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
            您的權利
            </Title>
            <ol>
              <li>
              您可以隨時在Bizibuz平台的用戶門戶，主導航面板的“我的個人資料” 瀏覽或更正您的個人信息。在適用法律的強制要求下，您也可以通過發送要求對您的個人信息進行限制處理，或通過發送電子郵件至info@bizibuz.com反對處理。您也可以通過電子郵件將您的要求發送至info@bizibuz.com，申請我們保存的您的信息的副本。
              </li>
              <li>
              如果您對您的個人信息處理有任何不滿，請通過以下聯繫方式與我們聯繫。在處理上述任何請求時，我們有權檢查請求者的身份，以確保他/她是有權提出請求的人。
              </li>
            </ol>
          </li>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
            其他事項
            </Title>
            <ol>
              <li>
              本隱私政策以英語起草並解釋。本隱私政策之翻譯僅供參考。如英語版本和翻譯版本之間存在不一致，則以英語版本為準。
              </li>
            </ol>
          </li>
        </ol>
        <div className={"tsl-3"}>
          <div>
            <b>問詢</b>
          </div>
          <div>
          如您對本隱私政策有任何疑問，請發送電子郵件至info@bizibuz.com。
          </div>
        </div>
        <div className={"bsl-3 tsl-3"}>最後更新於: 2021年4月22日。</div>
      </div>
    </>
  );
};

export default ZhPrivacyPolicy;
