import { NotificationStatus } from "constants/type.interface";
import { AppRoutes } from "helpers/app.routes";
import keycloak from "helpers/keycloak";
import { setConfigAxios } from "helpers/requestApi";
import i18n from "i18n";
import Cookies from "js-cookie";
import { Provider as Mobx } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "remixicon/fonts/remixicon.css";
import { useQueryParams } from "scripts/custom-hooks/useQueryParams";
import { Languages } from "scripts/mobx/languageStore";
import "./App.less";
import { CookiesPolicy } from "./components/CookiesPolicy";
import { LayoutBase } from "./components/Layout";
import RouterComponent from "./RouterComponent";
import StoreMobx from "./scripts/mobx/Store";
import helpers from "scripts/helpers";
import moment from "moment-timezone";
import { toJS } from "mobx";

const App = () => {
  const history = useHistory();

  const [loaded, setLoaded] = useState(false);
  const getInfoUser = () => {
    StoreMobx.userStore.getUserProfile();
  };

  useEffect(() => {
    keycloak
      .init({
        onLoad: "check-sso",
      })
      .then(async (res: any) => {
        setLoaded(false);
        if (res) {
          Cookies.set("react-token", keycloak.token);
          Cookies.set("react-refresh-token", keycloak.refreshToken);
          setConfigAxios(keycloak.token);
          // StoreMobx.languageStore?.setLang(
          //   Cookies.get("KEYCLOAK_LOCALE") === "zh-CN"
          //     ? Languages.ZH
          //     : Languages.EN,
          // );
          StoreMobx.languageStore?.setLang(Languages.EN);
          StoreMobx?.userStore?.getKeycloakInfo(keycloak);

          let paymentMethods = [];

          try {
            await StoreMobx.userStore.getPaymentMethods();
            paymentMethods = toJS(StoreMobx.userStore.paymentMethods);
          } catch (err) {}

          StoreMobx.userStore
            .getUserProfile()
            .then((user) => {
              setLoaded(true);

              const alreadyHasPaymentMethod = user?.userAttributes
                ? Boolean(paymentMethods.length) ||
                  moment(
                    helpers.getServerDate(user?.userAttributes?.createdDate),
                  ).isSameOrBefore("01/08/2023") // to skip user created this before 01/08/2023
                : false;

              if (
                !user ||
                !user?.userAttributes ||
                !user?.businessUnitAttributes ||
                !user?.providerAttributes?.description ||
                !alreadyHasPaymentMethod
              ) {
                history.replace("/signup");
              } else if (user?.immediateAction === "STRIPE_ONBOARDING") {
                history.push(AppRoutes.stripeAcc);
              }
            })
            .catch((e) => {
              // console.log(e);
            });
          StoreMobx.notificationsStore.getNotifications();
        } else {
          setLoaded(true);
        }
      })
      .catch(() => {
        console.log("err");
      });
  }, []);

  const getRefreshToken = () => {
    keycloak.onTokenExpired = () => {
      keycloak.updateToken(180).success(() => {
        setConfigAxios(keycloak.token);
        Cookies.set("react-token", keycloak.token);
        Cookies.set("react-refresh-token", keycloak.refreshToken);
        setTimeout(() => {
          getInfoUser();
        }, 200);
      });
    };
  };

  useEffect(() => {
    getRefreshToken();
    return () => {
      getRefreshToken();
    };
  });

  return loaded ? (
    <Mobx {...StoreMobx}>
      <LayoutBase>
        <RouterComponent />
        <CookiesPolicy />
      </LayoutBase>
    </Mobx>
  ) : (
    <></>
  );
};

export default App;
