import {
  Button,
  Col,
  Divider,
  Form,
  message,
  notification,
  Popconfirm,
  Row,
  Spin,
  Switch,
  Typography,
} from "antd";
import { HeadSeo } from "components/HeadSeo";
import { NotificationPlatforms } from "constants/type.interface";
import { countBy } from "lodash";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useCurrentUser } from "scripts/custom-hooks/useCurrentUser";
import helpers from "scripts/helpers";
import notificationsStore from "scripts/mobx/notificationsStore";
import { LayoutPage } from "../../components/Layout";
import {
  NotiPlatformList,
  NotiTriggerList,
  NotiTriggers,
  SMS_BOOKINGS,
} from "./data";
import "./index.less";
const { Title } = Typography;

const Notifications = observer((props: RouteComponentProps) => {
  const { t } = useTranslation();
  const user = useCurrentUser();
  const [form] = Form.useForm();
  const [collapse, setCollapse] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dataSettings, setDataSettings] = useState<any[]>([]);

  const getNotificationSettings = () => {
    setIsLoading(true);
    notificationsStore
      ?.getNotificationSettings()
      .then((res) => {
        form.setFieldsValue(res?.initData);
        setDataSettings(res?.settings);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  React.useEffect(() => {
    getNotificationSettings();
  }, []);

  const onFinish = (values: any) => {
    let count = 0;
    const newArrs = Object.entries(values)?.filter(
      (el) =>
        el?.[0]?.search(NotificationPlatforms.IN_APP) >= 0 ||
        el?.[0]?.search(NotificationPlatforms.EMAIL) >= 0,
    );
    newArrs?.forEach((el) => {
      if (el?.[1]) {
        count = count + 1;
      }
    });
    const params = dataSettings?.map((el: any) => {
      return {
        ...el,
        emailSetting: values[el?.type + "_" + NotificationPlatforms.EMAIL],
        inAppSetting: values[el?.type + "_" + NotificationPlatforms.IN_APP],
        smsSetting: values[el?.type + "_" + NotificationPlatforms.SMS],
      };
    });
    setIsLoading(true);
    form
      .validateFields([Object.keys(values)])
      .then(async () => {
        if (count === 0) {
          setIsLoading(false);
          message.error(t("notifications.at_least"));
        } else {
          notificationsStore
            ?.updateNotificationSettings(params)
            .then(() => {
              notification.success({
                message: t("notifications.update_notification_sucess"),
              });
              setTimeout(() => getNotificationSettings(), 400);
            })
            .catch((error: any) => {
              setIsLoading(false);
              helpers.throwErrors(
                t,
                [],
                error?.data?.error,
                error?.data?.message ||
                  t("notifications.update_notification_error"),
              );
            });
        }
      })
      .catch((e) => {
        const { _message } = e;
        message.error(_message || e.message);
        setIsLoading(false);
      });
  };

  let initialValues = {};
  NotiTriggerList?.forEach((el) => {
    NotiPlatformList.forEach((p) => {
      initialValues = Object.assign(initialValues, {
        [el + "_" + p]:
          el === NotiTriggers.BOOKING_REQUESTS &&
          p === NotificationPlatforms.SMS
            ? true
            : p === NotificationPlatforms.SMS
            ? false
            : true,
      });
    });
  });

  return (
    <>
      <HeadSeo title={t("notifications.settings")} />

      <LayoutPage prefix="kidzbiz">
        <div className="biz-view-content biz-noti-settings">
          <Spin spinning={isLoading} size="large">
            <Title level={1}>{t("notifications.settings")}</Title>
            <Form
              form={form}
              name="change-notifications"
              className="notification-form"
              initialValues={initialValues}
              onFinish={onFinish}
            >
              <Row gutter={24} className="tsl-3 bsl-4">
                <Col sm={6} xs={24}>
                  {/* <Title level={4}>{t("notifications.triggers.title")}</Title> */}
                </Col>
                <Col sm={6} xs={8}>
                  <Title level={4}>{t("notifications.IN_APP")}</Title>
                </Col>
                <Col sm={6} xs={8}>
                  <Title level={4}>{t("notifications.EMAIL")}</Title>
                </Col>
                <Col sm={6} xs={8}>
                  <Title level={4}>{t("notifications.SMS")}</Title>
                </Col>
              </Row>
              <Divider />

              {NotiTriggerList?.map((el) => {
                return (
                  <div className={`form-row-trigger `} key={el}>
                    <Row gutter={24} align="middle" className="bsl-4 flex-1">
                      <Col sm={6} xs={24}>
                        <div className={`flex-align-items form-row-title`}>
                          <Title level={4}>
                            {t("notifications.triggers." + el?.toLowerCase())}
                          </Title>
                        </div>
                      </Col>
                      {NotiPlatformList.map((p) => {
                        return (
                          <Col sm={6} xs={8} key={el + "_" + p}>
                            <Form.Item noStyle shouldUpdate>
                              {() => {
                                let disable =
                                  (el === NotiTriggers.BOOKING_REQUESTS &&
                                    p === NotificationPlatforms.SMS) ||
                                  ([
                                    NotiTriggers.BOOKING_REQUESTS,
                                    NotiTriggers.BOOKING_CANCELLATIONS,
                                    NotiTriggers.COURSE_CANCELLATIONS,
                                  ].includes(el) &&
                                    p === NotificationPlatforms.EMAIL)
                                    ? true
                                    : false;
                                if (
                                  (p === NotificationPlatforms.IN_APP &&
                                    form.getFieldValue(el + "_" + p) &&
                                    !form.getFieldValue(
                                      el + "_" + NotificationPlatforms.EMAIL,
                                    )) ||
                                  (p === NotificationPlatforms.EMAIL &&
                                    form.getFieldValue(el + "_" + p) &&
                                    !form.getFieldValue(
                                      el + "_" + NotificationPlatforms.IN_APP,
                                    ))
                                ) {
                                  disable = true;
                                }
                                return (
                                  <Form.Item
                                    name={el + "_" + p}
                                    valuePropName="checked"
                                  >
                                    <Switch disabled={disable} />
                                  </Form.Item>
                                );
                              }}
                            </Form.Item>
                          </Col>
                        );
                      })}
                    </Row>
                    <Divider />
                  </div>
                );
              })}

              <Form.Item>
                <Button
                  className="full-width fs-16"
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                  disabled={isLoading}
                  icon={
                    <div className={"flex-center"}>
                      {t("bizBase.save")}{" "}
                      <i className="ri-arrow-right-line ml-10 fs-20" />
                    </div>
                  }
                  style={{ maxWidth: 280 }}
                />
              </Form.Item>
            </Form>
          </Spin>
        </div>
      </LayoutPage>
    </>
  );
});

export default Notifications;
