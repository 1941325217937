export const DETAIL_API_URL = {
  USERS_REGISTER: "/provider/v1/user/register",
  USERS_LOGIN_PROFILE: "/provider/v1/user/loginProfile",
  USERS_UPDATE: "/provider/v1/user/update",
  USERS_SET_ADDRESS: "/provider/v1/user/setAddress",
  BU_REGISTER: "/provider/v1/businessUnit/register",
  BU_UPDATE: (businessUnitId) =>
    `/provider/v1/businessUnit/${businessUnitId}/update`,
  BU_REGISTER_MASTER_PROVIDER: (businessUnitId) =>
    `/provider/v1/businessUnit/${businessUnitId}/registerMasterProvider`,
  PROVIDER_SET_POLICIES: (providerId) =>
    `/provider/v1/provider/${providerId}/setPolicies`,

  PROVIDER_GET_POLICIES: () => `/provider/v1/account/policies`,

  USERS_GET_LOCATION: () => `/provider/v1/account/locationList`,
  CREATE_MASTER: "/provider/v1/account/createMaster ",
  SET_ADDITIONAL_INFO: `/provider/v1/account/setAdditionalInfo`,
  UPDATE_PROVIDER: "/provider/v1/account/updateMaster",
  // SET_VERIFIED: "/admin/v1/provider/setStatus",
  SET_VERIFIED: "/provider/v1/user/requestVerification",
  USERS_UPDATE_PASSWORD: "/provider/v1/user/updatePassword",
  GET_EMPLOYEE: "/provider/v1/user/employeeList",
  ADD_EMPLOYEE: "/provider/v1/user/addEmployee",
  EDIT_EMPLOYEE: "/provider/v1/user/editEmployee",
  DEACTIVATE_EMPLOYEE: "/provider/v1/user/deactivateEmployee",

  GET_LINKED_ACCOUNT: "/provider/v1/account/linkedAccounts",
  STRIPE_ACCOUNT_ONBOARD: "/provider/v1/payment/stripeAccountOnboardLink",
  SEND_INVITATION: "/provider/v1/account/invite",
  CREATE_SATELLITE: "/provider/v1/account/createSatellite",
  GET_MASTER_SUMMARY: "/provider/v1/account/masterSummary",
  GET_SATELLITE_SUMMARY: (providerId) =>
    `/provider/v1/account/satelliteSummary/${providerId}`,
  RESCHEDULING_OUTCOME: "/provider/v1/account/reschedulingOutcome",
  DELINK_ACCOUNT: (providerId) =>
    `/provider/v1/account/satelliteSummary/${providerId}/delinkAccount`,

  //Common
  UPLOAD_IMAGE: "/provider/v1/course/uploadImage",
  PROVIDER_LIST: "/provider/v1/account/providerFilterItems",

  //Courses
  GET_HK_HOLIDAYS: "/public/v1/misc/holidays",
  GET_CATEGORY_DICTIONARY: "/public/v1/course/categoryDictionary",
  GET_SCHEDULE: "/provider/v1/course/calculateClassSchedules",
  COURSE: "/provider/v1/course",
  COURSE_LIST: "/provider/v1/course/courseList",
  COURSE_SEARCH: "/provider/v1/course/activeCourse",
  COURSE_DETAIL: (courseId) => `/provider/v1/course/${courseId}/detail`,
  COURSE_UPDATE: (courseId) => `/provider/v1/course/${courseId}/update`,
  COURSE_UPLOAD_EXCEL: "/provider/v1/course/uploadExcel",
  COURSE_BULK_PUBLISH: "/provider/v1/course/bulkPublish",
  COURSE_BULK_DELETE: "/provider/v1/course/bulkDelete",
  COURSE_CANCEL: (courseId) => `/provider/v1/course/${courseId}/cancel`,
  COURSE_CLASS_CANCEL: (classId, lang) =>
    `/provider/v1/class/${classId}/cancel?language=${lang}`,

  //PROMOTION
  PROMOTION_LIST: "/provider/v1/account/bulkPromotions",
  PROMOTION_ADD: "/provider/v1/account/addBulkPromotion",
  PROMOTION_EDIT: "/provider/v1/account/editBulkPromotion",
  PROMOTION_DELETE: "/provider/v1/account/deleteBulkPromotion",
  PROMOTION_COURSE: "/provider/v1/course/coursePromotion",
  PROMOTION_COURSE_LIST: "/provider/v1/course/coursePromotions",
  PROMOTION_COURSE_ADD: "/provider/v1/course/addCoursePromotion",
  PROMOTION_COURSE_EDIT: "/provider/v1/course/editCoursePromotion",
  PROMOTION_COURSE_DELETE: "/provider/v1/course/deleteCoursePromotion",

  // Mailerlite Subscribers
  MAILERLITE_SUBSCRIBERS: `/provider/v1/cart/mailer/groups/subscribers`,

  //Bookings
  BOOKING_LIST: "/provider/v1/course/bookingList",
  BOOKING_STATUS: (bookingId) =>
    `/provider/v1/booking/${bookingId}/acceptReject`,
  BOOKING_EXPORT: "/provider/v1/booking/export",

  //Customers
  CUSTOMER_LIST: "/provider/v1/customer/customerList",
  CUSTOMER_DETAIL: (customerId) => `/provider/v1/customer/${customerId}/detail`,
  CUSTOMER_COURSE: (customerId) => `/provider/v1/customer/${customerId}/course`,
  COURSE_CUSTOMER: (courseId) => `/provider/v1/course/${courseId}/customers`,

  CHANGE_EMAIL_VERIFICATION: "/provider/v1/user/changeEmailVerification",
  UPDATE_USER_EMAIL: "/provider/v1/user/updateUserEmail",

  //Reviews
  REVIEW_LIST: "/provider/v1/reviews",
  REVIEW_DETAIL: (reviewId) => `/provider/v1/reviews/${reviewId}`,
  REVIEW_REPLY: (reviewId) => `/provider/v1/reviews/${reviewId}/reply`,
  REVIEW_OF_CUSTOMER: (customerId) =>
    `/provider/v1/customers/${customerId}/reviews`,

  COURSE_PREPARE_PUBLIST: `/provider/v1/course/preparePublish`,

  NOTI_SETTING: "/provider/v1/notification-settings",
  NOTI_SETTING_UPDATE: "/provider/v1/notification-settings/update",
  NOTI_LIST: "/provider/v1/notifications/list",
  NOTI_SET_STATUS: (notificationId) =>
    `/provider/v1/notifications/${notificationId}/setStatusNotification`,

  //PAYMENTS
  PAYMENT_LIST: `/provider/v1/payment/customerTransactionList`,
  PAYMENT_DETAIL: (transactionId) =>
    `/provider/v1/payment/transaction/${transactionId}/detail`,
  PAYMENT_EXPORT_LIST: "/provider/v1/payment/exportList",
  CREATE_BALANCE: "/provider/v1/customer/remainingCredit",
  ORG_PAYMENT_LIST: `/payment/v1/provider/payouts`,
  ORG_PAYMENT_DETAIL: (providerId, code) =>
    `/payment/v1/provider/payoutItems/${providerId}?partnerReferenceCode=${
      code || ""
    }`,
  PAYOUT_EXPORT_GENERIC: (providerId, code) =>
    `/payment/v1/provider/payoutItems/${providerId}/generic?partnerReferenceCode=${
      code || ""
    }`,
  PAYOUT_EXPORT_XERO: (providerId, code) =>
    `/payment/v1/provider/payoutItems/${providerId}/xero?partnerReferenceCode=${
      code || ""
    }`,
  PAYMENT_METHOD: "/payment/v1/provider/paymentMethod",

  // MISCELLANEOUS
  COMMON_CONFIGURATION: "public/v1/common/configuration",
};
