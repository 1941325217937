import { Form, Input, message, Modal, Tabs, Typography } from "antd";
import { HeadSeo } from "components/HeadSeo";
import { Link } from "components/Link";
import QueryString from "constants/query-string";
import { PromotionTab } from "constants/type.interface";
import { AppRoutes } from "helpers/app.routes";
import { toJS } from "mobx";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, withRouter } from "react-router-dom";
import {
  useCurrentUser,
  userBlocked,
  useRoles,
  useType,
} from "scripts/custom-hooks/useCurrentUser";
import { useQueryParams } from "scripts/custom-hooks/useQueryParams";
import { UserType } from "scripts/mobx/userStore";
import ButtonLink from "../../components/ButtonLink";
import { LayoutPage } from "../../components/Layout";
import TableComponent from "../../components/TableComponent/TableComponent";
import { bizIcons } from "../../constants/bizConstants";
import {
  tablePromotionsColumns,
  tablePromotionsCourseColumns,
} from "./tableGenerator";

const { TabPane } = Tabs;
const { Title } = Typography;

const Promotions = inject(
  "promotionsStore",
  "providerStore",
)(
  observer((props: any) => {
    const { history, promotionsStore } = props;
    const { t } = useTranslation();
    const user = useCurrentUser();
    const userType = useType();
    const isBlocked = userBlocked();
    const queryParams = useQueryParams();
    const currentTab = queryParams.get("tab") || PromotionTab.Bulk;
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [tab, setTab] = useState<string>(currentTab);
    const [searchForm] = Form.useForm();
    const location = useLocation();

    useEffect(() => {
      if (queryParams.get("tab") === PromotionTab.Course) {
        setTab(PromotionTab.Course);
        promotionsStore?.getCoursePromotions();
      } else {
        setTab(PromotionTab.Bulk);
        promotionsStore?.getPromotions();
      }
    }, [location]);

    const onAddPromotionClickHandler = (type: string) => {
      history.push(AppRoutes.promotionsAdd + "/" + type);
    };

    const handleDeletePromotion = (id: string | number) => () => {
      Modal.confirm({
        content: t("bizBase.confirm_delete"),
        async onOk() {
          try {
            if (tab === PromotionTab.Course) {
              await promotionsStore?.deleteCoursePromotionById(Number(id));
            } else {
              await promotionsStore?.deletePromotionById(Number(id));
            }
            message.success(t("promo.deleted"));
          } catch (error: any) {
            message.error(
              error?.data?.message || t("server_errors." + "Bad Request"),
            );
          }
        },
      });
    };

    const ActionButton = (type: string, record: any) => {
      return !isBlocked &&
        ((userType === UserType.MASTER && tab === PromotionTab.Bulk) ||
          (record?.isEdit && tab === PromotionTab.Course)) ? (
        <div className="flex-align-items">
          <ButtonLink
            onClick={() =>
              history.push(AppRoutes.promotionsEdit(type, record.id))
            }
            className="mr-15"
          >
            <i
              className={bizIcons.EDIT}
              style={{ fontSize: "16px", marginRight: 6 }}
            />
            {t("bizBase.edit")}
          </ButtonLink>
          <ButtonLink onClick={handleDeletePromotion(record.id)}>
            <i
              className={bizIcons.DELETE}
              style={{ fontSize: "16px", marginRight: 6 }}
            />
            {t("bizBase.delete")}
          </ButtonLink>
        </div>
      ) : (
        <ButtonLink
          onClick={() =>
            history.push(AppRoutes.promotionsView(type, record.id))
          }
        >
          {t("bizBase.view")}
        </ButtonLink>
      );
    };

    const onSearch = () => {
      setIsLoading(true);
      const promotionName = searchForm.getFieldValue("search");
      const filter = {
        params: {
          promotionName,
        },
      };
      if (tab === PromotionTab.Course) {
        promotionsStore?.getCoursePromotions(filter)?.finally(() => {
          setIsLoading(false);
        });
      } else {
        promotionsStore?.getPromotions(filter)?.finally(() => {
          setIsLoading(false);
        });
      }
    };

    return (
      <>
        <HeadSeo title={t("menu.promotions")} />
        <LayoutPage defMenuItem={"promotions"}>
          <div className="single-course">
            <div className="bsl-4 flex-space-between">
              <Title level={1}>{t("menu.promotions")}</Title>
              <Link to={AppRoutes.faqDetail("courses6")} target="_blank">
                {t("kidzbiz.goToFAQs")}
              </Link>
            </div>

            <div className="biz-tabs-content">
              <div>
                <Tabs
                  activeKey={tab}
                  destroyInactiveTabPane={true}
                  size={"small"}
                  onTabClick={(tab) => {
                    searchForm.setFieldsValue({
                      search: "",
                    });
                    setTab(tab);
                    promotionsStore?.clearPromotions();
                    if (tab === PromotionTab.Course) {
                      promotionsStore?.getCoursePromotions();
                    } else {
                      promotionsStore?.getPromotions();
                    }
                    history.replace({
                      pathname: location?.pathname,
                      search: QueryString.stringify({
                        tab,
                      }),
                    });
                  }}
                >
                  <TabPane tab={t("promo.bulk")} key={PromotionTab.Bulk}>
                    <div>
                      <Form
                        form={searchForm}
                        name="searchForm"
                        onFinish={onSearch}
                      >
                        <Form.Item name="search">
                          <Input
                            size={"middle"}
                            className={"bsl-4"}
                            placeholder={t("promo.placeholder_search")}
                            style={{ height: "48px" }}
                            prefix={
                              <i
                                className={
                                  "ri-search-line mr-8 fs-20 color-grey"
                                }
                              />
                            }
                            onPressEnter={onSearch}
                          />
                        </Form.Item>
                      </Form>
                      {!isBlocked && userType === UserType.MASTER && (
                        <ButtonLink
                          onClick={() => onAddPromotionClickHandler("bulk")}
                        >
                          <i
                            className={bizIcons.ADDCIRCLE}
                            style={{ marginRight: "8px", fontSize: "20px" }}
                          />
                          {t("promo.addBulkPromotion")}
                        </ButtonLink>
                      )}
                    </div>

                    <TableComponent
                      broken={true}
                      pagination={false}
                      propsTableColumns={tablePromotionsColumns(
                        t,
                        ActionButton,
                      )}
                      propsTableData={toJS(
                        promotionsStore?.promotionsData?.items,
                      )}
                      isLoading={isLoading}
                    />
                  </TabPane>
                  <TabPane tab={t("promo.course")} key={PromotionTab.Course}>
                    <div className={"bsl-4"}>
                      <div>
                        <Form
                          form={searchForm}
                          name="searchForm"
                          onFinish={onSearch}
                        >
                          <Form.Item name="search">
                            <Input
                              size={"middle"}
                              className={"bsl-4"}
                              placeholder={t("promo.placeholder_search")}
                              style={{ height: "48px" }}
                              prefix={
                                <i
                                  className={
                                    "ri-search-line mr-8 fs-20 color-grey"
                                  }
                                />
                              }
                              onPressEnter={onSearch}
                            />
                          </Form.Item>
                        </Form>
                        {!isBlocked && (
                          <ButtonLink
                            onClick={() => onAddPromotionClickHandler("course")}
                          >
                            <i
                              className={bizIcons.ADDCIRCLE}
                              style={{ marginRight: "8px", fontSize: "20px" }}
                            />
                            {t("promo.addCoursePromotion")}
                          </ButtonLink>
                        )}
                      </div>
                      <TableComponent
                        broken={true}
                        pagination={false}
                        propsTableColumns={tablePromotionsCourseColumns(
                          t,
                          user?.isLinked,
                          ActionButton,
                        )}
                        propsTableData={toJS(
                          promotionsStore?.coursePromotionsData?.items,
                        )}
                      />
                    </div>
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </div>
        </LayoutPage>
      </>
    );
  }),
);

export default withRouter(Promotions);
