import { toJS } from "mobx";
import { useContext } from "react";
import { employeeStoreContext } from "scripts/mobx/employeeStore";
import { linkedAccountStoreContext } from "scripts/mobx/linkedAccountStore";
import { locationStoreContext } from "scripts/mobx/locationStore";
import {
  ProviderStatus,
  Roles,
  userStoreContext,
  UserType,
} from "scripts/mobx/userStore";

export const useCurrentUser = () => {
  const users = useContext(userStoreContext);
  const currentUser = toJS(users?.userData?.currentUser);
  // const linkedProviders = toJS(users?.userData?.linkedProviders);

  return {
    ...currentUser,
    isLinked:
      currentUser?.linkedAccountsCount > 0 &&
      currentUser?.providerAttributes?.type === UserType.MASTER,
  };
};
export const useCurrentVerify = () => {
  const users = useContext(userStoreContext);
  const currentVerify =
    toJS(users?.userData?.currentUser?.providerAttributes?.status) ===
    "VERIFIED";
  return currentVerify;
};

export const usePolicies = () => {
  const users = useContext(userStoreContext);
  const policies = toJS(users?.userData?.policies);
  return policies;
};

export const useCurrentLocation = (detail?: boolean) => {
  const locations = useContext(locationStoreContext);
  if (!detail) {
    return toJS(locations?.locationsData);
  }
  const data = locations?.locationsData?.map((item: any) => {
    const param = {
      name: item?.name,
      email: item?.email,
      phoneNumber: item?.mobile,
      address: item?.address?.addressLine,
      area: item?.address?.area,
      suburb: item?.address?.suburb,
      country: item?.address?.country,
      postalCode: item?.address?.postalCode,
      id: item?.id,
    };
    return param;
  }, []);
  return data;
};

export const useEmployees = () => {
  const employees = useContext(employeeStoreContext);
  const data = toJS(employees?.employeeData);
  return data;
};

export const useRoles = () => {
  const user = useContext(userStoreContext);
  const roles = toJS(
    user?.userData?.keycloakInfo?.resourceAccess?.kidzbiz?.roles,
  );
  if (roles?.length > 2) {
    return Roles.FULL;
  } else if (roles?.length > 1) {
    return Roles.ADMIN;
  }
  return Roles.PARTIAL;
};

export const useType = () => {
  const user = useContext(userStoreContext);
  const type = toJS(user?.userData?.currentUser?.providerAttributes?.type);
  if (type === "MASTER") {
    return UserType.MASTER;
  }
  return UserType.SATELLITE;
};

export const userBlocked = () => {
  const user = useContext(userStoreContext);
  const type =
    toJS(user?.userData?.currentUser?.providerAttributes?.status) ===
    ProviderStatus.BLOCKED;
  return type;
};

export const userLinkedAccount = () => {
  const data = useContext(linkedAccountStoreContext);
  const linkedAccount = toJS(data?.linkedAccountData);
  return linkedAccount;
};

export const userMasterSummary = () => {
  const data = useContext(linkedAccountStoreContext);
  const masterSummary = toJS(data?.masterSummary);
  return masterSummary;
};
