import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { ReportsStore } from "../../../../scripts/mobx/reportsStore";
import GreySquare from "../../../../components/GreySquare";
import emptyTableSVG from "../../../../assets/courses-empty.svg";
import { ChartFilters } from "./filters";
import CustomerCharacteristicsChart from "./CustomerCharacteristicsChart";
import { useTranslation } from "react-i18next";

interface IProps {
  autoFit?: boolean;
  colors?: string[];
  reportsStore?: ReportsStore;
}

const CustomerCharacteristics = inject("reportsStore")(
  observer((props: IProps) => {
    const { autoFit } = props;
    const { reportsStore } = props;
    const { t } = useTranslation();
    const [data, setData] = useState<any | undefined>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
      reportsStore?.getReportCustomerCharacteristicsChart({}).then((el) => {
        setData(el || undefined);
      });
    }, []);

    const updateFilter = (filterObject?: any) => {
      setIsLoading(true);
      reportsStore
        ?.getReportCustomerCharacteristicsChart({ ...filterObject })
        .then((el) => {
          setData(el || undefined);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    return (
      <div className={"chart-block tsl-4 bsl-4"}>
        <div className={"chart-block-header bsl-3"}>
          <div className={"chart-block-title title"}>
            {t("reports.customer_characteristics", {
              defaultValue: "Customer characteristics",
            })}
          </div>
          <ChartFilters t={t} onApply={updateFilter} />
        </div>
        <div className={"chart-wrapper"}>
          {!!data && (
            <CustomerCharacteristicsChart autoFit={autoFit} data={data} />
          )}
          {!data && <GreySquare imageSrc={emptyTableSVG} />}
        </div>
      </div>
    );
  }),
);

export default CustomerCharacteristics;
