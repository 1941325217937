import React, { useEffect } from "react";
import { AutoComplete } from "antd";
import { FormInstance } from "antd/lib/form";
import "./index.less";

export interface IOptionItem {
  label: string;
  subLabel?: string;
  value: string;
  id: number | string;
}

export interface IAutoComplete {
  allowClear?: boolean;
  placeholder?: string;
  items?: IOptionItem[];
  initValue?: IOptionItem;
  formInstance?: FormInstance;
  fieldName?: string;
  readOnly?: boolean;
  disabled?: boolean;
  children?: React.ReactElement;
  onSelected?: (data: any) => void;
  onSearchValue?: (data: string) => void;
}

const AutoCompleteInput = (props: IAutoComplete) => {
  const {
    items,
    formInstance,
    fieldName,
    disabled,
    children,
    initValue,
    onSelected,
    onSearchValue,
    allowClear,
    placeholder,
  } = props;
  const [options, setOptions] = React.useState<IOptionItem[]>([]);
  const [selectedValue, setSelectedValue] = React.useState<IOptionItem>();
  const [value, setValue] = React.useState(initValue?.label);

  useEffect(() => {
    setOptions(items!);
  }, [items]);

  useEffect(() => {
    if (initValue !== undefined && selectedValue === undefined) {
      setSelectedValue(initValue);
    }
  }, [initValue]);

  const onSearch = (searchText: string) => {
    const _text = searchText ? searchText?.toLowerCase() : "";
    setOptions(
      !_text
        ? []
        : (items || []).filter(
            (item) => item.label?.toLowerCase().indexOf(_text) !== -1,
          ),
    );

    if (formInstance && fieldName) {
      const value = {};
      Object.defineProperty(value, fieldName, {
        value: undefined,
        enumerable: true,
      });

      formInstance.setFieldsValue(value);
    }

    if (typeof onSearchValue === "function") {
      onSearchValue(searchText);
    }
  };

  const onSelect = (data: any, option: any) => {
    if (formInstance && fieldName) {
      const value = {};
      Object.defineProperty(value, fieldName, {
        value: option.key,
        enumerable: true,
      });

      formInstance.setFieldsValue(value);
    }

    if (typeof onSelected === "function") {
      onSelected(option);
    }

    setSelectedValue(option);
    setValue("");
  };

  const onChange = (data: string) => {
    setValue(data);
  };

  return (
    <AutoComplete
      allowClear={allowClear}
      placeholder={placeholder}
      key={initValue?.id}
      onSelect={(val: any, option: any) => onSelect(val, option)}
      onSearch={onSearch}
      disabled={disabled}
      options={options}
      defaultValue={initValue?.label}
      value={value}
      onChange={onChange}
    >
      {children}
    </AutoComplete>
  );
};

export default AutoCompleteInput;
