import React from "react";
import { Typography } from "antd";
import TableComponent from "../../../../components/TableComponent/TableComponent";
import { tableCookiesColumns, tableCookiesData } from "./tableGenerator";
const { Title } = Typography;

const EnCookiePolicy = () => {
  return (
    <>
      <Title>Cookie Policy</Title>
      <div className={"bizibuz-policies-lists"}>
        <ol>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
              Cookies Background
            </Title>
            <ol>
              <li>
              Bizibuz strives to enhance your experience using the Bizibuz Platform and accordingly, may use cookies to manage the Website and improve your Website experience.
              </li>
              <li>
              Cookies are widely used on the Internet and Cookies are text files that are placed on your computer by websites that you visit. Cookies are then sent back to the originating website on each subsequent visit, or to another website that recognises that cookie. They are used in order to make websites work, or work more efficiently, as well as to provide information to the owners of the website. We use the term "cookies" throughout this notice to also cover all similar technologies, such as web beacons SDK, log files, pixel tags. 
              </li>
              <li>
              A cookie can be classified in terms of its (i) duration and (ii) the party who places it: <br /> 
              (i) Session and Persistent cookies: Session cookies expire once the browsing session ends. Persistent cookies are saved on your computer or mobile device for a fixed period of time. 	When you visit a website, your browser your browser will retrieve these cookies so that the website will recognise your device and personalise your site experience according to your 	previous browsing records. <br />
              (ii) First-party and Third-party cookies: Bizibuz sets the scope of first-party cookies but have no control over third-party cookies which are operated by third-party sites with a different domain.
              </li>
            </ol>
          </li>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
              Why are cookies used?
            </Title>
            <ol>
              <li>
              Bizibuz uses cookies to enhance your experience of the Bizibuz Platform in terms of safety, efficiency and customized service. Specifically, we limit the use of cookies to:  <br /> 
              (i) ensure the efficient functioning of this Website;<br /> 
	(ii) evaluate how this Website is accessed and used;<br /> 
	(iii) analyse what educational activities are popular among users;<br /> 
	(iv) determine your eligibility for campaigns and promotions;<br /> 
	(v) recommend educational activities that match your interests; <br /> 
	(vi) monitor compliance by Activity Providers with the Kidzbiz Agreement terms; and<br /> 
	(vii) improve the content of this Website and enhance user's experience.

              </li>
            </ol>
          </li>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
              What types of cookies are used?
            </Title>
            <ol>
              <li>
              Bizibuz uses four types of cookies, namely "functionality cookies", "analytics cookies," "advertising cookies" and "social media cookies“. Further information on these cookies is set out in the table below:
                <div className={"tsl-3 bsl-3"}>
                  <TableComponent
                    propsTableColumns={tableCookiesColumns()}
                    propsTableData={tableCookiesData()}
                    pagination={false}
                  />
                </div>
              </li>
            </ol>
          </li>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
              Consent to cookies
            </Title>
            <ol>
              <li>
              In most cases we need your consent in order to use cookies on this Website. The exception is where the cookies are strictly necessary in order for us to provide you with a 		service you have requested, or essential to the inherent functionality of the Website.
              </li>
              <li>
              Upon your first visit to our Website, you will see a notification banner on the landing page notifying you about our use of cookies. By continuing to use this Website without changing your settings, you agree to receive all cookies and other data tracking tools. On the other hand, if you do not wish to accept the use of these cookies, you can modify your cookie setting at any time. 
              </li>
            </ol>
          </li>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
              How can you manage your consent?
            </Title>
            <ol>
              <li>
              You can accept or decline our use of cookies at any time by managing and/or disabling all or some of the cookies from your browser settings. However, by doing so, you may not be able to enjoy the full functionality of this Website.
              </li>
              <li>
              Cookies settings can normally be found in the preference or settings menu from your	browser, for example: <br /> 
              (i) Chrome: Settings -- Advanced -- Content Settings -- Cookies <br /> 
	(ii) Safari: Preferences -- Privacy -- Cookies and website data<br /> 
	(iii) Firefox: Preferences -- Privacy -- History<br /> 
	(iv) Internet Explorer: Internet Options -- Privacy<br /> 

              </li>
            </ol>
          </li>
        </ol>
        <div className={"bsl-3 tsl-3"}>Last updated on: 22 April 2021.</div>
      </div>
    </>
  );
};

export default EnCookiePolicy;
