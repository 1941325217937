import ButtonLink from "components/ButtonLink";
import {
  countOfValuesEnum,
  IChkBlockProps,
  IControlProps,
} from "components/CheckBoxes/ChkBlock";
import { Filters } from "components/Filters";
import { observer } from "mobx-react";
import { CITY_DATA } from "pages/signup/interface";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { getLanguage } from "scripts/custom-hooks/useLanguage";

interface ICoursesFiltersProps extends RouteComponentProps {
  onApply: (data: any) => void;
}
export const SatellitesFilters = withRouter(
  observer((props: ICoursesFiltersProps): React.ReactElement => {
    const { onApply } = props;
    const { t } = useTranslation("base");
    const lang = getLanguage();

    const [tableFiltersData, setTableFiltersData] = useState<IChkBlockProps>();
    const [isFilterActive, setIsFilterActive] = useState<boolean>(false);

    const suburbs: any[] = [];
    CITY_DATA.forEach((el) => {
      suburbs.push(...el.suburbs);
    });

    const suburbsData = suburbs.map((el: any) => {
      return { value: el.value, placeholder: "suburbs." + el.value };
    });

    const getTableFiltersData = {
      status: {
        title: t("kidzbiz.status", { defaultValue: "Status" }),
        countOfValues: countOfValuesEnum.Array,
        controlsList: [
          {
            value: "",
            placeholder: t("filters.all", { defaultValue: "All" }),
            active: true,
            default: true,
          },
          {
            value: "VERIFIED",
            placeholder: t("filters.verified", { defaultValue: "Verified" }),
          },
          {
            value: "BLOCKED",
            placeholder: t("filters.blocked", { defaultValue: "Blocked" }),
          },
          {
            value: "UNVERIFIED_STRIPE",
            placeholder: t("filters.pendingStripe", {
              defaultValue: "Pending Stripe",
            }),
          },
          {
            value: "UNVERIFIED_BIZIBUZ",
            placeholder: t("filters.pendingVerification", {
              defaultValue: "Pending Verification",
            }),
          },
          {
            value: "REJECTED",
            placeholder: t("filters.rejected", { defaultValue: "Rejected" }),
          },
        ],
      },
      suburb: {
        title: t("filters.suburb", { defaultValue: "Suburb" }),
        countOfValues: countOfValuesEnum.Array,
        controlsList: [
          {
            value: "",
            active: true,
            default: true,
            placeholder: t("filters.all", { defaultValue: "All" }),
          },
          ...suburbsData,
        ],
      },
    };

    // const getProvidersListBySearch = async (search: string) => {
    //   if (providerStore?.satellitesData?.length) {
    //     return providerStore?.satellitesData
    //       .filter((el) => {
    //         return el?.organizationLegalName?.includes(search);
    //       })
    //       .map((el) => {
    //         return {
    //           value: el.id,
    //           text: el.organizationLegalName,
    //         };
    //       });
    //   }
    //   return [];
    // };

    // useEffect(() => {
    //   const tableFiltersData = getTableFiltersData(lang);
    //   (async () => {
    //     setTableFiltersData(tableFiltersData);
    //   })();
    // }, []);

    const demapFilterData = (data: any): any => {
      const _data = {};

      Object.keys(data).forEach((key) => {
        _data[key] = data[key].controlsList?.map(
          (el: IControlProps) => el.value,
        );
      });

      return _data;
    };

    const onApplyFilters = (data: any) => {
      const _data = { ...demapFilterData(data) };
      onApply(_data);
    };
    return getTableFiltersData ? (
      <Filters
        onApply={onApplyFilters}
        filterBlocks={getTableFiltersData}
        isFilterActive={isFilterActive}
      />
    ) : (
      <div>
        <ButtonLink disabled={true}>
          Filters
          <i
            className={"ri-arrow-drop-down-line"}
            style={{ fontSize: "20px" }}
          />
        </ButtonLink>
      </div>
    );
  }),
);
