import { Tabs, Typography } from "antd";
import { HeadSeo } from "components/HeadSeo";
import QueryString from "constants/query-string";
import { CourseTab } from "constants/type.interface";
import Cookies from "js-cookie";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, withRouter } from "react-router-dom";
import { useRoles } from "scripts/custom-hooks/useCurrentUser";
import { useQueryParams } from "scripts/custom-hooks/useQueryParams";
import providerOrdersStore from "scripts/mobx/providerOrdersStore";
import { Roles } from "scripts/mobx/userStore";
import { LayoutPage } from "../../components/Layout";
import CustomersPaymentsTab from "./customerPaymentsTab";
import { PaymentTab } from "./data";
import "./index.less";
import OrgPaymentsTab from "./orgPaymentsTab";

const { TabPane } = Tabs;
const { Title } = Typography;

const Payments = inject("userStore")(
  observer((props: any) => {
    const { t } = useTranslation("base");
    const roles = useRoles();
    const location = useLocation();
    const queryParams = useQueryParams();
    const history = useHistory();
    const currentTab = queryParams.get("tab") || PaymentTab.Customer;
    const [tab, setTab] = useState<string>(currentTab);

    useEffect(() => {
      if (!queryParams.get("tab")) {
        Cookies.set("paymentTab", PaymentTab.Customer);
        setTab(PaymentTab.Customer);
      }
    }, [location]);

    return (
      <>
        <HeadSeo title={t("menu.payments")} />
        <LayoutPage defMenuItem={"payments"}>
          <div className="single-course">
            <Title className={"bsl-4"} level={1}>
              {t("menu.payments")}
            </Title>
            <div className="biz-tabs-content">
              <div>
                <Tabs
                  activeKey={tab}
                  size={"small"}
                  destroyInactiveTabPane={true}
                  onTabClick={(key) => {
                    setTab(key);
                    providerOrdersStore?.setFilters();
                    Cookies.set("paymentTab", key);
                    history.replace({
                      pathname: location?.pathname,
                      search: QueryString.stringify({
                        tab: key,
                      }),
                    });
                  }}
                >
                  <TabPane tab={t("menu.customers")} key={PaymentTab.Customer}>
                    <CustomersPaymentsTab isParital={roles === Roles.PARTIAL} />
                  </TabPane>
                  {roles !== Roles.PARTIAL && (
                    <TabPane tab={t("menu.kidzbiz")} key={PaymentTab.Kidzbiz}>
                      <OrgPaymentsTab />
                    </TabPane>
                  )}
                </Tabs>
              </div>
            </div>
          </div>
        </LayoutPage>
      </>
    );
  }),
);
export default withRouter(Payments);
