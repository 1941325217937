import React, { useState } from "react";
import "./index.less";
import {
  AutoComplete,
  DatePicker,
  Input,
  Select,
  Slider,
  TimePicker,
} from "antd";
import { ButtonWhite } from "../../ButtonWhite";
import moment from "moment";
import { useTranslation } from "react-i18next";
import courses from "pages/courses";
const { Option } = AutoComplete;

export enum countOfValuesEnum {
  Array = "Array",
  Single = "Single",
}

export interface IChkBlockProps {
  [propname: string]: {
    title: string;
    controlsList?: IControlProps[];
    onChange?: (
      fullData: IChkBlockProps[],
      currentNewData: any,
      index: number,
    ) => IChkBlockProps[];
    curled?: boolean;
    combined?: boolean;
    countOfValues?: countOfValuesEnum;
  };
}

interface IProps {
  fullData: IChkBlockProps;
  blockData: IChkBlockProps;
  onChange?: (data: any, index?: number) => void;
}

export interface IControlProps {
  type?: CHECKBLOCKTYPES;
  placeholder?: string;
  active?: boolean;
  name?: string;
  markId?: string;
  value?: any;
  index?: number;
  default?: boolean;
  showNow?: boolean;
  optionsList?: string[] | any;
  isSearch?: boolean;

  disabledDates?: (current: any) => boolean;
  dependsOn?: {
    field:
      | string
      | string[]
      | { name: string; id?: number }
      | Array<{ name: string; id?: number }>;
    condition: (values: any) => any;
  };
}

export enum CHECKBLOCKTYPES {
  CHECKBOX = "CHECKBOX",
  DATE = "DATE",
  SLIDER = "SLIDER",
  TIME = "TIME",
  DROPDOWN = "DROPDOWN",
  SEARCH = "SEARCH",
}

export const ChkBlock = (props: IProps) => {
  const propName = Object.keys(props.blockData)[0];
  const { title, controlsList, curled, countOfValues } =
    props.blockData[propName];
  const { onChange, fullData } = props;
  const { t } = useTranslation("base");
  const [isOpen, setIsOpen] = useState(false);

  const clickTitleHandler = () => {
    if (!curled || !controlsList?.length) {
      return;
    }

    setIsOpen(!isOpen);
  };

  const onClickHandler = (index: number, value: any) => {
    let defaultIndex: number | undefined;
    if (onChange && typeof index === "number" && controlsList?.length) {
      // get default index
      for (let i = controlsList?.length - 1; i >= 0; i--) {
        if (controlsList?.[i]?.default) {
          defaultIndex = i;
          break;
        }
      }

      // if default Clicked
      if (index === defaultIndex) {
        if (controlsList[index].active) {
          return;
        } else {
          for (let i = controlsList.length - 1; i >= 0; i--) {
            controlsList[i].active = controlsList[i].default;
            if (controlsList[i].type === CHECKBLOCKTYPES.SEARCH) {
              controlsList[i].value = undefined;
              controlsList[i].optionsList = [];
              controlsList[i].isSearch = false;
            } else if (
              controlsList[i].type === CHECKBLOCKTYPES.DATE ||
              controlsList[i].type === CHECKBLOCKTYPES.TIME
            ) {
              controlsList[i].value = undefined;
            }
          }
        }
      } else {
        // if not default Clicked
        if (typeof defaultIndex === "number" && !controlsList[index].active) {
          controlsList[defaultIndex].active = false;

          if (countOfValues === countOfValuesEnum.Single) {
            controlsList.forEach((el, i) => {
              el.active = i === index;
            });
          } else if (countOfValues === countOfValuesEnum.Array) {
            controlsList[index].active = true;
          }
        } else if (
          typeof defaultIndex === "number" &&
          controlsList[index].active
        ) {
          controlsList[index].active = false;

          if (countOfValues === countOfValuesEnum.Array) {
            const activeCount = controlsList.reduce(
              (a: number, b: IControlProps) => a + (b.active ? 1 : 0),
              0,
            );
            if (!activeCount) {
              for (let i = controlsList.length - 1; i >= 0; i--) {
                if (controlsList[i].default) {
                  controlsList[i].active = true;
                  break;
                }
              }
            }
          } else if (countOfValues === countOfValuesEnum.Single) {
            for (let i = controlsList.length - 1; i >= 0; i--) {
              if (controlsList[i].default) {
                controlsList[i].active = true;
                break;
              }
            }
          }
        }
      }

      const data = {
        [propName]: {
          ...props.blockData[propName],
          controlsList: [...controlsList],
        },
      };

      onChange(data, index);
    }
  };

  const onSelectHandler = (index: number, value: any) => {
    if (onChange && typeof index === "number" && controlsList) {
      controlsList[index].value = value;
      const data = {
        [propName]: {
          ...props.blockData[propName],
          controlsList: [...controlsList],
        },
      };

      onChange(data, index);
    }
  };

  const onDataChangeHandler = (index: number, value: any) => {
    if (onChange && typeof index === "number" && controlsList) {
      controlsList[index].value = value;

      if (index && value) {
        for (let i = controlsList.length - 1; i >= 0; i--) {
          controlsList[i].active = !!controlsList[i].value;
        }
      } else if (index && !value) {
        let noValues = true;

        for (let i = controlsList.length - 1; i >= 0; i--) {
          if (controlsList[i].value) {
            noValues = false;
          }
          controlsList[i].active = !!controlsList[i].value;
        }
        if (noValues) {
          for (let i = controlsList.length - 1; i >= 0; i--) {
            controlsList[i].active = controlsList[i].default;
          }
        }
      }

      const data = {
        [propName]: {
          ...props.blockData[propName],
          controlsList: [...controlsList],
        },
      };

      onChange(data, index);
    }
  };

  const onSliderChangeHandler = (index: number, value: any[]) => {
    if (value[0] && value[1] && value[0] > value[1]) {
      return;
    }
    if (onChange && typeof index === "number" && controlsList) {
      if (!Array.isArray(controlsList[index].value)) {
        controlsList[index].value = [];
      }
      if (value[0] !== null && value[0] !== undefined && !isNaN(+value[0])) {
        controlsList[index].value[0] = +value[0];
      }
      if (value[1] !== null && value[1] !== undefined && !isNaN(+value[1])) {
        controlsList[index].value[1] = +value[1];
      }
      const data = {
        [propName]: {
          ...props.blockData[propName],
          controlsList: [...controlsList],
        },
      };

      onChange(data, index);
    }
  };

  const onChangeSearch = (index: number, value: any) => {
    if (onChange && typeof index === "number" && controlsList) {
      controlsList[index].value = value;
      if (index && value?.value) {
        for (let i = controlsList.length - 1; i >= 0; i--) {
          controlsList[i].active = i === index;
        }
      } else if (index && !value) {
        for (let i = controlsList.length - 1; i >= 0; i--) {
          controlsList[i].active = controlsList[i].default;
          if (controlsList[i].type === CHECKBLOCKTYPES.SEARCH) {
            controlsList[i].value = undefined;
            controlsList[i].optionsList = [];
            controlsList[i].isSearch = false;
          }
        }
      }

      const data = {
        [propName]: {
          ...props.blockData[propName],
          controlsList: [...controlsList],
        },
      };

      onChange(data, index);
    }
  };

  const getDependValues = (type: CHECKBLOCKTYPES, fields: any) => {
    if (type === CHECKBLOCKTYPES.DATE) {
      if (!Array.isArray(fields)) {
        if (!fields.name) {
          return;
        }

        if (typeof fields.id === "number") {
          {
            return {
              // @ts-ignore
              value: fullData[fields.name].controlsList[fields.id].value,
            };
          }
        }
      }
    }

    return {};
  };

  const getControl = (props: IControlProps) => {
    const {
      type,
      placeholder,
      active,
      index,
      name,
      value,
      disabledDates,
      dependsOn,
      optionsList,
      isSearch,
      showNow,
    } = props;

    switch (type || CHECKBLOCKTYPES.CHECKBOX) {
      case CHECKBLOCKTYPES.CHECKBOX:
        return (
          (active && (
            <button
              onClick={() => {
                if (placeholder) {
                  onClickHandler(index || 0, value);
                }
              }}
              className={"active-btn cursor-pointer no-select"}
            >
              {placeholder ? t(placeholder) : ""}
            </button>
          )) ||
          (!active && (
            <ButtonWhite
              type={"primary"}
              onClick={() => {
                if (placeholder) {
                  onClickHandler(index || 0, value);
                }
              }}
              size={"small"}
              className={`${!placeholder ? "text-color-secondary" : ""}`}
              disabled={!placeholder}
            >
              {placeholder ? t(placeholder) : "UNEXPECTED VALUE"}
            </ButtonWhite>
          ))
        );
      case CHECKBLOCKTYPES.DATE:
        return (
          <DatePicker
            name={name}
            style={{ width: "121px" }}
            className={`${active ? "active" : ""}`}
            showToday={false}
            format={"DD.MM.YY"}
            disabledDate={
              disabledDates
                ? disabledDates
                : (current) => {
                    return dependsOn?.condition({
                      value: getDependValues(
                        CHECKBLOCKTYPES.DATE,
                        dependsOn.field,
                        // @ts-ignore
                      )?.value,
                      current,
                    });
                  }
            }
            value={value ? moment(value) : undefined}
            placeholder={t(placeholder || "")}
            onChange={(val) => onDataChangeHandler(index || 0, val)}
            size={"small"}
          />
        );
      case CHECKBLOCKTYPES.TIME:
        return (
          <TimePicker
            name={name}
            className={`${active ? "active" : ""}`}
            style={{ width: "121px" }}
            format={"HH.mm"}
            value={value ? moment(value) : undefined}
            placeholder={t(placeholder || "")}
            onChange={(val) => onDataChangeHandler(index || 0, val)}
            size={"small"}
            showNow={showNow}
          />
        );
      case CHECKBLOCKTYPES.SLIDER:
        return (
          <div>
            <Slider
              range={true}
              min={0}
              max={1000}
              style={{ width: "220px" }}
              defaultValue={value}
              onAfterChange={(val) => onSliderChangeHandler(index || 0, val)}
            />
            <div className="flex-space-between">
              <Input
                min={0}
                max={1000}
                style={{ margin: "8px 8px 0 0", width: "112px" }}
                prefix={"from"}
                placeholder={"from"}
                defaultValue={value[0]}
                onBlur={({ target }) => {
                  onSliderChangeHandler(index || 0, [target.value, null]);
                }}
                size={"small"}
              />
              <Input
                min={0}
                max={1000}
                defaultValue={value[1]}
                style={{ marginTop: "8px", width: "112px" }}
                prefix={"to"}
                placeholder={t("courses.to")}
                onBlur={({ target }) => {
                  onSliderChangeHandler(index || 0, [null, target.value]);
                }}
                size={"small"}
              />
            </div>
          </div>
        );
      case CHECKBLOCKTYPES.SEARCH:
        let timer: any;
        const changeSearch = (val: string) => {
          if (timer) {
            clearTimeout(timer);
          }

          timer = setTimeout(() => onChangeSearch(index || 0, val), 500);
        };
        return (
          <AutoComplete
            allowClear={true}
            className={active ? "active" : ""}
            onSelect={(value: any, option: any) => {
              onChangeSearch(index || 0, option);
            }}
            onSearch={(value) => {
              changeSearch(value);
            }}
            style={{ width: 247 }}
            disabled={false}
            options={
              // @ts-ignore
              optionsList?.map((option: any, index: number) => (
                // @ts-ignore
                <Option key={index} value={option.text}>
                  {option.text}
                </Option>
              ))
            }
            defaultValue={
              value
                ? typeof value === "string"
                  ? value
                  : value.value
                : undefined
            }
            open={isSearch}
            size="small"
          >
            <Input
              size="small"
              suffix={<i className="ri-search-line" />}
              placeholder={t(placeholder || "")}
            />
          </AutoComplete>
        );
      case CHECKBLOCKTYPES.DROPDOWN:
        return (
          <Select
            style={{ width: "150px" }}
            disabled={!optionsList?.length}
            placeholder={t(placeholder || "")}
            size={"small"}
            value={value}
            onSelect={(val: any) => onSelectHandler(index || 0, val)}
            suffixIcon={<i className="ri-arrow-drop-down-line fs-20" />}
          >
            {
              // @ts-ignore
              optionsList?.map((el: any) => {
                return (
                  <Select.Option key={Math.random()} value={el}>
                    {el}
                  </Select.Option>
                );
              })
            }
          </Select>
        );
      default:
        return;
    }
  };
  return (
    <div className={"biz-chkblock"} key={Math.random()}>
      <div
        className={`biz-chkblock-title no-select ${
          curled ? "cursor-pointer no-select" : ""
        }`}
        style={{ minWidth: "260px" }}
        onClick={clickTitleHandler}
      >
        <span style={{ whiteSpace: "nowrap" }}>{title || ""}</span>
        {curled && (
          <i
            className={
              isOpen ? "ri-arrow-drop-up-line" : "ri-arrow-drop-down-line"
            }
            style={{ fontSize: "24px" }}
          />
        )}
      </div>
      {(!curled || (curled && isOpen)) && (
        <div className={"buttons"}>
          {controlsList?.map((el, index) => {
            return (
              <div
                className={"biz-chkblock-button-wrapper"}
                key={Math.random()}
                style={{ margin: "5px 8px 5px 0" }}
              >
                {getControl({ ...el, index })}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
