import React from "react";
import { Popover } from "antd";
import { PRICING } from "constants/bizConstants";

export const tableAgreeColumns = () => {
  return [
    {
      dataIndex: "name",
      key: "name",
      render: (text: React.ReactNode) => {
        return (
          <div>
            <Popover
              overlayClassName={`popover-ellipsis`}
              content={text}
              trigger="hover"
            >
              <div className="ellipsis">{text}</div>
            </Popover>
          </div>
        );
      },
    },
    {
      // title: "Introductory Period (6 months)**",
      ellipsis: true,
      dataIndex: "suburb",
      key: "suburb",
      render: (text: any) => (
        <Popover
          overlayClassName={`popover-ellipsis`}
          content={text}
          trigger="hover"
        >
          <div className="ellipsis">{text}</div>
        </Popover>
      ),
    },
  ];
};
export const tableAgreeData = () => {
  return [
    {
      name: "Applied to gross customer spend - ZH",
      suburb: PRICING,
    },
  ];
};
