import React from "react";
import { bizIcons as tb } from "../../constants/bizConstants";
import "./index.less";
import { Rate } from "antd";
import { RateProps } from "antd/lib/rate";

interface IProps extends RateProps {
  fontSize?: string;
  showCount?: boolean;
}

export const Rating = (props: IProps) => {
  const { showCount, className, style } = props;
  const fontSize = props.fontSize || "16px";
  return (
    <div className={`rating-container ${className}`} style={style}>
      <Rate
        character={
          <i
            className={tb.STAR}
            style={{
              fontSize,
            }}
          />
        }
        {...props}
      />
      {showCount && (
        <span className="rating-count">{props.value?.toFixed(1)}</span>
      )}
    </div>
  );
};
