import { DETAIL_API_URL } from "constants/apiUrl";
import requestApi from "helpers/requestApi";
import { action, computed, makeObservable, observable } from "mobx";
import { createContext } from "react";

export class ProviderStore {
  constructor() {
    makeObservable(this);
  }

  @observable
  private providerData: any = null;

  @observable
  private providersDataList: any = null;

  @action
  public updateProviderInfo(data: any) {
    this.providerData = {
      ...this.providerData,
      ...data,
    };

    return true;
  }

  @action
  public async getProviders() {
    const result = await requestApi.get(DETAIL_API_URL.PROVIDER_LIST);
    try {
      this.providersDataList = result?.data;
      return Promise.resolve(result?.data);
    } catch {
      return Promise.reject();
    }
  }

  @computed
  public get providerInfo() {
    return this.providerData;
  }

  @computed
  public get providerList() {
    return this.providersDataList;
  }

  @computed
  public get bulkInfo() {
    // const bulkData = Object.entries(this.providerData).filter(([key]) =>
    //   key?.includes("bulk"),
    // );
    // return Object.fromEntries(bulkData);
    return this.providerData;
  }
}

const providerStore = new ProviderStore();

export const providerStoreContext = createContext(providerStore);
export default providerStore;
