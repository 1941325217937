import { HeadSeo } from "components/HeadSeo";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import { LayoutPage } from "../../components/Layout";
import { ContentWithTableCustomers } from "./contentWithTableCustomers";
import "./index.less";

interface IProps {}
const Customers = inject("customersStore")(
  observer((props: IProps) => {
    const { t } = useTranslation("base");
    return (
      <>
        <HeadSeo title={t("menu.customers")} />
        <LayoutPage defMenuItem={"customers"} className="layout-customers">
          <ContentWithTableCustomers />
        </LayoutPage>
      </>
    );
  }),
);

export default Customers;
