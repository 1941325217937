export const AppRoutes = {
    home: "/",
    signup: "/signup",
    courses: "/courses",
    courseId: "/course/:id",
    courseDetail: (id) => `/view-course/${id}`,
    courseCreate: "/courses/create",
    courseEdit: (id) => `/courses/edit/${id}`,
    faqs: "/faqs",
    faqId: (tab) => `/faqs#${tab}`,
    faqDetail: (tab) => `/faqs?id=${tab}`,
    customers: "/customers",
    customerId: (id) => `/view-customer/${id}`,
    stripeAcc: "/stripeAccountOnboard",

    bookings: "/bookings",

    //Promotions
    promotions: "/promotions",
    promotionsAdd: "/promotions/add",
    promotionsEdit: (type, id) => `/promotions/edit-${type}/${id}`,
    promotionsView: (type, id) => `/promotions/view-${type}/${id}`,

    //Reviews
    reviews: "/reviews",
    reviewId: (id) => `/review/${id}`,

    //Payments
    payments: "/payments",
    paymentDetail: (type, id) => `/payment-${type}-details/${id}`,
    
    //customerURL
    customerURL: "https://dev01.bizibuz.com"
};