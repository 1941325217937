import React, { ReactElement, useState } from "react";
import { Upload } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import "./index.less";
import { useTranslation } from "react-i18next";
import helpers from "scripts/helpers";
import {
  IMAGE_LIMIT_SIZE,
  UPLOAD_ALLOW_TYPES_OF_IMAGE,
} from "constants/commons";

interface IProps extends React.HTMLAttributes<HTMLElement> {
  onUploadChanged?: (info: File) => void;
  onCheckBeforeUpload?: (messages: string[]) => void;
  uploadButtonFunc?: (isUploading: boolean) => ReactElement;
  onBeforeUpload?: (file: any) => void;
  imageUrl?: string;
  name?: string;
  accept?: string;
  disabled?: boolean;
}

const ImageUploader = (props: IProps) => {
  const {
    onUploadChanged,
    onCheckBeforeUpload,
    onBeforeUpload,
    uploadButtonFunc,
    imageUrl,
    accept,
    disabled,
  } = props;
  const { t } = useTranslation("base");
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [errorText, setErrorText] = useState<string>("");

  const onChange = (info: any) => {
    if (info.file.status === "uploading") {
      setIsImageLoading(true);

      return;
    }

    if (info.file.status === "done" && onUploadChanged !== undefined) {
      onUploadChanged(info.file.originFileObj);
      setIsImageLoading(false);
      setErrorText("");
    }
  };

  const customUploadRequest = (data: any) => {
    if (typeof data.onSuccess === "function") {
      data.onSuccess();
    }
  };

  const beforeUpload = (file: any): boolean | PromiseLike<void> => {
    const messages: string[] = [];

    const isJpgOrPng = UPLOAD_ALLOW_TYPES_OF_IMAGE.includes(
      helpers.getFileType(file.name),
    );
    if (!isJpgOrPng) {
      setErrorText(t("messages.please_choose_file"));
      // messages.push(t("messages.please_choose_file"));
    }
    const isLt3M = file.size <= IMAGE_LIMIT_SIZE;
    if (!isLt3M) {
      setErrorText(
        t("messages.max_size_image", { size: IMAGE_LIMIT_SIZE / 1024 / 1024 }),
      );
      // messages.push(
      //   t("messages.max_size_image", { size: IMAGE_LIMIT_SIZE / 1024 / 1024 }),
      // );
    }

    if (onCheckBeforeUpload !== undefined) {
      onCheckBeforeUpload(messages);
    }

    return isJpgOrPng && isLt3M;
  };

  const uploadButton = (
    <div>
      <div style={{ height: "24px" }} className={"flex-center"}>
        {isImageLoading ? <LoadingOutlined /> : <PlusOutlined />}
      </div>
      <div className={"text-color-secondary"} style={{ marginTop: 8 }}>
        {t("signUp.step4.uploadImage", {
          defaultValue: "Upload image",
        })}
      </div>
    </div>
  );

  return (
    <>
      <Upload
        {...props}
        listType="picture-card"
        className={`image-uploader ${errorText ? "error" : ""}`}
        accept={accept}
        showUploadList={false}
        customRequest={customUploadRequest}
        beforeUpload={onBeforeUpload || (beforeUpload as any)}
        onChange={onChange}
        disabled={disabled}
      >
        {imageUrl ? (
          <div
            style={{ backgroundImage: `url(${imageUrl})` }}
            className="uploaded-image"
          />
        ) : uploadButtonFunc ? (
          uploadButtonFunc(isImageLoading)
        ) : (
          uploadButton
        )}
      </Upload>
      {errorText && <p className="error-text mt-0"> {errorText}</p>}
    </>
  );
};

export default ImageUploader;
