export interface ICustomerChildData {
  id: number;
  name?: string;
  age?: string;
  birthday?: string;
  school?: string;
  medical?: string;
  isEnrolled?: boolean;
}
export const CLASS_DATES = [
  {
    date: "2021-09-08",
    startTime: "08:30",
    endTime: "09:00",
    dayOfWeek: "WEDNESDAY",
  },
  {
    date: "2021-09-13",
    startTime: "08:30",
    endTime: "09:00",
    dayOfWeek: "MONDAY",
  },
  {
    date: "2021-09-15",
    startTime: "08:30",
    endTime: "09:00",
    dayOfWeek: "WEDNESDAY",
  },
  {
    date: "2021-09-20",
    startTime: "08:30",
    endTime: "09:00",
    dayOfWeek: "MONDAY",
  },
  {
    date: "2021-09-22",
    startTime: "08:30",
    endTime: "09:00",
    dayOfWeek: "WEDNESDAY",
  },
  {
    date: "2021-09-27",
    startTime: "08:30",
    endTime: "09:00",
    dayOfWeek: "MONDAY",
  },
  {
    date: "2021-09-29",
    startTime: "08:30",
    endTime: "09:00",
    dayOfWeek: "WEDNESDAY",
  },
  {
    date: "2021-10-04",
    startTime: "08:30",
    endTime: "09:00",
    dayOfWeek: "MONDAY",
  },
  {
    date: "2021-10-06",
    startTime: "08:30",
    endTime: "09:00",
    dayOfWeek: "WEDNESDAY",
  },
  {
    date: "2021-10-11",
    startTime: "08:30",
    endTime: "09:00",
    dayOfWeek: "MONDAY",
  },
];
