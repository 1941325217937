import React, { useContext } from "react";
import { getLanguage } from "scripts/custom-hooks/useLanguage";
import {
  Languages,
  LanguageStore,
  languageStoreContext,
} from "../../../scripts/mobx/languageStore";
import EnPricingSchedule from "./en";
import ZhPricingSchedule from "./zh";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";

interface ILingualBlock extends React.HTMLAttributes<HTMLElement> {
  languageStore?: LanguageStore;
}

const PricingSchedule = inject("languageStore")(
  observer((props: ILingualBlock) => {
    const language = useContext(languageStoreContext);
    return (
      <>
        {language?.lang === Languages.ZH ? (
          <ZhPricingSchedule />
        ) : (
          <EnPricingSchedule />
        )}
      </>
    );
  }),
);

export default PricingSchedule;
