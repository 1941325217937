import { configure } from "mobx";
import userStore, { UserStore } from "./userStore";
import languageStore, { LanguageStore } from "./languageStore";
import courseStore, { CourseStore } from "./courseStore";
import locationsStore, { LocationsStore } from "./locationStore";
import employeeStore, { EmployeeStore } from "./employeeStore";
import bookingsStore, { BookingsStore } from "./bookingsStore";
import customersStore, { CustomersStore } from "./customersStore";
import linkedAccountStore, { LinkedAccountStore } from "./linkedAccountStore";
import promotionsStore, { PromotionsStore } from "./promotionsStore";
import providerStore, { ProviderStore } from "./providerStore";
import providerReviewsStore, {
  ProviderReviewsStore,
} from "./providerReviewsStore";
import notificationsStore, { NotificationsStore } from "./notificationsStore";
import providerOrdersStore, {
  ProviderOrdersStore,
} from "./providerOrdersStore";
import reportsStore, { ReportsStore } from "./reportsStore";
import reviewsStore, { ReviewsStore } from "./reviewsStore";

configure({
  disableErrorBoundaries: false,
});

const StoreMobx = {
  languageStore,
  userStore,
  courseStore,
  locationsStore,
  employeeStore,
  bookingsStore,
  customersStore,
  linkedAccountStore,
  promotionsStore,
  providerStore,
  providerReviewsStore,
  notificationsStore,
  providerOrdersStore,
  reportsStore,
  reviewsStore,
};

export interface IStoreMobx {
  languageStore: LanguageStore;
  userStore: UserStore;
  courseStore: CourseStore;
  locationsStore: LocationsStore;
  employeeStore: EmployeeStore;
  bookingsStore: BookingsStore;
  customersStore: CustomersStore;
  linkedAccountStore: LinkedAccountStore;
  promotionsStore: PromotionsStore;
  providerStore: ProviderStore;
  providerReviewsStore: ProviderReviewsStore;
  notificationsStore: NotificationsStore;
  providerOrdersStore: ProviderOrdersStore;
  reportsStore: ReportsStore;
  reviewsStore: ReviewsStore;
}

export default StoreMobx;
