import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { userStoreContext } from "scripts/mobx/userStore";

let bizcharts: any;
// @ts-ignore
if (process.browser) {
  // tslint:disable-next-line:no-var-requires
  bizcharts = require("bizcharts");
}

interface IProps {
  colors?: string[];
  data?: any[];
  autoFit?: boolean;
}

const OfflineOnlineSpendSplitByCategoryReport = (props: IProps) => {
  // const colors = props.colors || ["#5D7092", "#6DC8EC", "#5D7092", "#F6BD16"];
  const { data } = props;
  const userStore = useContext(userStoreContext);
  // const ds = new DataSet();
  // const dv = ds.createView().source(newdata);
  // dv.transform({
  //   type: "fold",
  //   fields: [
  //     "level_s_amount",
  //     "level_a_amount",
  //     "level_b_amount",
  //     "level_c_amount",
  //   ],
  //   // fields: ['time'],
  //   // 展开字段集
  //   key: "key",
  //   // key字段
  //   value: "value", // value字段
  // }).transform({
  //   type: "map",
  //   callback: (obj) => {
  //     if (obj.key.indexOf("amount") !== -1) {
  //       obj.type = "type";
  //     }
  //     obj.level = obj.key.split("_")[1].toUpperCase() + "whats here";
  //
  //     return obj;
  //   },
  // });
  const chartProps =
    props.autoFit === true
      ? {
          autoFit: true,
          key: "fit",
        }
      : {
          key: "no-fit",
          width: 600,
          forceFit: true,
        };

  return (
    <div className={"chart-wrapper"}>
      {/*// @ts-ignore*/}
      {process.browser && (
        <bizcharts.Chart
          {...chartProps}
          height={400}
          padding="auto"
          data={data}
          // scale={getScale()}
          // data={data}
        >
          {/*<bizcharts.Axis name={'numOfCustomers'} label={null} />*/}
          <bizcharts.Legend visible={false} />
          <bizcharts.Tooltip shared={true} name={""} value={false} />
          <bizcharts.Geom
            type="interval"
            position="axisValue*value"
            color="item"
            // label={[
            //   "value",
            //   {
            //     position: "middle",
            //     offset: 0,
            //     // style: { fill: "#fff" },
            //     // layout: { type: "limit-in-shape" },
            //   },
            // ]}
            tooltip={[
              "axisValue*value*item*percent",
              (axisValue: any, value: string, item: any, percent: string) => {
                return {
                  name: item,
                  value: percent + "% / " + value + userStore.currency,
                };
              },
            ]}
            adjust={[
              {
                type: "stack",
              },
            ]}
          />
        </bizcharts.Chart>
      )}
    </div>
  );
};

export default observer(OfflineOnlineSpendSplitByCategoryReport);
