import React from "react";
let bizcharts: any;
// @ts-ignore
if (process.browser) {
  // tslint:disable-next-line:no-var-requires
  bizcharts = require("bizcharts");
}

interface IProps {
  colors?: string[];
  data?: any;
  autoFit?: boolean;
}

const CircleChart = (props: IProps) => {
  const { data } = props;

  const _data = data.map((el: any) => {
    return {
      value: el.total,
      percent: el.percent,
      name: el.category,
    };
  });

  return (
    <bizcharts.Chart
      height={400}
      data={_data}
      autoFit={true}
      scale={{
        percent: {
          formatter: (val: any) => {
            // val = (val * 100).toFixed(2) + "%";
            return val + "%";
          },
        },
      }}
    >
      <bizcharts.Coordinate type="theta" radius={0.5} />
      <bizcharts.Axis visible={false} />
      <bizcharts.Legend
        visible={true}
        position={"right"}
        itemWidth={100}
        maxWidth={100}
        offsetX={-50}
      />
      <bizcharts.Tooltip showTitle={false} />

      <bizcharts.View data={_data}>
        <bizcharts.Coordinate
          type="theta"
          radius={0.75}
          innerRadius={0.5 / 0.75}
        />
        <bizcharts.Interval
          position="percent"
          adjust="stack"
          color={[
            "name",
            [
              "#BAE7FF",
              "#7FC9FE",
              "#71E3E3",
              "#ABF5F5",
              "#8EE0A1",
              "#BAF5C4",
              "#F6BD16",
            ],
          ]}
          element-highlight={true}
          // label="name"
        />
        <bizcharts.Annotation.Text
          position={["50%", "47%"]}
          content="100%"
          style={{
            lineHeight: "20px",
            fontSize: "12",
            fill: "#00000",
            opacity: "0.45",
            textAlign: "center",
          }}
        />
        <bizcharts.Annotation.Text
          position={["50%", "53%"]}
          content="HKD 221 380"
          style={{
            lineHeight: "24px",
            fontSize: "16",
            fontWeight: 500,
            fill: "#262626",
            textAlign: "center",
          }}
        />
      </bizcharts.View>
    </bizcharts.Chart>
  );
};

export default CircleChart;
