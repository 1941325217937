import React from "react";
import { Typography } from "antd";
import TableComponent from "../../../../components/TableComponent/TableComponent";
import { tableCookiesColumns, tableCookiesData } from "./tableGenerator";
const { Title } = Typography;

const ZhCookiePolicy = () => {
  return (
    <>
      <Title>Cookie政策</Title>
      <div className={"bizibuz-policies-lists"}>
        <ol>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
            Cookies背景
            </Title>
            <ol>
              <li>
              Bizibuz致力於提高您使用Bizibuz平台的體驗，因此，可能會使用cookie管理網站並改善您的網站體驗。
              </li>
              <li>
              Cookies在互聯網上被廣泛使用，Cookies是您瀏覽的網站放置在您電腦上的文本文件。在之後的每次瀏覽時，cookie會被發送回原始網站，或發送到識別該cookie的另一個網站。它們被用於幫助網站工作，或更有效地工作，以及向網站所有者提供信息。我們在本通知中使用術語“cookie”來涵蓋所有類似的技術，如網頁信標SDK、日誌文件、像素標籤。
              </li>
              <li>
              Cookie可按此分類 (i) 持續時長和 (ii) 放置它的人: <br /> 
              (i) 會話和持久化cookie:一旦瀏覽會話結束，會話cookie就會過期。持久化cookie會在您的電腦或移動設備上保存一段固定的時間。當您瀏覽一個網站時，您的瀏覽器會檢索這些cookies，這樣網站就會識別您的設備，並根據您以前的瀏覽記錄將網站體驗個性化。 <br />
              (ii) 第一方和第三方cookie: Bizibuz設置了第一方cookie的範圍，但對由不同第三方網站運營域名的第三方cookie沒有控制權。
              </li>
            </ol>
          </li>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
            為什麼要使用cookie?
            </Title>
            <ol>
              <li>
              Bizibuz使用cookie提升您瀏覽Bizibuz平台的安全、效率和定制服務體驗。具體而言，我們將cookies的使用限制為:  <br /> 
              (i) 確保本網站有效運行; <br />
(ii) 評估如何瀏覽和使用本網站; <br />
(iii) 分析哪些教育活動最受用戶歡迎; <br />
(iv) 確定您參加活動和促銷的資格 <br />
(v) 推薦匹配您興趣的教育活動; <br />
(vi) 監督活動供應商是否遵守Kidzbiz協議條款;和 <br />
(vii) 改善本網站的內容，提升用戶體驗。 <br />

              </li>
            </ol>
          </li>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
            使用什麼類型的cookie？
            </Title>
            <ol>
              <li>
              Bizibuz使用了四種類型的cookie，即“功能cookie”、“分析cookie”、“廣告cookie”和“社交媒體cookie”。有關這些cookie的更多資料請見下表:
                <div className={"tsl-3 bsl-3"}>
                  <TableComponent
                    propsTableColumns={tableCookiesColumns()}
                    propsTableData={tableCookiesData()}
                    pagination={false}
                  />
                </div>
              </li>
            </ol>
          </li>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
            同意cookies
            </Title>
            <ol>
              <li>
              在大多數情況下，我們需要您同意才能在本網站上使用cookies。例外為，我們為向您提供您所要求的服務而必須用到cookie，或對本網站的固有功能至關重要。
              </li>
              <li>
              在您首次瀏覽我們的網站時，您將在頁面底部看到一個通知橫幅，通知您我們使用了cookie。繼續使用本網站而不更改您的設置，即表示您同意接收所有cookies和其他數據跟踪工具。如果您不希望接受cookie的使用，您可以隨時修改您的cookie設置。
              </li>
            </ol>
          </li>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
            如何管理您的同意?
            </Title>
            <ol>
              <li>
              您可以隨時通過管理和/或禁用您的瀏覽器設置中的全部或部分cookie來接受或拒絕我們對cookie的使用。但是，通過此操作，您可能無法享受本網站的全部功能。
              </li>
              <li>
              通常可以在瀏覽器的首選項或設置菜單中找到Cookies設置，例如: <br /> 
              (i) Chrome: 設置 – 高級 – 內容設置 -- Cookies <br /> 
(ii) Safari: 偏好 – 隱私 – Cookies和網站數據 <br /> 
(iii) Firefox: 首選項 – 隱私 – 歷史 <br /> 
(iv) Internet Explorer: Internet 選項 – 隱私 <br /> 
              </li>
            </ol>
          </li>

          <li className="first-lvl">
            <Title level={3} className="sub-header">
            其他事項
            </Title>
            <ol>
              <li>
              本Cookie政策以英語起草並解釋。本Cookie政策之翻譯僅供參考。如英語版本和翻譯版本之間存在不一致，則以英語版本為準。
              </li>
            </ol>
          </li>
        </ol>
        <div className={"bsl-3 tsl-3"}>最後更新於: 2021年4月22日。</div>
      </div>
    </>
  );
};

export default ZhCookiePolicy;
