import React from "react";
import notifyImg from "../../../../assets/no-notifications.svg";

export const EmptyBlock = () => {
  return (
    <div className={"flex-center"}>
      <div className={"flex-center"} style={{ margin: "16px 24px" }}>
        <img src={notifyImg} alt="no notifications image" />
      </div>
    </div>
  );
};
