import React from "react";
import "./index.less";
import { Typography } from "antd";
const { Title } = Typography;

interface IProps extends React.HTMLAttributes<HTMLElement> {
  title?: string;
  text?: string;
  imageSrc?: string;
  getButton?: () => React.ReactElement;
}

const GreySquare = (props: IProps) => {
  const { title, text, imageSrc, className, getButton } = props;
  return (
    <div className={`grey-square-wrapper ${className}`}>
      {!!title && (
        <div className={"grey-square-title tsl-2"}>
          <Title level={1}>{title}</Title>
        </div>
      )}
      <div className={"grey-square full-width tsl-2"}>
        <div className={""}>{text}</div>
        {!!imageSrc && (
          <div>
            <img src={imageSrc} alt="image-in-square" />
          </div>
        )}
        {!!getButton && getButton()}
      </div>
    </div>
  );
};

export default GreySquare;
