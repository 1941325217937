export const TAB = [
  {
    id: "pricing",
    name_en: "Pricing",
    name_zh: "Pricing - ZH",
  },
  {
    id: "signup",
    name_en: "Sign Up",
    name_zh: "Sign Up - ZH",
  },
  {
    id: "profile",
    name_en: "Profile",
    name_zh: "Profile - ZH",
  },
  {
    id: "courses",
    name_en: "Courses",
    name_zh: "Courses - ZH",
  },
  {
    id: "booking",
    name_en: "Booking",
    name_zh: "Booking - ZH",
  },
  {
    id: "cancellation",
    name_en: "Cancellation",
    name_zh: "Cancellation - ZH",
  },
  {
    id: "payment",
    name_en: "Payment",
    name_zh: "Payment - ZH",
  },
  {
    id: "reports",
    name_en: "Reports",
    name_zh: "Reports - ZH",
  },
  {
    id: "other",
    name_en: "Other",
    name_zh: "Other - ZH",
  },
];
