import React, { useEffect, useState } from "react";
import {
  CHECKBLOCKTYPES,
  countOfValuesEnum,
  IChkBlockProps,
  IControlProps,
} from "components/CheckBoxes/ChkBlock";
import { Filters } from "../../../components/Filters";
import { observer } from "mobx-react";
import ButtonLink from "../../../components/ButtonLink";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { CITY_DATA } from "pages/signup/interface";
import courseStore from "scripts/mobx/courseStore";
import providerStore from "scripts/mobx/providerStore";
import { toJS } from "mobx";
import { TFunction, useTranslation } from "react-i18next";
import { experimentals } from "config/experimentals";

// @ts-ignore
const getTableFiltersData: (
  getProvidersListBySearch: any,
  categories?: any,
  getSuburbList?: any,
  userType?: boolean,
  providers?: any,
  t?: TFunction<"translation">,
) => IChkBlockProps = (
  getProvidersListBySearch,
  categories,
  getSuburbList,
  userType,
  providers,
  t,
) => {
  return {
    dateFromTo: {
      title: t?.("bookings.dateFromTo"),
      combined: true,
      controlsList: [
        {
          value: "",
          active: true,
          default: true,
          placeholder: "bizBase.all",
        },
        {
          value: "",
          markId: "dateFrom",
          type: CHECKBLOCKTYPES.DATE,
          dependsOn: {
            field: {
              name: "dateFromTo",
              id: 2,
            },
            condition: (data) => {
              const { value, current } = data;
              return value && current && current > value?.startOf("day");
            },
          },
          placeholder: "bookings.dateFrom",
        },
        {
          value: "",
          markId: "dateTo",
          type: CHECKBLOCKTYPES.DATE,
          placeholder: "bookings.dateTo",
          dependsOn: {
            field: {
              name: "dateFromTo",
              id: 1,
            },
            condition: (data) => {
              const { value, current } = data;
              return value && current && current < value?.startOf("day");
            },
          },
        },
      ],
    },
    categories: {
      title: t?.("courses.category"),
      countOfValues: countOfValuesEnum.Array,
      controlsList: [
        {
          value: "",
          active: true,
          default: true,
          placeholder: "bizBase.all",
        },
        ...(categories || [])?.map((el: any) => {
          return {
            value: el.id,
            placeholder: "categories." + el.name,
            active: false,
            default: false,
          };
        }),
      ],
    },
    suburbs: {
      title: t?.("bizBase.location"),
      countOfValues: countOfValuesEnum.Array,
      controlsList: [
        {
          value: "",
          active: true,
          default: true,
          placeholder: "bizBase.all",
        },
        {
          value: "0",
          active: false,
          default: false,
          placeholder: "courses.online",
        },
        {
          value: "-3",
          active: false,
          default: false,
          placeholder: "bizBase.onDemand",
        },
        {
          value: "-2",
          active: false,
          default: false,
          placeholder: "bizBase.parentHome",
        },
        ...getSuburbList,
      ].filter((item) => {
        if (experimentals.on_demand_location) return true;
        return item.value !== "-3";
      }),
    },
    providerId: {
      title: t?.("menu.kidzbiz"),
      countOfValues: countOfValuesEnum.Array,
      controlsList: userType && providers,
    },
  };
};
interface ICoursesFiltersProps extends RouteComponentProps {
  onApply: (data: any) => void;
  userType: boolean;
}
export const ReviewsFilters = withRouter(
  observer((props: ICoursesFiltersProps): React.ReactElement => {
    const { onApply, userType } = props;
    const { t } = useTranslation();

    const [tableFiltersData, setTableFiltersData] = useState<IChkBlockProps>();
    const [isFilterActive, setIsFilterActive] = useState<boolean>(false);

    // const getProvidersListBySearch = async (search: string) => {
    //   if (providerStore?.satellitesData?.length) {
    //     return providerStore?.satellitesData
    //       .filter((el) => {
    //         return el?.organizationLegalName?.includes(search);
    //       })
    //       .map((el) => {
    //         return {
    //           value: el.id,
    //           text: el.organizationLegalName,
    //         };
    //       });
    //   }
    //   return [];
    // };

    useEffect(() => {
      (async () => {
        const suburbs: any[] = [];
        CITY_DATA.forEach((el) => {
          suburbs.push(...el.suburbs);
        });

        const newSuburbs = suburbs.map((el: any) => {
          return { value: el.value, placeholder: "suburbs." + el.value };
        });
        await providerStore?.getProviders();
        await courseStore.getCategories().then((res) => {
          const tableFiltersData = getTableFiltersData(
            "",
            res,
            newSuburbs,
            userType,
            toJS(providerStore?.providerList)?.map((el: any) => {
              return {
                value: el?.id,
                placeholder: el?.legalName,
                active: false,
                default: false,
              };
            }),
            t,
          );

          // tslint:disable-next-line:forin
          for (const filterKey in tableFiltersData) {
            /** ADDING 'ALL' BUTTONS */
            if (
              tableFiltersData[filterKey]?.controlsList &&
              // @ts-ignore
              !tableFiltersData[filterKey]?.controlsList[0]?.default
            ) {
              // @ts-ignore
              tableFiltersData[filterKey].controlsList.unshift({
                value: "",
                placeholder: "bizBase.all",
                active: true,
                default: true,
              });
            }

            setTableFiltersData(tableFiltersData);
          }
        });
      })();
    }, []);

    const demapFilterData = (data: any): any => {
      const _data = {};

      Object.keys(data).forEach((key) => {
        if (!data[key].combined) {
          _data[key] = data[key].controlsList?.map(
            (el: IControlProps) => el.value,
          );
        } else {
          data[key].controlsList?.forEach((el: IControlProps) => {
            if (el.markId) {
              _data[el.markId] = el.value;
            }
          });
        }
      });

      return _data;
    };

    const onApplyFilters = (data: any) => {
      const _data = { ...demapFilterData(data) };
      onApply(_data);
    };

    return tableFiltersData ? (
      <Filters
        onApply={onApplyFilters}
        filterBlocks={tableFiltersData}
        isFilterActive={isFilterActive}
      />
    ) : (
      <div>
        <ButtonLink disabled={true}>
          {t("bizBase.filters", {
            defaultValue: "Filters",
          })}
          <i
            className={"ri-arrow-drop-down-line"}
            style={{ fontSize: "20px" }}
          />
        </ButtonLink>
      </div>
    );
  }),
);
