import React, { useEffect, useState } from "react";
import CustomersAndRevenueSummaryChart from "./CustomersAndRevenueSummaryChart";
import { inject, observer } from "mobx-react";
import { ReportsStore } from "../../../../scripts/mobx/reportsStore";
import GreySquare from "../../../../components/GreySquare";
import emptyTableSVG from "../../../../assets/courses-empty.svg";
import { ChartFilters } from "./filters";
import { useTranslation } from "react-i18next";

interface IProps {
  autoFit?: boolean;
  colors?: string[];
  reportsStore?: ReportsStore;
}

const CustomersAndRevenueSummary = inject("reportsStore")(
  observer((props: IProps) => {
    const { autoFit, reportsStore } = props;
    const { t } = useTranslation();
    const [data, setData] = useState<any[] | undefined>();
    // const [data, setData] = useState<ProviderReportCustomersAndRevenueListItemModel[] | undefined>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const mapper = (data?: any[]): any[] => {
      const _data: any[] = [];

      data?.forEach((el) => {
        const rec = {
          type: "recurring",
          month: `${el.year}-${String(el.month).padStart(2, "0")}`,
          organizationRevenue: el.organizarionRecurringRevenue,
          numberOfCustomers: el.numberOfRecurringCustomers,
        };
        const def = {
          type: "default",
          month: `${el.year}-${String(el.month).padStart(2, "0")}`,
          organizationRevenue: el.organizationRevenue,
          numberOfCustomers: el.numberOfCustomers,
        };
        _data.push(rec, def);
      });
      return _data;
    };

    useEffect(() => {
      reportsStore?.getReportCustomersAndRevenueSummary({}).then((el) => {
        console.log(el);

        setData(mapper(el?.items || undefined));
      });
    }, []);

    const updateFilter = (filterObject?: any) => {
      setIsLoading(true);
      reportsStore
        ?.getReportCustomersAndRevenueSummary({ ...filterObject })
        .then((el) => {
          setData(mapper(el?.items || undefined));
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    return (
      <div className={"chart-block tsl-4 bsl-4"}>
        <div className={"chart-block-header bsl-3"}>
          <div className={"chart-block-title title"}>
            {t("reports.customers_organizations_revenue_chart", {
              defaultValue: "Сustomers / Organizations revenue chart",
            })}
          </div>
          <ChartFilters t={t} onApply={updateFilter} />
        </div>
        <div className={"chart-wrapper"}>
          {data?.length && (
            <CustomersAndRevenueSummaryChart autoFit={autoFit} data={data} />
          )}
          {!data?.length && <GreySquare imageSrc={emptyTableSVG} />}
        </div>
      </div>
    );
  }),
);

export default CustomersAndRevenueSummary;
