import { Badge, Button, Divider } from "antd";
import ButtonLink from "components/ButtonLink";
import React, { useEffect, useState } from "react";
import { TFunction, useTranslation } from "react-i18next";
import { getLanguage } from "scripts/custom-hooks/useLanguage";
import { useQueryParams } from "scripts/custom-hooks/useQueryParams";
import { DEVICES, useDevices } from "../../scripts/custom-hooks/useDevices";
import { CheckBoxes } from "../CheckBoxes";
import {
  CHECKBLOCKTYPES,
  IChkBlockProps,
  IControlProps,
} from "../CheckBoxes/ChkBlock";
import "./index.less";

export const tableFiltersData = (
  t: TFunction<"translation">,
): IChkBlockProps => {
  return {
    date: {
      title: t("Date"),
      controlsList: [
        {
          active: true,
          placeholder: "bizBase.all",
          default: true,
        },
        {
          type: CHECKBLOCKTYPES.DATE,
          placeholder: "From",
        },
        {
          type: CHECKBLOCKTYPES.DATE,
          placeholder: "To",
        },
      ],
    },
    names: {
      title: t("Name"),
      controlsList: [
        {
          active: true,
          placeholder: "bizBase.all",
          default: true,
        },
        {
          placeholder: "Tian Dan 田丹",
        },
      ],
    },
    status: {
      title: t("Status"),
      // curled: true,
      controlsList: [
        {
          active: true,
          placeholder: "bizBase.all",
          default: true,
        },
      ],
    },
  };
};

export interface IFitersProps {
  onOpen?: () => void;
  onApply?: (data: any) => void;
  onSetFilter?: (data: any) => void;
  onReset?: () => void;
  filterBlocks: IChkBlockProps;
  filterModel?: any;
  isFilterActive?: boolean;
}

export const Filters = (props: IFitersProps): React.ReactElement => {
  const { onOpen, onSetFilter, onApply, onReset, isFilterActive, filterModel } =
    props;
  const { t } = useTranslation();
  const lang = getLanguage();
  const queryParams = useQueryParams();
  const tabKey = queryParams.get("tab");

  const [filterIsOpen, setFilterIsOpen] = useState(false);
  const [broken, setBroken] = useState(false);
  const [changed, setChanged] = useState(isFilterActive);
  const [isApplied, setIsApplied] = useState(isFilterActive);
  const [disabledButton, setDisabledButton] = useState(true);

  const [statusFilterData, setStatusFilterData] = useState<any>(0);
  const [suburbFilterData, setSuburbFilterData] = useState<any>(0);

  const device = useDevices();

  const [filterBlocks, setFilterBlocks] = useState<IChkBlockProps>(
    props.filterBlocks,
  );

  useEffect(() => {
    setFilterIsOpen(false);
  }, [tabKey]);

  useEffect(() => {
    if (filterModel) {
      setChanged(true);
      setIsApplied(true);
      setDisabledButton(false);
    } else {
      onClickResetHandler();
    }
  }, [filterModel]);

  useEffect(() => {
    setFilterBlocks(props.filterBlocks);
  }, [lang]);

  useEffect(() => {
    if (
      device &&
      (device === DEVICES.MOBILE || device === DEVICES.TABLET_PORT) &&
      !broken
    ) {
      (Object.keys(filterBlocks) || []).forEach((propName) => {
        filterBlocks[propName].curled = true;
      });
      setBroken(true);
    } else if (
      device &&
      device !== DEVICES.MOBILE &&
      device !== DEVICES.TABLET_PORT &&
      broken
    ) {
      (Object.keys(filterBlocks) || []).forEach((propName) => {
        filterBlocks[propName].curled = false;
      });
      setBroken(false);
    }
  }, [device, broken]);

  const onChangeFilter = async (data?: any, index?: number) => {
    onSetFilter?.(data);
    let changedFilterBlocks = {};

    if (data[Object.keys(data)[0]].onChange) {
      changedFilterBlocks = await data[Object.keys(data)[0]].onChange(
        filterBlocks,
        data,
        index,
      );

      await setFilterBlocks({
        ...changedFilterBlocks,
      });
    } else {
      await setFilterBlocks({
        ...filterBlocks,
        ...data,
      });
    }

    let checkISChanged = false;
    const filterKeys = Object.keys(filterBlocks);
    for (
      let filterKeyIndex = filterKeys.length - 1;
      filterKeyIndex >= 0;
      filterKeyIndex--
    ) {
      if (!filterBlocks[filterKeys[filterKeyIndex]].controlsList?.length) {
        continue;
      } else {
        // @ts-ignore
        checkISChanged = !!filterBlocks[
          filterKeys?.[filterKeyIndex]
          // @ts-ignore
        ].controlsList?.reduce((a: number, b: IControlProps) => {
          if (!b?.type || b?.type === CHECKBLOCKTYPES.CHECKBOX) {
            return (
              a +
              ((b?.active && !b?.default) || (b?.default && !b?.active) ? 1 : 0)
            );
          } else if (b?.type === CHECKBLOCKTYPES.DATE) {
            return a + +!!b?.value;
          } else if (b?.type === CHECKBLOCKTYPES.TIME) {
            return a + +!!b?.value;
          } else if (b?.type === CHECKBLOCKTYPES.SLIDER) {
            // TODO: HERE CHECK
            return a + +!!b?.value;
          }
        }, 0);
        if (checkISChanged) {
          break;
        }
      }
    }

    setChanged(checkISChanged);
    setDisabledButton(false);
    if (data?.status?.title === "Status") {
      setStatusFilterData(index);
    } else {
      setSuburbFilterData(index);
    }
    if (index) {
      setIsApplied(true);
    }
  };

  // useEffect(() => {
  //   !statusFilterData && !suburbFilterData && setIsApplied(false);
  // }, [statusFilterData, suburbFilterData]);

  const onApplyHandler = () => {
    if (!onApply) {
      return;
    }

    const _activeData = {};
    Object.keys(filterBlocks).forEach((key, i) => {
      const el = filterBlocks[key];

      if (!el.controlsList?.length) {
        return;
      } else {
        el.controlsList.forEach((controlItem: IControlProps) => {
          const tp = controlItem.type;
          const cTp = CHECKBLOCKTYPES;
          if (
            (((!tp || tp === cTp.CHECKBOX || tp === cTp.SEARCH) &&
              controlItem.active) ||
              tp === cTp.DATE ||
              tp === cTp.TIME ||
              tp === cTp.SLIDER ||
              tp === cTp.DROPDOWN) &&
            controlItem.value
          ) {
            _activeData[key] = _activeData[key] || {
              ...filterBlocks[key],
              controlsList: [],
            };
            _activeData[key].controlsList?.push(controlItem);
          }
        });
      }
    });
    setFilterIsOpen(false);
    onApply(_activeData);
  };
  const onClickResetHandler = () => {
    onReset?.();
    if (!changed) {
      return;
    }

    const resetData: IChkBlockProps = {};

    (Object.keys(filterBlocks) || []).forEach((propsName: string) => {
      resetData[propsName] = {
        ...filterBlocks[propsName],
        controlsList:
          propsName === "subCategory" || !filterBlocks[propsName].controlsList
            ? []
            : filterBlocks[propsName].controlsList?.map((el): IControlProps => {
                if (!el.type || el.type === CHECKBLOCKTYPES.CHECKBOX) {
                  return { ...el, active: el.default || false };
                } else if (el.type === CHECKBLOCKTYPES.DATE) {
                  return { ...el, value: "", active: el.default || false };
                } else if (el.type === CHECKBLOCKTYPES.TIME) {
                  return { ...el, value: "", active: el.default || false };
                } else if (el.type === CHECKBLOCKTYPES.SEARCH) {
                  return {
                    ...el,
                    value: "",
                    active: el.default || false,
                    isSearch:
                      typeof el.isSearch === "boolean" ? false : undefined,
                  };
                } else if (el.type === CHECKBLOCKTYPES.SLIDER) {
                  return {
                    ...el,
                    value: [0, 1000],
                    active: el.default || false,
                  };
                } else {
                  return { ...el };
                }
              }),
      };
    });

    setFilterBlocks(resetData);
    setChanged(false);
    setIsApplied(false);
    // setFilterIsOpen(false);
    if (onApply) {
      onApply({});
    }
  };
  return (
    <>
      <div>
        <Badge dot={isApplied} className={"filter"}>
          <div
            onClick={() => {
              onOpen?.();
              setFilterIsOpen(!filterIsOpen);
            }}
          >
            <ButtonLink>
              {t("bizBase.filters", {
                defaultValue: "Filters",
              })}
              <i
                className={
                  filterIsOpen
                    ? "ri-arrow-drop-up-line"
                    : "ri-arrow-drop-down-line"
                }
                style={{ fontSize: "20px" }}
              />
            </ButtonLink>
          </div>
        </Badge>
      </div>

      <div className={`filter-content ${filterIsOpen ? "open" : ""}`}>
        <Divider />
        <div className={"filter-wrapper"}>
          <div className={"filter-wrapper-content"}>
            <CheckBoxes
              inline={!broken}
              data={filterBlocks}
              onChange={onChangeFilter}
            />
          </div>
          <div className={"filter-actions"}>
            <Button
              style={{
                lineHeight: "22px",
                fontSize: "14px",
                verticalAlign: "middle",
                padding: "11px 11px 7px 11px",
                height: "unset",
              }}
              type="primary"
              htmlType="button"
              onClick={onApplyHandler}
              disabled={disabledButton}
            >
              <div
                style={{ marginTop: "-4px", display: "flex" }}
                className="flex-center"
              >
                {t("bizBase.applyFilters", {
                  defaultValue: "Apply filters",
                })}
              </div>
            </Button>
            <button
              style={{
                marginLeft: "32px",
                cursor: `${changed ? "pointer" : "not-allowed"}`,
                color: `${changed ? "" : "rgba(34, 34, 34, 0.35)"}`,
              }}
              onClick={onClickResetHandler}
            >
              {t("bizBase.resetAll", {
                defaultValue: "Reset all",
              })}
            </button>
          </div>
        </div>
        <Divider />
      </div>
    </>
  );
};
