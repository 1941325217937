import { Spin } from "antd";
import { HeadSeo } from "components/HeadSeo";
import { RescheduleType } from "constants/type.interface";
import { AppRoutes } from "helpers/app.routes";
import Cookies from "js-cookie";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import helpers from "scripts/helpers";
import { Languages, languageStoreContext } from "scripts/mobx/languageStore";
import { formatPrice } from "scripts/utils/formatPrice";
import ButtonLink from "../../../components/ButtonLink";
import { LayoutPage } from "../../../components/Layout";
import { bizIcons } from "../../../constants/bizConstants";
import providerOrdersStore from "../../../scripts/mobx/providerOrdersStore";
import PayViewCustomerComponent from "../payViewCustomerComponent";

const PayViewCustomerPage = observer((props: RouteComponentProps) => {
  const { history, match } = props;
  const { t } = useTranslation();
  const language = useContext(languageStoreContext);
  const [detailsData, setDetailsData] = useState<any>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // @ts-ignore
  const id = props.match.params.id;

  useEffect(() => {
    setIsLoading(true);
    providerOrdersStore
      ?.getCustomerDetailsById(Number(id))
      .then((el: any) => {
        setDetailsData(el);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        helpers.throwErrors(t, [], error?.data?.error, error?.data?.message);
      });
  }, []);

  const exportCustomerFile = () => {
    const data = [detailsData];
    const dataCSV = data?.map((el: any) => {
      const params = {};
      el?.children?.forEach((c: any, index: number) => {
        Object.assign(params, {
          [t("exports.child_name", {
            index: index + 1,
          })]: c,
        });
      });
      return {
        [t("exports.transaction_timestamp")]: helpers.formatDateTime(el?.date),
        [t("exports.transaction_type")]: t(
          "payments.methods." + el?.transactionType,
        ),
        [t("exports.transaction_id")]: el?.id,
        [t("exports.course_name")]: el?.courseName,
        [t("exports.course_code")]: el?.courseCode,
        [t("exports.course_location")]: el?.location,
        [t("exports.customer_name")]: el?.customerName,
        [t("exports.customer_phone")]: el?.customerPhone,
        [t("exports.customer_email")]: el?.customerEmail,
        [t("exports.booking_request_timestamp")]: helpers.formatDateTime(
          el?.date,
        ),
        [t("exports.amount")]: formatPrice(
          (el?.totalCourseCost || 0) - (el?.creditAmount || 0),
        ),
        [t("exports.credit_issued")]:
          [RescheduleType.CANCELLATION].includes(el?.transactionType) &&
          el?.creditAmount > 0
            ? formatPrice(el?.creditAmount)
            : "",
        // [t("exports.refund_issued")]:
        //   [RescheduleType.CANCELLATION].includes(el?.transactionType) &&
        //   el?.cashAmount > 0
        //     ? formatPrice(el?.cashAmount)
        //     : "",
        [t("exports.credit_used")]:
          ![RescheduleType.CANCELLATION].includes(el?.transactionType) &&
          el?.creditAmount > 0
            ? formatPrice(el?.creditAmount)
            : "",
        [t("exports.no_classes_purchased")]: el?.numberOfClassPurchased,
        [t("exports.no_classes_cancelled")]: el?.numberOfClassCancelled,
        [t("exports.child_count")]: el?.children?.length,
        ...params,
      };
    });
    helpers.exportToCSV(dataCSV, "Transactions_Export_Detail");
  };

  return (
    <>
      <HeadSeo title={detailsData?.customerName} />
      <LayoutPage defMenuItem={"payments"}>
        <Spin spinning={isLoading} size="large">
          <div className="content-with-table">
            <div className={"back-block  bsl-4"}>
              <ButtonLink
                onClick={() =>
                  history.push(
                    AppRoutes.payments + "?tab=" + Cookies.get("paymentTab"),
                  )
                }
              >
                <i
                  style={{ fontSize: "20px", paddingRight: "8px" }}
                  className={bizIcons.ARROWLEFT}
                />
                {t("payments.back_to_payments")}
              </ButtonLink>
            </div>
            <PayViewCustomerComponent
              t={t}
              _data={detailsData}
              exportHandler={exportCustomerFile}
            />
          </div>
        </Spin>
      </LayoutPage>
    </>
  );
});

export default withRouter(PayViewCustomerPage);
