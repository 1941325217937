import PrivateRoute from "components/Route/private";
import React from "react";
import { Switch } from "react-router-dom";
import "remixicon/fonts/remixicon.css";
import "./App.less";
import PublicRoute from "./components/Route/public";
import * as routes from "./routes";

const RouterComponent = () => {
  return (
    <Switch>
      <PrivateRoute {...routes.OrganizationsRoute} />
      <PrivateRoute {...routes.HomeRoute} />
      <PublicRoute {...routes.BiziPolicyRoute} />
      <PrivateRoute {...routes.VerifyRoute} />
      <PrivateRoute {...routes.SignUpRoute} />
      <PrivateRoute {...routes.StripeAccountRoute} />
      <PrivateRoute {...routes.AccountSettingsRoute} />
      <PrivateRoute {...routes.ChangePasswordRoute} />
      <PrivateRoute {...routes.ChangeEmailRoute} />
      <PrivateRoute {...routes.CoursesRoute} />
      <PrivateRoute {...routes.ViewCourseRoute} />
      <PrivateRoute {...routes.PaymentsRoute} />
      <PrivateRoute {...routes.FAQsRoute} />
      <PrivateRoute {...routes.BookingsRoute} />
      {/* <PrivateRoute {...routes.ReportsRoute} /> */}
      <PrivateRoute {...routes.CustomersRoute} />
      <PrivateRoute {...routes.viewCustomerRoute} />
      <PrivateRoute {...routes.PromotionsRoute} />
      <PrivateRoute {...routes.AddPromotionsBulkRoute} />
      <PrivateRoute {...routes.EditPromotionsBulkRoute} />
      <PrivateRoute {...routes.ViewPromotionsBulkRoute} />
      <PrivateRoute {...routes.AddPromotionsCourseRoute} />
      <PrivateRoute {...routes.EditPromotionsCourseRoute} />
      <PrivateRoute {...routes.ViewPromotionsCourseRoute} />
      <PrivateRoute {...routes.ViewReviewRoute} />
      <PrivateRoute {...routes.ReviewsRoute} />
      <PrivateRoute {...routes.NotificationsRoute} />
      <PrivateRoute {...routes.TutorialRoute} />
      <PrivateRoute {...routes.PaymentViewCustomerRoute} />
      <PrivateRoute {...routes.PaymentViewKidzbizRoute} />
      <PrivateRoute {...routes.NoAccessRoute} />
      <PublicRoute {...routes.NotRespondingRoute} />
      <PublicRoute {...routes.NoMatchRoute} />
    </Switch>
  );
};

export default RouterComponent;
