import React, { useRef, useState } from "react";
import {
  Button,
  Checkbox,
  Divider,
  message,
  notification,
  Typography,
  Upload,
  Modal,
  Form,
} from "antd";
import { bizIcons } from "../../../../constants/bizConstants";
import ButtonLink from "../../../../components/ButtonLink";
import { observer } from "mobx-react";
import { ButtonWhite } from "../../../../components/ButtonWhite";
import { withRouter, RouteComponentProps } from "react-router-dom";
import courseStore from "scripts/mobx/courseStore";
import { AppRoutes } from "helpers/app.routes";
import { useTranslation } from "react-i18next";
import helpers from "scripts/helpers";
import { FILE_LIMIT_SIZE, UPLOAD_ALLOW_TYPES_IMPORT } from "constants/commons";
import { HeadSeo } from "components/HeadSeo";
import { Link } from "components/Link";
import "./index.less";
import ImageUploader from "components/ImageUploader";
import { LoadingOutlined } from "@ant-design/icons";
import QueryString from "constants/query-string";
import { createStatus } from "..";
const { Title } = Typography;

interface IProps extends RouteComponentProps {
  backToCourseCreateHandler: () => void;
  data?: any[];
}

interface ICoursesData {
  id?: number;
  name?: string;
  hasError?: boolean;
  validationMessage?: string[];
  _isActive?: boolean;
}

export const CreateCourseByXLS = withRouter(
  observer((props: IProps) => {
    const { backToCourseCreateHandler, history } = props;
    const { t } = useTranslation("base");
    const [form] = Form.useForm();
    const [total, setTotal] = useState<number>(0);
    const [totalSuccess, setTotalSuccess] = useState<number>(0);
    const [coursesArr, setCoursesArr] = useState<ICoursesData[]>([]);
    const [courseNames, setCourseNamesArr] = useState<
      { courseName: string; url: string }[]
    >([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isUpload, setUpload] = useState<boolean>(false);
    const [isExistExcel, setExistExcel] = useState<boolean>(false);
    const countOfFiles = useRef<number>(0);

    const countError = coursesArr.reduce((a, b) => {
      return b._isActive ? a + 1 : a;
    }, 0);
    const countOfCourses = totalSuccess + countError;

    const customUploadRequest = (data: any) => {
      if (typeof data.onSuccess === "function") {
        data.onSuccess();
      }
    };

    const uploadXLSHandler = (file: File) => {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("file", file);
      courseStore
        .xlsRecognition(formData)
        .then(
          (res: any) => {
            setExistExcel(true);
            if (res?.courses?.length) {
              const coursesError = res?.courses?.map((el: any) => {
                return { ...el, _isActive: true };
              });
              setTotal(res?.totalSuccess);
              setTotalSuccess(res?.totalSuccess - coursesError?.length);
              setCoursesArr(coursesError);
              setCourseNamesArr(
                res?.courseNames?.map((el: string) => {
                  return {
                    courseName: el,
                    url: "",
                  };
                }),
              );
            }
          },
          (rej) => {
            helpers.throwErrors(t, [], rej?.data?.error, rej?.data?.message);
          },
        )
        .finally(() => {
          setIsLoading(false);
          countOfFiles.current = 0;
        });
    };

    const withDataBlock = (
      <>
        <div style={{ marginTop: "32px" }} className={"form-width"}>
          {coursesArr.map((el: any, index: number) => {
            return (
              <div key={Math.random()}>
                <div className={"flex"}>
                  <Checkbox
                    checked={el._isActive}
                    onChange={(el) => {
                      const _tmp = [...coursesArr];
                      _tmp[index]._isActive = !_tmp[index]._isActive;

                      setCoursesArr(_tmp);
                    }}
                    className={"mr-8"}
                  />
                  <Title level={3}>{el.name}</Title>
                </div>
                {el?.validationMessage?.length > 0 ? (
                  <div style={{ marginTop: "4px", color: "red" }}>
                    {`${t("courses.rowNum", { row: el?.rowNum })} - `}
                    {el?.validationMessage.includes(
                      "ProviderPaymentMethodException",
                    ) ? (
                      <>
                        In order to create free trial classes, you must enter a
                        valid payment method{" "}
                        <ButtonLink
                          onClick={() => {
                            history.push({
                              pathname: AppRoutes.courseCreate,
                              search: QueryString.stringify({
                                action: createStatus.ADD_PAYMENT_METHOD,
                              }),
                            });
                          }}
                        >
                          <span style={{ fontSize: 16 }}>here.</span>
                        </ButtonLink>
                      </>
                    ) : (
                      el?.validationMessage?.join(", ")
                    )}
                    {/* {t("courses.rowNum", {
                      row: el?.rowNum,
                    }) +
                      " - " +
                      el?.validationMessage?.join(", ")} */}
                  </div>
                ) : (
                  <div
                    className={"text-color-secondary"}
                    style={{ marginTop: "4px" }}
                  >
                    {t("messages.there_are_no_errors")}
                  </div>
                )}

                <Divider />
              </div>
            );
          })}
        </div>
        <div
          className={"tsl-4 bsl-4 flex-align-items"}
          style={{ flexWrap: "wrap" }}
        >
          {/* <Button
            className="fs-16 tsl-4 mr-8"
            type="primary"
            style={{ width: "183px" }}
            htmlType="submit"
            disabled={!countOfCourses}
            onClick={async () => {
              setUpload(true);
            }}
          >
            <div className={"flex-center"}>{t("bizBase.uploadImages")}</div>
          </Button> */}
          <Button
            className="fs-16 tsl-4 mr-8"
            type="primary"
            style={{ width: "200px" }}
            htmlType="submit"
            disabled={!countOfCourses}
            onClick={async () => {
              setIsLoading(true);
              const images = form.getFieldsValue();
              const courseImages = courseNames
                ?.map((el: any, index: number) => {
                  return {
                    courseName: el?.courseName,
                    url: images["courseName_" + index],
                  };
                })
                ?.filter((el) => !!el.url);
              courseStore
                .createByRecognitionData({
                  courseImages,
                  idToCreate: coursesArr
                    ?.filter((el) => el._isActive)
                    ?.map((el: any) => el?.id),
                  idToDelete: coursesArr
                    ?.filter((el) => !el._isActive)
                    ?.map((el: any) => el?.id),
                })
                .then(() => {
                  notification.success({
                    message: t(
                      "courses.bulk_course_drafts_created_successfully",
                    ),
                  });
                  setCoursesArr([]);
                  history.push(AppRoutes.courses + "?tab=Draft");
                  setIsLoading(false);
                })
                .catch((error) => {
                  helpers.throwErrors(
                    t,
                    [],
                    error?.data?.error,
                    error?.data?.message ||
                      t("courses.bulk_course_drafts_created_unsuccessfully"),
                  );
                });
            }}
          >
            <div className={"flex-center"}>
              {t("courses.import_courses_success", {
                number:
                  countOfCourses +
                  " " +
                  `${"draft course" + (countOfCourses === 1 ? "" : "s")}`,
              })}
            </div>
          </Button>
          <ButtonWhite
            className="fs-16 tsl-4"
            onClick={() => {
              setCoursesArr([]);
              setCourseNamesArr([]);
              setExistExcel(false);
              form.resetFields();
            }}
            style={{ width: "110px" }}
            type="primary"
          >
            <div className={"flex-center"}>{t("bizBase.clearAll")}</div>
          </ButtonWhite>
        </div>
      </>
    );

    const noDataBlock = (
      <>
        {isExistExcel ? (
          <>
            <div className={"text-color-secondary"}>
              {t("courses.no_errors_in_your_file_upload")}
            </div>
            <div
              className={"tsl-4 bsl-4 flex-align-items"}
              style={{ flexWrap: "wrap" }}
            >
              <Button
                className="fs-16 tsl-4 mr-8"
                type="primary"
                style={{ width: "183px" }}
                htmlType="button"
                onClick={async () => {
                  notification.info({
                    message: t("courses.upload_on_processing"),
                  });
                  history.push(AppRoutes.courses + "?tab=Draft");
                }}
              >
                <div className={"flex-center"}>
                  {t("courses.create_draft_courses")}
                </div>
              </Button>
              <ButtonWhite
                className="fs-16 tsl-4"
                onClick={() => {
                  setCoursesArr([]);
                  setCourseNamesArr([]);
                  setExistExcel(false);
                  form.resetFields();
                }}
                style={{ width: "110px" }}
                type="primary"
              >
                <div className={"flex-center"}>{t("bizBase.clearAll")}</div>
              </ButtonWhite>
            </div>
          </>
        ) : (
          <>
            <div className="flex-align-items" style={{ marginTop: "32px" }}>
              <Upload
                multiple={false}
                showUploadList={false}
                customRequest={customUploadRequest}
                beforeUpload={(file: any, fil) => {
                  countOfFiles.current = fil.length;
                  const messages: string[] = [];

                  const isXLS = UPLOAD_ALLOW_TYPES_IMPORT.includes(
                    helpers.getFileType(file.name),
                  );

                  if (!isXLS) {
                    messages.push(
                      t("courses.upload_file_type", {
                        file: UPLOAD_ALLOW_TYPES_IMPORT.join(
                          "/",
                        )?.toUpperCase(),
                      }),
                    );
                  }
                  const isLtM = file.size < FILE_LIMIT_SIZE;
                  if (!isLtM) {
                    messages.push(
                      t("courses.max_upload_file", {
                        size: FILE_LIMIT_SIZE / 1024 / 1024,
                      }),
                    );
                  }

                  messages.map((m) => message.error(m));

                  return isXLS && isLtM;
                }}
                onChange={(info: any) => {
                  if (countOfFiles.current !== info.fileList.length) {
                    return;
                  }

                  if (info.fileList.every((el: any) => el.status === "done")) {
                    uploadXLSHandler(
                      info.fileList.map((el: any) => el.originFileObj)?.[0],
                    );
                  }
                }}
              >
                <ButtonLink>
                  {t("bizBase.uploadFiles", {
                    defaultValue: "Upload files",
                  })}
                  *
                </ButtonLink>
              </Upload>
              <div style={{ marginLeft: 40 }}>
                <Link to={"/files/BulkUploadTemplate.xlsx"} target="_blank">
                  {t("courses.downloadTemplate")}
                </Link>
              </div>
            </div>
            <div className={"tsl-4 bsl-4"}>
              <div className={"text-color-secondary"}>
                *{" "}
                {t("courses.helpUpload", {
                  file: UPLOAD_ALLOW_TYPES_IMPORT.join("/")?.toUpperCase(),
                  size: FILE_LIMIT_SIZE / 1024 / 1024,
                })}
                .
              </div>
            </div>
          </>
        )}
      </>
    );

    const uploadButton = (isUploading: boolean) => {
      return (
        <div>
          <div style={{ height: "24px" }} className={"flex-center"}>
            {isUploading ? (
              <LoadingOutlined />
            ) : (
              <i className={`${bizIcons.IMAGE} fs-20 text-color-secondary`} />
            )}
          </div>
          <div
            className={"text-color-secondary"}
            style={{ marginTop: 8, width: "100%" }}
          >
            {t("signUp.step4.uploadImage", {
              defaultValue: "Upload image",
            })}
          </div>
        </div>
      );
    };

    const onCheckBeforeUpload = (messages: string[]) => {
      messages.map((m) => message.error(m));
    };

    const onUploadChanged = (index: number, file: File) => {
      const formData = new FormData();
      formData.append("files", file);
      courseStore?.uploadImage(formData).then((res) => {
        form.setFieldsValue({
          ["courseName_" + index]: res?.data?.[0]?.url,
        });
      });
    };

    return (
      <>
        <HeadSeo
          title={t("courses.createFromXLSTitle", {
            defaultValue: "Create from XLS",
          })}
        />
        <div className={"create-courses content-with-table"}>
          <div className={"back-block  bsl-4"}>
            <ButtonLink onClick={backToCourseCreateHandler}>
              <i
                style={{ fontSize: "20px", paddingRight: "8px" }}
                className={bizIcons.ARROWLEFT}
              />
              {t("courses.backtoCreateACourse", {
                defaultValue: "Back to Create a course",
              })}
            </ButtonLink>
          </div>
          <div className={""}>
            <Title style={{ marginBottom: "32px" }} level={1}>
              {t("courses.createFromXLSTitle", {
                defaultValue: "Create from XLS",
              })}
            </Title>
          </div>

          {isLoading ? (
            <Button
              style={{ marginTop: "32px" }}
              className={"loader-from-button flex-center"}
              loading={true}
            />
          ) : !!coursesArr.length ? (
            withDataBlock
          ) : (
            noDataBlock
          )}
        </div>

        <Modal
          centered
          title=""
          visible={isUpload}
          closable={false}
          onCancel={() => {
            setUpload((prev) => !prev);
          }}
          footer={false}
          wrapClassName="courses-modal-upload-images"
        >
          <Form form={form} initialValues={{}}>
            <div className="bsl-4">
              <h3>{t("bizBase.uploadImages")}</h3>
              <Form.Item noStyle shouldUpdate>
                {() => {
                  return courseNames?.map((el, index) => (
                    <Form.Item
                      key={index}
                      help={
                        <div className="flex-space-between">
                          <span>
                            {t("courses.helpUploadImageCourse", {
                              size: 1,
                            })}
                          </span>
                        </div>
                      }
                      className={"bsl-4"}
                      name={"courseName_" + index}
                    >
                      <div className="label-wrapper">
                        <label
                          htmlFor={"courseName_" + index}
                          style={{ fontWeight: 700 }}
                        >
                          {el?.courseName}
                        </label>
                      </div>
                      <ImageUploader
                        name={t("courses.imageOfCourse", {
                          defaultValue: "Image of course",
                        })}
                        imageUrl={form.getFieldValue("courseName_" + index)}
                        onCheckBeforeUpload={onCheckBeforeUpload}
                        uploadButtonFunc={uploadButton}
                        onUploadChanged={(file: File) =>
                          onUploadChanged(index, file)
                        }
                      />
                    </Form.Item>
                  ));
                }}
              </Form.Item>
            </div>
            <div className="flex-space-between">
              <ButtonWhite
                className="fs-16 tsl-4 flex-1 mr-25"
                onClick={() => {
                  setUpload((prev) => !prev);
                  // form.resetFields();
                }}
                style={{
                  width: "auto",
                  minWidth: "auto",
                  paddingLeft: 20,
                  paddingRight: 20,
                }}
                type="primary"
              >
                <div className={"flex-center"}>{t("bizBase.cancel")}</div>
              </ButtonWhite>
              <Button
                className="fs-16 tsl-4 flex-1"
                type="primary"
                style={{ width: "auto", paddingLeft: 20, paddingRight: 20 }}
                onClick={() => {
                  setUpload((prev) => !prev);
                  Modal.destroyAll();
                }}
                icon={
                  <div className={"flex-center"}>
                    <>{t("bizBase.ok")}</>
                  </div>
                }
              />
            </div>
          </Form>
        </Modal>
      </>
    );
  }),
);
