import { Badge, Popover } from "antd";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import React, { useRef } from "react";
import notificationsStore from "scripts/mobx/notificationsStore";
import { DataBlock } from "./dataBlock";
import { EmptyBlock } from "./emptyBlock";
import "./index.less";

interface INotificationsProps {}
export const NotificationsBlock = observer((props: INotificationsProps) => {
  const [notificationsList, setNotificationsList] = React.useState<any[]>(
    toJS(notificationsStore?.notificationData) || [],
  );
  const [visible, setVisible] = React.useState<boolean>(false);

  const getNotiList = () => {
    notificationsStore
      ?.getNotifications()
      .then((res) => setNotificationsList(res));
  };

  const onAccept = (id: number) => {
    notificationsStore
      ?.notificationsMarkRead(id)
      .then((res) => setNotificationsList(res));
  };

  const ref = React.useRef(null);

  React.useEffect(() => {
    document.addEventListener("click", handleClose, true);
    return () => {
      document.removeEventListener("click", handleClose, true);
    };
  }, []);

  const handleClose = (event: MouseEvent) => {
    const { target } = event;
    if (!(ref as any)?.current?.contains(target)) {
      setVisible(false);
    }
  };

  return (
    <>
      {/* <Popover
        visible={visible}
        onVisibleChange={(v) => setVisible(v)}
        placement="bottom"
        content={
          <div
            style={{
              width: "312px",
              minHeight: "224px",
              overflowY: "auto",
              maxHeight: "500px",
            }}
          >
            {toJS(notificationsStore?.notificationData)?.length > 0 ? (
              <DataBlock
                onAccept={onAccept}
                data={toJS(notificationsStore?.notificationData)}
              />
            ) : (
              <EmptyBlock />
            )}
          </div>
        }
        trigger="click"
      >
        <Badge count={toJS(notificationsStore?.notificationData)?.length}>
          <div
            className="head-item"
            onClick={() => {
              getNotiList();
              setTimeout(() => setVisible(true), 400);
            }}
          >
            <i className="ri-notification-2-line" />
          </div>
        </Badge>
      </Popover> */}

      <div
        className={"flex-center"}
        style={{ position: "relative" }}
        // onClick={() => history.push("/notifications")}
      >
        <Badge count={notificationsList?.length}>
          <div
            className="head-item"
            onClick={() => {
              getNotiList();
              setVisible(true);
            }}
          >
            <i className="ri-notification-2-line" />
          </div>
        </Badge>
        {visible && (
          <div ref={ref} className="biz-popover">
            {notificationsList?.length > 0 ? (
              <DataBlock onAccept={onAccept} data={notificationsList} />
            ) : (
              <EmptyBlock />
            )}
          </div>
        )}
      </div>
    </>
  );
});
