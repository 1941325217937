import { Popover } from "antd";
import { Link } from "components/Link";
import { AppRoutes } from "helpers/app.routes";
import React from "react";
import { formatPrice } from "scripts/utils/formatPrice";
import { bizIcons as tb } from "../../constants/bizConstants";
import helpers from "../../scripts/helpers";

export const tableBookingsColumns = (
  t: any,
  pendingButtonFunc?: (id: number) => any,
  isLinked?: boolean,
) => {
  const columns = [
    {
      key: "courseName",
      dataIndex: "courseName",
      render: (text: any, record: any) =>
        text && (
          <div>
            <div>{text}</div>
            {!!pendingButtonFunc && pendingButtonFunc(record)}
          </div>
        ),
    },
    {
      key: "code",
      dataIndex: "code",
      render: (text: any) =>
        text && (
          <div>
            <i
              className={tb.CODE}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            {text}
          </div>
        ),
    },

    {
      dataIndex: "courseLocation",
      key: "courseLocation",
      render: (text: React.ReactNode) => {
        return text ? (
          <div>
            <i
              className={tb.GLOBE}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            <div> {text} </div>
          </div>
        ) : null;
      },
    },
    {
      dataIndex: "empty",
      key: "empty",
      className: "full-width empty",
      render: () => <div />,
    },
    {
      dataIndex: "bookingDate",
      key: "bookingDate",
      render: (text: any) => (
        <div>
          <i
            className={tb.CALENDAR}
            style={{ fontSize: "16px", marginRight: "8px" }}
          />
          {text}
        </div>
      ),
    },

    {
      dataIndex: "customer",
      key: "customer",
      render: (text: React.ReactNode) => {
        return (
          <div>
            <i
              className={tb.USER3}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            <div> {text} </div>
          </div>
        );
      },
    },

    {
      dataIndex: "childrenEnrolled",
      key: "childrenEnrolled",
      render: (text: any) => (
        <div>
          <i
            className={tb.TEAM}
            style={{ fontSize: "16px", marginRight: "8px" }}
          />
          {text}
        </div>
      ),
    },
    {
      dataIndex: "price",
      key: "price",
      render: (text: any) =>
        text && (
          <div>
            <i
              className={tb.PRICE}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            {text}
          </div>
        ),
    },
    {
      key: "childName",
      dataIndex: "childName",
      render: (text: any, record: any) => {
        return (
          <>
            {record?.childName?.length > 0
              ? record?.childName?.map((item: any, index: number) => {
                  return (
                    <Popover
                      key={"childName" + index}
                      overlayClassName={`popover-ellipsis ${
                        !item?.name ? "display-none" : ""
                      }`}
                      content={item?.name}
                      trigger="hover"
                    >
                      <div className="ellipsis mr-8">
                        {item?.name && (
                          <i
                            className={tb.USER5}
                            style={{ fontSize: "16px", marginRight: "8px" }}
                          />
                        )}
                        {item?.name}
                      </div>
                    </Popover>
                  );
                })
              : ""}
          </>
        );
      },
    },
    {
      dataIndex: "rejectDate",
      key: "rejectDate",
      render: (text: any) =>
        text && (
          <div>
            <i
              className={tb.CALENDAR}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            {text}
          </div>
        ),
    },
    {
      key: "Action",
      dataIndex: "Action",
      className: "full-width semi-bold pt-8",
      render: (text: any, record: any) => (
        <div>
          <Link to={AppRoutes.courseDetail(record.courseId)}>
            {t("customers.goToTheCourse")}
          </Link>
        </div>
      ),
    },
  ];
  if (isLinked) {
    columns.splice(2, 0, {
      key: "organizationName",
      dataIndex: "organizationName",
      render: (text: any, record: any) => {
        return (
          <>
            <div>
              <i
                className={tb.BUILDING}
                style={{ fontSize: "16px", marginRight: "8px" }}
              />
              {text}
            </div>
          </>
        );
      },
    });
  }
  return columns;
};
export const tableBookingsData = (data?: any[] | null) => {
  return data?.filter(Boolean).map((el) => {
    const bookingDate = el?.bookingTime
      ? helpers.formatDateTime(
          `${helpers.getServerDate(el.bookingTime.slice(0, 3))} ${el.bookingTime
            .slice(3, 6)
            .join(":")}`,
        )
      : "";
    return {
      customerId: el?.legalName,
      customer: el?.customerName,
      childName: el?.customerChildren || [],
      childrenEnrolled: el?.customerChildren?.length || 0,
      legalName: el?.legalName,
      organizationName: el?.legalName,
      courseName: el?.courseName,
      courseLocation: el?.courseLocation,
      courseId: el?.courseId,
      code: el?.courseCode,
      bookingDate,
      price: el?.totalPrice
        ? formatPrice(el?.totalPrice, { currency: el?.currency })
        : undefined,
      rejectDate: el?.rejectDate ? helpers.formatDateTime(el.rejectDate) : "",
      providerId: el?.providerId,
      status: el?.status,
      id: el?.bookingId,
      key: el?.bookingId,
    };
  });
};
