import React, { useEffect, useState } from "react";
import ButtonLink from "components/ButtonLink";
import { bizIcons } from "constants/bizConstants";
import { Button, Form, Input, message, Modal } from "antd";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { FieldData } from "rc-field-form/lib/interface";
import "./index.less";
import { useTranslation } from "react-i18next";

interface IEditPolicyLinkProps {
  policies: any[];
  onSetPolicies: (val: any) => void;
  onBack: () => void;
  editIndex?: number;
}

const fieldsRequried = {
  linkname: true,
  link: true,
};

const EditPolicyLink = (props: IEditPolicyLinkProps) => {
  const { onBack, editIndex, policies, onSetPolicies } = props;
  const { t } = useTranslation("base");

  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  const [form] = Form.useForm();

  const onFinish = async (values: any) => {
    setIsLoading(true);
    form
      .validateFields(Object.keys(values))
      .then(
        async () => {
          // will be removed after swagger scheme updated
          const policylinks: any[] = [...policies];

          if (editIndex !== undefined && policylinks.length >= editIndex) {
            policylinks.splice(editIndex, 1, values);
          } else {
            policylinks.push(values);
          }
          onSetPolicies(policylinks);
          setIsLoading(false);
          onBack();
        },
        (e) => {
          const { _message } = e;
          console.log(e);
          message.error(
            _message ||
              t("messages.not_valid_input_data", {
                defaultValue: "Not valid input data",
              }),
          );
          setIsLoading(false);
        },
      )
      .catch((e) => {
        const { _message } = e;
        console.log(e);
        message.error(
          _message ||
            t("messages.some_error_occurred", {
              defaultValue: "Some error occurred",
            }),
        );
        setIsLoading(false);
      });
  };

  const onDeleteItem = () => {
    Modal.confirm({
      title: t("messages.confirm_remove_link"),
      autoFocusButton: null,
      icon: "",
      okText: t("bizBase.ok"),
      cancelText: t("bizBase.cancel"),
      onOk() {
        const policylinks: any[] = [...policies];
        if (editIndex !== undefined && policylinks.length >= editIndex) {
          policylinks.splice(editIndex, 1);
        }
        onSetPolicies(policylinks);
        onBack();
      },
      onCancel() {},
    });
  };

  useEffect(() => {
    const values = form.getFieldsValue();
    const hasValue = Object.keys(values).some(
      (key) => values[key] !== undefined && values[key].length > 0,
    );

    if (hasValue) {
      form.validateFields();
    }
  }, []);

  const onErrorsCheck = (fields: FieldData[], allfields: FieldData[]) => {
    const hasErrors = allfields.some(
      (field: FieldData) =>
        (field.errors && field.errors?.length > 0) ||
        (fieldsRequried[field.name[0]] && field.value === undefined),
    );

    setIsDisabled(hasErrors);
  };

  return (
    <div className="auth">
      <div className="content-wrapper">
        <ButtonLink onClick={() => onBack()}>
          <i className={`${bizIcons.ARROWLEFT} fs-20 mr-8`} />
          {t("bizBase.back", {
            defaultValue: "Back",
          })}
        </ButtonLink>
        <div className="auth-header bsl-4">
          <div className="header-title">
            <span className={"auth-header_title"}>
              {editIndex !== undefined
                ? t("signUp.addPolicies.title", {
                    type: "Edit",
                  })
                : t("signUp.addPolicies.title", {
                    type: "Add",
                  })}
            </span>
            {editIndex !== undefined && (
              <ButtonLink onClick={onDeleteItem}>
                <i className={`${bizIcons.DELETE} fs-20 mr-8`} />
                {t("bizBase.delete", {
                  defaultValue: "Delete",
                })}
              </ButtonLink>
            )}
          </div>
        </div>
        <div className="auth-form">
          <Form
            form={form}
            name="normal_login"
            className="login-form"
            initialValues={
              editIndex !== undefined && policies.length >= editIndex
                ? policies[editIndex]
                : {}
            }
            onFieldsChange={onErrorsCheck}
            onFinish={onFinish}
          >
            <Form.Item className="bsl-4">
              <div className="label-wrapper">
                <label htmlFor="email">
                  {t("signUp.addPolicies.linkName", {
                    defaultValue: "Link name",
                  })}
                  *
                </label>
              </div>
              <Form.Item
                name="linkname"
                rules={[
                  {
                    required: true,
                    message: t("messages.required_field", {
                      defaultValue: "Please fill in all required fields",
                    }),
                  },
                ]}
              >
                <Input
                  id={"linkname"}
                  placeholder={t("signUp.addPolicies.linkName", {
                    defaultValue: "Link name",
                  })}
                  readOnly={isLoading}
                  size={"large"}
                  maxLength={60}
                />
              </Form.Item>
            </Form.Item>
            <Form.Item className="bsl-4">
              <div className="label-wrapper">
                <label htmlFor="email">
                  {t("signUp.addPolicies.enterLink", {
                    defaultValue: "Enter link",
                  })}
                  *
                </label>
              </div>
              <Form.Item
                name="link"
                rules={[
                  {
                    required: true,
                    message: t("messages.required_field", {
                      defaultValue: "Please fill in all required fields",
                    }),
                  },
                  {
                    pattern:
                      /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
                    message: t("messages.url_format", {
                      defaultValue: "Please check url format",
                    }),
                  },
                ]}
              >
                <Input
                  id={"link"}
                  required={true}
                  placeholder={t("signUp.addPolicies.link", {
                    defaultValue: "link",
                  })}
                  readOnly={isLoading}
                  size={"large"}
                  maxLength={150}
                />
              </Form.Item>
            </Form.Item>
            <Form.Item>
              <Button
                className="full-width fs-16 tsl-4"
                type="primary"
                htmlType="submit"
                loading={isLoading}
                disabled={isDisabled || isLoading}
                icon={
                  <div className={"flex-center"}>
                    <i className={`${bizIcons.SAVE} mr-8 fs-20`} />
                    {t("signUp.addPolicies.saveLink", {
                      defaultValue: "Save link",
                    })}
                  </div>
                }
              />
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default EditPolicyLink;
