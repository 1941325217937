import axios from "axios";
import { API_URL, KEYCLOAK_REALM } from "config";
import keycloak from "helpers/keycloak";

const axiosApiInstance = axios.create({ baseURL: API_URL });

export const localStore = {
  TOKEN: "react-token",
  REFRESH_TOKEN: "react-refresh-token",
  ACCESS_TOKEN: "Authorization",
  REALM: "realm",
};

export function setConfigAxios(token) {
  if (token) {
    axiosApiInstance.defaults.headers[localStore.ACCESS_TOKEN] =
      `Bearer ` + token;
    axiosApiInstance.defaults.headers[localStore.REALM] = KEYCLOAK_REALM;
  }
}

export function setConfigAxiosRefreshToken(token) {
  if (token) {
    axiosApiInstance.defaults.headers[localStore.ACCESS_TOKEN] =
      `Bearer ` + token;
    axiosApiInstance.defaults.headers[localStore.REALM] = KEYCLOAK_REALM;
  }
}

axiosApiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401 || error.message === "Network Error") {
      return keycloak.login();
    }

    return Promise.reject(error);
  },
);

function refreshToken(err) {
  console.log(JSON.stringify(err, "refreshToken"));
  return new Promise(function (resolve, reject) {
    if (err.response) {
      return reject(err.response);
    } else {
      return keycloak.login();
    }
  });
}

export default {
  get(url, config = {}) {
    return axiosApiInstance
      .get(url, config)
      .then((response) => response)
      .catch((err) => {
        return refreshToken(err);
      });
  },
  post(url, dataBody, config = {}) {
    return axiosApiInstance
      .post(url, dataBody, config)
      .then((response) => response)
      .catch((err) => {
        return refreshToken(err);
      });
  },

  put(url, dataBody, config = {}) {
    return axiosApiInstance
      .put(url, dataBody, config)
      .then((response) => response)
      .catch((err) => {
        return refreshToken(err);
      });
  },
  delete(url, config = {}) {
    return axiosApiInstance
      .delete(url, config)
      .then((response) => response)
      .catch((err) => {
        return refreshToken(err);
      });
  },
};
