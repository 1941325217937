import { Divider, Typography } from "antd";
import { HeadSeo } from "components/HeadSeo";
import QueryString from "constants/query-string";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useQueryParams } from "scripts/custom-hooks/useQueryParams";
import { HashLink } from "../../components/HashLink";
import { LayoutPage } from "../../components/Layout";
import { Link } from "../../components/Link";
import TableComponent from "../../components/TableComponent/TableComponent";
import { TAB } from "./data";
import "./index.less";
import { tableFAQColumns, tableFAQDataZH } from "./tableGenerator";
import ViewBlock from "./viewBlock";
const { Title } = Typography;

const ZhFAQs = inject("languageStore")(
  observer(() => {
    const { pathname } = useLocation();
    const history = useHistory();
    const params = useQueryParams();
    const tabId = params.get("id");

    React.useEffect(() => {
      if (tabId) {
        const elmnt = document.getElementById(tabId);
        if (elmnt?.offsetTop) {
          setTimeout(() => {
            window.scrollTo(0, elmnt?.offsetTop + 5);
          }, 200);
        }
      }
    }, []);

    const onScroll = (id: string) => {
      const elmnt = document.getElementById(id);
      history.replace({
        pathname: pathname,
        search: QueryString.replace({
          id,
        }),
      });
      if (elmnt?.offsetTop) {
        setTimeout(() => {
          window.scrollTo(0, elmnt?.offsetTop + 5);
        }, 200);
      }
    };
    return (
      <>
        <HeadSeo title="FAQs" />
        <LayoutPage>
          <div className={"content-with-table"}>
            <Title level={1} style={{ marginBottom: "32px" }}>
              FAQs - ZH
            </Title>

            <div className={"faq-links"}>
              {TAB.map((el) => (
                <span
                  key={el.id}
                  className="link"
                  onClick={() => onScroll(el.id)}
                >
                  <span className="link-text"> {el.name_zh} </span>
                </span>
              ))}
            </div>

            <div className={"faq-texts bsl-2"}>
              <div
                id={"pricing"}
                className={"faq-text-block"}
                style={{ marginTop: "32px" }}
              >
                <div>
                  <Title level={2}>Pricing - ZH</Title>{" "}
                </div>
                <Divider />

                <ViewBlock
                  title={
                    "What is the cost of joining the Bizibuz platform - ZH?"
                  }
                  id="pricing1"
                >
                  <div className={"text-color-secondary"}>
                    <div className={"bsl-4"}>
                      There is no cost to join the platform. Bizibuz employs a
                      marketplace commission model which means you only pay us
                      when we bring you paying customers. We implement a
                      transparent pricing model to source incremental revenue
                      for your business while also providing a complete
                      operational, marketing and strategy solution for your
                      Kidzbiz. - ZH
                    </div>
                    <div className={"bsl-4"}>
                      <TableComponent
                        propsTableColumns={tableFAQColumns()}
                        propsTableData={tableFAQDataZH()}
                        pagination={false}
                      />
                    </div>
                    <div className={"bsl-4"}>
                      * Bizibuz reserves the right to change the rates at any
                      time without notice - ZH.
                    </div>
                    {/* ** Non profit organizations are entitled to the introductory
                    rate on a permanent basis. Please contact Bizibuz to apply
                    Bizibuz reserves the right to change the rates at any time
                    without notice after the introductory period . - ZH */}
                  </div>
                </ViewBlock>
                <ViewBlock
                  title={"What other fees might apply - ZH?"}
                  id="pricing2"
                >
                  <div className={"text-color-secondary"}>
                    <div className={"bsl-4"}>
                      Bizibuz pays accrued revenue on a monthly basis to each
                      Kidzbiz equal to pro rated customer spend less the Bizibuz
                      Fee outlined above less any course Cancellation Fees. - ZH
                    </div>
                    Third party fees are payments charged by Stripe for payment
                    processing and are typical for online ecommerce
                    capabilities.{" "}
                    <b>Bizibuz absorbs these costs on your behalf</b> - ZH:
                    <ul>
                      <li>
                        3.4% + HK$2.35 per credit card spend (Visa, Mastercard)
                        - ZH
                      </li>
                      <li>
                        2.2% + HK$2.00 per digital wallet spend (WeChat Pay or
                        Alipay) - ZH
                      </li>
                      {/* <li>
                        HK$20 per month + 0.25% of funds paid into the Kidzbiz’s
                        bank account - ZH
                      </li> */}
                    </ul>
                    If a Kidzbiz cancels a course or accepted course booking for
                    all remaining classes in course that has the status of “In
                    progress”, Bizibuz will charge a Cancellation Fee per course
                    attendee per course. This Cancellation Fee is not charged
                    for single class cancellations by a Kidzbiz. The
                    Cancellation Fee is currently set at
                    HK$150/S$30/RM80/INR1000 - Bizibuz reserves the right to
                    change the Cancellation Fee at any time without notice.
                    Bizibuz charges this Cancellation Fee to cover
                    administrative expenses and to discourage disappointing
                    customers with last minute course cancellations. <br />
                    The only other fee that may be incurred is a monthly account
                    fee charged by Stripe. This is only charged if there is a
                    payout in that month and amounts to HK$20 per month + 0.25%
                    of funds paid into the Kidzbiz’s bank account. This is
                    levied directly on a Kidzbiz by Stripe. - ZH
                  </div>
                </ViewBlock>
                <ViewBlock
                  title={"What happens if Bizibuz changes the pricing - ZH?"}
                  id="pricing3"
                >
                  <div className={"text-color-secondary"}>
                    If Bizibuz changes the pricing, the updated pricing will
                    apply to the next whole month onwards. For example, if the
                    rate is changed mid January and the amendment causes your
                    Kidzbiz fee to rise, the new Kidzbiz fee will only apply for
                    the month of February onwards. - ZH
                  </div>
                </ViewBlock>
              </div>
              <div
                id={"signup"}
                className={"faq-text-block"}
                style={{ marginTop: "32px" }}
              >
                <Title level={2}>Sign Up - ZH</Title>
                <Divider />
                <ViewBlock
                  title={"What am I agreeing to by signing up - ZH?"}
                  id="signup1"
                >
                  <div className={"text-color-secondary"}>
                    <div className={"bsl-4"}>
                      Upon sign-up, a Kidzbiz is agreeing to the terms outlined
                      in the Kidzbiz Agreement, Terms and Conditions of the
                      Bizibuz platform, the Privacy Policy and Cookie Policy.
                      Terms of the Kidzbiz Agreement are shared during sign-up
                      and subsequently available in the Policies area of the
                      Kidzbiz web application. - ZH
                    </div>
                    The obligations of a Kidzbiz on the Bizibuz platform include
                    (but are not limited to) uploading information regarding its
                    business and classes, and keeping such content current,
                    accurate and appropriate. Upon receipt of a customer booking
                    request, the Kidzbiz should endeavour to accept or reject
                    the booking request within 24 hours of receipt through the
                    Kidzbiz portal. If a Kidzbiz has not accepted or rejected a
                    booking request within the lesser of 5 days after the
                    customer makes the booking and 2 hours of the course or next
                    applicable class starting, the booking will be automatically
                    rejected/cancelled. After a booking has been accepted, the
                    Kidzbiz should subsequently perform the course for the
                    customer according to the highest possible standard of
                    quality. - ZH
                  </div>
                </ViewBlock>
                <ViewBlock
                  title={
                    "Why do I need to upload certified business/ID documents when creating my account - ZH?"
                  }
                  id="signup2"
                >
                  <div className={"text-color-secondary"}>
                    <div className={"bsl-4"}>
                      It is necessary for our payment processor and Bizibuz to
                      verify that you are not misrepresenting your identity in
                      name or activity as a provider of after school services to
                      youth. Although from a practical standpoint we cannot vet
                      that your business is complying with all legal
                      requirements, we do take our responsibility to parents for
                      fraud reduction seriously. - ZH
                    </div>
                  </div>
                </ViewBlock>
                <ViewBlock
                  title={
                    "Why have I been asked if I want to link another Kidzbiz user under my account setup - ZH?"
                  }
                  id="signup3"
                >
                  <div className={"text-color-secondary"}>
                    <div className={"bsl-4"}>
                      Bizibuz gives you the option of inviting other Kidzbiz
                      users to upload class schedules and manage associated
                      bookings under your brand, your organization profile and
                      your policies. This may be desirable if - ZH
                      <ol>
                        <li>
                          you are a franchisor and have numerous locations run
                          as separate franchises under your brand, or - ZH
                        </li>
                        <li>
                          where you control (a) venue(s) and outsource booking
                          management to sub-contracted activity providers. - ZH
                        </li>
                      </ol>
                    </div>
                    <div className={"bsl-4"}>
                      By clicking on this option, you are able to invite other
                      Kidzbiz users to create a Bizibuz login that is linked to
                      your organization’s brand (a Linked Account). The Linked
                      Account will have the ability to create course schedules,
                      set course-specific promotions, accept/reject bookings,
                      and take payments for courses listed under your
                      organization’s profile. - ZH
                    </div>
                    <div className="bsl-4">
                      As the inviter, you will have visibility over the Linked
                      Account’s activities on the Bizibuz platform including
                      course schedules, bookings and payments, however you will
                      not be able to edit the information. Course payments will
                      be made directly to the Stripe account nominated by the
                      Linked Account given they are managing the course
                      schedule. An inviter can withdraw the invitation and
                      remove the linkage to the Linked Account provided there
                      are no active customers. Bizibuz also retains the ability
                      to block the Linked Account at any time - ZH.{" "}
                    </div>
                    <div className={"bsl-4"}>
                      The transaction volume associated with all Linked Accounts
                      will be aggregated for the purposes of determining
                      appropriate Bizibuz fees, hence Bizibuz fees may be lower
                      than if the Linked Account set up their own standalone
                      Bizibuz account - ZH.
                    </div>
                    Bizibuz will not accept liability for any actions that are
                    contrary to the understanding reached between the inviter
                    and the invitee under either oral or written agreements. See
                    the Kidzbiz Agreement for further details - ZH.
                  </div>
                </ViewBlock>
                <ViewBlock
                  title={
                    "If I have permissioned another user to manage bookings as a Linked Account but subsequently change my mind, how can I de-link or block them - ZH?"
                  }
                  id="signup4"
                >
                  <div className={"text-color-secondary"}>
                    <div className={"bsl-4"}>
                      If you decide you no longer want to permission another
                      user to manage bookings, you can de-link them under the
                      Kidzbiz area. The de-linkage process can only occur if
                      there are no customers signed up to courses managed by the
                      Linked Account - ZH.
                    </div>
                    <div className={"bsl-4"}>
                      If there are courses for sale with customers signed up,
                      another alternative is to contact Bizibuz to block the
                      Linked Account rather than de-link as this preserves
                      active courses with existing customers, allowing the
                      Linked Account to still accept pending bookings but not
                      accept any new bookings, upload new courses or modify
                      existing information in any way - ZH.
                    </div>
                    A Linked Account when de-linked, is automatically converted
                    into a standalone Bizibuz account with historic customers
                    and transactional information copied into the new account.
                    All historic information pertaining to customers and
                    transactions will remain visible for the inviter after
                    de-linking a Linked Account - ZH.
                  </div>
                </ViewBlock>
                <ViewBlock
                  title={
                    "What does it mean if I have signed up to be a Linked Account on the basis of an invitation by another Kidzbiz - ZH?"
                  }
                  id="signup5"
                >
                  <div className={"text-color-secondary"}>
                    <div className={"bsl-4"}>
                      By accepting an invitation from another Kidzbiz, you will
                      be able to manage bookings under their brand. All courses
                      you create will be listed under the inviter’s organization
                      profile, and you will be subject to the inviter’s
                      oversight. The inviter will have visibility over your
                      actions on the Bizibuz platform as it pertains to
                      management of course schedules, bookings and payments,
                      however the inviter will not be able to edit the
                      information that you enter. As the manager of the course
                      schedule, you will be obligated to respond to booking
                      requests and course payments will be made directly to the
                      Stripe account nominated by you. The inviter retains the
                      ability to withdraw the invitation and linkage to you as
                      the invited user. Bizibuz also retains the ability to
                      block you as a user at any time - ZH.
                    </div>
                    Bizibuz will not accept liability for any actions or
                    omissions that are contrary to the understanding reached
                    between the inviter and the invitee under either oral or
                    written agreements. See the Kidzbiz Agreement for further
                    details - ZH.
                  </div>
                </ViewBlock>
                <ViewBlock
                  title={
                    "If I have signed up by accepting an invitation from another Kidzbiz as a Linked Account but change my mind, how can I de-link from them - ZH?"
                  }
                  id="signup6"
                >
                  <div className={"text-color-secondary"}>
                    <div>
                      If you decide you no longer want the courses you create to
                      be listed under the inviter’s organization profile and
                      brand, and instead you wish to create a standalone Bizibuz
                      login, you can de-link your account under the Kidzbiz area
                      if you have no courses with active customers. De-linking
                      will automatically convert your login into a standalone
                      Bizibuz account that contains your historic customer and
                      transaction data - ZH.
                    </div>
                    The transaction volume associated with all invited users
                    will be aggregated for the purposes of determining
                    appropriate Bizibuz fees, therefore Bizibuz fees may be
                    lower than if you set up a standalone Bizibuz account - ZH.
                  </div>
                </ViewBlock>
                <ViewBlock
                  title={
                    "How can I invite employees to help manage my Bizibuz account - ZH?"
                  }
                  id="signup7"
                >
                  <div className={"text-color-secondary"}>
                    <div>
                      You have the option of sending an invitation to employees
                      and specifying whether the access type should be - ZH:
                      <ul>
                        <li>
                          full: the employee will then have access to all
                          information associated with the Bizibuz account such
                          that access rights are equivalent to the inviter
                          account - ZH
                        </li>
                        <li>
                          partial: the employee will have access to basic
                          profile information and information regarding
                          customers and their transactions. They will have the
                          ability to manage courses, promotions and bookings
                          associated with courses. The employee will not have
                          the ability to do more sensitive tasks such as
                          downloading bulk customer lists, accessing monthly
                          payout information from Bizibuz to the employer and
                          will not be able to access the report area - ZH.
                        </li>
                      </ul>
                    </div>
                    Employees will be able to manage courses and bookings on
                    behalf of each other. If an employee leaves your
                    organization, you can edit details associated with their
                    account and/or remove their access completely - ZH.
                  </div>
                </ViewBlock>
              </div>
              <div
                id={"profile"}
                className={"faq-text-block"}
                style={{ marginTop: "32px" }}
              >
                <Title level={2}>Profile - ZH</Title>
                <Divider />
                <ViewBlock
                  title={
                    "What information should I put in my Kidzbiz profile - ZH?"
                  }
                >
                  <div className={"text-color-secondary"}>
                    The first step in raising customer awareness of your
                    Kidzbidz and selling courses on the Bizibuz platform is to
                    create a profile upon setting up your account. This is an
                    opportunity for you to advertise your brand and should
                    contain largely static information. For example, your logo,
                    a brief description of your business, your contact details,
                    locations, cancellation, absence, weather and safety
                    policies, and organization wide promotions you might wish to
                    create (that apply when a customer purchases multiple
                    courses from your organization during the same checkout
                    process) - ZH.
                  </div>
                </ViewBlock>
                <ViewBlock
                  title={
                    "When and how does my Kidzbiz profile appear on the Bizibuz platform?"
                  }
                >
                  <div className={"text-color-secondary"}>
                    Your Kidzbiz profile is viewable by customers when they
                    search for your organization by name or through associated
                    courses which link to an organization profile once you have
                    completed Stripe onboarding and uploaded courses for sale.
                    If you have signed up on the basis of an invitation from
                    another organization, your organization details may appear
                    under the profile and branding of the inviting organization
                    - ZH.
                  </div>
                </ViewBlock>
              </div>
              <div
                id={"courses"}
                className={"faq-text-block"}
                style={{ marginTop: "32px" }}
              >
                <Title level={2}>Courses - ZH</Title>
                <Divider />
                <ViewBlock
                  title={
                    "How do I upload a new course to the Bizibuz platform - ZH?"
                  }
                  id="courses1"
                >
                  <div className={"text-color-secondary"}>
                    The Bizibuz platform offers several ways to upload new
                    course information as quickly as possible under the Courses
                    area - ZH:
                    <ol>
                      <li>
                        Manual entry of fields (3 minutes to complete) - ZH
                      </li>
                      <li>
                        Copy and paste of prior courses (1 minute to complete) -
                        ZH
                      </li>
                      <li>
                        Bulk upload of courses using the Bizibuz template (less
                        than 1 minute to complete) - ZH
                      </li>
                    </ol>
                    Please note that option 3. does not allow for bulk upload of
                    course images but course images can be manually added to
                    courses once bulk created - ZH.
                  </div>
                </ViewBlock>
                <ViewBlock
                  title={
                    "Do you offer other approaches so that course uploads can be automated - ZH?"
                  }
                  id="courses2"
                >
                  <div className={"text-color-secondary"}>
                    We are actively working on a cloud CRM extension to the
                    Bizibuz platform which will re-create all our best features
                    on your website so that you can provide online bookings to
                    customers directly and manage your existing customers more
                    efficiently. This will mean that any courses you upload in
                    the Bizibuz platform can automatically be added to your
                    cloud CRM for your direct customers. Alternatively, for
                    Kidzbiz(s) with very large monthly gross transaction volume,
                    we may be able to partner with you on an API integration to
                    your website to automate course uploads. Please contact the
                    Bizibuz team for more information - ZH.
                  </div>
                </ViewBlock>
                <ViewBlock
                  title={
                    "What ongoing information must be kept up to date when a course is being offered for sale on the Bizibuz platform - ZH?"
                  }
                  id="courses3"
                >
                  <div className={"text-color-secondary"}>
                    Once a course has been uploaded to the Bizibuz platform,
                    provided there is no change to the course creation details,
                    the only information that must be regularly updated is the
                    headcount capacity so we can be sure we are not over booking
                    courses. Once a course is full because capacity is reached,
                    it will no longer be offered for sale on the Bizibuz
                    platform - ZH.
                  </div>
                </ViewBlock>
                <ViewBlock
                  title={
                    "How do I ensure that a course stays open for sale even after it is underway - ZH?"
                  }
                  id="courses4"
                >
                  <div className={"text-color-secondary"}>
                    During the course upload process, you will be asked to input
                    a minimum number of classes in a course for sale. If this
                    number is less than the complete number of classes in a
                    course, it means that a customer can purchase the remaining
                    classes in a course on a pro-rated basis until the number of
                    remaining classes is less than the minimum you have set for
                    sale. Once the number of classes left is below the minimum
                    threshold, the course will no longer be offered for sale on
                    the Bizibuz platform. Just remember, the smaller this
                    number, the longer a course will be offered for sale - ZH.
                  </div>
                </ViewBlock>
                <ViewBlock
                  title={
                    "How late or close to the next class occurring can a customer make a booking - ZH?"
                  }
                  id="courses5"
                >
                  <div className={"text-color-secondary"}>
                    We allow customers to make a booking up until 3 hours before
                    the next class starts. After that time, that class becomes
                    unbookable and provided the remaining class count has not
                    dropped below the minimum required classes you specified at
                    course creation, the next class in the course will
                    automatically become bookable - ZH.
                  </div>
                </ViewBlock>
                <ViewBlock
                  title={"How do I set promotions - ZH?"}
                  id="courses6"
                >
                  <div className={"text-color-secondary"}>
                    <div className={"bsl-4"}>
                      Under the Promotions area, you will be able to set a range
                      of promotions that incentivize different behaviour - ZH:
                      <ol>
                        <li>
                          Bulk purchase: organization loyalty promotions
                          associated with multiple course bookings e.g. buy “x”
                          (a number of courses) or spend “y” (an amount) from
                          our organization and receive “z”% off all courses from
                          the organization - ZH
                        </li>
                        <li>
                          Course purchase: promotions associated with specific
                          courses e.g. buy this course and receive ”x”% discount
                          / “y” number of free classes in the course - ZH
                        </li>
                      </ol>
                      The ability to run varied promotions can be combined with
                      tools in the Reports area for powerful analysis of the
                      effectiveness of different types of promotions - ZH.
                    </div>
                    If your login was created by invitation from another
                    organization, the inviting organization will control the
                    organization loyalty promotions and you will control the
                    course specific promotions - ZH.
                  </div>
                </ViewBlock>
                <ViewBlock
                  title={
                    "How do I cancel a course (all remaining classes) - ZH?"
                  }
                >
                  <div className={"text-color-secondary"}>
                    <div className={"bsl-4"}>
                      You can cancel courses under the Courses area. The Details
                      tab allows you to cancel the remaining classes in a course
                      for all attendees while the Customer tab allows you to
                      cancel the remaining classes in a course for individual
                      attendees - ZH.
                    </div>
                    <div className="bsl-4">
                      If you cancel a course or course booking, the customer
                      will be treated in accordance with the elections you have
                      made under “Rescheduling outcomes” as they stand at the
                      time the customer booked the course. You are asked to
                      confirm rescheduling outcomes as part of the account
                      creation sign up and may edit your elections under the
                      Kidzbiz/Details tab thereafter - ZH.
                    </div>

                    <div className="bsl-4">
                      If you elect to issue a refund upon you cancelling a
                      course or course booking, the customer will receive a
                      payment from Bizibuz for the remaining unattended classes.
                      If you elect to issue a credit upon cancelling a course or
                      course booking, the customer will automatically have the
                      credit applied at future checkout against courses
                      purchased from you. - ZH.
                    </div>

                    <div className="bsl-4">
                      If you cancel a course or booking for a course with status
                      of “In progress” (has at least one accepted booking),
                      Bizibuz will charge you a Cancellation Fee per cancelled
                      course attendee per cancelled course - ZH.
                    </div>
                  </div>
                </ViewBlock>

                <ViewBlock title={"How do I cancel a (single) class - ZH?"}>
                  <div className={"text-color-secondary"}>
                    <div className={"bsl-4"}>
                      You can cancel a specific class under the Courses area and
                      the Schedule tab. If you cancel a class, impacted
                      customers will be treated in accordance with the elections
                      you have made under “Rescheduling outcomes” as they stand
                      at the time the customer booked the course. You are asked
                      to confirm rescheduling outcomes as part of the account
                      creation sign up and may edit your elections under the
                      Kidzbiz/Details tab thereafter - ZH.
                    </div>
                    <div className="bsl-4">
                      If you elect to issue a refund upon you cancelling a
                      class, the customer will receive a payment from Bizibuz
                      for the cancelled class. If you elect to issue a credit,
                      the customer will automatically have the credit applied at
                      future checkout against courses purchased from you - ZH.
                    </div>

                    <div className="bsl-4">
                      For last minute class cancellations, Bizibuz will notify
                      impacted customers by both SMS and email so you don’t have
                      to worry about customers not being alerted in time - ZH.
                    </div>
                  </div>
                </ViewBlock>
              </div>
              <div
                id={"booking"}
                className={"faq-text-block"}
                style={{ marginTop: "32px" }}
              >
                <Title level={2}>Booking - ZH</Title>
                <Divider />

                <ViewBlock
                  title={
                    "How will I be notified when a customer books a course - ZH?"
                  }
                >
                  <div className={"text-color-secondary"}>
                    After a customer purchases a course, a booking request is
                    sent via email/SMS/within the app to the Kidzbiz that
                    uploaded the course. The notification will contain a link to
                    the Bookings area of the Kidzbiz portal for acceptance or
                    rejection. Bizibuz encourages Kidzbiz(s) to accept or reject
                    bookings within 24 hours of notification so it is important
                    that you provide a phone number or email address that you
                    regularly access - ZH.
                  </div>
                </ViewBlock>
                <ViewBlock
                  title={
                    "How do I accept or reject a booking from a customer - ZH?"
                  }
                >
                  <div className={"text-color-secondary"}>
                    The booking notification contains a link which you can
                    follow to the Bookings area of the Kidzbiz portal or go you
                    can directly to the Bookings area in order to accept or
                    reject a customer booking - ZH.
                  </div>
                </ViewBlock>
                <ViewBlock
                  title={
                    "What happens when I reject a booking and once I have rejected it, can I later change my mind - ZH?"
                  }
                >
                  <div className={"text-color-secondary"}>
                    Once you reject a booking, the customer will be notified and
                    the hold that was placed on the customer’s payment method at
                    the time of the booking will be released or if payment was
                    made at the time of booking, a refund will be processed. -
                    ZH
                    <u>
                      It is advisable to keep the available slots in your
                      courses up to date to prevent a customer from being
                      disappointed that their booking has not been accepted and
                      to minimize third party fees - ZH.
                    </u>
                  </div>
                </ViewBlock>
                <ViewBlock
                  title={
                    "What happens if I forget to accept or reject a customer booking - ZH?"
                  }
                >
                  <div className={"text-color-secondary"}>
                    If a booking is not accepted or rejected within the lesser
                    of 120 hours after the booking (5 days) and within 2 hours
                    of the first purchased class commencing, there will be
                    automatic cancellation of the booking. The hold that was
                    placed on the customer’s payment method at the time of the
                    booking will be released or if payment was made at the time
                    of booking, a refund will be processed. - ZH.
                    <u>
                      It is advisable to accept or reject bookings in a timely
                      manner to avoid automatic cancellation - ZH.
                    </u>
                  </div>
                </ViewBlock>
                <ViewBlock
                  title={
                    "How do I download my bookings for upload into my CRM software - ZH?"
                  }
                >
                  <div className={"text-color-secondary"}>
                    In the Bookings area there is functionality to allow you to
                    download your accepted bookings in csv or excel format. This
                    format can be imported into various types of CRM software -
                    ZH.
                  </div>
                </ViewBlock>
              </div>
              <div
                id={"cancellation"}
                className={"faq-text-block"}
                style={{ marginTop: "32px" }}
              >
                <Title level={2}>Cancellation - ZH</Title>
                <Divider />

                <ViewBlock
                  title={
                    "How do I cancel a course (all remaining classes) - ZH?"
                  }
                  id="cancellation1"
                >
                  <div className={"text-color-secondary"}>
                    <div className={"bsl-4"}>
                      You can cancel courses under the Courses area. The Details
                      tab allows you to cancel the remaining classes in a course
                      for all attendees while the Customer tab allows you to
                      cancel the remaining classes in a course for individual
                      attendees - ZH.
                    </div>
                    <div className="bsl-4">
                      If you cancel a course or course booking, the customer
                      will be treated in accordance with the elections you have
                      made under “Rescheduling outcomes” as they stand at the
                      time the customer booked the course. You are asked to
                      confirm rescheduling outcomes as part of the account
                      creation sign up and may edit your elections under the
                      Kidzbiz/Details tab thereafter - ZH.
                    </div>

                    <div className="bsl-4">
                      If you elect to issue a refund upon you cancelling a
                      course or course booking, the customer will receive a
                      payment from Bizibuz for the remaining unattended classes.
                      If you elect to issue a credit upon cancelling a course or
                      course booking, the customer will automatically have the
                      credit applied at future checkout against courses
                      purchased from you - ZH.
                    </div>

                    <div className="bsl-4">
                      If you cancel a course or booking for a course with status
                      of “In progress” (has at least one accepted booking),
                      Bizibuz will charge you a Cancellation Fee per cancelled
                      course attendee per cancelled course - ZH.
                    </div>
                  </div>
                </ViewBlock>

                <ViewBlock
                  title={"How do I cancel a (single) class - ZH?"}
                  id="cancellation2"
                >
                  <div className={"text-color-secondary"}>
                    <div className={"bsl-4"}>
                      You can cancel a specific class under the Courses area and
                      the Schedule tab. If you cancel a class, impacted
                      customers will be treated in accordance with the elections
                      you have made under “Rescheduling outcomes” as they stand
                      at the time the customer booked the course. You are asked
                      to confirm rescheduling outcomes as part of the account
                      creation sign up and may edit your elections under the
                      Kidzbiz/Details tab thereafter - ZH.
                    </div>
                    <div className="bsl-4">
                      If you elect to issue a refund upon you cancelling a
                      class, the customer will receive a payment from Bizibuz
                      for the cancelled class. If you elect to issue a credit,
                      the customer will automatically have the credit applied at
                      future checkout against courses purchased from you - ZH.
                    </div>

                    <div className="bsl-4">
                      For last minute class cancellations, Bizibuz will notify
                      impacted customers by both SMS and email so you don’t have
                      to worry about customers not being alerted in time - ZH.
                    </div>
                  </div>
                </ViewBlock>

                <ViewBlock
                  title={
                    "Can a customer cancel remaining classes in a course or request compensation for a class they can no longer attend - ZH?"
                  }
                  id="cancellation3"
                >
                  <div className={"text-color-secondary"}>
                    Customers are able to cancel the remaining classes in a
                    course or request an absence from an upcoming class under
                    the My courses area of the customer portal if it is more
                    than 3 hours before the start of that class. If a customer
                    requests to cancel a course or a class, they will be
                    compensated in accordance with the elections you have made
                    under “Rescheduling outcomes” as they stand at the time the
                    customer booked the course. You are asked to confirm
                    rescheduling outcomes as part of the account creation sign
                    up and may edit your elections under the Kidzbiz/Details tab
                    thereafter
                  </div>
                </ViewBlock>
              </div>
              <div
                id={"payment"}
                className={"faq-text-block"}
                style={{ marginTop: "32px" }}
              >
                <Title level={2}>Payment - ZH</Title>
                <Divider />

                <ViewBlock
                  title={
                    "How is the monthly remittance of customer revenue calculated - ZH?"
                  }
                  id="payment1"
                >
                  <div className={"text-color-secondary"}>
                    The monthly remittance amount is calculated based on gross
                    customer spend, adjusted for customer refunds and credits,
                    Bizibuz Fee, cancellation fees and any negative balance from
                    a prior month. The calculation is applied on an accrued
                    basis therefore reflects earned revenue on classes in the
                    preceding month (classes that have already occurred).
                    Transaction volume and the Bizibuz Fee will not be
                    recognised on future classes until the classes have occurred
                    and resulting revenue is earned - ZH.
                  </div>
                </ViewBlock>
                <ViewBlock
                  title={
                    "How can I download customer revenue and is it in a form compatible for importing into my accounting software - ZH?"
                  }
                  id="payment2"
                >
                  <div className={"text-color-secondary"}>
                    <div className={"bsl-4"}>
                      <div>
                        The Bizibuz platform is powered by Stripe. Stripe is
                        currently integrated with 11 accounting partners in Hong
                        Kong which facilitates automated reconciliation for
                        activity providers using Xero, NetSuite, QuickBooks etc.
                        See further details here - ZH:{" "}
                        <u>
                          <Link
                            to="https://stripe.com/en-hk/partners/apps-and-extensions?l=hk&n=accounting"
                            target="_blank"
                          >
                            https://stripe.com/en-hk/partners/apps-and-extensions?l=hk&n=accounting
                          </Link>
                        </u>
                      </div>{" "}
                      The Payments area of the Kidzbiz portal also allows you to
                      export detailed monthly remittance amounts that are
                      itemized by customer transaction in CSV/TXT format.
                      Depending on your accountant’s requirements, you can
                      either import this file into your accounting software as
                      bulk sales invoices (itemized reconciliation) or just save
                      it as a document for record purposes (single monthly
                      payment reconciliation) - ZH.
                    </div>
                    <div className={"bsl-4"}>
                      There are 2 CSV/TXT formats you can choose from in order
                      to ensure upload into an accounting software is as easy as
                      possible if required - ZH:
                      <ol>
                        <li>
                          Generic format – this includes all relevant
                          information including detailed third party fees - ZH.
                        </li>
                        <li>
                          Xero importing compatible format – this includes
                          customer transaction information but may exclude some
                          third party fee information in order to be in a format
                          that is importable as customer revenue - ZH.
                        </li>
                      </ol>
                    </div>
                    <div className={"bsl-4"}>
                      <div className={"bsl-4"}>
                        If your accountant requests you to import the csv/txt
                        file as bulk sales invoices, please follow the steps
                        below. If you have further difficulties, Xero or your
                        accountant should be contacted for further assistance -
                        ZH.
                      </div>
                      Xero - ZH:
                      <ol>
                        <li>Save down the CSV file - ZH</li>
                        <li>
                          Go to Business {"->"} Invoices {"->"} Import and
                          proceed to import the CSV file as an itemized list of
                          customer sales invoices - ZH
                        </li>
                      </ol>
                    </div>
                  </div>
                </ViewBlock>
                <ViewBlock
                  title={
                    "How do I enter the relevant details to receive the monthly remittance of customer revenue - ZH?"
                  }
                  id="payment3"
                >
                  <div className={"text-color-secondary"}>
                    Upon account creation you will be asked to onboard with
                    Stripe. Once you have a Stripe account created, this will
                    allow direct payouts into the bank account you nominated
                    during Stripe onboarding - ZH.
                  </div>
                </ViewBlock>
                <ViewBlock
                  title={
                    "What do I do if I don’t agree with the monthly remittance amount - ZH?"
                  }
                  id="payment4"
                >
                  <div className={"text-color-secondary"}>
                    Please check the Generic format of the monthly payout CSV
                    file for the monthly remittance amount breakdown. Contact
                    Bizibuz for further discussion if the issue is still not
                    resolved - ZH.
                  </div>
                </ViewBlock>
              </div>
              <div
                id={"reports"}
                className={"faq-text-block"}
                style={{ marginTop: "32px" }}
              >
                <Title level={2}>Reports - ZH</Title>
                <Divider />
                <ViewBlock
                  title={
                    "What type of reporting functionality does the Bizibuz platform offer - ZH?"
                  }
                  id="reports1"
                >
                  <div className={"text-color-secondary"}>
                    Bizibuz aims to help you grow your Kidzbiz. One tool we
                    offer to achieve this is the Reports area of the Kidzbiz
                    portal which has two main types of reports - ZH:
                    <ol>
                      <li>
                        Business performance reporting. These are tailored
                        reports to help you track numbers of paying customers,
                        their loyalty and spending patterns across metrics such
                        as courses, categories, age brackets, locations etc.
                        This is a powerful tool when used in conjunction with
                        organisation loyalty and course specific promotions -
                        ZH.
                      </li>
                      <li>
                        Web traffic reporting. These reports reflect Google
                        Analytics metrics such as users, page views, average
                        time on page, entrances, bounce rate and exits
                        associated with the Bizibuz platform and web pages that
                        are specific to your Kidzbiz - ZH.
                      </li>
                    </ol>
                  </div>
                </ViewBlock>
                <ViewBlock
                  title={"How can I download these reports - ZH?"}
                  id="reports2"
                >
                  <div className={"text-color-secondary"}>
                    The Reports area of the Kidzbiz portal allows you to export
                    business performance and web traffic reports in either pdf
                    or csv format attached to an email. In order to access the
                    web traffic reports, you may need to have a Google Analytics
                    account which can be created for free if you don’t already
                    have one - ZH.
                  </div>
                </ViewBlock>
                <ViewBlock
                  title={
                    "Does any other Kidzbidz have access to my business performance or web traffic statistics - ZH"
                  }
                  id="reports3"
                >
                  <div className={"text-color-secondary"}>
                    Your Kidzbiz performance and web traffic statistics are not
                    available to other Kidzbiz(s) unless you created your
                    Bizibuz login by accepting an invitation from a Kidzbiz
                    organization. If you have a standalone Kidzbiz login that
                    you created, your business performance is treated
                    confidentially - ZH.
                  </div>
                </ViewBlock>
              </div>
              <div
                id={"other"}
                className={"faq-text-block "}
                style={{ marginTop: "32px" }}
              >
                <Title level={2}>Other - ZH</Title>
                <Divider />

                <ViewBlock
                  title={"How can I download my customer information?"}
                  id="other1"
                >
                  <div className={"text-color-secondary"}>
                    The Customers area of the Kidzbiz portal allows you to
                    export a list of your customers and their relevant details
                    in csv or excel format. This format will facilitate easy
                    upload into CRM software - ZH.
                  </div>
                </ViewBlock>
                <ViewBlock
                  title={
                    "How can I access the reviews that have been written about my courses - ZH?"
                  }
                  id="other2"
                >
                  <div className={"text-color-secondary"}>
                    The Reviews area of the Kidzbiz portal allows you to access
                    a list of reviews written about courses associated with your
                    organisation profile or course management - ZH.
                  </div>
                </ViewBlock>
                <ViewBlock
                  title={
                    "Can I respond to a review and have my response published - ZH?"
                  }
                  id="other3"
                >
                  <div className={"text-color-secondary"}>
                    Yes you can respond to a review and your response may be
                    accessible to all review readers - ZH.
                  </div>
                </ViewBlock>
                <ViewBlock
                  title={
                    "How can I implement promotions and test the impact - ZH?"
                  }
                  id="other4"
                >
                  <div className={"text-color-secondary"}>
                    <div className={"bsl-4"}>
                      Under the Promotions area, you will be able to set a range
                      of promotions that incentivize different behaviour - ZH:
                      <ol>
                        <li>
                          Organization loyalty: promotions associated with
                          multiple course bookings e.g. buy “x” or more courses
                          from our organization and receive “y”% off - ZH
                        </li>
                        <li>
                          Course purchase: promotions associated with specific
                          courses e.g. buy this course and receive $”x” discount
                          / “y”% discount / “z” number of free classes in the
                          course - ZH
                        </li>
                      </ol>
                      The ability to run varied promotions can be combined with
                      tools in the Reports area for powerful analysis of the
                      effectiveness of different types of promotions - ZH.
                    </div>
                    <div className={"bsl-4"}>
                      If your login was created by invitation from another
                      Kidzbiz, the inviting organization will control the
                      organization loyalty promotions and you will control the
                      course specific promotions - ZH.
                    </div>
                    You can test the promotions impact by viewing the business
                    performance and web traffic reports available in the Reports
                    area of the Kidzbiz portal - ZH.
                  </div>
                </ViewBlock>

                <ViewBlock
                  title={
                    "What happens if I breach the Kidzbiz Agreement (for example: Active Course Content, Exclusivity, Max Offer Price, No Inducement, Confidential Information) - ZH?"
                  }
                  id="other5"
                >
                  <div className={"text-color-secondary"}>
                    Bizibuz may take a number of steps in response to a breach
                    depending on the severity including removing your current
                    profile and course information, and blocking your further
                    access to the Bizibuz platform - ZH.
                  </div>
                </ViewBlock>

                <ViewBlock
                  title={"What does it mean if my account gets blocked - ZH?"}
                  id="other6"
                >
                  <div className={"text-color-secondary"}>
                    <div className={"bsl-4"}>
                      <ul>
                        <li>
                          If you have active courses for sale (with prior or
                          pending customer bookings), this means the course
                          remains active for those pre-existing or pending
                          customers, and you can still log in and accept
                          bookings from the pending customers - ZH
                        </li>
                        <li>
                          All courses including active courses mentioned above,
                          will be hidden from the customer website and you will
                          not be able to accept any new bookings - ZH
                        </li>
                        <li>
                          You will no longer be able to upload new courses for
                          sale or modify any pre-existing information associated
                          with your account including profile information, bank
                          account details or course details - ZH
                        </li>
                        <li>
                          You will also no longer have access to the Reports
                          functionality - ZH
                        </li>
                        <li>
                          If you have invited other users to become a Linked
                          Account, the Linked Accounts will also be blocked,
                          however if you have accepted an invitation to become a
                          Linked Account and you alone become blocked, this will
                          not impact the inviting user - ZH
                        </li>
                      </ul>
                    </div>
                    Account blocking can potentially be reversed by contacting
                    Bizibuz - ZH.
                  </div>
                </ViewBlock>

                <ViewBlock
                  title={"How is my information used - ZH?"}
                  id="other7"
                >
                  <div className={"text-color-secondary"}>
                    Bizibuz believes in protecting your privacy. Your use of the
                    Bizibuz Platform constitutes your acceptance and agreement
                    to be bound by our Privacy Policy. Please access - ZH{" "}
                    <Link
                      to="/bizibuz-policies"
                      style={{ marginLeft: "4px" }}
                      target="_blank"
                    >
                      Bizibuz’s Privacy Policy here - ZH.
                    </Link>
                  </div>
                </ViewBlock>
              </div>
            </div>
          </div>
        </LayoutPage>
      </>
    );
  }),
);

export default ZhFAQs;
