import React from "react";
import "./index.less";

interface IProps extends React.HTMLAttributes<HTMLElement> {
  disabled?: boolean;
  type?: 'reset' | 'button' | 'submit' | undefined;
}

const ButtonLink = (props: IProps) => {
  const { disabled } = props;
  return (
    <button
      {...props}
      className={`link no-select cursor-pointer ${disabled ? "disabled" : ""} ${
        props.className
      }`}
    >
      <span className={"link-text no-select"}>{props.children}</span>
    </button>
  );
};

export default ButtonLink;
