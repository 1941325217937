import { LoadingOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  message,
  Modal,
  notification,
  Popover,
  Tabs,
  Typography,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { ButtonWhite } from "components/ButtonWhite";
import { HeadSeo } from "components/HeadSeo";
import ImageUploader from "components/ImageUploader";
import { FORMAT_DATE } from "constants/commons";
import QueryString from "constants/query-string";
import { CourseStatus, CourseTab } from "constants/type.interface";
import { AppRoutes } from "helpers/app.routes";
import Cookies from "js-cookie";
import { omit, uniq } from "lodash";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import moment from "moment";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  RouteComponentProps,
  useHistory,
  useLocation,
  withRouter,
} from "react-router-dom";
import {
  useCurrentUser,
  useCurrentVerify,
  userBlocked,
  useType,
} from "scripts/custom-hooks/useCurrentUser";
import { useQueryParams } from "scripts/custom-hooks/useQueryParams";
import helpers from "scripts/helpers";
import { courseStoreContext, pageable } from "scripts/mobx/courseStore";
import ButtonLink from "../../../components/ButtonLink";
import TableComponent from "../../../components/TableComponent/TableComponent";
import { bizIcons } from "../../../constants/bizConstants";
// import { tableFiltersData } from "../../../constants/filters/courses";
import { DEVICES, useDevices } from "../../../scripts/custom-hooks/useDevices";
import "../index.less";
import { tableCoursesColumns, tableCoursesData } from "../tableGenerator";
import { CurrentCoursesFilters } from "./current-filters";
import { CoursesFilters } from "./filters";

const { Title } = Typography;
const { TabPane } = Tabs;

interface IProps {
  createCourseHandler?: () => void;
  backToCourseCreateHandler?: () => void;
  forCopy?: boolean;
  isLoading?: boolean;
  copyCourseHandler?: (id: number) => void;
}

export const ContentWithTableCourses = observer((props: IProps) => {
  const {
    createCourseHandler,
    forCopy,
    backToCourseCreateHandler,
    copyCourseHandler,
  } = props;
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const queryParams = useQueryParams();
  const currentTab = (queryParams.get("tab") as CourseTab) || CourseTab.Current;
  const keyword = queryParams.get("keyword") || "";
  const isVerified = useCurrentVerify();
  const user = useCurrentUser();
  const userType = useType();
  const isBlocked = userBlocked();
  const device = useDevices();
  const [form] = useForm();

  const [isBroken, setIsBroken] = useState<boolean | undefined>();
  const [isLoading, setIsLoading] = useState<boolean | undefined>(true);
  const [isConfirm, setConfirm] = useState<boolean>(false);
  const [tabKey, setTabKey] = useState<CourseTab>(currentTab);
  const [keywordState, setKeyword] = useState<string>(keyword);
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  const [checkThisPage, setCheckThisPage] = useState<boolean>(false);
  const [checkAll, setCheckAll] = useState<boolean>(false);
  const [isUpload, setUpload] = useState<boolean>(false);
  const [courseNames, setCourseNamesArr] = useState<
    { courseName: string; url: string }[]
  >([]);
  const [courseList, setCourseList] = useState<any>();

  const courseStore = useContext(courseStoreContext);
  const filterModel = toJS(courseStore.filterModel);

  const showConfirm = () => {
    setConfirm(true);
  };

  useEffect(() => {
    if (
      device &&
      (device === DEVICES.MOBILE ||
        device === DEVICES.TABLET_PORT ||
        device === DEVICES.TABLET_LAND ||
        device === DEVICES.DESKTOP) &&
      !isBroken
    ) {
      setIsBroken(true);
    } else if (
      device &&
      device !== DEVICES.MOBILE &&
      device !== DEVICES.TABLET_PORT &&
      device !== DEVICES.TABLET_LAND &&
      device !== DEVICES.DESKTOP &&
      isBroken
    ) {
      setIsBroken(false);
    }
  }, [device, isBroken]);

  const getOnlyCourses = async (filter?: any) => {
    setIsLoading(true);

    try {
      const res = await courseStore.getCourses(filter);
      const data = res?.filter((el: any) => el.state === filter?.state)?.[0];
      setCourseList(data);
    } catch (error) {
      // console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getCourses = async (filter?: any) => {
    setIsLoading(true);

    try {
      const res = await courseStore.getCourses(filter);

      let newSelectedRowKeys = uniq(
        selectedRowKeys.concat(toJS(courseStore?.dataSelectedRowKeys)),
      );
      const items = res
        ?.filter((el: any) => el.state === tabKey)?.[0]
        ?.data?.filter((el: any) =>
          tabKey === CourseTab.Current
            ? el?.status !== CourseStatus.InProgress
            : el,
        )
        ?.map((el: any) => Number(el.id));
      if (checkAll) {
        if (!checkThisPage) {
          newSelectedRowKeys = uniq(
            newSelectedRowKeys.filter((el) => !items?.includes(el)),
          );
        } else {
          newSelectedRowKeys = uniq(newSelectedRowKeys.concat(items));
        }
      } else if (checkThisPage) {
        newSelectedRowKeys = uniq(items);
      } else {
        newSelectedRowKeys = [];
      }
      courseStore?.updateSelectRowKeys(newSelectedRowKeys);
      setSelectedRowKeys(newSelectedRowKeys);

      const data = res?.filter((el: any) => el.state === filter?.state)?.[0];
      setCourseList(data);
      getPreparePublish(data?.data, checkAll, newSelectedRowKeys);
    } catch (error) {
      // console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const tabQueryParam =
    (queryParams.get("tab") as CourseTab) || CourseTab.Current;
  const keywordQueryParam = queryParams.get("keyword") || "";

  useEffect(() => {
    const loadCourses = async () => {
      setTabKey(tabQueryParam);
      setKeyword(keywordQueryParam);
      Cookies.set("coursesTab", tabQueryParam);

      getCourses({
        pageable,
        state: tabQueryParam,
        keyword: keywordQueryParam,
      });
    };

    loadCourses();
  }, [tabQueryParam, keywordQueryParam]);

  const resetSelectRowKeys = () => {
    setSelectedRowKeys([]);
    setCheckAll(false);
    setCheckThisPage(false);
    courseStore?.updateSelectRowKeys([]);
  };

  const onSelectedRowKeys = (id: number | string) => {
    let newSelectedRowKeys = uniq(
      selectedRowKeys.concat(toJS(courseStore?.dataSelectedRowKeys)),
    );
    const lists = toJS(courseStore?.courseList);
    const items = lists
      ?.filter((el: any) => el.state === tabKey)?.[0]
      ?.data?.filter((el: any) =>
        tabKey === CourseTab.Current
          ? el?.status !== CourseStatus.InProgress
          : el,
      )
      ?.map((el: any) => Number(el.id));
    if (["all", "this_page"].includes(id?.toString())) {
      if (id === "this_page") {
        setCheckAll(false);
        setCheckThisPage(true);
        newSelectedRowKeys = uniq(items);
      } else {
        setCheckAll(true);
        setCheckThisPage(true);
        newSelectedRowKeys = uniq(newSelectedRowKeys.concat(items));
      }
    } else if (id === "remove") {
      setCheckAll(false);
      setCheckThisPage(false);
      newSelectedRowKeys = [];
    } else if (id === "this_page_remove") {
      setCheckAll(false);
      setCheckThisPage(false);
      newSelectedRowKeys = uniq(
        newSelectedRowKeys.filter((el) => !items?.includes(el)),
      );
    } else {
      if (newSelectedRowKeys.includes(Number(id))) {
        newSelectedRowKeys = newSelectedRowKeys?.filter(
          (key) => key !== Number(id),
        );
      } else {
        newSelectedRowKeys = newSelectedRowKeys.concat(Number(id));
      }
    }
    courseStore?.updateSelectRowKeys(newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
    getPreparePublish(
      courseList?.data,
      ["all"].includes(id?.toString()),
      newSelectedRowKeys,
    );
  };

  const getPreparePublish = async (
    courseList: any[],
    isCheckAll: boolean,
    newSelectedRowKeys: any[],
  ) => {
    try {
      const res = await courseStore.getPreparePublish({
        ids: isCheckAll ? [] : newSelectedRowKeys,
        allCourse: isCheckAll,
      });

      const dateCourseNames = res?.map((el: string) => {
        return {
          courseName: el,
          url: courseList.find((item) => item.name === el)?.imageUrl || "",
        };
      });
      const names = uniq(
        courseList
          ?.filter((el) => newSelectedRowKeys?.includes(el.id))
          ?.map((el) => el?.name),
      );

      const currentCourseName = isCheckAll
        ? dateCourseNames
        : dateCourseNames.filter((el: any) => names.includes(el?.courseName));
      setCourseNamesArr(currentCourseName);
    } catch (error) {
      // TODO: handle error
    }
  };

  const copyLinkClipboard = (
    id: number,
    name: string,
    type: "iframe" | "link",
  ) => {
    const ta = document.createElement("textarea");
    const link = `${process.env.RAZZLE_CUSTOMER_URL}/courses/${id}/${name
      .split(" ")
      .join("-")}`;

    if (type === "iframe") {
      ta.innerText = `<iframe src="${link}" title="${name}"></iframe>`;
    } else {
      ta.innerText = `${link}`;
    }
    document.body.appendChild(ta);
    ta.select();
    document.execCommand("copy");
    message.success(
      t("courses.courseLinkCopied", { defaultValue: "Course link copied" }),
    );
    ta.remove();
  };

  const onPageChangeHandler = (page: any) => {
    getCourses({
      ...filterModel,
      pageable: {
        ...filterModel?.pageable,
        pageNumber: page,
      },
    });
  };

  const searchHandler = async (event: any) => {
    const value = event?.target?.value;
    setKeyword(value);

    history.replace({
      pathname: location?.pathname,
      search: QueryString.stringify({
        tab: currentTab,
        keyword: value ?? "",
      }),
    });
  };

  const onApplyFilters = (data: any) => {
    let params = {
      ...filterModel,
      pageable: {
        ...filterModel?.pageable,
        pageNumber: 1,
      },
    };
    if (data?.Age) {
      const ages = data?.Age?.map((a: string) => {
        const field = a?.split("-");
        return {
          ageFrom: Number(field?.[0]) * 12,
          ageTo: Number(field?.[1]) * 12,
        };
      });

      Object.assign(params, {
        ages,
      });
    } else {
      params = omit(params, ["ages"]);
    }
    if (data?.dateFrom) {
      Object.assign(params, {
        dateFrom: moment(data?.dateFrom)?.format(FORMAT_DATE.query),
      });
    } else {
      params = omit(params, ["dateFrom"]);
    }
    if (data?.dateTo) {
      Object.assign(params, {
        dateTo: moment(data?.dateTo)?.format(FORMAT_DATE.query),
      });
    } else {
      params = omit(params, ["dateTo"]);
    }

    if (data?.categoryId) {
      Object.assign(params, {
        categories: data?.categoryId,
      });
    } else {
      params = omit(params, ["categories"]);
    }

    if (data?.subCategory) {
      Object.assign(params, {
        subcategories: data?.subCategory,
      });
    } else {
      params = omit(params, ["subcategories"]);
    }
    if (data?.suburb) {
      Object.assign(params, {
        suburb: data?.suburb,
      });
    } else {
      params = omit(params, ["suburb"]);
    }

    if (data?.status) {
      Object.assign(params, {
        status: data?.status,
      });
    } else {
      params = omit(params, ["status"]);
    }

    if (!params?.keyword) {
      params = omit(params, ["keyword"]);
    }

    if (data?.providerId) {
      Object.assign(params, {
        providerId: data?.providerId,
      });
    } else {
      params = omit(params, ["providerId"]);
    }

    getCourses(params);
  };

  //Change tab
  const onTabClick = (key: any) => {
    setKeyword("");
    setTabKey(key);
    resetSelectRowKeys();

    history.replace({
      pathname: location?.pathname,
      search: QueryString.stringify({
        tab: key,
      }),
    });
    Cookies.set("coursesTab", key);
  };

  //Upload ALl Image
  const onCheckBeforeUpload = (messages: string[]) => {
    messages.map((m) => message.error(m));
  };

  const onUploadChanged = (file: File, index: number) => {
    const formData = new FormData();
    formData.append("files", file);
    courseStore?.uploadImage(formData).then((res) => {
      form.setFieldsValue({
        ["courseName_" + index]: res?.data?.[0]?.url,
      });
    });
  };

  const uploadButton = (isUploading: boolean) => {
    return (
      <div>
        <div style={{ height: "24px" }} className={"flex-center"}>
          {isUploading ? (
            <LoadingOutlined />
          ) : (
            <i className={`${bizIcons.IMAGE} fs-26 text-color-secondary`} />
          )}
        </div>
      </div>
    );
  };

  const onPublished = async () => {
    try {
      await form.validateFields();

      setIsLoading(true);
      const images = form.getFieldsValue();
      const courseImages = courseNames
        .map((el, index) => {
          return {
            courseName: el?.courseName,
            url: el?.url || images["courseName_" + index],
          };
        })
        .filter((el) => Boolean(el.url));

      const params = {
        ids: checkAll ? [] : selectedRowKeys,
        allCourse: checkAll,
        courseImages: courseImages?.length > 0 ? courseImages : [],
      };

      try {
        await courseStore.bulkPublishCourse(params);

        resetSelectRowKeys();
        setUpload(false);
        Modal.destroyAll();
        notification.success({
          message: t("courses.bulk_course_publish_sucess"),
        });

        setTimeout(() => {
          setIsLoading(false);
          // onTabClick(CourseTab.Draft);
          getOnlyCourses({
            ...filterModel,
            state: CourseTab.Draft,
          });
        }, 400);
      } catch (err: any) {
        setIsLoading(false);
        helpers.throwErrors(
          t,
          [],
          err?.data?.error,
          err?.data?.message || t("courses.bulk_course_publish_error"),
        );
      }
    } catch (error: any) {
      const errorMessage = (error?.errorFields?.[0]?.errors || []).join(", ");
      notification.error({
        message: errorMessage,
      });
    }
  };

  const handleClickPublishBtn = () => {
    if (!checkAll && !selectedRowKeys.length) {
      notification.warn({
        message: t("courses.please_select_courses"),
      });
    } else {
      if (courseNames.filter((item) => !item.url).length) setUpload(true);
      else onPublished();
    }
  };

  const onDelete = async () => {
    if (tabKey === CourseTab.Current) {
      await getCourses({ ...filterModel, status: [CourseStatus.Pending] }); // only allow to delete pending status course on current tab
    }

    const params = {
      ids: checkAll ? [] : selectedRowKeys,
      allCourse: checkAll,
      state: tabKey,
    };

    const deleteAmount = checkAll
      ? courseStore.courseList.find((item: any) => item.state === tabKey)
          ?.totalCount || 0
      : selectedRowKeys.length;

    if (!deleteAmount) return;

    Modal.confirm({
      title: t("courses.confirmBulkDelete", {
        count: deleteAmount,
      }),
      onOk: async () => {
        try {
          setIsLoading(true);
          await courseStore?.bulkDeleteCourse(params);
          resetSelectRowKeys();
          notification.success({
            message: t("courses.bulk_course_delete_sucess"),
          });

          // load the second last page when delete all courses on last page
          const isLastPage =
            courseList.pageNumber ===
            (courseList.totalCount % courseList.pageSize
              ? Math.floor(courseList.totalCount / courseList.pageSize) + 1
              : courseList.totalCount / courseList.pageSize);
          const isDeleteAllCourseOnLastPage =
            params.ids.length === courseList.totalCount % courseList.pageSize;

          await getOnlyCourses({
            ...filterModel,
            pageable: {
              ...filterModel.pageable,
              pageNumber:
                isLastPage && isDeleteAllCourseOnLastPage
                  ? filterModel.pageable.pageNumber - 1
                  : filterModel.pageable.pageNumber,
            },
          });
        } catch (error: any) {
          helpers.throwErrors(
            t,
            ["com.bizibuz.core.service.course.InvalidInputException"],
            error?.data?.error,
            error?.data?.message ||
              error?.data?.error ||
              t("courses.bulk_course_delete_error"),
          );
        } finally {
          setIsLoading(false);
        }
      },
    });
  };
  /* ---- Bulk Action ----- */
  const BulkAction = () => {
    return (
      isVerified && (
        <>
          {[CourseTab.Current].includes(tabKey) ? (
            <Button
              className={isBroken ? "ml-20" : ""}
              style={{
                lineHeight: "22px",
                fontSize: "14px",
                verticalAlign: "middle",
                padding: "11px 17px 7px 11px",
                height: "unset",
              }}
              type="primary"
              htmlType="button"
              onClick={onDelete}
              disabled={selectedRowKeys?.length ? false : true}
            >
              <div
                style={{ marginTop: "-4px", display: "flex" }}
                className="flex-center btn-group-login"
              >
                <i className={`${bizIcons.DELETE} mr-8 fs-20`} />
                {t("bizBase.delete", { defaultValue: "Delete" })}
              </div>
            </Button>
          ) : null}
          {[CourseTab.Draft].includes(tabKey) ? (
            <Button
              className={isBroken ? "ml-20" : ""}
              style={{
                lineHeight: "22px",
                fontSize: "14px",
                verticalAlign: "middle",
                padding: "11px 11px 7px",
                height: "unset",
              }}
              type="primary"
              htmlType="button"
              disabled={selectedRowKeys?.length ? false : true}
            >
              <div
                style={{ marginTop: "-4px", display: "flex" }}
                className="flex-center btn-group-login"
              >
                <div
                  onClick={handleClickPublishBtn}
                  className="flex-align-items"
                >
                  <i className={`${bizIcons.LOGIN} mr-8 fs-20`} />
                  {t("bizBase.publish", { defaultValue: "Publish" })}
                </div>

                <Popover
                  placement="bottomLeft"
                  content={
                    <div style={{ width: "146px" }}>
                      <div className={"popover-button"} onClick={onDelete}>
                        <i className={`${bizIcons.DELETE} mr-8 fs-20`} />
                        {t("bizBase.delete", { defaultValue: "Delete" })}
                      </div>
                    </div>
                  }
                  trigger="click"
                >
                  <i
                    className={`${bizIcons.ARROWDOWN} ml-8`}
                    style={{ fontSize: 30 }}
                  />
                </Popover>
              </div>
            </Button>
          ) : null}
        </>
      )
    );
  };
  /* ---- End Bulk Action ----- */

  const HeadingSelectOption = () => {
    return (
      isVerified && (
        <div className="flex-center">
          {/* ---- Select Item ----- */}
          <Popover
            placement="bottomLeft"
            content={
              <div style={{ width: "146px" }}>
                <div
                  className={"popover-button"}
                  onClick={() => {
                    if (!checkThisPage) {
                      onSelectedRowKeys?.("this_page");
                    } else {
                      onSelectedRowKeys?.("this_page_remove");
                    }
                  }}
                  style={{
                    fontWeight: checkThisPage ? "bold" : "normal",
                    opacity: checkAll ? 0.5 : 1,
                    pointerEvents: checkAll ? "none" : "auto",
                  }}
                >
                  {t("bizBase.selectVisible", {
                    defaultValue: "Select visible",
                  })}
                </div>
                <div
                  className={"popover-button"}
                  onClick={() => {
                    if (!checkAll) {
                      onSelectedRowKeys?.("all");
                    } else {
                      onSelectedRowKeys?.("remove");
                    }
                  }}
                  style={{ fontWeight: checkAll ? "bold" : "normal" }}
                >
                  {t("bizBase.selectAll", { defaultValue: "Select all" })}
                </div>
              </div>
            }
            trigger="click"
          >
            <Button
              style={{
                lineHeight: "22px",
                fontSize: "14px",
                verticalAlign: "middle",
                padding: "3px",
                height: "unset",
              }}
              type="primary"
              htmlType="button"
            >
              <div
                style={{ display: "flex" }}
                className="flex-center btn-group-login"
              >
                <i
                  className={`${bizIcons.ARROWDOWN}`}
                  style={{
                    fontSize: "26px",
                    margin: "0px",
                  }}
                />
              </div>
            </Button>
          </Popover>
          {/* ---- End Select Item ----- */}
        </div>
      )
    );
  };

  //END BULK ACTION

  //TABLE DATA
  const mapTableData = useCallback(() => {
    const lists = toJS(courseStore?.courseList);
    const items = lists?.filter((el: any) => el.state === tabKey)?.[0]?.data;
    const mapper = (el: any) => {
      return tableCoursesData(el, t);
    };
    return items?.map(mapper);
  }, [courseStore?.courseList]);

  const tableColumns: any[] = tableCoursesColumns({
    tab: tabKey,
    isLinked: user?.isLinked,
    isBroken,
    forCopy,
    copyCourseHandler,
    rowSelection: tabKey === CourseTab.Draft && !isBlocked ? true : false,
    selectedRowKeys,
    onSelectedRowKeys,
    providerId: user?.providerAttributes?.id,
    copyLinkClipboard,
    checkAll,
    checkThisPage,
    isVerified,
    HeadingSelectOption,
  });
  //END TABLE DATA

  return (
    <>
      <HeadSeo title={t("courses.courses", { defaultValue: "Courses" })} />
      <div className={"content-with-table"}>
        {!!forCopy && (
          <div className={"back-block tsl-4"}>
            <ButtonLink onClick={backToCourseCreateHandler}>
              <i
                style={{ fontSize: "20px", paddingRight: "8px" }}
                className={bizIcons.ARROWLEFT}
              />
              {t("courses.backtoCreateACourse", {
                defaultValue: "Back to Create a course",
              })}
            </ButtonLink>
          </div>
        )}
        <div className={"cwt_head"}>
          {!!forCopy ? (
            <Title level={2} className={"title"}>
              {t("courses.copyACourse", { defaultValue: "Copy a course" })}
            </Title>
          ) : (
            <Title className={"title"}>
              {t("courses.courses", { defaultValue: "Courses" })}
            </Title>
          )}
          <div className={"search"}>
            <Input
              size={"large"}
              placeholder={t("courses.search", {
                defaultValue: "Course name or code",
              })}
              prefix={<i className={"ri-search-line mr-8 fs-20 color-grey"} />}
              onBlur={searchHandler}
              onPressEnter={searchHandler}
              value={keywordState}
              onChange={(e) => setKeyword(e?.target?.value)}
            />
          </div>
          {tabKey === CourseTab.Current ? (
            <CurrentCoursesFilters
              onApply={onApplyFilters}
              userType={user?.isLinked}
            />
          ) : (
            <CoursesFilters
              onApply={onApplyFilters}
              userType={user?.isLinked}
            />
          )}
        </div>
        {!isBlocked && createCourseHandler && (
          <div style={{ marginTop: "16px", fontWeight: 500 }}>
            <ButtonLink
              onClick={() => {
                if (!isVerified) {
                  showConfirm?.();
                } else {
                  createCourseHandler?.();
                }
              }}
              // onClick={createCourseHandler}
            >
              <i
                className={bizIcons.ADDCIRCLE}
                style={{ marginRight: "8px", fontSize: "20px" }}
              />
              {t("courses.createACourse", {
                defaultValue: "Create a course",
              })}
            </ButtonLink>
          </div>
        )}

        <div className={"cwt_table tsl-4"}>
          <Tabs
            activeKey={tabKey}
            size={"small"}
            onTabClick={onTabClick}
            destroyInactiveTabPane={true}
          >
            <TabPane tab={t("bizBase.current")} key={CourseTab.Current}>
              <div className="tsl-4 bsl-4 flex-align-items">
                {isBroken && isVerified && (
                  <div className="flex ">
                    <div />
                    <div className="flex-align-items">
                      {HeadingSelectOption()}
                    </div>
                  </div>
                )}
                {BulkAction()}
              </div>
              <TableComponent
                emptyTableContent={{
                  text: t("courses.empty.list"),
                }}
                broken={isBroken}
                isLoading={isLoading}
                propsTableColumns={tableColumns}
                propsTableData={mapTableData()}
                //MOCK_DATA
                pagination={
                  mapTableData()?.length > 0
                    ? {
                        total: courseList?.totalCount || 0,
                        pageSize: courseList?.pageSize || 10,
                        page: courseList?.pageNumber || 1,
                        onChange: onPageChangeHandler,
                      }
                    : false
                }
              />
            </TabPane>
            <TabPane
              tab={t("bizBase.draft")}
              key={CourseTab.Draft}
              // className={`content-course-draft ${
              //   isVerified ? "true" : "false"
              // }`}
            >
              <div className="tsl-4 bsl-4 flex-align-items">
                {isBroken && isVerified && (
                  <div className="flex ">
                    <div />
                    <div className="flex-align-items">
                      {HeadingSelectOption()}
                    </div>
                  </div>
                )}
                {BulkAction()}
              </div>
              <TableComponent
                emptyTableContent={{
                  text: t("courses.empty.list"),
                }}
                broken={isBroken}
                isLoading={isLoading}
                propsTableColumns={tableColumns}
                propsTableData={mapTableData()}
                pagination={
                  mapTableData()?.length > 0
                    ? {
                        total: courseList?.totalCount || 0,
                        pageSize: courseList?.pageSize || 10,
                        page: courseList?.pageNumber || 1,
                        onChange: onPageChangeHandler,
                      }
                    : false
                }

                // rowSelection={rowSelection}
              />
            </TabPane>

            <TabPane tab={t("bizBase.past")} key={CourseTab.Past}>
              <div className="tsl-4 bsl-4 flex-align-items">
                {isBroken && isVerified && (
                  <div className="flex ">
                    <div />
                    <div className="flex-align-items">
                      {HeadingSelectOption()}
                    </div>
                  </div>
                )}
                {BulkAction()}
              </div>
              <TableComponent
                emptyTableContent={{
                  text: t("courses.empty.list"),
                }}
                broken={isBroken}
                isLoading={isLoading}
                propsTableColumns={tableColumns}
                propsTableData={mapTableData()}
                //MOCK_DATA
                pagination={
                  mapTableData()?.length > 0
                    ? {
                        total:
                          courseList?.totalCount ||
                          courseList?.data?.length ||
                          0,
                        pageSize: courseList?.pageSize || 10,
                        page: courseList?.pageNumber || 1,
                        onChange: onPageChangeHandler,
                      }
                    : false
                }
              />
            </TabPane>
          </Tabs>
        </div>
      </div>
      <Modal
        centered
        title=""
        visible={isConfirm}
        closable={false}
        onCancel={() => {
          setConfirm((prev) => !prev);
        }}
        footer={false}
        wrapClassName="courses-modal-confirm-verified"
      >
        <div className="bsl-4">
          {!isVerified && (
            <>
              <h3>{t("courses.verify_title_popup")}</h3>
              <span style={{ color: "rgba(34,34,34,.65)" }}>
                {t("courses.confirm_verify_2")}
              </span>
            </>
          )}
          {/* {isVerified && mapTableData()?.length === 0 && (
              <h3 style={{ textAlign: "center" }}>{t("courses.empty.list")}</h3>
            )} */}
        </div>
        <div className="flex-space-between">
          <ButtonWhite
            className="fs-16 tsl-4 flex-1 mr-25"
            onClick={() => {
              setConfirm((prev) => !prev);
            }}
            style={{
              width: "auto",
              minWidth: "auto",
              paddingLeft: 20,
              paddingRight: 20,
            }}
            type="primary"
          >
            <div className={"flex-center"}>{t("bizBase.cancel")}</div>
          </ButtonWhite>
          <Button
            className="fs-16 tsl-4 flex-1"
            type="primary"
            style={{ width: "auto", paddingLeft: 20, paddingRight: 20 }}
            htmlType="button"
            onClick={() => {
              setConfirm((prev) => !prev);
              Modal.destroyAll();
              // createCourseHandler?.();
              history.push(AppRoutes.courseCreate + "?action=MANUALLY");
            }}
            icon={
              <div className={"flex-center"}>
                <>{t("bizBase.ok")}</>
              </div>
            }
          />
        </div>
      </Modal>

      <Modal
        centered
        title=""
        visible={isUpload}
        closable={false}
        onCancel={() => {
          setUpload((prev) => !prev);
        }}
        footer={false}
        wrapClassName="courses-modal-upload-images"
      >
        <Form form={form} initialValues={{}}>
          <div className="bsl-4">
            {courseNames?.length > 0 ? (
              <h3>{t("bizBase.uploadImages")}</h3>
            ) : (
              <h3 className="text-center">
                {t("courses.cannotPublishWithErrors", {
                  defaultValue: "Cannot publish courses with errors",
                })}
              </h3>
            )}

            <Form.Item noStyle shouldUpdate>
              {() => {
                const missingURLCourseNames = courseNames.filter(
                  (item) => !item.url,
                );
                return missingURLCourseNames?.length > 0 ? (
                  missingURLCourseNames?.map((el, index) => (
                    <Form.Item
                      key={index}
                      help={
                        <div className="flex-space-between">
                          <span>
                            {t("courses.helpUploadImageCourse", {
                              size: 1,
                            })}
                          </span>
                        </div>
                      }
                      rules={[
                        {
                          required: true,
                          message: t("messages.required_field", {
                            defaultValue: "Please fill in all required fields",
                          }),
                        },
                      ]}
                      className={"bsl-4"}
                      name={"courseName_" + index}
                    >
                      <div className="label-wrapper">
                        <label
                          htmlFor={"courseName_" + index}
                          style={{ fontWeight: 700 }}
                        >
                          {el?.courseName}
                        </label>
                      </div>
                      <ImageUploader
                        name={t("courses.imageOfCourse", {
                          defaultValue: "Image of course",
                        })}
                        imageUrl={form.getFieldValue("courseName_" + index)}
                        onCheckBeforeUpload={onCheckBeforeUpload}
                        uploadButtonFunc={uploadButton}
                        onUploadChanged={(file: File) =>
                          onUploadChanged(file, index)
                        }
                      />
                    </Form.Item>
                  ))
                ) : (
                  <div />
                );
              }}
            </Form.Item>
          </div>
          <div className="flex-space-between">
            <ButtonWhite
              className="fs-16 tsl-4 flex-1 mr-25"
              onClick={() => {
                setUpload((prev) => !prev);
                // form.resetFields();
              }}
              style={{
                width: "auto",
                minWidth: "auto",
                paddingLeft: 20,
                paddingRight: 20,
              }}
              type="primary"
            >
              <div className={"flex-center"}>{t("bizBase.cancel")}</div>
            </ButtonWhite>
            {courseNames?.length > 0 ? (
              <>
                <Button
                  className="fs-16 tsl-4 flex-1"
                  type="primary"
                  style={{ width: "auto", paddingLeft: 20, paddingRight: 20 }}
                  onClick={onPublished}
                  icon={
                    <div className={"flex-center"}>
                      <>{t("bizBase.publish")}</>
                    </div>
                  }
                  loading={isLoading}
                  disabled={!courseNames?.length || isLoading}
                />
              </>
            ) : (
              <Button
                className="fs-16 tsl-4 flex-1"
                type="primary"
                style={{ width: "auto", paddingLeft: 20, paddingRight: 20 }}
                onClick={() => setUpload(false)}
                icon={
                  <div className={"flex-center"}>
                    <>{t("bizBase.ok")}</>
                  </div>
                }
              />
            )}
          </div>
        </Form>
      </Modal>
    </>
  );
});
