import React from "react";
import { formatPrice } from "scripts/utils/formatPrice";
import {
  bizIcons as tb,
  tableBookingStatus,
} from "../../../constants/bizConstants";
import helpers from "../../../scripts/helpers";
import { kidsAge } from "../constants";

export const tableColumns = (t: any) => {
  const colData = [
    {
      dataIndex: "courseName",
      key: "courseName",
    },
    {
      key: "totalAmount",
      dataIndex: "totalAmount",
      render: (text: any) =>
        text && (
          <div>
            <i
              className={tb.PRICE}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            {formatPrice(text)}
          </div>
        ),
    },
    {
      key: "age",
      dataIndex: "age",
      render: (text: any) =>
        text && (
          <div>
            <i
              className={tb.AGE}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            {text}
          </div>
        ),
    },
    {
      key: "categoryName",
      dataIndex: "categoryName",
      render: (text: any) =>
        text && (
          <div>
            <i
              className={tb.UL}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            {t("categories." + text)}
          </div>
        ),
    },
    {
      key: "locationName",
      dataIndex: "locationName",
      render: (text: any) =>
        text && (
          <div>
            <i
              className={tb.PIN}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            {text}
          </div>
        ),
    },
  ];

  return colData;
};
export const tableData = (data: any) => {
  return data.map((el: any) => {
    return {
      courseName: el.courseName,
      totalAmount: el.amountSpend,
      age: kidsAge[el.ageId],
      categoryName: el.categoryName,
      locationName: el.locationName,
      key: Math.random(),
    };
  });
};
