import { CheckCircleOutlined } from "@ant-design/icons";
import { Modal, notification, Popover, Progress, Spin, Steps } from "antd";
import { bizIcons } from "constants/bizConstants";
import { AppRoutes } from "helpers/app.routes";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, withRouter } from "react-router-dom";
import { useCurrentUser } from "scripts/custom-hooks/useCurrentUser";
import helpers from "scripts/helpers";
import userStore, {
  ProviderStatus,
  UserStore,
  userStoreContext,
} from "scripts/mobx/userStore";
import { LayoutForm } from "../../components/LayoutForm";
import "./index.less";
import Step2FormWrapper from "./Step2Form";
import Step3FormWrapper from "./Step3Form";
import Step4FormWrapper from "./Step4Form";
import moment from "moment-timezone";
import { Step5FormWrapper } from "./Step5Form";

interface IProps extends React.HTMLAttributes<HTMLElement> {
  userStore?: UserStore;
}

const SignUpWrapper = inject("userStore")(
  observer((props: IProps) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [step, setStep] = useState<number>(2);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isPopup, setPopup] = useState<boolean>(false);
    const currentUser = useCurrentUser() as any;
    const userStore = useContext(userStoreContext);

    React.useEffect(() => {
      const alreadyHasPaymentMethod = currentUser?.userAttributes
        ? Boolean(userStore.paymentMethods.length) ||
          moment(
            helpers.getServerDate(currentUser?.userAttributes?.createdDate),
          ).isSameOrBefore("01/08/2023") // to skip user created this before 01/08/2023
        : false;

      if (
        currentUser?.userAttributes &&
        currentUser?.businessUnitAttributes &&
        currentUser?.providerAttributes?.description &&
        alreadyHasPaymentMethod
      ) {
        if (currentUser?.immediateAction === "STRIPE_ONBOARDING") {
          setPopup(true);
          setTimeout(() => {
            userStore
              ?.getStripeAccountOnboard()
              .then((res) => {
                if (res?.data) {
                  window.location.href = res?.data;
                }
              })
              .catch((e: any) =>
                helpers?.throwErrors(t, [], e?.data?.error, e?.data?.message),
              );
          }, 2000);
        } else {
          history.push("/");
        }
      } else if (!currentUser?.userAttributes) {
        setStep(2);
      } else if (!currentUser?.businessUnitAttributes) {
        setStep(3);
      } else if (!currentUser?.providerAttributes?.description) {
        setStep(4);
      } else {
        setStep(5);
      }
    }, [currentUser, userStore.paymentMethods]);

    const nextStep = () => {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 200);

      if (step < 6) {
        setStep(step + 1);
      } else if (step === 6) {
        history.push("/");
      }
    };

    const customDot = (dot: number, { status, index }: any) => (
      <span>{dot}</span>
    );

    return (
      <LayoutForm {...props} disableLogoClick>
        {isLoading && <Spin />}
        {!isLoading && step === 2 && <Step2FormWrapper nextStep={nextStep} />}
        {!isLoading && step === 3 && <Step3FormWrapper nextStep={nextStep} />}
        {!isLoading && step === 4 && <Step4FormWrapper nextStep={nextStep} />}
        {!isLoading && step === 5 && <Step5FormWrapper nextStep={nextStep} />}

        <Modal
          centered
          title=""
          visible={isPopup}
          maskClosable={false}
          closable={false}
          footer={false}
          wrapClassName="courses-modal-redirect-stripe"
        >
          <div className="popup-stripe-wrap">
            <div className="popup-title text-center">
              <i
                className={bizIcons.CHECKBOXCIRCLE}
                style={{ fontSize: 70, marginBottom: 20 }}
              />
              <h3>{t("signUp.step4.popup.title")}</h3>
            </div>
            <div className="bsl-3 progress-line">
              <div className="flex-1">
                <Progress
                  percent={80}
                  showInfo={false}
                  strokeColor="#FFCC33"
                  trailColor="#e5e5e5"
                />
              </div>
              <span className="infor-line">
                {t("signUp.step4.popup.progress")}
              </span>
            </div>
            <div className="flex-space-between steps-line">
              <div className="step finish">
                <div className="dots">
                  <i className={bizIcons.CHECK} />
                </div>
                <span className="label">
                  {t("signUp.step4.popup.profileCreated")}{" "}
                </span>
              </div>
              <div className="step">
                <div className="dots" />
                <span className="label">
                  {t("signUp.step4.popup.setupToReceive")}{" "}
                </span>
              </div>
            </div>
          </div>
          <div className="bottom flex-space-between">
            <div />
            <span>{t("signUp.step4.popup.redirectingToStripe")}</span>
          </div>
        </Modal>
      </LayoutForm>
    );
  }),
);

export default withRouter(SignUpWrapper);
