import { DETAIL_API_URL } from "constants/apiUrl";
import { FORMAT_DATE } from "constants/commons";
import requestApi from "helpers/requestApi";
import { action, computed, makeObservable, observable, toJS } from "mobx";
import moment from "moment";
import { createContext } from "react";
import { uniq, sortBy, omit } from "lodash";
import helpers from "scripts/helpers";
import {
  CourseType,
  CourseStatus,
  CourseBodyParams,
  KidsAgeType,
} from "constants/type.interface";
import { getKidsAge } from "pages/courses/tableGenerator";
import { PAGE_SIZE } from "config";

export const pageable = {
  pageNumber: 1,
  pageSize: PAGE_SIZE,
  sortField: "createdAt",
  sortDirection: "desc",
};

type Pageable = {
  pageNumber: number;
  pageSize: number;
  state: string;
  totalCount: number;
};

type ICourse = {
  id: number;
  name: string;
  category: string;
  subCategory: string;
  ageFrom: number;
  ageTo: number;
  dateStart: string;
  dateEnd: string;
  status: string;
  legalName: string;
  acceptedBookingCount: number;
  pendingBookingCount: number;
  code: string;
  hasError: true;
  providerId: number;
  cancellationImpactCount: number;
  createdDate: string;
  imageUrl: string;
};

type CourseListResponse = Array<Pageable & { data: Array<ICourse> }>;

export class CourseStore {
  constructor() {
    makeObservable(this);
  }

  @observable
  public courseList: CourseListResponse = [];

  @observable
  public courseCreateDataVal: any = null;

  @observable
  public dayOfWeekObj: any = null;

  @observable
  public dayOfWeekArr: any[] = [];

  @observable
  public excludedDates: any[] = [];

  @observable
  public scheduleClass: any = null;

  @observable
  public categoryData: any[] = [];

  @observable
  public classDate: any = null;

  @observable
  public durationTime: number = 0;

  @observable
  public locationType: CourseType = CourseType.Offline;

  @observable
  public dataSelectedRowKeys: number[] = [];

  @observable
  public errorHandler: string = "";

  @observable
  public dataFilters: any = null;

  @observable
  public filterModel: any = {
    pageable,
    state: "Current",
  };
  @action
  public async setFilters(filter?: any) {
    this.dataFilters = filter;
  }

  @action
  public async getCourses(filter?: any) {
    if (filter) this.filterModel = filter;
    const params = {
      pageable,
      state: "Current",
    };

    const result = await requestApi.post(DETAIL_API_URL.COURSE_LIST, {
      ...params,
      ...filter,
    });
    if (result?.status === 200) {
      this.courseList = result?.data;
      return Promise.resolve(result?.data);
    } else {
      return Promise.reject();
    }
  }

  @action
  public async updateCourseState(data: any) {
    if (data) {
      this.courseCreateDataVal = {
        ...this.courseCreateDataVal,
        ...data,
      };
    } else {
      this.courseCreateDataVal = { ...this.courseCreateDataVal };
    }
  }

  @action
  public async updateCourseCreateData(data: any) {
    if (data) {
      this.courseCreateDataVal = {
        ...this.courseCreateDataVal,
        ...data,
      };
    } else {
      this.courseCreateDataVal = { ...this.courseCreateDataVal };
    }
  }

  public async uploadImage(params: any) {
    const result = await requestApi.post(DETAIL_API_URL.UPLOAD_IMAGE, params, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    if (result?.status === 200 || !!result?.data) {
      return Promise.resolve(result);
    } else {
      return Promise.reject();
    }
  }

  //CREATE COURSE
  @action
  public async createCourse(params: CourseBodyParams) {
    const result = await requestApi.post(DETAIL_API_URL.COURSE, params);
    if (result?.status === 200) {
      return Promise.resolve(result);
    } else {
      this.courseList = { ...this.courseList };
      return Promise.reject();
    }
  }

  //UPDATE COURSE
  @action
  public async updateCourse(id: number | string, params: CourseBodyParams) {
    const result = await requestApi.put(
      DETAIL_API_URL.COURSE_UPDATE(id),
      params,
    );
    if (result?.status === 200) {
      // TODO: remove this
      this.getCourses();
      this.getDetailCourse(id);
      return Promise.resolve(result);
    } else {
      this.courseList = { ...this.courseList };
      return Promise.reject();
    }
  }

  //DELETE/CANCEL COURSE
  @action
  public async cancelCourse(id: number, ids: any[]) {
    const result = await requestApi.post(
      DETAIL_API_URL.COURSE_CANCEL(id),
      ids,
      {
        headers: { "content-type": "application/json" },
      },
    );
    if (result?.status === 200) {
      this.getDetailCourse(id);
      return Promise.resolve(result?.data);
    } else {
      this.courseList = { ...this.courseList };
      return Promise.reject();
    }
  }

  @action
  public async cancelClass(courseId: number, classId: number, lang?: string) {
    const result = await requestApi.post(
      DETAIL_API_URL.COURSE_CLASS_CANCEL(classId, lang),
      [],
      {
        headers: { "content-type": "application/json" },
      },
    );
    if (result?.status === 200) {
      this.getDetailCourse(courseId);
      return Promise.resolve(result);
    } else {
      this.courseList = { ...this.courseList };
      return Promise.reject();
    }
  }

  //EXCEL
  @action
  public async xlsRecognition(params: any) {
    const result = await requestApi.post(
      DETAIL_API_URL.COURSE_UPLOAD_EXCEL,
      params,
      {
        headers: { "Content-Type": "multipart/form-data" },
      },
    );
    if (result?.status === 200) {
      return Promise.resolve(result?.data);
    } else {
      return Promise.reject();
    }
  }

  @action
  public async createByRecognitionData(data: any) {
    const result = await requestApi.put(
      DETAIL_API_URL.COURSE_UPLOAD_EXCEL,
      data,
    );
    if (result?.status === 200) {
      return Promise.resolve();
    } else {
      return Promise.reject();
    }
  }

  @action
  public async getDetailCourse(id: any) {
    const result = await requestApi.get(DETAIL_API_URL.COURSE_DETAIL(id));
    if (result?.status === 200) {
      const dataCourse = result?.data || {};
      const classes = dataCourse?.classes?.map((el: any) => {
        return {
          dayOfWeek: el.dayOfWeek,
          date: helpers.getServerDate(el.date),
          startTime: el.startTime?.slice(0, 5),
          endTime: el.endTime?.slice(0, 5),
        };
      });
      this.courseCreateDataVal = {
        ...dataCourse,
        step1: {
          ageGroup:
            getKidsAge(dataCourse?.minAgeMonth)?.age &&
            getKidsAge(dataCourse?.maxAgeMonth)?.age
              ? 1
              : undefined,
          code: dataCourse?.code,
          imageUrl: dataCourse?.image_url,
          kidsAgeFrom: getKidsAge(dataCourse?.minAgeMonth)?.age || undefined,
          kidsAgeFromType:
            getKidsAge(dataCourse?.minAgeMonth)?.type || KidsAgeType.Months,
          kidsAgeTo: getKidsAge(dataCourse?.maxAgeMonth)?.age || undefined,
          kidsAgeToType:
            getKidsAge(dataCourse?.maxAgeMonth)?.type || KidsAgeType.Months,
          name: dataCourse?.name,
          _category: dataCourse?.category,
          _location: dataCourse?.locations?.[0]?.id || undefined,
          _subCategory: dataCourse?.subCategory,
        },
        step2: {
          numberOfClasses: dataCourse?.numberOfClasses || undefined,
          startDate: dataCourse?.startDate
            ? moment(
                helpers?.getServerDate(dataCourse?.startDate),
                FORMAT_DATE.query,
              )
            : undefined,
        },
        step3: {
          briefDescription: dataCourse?.description ?? "",
          minClassesForSale: dataCourse?.minClassesSales || 1,
          availableClassHeadcount: dataCourse?.capacity || 1,
          totalPrice: dataCourse?.pricePerClass ?? "",
          externalSignups: dataCourse?.externalSignups ?? "",
          publishACourse:
            dataCourse?.status !== CourseStatus.Draft ? true : false,
        },
      };
      this.dayOfWeekArr = dataCourse?.weeklySchedules?.map((el: any) => {
        return {
          ...el,
          timeStart: el?.startTime?.slice(0, 5),
          timeEnd: el?.endTime?.slice(0, 5),
        };
      });
      this.excludedDates = [];
      this.scheduleClass = classes;
      return Promise.resolve(result);
    } else {
      this.courseCreateDataVal = { ...toJS(this.courseCreateDataVal) };
      return Promise.reject();
    }
  }

  @action
  public async updateDaysOfWeek(date: any, range: any) {
    //
    const newArr = this.dayOfWeekArr || null;
    if (range?.[0] && range?.[1]) {
      const findIndex = newArr.findIndex(
        (item: any) => item.dayOfWeek === date,
      );
      if (findIndex !== -1) {
        newArr[findIndex] = {
          dayOfWeek: date,
          timeStart: range[0],
          timeEnd: range[1],
        };
      } else {
        newArr.push({
          dayOfWeek: date,
          timeStart: range[0],
          timeEnd: range[1],
        });
      }
      this.dayOfWeekArr = newArr;
    } else {
      const arrs = newArr.filter((item: any) => item.dayOfWeek !== date);
      this.dayOfWeekArr = arrs;
    }
  }

  @action
  public async updateExcludedDates(data: any) {
    this.excludedDates = sortBy(data);
  }

  @action
  public async generateDatesFirst(data: any) {
    const result = await requestApi.put(DETAIL_API_URL.GET_SCHEDULE, data);
    const newArrays = result?.data?.map((el: any) => {
      return {
        ...el,
        date: helpers.getServerDate(el?.date),
      };
    });

    try {
      return Promise.resolve(newArrays);
    } catch {
      this.errorHandler = result?.data?.message;
      return Promise.reject();
    }
  }

  @action
  public async getHKHolidays(data: any) {
    const result = await requestApi.get(DETAIL_API_URL.GET_HK_HOLIDAYS, {
      params: {
        fromDate: moment().format(FORMAT_DATE.query),
        toDate: moment().add(1, "years").format(FORMAT_DATE.query),
        subdirectory: data?.subdirectory,
      },
    });

    try {
      const holidays = result?.data?.map((el: any) => {
        const newDate = new Date(
          moment(helpers.getServerDate(el), FORMAT_DATE.query).format(),
        );
        return newDate;
      });

      this.excludedDates = holidays;
      return Promise.resolve();
    } catch {
      return Promise.reject();
    }
  }

  @action
  public async updateHKHolidays(data: any) {
    this.excludedDates = data;
  }

  @action
  public async getDuration(duration: number) {
    this.durationTime = duration;
  }

  @action
  public async generateDates(data: any) {
    const result = await requestApi.put(DETAIL_API_URL.GET_SCHEDULE, data);

    const newArrays = result?.data?.map((el: any) => {
      return {
        ...el,
        date: el?.date ? helpers?.getServerDate(el?.date) : undefined,
      };
    });

    // const holidays = toJS(this.excludedDates)?.map((el: any) => {
    //   const newDate = new Date(
    //     moment(helpers.getServerDate(el), FORMAT_DATE.query).format(),
    //   );
    //   return newDate;
    // });
    // const newHolidays = helpers.getArrayDates([
    //   ...toJS(this.excludedDates),
    //   ...holidays,
    // ]);

    try {
      // this.updateHKHolidays(
      //   newHolidays?.filter(
      //     (day) =>
      //       moment(day)?.isAfter(
      //         moment(newArrays?.[0]?.date, FORMAT_DATE.query),
      //       ) &&
      //       moment(day)?.isBefore(
      //         moment(
      //           newArrays?.[newArrays?.length - 1]?.date,
      //           FORMAT_DATE.query,
      //         ),
      //       ),
      //   ),
      // );
      this.scheduleClass = newArrays;
      return Promise.resolve(newArrays);
    } catch {
      this.errorHandler = result?.data?.message;
      return Promise.reject();
    }
  }

  @action
  public async updateClassDates(data: any) {
    this.scheduleClass = data;
  }

  @action
  public async clearGenDates() {
    this.scheduleClass = [];
    this.classDate = null;
  }

  @action
  public async getCategories() {
    const result = await requestApi.get(DETAIL_API_URL.GET_CATEGORY_DICTIONARY);
    const categories = Object.keys(result?.data).map((key) => {
      return {
        id: key,
        name: key,
        subCategories: sortBy(
          result?.data?.[key]?.map((el: string) => {
            return {
              id: el,
              name: el,
            };
          }),
          ["id"],
        ),
      };
    });

    try {
      this.categoryData = sortBy(categories, ["id"]);
      return Promise.resolve(sortBy(categories, ["id"]));
    } catch {
      this.errorHandler = result?.data?.message;
      return Promise.reject();
    }
  }

  @action
  public searchCourses = async (filter: { keyword: string }) => {
    const params = {
      pageable,
    };

    const result = await requestApi.post(DETAIL_API_URL.COURSE_SEARCH, {
      ...params,
      ...filter,
    });
    if (result?.status === 200) {
      return Promise.resolve(result?.data || []);
    } else {
      return Promise.reject();
    }
  };

  @action
  public async updateClassDate(data: any) {
    this.classDate = data;
  }

  @action
  public async updateSelectRowKeys(data: number[]) {
    this.dataSelectedRowKeys = data;
  }

  //PUBLISH COURSE
  @action
  public async bulkPublishCourse(params: {
    ids: number[];
    courseImages: { url: string; courseName: string }[];
    allCourse: boolean;
  }) {
    const result = await requestApi.put(
      DETAIL_API_URL.COURSE_BULK_PUBLISH,
      params,
    );
    if (result?.status === 200) {
      return Promise.resolve(result);
    } else {
      return Promise.reject();
    }
  }

  @action
  public async getPreparePublish(params: {
    ids: number[];
    allCourse: boolean;
  }) {
    const result = await requestApi.get(
      DETAIL_API_URL.COURSE_PREPARE_PUBLIST,
      params,
    );
    if (result?.status === 200) {
      return Promise.resolve(result?.data);
    } else {
      return Promise.reject();
    }
  }

  //BULK DELETE COURSE
  @action
  public async bulkDeleteCourse(params: {
    ids: number[];
    allCourse: boolean;
    state: string;
  }) {
    const deleteParams = { ...this.filterModel, ...params };
    const result = await requestApi.put(
      DETAIL_API_URL.COURSE_BULK_DELETE,
      deleteParams,
    );
    if (result?.status === 200) {
      return Promise.resolve(result);
    } else {
      return Promise.reject();
    }
  }

  @action
  public async clearDateCreateCourse() {
    this.courseCreateDataVal = [];
    this.dayOfWeekArr = [];
    this.excludedDates = [];
    this.scheduleClass = [];
  }

  @computed
  public get getCourseList() {
    return this.courseList;
  }

  @computed
  public get getCourseState() {
    return this.courseCreateDataVal;
  }

  @computed
  public get getHandleError() {
    return this.errorHandler;
  }
}

const courseStore = new CourseStore();

export const courseStoreContext = createContext(courseStore);
export default courseStore;
