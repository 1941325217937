import keycloakData from "./keycloak.json";

const Keycloak = typeof window !== "undefined" ? require("keycloak-js") : null;

const keycloakConfig = {
    clientId: process.env.RAZZLE_KEYCLOAK_CLIENT_ID,
    realm: process.env.RAZZLE_KEYCLOAK_REALM,
    url: process.env.RAZZLE_KEYCLOAK_URL,
};

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
const keycloak =
    typeof window !== "undefined" ? new Keycloak(keycloakConfig) : null;

export default keycloak;