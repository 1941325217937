import React from "react";
import { Typography } from "antd";
import TableComponent from "../../../../components/TableComponent/TableComponent";
import { tableAgreeColumns, tableAgreeData } from "./tableGenerator";
const { Title } = Typography;

const PricingSchedule = () => {
  return (
    <>
      <Title>Pricing Schedule - ZH</Title>
      <div className={"bizibuz-policies-lists"}>
        {/* <div className={"bsl-3 tsl-3"}>
          <b>What is the cost of joining the Bizibuz platform?</b>
        </div> */}
        <div className={"bsl-3 tsl-3"}>
          There is no upfront cost to join the Bizibuz platform. Bizibuz
          provides a complete operational, marketing and strategy solution for
          your Kidzbiz and you only pay us when we bring you paying customers. -
          ZH
        </div>
        <div className={"bsl-3 tsl-3"}>
          <TableComponent
            propsTableColumns={tableAgreeColumns()}
            propsTableData={tableAgreeData()}
            pagination={false}
          />
          <div className={"tsl-3"}>
            * Gross transaction volume is defined as the aggregate gross
            customer spend. - ZH
            <div>
              ** Non profit organizations are entitled to the above rate on a
              permanent basis. - ZH
            </div>
            <br />
            <div>
              Bizibuz reserves the right to change pricing rates at any time
              without notice. 固定介紹費是預先收取的，不以客戶出席為條件。- ZH
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PricingSchedule;
