import { Divider, Popover, Spin } from "antd";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import { useLocation } from "react-router-dom";
import { parse } from "query-string";
import { useCurrentUser } from "scripts/custom-hooks/useCurrentUser";
import { DEVICES, useDevices } from "scripts/custom-hooks/useDevices";
import helpers from "scripts/helpers";
import providerOrdersStore from "scripts/mobx/providerOrdersStore";
import { formatPrice } from "scripts/utils/formatPrice";
import ButtonLink from "../../../components/ButtonLink";
import TableComponent from "../../../components/TableComponent/TableComponent";
import { bizIcons } from "../../../constants/bizConstants";
import "../index.less";
import {
  tablePayOrgViewColumns,
  tablePayOrgViewData,
  tablePayViewKidzbizCancellationFeesColumns,
  tablePayViewKidzbizCustomerColumns,
  tablePayViewKidzbizCustomerData,
} from "../tableGenerator";
import moment from "moment";

interface IProps {
  t: any;
  _data: any;
  providerId: number;
  code?: string;
}

const PayViewKidzbizComponent = observer((props: IProps) => {
  const { t, _data, providerId, code } = props;
  const user = useCurrentUser();
  const device = useDevices();
  const [isBroken, setIsBroken] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [dataXero, setDataXero] = useState<any[]>([]);
  const location = useLocation();

  const filterDate: string = useMemo(() => {
    try {
      return (parse(location.search)?.date || "") as string;
    } catch (error) {
      return "";
    }
  }, [location.search]);

  useEffect(() => {
    if (
      device &&
      (device === DEVICES.MOBILE ||
        device === DEVICES.TABLET_PORT ||
        device === DEVICES.TABLET_LAND) &&
      !isBroken
    ) {
      setIsBroken(true);
    } else if (
      device &&
      device !== DEVICES.MOBILE &&
      device !== DEVICES.TABLET_PORT &&
      device !== DEVICES.TABLET_LAND &&
      isBroken
    ) {
      setIsBroken(false);
    }
  }, [device]);

  React.useEffect(() => {
    providerOrdersStore
      ?.exportPayoutXero(providerId, code || "")
      .then((data) => {
        const dataCSV = data?.map((el: any) => {
          return {
            [t("exports.ContactName")]: el?.ContactName,
            [t("exports.EmailAddress")]: el?.EmailAddress,
            [t("exports.POAddressLine1")]: el?.POAddressLine1,
            [t("exports.POAddressLine2")]: el?.POAddressLine2,
            [t("exports.POAddressLine3")]: el?.POAddressLine3,
            [t("exports.POAddressLine4")]: el?.POAddressLine4,
            [t("exports.POCity")]: el?.POCity,
            [t("exports.PORegion")]: el?.PORegion,
            [t("exports.POPostalCode")]: el?.POPostalCode,
            [t("exports.POCountry")]: el?.POCountry,
            [t("exports.InvoiceNumber")]: el?.InvoiceNumber,
            [t("exports.Reference")]: el?.Reference,
            [t("exports.InvoiceDate")]: helpers.formatDate(
              helpers.getServerDate(el?.InvoiceDate),
            ),
            [t("exports.DueDate")]: helpers.formatDate(
              helpers.getServerDate(el?.DueDate),
            ),
            [t("exports.Total")]: el?.Total
              ? helpers.formatNumber(Math.trunc(el?.Total * 10) / 10)
              : null,
            [t("exports.InventoryItemCode")]: el?.InventoryItemCode,
            [t("exports.Description")]: el?.Description,
            [t("exports.Quantity")]: el?.Quantity,
            [t("exports.UnitAmount")]: el?.UnitAmount
              ? helpers.formatNumber(Math.trunc(el?.UnitAmount * 10) / 10)
              : null,
            [t("exports.Discount")]: el?.Discount
              ? helpers.formatNumber(Math.trunc(el?.Discount * 10) / 10)
              : null,
            [t("exports.AccountCode")]: el?.AccountCode,
            [t("exports.TaxType")]: el?.TaxType,
            [t("exports.TaxAmount")]: el?.TaxAmount
              ? helpers.formatNumber(Math.trunc(el?.TaxAmount * 10) / 10)
              : null,
            [t("exports.TrackingName1")]: el?.TrackingName1,
            [t("exports.TrackingOption1")]: el?.TrackingOption1,
            [t("exports.TrackingName2")]: el?.TrackingName2,
            [t("exports.TrackingOption2")]: el?.TrackingOption2,
            [t("exports.Currency")]: el?.Currency,
            [t("exports.BrandingTheme")]: el?.BrandingTheme,
          };
        });

        setDataXero(dataCSV);
      })
      .catch((error) => {
        helpers.throwErrors(
          t,
          [],
          error?.data?.error,
          error?.data?.message || "",
        );
      });
  }, []);

  const exportPayout = (type: "GENERIC" | "XERO") => {
    setLoading(true);
    providerOrdersStore
      ?.exportPayoutGeneric(providerId, code || "")
      .then((data) => {
        setLoading(false);
        const dataCSV = data?.map((el: any) => {
          return {
            [t("exports.business_date")]: helpers.formatDate(
              helpers.getServerDate(el?.businessDate),
            ),
            [t("exports.start_time")]: el?.startTime,
            [t("exports.end_time")]: el?.endTime,
            [t("exports.course_name")]: el?.courseName,
            [t("exports.location_name")]: el?.locationName,
            [t("exports.customer_name")]: el?.customerName,
            [t("exports.customer_email")]: el?.customerEmail,
            [t("exports.child_name_title")]: el?.childName,
            [t("exports.original_price")]: el?.originalPrice
              ? formatPrice(el?.originalPrice)
              : null,
            [t("exports.course_promotion_discount")]:
              el?.coursePromotionDiscount
                ? formatPrice(el?.coursePromotionDiscount)
                : null,
            [t("exports.bulk_promotion_discount")]: el?.bulkPromotionDiscount
              ? formatPrice(el?.bulkPromotionDiscount)
              : null,
            [t("exports.bizibuzFee")]: el?.bizibuzFee
              ? formatPrice(el?.bizibuzFee)
              : null,
            [t("payments.payout")]: el?.payout ? formatPrice(el?.payout) : null,
          };
        });
        helpers.exportToCSV(dataCSV, "Payout_Export");
      })
      .catch((error) => {
        helpers.throwErrors(
          t,
          [],
          error?.data?.error,
          error?.data?.message || "",
        );
        setLoading(false);
      });
  };

  const getPopoverContent = () => {
    return (
      <div style={{ width: "184px" }}>
        <div
          key={Math.random()}
          onClick={() => {
            exportPayout("GENERIC");
          }}
          className={"popover-button"}
          style={{
            color: "rgba(34, 34, 34, 0.65)",
            cursor: "pointer",
          }}
        >
          {t("payments.GENERIC")}
        </div>

        <CSVLink
          data={dataXero}
          className={"popover-button"}
          style={{
            color: "rgba(34, 34, 34, 0.65)",
            cursor: "pointer",
          }}
          filename="Payout_Export.csv"
        >
          {t("payments.XERO")}
        </CSVLink>
      </div>
    );
  };

  const filteredCancellationFeeByMonth = (
    _data?.courseCancellationFeeList || []
  ).filter((item: any) => {
    if (filterDate)
      return !moment(item.cancellationDate).isBefore(moment(filterDate));
    return true;
  });

  return (
    <>
      <div style={{ paddingTop: "13px", marginTop: "16px", fontWeight: 500 }}>
        <Popover
          placement="bottomLeft"
          content={getPopoverContent}
          trigger="click"
        >
          <ButtonLink className="mr-25">
            <i
              className={bizIcons.FILEDOWNLOAD}
              style={{ marginRight: "8px", fontSize: "20px" }}
            />
            {t("payments.export_details")}
          </ButtonLink>
        </Popover>
      </div>
      <Spin spinning={isLoading} size="large">
        <div className="flex-align-items flex-wrap tsl-4">
          {_data?.stripeExpressAccountFee > 0 && (
            <div className="bls-4 mr-25">
              <div className="td-title flex align-center">
                <i
                  className={bizIcons.PRICE}
                  style={{ fontSize: "16px", marginRight: "8px" }}
                />
                {t("payments.stripe_express_account_monthly_fee")}
              </div>
              <span className="text-red">
                {formatPrice(_data?.stripeExpressAccountFee, {
                  isReduce: true,
                })}
              </span>
            </div>
          )}

          {_data?.stripeTransactionFee > 0 && (
            <div className="bls-4">
              <div className="td-title flex align-center">
                <i
                  className={bizIcons.PRICE}
                  style={{ fontSize: "16px", marginRight: "8px" }}
                />
                {t("payments.stripe_payout_transaction_fee")}
              </div>
              <span className="text-red">
                {formatPrice(_data?.stripeTransactionFee, { isReduce: true })}
              </span>
            </div>
          )}
        </div>
        <Divider />
        {_data?.payoutItemViews?.map((el: any, index: number) => {
          return (
            <div key={index} className="tsl-4">
              <TableComponent
                broken={true}
                grid={true}
                pagination={false}
                propsTableColumns={tablePayOrgViewColumns(t, user?.isLinked)}
                propsTableData={tablePayOrgViewData([el])}
              />
              <TableComponent
                broken={isBroken}
                grid={true}
                pagination={false}
                propsTableColumns={tablePayViewKidzbizCustomerColumns(
                  t,
                  isBroken,
                  true,
                )}
                propsTableData={tablePayViewKidzbizCustomerData(
                  el?.childPayoutItems,
                )}
                className="biz-org-customers"
              />
            </div>
          );
        })}
        {filteredCancellationFeeByMonth.length && (
          <div className="tsl-3">
            <h3>{t("payments.cancellation_fees")}</h3>
            <TableComponent
              broken={isBroken}
              grid={true}
              pagination={false}
              propsTableColumns={tablePayViewKidzbizCancellationFeesColumns(
                t,
                isBroken,
              )}
              propsTableData={filteredCancellationFeeByMonth}
              className="biz-org-customers"
            />
          </div>
        )}
      </Spin>
    </>
  );
});

export default PayViewKidzbizComponent;
