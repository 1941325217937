import { Input, Modal, notification, Popover, Tabs, Typography } from "antd";
import { HeadSeo } from "components/HeadSeo";
import { bizIcons } from "constants/bizConstants";
import { FORMAT_DATE } from "constants/commons";
import QueryString from "constants/query-string";
import { forEach, omit } from "lodash";
import { toJS } from "mobx";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import {
  useCurrentUser,
  userBlocked,
  useType,
} from "scripts/custom-hooks/useCurrentUser";
import { useQueryParams } from "scripts/custom-hooks/useQueryParams";
import helpers from "scripts/helpers";
import {
  BookingsStore,
  BookingStatus,
  pageable,
} from "scripts/mobx/bookingsStore";
import { UserType } from "scripts/mobx/userStore";
import { formatPrice } from "scripts/utils/formatPrice";
import ButtonLink from "../../components/ButtonLink";
import { LayoutPage } from "../../components/Layout";
import TableComponent from "../../components/TableComponent/TableComponent";
import { BookingsFilters } from "./filters";
import "./index.less";
import { tableBookingsColumns, tableBookingsData } from "./tableGenerator";
import { AppRoutes } from "helpers/app.routes";
import { createStatus } from "pages/courses/CreateCoursesWrapper";

const { TabPane } = Tabs;
const { Title } = Typography;
interface IProps {
  bookingsStore?: BookingsStore;
}
const Bookings = inject("bookingsStore")(
  observer((props: IProps) => {
    const { bookingsStore } = props;
    const { t } = useTranslation("base");
    const user = useCurrentUser();
    const userType = useType();
    const isBlocked = userBlocked();
    const history = useHistory();
    const location = useLocation();
    const queryParams = useQueryParams();
    const queryTab = queryParams.get("tab") || BookingStatus.Pending;

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [searchVal, setSearchVal] = useState<string | undefined>(undefined);
    const [currentTab, setCurrentTab] = useState<string>(queryTab);
    const [bookingList, setBookingList] = useState<any>();
    const bookingDatas = toJS(bookingsStore?.getBookingDatas) || [];
    const filterModel = toJS(bookingsStore?.filterInfo);

    const getBookings = async (filter?: any) => {
      setIsLoading(true);
      bookingsStore
        ?.getMyBookings(filter)
        .then((res) => {
          setIsLoading(false);
          setBookingList(
            res?.filter((el: any) => el.status === filter?.status)?.[0],
          );
        })
        .catch(() => {
          setIsLoading(false);
        });
    };

    const tabQueryParam = queryParams.get("tab") || "PENDING";
    const keywordQueryParam = queryParams.get("keyword") || "";

    useEffect(() => {
      const loadBooking = async () => {
        setCurrentTab(tabQueryParam);
        setSearchVal(keywordQueryParam);

        getBookings({
          pageable,
          status: tabQueryParam,
          keyword: keywordQueryParam,
        });
      };

      loadBooking();
    }, [tabQueryParam, keywordQueryParam]);

    const updateFilter = (data?: any) => {
      let params = {
        ...filterModel,
        pageable: {
          ...filterModel?.pageable,
          pageNumber: data?.page || 0,
        },
      };
      if (data?.providerId) {
        Object.assign(params, {
          providerId: data?.providerId,
        });
      } else {
        params = omit(params, ["providerId"]);
      }
      if (data?.dateFrom) {
        Object.assign(params, {
          dateFrom: moment(data?.dateFrom)?.format(FORMAT_DATE.query),
        });
      } else {
        params = omit(params, ["dateFrom"]);
      }
      if (data?.dateTo) {
        Object.assign(params, {
          dateTo: moment(data?.dateTo)?.format(FORMAT_DATE.query),
        });
      } else {
        params = omit(params, ["dateTo"]);
      }
      getBookings(params);
    };

    const exportFile = () => {
      bookingsStore?.exportBooking(filterModel).then((res) => {
        const dataCSV = res?.map((el: any) => {
          const params = {};
          forEach(el?.childNames, (c, index) => {
            const i = index + 1;
            Object.assign(params, {
              [t("exports.child_name", {
                index: index + 1,
              })]: c,
            });
          });
          return {
            [t("exports.booking_request_timestamp")]: el?.bookingTime,
            [t("exports.course_name")]: el?.courseName,
            [t("exports.course_code")]: el?.courseCode,
            [t("exports.course_location")]: el?.courseLocation,
            [t("exports.customer_name")]: el?.customerName,
            [t("exports.no_classes_per_child")]: el?.noClasses,
            [t("exports.no_children_enrolled")]: el?.noChildren,
            [t("exports.total_price")]: formatPrice(el?.totalPrice),
            ...params,
          };
        });
        helpers.exportToCSV(dataCSV, "Bookings_Export");
      });
    };

    const searchHandler = async (event: any) => {
      const value = event?.target?.value;
      setSearchVal(value);

      history.replace({
        pathname: location?.pathname,
        search: QueryString.stringify({
          tab: currentTab,
          keyword: value ?? "",
        }),
      });
    };

    const { confirm } = Modal;

    const onSuccess = (status: BookingStatus) => {
      getBookings(filterModel);
      setTimeout(() => {
        setIsLoading(false);
        notification.success({
          message: t(`bookings.status_request.${status}_success`),
        });
      }, 300);
    };

    function showConfirm(status: BookingStatus, id: number) {
      confirm({
        title: t(`bookings.status_request.${status}_title`),
        content: t(`bookings.status_request.${status}_message`),
        autoFocusButton: null,
        icon: "",
        okText: t(`bookings.status_request.${status}_button`),
        cancelText: t("bizBase.cancel"),
        async onOk() {
          // console.log("OK");

          const requestFunc =
            status === BookingStatus.Accepted
              ? bookingsStore?.acceptBooking
              : bookingsStore?.rejectBooking;

          try {
            setIsLoading(true);
            await requestFunc?.(id);
            onSuccess(status);
          } catch (err: any) {
            if (
              [
                "com.bizibuz.common.exception.ProviderPaymentMethodException",
              ].includes(err?.data?.error)
            ) {
              notification.error({
                message: (
                  <div>
                    In order to accept bookings for free trial classes, you must
                    enter a valid payment method{" "}
                    <ButtonLink
                      onClick={() => {
                        history.push({
                          pathname: AppRoutes.courseCreate,
                          search: QueryString.stringify({
                            action: createStatus.ADD_PAYMENT_METHOD,
                          }),
                        });
                      }}
                    >
                      <span style={{ fontSize: 16 }}>here.</span>
                    </ButtonLink>
                  </div>
                ),
                duration: 10,
              });
            } else {
              helpers.throwErrors(t, [], err?.data?.error, "");
            }
          } finally {
            setIsLoading(false);
          }
        },
      });
    }
    function getPopoverContent(id: number) {
      return (
        <div style={{ width: "184px" }}>
          {[BookingStatus.Accepted, BookingStatus.Rejected].map((el) => {
            return (
              <div
                key={Math.random()}
                onClick={() => {
                  showConfirm(el, id);
                }}
                className={"popover-button"}
                style={{
                  color: "rgba(34, 34, 34, 0.65)",
                  cursor: "pointer",
                }}
              >
                {t("bookings." + el?.toLowerCase())}
              </div>
            );
          })}
        </div>
      );
    }

    const onPageChangeHandler = (page: any) => {
      getBookings({
        ...filterModel,
        pageable: {
          ...filterModel?.pageable,
          pageNumber: page - 1,
        },
      });
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    const pendingButtonFunc = (data: any) =>
      user?.providerAttributes?.legalName === data?.legalName && (
        // <Popover
        //   placement="bottomRight"
        //   content={getPopoverContent(data.id)}
        //   trigger="click"
        // >
        <ButtonLink
          className={"block-action"}
          onClick={() => showConfirm(BookingStatus.Accepted, data.id)}
        >
          {t("bookings.accepted")}
          {/* <i className="ri-arrow-drop-down-line" style={{ fontSize: "16px" }} /> */}
        </ButtonLink>
        // </Popover>
      );

    return (
      <>
        <HeadSeo title={t("menu.bookings")} />
        <LayoutPage defMenuItem={"bookings"} loading={isLoading}>
          <div className="single-course">
            <Title className={"bsl-4"} level={1}>
              {t("menu.bookings")}
            </Title>
            <div className="biz-tabs-content bookings">
              <div>
                <Tabs
                  activeKey={currentTab}
                  destroyInactiveTabPane={true}
                  size={"small"}
                  onTabClick={(status: string) => {
                    setSearchVal(undefined);
                    setCurrentTab(status);
                    history.replace({
                      pathname: location?.pathname,
                      search: QueryString.stringify({
                        tab: status,
                      }),
                    });
                  }}
                >
                  <TabPane
                    tab={t("bookings.Pending")}
                    key={BookingStatus.Pending}
                  >
                    <div className={"tab-table-block"}>
                      <div className={"tab-table-block-head no-title bsl-4"}>
                        <div className={"flex-1 search"}>
                          <Input
                            size={"large"}
                            placeholder={t("bookings.placehoder_search")}
                            prefix={
                              <i
                                className={
                                  "ri-search-line mr-8 fs-20 color-grey"
                                }
                              />
                            }
                            onChange={(e) => {
                              setSearchVal(e?.target?.value);
                            }}
                            onBlur={searchHandler}
                            onPressEnter={searchHandler}
                            value={searchVal}
                          />
                        </div>

                        <BookingsFilters
                          userType={user?.isLinked}
                          onApply={updateFilter}
                        />
                      </div>
                      <TableComponent
                        style={{ opacity: `${isLoading ? "0.4" : 1}` }}
                        broken={true}
                        pagination={{
                          total: bookingList?.totalCount,
                          pageSize: bookingList?.pageSize,
                          page: bookingList?.pageNumber + 1,
                          onChange: onPageChangeHandler,
                        }}
                        propsTableColumns={tableBookingsColumns(
                          t,
                          pendingButtonFunc,
                          user?.isLinked,
                        )}
                        propsTableData={tableBookingsData(bookingList?.data)}
                        emptyTableContent={{
                          text: t("bookings.empty_data"),
                        }}
                      />
                    </div>
                  </TabPane>
                  <TabPane
                    tab={t("bookings.Accepted")}
                    key={BookingStatus.Accepted}
                  >
                    <div className={"tab-table-block"}>
                      <div className={"tab-table-block-head no-title bsl-4"}>
                        <div className={"flex-1 search"}>
                          <Input
                            size={"large"}
                            placeholder={t("bookings.placehoder_search")}
                            prefix={
                              <i
                                className={
                                  "ri-search-line mr-8 fs-20 color-grey"
                                }
                              />
                            }
                            onChange={(e) => {
                              setSearchVal(e?.target?.value);
                            }}
                            onBlur={searchHandler}
                            onPressEnter={searchHandler}
                            value={searchVal}
                          />
                        </div>

                        <BookingsFilters
                          userType={user?.isLinked}
                          onApply={updateFilter}
                        />
                      </div>
                      {userType === UserType.MASTER && (
                        <ButtonLink onClick={() => exportFile()}>
                          <i
                            className={bizIcons.FILEDOWNLOAD}
                            style={{ fontSize: "20px", marginRight: 8 }}
                          />
                          {t("bookings.exportThisList")}
                        </ButtonLink>
                      )}

                      <TableComponent
                        style={{ opacity: `${isLoading ? "0.4" : 1}` }}
                        broken={true}
                        pagination={{
                          total: bookingList?.totalCount,
                          pageSize: bookingList?.pageSize,
                          page: bookingList?.pageNumber + 1,
                          onChange: onPageChangeHandler,
                        }}
                        propsTableColumns={tableBookingsColumns(
                          t,
                          undefined,
                          user?.isLinked,
                        )}
                        propsTableData={tableBookingsData(bookingList?.data)}
                        emptyTableContent={{
                          text: t("bookings.empty_data"),
                        }}
                      />
                    </div>
                  </TabPane>
                  <TabPane
                    tab={t("bookings.Rejected")}
                    key={BookingStatus.Rejected}
                  >
                    <div className={"tab-table-block"}>
                      <div className={"tab-table-block-head no-title bsl-4"}>
                        <div className={"flex-1 search"}>
                          <Input
                            size={"large"}
                            placeholder={t("bookings.placehoder_search")}
                            prefix={
                              <i
                                className={
                                  "ri-search-line mr-8 fs-20 color-grey"
                                }
                              />
                            }
                            onChange={(e) => {
                              setSearchVal(e?.target?.value);
                            }}
                            onBlur={searchHandler}
                            onPressEnter={searchHandler}
                            value={searchVal}
                          />
                        </div>

                        <BookingsFilters
                          userType={user?.isLinked}
                          onApply={updateFilter}
                        />
                      </div>

                      <TableComponent
                        style={{ opacity: `${isLoading ? "0.4" : 1}` }}
                        broken={true}
                        pagination={{
                          total: bookingList?.totalCount,
                          pageSize: bookingList?.pageSize,
                          page: bookingList?.pageNumber + 1,
                          onChange: onPageChangeHandler,
                        }}
                        propsTableColumns={tableBookingsColumns(
                          t,
                          undefined,
                          user?.isLinked,
                        )}
                        propsTableData={tableBookingsData(bookingList?.data)}
                        emptyTableContent={{
                          text: t("bookings.empty_data"),
                        }}
                      />
                    </div>
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </div>
        </LayoutPage>
      </>
    );
  }),
);

export default Bookings;
