export const ReportCustomersAndRevenueSummary = [
  {
    year: 2021,
    month: 12,
    organizationRevenue: 2000,
    organizarionRecurringRevenue: 3000,
    numberOfCustomers: 1500,
    numberOfRecurringCustomers: 500,
  },
  {
    year: 2020,
    month: 10,
    organizationRevenue: 1600,
    organizarionRecurringRevenue: 35400,
    numberOfCustomers: 1550,
    numberOfRecurringCustomers: 1500,
  },
];
export const ReportCustomerSpendByKidAgeChart = [
  {
    item: "Legal name",
    count: 10,
    percent: 20,
  },
  {
    item: "Legal name 2",
    count: 33,
    percent: 50,
  },
  {
    item: "Legal name 3",
    count: 4,
    percent: 13,
  },
];
export const ReportCustomerSpendByCategoryChart = [
  {
    total: 2000,
    percent: 15,
    category: "ARTS_CRAFTS",
  },
  {
    total: 200,
    percent: 22,
    category: "DEVELOPMENTAL",
  },
  {
    total: 340,
    percent: 23,
    category: "EXAM_PREPARATION",
  },
  {
    total: 340,
    percent: 20,
    category: "LANGUAGES",
  },
  {
    total: 340,
    percent: 20,
    category: "PERFORMING_ARTS",
  },
  {
    total: 340,
    percent: 13,
    category: "SPORT",
  },
  {
    total: 340,
    percent: 15,
    category: "STEM",
  },
];
export const ReportCustomerCharacteristicsChart = {
  averageNumberOfCoursesPurchasedPerMonth: [
    {
      item: "1",
      key: "1",
      axisValue: "1",
      value: 12,
      year: 2021,
      month: 11,
      percent: 22,
    },
    {
      item: "2",
      key: "2",
      axisValue: "2",
      value: 10,
      year: 2021,
      month: 10,
      percent: 15,
    },
    {
      item: "3",
      key: "3",
      axisValue: "3",
      value: 12,
      year: 2021,
      month: 9,
      percent: 13,
    },
  ],
  averageTenureAndAverageSpendByMonth: [
    {
      item: "1",
      key: "1",
      axisValue: "1",
      value: 15,
      year: 2021,
      month: 11,
      percent: 25,
    },
    {
      item: "2",
      key: "2",
      axisValue: "2",
      value: 10,
      year: 2021,
      month: 10,
      percent: 18,
    },
    {
      item: "3",
      key: "3",
      axisValue: "3",
      value: 10,
      year: 2021,
      month: 8,
      percent: 10,
    },
  ],
};
export const ReportCustomerSpendByChildAge = [
  {
    item: "1",
    key: "1",
    axisValue: "1",
    value: 15,
    year: 2021,
    month: 8,
    percent: 25,
  },
  {
    item: "2",
    key: "2",
    axisValue: "2",
    value: 10,
    year: 2021,
    month: 9,
    percent: 18,
  },
  {
    item: "3",
    key: "3",
    axisValue: "3",
    value: 22,
    year: 2021,
    month: 10,
    percent: 10,
  },
];
export const ReportCustomerSpendByCourseCategory = [
  {
    year: 2021,
    month: 8,
    total: 2000,
    percent: 15,
    category: "SPORT",
  },
  {
    year: 2021,
    month: 9,
    total: 1000,
    percent: 15,
    category: "STEM",
  },
  {
    year: 2021,
    month: 10,
    total: 200,
    percent: 15,
    category: "STEM",
  },
];
export const ReportOfflineOnlineSpendSplitByChildAge = [
  {
    item: "1",
    key: "1",
    axisValue: "1",
    value: 15,
    year: 2021,
    month: 8,
    percent: 25,
  },
  {
    item: "2",
    key: "2",
    axisValue: "2",
    value: 10,
    year: 2021,
    month: 9,
    percent: 18,
  },
  {
    item: "3",
    key: "3",
    axisValue: "3",
    value: 22,
    year: 2021,
    month: 10,
    percent: 10,
  },
];
export const ReportOfflineOnlineSpendSplitByCourseCategory = [
  {
    item: "ARTS_CRAFTS",
    key: "ARTS_CRAFTS",
    axisValue: "ARTS_CRAFTS",
    value: 23,
    year: 2021,
    month: 8,
    percent: 25,
  },
  {
    item: "DEVELOPMENTAL",
    key: "DEVELOPMENTAL",
    axisValue: "DEVELOPMENTAL",
    value: 10,
    year: 2021,
    month: 9,
    percent: 18,
  },
  {
    item: "EXAM_PREPARATION",
    key: "EXAM_PREPARATION",
    axisValue: "EXAM_PREPARATION",
    value: 16,
    year: 2021,
    month: 10,
    percent: 10,
  },
  {
    item: "LANGUAGES",
    key: "LANGUAGES",
    axisValue: "LANGUAGES",
    value: 16,
    year: 2021,
    month: 10,
    percent: 10,
  },
  {
    item: "PERFORMING_ARTS",
    key: "PERFORMING_ARTS",
    axisValue: "PERFORMING_ARTS",
    value: 16,
    year: 2021,
    month: 10,
    percent: 10,
  },
  {
    item: "SPORT",
    key: "SPORT",
    axisValue: "SPORT",
    value: 16,
    year: 2021,
    month: 10,
    percent: 10,
  },
  {
    item: "STEM",
    key: "STEM",
    axisValue: "STEM",
    value: 16,
    year: 2021,
    month: 10,
    percent: 10,
  },
];

export const ReportCustomersAndRevenue = [
  {
    year: 2021,
    month: 6,
    organizationRevenue: 4000,
    organizarionRecurringRevenue: 1000,
    numberOfCustomers: 55,
    numberOfRecurringCustomers: 0,
  },
  {
    year: 2021,
    month: 7,
    organizationRevenue: 7000,
    organizarionRecurringRevenue: 1000,
    numberOfCustomers: 67,
    numberOfRecurringCustomers: 0,
  },
  {
    year: 2021,
    month: 8,
    organizationRevenue: 10000,
    organizarionRecurringRevenue: 1000,
    numberOfCustomers: 87,
    numberOfRecurringCustomers: 0,
  },
  {
    year: 2021,
    month: 9,
    organizationRevenue: 15000,
    organizarionRecurringRevenue: 200,
    numberOfCustomers: 100,
    numberOfRecurringCustomers: 2,
  },
  {
    year: 2021,
    month: 10,
    organizationRevenue: 22000,
    organizarionRecurringRevenue: 18000,
    numberOfCustomers: 200,
    numberOfRecurringCustomers: 20,
  },
];
export const ReportRecurringCustomersAndRevenue = [
  {
    year: 2021,
    month: 6,
    organizationRevenue: 4000,
    organizarionRecurringRevenue: 1200,
    numberOfCustomers: 55,
    numberOfRecurringCustomers: 30,
  },
  {
    year: 2021,
    month: 7,
    organizationRevenue: 7000,
    organizarionRecurringRevenue: 500,
    numberOfCustomers: 67,
    numberOfRecurringCustomers: 2,
  },
  {
    year: 2021,
    month: 8,
    organizationRevenue: 10000,
    organizarionRecurringRevenue: 2000,
    numberOfCustomers: 87,
    numberOfRecurringCustomers: 7,
  },
  {
    year: 2021,
    month: 9,
    organizationRevenue: 15000,
    organizarionRecurringRevenue: 200,
    numberOfCustomers: 99,
    numberOfRecurringCustomers: 2,
  },
  {
    year: 2021,
    month: 10,
    organizationRevenue: 22000,
    organizarionRecurringRevenue: 18000,
    numberOfCustomers: 199,
    numberOfRecurringCustomers: 4,
  },
];
export const Top3Spend = [
  {
    amountSpend: 2000,
    courseId: 1,
    courseName: "Flutter",
    ageId: 10,
    categoryId: 22,
    categoryName: "STEM",
  },
  {
    amountSpend: 4000,
    courseId: 2,
    courseName: "Flutter 3",
    ageId: 10,
    categoryId: 22,
    categoryName: "DEVELOPMENTAL",
  },
  {
    amountSpend: 5000,
    courseId: 3,
    courseName: "Spainsh",
    ageId: 10,
    categoryId: 22,
    categoryName: "LANGUAGES",
  },
];
export const Bot3Spend = [
  {
    amountSpend: 1000,
    courseId: 1,
    courseName: "Flutter",
    ageId: 10,
    categoryId: 22,
    categoryName: "STEM",
  },
  {
    amountSpend: 1000,
    courseId: 2,
    courseName: "Flutter 3",
    ageId: 10,
    categoryId: 22,
    categoryName: "DEVELOPMENTAL",
  },
  {
    amountSpend: 500,
    courseId: 3,
    courseName: "Spainsh",
    ageId: 10,
    categoryId: 22,
    categoryName: "LANGUAGES",
  },
];

export const Top3ByCustomers = [
  {
    amountOfCustomers: 500,
    courseId: 3,
    courseName: "Piano beginner classes",
    ageId: 10,
    categoryId: 22,
    categoryName: "ARTS_CRAFTS",
  },
  {
    amountOfCustomers: 1000,
    courseId: 1,
    courseName: "Piano teaching",
    ageId: 10,
    categoryId: 22,
    categoryName: "STEM",
  },
  {
    amountOfCustomers: 1000,
    courseId: 2,
    courseName: "Flutter 3",
    ageId: 10,
    categoryId: 22,
    categoryName: "DEVELOPMENTAL",
  },
];
export const Bot3ByCustomers = [
  {
    amountOfCustomers: 500,
    courseId: 3,
    courseName: "Ice skating",
    ageId: 10,
    categoryId: 22,
    categoryName: "SPORT",
  },
  {
    amountOfCustomers: 1000,
    courseId: 1,
    courseName: "Flutter",
    ageId: 10,
    categoryId: 22,
    categoryName: "STEM",
  },
  {
    amountOfCustomers: 1000,
    courseId: 2,
    courseName: "IB Test Preparation",
    ageId: 10,
    categoryId: 22,
    categoryName: "DEVELOPMENTAL",
  },
];
