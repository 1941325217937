import React, { useState } from "react";
import "./index.less";
import ButtonLink from "../ButtonLink";
import { bizIcons } from "../../constants/bizConstants";
import { Typography } from "antd";
interface IProps extends React.HTMLAttributes<HTMLElement> {
  title: string;
  contentClassName?: string;
}
const { Title } = Typography;

export const ViewMoreBlock = (props: IProps) => {
  const { title, children, style, contentClassName } = props;
  const [isOpened, setIsOpened] = useState(false);
  const _text =
    String(children).length > 370 && !isOpened
      ? String(children)
          .slice(0, 369)
          .replace(/\b(\w+)$/, "…")
      : children;
  return (
    <div
      className={`view-more-block ${props.className ? props.className : ""}`}
      style={style}
    >
      <div className={"view-more-title"}>
        <div className={"biz-typography l1"} style={{ marginBottom: "8px" }}>
          <Title level={4}> {title}</Title>
        </div>
      </div>
      <div>
        <div
          className={`text ${!isOpened ? "is-closed" : ""} ${contentClassName}`}
        >
          {_text}
        </div>

        {String(children).length > 370 && (
          <ButtonLink
            style={{ marginTop: "9px" }}
            onClick={() => setIsOpened(!isOpened)}
          >
            {!isOpened ? "View more" : "View less"}
            <i
              className={`${
                !isOpened ? bizIcons.ARROWDOWN : bizIcons.ARROWUP
              } fs-20`}
            />
          </ButtonLink>
        )}
      </div>
    </div>
  );
};
