import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import {
  Languages,
  LanguageStore,
  languageStoreContext,
} from "../../../scripts/mobx/languageStore";
import EnTermsAndConditions from "./en";
import ZhTermsAndConditions from "./zh";

interface ILingualBlock extends React.HTMLAttributes<HTMLElement> {
  languageStore?: LanguageStore;
}

const TermsAndConditions = inject("languageStore")(
  observer((props: ILingualBlock) => {
    const language = useContext(languageStoreContext);

    return (
      <>
        {language?.lang === Languages.ZH ? (
          <ZhTermsAndConditions />
        ) : (
          <EnTermsAndConditions />
        )}
      </>
    );
  }),
);

export default TermsAndConditions;
