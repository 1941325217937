import { Popover } from "antd";
import { PAGE_SIZE } from "config";
import { FORMAT_DATE } from "constants/commons";
import { pageableFirst } from "constants/type.interface";
import { AppRoutes } from "helpers/app.routes";
import { omit } from "lodash";
import { toJS } from "mobx";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useCurrentUser } from "scripts/custom-hooks/useCurrentUser";
import { DEVICES, useDevices } from "scripts/custom-hooks/useDevices";
import { languageStoreContext } from "scripts/mobx/languageStore";
import providerOrdersStore from "scripts/mobx/providerOrdersStore";
import ButtonLink from "../../../components/ButtonLink";
import TableComponent from "../../../components/TableComponent/TableComponent";
import { bizIcons } from "../../../constants/bizConstants";
import "../index.less";
import { tablePayKidzbizColumns, tablePayKidzbizData } from "../tableGenerator";
import { PayCustomersFilters } from "./filters";

interface IProps extends RouteComponentProps {}

const OrgPaymentsTab = inject("providerOrdersStore")(
  observer((props: IProps) => {
    const { t } = useTranslation();
    const user = useCurrentUser();
    const device = useDevices();
    const language = useContext(languageStoreContext);
    const [isBroken, setIsBroken] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [orgOrdersData, setOrgOrdersData] = useState<any>();
    const filterModel = toJS(providerOrdersStore?.orgPayoutsFilterModel);

    const getOrgOrders = (filter: any) => {
      setIsLoading(true);
      providerOrdersStore
        ?.getOrgPayoutsOrders(filter)
        .then((res) => {
          setOrgOrdersData(res);
          setIsLoading(false);
        })
        .catch(() => setIsLoading(false));
    };

    useEffect(() => {
      (async () => {
        getOrgOrders(filterModel);
      })();
    }, []);

    useEffect(() => {
      if (
        device &&
        (device === DEVICES.MOBILE ||
          device === DEVICES.TABLET_PORT ||
          device === DEVICES.TABLET_LAND) &&
        !isBroken
      ) {
        setIsBroken(true);
      } else if (
        device &&
        device !== DEVICES.MOBILE &&
        device !== DEVICES.TABLET_PORT &&
        device !== DEVICES.TABLET_LAND &&
        isBroken
      ) {
        setIsBroken(false);
      }
    }, [device]);

    const onApply = (data?: any) => {
      let params = {
        ...filterModel,
      };

      if (data?.dateFrom) {
        Object.assign(params, {
          yearFrom: Number(moment(data?.dateFrom)?.format("YYYY")),
          monthFrom: Number(moment(data?.dateFrom)?.format("M")),
        });
      } else {
        params = omit(params, ["yearFrom", "monthFrom"]);
      }
      if (data?.dateTo) {
        Object.assign(params, {
          yearTo: Number(moment(data?.dateTo)?.format("YYYY")),
          monthTo: Number(moment(data?.dateTo)?.format("M")),
        });
      } else {
        params = omit(params, ["yearTo", "monthTo"]);
      }

      setTimeout(() => {
        getOrgOrders(params);
      }, 200);
    };

    const onPageChangeHandler = (page?: any) => {
      getOrgOrders({
        ...filterModel,
        pageable: {
          ...filterModel?.pageable,
          pageNumber: page,
        },
      });
    };

    return (
      <div className={"bsl-4 tsl-4 tab-table-block"}>
        <div
          className={
            "payment-tabs-title no-title tab-table-block-head biz-kidzbiz-filters"
          }
        >
          <PayCustomersFilters onApply={onApply} />
        </div>
        <div className={"tsl-5 bsl-4 flex-align-items"}>
          <ButtonLink
            onClick={() =>
              window.open(AppRoutes.faqDetail("payment"), "_blank")
            }
          >
            {t("kidzbiz.goToFAQs")}
          </ButtonLink>
        </div>

        <div style={{ fontSize: 12 }}>
          <i>{t("payments.fx_warning")}</i>
        </div>

        <TableComponent
          style={{ opacity: `${isLoading ? "0.4" : 1}` }}
          broken={true}
          emptyTableContent={{
            text: t("bookings.empty_data"),
          }}
          propsTableColumns={tablePayKidzbizColumns(
            t,
            language?.lang,
            user?.isLinked,
          )}
          propsTableData={tablePayKidzbizData(
            orgOrdersData?.data?.filter((el: any) => el?.payouts?.length) ||
              undefined,
          )}
          pagination={
            orgOrdersData?.totalCount > PAGE_SIZE
              ? {
                  total: orgOrdersData?.totalCount,
                  pageSize: orgOrdersData?.pageSize,
                  page: orgOrdersData?.page,
                  onChange: onPageChangeHandler,
                }
              : false
          }
          className="biz-month-table"
        />
      </div>
    );
  }),
);

export default withRouter(OrgPaymentsTab);
