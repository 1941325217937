import React from "react";
import { Popover } from "antd";
import { useTranslation } from "react-i18next";
import { PRICING } from "constants/bizConstants";

export const tableFAQColumns = () => {
  const columns = [
    {
      // title: "",
      dataIndex: "name",
      key: "name",
      // ellipsis: !broken,
      render: (text: React.ReactNode) => {
        return (
          <div>
            <Popover
              overlayClassName={`popover-ellipsis`}
              content={text}
              trigger="hover"
            >
              <div className="ellipsis">{text}</div>
            </Popover>
          </div>
        );
      },
    },
    {
      // title: "Introductory Period**",
      ellipsis: true,
      dataIndex: "suburb",
      key: "suburb",
      render: (text: any) => (
        <Popover
          overlayClassName={`popover-ellipsis`}
          content={text}
          trigger="hover"
        >
          <div className="ellipsis">{text}</div>
        </Popover>
      ),
    },
  ];

  return columns;
};
export const tableFAQData = () => {
  return [
    {
      name: "Applied to gross customer spend for multi class courses",
      suburb: PRICING,
    },
    {
      name: "Fixed introductory fee for single classes (paid or free)",
      suburb: "HK$250/S$30/RM130/INR1600",
    },
  ];
};

export const tableFAQDataZH = () => {
  return [
    {
      name: "Applied to gross customer spend - ZH",
      suburb: PRICING,
    },
  ];
};
