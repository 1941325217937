import React from "react";
import { bizIcons as tb } from "../../constants/bizConstants";
import { Link } from "../../components/Link";
import helpers from "../../scripts/helpers";
import moment from "moment";
import { AppRoutes } from "helpers/app.routes";
import { RescheduleType } from "constants/type.interface";
import { Languages } from "scripts/mobx/languageStore";
import TableComponent from "components/TableComponent/TableComponent";
import { Popover } from "antd";
import { uniqBy } from "lodash";
import { formatPrice } from "scripts/utils/formatPrice";
import QueryString from "constants/query-string";

export const tablePayCustomersColumns = (
  t: any,
  isBroken: boolean,
  isLinked: boolean,
) => {
  const columns = [
    {
      title: (
        <div>
          <i key={Math.random()} className={tb.DATECHECK} />
          {t("payments.date")}
        </div>
      ),
      dataIndex: "buyDate",
      key: "buyDate",
      width: isBroken ? undefined : "220px",
      render: (text: any, record: any) => (
        <div>
          {text}
          {isBroken && (
            <Link to={AppRoutes.paymentDetail("customer", record.id)}>
              {t("bizBase.view")}
              <i
                className="ri-arrow-drop-right-line"
                style={{ fontSize: "16px" }}
              />
            </Link>
          )}
        </div>
      ),
    },
    {
      title: (
        <div>
          <i key={Math.random()} className={tb.CARD} />
          {t("payments.type")}
        </div>
      ),
      dataIndex: "Status",
      key: "Status",
      width: isBroken ? undefined : "220px",
      render: (text: any) => (
        <div>
          {isBroken && (
            <i
              className={tb.CARD}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
          )}
          {text ? t("payments.methods." + text) : "-"}
        </div>
      ),
    },
    {
      title: (
        <div>
          <i key={Math.random()} className={tb.PRICE} />
          {t("payments.amount")}
        </div>
      ),
      dataIndex: "price",
      key: "price",
      width: isBroken ? undefined : "220px",
      render: (text: any) => (
        <div>
          {isBroken && (
            <i
              className={tb.PRICE}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
          )}

          {text}
        </div>
      ),
    },
    {
      title: (
        <div>
          <i key={Math.random()} className={tb.BOOKOPEN} />
          {t("courses.courseName")}
        </div>
      ),
      dataIndex: "courseName",
      key: "courseName",
      width: isBroken ? undefined : "200px",
      render: (text: React.ReactNode, record: any) => {
        return (
          <div
            className="ellipsis"
            style={{ maxWidth: isBroken ? "none" : "200px" }}
          >
            {isBroken && (
              <i
                className={tb.BOOKOPEN}
                style={{ fontSize: "16px", marginRight: "8px" }}
              />
            )}
            {text}
          </div>
        );
      },
    },
    {
      title: (
        <div>
          <i key={Math.random()} className={tb.USER} />
          {t("payments.customer_name")}
        </div>
      ),
      dataIndex: "Name",
      key: "Name",
      width: isBroken ? undefined : "220px",
      render: (text: React.ReactNode, record: any) => {
        return (
          <div>
            {isBroken && (
              <i
                className={tb.USER}
                style={{ fontSize: "16px", marginRight: "8px" }}
              />
            )}
            <div className="ellipsis">{text}</div>
          </div>
        );
      },
    },
  ];

  if (isLinked) {
    const finIndexStatus = columns?.findIndex((el) => el.key === "Name");
    const elm = {
      title: (
        <div>
          <i key={Math.random()} className={tb.BUILDING} />
          {t("menu.kidzbiz")}
        </div>
      ),
      key: "legalName",
      dataIndex: "legalName",
      width: isBroken ? undefined : "220px",
      render: (text: any) =>
        text && (
          <div className="ellipsis">
            {isBroken && (
              <i
                className={tb.BUILDING}
                style={{ fontSize: "16px", marginRight: "8px" }}
              />
            )}
            {text}
          </div>
        ),
    };
    columns.splice(finIndexStatus + 1, 0, elm);
  }

  if (!isBroken) {
    columns.push({
      title: t("bizBase.action"),
      key: "action",
      dataIndex: "action",
      width: undefined,
      render: (text: React.ReactNode, record: any) => (
        // ActionElement HERE
        <Link to={AppRoutes.paymentDetail("customer", record.id)}>
          {t("bizBase.view")}
          <i
            className="ri-arrow-drop-right-line"
            style={{ fontSize: "16px" }}
          />
        </Link>
      ),
    });
  }

  return columns;
};
export const tablePayCustomersData = (data?: any[]) => {
  return data?.map((el: any) => {
    return {
      Name: el?.customerName || "",
      price: formatPrice(el?.amount || 0, { currency: el?.currency }),
      buyDate: el.date ? helpers.formatDateTime(el.date) : undefined,
      Status: el?.type,
      legalName: el?.providerName || "",
      courseName: el?.courseName || "",
      id: el?.id,
      key: el?.id,
    };
  });
};

export const tablePayKidzbizColumns = (
  t: any,
  lang: string,
  isLinked?: boolean,
) => {
  const columns = [
    {
      title: (
        <div>
          <i key={Math.random()} className={tb.DATECHECK} />
          {t("payments.date")}
        </div>
      ),
      dataIndex: "buyDate",
      key: "buyDate",
      render: (text: any, record: any) => {
        const month =
          record?.payoutMonth < 10
            ? "0" + record?.payoutMonth
            : record?.payoutMonth;
        const date = record?.payoutYear + "-" + month + "-" + "01";
        const dateTitle =
          lang === Languages.ZH
            ? moment(date).locale("zh_cn").format("MMMM YYYY")
            : moment(date).format("MMMM YYYY");

        const providers = uniqBy(record?.payouts, "providerId");
        return (
          <div>
            {dateTitle}
            {/* {providers?.length > 1 ? (
              <Popover
                placement="topRight"
                content={
                  <div className="biz-provider-links">
                    {providers?.map((el: any) => {
                      return (
                        <div key={el?.providerId} className="biz-provider-link">
                          <Link
                            to={
                              !!el?.partnerReferenceCode
                                ? `/payment-kidzbiz-details/${el?.providerId}/${el?.partnerReferenceCode}`
                                : `/payment-kidzbiz-details/${el?.providerId}`
                            }
                          >
                            {el?.providerName}
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                }
                trigger="click"
              >
                <ButtonLink>
                  {t("bizBase.view")}
                  <i
                    className="ri-arrow-drop-right-line"
                    style={{ fontSize: "16px" }}
                  />
                </ButtonLink>
              </Popover>
            ) : (
              <Link
                to={
                  !!record?.payouts?.[0]?.partnerReferenceCode
                    ? `/payment-kidzbiz-details/${record?.payouts?.[0]?.providerId}/${record?.payouts?.[0]?.partnerReferenceCode}`
                    : `/payment-kidzbiz-details/${record?.payouts?.[0]?.providerId}`
                }
              >
                {t("bizBase.view")}
                <i
                  className="ri-arrow-drop-right-line"
                  style={{ fontSize: "16px" }}
                />
              </Link>
            )} */}
          </div>
        );
      },
    },
    {
      title: "",
      dataIndex: "payouts",
      key: "payouts",
      render: (text: any, record: any) => {
        const date =
          record?.payoutYear + "-" + record?.payoutMonth + "-" + "01";
        return (
          <TableComponent
            propsTableColumns={tablePayKidzbizChildColumns(t, date)}
            propsTableData={record?.payouts}
            pagination={false}
            className="biz-table-inner"
          />
        );
      },
    },
  ];

  return columns;
};

export const tablePayKidzbizChildColumns = (t: any, date: string) => {
  const columns = [
    {
      dataIndex: "",
      key: "",
    },
    {
      key: "providerName",
      dataIndex: "providerName",
      render: (text: any, record: any) => (
        <div>
          <div className="td-title flex align-center">
            <i
              className={tb.BUILDING}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
          </div>
          {text}
        </div>
      ),
    },
    {
      key: "payoutAmount",
      dataIndex: "payoutAmount",
      render: (text: any, record: any) => (
        <div>
          <div className="td-title flex align-center">
            <i
              className={tb.PRICE}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
          </div>
          {formatPrice(text || 0)}
        </div>
      ),
    },
    {
      key: "pendingPayout",
      dataIndex: "pendingPayout",
      render: (text: any) => (
        <div>
          <div className="td-title flex align-center">
            <i
              className={tb.CHECKBOXCIRCLE}
              style={{
                color: text ? "#ffcc33" : "green",
                fontSize: "16px",
                marginRight: "8px",
              }}
            />
          </div>
          {text ? t("payments.PENDING") : t("payments.PROCESSED")}
        </div>
      ),
    },
    {
      key: "partnerReferenceCode",
      dataIndex: "partnerReferenceCode",
      render: (text: any, record: any) =>
        !!text && (
          <div>
            <div className="td-title flex align-center">
              <i
                className={tb.CODE}
                style={{ fontSize: "16px", marginRight: "8px" }}
              />
            </div>
            {text}
          </div>
        ),
    },

    {
      dataIndex: "action",
      key: "action",
      render: (text: any, record: any) => {
        return (
          <div className="biz-provider-link">
            <Link
              to={{
                pathname: !!record?.partnerReferenceCode
                  ? `/payment-kidzbiz-details/${record?.providerId}/${record?.partnerReferenceCode}`
                  : `/payment-kidzbiz-details/${record?.providerId}`,
                search: QueryString.stringify({
                  date,
                }),
              }}
            >
              {t("bizBase.view")}
              <i
                className="ri-arrow-drop-right-line"
                style={{ fontSize: "16px" }}
              />
            </Link>
          </div>
        );
      },
    },
  ];

  return columns;
};

export const tablePayKidzbizData = (data?: any[]) => {
  return data?.map((el: any, index: number) => {
    return {
      ...el,
      id: "tablePayKidzbizData" + index,
      key: "tablePayKidzbizData" + index,
    };
  });
};

export const tablePayOrgViewColumns = (t: any, isLineked?: boolean) => {
  const columns = [
    {
      dataIndex: "",
      key: "",
    },
    {
      key: "businessDate",
      dataIndex: "businessDate",
      render: (text: any, record: any) => (
        <div>
          <div className="td-title flex align-center">
            <i
              className={tb.DATECHECK}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            {t("payments.class_datetime")}
          </div>
          {helpers.formatDate(helpers.getServerDate(record?.businessDate)) +
            " / " +
            record?.startTime?.slice(0, 5) +
            " - " +
            record?.endTime?.slice(0, 5)}
        </div>
      ),
    },
    {
      key: "courseName",
      dataIndex: "courseName",
      render: (text: any, record: any) => (
        <div>
          <div className="td-title flex align-center">
            <i
              className={tb.BOOKOPEN}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            {t("courses.courseName")}
          </div>
          {record?.courseName}
        </div>
      ),
    },
    {
      key: "locationName",
      dataIndex: "locationName",
      render: (text: any, record: any) => (
        <div>
          <div className="td-title flex align-center">
            <i
              className={tb.GLOBE}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            {t("bizBase.location")}
          </div>
          {text}
        </div>
      ),
    },
  ];

  return columns;
};
export const tablePayOrgViewData = (data: any) => {
  return data;
};

export const tablePayViewKidzbizCustomerColumns = (
  t: any,
  isBroken?: boolean,
  isButton?: boolean,
) => {
  const columns = [
    {
      title: t("payments.customer_name"),
      key: "customerName",
      dataIndex: "customerName",
      width: isBroken ? undefined : 180,
      render: (text: any) => <div>{text || "-"}</div>,
    },

    {
      title: t("payments.customer_email"),
      key: "customerEmail",
      dataIndex: "customerEmail",
      width: isBroken ? undefined : 120,
      render: (text: any) => (
        <Popover
          overlayClassName={`popover-ellipsis`}
          content={text}
          trigger="hover"
        >
          <div className="ellipsis">
            {isBroken && (
              <div className="td-title flex align-center">
                <i
                  className={tb.MAIL}
                  style={{ fontSize: "16px", marginRight: "8px" }}
                />
                {t("payments.customer_email")}
              </div>
            )}
            {text || "-"}
          </div>
        </Popover>
      ),
    },
    {
      title: t("customers.childName"),
      key: "childName",
      dataIndex: "childName",
      width: isBroken ? undefined : 120,
      render: (text: any) => (
        <div>
          {isBroken && (
            <div className="td-title flex align-center">
              <i
                className={tb.USER5}
                style={{ fontSize: "16px", marginRight: "8px" }}
              />
              {t("customers.childName")}
            </div>
          )}
          {text || "-"}
        </div>
      ),
    },
    {
      title: t("payments.original_price"),
      key: "originalPrice",
      dataIndex: "originalPrice",
      width: isBroken ? undefined : 120,
      render: (text: any) => (
        <div>
          {isBroken && (
            <div className="td-title flex align-center">
              <i
                className={tb.PRICE2}
                style={{ fontSize: "16px", marginRight: "8px" }}
              />
              {t("payments.original_price")}
            </div>
          )}
          {formatPrice(text)}
        </div>
      ),
    },
    {
      title: t("payments.course_promotion_discount"),
      key: "coursePromotionDiscount",
      dataIndex: "coursePromotionDiscount",
      width: isBroken ? undefined : 180,
      render: (text: any) => (
        <div>
          {isBroken && (
            <div className="td-title flex align-center">
              <i
                className={tb.GIFT2}
                style={{ fontSize: "16px", marginRight: "8px" }}
              />
              {t("payments.course_promotion_discount")}
            </div>
          )}
          {text ? (
            <span style={{ color: "red" }}>
              {formatPrice(text, { isReduce: true })}
            </span>
          ) : (
            "-"
          )}
        </div>
      ),
    },
    {
      title: t("payments.bulk_promotion_discount"),
      key: "bulkPromotionDiscount",
      dataIndex: "bulkPromotionDiscount",
      width: isBroken ? undefined : 180,
      render: (text: any) => (
        <div>
          {isBroken && (
            <div className="td-title flex align-center">
              <i
                className={tb.GIFT2}
                style={{ fontSize: "16px", marginRight: "8px" }}
              />
              {t("payments.bulk_promotion_discount")}
            </div>
          )}
          {text ? (
            <span style={{ color: "red" }}>
              {formatPrice(text, { isReduce: true })}
            </span>
          ) : (
            "-"
          )}
        </div>
      ),
    },
    {
      title: t("kidzbiz.bizibuzFee"),
      key: "bizibuzFee",
      dataIndex: "bizibuzFee",
      width: isBroken ? undefined : 120,
      render: (text: any) => (
        <div>
          {isBroken && (
            <div className="td-title flex align-center">
              <i
                className={tb.PRICE2}
                style={{ fontSize: "16px", marginRight: "8px" }}
              />
              {t("kidzbiz.bizibuzFee")}
            </div>
          )}
          {text ? (
            <span style={{ color: "red" }}>
              {formatPrice(text, { isReduce: true })}
            </span>
          ) : (
            "-"
          )}
        </div>
      ),
    },
    {
      title: t("payments.payout"),
      key: "payout",
      dataIndex: "payout",
      width: isBroken ? undefined : 120,
      render: (text: any) => (
        <div>
          {isBroken && (
            <div className="td-title flex align-center">
              <i
                className={tb.PRICE}
                style={{ fontSize: "16px", marginRight: "8px" }}
              />
              {t("payments.payout")}
            </div>
          )}
          {formatPrice(text)}
        </div>
      ),
    },
    // {
    //   key: "Action",
    //   dataIndex: "Action",
    //   className: "full-width",
    //   render: (text: any, allData: any) =>
    //     isButton && (
    //       <div
    //         className={"full-width"}
    //         style={{
    //           maxWidth: "800px",
    //           width: "calc(100vw - 100px)",
    //         }}
    //       >
    //         <Link to={AppRoutes.paymentDetail("customer", allData.CustomerId)}>
    //           {t("payments.go_to_customer_payment")}
    //         </Link>
    //       </div>
    //     ),
    // },
  ];

  return columns;
};

export const tablePayViewKidzbizCustomerData = (data?: any) => {
  return data;
};

export const tablePayViewCustomerCourseColumns = (t: any) => {
  const columns = [
    {
      dataIndex: "",
      key: "",
    },
    {
      dataIndex: "CourseName",
      key: "CourseName",
      render: (text: any) => (
        <div>
          <div className="td-title flex align-center">
            <i
              className={tb.BOOKOPEN}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            {t("courses.courseName")}
          </div>
          {text || "-"}
        </div>
      ),
    },
    {
      key: "CourseCode",
      dataIndex: "CourseCode",
      render: (text: any) =>
        !!text && (
          <div>
            <div className="td-title flex align-center">
              <i
                className={tb.CODE}
                style={{ fontSize: "16px", marginRight: "8px" }}
              />
              {t("courses.courseCode")}
            </div>
            {text || "-"}
          </div>
        ),
    },
    {
      key: "Category",
      dataIndex: "Category",
      render: (text: any) => (
        <div>
          <div className="td-title flex align-center">
            <i
              className={tb.UL}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            {t("courses.category")}
          </div>
          {text || "-"}
        </div>
      ),
    },
    {
      key: "AgeRange",
      dataIndex: "AgeRange",
      render: (text: any) => (
        <div>
          <div className="td-title flex align-center">
            <i
              className={tb.AGE}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            {t("courses.ageRange")}
          </div>
          {text || "-"}
        </div>
      ),
    },
    {
      key: "CourseDates",
      dataIndex: "CourseDates",
      render: (text: any) => (
        <div>
          <div className="td-title flex align-center">
            <i
              className={tb.CALENDARTODO}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            {t("payments.course_dates")}
          </div>
          {text || "-"}
        </div>
      ),
    },
    {
      key: "NumberOfClasses",
      dataIndex: "NumberOfClasses",
      render: (text: any) => (
        <div>
          <div className="td-title flex align-center">
            <i
              className={tb.BOOKOPEN}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            {t("courses.numberOfClasses")}
          </div>
          {text || "-"}
        </div>
      ),
    },
    {
      key: "pricePerHour",
      dataIndex: "pricePerHour",
      render: (text: any) => (
        <div>
          <div className="td-title flex align-center">
            <i
              className={tb.PRICE2}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            {t("courses.pricePerHour")}
          </div>
          {formatPrice(0)}
        </div>
      ),
    },
    {
      key: "PromotionName",
      dataIndex: "PromotionName",
      render: (text: any) => (
        <div>
          <div className="td-title flex align-center">
            <i
              className={tb.USER4}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            {t("promo.promotionName")}
          </div>
          {text || "-"}
        </div>
      ),
    },
    {
      key: "Childs",
      dataIndex: "Childs",
      render: (text: any, record: any) =>
        record?.Childs?.map((el: any) => (
          <div key={el.id} className="mr-15">
            <div className="td-title flex align-center">
              <i
                className={tb.USER4}
                style={{ fontSize: "16px", marginRight: "8px" }}
              />
              {t("customers.childName")}
            </div>
            {el.name}
          </div>
        )),
    },
    {
      key: "Action",
      dataIndex: "Action",
      render: (text: any, allData: any) => (
        <div
          className={"full-width"}
          style={{
            marginBottom: "32px",
            maxWidth: "800px",
            width: "calc(100vw - 100px)",
          }}
        >
          <Link to={AppRoutes.courseDetail(allData.CourseId)}>
            {t("payments.go_to_course")}
          </Link>
        </div>
      ),
    },
  ];

  return columns;
};
export const tablePayViewCustomerCourseData = (data?: any) => {
  return [
    {
      CourseName: data?.CourseName,
      Category: data?.Category,
      CourseCode: data?.CourseCode,
      CourseDates: data?.CourseDates,
      NumberOfClasses: data?.NumberOfClasses,
      pricePerHour: data?.pricePerHour,
      CustomerAge: data?.CustomerAge,
      CourseId: data?.CourseId,
      Childs: [
        {
          id: 1,
          name: "Chu chu",
        },
        {
          id: 2,
          name: "Chu chu 2",
        },
        {
          id: 3,
          name: "Chu chu 3",
        },
        {
          id: 4,
          name: "Chu chu 4",
        },
      ],
      id: data?.id,
      key: data?.id,
    },
  ];
};

export const tablePayViewCustomerColumns = (t: any) => {
  const columns = [
    {
      dataIndex: "",
      key: "",
    },
    {
      key: "TransactionType",
      dataIndex: "TransactionType",
      render: (text: any) =>
        !!text && (
          <div>
            <div className="td-title flex align-center">
              <i
                className={tb.CARD}
                style={{ fontSize: "16px", marginRight: "8px" }}
              />
              {t("payments.transaction_type")}
            </div>
            {t("payments.methods." + text) || "-"}
          </div>
        ),
    },
    {
      key: "CashAmount",
      dataIndex: "CashAmount",
      render: (text: any, record: any) => {
        let label = t("payments.amount");
        let content = formatPrice(text, { currency: record?.currency });

        if (record?.CreditAmount > 0) {
          label = t("payments.amount_credit");
        }

        if (record?.TransactionType === "CANCELLATION") {
          content = formatPrice(record?.cashAmount, {
            currency: record?.currency,
          });
        }
        return (
          <div>
            <div className="td-title flex align-center">
              <i
                className={tb.PRICE}
                style={{ fontSize: "16px", marginRight: "8px" }}
              />
              {label}
            </div>
            {content}
          </div>
        );
      },
    },
    {
      key: "AmountIssued",
      dataIndex: "AmountIssued",
      render: (text: any, record: any) =>
        ![RescheduleType.PAYMENT, RescheduleType.PAYMENT_CREDIT].includes(
          record?.TransactionType,
        ) &&
        !!record?.CreditAmount && (
          <div>
            <div className="td-title flex align-center">
              <i
                className={tb.PRICE}
                style={{ fontSize: "16px", marginRight: "8px" }}
              />
              {t("payments.amount_of_credit_issued")}
            </div>
            {formatPrice(record?.CreditAmount)}
          </div>
        ),
    },
    // {
    //   key: "RefundAmountIssued",
    //   dataIndex: "RefundAmountIssued",
    //   render: (text: any, record: any) =>
    //     ![RescheduleType.PAYMENT, RescheduleType.PAYMENT_CREDIT].includes(
    //       record?.TransactionType,
    //     ) &&
    //     !!record?.CashAmount && (
    //       <div>
    //         <div className="td-title flex align-center">
    //           <i
    //             className={tb.PRICE}
    //             style={{ fontSize: "16px", marginRight: "8px" }}
    //           />
    //           {t("payments.amount_of_refund_issued")}
    //         </div>
    //         {formatPrice(record?.CashAmount)}
    //       </div>
    //     ),
    // },
    {
      key: "AmountUsed",
      dataIndex: "AmountUsed",
      render: (text: any, record: any) =>
        [RescheduleType.PAYMENT, RescheduleType.PAYMENT_CREDIT].includes(
          record?.TransactionType,
        ) &&
        !!record?.CreditAmount && (
          <div>
            <div className="td-title flex align-center">
              <i
                className={tb.PRICE}
                style={{ fontSize: "16px", marginRight: "8px" }}
              />
              {t("payments.amount_of_credit_used")}
            </div>
            {formatPrice(record?.CreditAmount)}
          </div>
        ),
    },

    {
      key: "CancellationFee",
      dataIndex: "CancellationFee",
      render: (text: any, record: any) =>
        ![RescheduleType.PAYMENT, RescheduleType.PAYMENT_CREDIT].includes(
          record?.TransactionType,
        ) &&
        !!record?.CancellationFee && (
          <div>
            <div className="td-title flex align-center">
              <i
                className={tb.PRICE}
                style={{ fontSize: "16px", marginRight: "8px" }}
              />
              {t("payments.cancellation_fee")}
            </div>
            {formatPrice(text)}
          </div>
        ),
    },
    {
      key: "Timestamp",
      dataIndex: "Timestamp",
      render: (text: any) => (
        <div>
          <div className="td-title flex align-center">
            <i
              className={tb.DATECHECK}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            {t("payments.date")}
          </div>
          {text || "-"}
        </div>
      ),
    },

    // {
    //   key: "ThirdPartyFees",
    //   dataIndex: "ThirdPartyFees",
    //   render: (text: any, record: any) =>
    //     [RescheduleType.PAYMENT, RescheduleType.PAYMENT_CREDIT].includes(
    //       record?.TransactionType,
    //     ) && (
    //       <div>
    //         <div className="td-title flex align-center">
    //           <i
    //             className={tb.MAIL}
    //             style={{ fontSize: "16px", marginRight: "8px" }}
    //           />
    //           {t("payments.third_party_fees")}
    //         </div>
    //         {formatPrice(text)}
    //       </div>
    //     ),
    // },
    // {
    //   key: "CancellationFee",
    //   dataIndex: "CancellationFee",
    //   render: (text: any, record: any) =>
    //     [RescheduleType.REFUND, RescheduleType.CREDIT].includes(
    //       record?.TransactionType,
    //     ) && (
    //       <div>
    //         <div className="td-title flex align-center">
    //           <i
    //             className={tb.MAIL}
    //             style={{ fontSize: "16px", marginRight: "8px" }}
    //           />
    //           {t("payments.cancellation_fee")}
    //         </div>
    //         {formatPrice(text)}
    //       </div>
    //     ),
    // },

    {
      key: "TransactionId",
      dataIndex: "TransactionId",
      render: (text: any) => (
        <div>
          <div className="td-title flex align-center">
            <i
              className={tb.PRICE2}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            {t("payments.transaction_id")}
          </div>
          {text || "-"}
        </div>
      ),
    },
    {
      dataIndex: "",
      key: "",
      className: "full-width width-0",
      render: () => <div />,
    },
    {
      key: "CustomerName",
      dataIndex: "CustomerName",
      render: (text: any) => (
        <div>
          <div className="td-title flex align-center">
            <i
              className={tb.USER4}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            {t("payments.customer_name")}
          </div>
          {text || "-"}
        </div>
      ),
    },

    {
      key: "CustomerPhone",
      dataIndex: "CustomerPhone",
      render: (text: any) => (
        <div>
          <div className="td-title flex align-center">
            <i
              className={tb.PHONE}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            {t("payments.customer_phone")}
          </div>
          {text || "-"}
        </div>
      ),
    },

    {
      key: "CustomerEmail",
      dataIndex: "CustomerEmail",
      render: (text: any) => (
        <div>
          <div className="td-title flex align-center">
            <i
              className={tb.MAIL}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            {t("payments.customer_email")}
          </div>
          {text || "-"}
        </div>
      ),
    },
    // {
    //   key: "TimestampBooking",
    //   dataIndex: "TimestampBooking",
    //   render: (text: any) => (
    //     <div>
    //       <div className="td-title flex align-center">
    //         <i
    //           className={tb.DATECHECK}
    //           style={{ fontSize: "16px", marginRight: "8px" }}
    //         />
    //         {t("payments.date_of_booking")}
    //       </div>
    //       {text || "-"}
    //     </div>
    //   ),
    // },
    {
      key: "PaymentMethod",
      dataIndex: "PaymentMethod",
      render: (text: any) =>
        !!text && (
          <div>
            <div className="td-title flex align-center">
              <i
                className={tb.DATECHECK}
                style={{ fontSize: "16px", marginRight: "8px" }}
              />
              {t("payments.payment_method")}
            </div>
            {text !== "PROVIDER_CREDIT" ? t("payments.methods." + text) : "-"}
          </div>
        ),
    },
    {
      key: "Action",
      dataIndex: "Action",
      className: "full-width",
      render: (text: any, allData: any) => (
        <div
          className={"full-width"}
          style={{
            maxWidth: "800px",
            width: "calc(100vw - 100px)",
          }}
        >
          <Link to={AppRoutes.customerId(allData.CustomerId)}>
            {t("payments.go_to_customer")}
          </Link>
        </div>
      ),
    },
  ];

  return columns;
};
export const tablePayViewCustomerData = (data?: any) => {
  return [
    {
      Amount: data?.totalCourseCost || 0,
      CashAmount: (data?.totalCourseCost || 0) - (data?.creditAmount || 0),
      cashAmount: data?.cashAmount || 0,
      CreditAmount: data?.creditAmount || 0,
      Timestamp: helpers.formatDateTime(data?.date),
      TransactionType: data?.transactionType,
      ThirdPartyFees: data?.thirdPartyFees,
      BizibuzFee: data?.bizibuzFee,
      CancellationFee: data?.kidzbizCourseCancellationFee,
      TransactionId: data?.id,
      CustomerId: data?.customerId,
      CustomerName: data?.customerName,
      CustomerPhone: data?.customerPhone,
      CustomerEmail: data?.customerEmail,
      TimestampBooking: helpers.formatDateTime(data?.bookingDate),
      PaymentMethod: data?.paymentMethod,
      id: data?.id,
      key: data?.id,
      currency: data?.currency,
    },
  ];
};

export const tablePayViewKidzbizCancellationFeesColumns = (
  t: any,
  isBroken?: boolean,
) => {
  const columns = [
    {
      title: t("payments.customer_name"),
      key: "customerName",
      dataIndex: "customerName",
      render: (text: any) => <div>{text || "-"}</div>,
    },

    {
      title: t("payments.customer_email"),
      key: "customerEmail",
      dataIndex: "customerEmail",
      render: (text: any) => (
        <Popover
          overlayClassName={`popover-ellipsis`}
          content={text}
          trigger="hover"
        >
          <div className="ellipsis">
            {isBroken && (
              <div className="td-title flex align-center">
                <i
                  className={tb.MAIL}
                  style={{ fontSize: "16px", marginRight: "8px" }}
                />
                {t("payments.customer_email")}
              </div>
            )}
            {text || "-"}
          </div>
        </Popover>
      ),
    },
    {
      title: t("customers.childName"),
      key: "childName",
      dataIndex: "childName",
      render: (text: any) => (
        <div>
          {isBroken && (
            <div className="td-title flex align-center">
              <i
                className={tb.USER5}
                style={{ fontSize: "16px", marginRight: "8px" }}
              />
              {t("customers.childName")}
            </div>
          )}
          {text || "-"}
        </div>
      ),
    },
    {
      title: t("courses.courseName"),
      key: "courseName",
      dataIndex: "courseName",
      render: (text: any) => (
        <div>
          {isBroken && (
            <div className="td-title flex align-center">
              <i
                className={tb.BOOKOPEN}
                style={{ fontSize: "16px", marginRight: "8px" }}
              />
              {t("courses.courseName")}
            </div>
          )}
          {text || "-"}
        </div>
      ),
    },
    {
      title: t("payments.cancellation_fee"),
      key: "cancellationFee",
      dataIndex: "cancellationFee",
      render: (text: any) => (
        <div>
          {isBroken && (
            <div className="td-title flex align-center">
              <i
                className={tb.PRICE2}
                style={{ fontSize: "16px", marginRight: "8px" }}
              />
              {t("payments.cancellation_fee")}
            </div>
          )}
          {formatPrice(text)}
        </div>
      ),
    },
    {
      title: t("payments.cancellation_date"),
      key: "cancellationDate",
      dataIndex: "cancellationDate",
      render: (text: any) => (
        <div>
          {isBroken && (
            <div className="td-title flex align-center">
              <i
                className={tb.PRICE2}
                style={{ fontSize: "16px", marginRight: "8px" }}
              />
              {t("payments.cancellation_fee")}
            </div>
          )}
          {text
            ? helpers.formatDateTime(helpers.getServerDate(text))
            : undefined}
        </div>
      ),
    },
  ];

  return columns;
};
