import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { UserStore } from "scripts/mobx/userStore";
import "./index.less";

interface IProps extends RouteComponentProps {
  userStore?: UserStore;
}

const StripeAccount = inject("userStore")(
  observer((props: IProps) => {
    const { userStore } = props;
    const { t } = useTranslation();
    const [isLoading, setLoading] = React.useState<boolean>(false);
    useEffect(() => {
      setLoading(true);
      userStore
        ?.getStripeAccountOnboard()
        .then((res) => {
          setTimeout(() => {
            setLoading(false);
            if (res?.data) {
              window.location.href = res?.data;
            }
          }, 2000);
        })
        .catch(() => setLoading(false));
    }, []);

    return (
      <div className="tsl-3 bsl-4 flex-column">
        <h3 className="bsl-3">{t("signUp.stripe_account_onboard")}</h3>
        {isLoading && <div className="loader"></div>}
      </div>
    );
  }),
);
export default StripeAccount;
