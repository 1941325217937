import React from "react";
import "./index.less";
import { Link } from "components/Link";
import emptyTableSVG from "../../assets/courses-empty.svg";

interface IProps extends React.HTMLAttributes<HTMLElement> {
  text?: string;
  imageSrc?: string;
  linkUrl?: string;
  linkText?: string;
  className?: string;
}

const EmptyTableContent = (props: IProps) => {
  const { text, imageSrc = emptyTableSVG, className, linkUrl, linkText} = props;

  return (
    <div className={`empty-table-content-wrapper ${className}`}>
      <div className={"empty-table-content full-width tsl-2"}>
        <div>
          <div>{text}</div>
          {!!linkUrl && <Link to={linkUrl}>{linkText}</Link>}
        </div>
        {!!imageSrc && (
          <div>
            <img src={imageSrc} alt="image-in-empty-table" />
          </div>
        )}
      </div>
    </div>
  );
};

export default EmptyTableContent;
