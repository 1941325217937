import React from "react";
import helpers from "scripts/helpers";
import { UserType } from "scripts/mobx/userStore";
import { formatPrice } from "scripts/utils/formatPrice";
import { bizIcons as tb } from "../../constants/bizConstants";

export const tableOrgDetailsTitleColumns = (t?: any, landlinePhone?: any) => {
  const columns = [
    {
      dataIndex: "",
      key: "",
    },
    {
      key: "ContactPerson",
      dataIndex: "ContactPerson",
      hidden: false,
      render: (text: any, allData: any) => (
        <div>
          <div className="td-title flex align-center">
            <i
              className={tb.USER}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            {t("kidzbiz.contactPerson")}
          </div>
          {`${allData?.ContactPersonFirstName || ""} ${
            allData?.ContactPersonLastName || ""
          }` || "-"}
        </div>
      ),
    },
    {
      key: "MobilePhone",
      dataIndex: "MobilePhone",
      hidden: false,
      render: (text: any) => (
        <div>
          <div className="td-title flex align-center">
            <i
              className={tb.SMARTPHONE}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            {t("kidzbiz.mobilePhone")}
          </div>
          {text || "-"}
        </div>
      ),
    },
    {
      key: "LandlinePhone",
      dataIndex: "LandlinePhone",
      // hidden: landlinePhone ? false : true,
      render: (text: any) => (
        <div>
          <div className="td-title flex align-center">
            <i
              className={tb.PHONE}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            {t("kidzbiz.landlinePhone")}
          </div>
          {text || "-"}
        </div>
      ),
    },
    {
      key: "Email",
      dataIndex: "Email",
      hidden: false,
      render: (text: any) => (
        <div>
          <div className="td-title flex align-center">
            <i
              className={tb.MAIL}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            {t("kidzbiz.email")}
          </div>
          {text || "-"}
        </div>
      ),
    },
  ].filter((item) => !item.hidden);

  return columns;
};

export const tableOrgDetailsTitleData = (data?: any) => {
  return [
    {
      City: data?.city,
      StateProvince: data?.stateProvince,
      MobilePhone: data?.mobilePhone,
      LandlinePhone: data?.landlinePhone,
      ContactPersonFirstName: data?.contactPersonFirstName,
      ContactPersonLastName: data?.contactPersonLastName,
      Email: data?.email,
      Website: data?.webSite?.trim(),
      id: data?.id,
      key: data?.id,
    },
  ];
};

export const tableOrgDetailsFundsColumns = (
  data: any,
  t?: any,
  userType?: UserType,
) => {
  // console.log(data);
  const columns = [
    {
      dataIndex: "",
      key: "",
    },
    {
      key: "TransactionVolume",
      dataIndex: "TransactionVolume",
      hidden: data?.transactionVolume ? false : true,
      render: (text: any) => (
        <div>
          <div className="td-title flex align-center">
            <i
              className={tb.PRICE}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            {t("kidzbiz.transactionVolume")}
          </div>
          {formatPrice(Number(text))}
        </div>
      ),
    },
    {
      key: "CurrentBalance",
      dataIndex: "CurrentBalance",
      render: (text: any) => (
        <div>
          <div className="td-title flex align-center">
            <i
              className={tb.WALLET}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            {t("kidzbiz.accruedPayout")}
          </div>
          {formatPrice(Number(text))}
        </div>
      ),
    },
    {
      key: "KidsEnrolled",
      dataIndex: "KidsEnrolled",
      hidden: data?.kidsEnrolled ? false : true,
      render: (text: any) => (
        <div>
          <div className="td-title flex align-center">
            <i
              className={tb.TEAM}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            {t("kidzbiz.kidsEnrolled")}
          </div>
          {helpers.formatNumber(text) || "-"}
        </div>
      ),
    },
    {
      key: "NumberOfCourses",
      dataIndex: "NumberOfCourses",
      hidden: data?.numberOfCourses ? false : true,
      render: (text: any) => (
        <div>
          <div className="td-title flex align-center">
            <i
              className={tb.BOOKOPEN}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            {t("kidzbiz.noCourses")}
          </div>
          {helpers.formatNumber(text) || "-"}
        </div>
      ),
    },
    {
      key: "NumberOfCancellationsRefunds",
      dataIndex: "NumberOfCancellationsRefunds",
      hidden: data?.numberOfCancellationsRefunds ? false : true,
      render: (text: any) => (
        <div>
          <div className="td-title flex align-center">
            <i
              className={tb.HOME3}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            {t("kidzbiz.noCancellationsRefunds")}
          </div>
          {text || "-"}
        </div>
      ),
    },
    {
      key: "TakeRate",
      dataIndex: "TakeRate",
      hidden: data?.takeRate ? false : true,
      render: (text: any) => (
        <div>
          <div className="td-title flex align-center">
            <i
              className={tb.PRICE2}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            {t("kidzbiz.bizibuzFee")}
          </div>
          {(text * 100).toFixed(1)}%
        </div>
      ),
    },
  ].filter((item) => !item.hidden);

  return columns;
};
export const tableOrgDetailsFundsData = (data?: any) => {
  return [
    {
      TransactionVolume: data?.transactionVolume,
      CurrentBalance: data?.accruedPayoutMtd,
      Status: data?.status,
      TakeRate: data?.takeRate,
      KidsEnrolled: data?.kidsEnrolled,
      NumberOfCourses: data?.numberOfCourses,
      NumberOfCancellationsRefunds: formatPrice(
        data?.numberOfCancellationsRefunds,
      ),
      id: data.id,
      key: data.id,
    },
  ];
};

export const tableLocationsColumns = (
  broken?: boolean,
  ActionEdit?: React.FC<{ record: any; index: number }>,
) => {
  const columns = [
    {
      dataIndex: "name",
      key: "name",
      render: (text: any, record: any, index: number) => (
        <div>
          <div>{text || "-"}</div>
          {ActionEdit !== undefined && (
            <div>
              <ActionEdit record={record} index={index} />
            </div>
          )}
        </div>
      ),
    },
    {
      key: "PhoneNumber",
      dataIndex: "phoneNumber",
      render: (text: any) => (
        <>
          {text ? (
            <div>
              <i
                className={tb.SMARTPHONE}
                style={{ fontSize: "16px", marginRight: "8px" }}
              />
              {text}
            </div>
          ) : (
            ""
          )}
        </>
      ),
    },
    {
      key: "Email",
      dataIndex: "email",
      render: (text: any) => (
        <>
          {text ? (
            <div>
              <i
                className={tb.MAIL}
                style={{ fontSize: "16px", marginRight: "8px" }}
              />
              {text || "-"}
            </div>
          ) : (
            ""
          )}
        </>
      ),
    },
    {
      dataIndex: "address",
      key: "address",
      render: (text: any) => (
        <>
          {text ? (
            <div>
              <i
                className={tb.PIN}
                style={{ fontSize: "16px", marginRight: "8px" }}
              />
              {text || "-"}
            </div>
          ) : (
            ""
          )}
        </>
      ),
    },
    {
      key: "suburb",
      dataIndex: "suburb",
      render: (text: any) => (
        <>
          {text ? (
            <div>
              <i
                className={tb.BUILDING}
                style={{ fontSize: "16px", marginRight: "8px" }}
              />
              {text}
            </div>
          ) : (
            ""
          )}
        </>
      ),
    },
  ];

  return columns;
};

export const tableOrgLinkedColumns = (
  t?: any,
  userType?: UserType,
  ActionEdit?: React.FC<{ record: any }>,
  ActionResend?: React.FC<{ record: any }>,
) => {
  const columns = [
    {
      dataIndex: "legalName",
      key: "legalName",
      render: (text: any, record: any) => (
        <div className="biz-table-heading">
          <div className="flex biz-table-title-name">
            {record?.legalName}
            <span className="flex ml-10 fs-14" style={{ color: "#222222a6" }}>
              <i className={"ri-links-fill"} style={{ marginRight: 3 }} />{" "}
              {userType === UserType.MASTER
                ? t("bizBase.invitee")
                : t("bizBase.inviter")}
            </span>
            {userType === UserType.MASTER && (
              <>
                <div className="flex ml-10 green fs-14">
                  <i
                    className={tb.MAILSEND}
                    style={{ fontSize: 16, marginRight: 4 }}
                  />
                  {t("bizBase.accepted")}
                </div>
              </>
            )}
          </div>

          {ActionEdit !== undefined && (
            <div className="biz-table-btn-view">
              <ActionEdit record={record} />
            </div>
          )}
        </div>
      ),
    },
    {
      dataIndex: "firstName",
      key: "firstName",
      render: (text: any, record: any) => (
        <>
          {record?.firstName ? (
            <div>
              <i
                className={tb.USER}
                style={{ fontSize: "16px", marginRight: "8px" }}
              />
              {`${record?.firstName} ${record?.lastName}` || "-"}
            </div>
          ) : (
            ""
          )}
        </>
      ),
    },
    {
      key: "mobilePhone",
      dataIndex: "mobilePhone",
      render: (text: any, record: any) => (
        <>
          {record?.mobile ? (
            <div>
              <i
                className={tb.SMARTPHONE}
                style={{ fontSize: "16px", marginRight: "8px" }}
              />
              {record?.mobile}
            </div>
          ) : (
            ""
          )}
        </>
      ),
    },
    {
      key: "email",
      dataIndex: "email",
      render: (text: any, record: any) => (
        <>
          {record?.email ? (
            <div>
              <i
                className={tb.MAIL}
                style={{ fontSize: "16px", marginRight: "8px" }}
              />
              {record?.email}
            </div>
          ) : (
            ""
          )}
        </>
      ),
    },
    {
      key: "suburb",
      dataIndex: "suburb",
      render: (text: any, record: any) => (
        <>
          {record?.suburb ? (
            <div>
              <i
                className={tb.BUILDING}
                style={{ fontSize: "16px", marginRight: "8px" }}
              />
              {record?.suburb}
            </div>
          ) : (
            ""
          )}
        </>
      ),
    },
    {
      key: "status",
      dataIndex: "status",
      render: (text: any, record: any) => (
        <>
          {record?.status ? (
            <div>
              <i
                className={tb.KEY}
                style={{ fontSize: "16px", marginRight: "5px" }}
              />
              {helpers.userStatus(t, record?.status)}
            </div>
          ) : (
            ""
          )}
        </>
      ),
    },
    {
      key: "invitationDate",
      dataIndex: "invitationDate",
      render: (text: any, record: any) => (
        <>
          {record?.invitationDate ? (
            <div>
              <i
                className={"ri-time-line"}
                style={{ fontSize: "16px", marginRight: "8px" }}
              />
              {helpers.formatDate(record?.invitationDate)}
            </div>
          ) : (
            ""
          )}
        </>
      ),
    },
  ];

  return columns;
};

export const tableInvitationsLinkedColumns = (t?: any, userType?: UserType) => {
  const columns = [
    {
      dataIndex: "email",
      key: "email",
      render: (text: any, record: any) => (
        <div>
          <div className="flex">
            {record?.email}
            <span className="flex ml-10 fs-14" style={{ color: "#222222a6" }}>
              <i className={"ri-links-fill"} style={{ marginRight: 3 }} />{" "}
              {userType === UserType.MASTER
                ? t("bizBase.invitee")
                : t("bizBase.inviter")}
            </span>
            {record?.status === "EXPIRED" ? (
              <div className="flex ml-10 fs-14" style={{ color: "red" }}>
                <i
                  className={tb.MAILSEND}
                  style={{ fontSize: 16, marginRight: 4 }}
                />
                {t("bizBase.expired")}
              </div>
            ) : (
              <div className="flex ml-10 fs-14" style={{ color: "#1273eb" }}>
                <i
                  className={tb.MAILSEND}
                  style={{ fontSize: 16, marginRight: 4 }}
                />
                {t("bizBase.sent")}
              </div>
            )}
          </div>
        </div>
      ),
    },
    {
      key: "invitationDate",
      dataIndex: "invitationDate",
      render: (text: any, record: any) => (
        <>
          {record?.invitationDate ? (
            <div>
              <i
                className={"ri-time-line"}
                style={{ fontSize: "16px", marginRight: "8px" }}
              />
              {helpers.formatDate(
                helpers.getServerDate(record?.invitationDate),
              )}
            </div>
          ) : (
            ""
          )}
        </>
      ),
    },
  ];

  return columns;
};

export const tableEmployeesColumns = (
  t: any,
  broken?: boolean,
  ActionEdit?: React.FC<{ record: any; index: number }>,
  ActionDeactivate?: React.FC<{ record: any }>,
) => {
  const columns = [
    {
      dataIndex: "name",
      key: "name",
      render: (text: any, record: any, key: number) => (
        <div>
          <div className="flex">
            {`${record?.firstName}${` `}${record?.lastName}` || "-"}
            {record?.status === "ACTIVE" ? (
              <span className="flex green fs-14 ml-10">
                <i className={"ri-checkbox-circle-line"} />{" "}
                {t("bizBase.active")}
              </span>
            ) : (
              <span className="flex red fs-14 ml-10">
                <i className={"ri-checkbox-circle-line"} />{" "}
                {t("bizBase.deactivated")}
              </span>
            )}
          </div>
          {record?.status === "ACTIVE" ? (
            <div className={"flex-center"}>
              {ActionEdit !== undefined && (
                <ActionEdit record={record} index={key} />
              )}
              {ActionDeactivate !== undefined && (
                <ActionDeactivate record={record} />
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      ),
    },
    {
      dataIndex: "email",
      key: "email",
      render: (value: any) => (
        <div>
          <i
            className={tb.MAIL}
            style={{ fontSize: "16px", marginRight: "8px" }}
          />
          {value !== undefined ? value : "-"}
        </div>
      ),
    },
    {
      dataIndex: "accessType",
      key: "accessType",
      render: (value: any) => (
        <div style={{ textTransform: "capitalize" }}>
          <i
            className={tb.UNLINK}
            style={{ fontSize: "16px", marginRight: "8px" }}
          />
          {value === "PARTIAL" ? "Partial" : "Full"}
        </div>
      ),
    },
  ];
  return columns;
};

export const tablePoliciesLinksColumns = (
  broken?: boolean,
  ActionEdit?: React.FC<{ record: any; index: number }>,
) => {
  const columns = [
    {
      dataIndex: "name",
      key: "name",
      render: (text: any, record: any, index: number) => (
        <div>
          <div>{text || "-"}</div>
          {ActionEdit !== undefined && (
            <div>
              <ActionEdit record={record} index={index} />
            </div>
          )}
        </div>
      ),
    },
  ];

  return columns;
};
