import React, { useContext, useState } from "react";
import "./index.less";
import cookieSVG from "../../assets/icons/cookies.svg";
import { Link } from "../Link";
import { Button } from "antd";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { Languages, languageStoreContext } from "scripts/mobx/languageStore";
import { useHistory } from "react-router";

export const CookiesPolicy = () => {
  const languageStore = useContext(languageStoreContext);
  const { t } = useTranslation("base");
  const history = useHistory();
  const [cookieAccepted, setCookieAccepted] = useState(Cookies.get("accepted"));

  const acceptCookie = () => {
    if (process.env.RAZZLE_ENV === "development") {
      Cookies.set("accepted", "true", { sameSite: "strict" });
    } else {
      Cookies.set("accepted", "true", { domain: ".bizibuz.com" });
    }

    setCookieAccepted("true");
  };

  return !cookieAccepted ? (
    <div className={`policy-block-wrapper`}>
      <div className={`policy-block`}>
        <div className={"policy-text-block flex"}>
          <img src={cookieSVG} alt="cookie svg" className={"mr-16"} />
          <div style={{ alignItems: "center", display: "flex" }}>
            {t("cookies.text")}
          </div>
        </div>
        <div className={"policy-buttons-block flex"}>
          <div style={{ padding: "12px 24px" }}>
            <Link
              to={`/bizibuz-policies/cookie-policy?lang=${
                languageStore?.lang === Languages.ZH ? "zh-CN" : "en"
              }`}
              target="_blank"
            >
              {t("cookies.link")}
            </Link>
          </div>
          <Button onClick={acceptCookie} className="fs-16" type="primary">
            {t("cookies.button")}
          </Button>
        </div>
      </div>
    </div>
  ) : (
    <> </>
  );
};
