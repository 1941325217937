import { Input, Typography } from "antd";
import { FORMAT_DATE } from "constants/commons";
import QueryString from "constants/query-string";
import { PreferredLanguage } from "constants/type.interface";
import { forEach, keys, omit } from "lodash";
import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import { userBlocked, useRoles } from "scripts/custom-hooks/useCurrentUser";
import { useQueryParams } from "scripts/custom-hooks/useQueryParams";
import helpers from "scripts/helpers";
import courseStore, { pageable } from "scripts/mobx/courseStore";
import { CustomersStore } from "scripts/mobx/customersStore";
import { Roles } from "scripts/mobx/userStore";
import ButtonLink from "../../../components/ButtonLink";
import TableComponent from "../../../components/TableComponent/TableComponent";
import { DEVICES, useDevices } from "../../../scripts/custom-hooks/useDevices";
import { tableCustomersColumns } from "../tableGenerator";
import { CustomersFilters } from "./filters";

const { Title } = Typography;

interface IProps {
  customersStore?: CustomersStore;
}

export const ContentWithTableCustomers = inject("customersStore")(
  observer((props: IProps) => {
    const { customersStore } = props;
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const queryParams = useQueryParams();
    const device = useDevices();
    const userRole = useRoles();
    const isBlocked = userBlocked();
    const keyword = queryParams.get("keyword") || "";
    const [broken, setBroken] = useState<boolean | undefined>();
    const [isLoading, setIsLoading] = useState<boolean | undefined>(true);
    const [keywordState, setKeyword] = useState<string>(keyword);
    const customers = toJS(customersStore?.customersData);
    const filterModel = toJS(customersStore?.filterModel);

    const exportFile = () => {
      const dataCSV = customers?.data?.map((el: any) => {
        const params = {};
        forEach(el?.children, (c: any, index: number) => {
          const i = index + 1;
          Object.assign(params, {
            [t("exports.child_name_first", {
              i,
            })]: c?.firstName,
            [t("exports.child_name_last", {
              i,
            })]: c?.lastName,
            [t("exports.child_name_dob", {
              i,
            })]: moment(helpers?.getServerDate(c?.dateOfBirth)).format(
              FORMAT_DATE.date,
            ),
            [t("exports.child_name_medical", {
              i,
            })]: c?.medicalIssues,
          });
        });
        return {
          [t("exports.customer_first_name")]: el?.firstName,
          [t("exports.customer_last_name")]: el?.lastName,
          [t("exports.mobile_phone_number")]: el?.mobile,
          [t("exports.email")]: el?.email,
          // Suburb: el?.suburb,
          [t("exports.location")]: el?.location,
          [t("exports.language_preference")]:
            el?.preferredLanguage === PreferredLanguage.English
              ? "English"
              : "Chinese",
          ...params,
        };
      });
      helpers.exportToCSV(dataCSV, "Customers_Export");
    };

    const getCustomers = async (filter?: any) => {
      setIsLoading(true);
      await customersStore?.getProviderCustomers(filter).finally(() => {
        setIsLoading(false);
      });
    };

    useEffect(() => {
      courseStore.getCategories();
      getCustomers({ ...filterModel });
    }, []);

    useEffect(() => {
      if (
        device &&
        (device === DEVICES.MOBILE ||
          device === DEVICES.TABLET_PORT ||
          device === DEVICES.TABLET_LAND) &&
        !broken
      ) {
        setBroken(true);
      } else if (
        device &&
        device !== DEVICES.MOBILE &&
        device !== DEVICES.TABLET_PORT &&
        device !== DEVICES.TABLET_LAND &&
        broken
      ) {
        setBroken(false);
      }
    }, [device, broken]);

    const onApply = (data?: any) => {
      let params = {
        ...filterModel,
        pageable: pageable,
      };
      if (data?.Age) {
        const ages = data?.Age?.map((a: string) => {
          const field = a?.split("-");
          return {
            ageFrom: Number(field?.[0]) * 12,
            ageTo: Number(field?.[1]) * 12,
          };
        });

        Object.assign(params, {
          ages,
        });
      } else {
        params = omit(params, ["ages"]);
      }
      if (data?.dateFrom) {
        Object.assign(params, {
          dateFrom: moment(data?.dateFrom)?.format(FORMAT_DATE.query),
        });
      } else {
        params = omit(params, ["dateFrom"]);
      }
      if (data?.dateTo) {
        Object.assign(params, {
          dateTo: moment(data?.dateTo)?.format(FORMAT_DATE.query),
        });
      } else {
        params = omit(params, ["dateTo"]);
      }

      if (data?.categoryId) {
        Object.assign(params, {
          category: data?.categoryId,
        });
      } else {
        params = omit(params, ["category"]);
      }

      if (data?.subCategory) {
        Object.assign(params, {
          subCategory: data?.subCategory,
        });
      } else {
        params = omit(params, ["subCategory"]);
      }
      if (data?.suburb) {
        Object.assign(params, {
          location: data?.suburb,
        });
      } else {
        params = omit(params, ["location"]);
      }
      if (!params?.keyword) {
        params = omit(params, ["keyword"]);
      }

      if (data?.transactionVolume) {
        const lifetimeSpend = data?.transactionVolume?.map((a: string) => {
          const field = a?.split("-");
          return {
            from: Number(field?.[0]),
            to: Number(field?.[1]),
          };
        });

        Object.assign(params, {
          lifetimeSpend,
        });
      } else {
        params = omit(params, ["lifetimeSpend"]);
      }

      getCustomers(params);
    };

    const onPageChangeHandler = (page?: any) => {
      getCustomers({
        ...filterModel,
        pageable: {
          ...filterModel?.pageable,
          pageNumber: page,
        },
      });
    };

    const searchHandler = async (event: any) => {
      const value = event?.target?.value;
      setKeyword(value);
      setIsLoading(true);

      try {
        await customersStore?.getProviderCustomers({
          ...filterModel,
          pageable: {
            ...filterModel?.pageable,
            pageNumber: 1,
          },
          keyword: value || null,
        });
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };
    return (
      <div className={`content-with-table customers-wrapper-content`}>
        <div className={`cwt_head`}>
          <Title className={"title"} level={1}>
            {t("menu.customers")}
          </Title>
          <div className={"search"}>
            <Input
              size={"large"}
              placeholder={t("bizBase.search")}
              prefix={<i className={"ri-search-line mr-8 fs-20 color-grey"} />}
              onBlur={searchHandler}
              onPressEnter={searchHandler}
              value={keywordState}
              onChange={(e) => setKeyword(e?.target?.value)}
              allowClear
            />
          </div>
          <CustomersFilters onApply={onApply} />
        </div>

        {userRole !== Roles.PARTIAL && (
          <div
            style={{ paddingTop: "13px", marginTop: "16px", fontWeight: 500 }}
          >
            <ButtonLink onClick={exportFile}>
              <i
                className="ri-file-download-line"
                style={{ marginRight: "8px", fontSize: "16px" }}
              />
              {t("bookings.exportThisList")}
            </ButtonLink>
          </div>
        )}

        <div className={"cwt_table tsl-4"}>
          <TableComponent
            pagination={{
              total: customers?.totalCount,
              pageSize: customers?.pageSize,
              page: customers?.pageNumber,
              onChange: onPageChangeHandler,
            }}
            broken={broken}
            isLoading={isLoading}
            propsTableColumns={tableCustomersColumns(t, broken)}
            propsTableData={customers?.data}
            emptyTableContent={{
              text: t("bookings.empty_data"),
            }}
          />
        </div>
      </div>
    );
  }),
);
