import { useCallback, useEffect, useState } from "react";

export interface IBreakpoint {
  MOBILE: number;
  TABLET_PORT: number;
  TABLET_LAND: number;
  DESKTOP: number;
  EXTRADESKTOP: number;
}

export const BREAKPOINTS: IBreakpoint = {
  MOBILE: 360,
  TABLET_PORT: 670,
  TABLET_LAND: 960,
  DESKTOP: 1380,
  EXTRADESKTOP: 1600,
};
export enum DEVICES {
  MOBILE = "MOBILE",
  TABLET_PORT = "TABLET_PORT",
  TABLET_LAND = "TABLET_LAND",
  DESKTOP = "DESKTOP",
  EXTRA_DESKTOP = "EXTRAD_ESKTOP",
}

export const useDevices = () => {
  const [device, setDevice] = useState<string | undefined>();

  const breakValuesArr: number[] = Object.values(BREAKPOINTS).map((el) => +el);
  const breakKeysArr: string[] = Object.keys(DEVICES);

  const onResize = useCallback(() => {
    for (let i = breakKeysArr.length; i--; i > 0) {
      if (window.innerWidth >= breakValuesArr[i]) {
        if (device !== breakKeysArr[i]) {
          setDevice(breakKeysArr[i]);
        }
        break;
      }
    }
  }, [device]);

  useEffect(() => {
    onResize();
    addEventListener("resize", onResize);

    return () => removeEventListener("resize", onResize);
  }, []);

  return device;
};
