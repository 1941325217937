import React from "react";
import { Typography } from "antd";
import TableComponent from "../../../../components/TableComponent/TableComponent";
import { tableAgreeColumns, tableAgreeData } from "./tableGenerator";
const { Title } = Typography;

const PricingSchedule = () => {
  return (
    <>
      <Title>Pricing Schedule</Title>
      <div className={"bizibuz-policies-lists"}>
        {/* <div className={"bsl-3 tsl-3"}>
          <b>What is the cost of joining the Bizibuz platform?</b>
        </div> */}
        <div className={"bsl-3 tsl-3"}>
          Bizibuz provides a complete operational, marketing and strategy
          solution for your Kidzbiz. There is also a listing fee package and
          other opportunities for optimal reach and awareness building. Please
          contact the Bizibuz team for more information.
        </div>
        <div className={"bsl-3 tsl-3"}>
          <TableComponent
            propsTableColumns={tableAgreeColumns()}
            propsTableData={tableAgreeData()}
            pagination={false}
          />
          <div className={"tsl-3"}>
            {/* * Gross transaction volume is defined as the aggregate gross customer spend.
            <div>
              ** Non profit organizations are entitled to the above rate on a permanent basis.
            </div>
            <br /> */}
            <div>
              Bizibuz reserves the right to change pricing rates at any time
              without notice. The fixed introductory fee is charged upfront and
              is not conditional upon attendance by the customer.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PricingSchedule;
