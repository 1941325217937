import { Layout, Menu } from "antd";
import { inject, observer } from "mobx-react";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  useCurrentUser,
  useCurrentVerify,
} from "scripts/custom-hooks/useCurrentUser";
import logoHeader from "../../../assets/logo-header.svg";
import logo from "../../../assets/logo.svg";
import LingualBlock from "../../../components/Layout/LingualBlock";
import { UpScrollArrow } from "../../../components/UpScrollArrow";
import { useIsCollapsed } from "../../../scripts/custom-hooks/useCollapseByWidth";
import CookiePolicy from "../CookiePolicy";
import KidzbizAgreement from "../KidzbizAgreement";
import PricingSchedule from "../PricingSchedule";
import PrivacyPolicy from "../PrivacyPolicy";
import TermsAndConditions from "../TermsAndConditions";
import "./index.less";

const { Header, Content, Footer, Sider } = Layout;

enum ITEMS {
  TERMS = "terms-and-conditions",
  PRIVACYPOLICY = "privacy-policy",
  COOKIEPOLICY = "cookie-policy",
  KIDZBIZAGREEMENT = "kidzbiz-agreement",
  PRICINGSCHEDULE = "pricing-schedule",
}

interface IPoliciesProps extends RouteComponentProps {}

const PolicyLayout = inject(
  "languageStore",
  "userStore",
)(
  observer((props: IPoliciesProps) => {
    const { history, location, match } = props;
    const { t } = useTranslation("base");
    const isVerified = useCurrentVerify();

    const collapsedByWidth = useIsCollapsed();
    const [collapsed, setCollapsed] = useState(collapsedByWidth);

    // @ts-ignore
    const item = match?.params?.item || ITEMS.TERMS;

    const menuClickHandler = useCallback(() => {
      setCollapsed(!collapsed);
    }, [collapsed]);

    const menuButton = () => {
      return (
        <div className={"menu_button"} onClick={menuClickHandler}>
          <i className="ri-menu-2-fill" />
          <div className="text">Menu</div>
        </div>
      );
    };
    const [page, setPage] = useState<ITEMS>(item);

    useEffect(() => {
      if (collapsed !== collapsedByWidth) {
        setCollapsed(collapsedByWidth);
      }
    }, [collapsedByWidth]);

    useEffect(() => {
      if (collapsedByWidth && !collapsed) {
        document.body.style.position = "fixed";
        document.body.style.top = `-${window.scrollY}px`;
      } else if (!collapsedByWidth || (collapsedByWidth && collapsed)) {
        document.body.style.position = "";
        document.body.style.top = "";
      }
    }, [collapsed]);

    const handleClick = (e: any) => {
      setCollapsed(collapsedByWidth);
      setPage(e.key);

      history.push("/bizibuz-policies/" + e.key);
    };

    return (
      <>
        {collapsedByWidth && !collapsed && (
          <div
            className={"layout-overlay"}
            onClick={() => setCollapsed(true)}
          />
        )}
        <Sider
          breakpoint="xl"
          className={`layout-sider ${
            !collapsedByWidth ? "pos-relative" : "pos-absolute"
          }`}
          collapsedWidth="0"
          theme={"light"}
          trigger={null}
          width="256px"
          collapsible={true}
          collapsed={collapsed}
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
          }}
        >
          <div className="sider-logo">
            <img
              src={logo}
              alt="logo"
              className={"cursor-pointer"}
              onClick={() => {
                history.push("/kidzbiz");
              }}
            />
          </div>
          <Menu
            mode="inline"
            defaultSelectedKeys={[page]}
            selectedKeys={[page]}
            onClick={handleClick}
          >
            <Menu.Item key={ITEMS.TERMS}>{t("bizBase.t&C")}</Menu.Item>
            <Menu.Item key={ITEMS.PRIVACYPOLICY}>
              {t("bizBase.pyPolicy")}
            </Menu.Item>
            <Menu.Item key={ITEMS.COOKIEPOLICY}>
              {t("bizBase.cookiePolicy")}
            </Menu.Item>
            {isVerified && (
              <Menu.Item key={ITEMS.KIDZBIZAGREEMENT}>
                {t("bizBase.agreement")}
              </Menu.Item>
            )}
            {isVerified && (
              <Menu.Item key={ITEMS.PRICINGSCHEDULE}>
                {t("bizBase.pricingSchedule")}
              </Menu.Item>
            )}
          </Menu>
        </Sider>
        <Layout>
          {/* <LingualBlock className={"bsl-4 tsl-4 sider-lang"} /> */}

          {collapsedByWidth && (
            <Header className={"policy-layout-header"}>
              <div className={`left-side ${collapsed ? "collapsed" : ""}`}>
                {collapsedByWidth && (
                  <img
                    src={logoHeader}
                    alt={"logo-header"}
                    className={"cursor-pointer"}
                    onClick={() => {
                      history.push("/kidzbiz");
                    }}
                  />
                )}
                {collapsedByWidth && menuButton()}
              </div>
            </Header>
          )}
          <Content className="layout-content layout-content-with-menu policy-layout-content">
            <div className="layout-content-bg">
              <div className="content-with-table">
                {/* {process.env.RAZZLE_ENV} */}
                {page === ITEMS.TERMS && <TermsAndConditions />}
                {page === ITEMS.PRIVACYPOLICY && <PrivacyPolicy />}
                {page === ITEMS.COOKIEPOLICY && <CookiePolicy />}
                {page === ITEMS.KIDZBIZAGREEMENT && <KidzbizAgreement />}
                {page === ITEMS.PRICINGSCHEDULE && <PricingSchedule />}
              </div>
            </div>
          </Content>
          <Footer className="layout-footer">
            {` bizibuz.com © ${new Date().getFullYear()}. All rights reserved. `}
            <UpScrollArrow />
          </Footer>
        </Layout>
      </>
    );
  }),
);

export default withRouter(PolicyLayout);
