import React, { useContext, useEffect, useState } from "react";
import ButtonLink from "../../../components/ButtonLink";
import { observer } from "mobx-react-lite";
import { bizIcons } from "../../../constants/bizConstants";
import { Input, Modal, notification, Popover } from "antd";
import TableComponent from "../../../components/TableComponent/TableComponent";
import {
  tablePayCustomersColumns,
  tablePayCustomersData,
} from "../tableGenerator";
import { inject } from "mobx-react";
import { PayCustomersFilters } from "./filters";
import {
  RouteComponentProps,
  useHistory,
  useLocation,
  withRouter,
} from "react-router-dom";
import { debounce, omit } from "lodash";
import { ProviderOrdersStore } from "scripts/mobx/providerOrdersStore";
import { toJS } from "mobx";
import { useCurrentUser } from "scripts/custom-hooks/useCurrentUser";
import { useTranslation } from "react-i18next";
import { DEVICES, useDevices } from "scripts/custom-hooks/useDevices";
import { useQueryParams } from "scripts/custom-hooks/useQueryParams";
import { FORMAT_DATE } from "constants/commons";
import moment from "moment";
import QueryString from "constants/query-string";
import { AppRoutes } from "helpers/app.routes";
import helpers from "scripts/helpers";
import { Languages, languageStoreContext } from "scripts/mobx/languageStore";
import { pageableRezo, RescheduleType } from "constants/type.interface";
import { formatPrice } from "scripts/utils/formatPrice";

const { confirm } = Modal;

interface IProps extends RouteComponentProps {
  providerOrdersStore?: ProviderOrdersStore;
  isParital?: boolean;
}

const CustomersPaymentsTab = withRouter(
  inject("providerOrdersStore")(
    observer((props: IProps) => {
      const { providerOrdersStore, isParital } = props;
      const { t } = useTranslation();
      const user = useCurrentUser();
      const device = useDevices();
      const history = useHistory();
      const location = useLocation();
      const language = useContext(languageStoreContext);
      const queryParams = useQueryParams();
      const keyword = queryParams.get("keyword") || "";
      const [isBroken, setIsBroken] = useState<boolean>(false);
      const [isLoading, setIsLoading] = useState<boolean>(false);
      const [keywordState, setKeyword] = useState<string>(keyword);
      const [customersOrdersData, setCustomersOrdersData] = useState<any>();
      const [isLoadingBal, setIsLoadingBal] = useState<boolean>(false);
      const [dataBalance, setDataBalance] = useState<any[]>([]);
      const filterModel = toJS(providerOrdersStore?.customersFilterModel);

      // @ts-ignore
      const id = props.match.params.id;

      const getCustomersOrders = (filter: any) => {
        setIsLoading(true);
        providerOrdersStore?.getCustomersOrders(filter).then((res) => {
          setCustomersOrdersData(res);
          setIsLoading(false);
        });
      };

      useEffect(() => {
        (async () => {
          getCustomersOrders(filterModel);
        })();
      }, []);

      useEffect(() => {
        if (
          device &&
          (device === DEVICES.MOBILE ||
            device === DEVICES.TABLET_PORT ||
            device === DEVICES.TABLET_LAND) &&
          !isBroken
        ) {
          setIsBroken(true);
        } else if (
          device &&
          device !== DEVICES.MOBILE &&
          device !== DEVICES.TABLET_PORT &&
          device !== DEVICES.TABLET_LAND &&
          isBroken
        ) {
          setIsBroken(false);
        }
      }, [device]);

      const onApply = (data?: any) => {
        let params = {
          ...filterModel,
          pageable: pageableRezo,
        };

        if (data?.type) {
          Object.assign(params, {
            type: data?.type,
          });
        } else {
          params = omit(params, ["type"]);
        }

        if (data?.dateFrom) {
          Object.assign(params, {
            dateFrom: moment(data?.dateFrom)?.format(FORMAT_DATE.query),
          });
        } else {
          params = omit(params, ["dateFrom"]);
        }
        if (data?.dateTo) {
          Object.assign(params, {
            dateTo: moment(data?.dateTo)?.format(FORMAT_DATE.query),
          });
        } else {
          params = omit(params, ["dateTo"]);
        }

        if (data?.satelliteIds) {
          Object.assign(params, {
            providerId: data?.satelliteIds,
          });
        } else {
          params = omit(params, ["providerId"]);
        }

        if (!params?.keyword) {
          params = omit(params, ["keyword"]);
        }

        setTimeout(() => {
          getCustomersOrders(params);
        }, 200);
      };

      const onPageChangeHandler = (page?: any) => {
        getCustomersOrders({
          ...filterModel,
          pageable: {
            ...filterModel?.pageable,
            pageNumber: page - 1,
          },
        });
      };

      const searchHandler = (event: any) => {
        const value = event?.target?.value;
        setKeyword(value);
        setIsLoading(true);
        let params = {
          ...filterModel,
          pageable: {
            ...filterModel?.pageable,
            pageNumber: 0,
          },
        };
        if (!!value) {
          Object.assign(params, {
            keyword: value,
          });
        } else {
          params = omit(params, ["keyword"]);
        }
        setTimeout(() => {
          getCustomersOrders(params);
          if (value) {
            history.replace({
              pathname: location?.pathname,
              search: QueryString.stringify({
                keyword: value,
              }),
            });
          } else {
            history.replace({
              pathname: location?.pathname,
            });
          }

          setIsLoading(false);
        }, 200);
      };

      const getColumns = () => {
        const columnsTimetable = [
          {
            title: t("payments.customer_name"),
            dataIndex: "customerName",
            key: "customerName",
            width: isBroken ? undefined : 160,
            render: (text: string, record: any) => {
              return (
                <div
                  style={{ cursor: "pointer" }}
                  className={isBroken ? "mr-20" : ""}
                  onClick={() => {
                    history.push(AppRoutes.customerId(record?.customerId));
                  }}
                >
                  {text}
                </div>
              );
            },
          },
          {
            title: t("payments.credit_balance"),
            dataIndex: "credit_balance",
            key: "credit_balance",
            width: isBroken ? undefined : 160,
            render: (text: string, record: any) => {
              return <>{formatPrice(record?.credit)}</>;
            },
          },
        ];

        if (user?.isLinked) {
          const elm: any = {
            title: <div>{t("menu.kidzbiz")}</div>,
            key: "providerName",
            dataIndex: "providerName",
            width: isBroken ? undefined : 120,
            render: (text: any) =>
              text && <div className="ellipsis">{text}</div>,
          };
          columnsTimetable.unshift(elm);
        }
        return columnsTimetable;
      };

      const exportCustomerFile = (data?: any[]) => {
        if (!!data) {
          const dataCSV = data?.map((el: any) => {
            const params = {};
            el?.children?.forEach((c: any, index: number) => {
              Object.assign(params, {
                [t("exports.child_name", {
                  index: index + 1,
                })]: c,
              });
            });
            return {
              [t("exports.transaction_id")]: el?.id,
              [t("exports.transaction_type")]: t(
                "payments.methods." + el?.type,
              ),
              [t("exports.course_name")]: el?.courseName,
              [t("exports.course_code")]: el?.courseCode,
              [t("exports.course_location")]: el?.courseLocation,
              [t("exports.customer_name")]: el?.customerName,
              [t("exports.customer_phone")]: el?.customerPhone,
              [t("exports.customer_email")]: el?.customerEmail,
              [t("exports.booking_request_timestamp")]: helpers.formatDateTime(
                el?.date,
              ),
              [t("exports.amount")]: formatPrice(
                (el?.cashAmount || 0) + (el?.creditAmount || 0),
              ),
              [t("exports.credit_issued")]:
                [RescheduleType.CANCELLATION].includes(el?.type) &&
                el?.creditAmount > 0
                  ? formatPrice(el?.creditAmount)
                  : "",
              [t("exports.refund_issued")]:
                [RescheduleType.CANCELLATION].includes(el?.type) &&
                el?.cashAmount > 0
                  ? formatPrice(el?.cashAmount)
                  : "",
              [t("exports.credit_used")]:
                ![RescheduleType.CANCELLATION].includes(el?.type) &&
                el?.creditAmount > 0
                  ? formatPrice(el?.creditAmount)
                  : "",
              [t("exports.no_classes_purchased")]: el?.numberOfClassPurchased,
              [t("exports.no_classes_cancelled")]: el?.numberOfClassCancelled,
              [t("exports.child_count")]: el?.children?.length,
              ...params,
            };
          });
          helpers.exportToCSV(dataCSV, "Transactions_Export");
        }
      };

      return (
        <div className={"bsl-4 tsl-4 tab-table-block"}>
          <div className={"payment-tabs-title no-title tab-table-block-head"}>
            <div className={"search"}>
              <Input
                size={"large"}
                placeholder={t("payments.placeholder_search")}
                prefix={
                  <i className={"ri-search-line mr-8 fs-20 color-grey"} />
                }
                onBlur={searchHandler}
                onPressEnter={searchHandler}
                value={keywordState}
                onChange={(e) => setKeyword(e?.target?.value)}
                allowClear
              />
            </div>

            <PayCustomersFilters onApply={onApply} userType={user?.isLinked} />
          </div>
          {!isParital && (
            <div className={"flex-align-items tsl-4 bsl-4"}>
              <ButtonLink
                className="mr-25"
                onClick={() =>
                  providerOrdersStore?.exportCustomersList().then((res) => {
                    exportCustomerFile(res);
                  })
                }
              >
                <i
                  className={bizIcons.FILEDOWNLOAD}
                  style={{ marginRight: "8px", fontSize: "20px" }}
                />
                {t("bookings.exportThisList")}
              </ButtonLink>
              {/* <Popover
                overlayClassName="classDatePopup absence"
                placement="topLeft"
                content={
                  <div style={{ padding: isBroken && user?.isLinked ? 15 : 0 }}>
                    <TableComponent
                      pagination={false}
                      propsTableColumns={getColumns()}
                      propsTableData={dataBalance}
                      broken={user?.isLinked ? isBroken : false}
                      emptyTableContent={{
                        text: t(`payments.balance_message`),
                      }}
                      isLoading={isLoadingBal}
                    />
                  </div>
                }
                trigger="click"
              >
                <ButtonLink
                  onClick={() => {
                    setIsLoadingBal(true);
                    providerOrdersStore
                      ?.createBalance()
                      .then((res) => {
                        setIsLoadingBal(false);
                        setDataBalance(res);
                      })
                      .catch(() => setIsLoadingBal(false));
                  }}
                >
                  <i
                    className={`${bizIcons.PRICE}`}
                    style={{ marginRight: "8px", fontSize: "16px" }}
                  />
                  {t("payments.credit_balance")}
                </ButtonLink>
              </Popover> */}
            </div>
          )}

          <TableComponent
            style={{ opacity: `${isLoading ? "0.4" : 1}` }}
            broken={isBroken}
            propsTableColumns={tablePayCustomersColumns(
              t,
              isBroken,
              user?.isLinked,
            )}
            emptyTableContent={{
              text: t("bookings.empty_data"),
            }}
            propsTableData={tablePayCustomersData(
              customersOrdersData?.data || undefined,
            )}
            pagination={{
              total: customersOrdersData?.totalCount,
              pageSize: customersOrdersData?.pageSize,
              page: customersOrdersData?.pageNumber + 1,
              onChange: onPageChangeHandler,
            }}
          />
        </div>
      );
    }),
  ),
);

export default CustomersPaymentsTab;
