import { HeadSeo } from "components/HeadSeo";
import { AppRoutes } from "helpers/app.routes";
import Cookies from "js-cookie";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import ButtonLink from "../../../components/ButtonLink";
import { LayoutPage } from "../../../components/Layout";
import { bizIcons } from "../../../constants/bizConstants";
import providerOrdersStore from "../../../scripts/mobx/providerOrdersStore";
import PayViewCustomerComponent from "../payViewCustomerComponent";

export const PayViewTransaction = observer(
  (props: { onBack?: () => void; id: number }) => {
    const { onBack, id } = props;
    const { t } = useTranslation();
    const [detailsData, setDetailsData] = useState<any>(undefined);

    useEffect(() => {
      providerOrdersStore?.getCustomerDetailsById(id).then((el: any) => {
        setDetailsData(el);
      });
    }, [id]);

    return (
      <>
        <div className={"back-block  bsl-4"}>
          <ButtonLink onClick={() => onBack?.()}>
            <i
              style={{ fontSize: "20px", paddingRight: "8px" }}
              className={bizIcons.ARROWLEFT}
            />
            {t("payments.back_to_transactions")}
          </ButtonLink>
        </div>
        <PayViewCustomerComponent
          t={t}
          _data={detailsData}
          exportHandler={() => {
            providerOrdersStore.exportCustomerById(detailsData?.id);
          }}
        />
      </>
    );
  },
);
