import React from "react";
import { useTranslation } from "react-i18next";
import "./index.less";

type Props = {
  msg?: string;
};
export const ErrorText = React.memo<Props>(({ msg }) => {
  const { t } = useTranslation("base");
  return msg ? (
    <p className="error-text"> {t("server_errors." + msg)}</p>
  ) : (
    <></>
  );
});
