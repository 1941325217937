import React from "react";
import { formatPrice } from "scripts/utils/formatPrice";

let bizcharts: any;
// @ts-ignore
if (process.browser) {
  // tslint:disable-next-line:no-var-requires
  bizcharts = require("bizcharts");
}

interface IProps {
  colors?: string[];
  data?: any;
  autoFit?: boolean;
}

const data = [
  {
    month: "Jan",
    city: "Tokyo",
    daytemperature: 17314,

    temperature: 7313,
  },
  {
    month: "Jan",
    city: "London",
    daytemperature: 12414,

    temperature: 3121.9,
  },
  {
    month: "Feb",
    city: "Tokyo",
    daytemperature: 14214,

    temperature: 6414.9,
  },
  {
    month: "Feb",
    city: "London",
    daytemperature: 7142,

    temperature: 4145.2,
  },
  {
    month: "Mar",
    city: "Tokyo",
    daytemperature: 10142,

    temperature: 9144.5,
  },
  {
    month: "Mar",
    city: "London",
    daytemperature: 14456,

    temperature: 5267.7,
  },
  {
    month: "Apr",
    city: "Tokyo",
    daytemperature: 27795,

    temperature: 14657.5,
  },
  {
    month: "Apr",
    city: "London",
    daytemperature: 17512,

    temperature: 8512.5,
  },
  {
    month: "May",
    city: "Tokyo",
    daytemperature: 27512,

    temperature: 18512.4,
  },
  {
    month: "May",
    city: "London",
    daytemperature: 12512,

    temperature: 11512.9,
  },
  {
    month: "Jun",
    city: "Tokyo",
    daytemperature: 22512,

    temperature: 21512.5,
  },
  {
    month: "Jun",
    city: "London",
    daytemperature: 27512,

    temperature: 15512.2,
  },
  {
    month: "Jul",
    city: "Tokyo",
    daytemperature: 37512,

    temperature: 25512.2,
  },
  {
    month: "Jul",
    city: "London",
    daytemperature: 17512,

    temperature: 17512,
  },
  {
    month: "Aug",
    city: "Tokyo",
    daytemperature: 30512,

    temperature: 26512.5,
  },
  {
    month: "Aug",
    city: "London",
    daytemperature: 17512,

    temperature: 16512.6,
  },
  {
    month: "Sep",
    city: "Tokyo",
    daytemperature: 27,

    temperature: 23.3,
  },
  {
    month: "Sep",
    city: "London",
    daytemperature: 17512,

    temperature: 14512.2,
  },
  {
    month: "Oct",
    city: "Tokyo",
    daytemperature: 27512,

    temperature: 18512.3,
  },
  {
    month: "Oct",
    city: "London",
    daytemperature: 17512,

    temperature: 10512.3,
  },
  {
    month: "Nov",
    city: "Tokyo",
    daytemperature: 15512,

    temperature: 13512.9,
  },
  {
    month: "Nov",
    city: "London",
    daytemperature: 19512,

    temperature: 6512.6,
  },
  {
    month: "Dec",
    city: "Tokyo",
    daytemperature: 11512,

    temperature: 9512.6,
  },
  {
    month: "Dec",
    city: "London",
    daytemperature: 10512,

    temperature: 4512.8,
  },
];

// const scale = {
//   temperature: { min: 0, tickCount: 10, max: 50000 },
//   daytemperature: { min: 0, tickCount: 10, max: 50000 },
//   city: {
//     formatter: (v: string | number) => {
//       return {
//         London: "London",
//         Tokyo: "Tokyo",
//       }[v];
//     },
//   },
// };

const CustomersAndRevenueSummaryChart = (props: IProps) => {
  const { data } = props;

  const chartProps =
    props.autoFit === true
      ? {
          autoFit: true,
          key: "fit",
        }
      : {
          key: "no-fit",
          width: 600,
          forceFit: true,
        };

  return (
    <div className={"chart-wrapper"}>
      {/*// @ts-ignore*/}
      {process.browser && (
        <bizcharts.Chart
          {...chartProps}
          height={400}
          padding="auto"
          data={data}
          // scale={scale}
          autoFit={true}
        >
          <bizcharts.Tooltip shared={true} />
          <bizcharts.Interval
            position="month*organizationRevenue"
            tooltip={[
              "month*type*organizationRevenue",
              (month: any, type: string, organizationRevenue: any) => {
                return {
                  name:
                    type === "recurring"
                      ? "Kidzbiz revenue"
                      : "Recurring Kidzbiz revenue",
                  value: formatPrice(organizationRevenue),
                };
              },
            ]}
            // label={[
            //   "temperature",
            //   {
            //     position: "middle",
            //     offset: 0,
            //     // style: { fill: "#fff" },
            //     // layout: { type: "limit-in-shape" },
            //   },
            // ]}
            adjust={[
              {
                type: "stack",
              },
            ]}
            color={[
              "type",
              (xVal: string) => {
                if (xVal === "recurring") {
                  return "#5D7092";
                }
                return "#6DC8EC";
              },
            ]}
          />

          <bizcharts.Line
            position="month*numberOfCustomers"
            color="type"
            tooltip={[
              "month*type*numberOfCustomers",
              (month: any, type: string, numberOfCustomers: any) => {
                return {
                  name:
                    type === "recurring"
                      ? "Number of customers"
                      : "Number of recurring customers",
                  value: numberOfCustomers,
                };
              },
            ]}
          />
          <bizcharts.Point
            position="month*numberOfCustomers"
            color="type"
            shape="circle"
          />
          <bizcharts.Legend visible={false} />
        </bizcharts.Chart>
      )}
    </div>
  );
};

export default CustomersAndRevenueSummaryChart;
