import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { StripeElementChangeEvent, loadStripe } from "@stripe/stripe-js";
import ButtonLink from "components/ButtonLink";
import StepsBizi from "components/StepsBizi";
import { AppRoutes } from "helpers/app.routes";
import i18n from "i18n";
import { observer } from "mobx-react-lite";
import React, { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useHistory, withRouter } from "react-router-dom";
import { userStoreContext } from "scripts/mobx/userStore";

import "./index.less";
import { Button, Typography, notification } from "antd";
import { postPaymentMethod } from "scripts/apis/payment";
import helpers from "scripts/helpers";

const stripeOptions = {
  style: {
    base: {
      fontSize: "14px",
      color: "#424770",
      letterSpacing: "0.025em",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#9e2146",
    },
  },
};

const stripeHongKongPromise = loadStripe(
  process.env.RAZZLE_STRIPE_KEY_HONG_KONG ?? "",
);
const stripeSingaporePromise = loadStripe(
  process.env.RAZZLE_STRIPE_KEY_SINGAPORE ?? "",
);

interface IStripeFormElements extends HTMLFormControlsCollection {
  name: HTMLInputElement;
}

interface IStripeForm extends HTMLFormElement {
  readonly elements: IStripeFormElements;
}

const Step5Form = observer(({ nextStep }: any) => {
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();

  const nameInputRef = useRef<HTMLInputElement>(null);

  const [cardNumberCheck, setCardNumberCheck] = useState<boolean>(true);
  const [cardExpiryCheck, setCardExpiryCheck] = useState<boolean>(true);
  const [cardCvcCheck, setCardCvcCheck] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  const userStore = useContext(userStoreContext);

  const { t } = useTranslation();

  const handleSubmit = async (e: React.FormEvent<IStripeForm>) => {
    e.preventDefault();

    if (!stripe) return;
    const cardElement = elements?.getElement(CardNumberElement);
    const cardExpiryElement = elements?.getElement(CardExpiryElement);
    const cardCVCElement = elements?.getElement(CardCvcElement);

    if (!cardElement) return;

    const target = e.currentTarget.elements;
    if (!target?.name?.value) return;

    try {
      setLoading(true);

      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: {
          name: target.name.value,
        },
      });

      if (error) {
        notification.error({
          message: error?.message ?? t("server_errors." + "Bad Request"),
        });
      }

      if (paymentMethod) {
        await postPaymentMethod(
          paymentMethod.id,
          userStore.isSGProvider ? "Singapore" : "Hong Kong",
        );

        await userStore.getPaymentMethods();

        notification.success({
          message: t("payments.add_payment_successful", {
            defaultValue: "Your payment method has been added successfully!",
          }),
        });
      }

      // clear input value
      cardElement.clear();
      cardCVCElement?.clear();
      cardExpiryElement?.clear();
      if (nameInputRef.current) nameInputRef.current.value = "";

      // for redirecting user to stripe page after this step
      userStore
        ?.getStripeAccountOnboard()
        .then((res) => {
          if (res?.data) {
            window.location.href = res?.data;
          }
        })
        .catch((e: any) =>
          helpers?.throwErrors(t, [], e?.data?.error, e?.data?.message),
        );

      nextStep();
    } catch (error: any) {
      notification.error({
        message: error?.data?.message ?? t("server_errors." + "Bad Request"),
      });
    } finally {
      setLoading(false);
    }
  };

  const onChange = (element: StripeElementChangeEvent) => {
    if (element?.elementType === "cardNumber") {
      setCardNumberCheck(!element?.complete);
    }
    if (element?.elementType === "cardExpiry") {
      setCardExpiryCheck(!element?.complete);
    }
    if (element?.elementType === "cardCvc") {
      setCardCvcCheck(!element?.complete);
    }
  };

  const handleOpenPricingFAQs = () => {
    history.push(AppRoutes.faqDetail("pricing1"));
  };

  return (
    <form onSubmit={handleSubmit} className="form biz-payment">
      <label>
        {t("payment.cardInformation", {
          defaultValue: "Card Information",
        })}
      </label>
      <div className="card-item card-number">
        <div className="card_input">
          <CardNumberElement options={stripeOptions} onChange={onChange} />
        </div>
      </div>
      <div className="card-item w-50 card-expiry">
        <div className="card_input">
          <CardExpiryElement options={stripeOptions} onChange={onChange} />
        </div>
      </div>
      <div className="card-item w-50 card-cvc">
        <div className="card_input">
          <CardCvcElement options={stripeOptions} onChange={onChange} />
        </div>
      </div>

      <div className="card-item">
        <label>
          {t("payment.nameOnCard", {
            defaultValue: "Name on card",
          })}
          <div className="card_input_name">
            <input name="name" ref={nameInputRef} />
          </div>
        </label>
      </div>
      <p className="payment_disclaimer">
        {t("payment.payment_disclaimer", {
          defaultValue:
            "Your credit card information is handled securely and directly by Stripe",
        })}
      </p>

      <div className="auth-header bsl-4">
        <Typography.Title level={3} className={"auth-header_title"}>
          {t("step5.notice", {
            defaultValue: "Why am I being asked to upload a credit card?",
          })}
        </Typography.Title>
      </div>

      <ButtonLink onClick={handleOpenPricingFAQs} className="bsl-4">
        {t("courses.clickToGoToPricingFAQs", {
          defaultValue: "Click to go to pricing FAQs",
        })}
      </ButtonLink>

      <Button
        htmlType="submit"
        type="primary"
        className="fs-16 tsl-4 full-width"
        loading={loading}
        disabled={Boolean(cardNumberCheck || cardExpiryCheck || cardCvcCheck)}
        icon={
          <div className={"flex-center"}>
            {t("bizBase.submit", {
              defaultValue: "Submit",
            })}
            <i className="ri-arrow-right-line ml-8 fs-20" />
          </div>
        }
      />
    </form>
  );
});

interface IPropsWrapper extends RouteComponentProps {
  nextStep: () => void;
}

export const Step5FormWrapper = withRouter(
  observer((props: IPropsWrapper) => {
    const { nextStep, history } = props;
    const { t } = useTranslation("base");

    const userStore = useContext(userStoreContext);

    return (
      <Elements
        key={i18n.language}
        stripe={
          userStore.isSGProvider
            ? stripeSingaporePromise
            : stripeHongKongPromise
        }
      >
        <div className="auth">
          <div className="content-wrapper">
            <div className="auth-header bsl-4">
              <div>
                <span className={"auth-header_title"}>
                  {t("signUp.step5.title", {
                    defaultValue: "Payment method",
                  })}
                </span>
              </div>
            </div>
            <div className="auth-steps bsl-4">
              <StepsBizi total={4} active={4} />
            </div>

            <div className="auth-form">
              <Step5Form nextStep={nextStep} history={history} />
            </div>
          </div>
        </div>
      </Elements>
    );
  }),
);
