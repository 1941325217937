import { NotificationPlatforms } from "constants/type.interface";

export enum NotiTriggers {
  ACCOUNT_STATUS = "ACCOUNT_STATUS",
  BOOKING_REQUESTS = "BOOKING_REQUESTS",
  NEW_BOOKING_REQUEST = "NEW_BOOKING_REQUEST",
  PENDING_BOOKING_REQUEST = "PENDING_BOOKING_REQUEST",
  BOOKING_CANCELLATIONS = "BOOKING_CANCELLATIONS",
  COURSE_CANCELLATIONS = "COURSE_CANCELLATIONS",
  REVIEW_NOTIFICATIONS = "REVIEW_NOTIFICATIONS",
  EMPLOYEE_ACCOUNT_STATUS = "EMPLOYEE_ACCOUNT_STATUS",
  LINKED_ACCOUNT_STATUS = "LINKED_ACCOUNT_STATUS",
}

export const NotiTriggerList = [
  NotiTriggers.ACCOUNT_STATUS,
  NotiTriggers.BOOKING_REQUESTS,
  NotiTriggers.BOOKING_CANCELLATIONS,
  NotiTriggers.COURSE_CANCELLATIONS,
  NotiTriggers.REVIEW_NOTIFICATIONS,
  NotiTriggers.EMPLOYEE_ACCOUNT_STATUS,
  NotiTriggers.LINKED_ACCOUNT_STATUS,
];
export const NotiPlatformList = [
  NotificationPlatforms.IN_APP,
  NotificationPlatforms.EMAIL,
  NotificationPlatforms.SMS,
];
export const SMS_BOOKINGS = [
  NotiTriggers.BOOKING_REQUESTS,
  NotiTriggers.NEW_BOOKING_REQUEST,
  NotiTriggers.PENDING_BOOKING_REQUEST,
];
