import { Divider, message, Modal, notification, Tabs, Typography } from "antd";
import TableComponent from "components/TableComponent/TableComponent";
import { ViewMoreBlock } from "components/viewMoreBlock";
import { AppRoutes } from "helpers/app.routes";
import keycloak from "helpers/keycloak";
import requestApi from "helpers/requestApi";
import Cookies from "js-cookie";
import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, withRouter } from "react-router-dom";
import {
  useCurrentLocation,
  useCurrentUser,
  useCurrentVerify,
  useEmployees,
  usePolicies,
  userBlocked,
  userLinkedAccount,
  userMasterSummary,
  useRoles,
  useType,
} from "scripts/custom-hooks/useCurrentUser";
import { useQueryParams } from "scripts/custom-hooks/useQueryParams";
import helpers from "scripts/helpers";
import employeeStore from "scripts/mobx/employeeStore";
import linkedAccountStore from "scripts/mobx/linkedAccountStore";
import locationsStore from "scripts/mobx/locationStore";
import userStore, { Roles, UserType } from "scripts/mobx/userStore";
import ButtonLink from "../../components/ButtonLink";
import { LayoutPage } from "../../components/Layout";
import { Link } from "../../components/Link";
import { bizIcons } from "../../constants/bizConstants";
import EditInfo from "./editInfo";
import EditKidzBizDetails from "./editKidzBizDetails";
import AddEmployees from "./employees/addEmployees";
import EditEmployeesDetail from "./employees/editEmployees";
import { SatellitesFilters } from "./filters";
import "./index.less";
import AddLinkedKidzbiz from "./linkedKidzbiz/addLinkedKidzbiz";
import EditLinkedKidzbizDetail from "./linkedKidzbiz/editLinkedKidzbizDetail";
import AddLocations from "./locations/addLocation";
import EditLocations from "./locations/editLocation";
import {
  tableEmployeesColumns,
  tableInvitationsLinkedColumns,
  tableLocationsColumns,
  tableOrgDetailsFundsColumns,
  tableOrgDetailsFundsData,
  tableOrgDetailsTitleColumns,
  tableOrgDetailsTitleData,
  tableOrgLinkedColumns,
} from "./tableGenerator";
const { confirm } = Modal;

const locationsData = require("./fakeData.json");

const { TabPane } = Tabs;
const { Title } = Typography;

const KidzBiz = inject(
  "userStore",
  "locationsStore",
  "employeeStore",
  "linkedAccountStore",
)(
  observer((props: any) => {
    const { history } = props;
    const { t } = useTranslation("base");
    const user = useCurrentUser();
    const isVerified = useCurrentVerify();
    const employees = useEmployees();
    const roles = useRoles();
    const userType = useType();
    const isBlocked = userBlocked();
    const location = useLocation();
    const params = useQueryParams();
    const [tabKey, setTabKey] = useState<any>("1");
    const [editDetails, setEditDetails] = useState(false);
    const [editInfo, setEditInfo] = useState(false);
    const [addLinkedKbiz, setAddLinkedKbiz] = useState(false);
    const [editLinkedKbiz, setEditLinkedKbiz] = useState(false);
    const [linkedKbizData, setLinkedKbizData] = useState<any>();
    const [editEmployees, setEditEmployees] = useState(false);
    const [employeesData, setEmployeesData] = useState<any>();

    const [addEmployees, setAddEmployees] = useState(false);
    const [addLocation, setAddLocation] = useState(false);
    const [editLocation, setEditLocation] = useState(false);
    const [editLocationIndex, setEditLocationIndex] = useState<any>();
    const [isLoading, setIsLoading] = useState(false);
    const [locationData, setLocationData] = useState<any>();
    const firstName = user?.userAttributes?.firstName || "";
    const lastName = user?.userAttributes?.lastName || "";

    const reschedulingOutcome = toJS(userStore?.reschedulingOutcomeData);

    const fullName = firstName + " " + lastName;

    useEffect(() => {
      if (params.get("action") === "linked_account") {
        setTabKey("4");
      }
    }, [location]);

    // CALL API GET LOCATION
    useEffect(() => {
      locationsStore.getLocation();
      userStore.getProviderCurrentPolicies();
      employeeStore.getEmployee();
      userStore?.getReschedulingOutcome();
      if (userType === UserType.MASTER) {
        linkedAccountStore.getLinkedAccount().then(() => {
          setIsLoading(true);
        });
      }
      if (userType === UserType.SATELLITE) {
        linkedAccountStore.getMasterSummary();
      }
    }, []);

    //use Policies
    const policies = usePolicies();
    const policiesText = policies?.filter((text: any) => {
      return text?.contentType === "TEXT";
    });
    const policiesUrl = policies?.filter((url: any) => {
      return url?.contentType === "URL";
    });

    const locationCurent = useCurrentLocation(false);

    const locations = useCurrentLocation(true);
    const onBack = () => {
      setEditDetails(false);
      setEditInfo(false);
      setAddLinkedKbiz(false);
      setEditLocation(false);
      setAddEmployees(false);
    };

    const onAddLocationData = async (data: any) => {
      const addData = [
        ...locationCurent,
        ...data,
        // stateProvinceId: data.stateProvinceId,
      ];
      locationsStore
        .putLocation(addData)
        .then(() => {
          message.success(
            t("messages.location_successfully_created", {
              defaultValue: "Location successfully created",
            }),
          );
        })
        .catch((error) => {
          helpers.throwErrors(
            t,
            [],
            error?.data?.error,
            error?.data?.message || error?.message,
          );
        })
        .finally(() => {
          setTimeout(() => setAddLocation(false), 400);
        });
    };

    const onEditLocationData = async (data: any) => {
      const _temp = [...locationCurent];
      const findIndex = _temp?.findIndex(
        (el) => Number(el.id) === Number(data.id),
      );
      if (findIndex >= 0) {
        _temp[findIndex] = data;
        locationsStore
          .putLocation(_temp)
          .then(() => {
            message.success(
              t("messages.location_successfully_updated", {
                defaultValue: "Location successfully updated",
              }),
            );
            setTimeout(() => setEditLocation(false), 400);
          })
          .catch((error) => {
            helpers.throwErrors(
              t,
              [],
              error?.data?.error,
              error?.data?.message,
            );
          });
      }
    };

    const onDeleteLocationData = async () => {
      const _temp = [...locationCurent]?.filter(
        (el) => Number(el?.id) !== Number(editLocationIndex?.id),
      );
      locationsStore
        .putLocation(_temp)
        .then(() => {
          message.success(
            t("messages.location_successfully_deleted", {
              defaultValue: "Location successfully deleted",
            }),
          );
          setTimeout(() => setEditLocation(false), 400);
        })
        .catch((error) => {
          helpers.throwErrors(t, [], error?.data?.error, error?.data?.message);
        });
    };

    const onClickEditLocationData = (data: any) => {
      const location = {
        ...data.record,
        // stateProvinceId: data.record.stateProvinceId,
      };
      setLocationData(location);
      setEditLocation(true);
      setEditLocationIndex(data?.record);
    };

    const onClickEditLinkedAccout = (data: any) => {
      const account = {
        ...data.record,
      };
      if (userType === UserType.MASTER) {
        linkedAccountStore
          .getSatelliteSummary(data?.record?.id)
          .then((res: any) => {
            setLinkedKbizData(res);
            setEditLinkedKbiz(true);
          });
      } else {
        setLinkedKbizData(account);
        setEditLinkedKbiz(true);
      }
    };

    //ACTION EMPLOYEES
    const [employeeIndex, setEmployeeIndex] = useState<number>(0);
    const onClickEditEmployees = (data: any) => {
      const eployee = {
        ...data.record,
      };
      setEmployeesData(eployee);
      setEditEmployees(true);
      setEmployeeIndex(data?.index);
    };

    const onClickDeactivate = (data: any) => {
      confirm({
        title: t("employee.confirmDeactivate"),
        content: ``,
        autoFocusButton: null,
        icon: "",
        okText: t("bizBase.yes"),
        cancelText: t("bizBase.no"),
        onOk() {
          employeeStore
            .deleteEmployee(data?.record?.email)
            .then(() => {
              message.success(t("messages.employee_deactivate"));
            })
            .catch((error) => {
              helpers.throwErrors(t, [], error?.data?.error, "");
            });
        },
      });
    };

    const onEditDetails = async (_data: any) => {
      setEditDetails(false);
      setEditInfo(false);
    };

    const onAddLinkedAccount = async (_data?: any): Promise<void> => {
      await linkedAccountStore.getLinkedAccount();
      await setAddLinkedKbiz(false);
    };

    // LINK ACCOUNTS FILTER
    const dataLinkAccount =
      userType === UserType.MASTER ? userLinkedAccount() : userMasterSummary();
    const [dataLinkAccountFilter, setDataLinkAccountFilter] = useState<any>();
    const [filter, setFilter] = useState<boolean>(false);

    useEffect(() => {
      setDataLinkAccountFilter(dataLinkAccount?.linkedProviders);
    }, [isLoading, dataLinkAccount]);

    const updateFilter = (data: any) => {
      const applyFilter = dataLinkAccount?.linkedProviders?.filter(
        (obj: any) => {
          return Object.entries(data).every(([prop, find]: any) =>
            find.includes(obj?.[prop]),
          );
        },
      );

      if (data?.status || data?.suburb) {
        setDataLinkAccountFilter(applyFilter);
        setFilter(true);
      } else {
        setDataLinkAccountFilter(dataLinkAccount?.linkedProviders);
        setFilter(false);
      }
      setIsLoading(true);
    };

    const locationEditComponent = (data: any, index: number) => (
      <>
        {!isBlocked && roles !== Roles.PARTIAL && (
          <ButtonLink onClick={() => onClickEditLocationData(data)}>
            {t("kidzbiz.edit")}
            <i
              className={bizIcons.ARROWRIGHT}
              style={{ marginLeft: "8px", fontSize: "16px" }}
            />
          </ButtonLink>
        )}
      </>
    );

    const linkedAccoutEditComponent = (data: any) => (
      <ButtonLink onClick={() => onClickEditLinkedAccout(data)}>
        {t("bizBase.view")}
        <i
          className={bizIcons.ARROWRIGHT}
          style={{ marginLeft: "8px", fontSize: "16px" }}
        />
      </ButtonLink>
    );

    const onClickReSendLinkedAccout = (data: any) => {
      linkedAccountStore
        .sendInvitation(data?.linkedProviders?.email)
        .then(() => {
          message.success(t("messages.linkedAccount_resend"));
        })
        .catch((error) => {
          if (
            error?.data?.error ===
            "com.bizibuz.core.service.user.EmailInUsedException"
          ) {
            notification.error({
              message: t(
                "server_errors.com.bizibuz.core.service.user.EmailInUsedException",
                { email: data?.linkedProviders?.emai },
              ),
            });
          } else {
            notification.error({ message: error?.data?.message });
          }
        });
    };

    const linkedAccoutReSendComponent = (data: any) => (
      <ButtonLink onClick={() => onClickReSendLinkedAccout(data)}>
        {t("bizBase.resend", { defaultValue: "Resend" })}
        <i
          className={bizIcons.ARROWRIGHT}
          style={{ marginLeft: "8px", fontSize: "16px" }}
        />
      </ButtonLink>
    );

    const employeesEditComponent = (data: any) =>
      !isBlocked ? (
        <ButtonLink
          onClick={() => onClickEditEmployees(data)}
          style={{ marginRight: 20 }}
        >
          {t("kidzbiz.edit")}
          <i
            className={bizIcons.ARROWRIGHT}
            style={{ marginLeft: "8px", fontSize: "16px" }}
          />
        </ButtonLink>
      ) : (
        <div />
      );
    const employeesDeleteComponent = (data: any) =>
      !isBlocked ? (
        <ButtonLink onClick={() => onClickDeactivate(data)}>
          {t("bizBase.deactivate")}
        </ButtonLink>
      ) : (
        <div />
      );

    const VerifyStatusContent = () => {
      return (
        <div className={"flex"} style={{ height: "22px" }}>
          <>
            {user?.providerAttributes?.status == "VERIFIED" && (
              <>
                <div className={"mr-16 green fs-14 flex-center"}>
                  <i className={"fs-20 mr-4 ri-checkbox-circle-line"} />
                  {t("filters.verified")}
                </div>
              </>
            )}
            {user?.providerAttributes?.status == "BLOCKED" && (
              <>
                <div className={"mr-16 red fs-14 flex-center"}>
                  <i className={"fs-20 mr-4 ri-information-line"} />
                  {t("filters.blocked")}
                </div>
              </>
            )}

            {user?.providerAttributes?.status == "REJECTED" && (
              <>
                <div className={"mr-16 red fs-14 flex-center"}>
                  <i className={"fs-20 mr-4 ri-information-line"} />
                  {t("filters.rejected")}
                </div>
              </>
            )}

            {user?.providerAttributes?.status == "UNVERIFIED_STRIPE" && (
              <>
                <div
                  className={"mr-16 fs-14 flex-center"}
                  style={{ color: "#1273eb" }}
                >
                  <i className={"fs-20 mr-4 ri-checkbox-circle-line"} />
                  {t("filters.pendingStripe")}
                </div>
              </>
            )}

            {user?.providerAttributes?.status == "UNVERIFIED_BIZIBUZ" && (
              <>
                <div
                  className={"mr-16 fs-14 flex-center"}
                  style={{ color: "#1273eb" }}
                >
                  <i className={"fs-20 mr-4 ri-checkbox-circle-line"} />
                  {t("filters.pendingVerification")}
                </div>
              </>
            )}
          </>
        </div>
      );
    };

    //TAB INDEX
    const onTabClick = (key: any) => {
      setTabKey(key);
    };

    return (
      <LayoutPage defMenuItem={"kidzbiz"}>
        {editDetails ? (
          <EditKidzBizDetails
            data={user}
            onBack={onBack}
            onEdit={onEditDetails}
          />
        ) : editInfo ? (
          <EditInfo
            info={user}
            policiesText={policiesText}
            policiesUrl={policiesUrl}
            onBack={onBack}
            onEdit={onEditDetails}
            reschedulingOutcome={reschedulingOutcome}
          />
        ) : addLinkedKbiz ? (
          <AddLinkedKidzbiz onBack={onBack} onAdd={onAddLinkedAccount} />
        ) : editLinkedKbiz ? (
          <EditLinkedKidzbizDetail
            // index={1}
            data={linkedKbizData}
            takeRate={user?.businessUnitAttributes?.takeRate}
            onBack={() => setEditLinkedKbiz(false)}
          />
        ) : addEmployees ? (
          <AddEmployees onBack={onBack} />
        ) : editEmployees ? (
          <EditEmployeesDetail
            index={employeeIndex}
            data={employeesData}
            onBack={() => setEditEmployees(false)}
          />
        ) : addLocation ? (
          <AddLocations
            userId={user?.providerAttributes?.id}
            addLocationData={onAddLocationData}
            onBack={() => setAddLocation(false)}
          />
        ) : editLocation ? (
          <EditLocations
            data={locationData}
            editLocationData={onEditLocationData}
            deleteLocation={onDeleteLocationData}
            onBack={() => setEditLocation(false)}
          />
        ) : (
          <div className="single-course">
            <Title className={"bsl-4"} level={1}>
              <div>{t("kidzbiz.kidzbiz")}</div>
            </Title>
            <div className="biz-tabs-title bsl-4">
              <div className={"avatar"}>
                <div
                  className="avatar-img"
                  style={{
                    backgroundImage: `url(${
                      user?.providerAttributes?.logoImageUrl?.match(
                        /data:image\/(.*);base64,/,
                      )
                        ? ""
                        : user?.providerAttributes?.logoImageUrl
                    })`,
                  }}
                />
              </div>
              <div>
                <div>{fullName}</div>
                <Title
                  level={4}
                  className={"text-color-secondary"}
                  style={{ marginTop: "4px" }}
                >
                  {user?.providerAttributes?.legalName || ""}
                </Title>
                {VerifyStatusContent()}
              </div>
            </div>
            <div className="biz-tabs-content">
              <div>
                <Tabs activeKey={tabKey} size={"small"} onTabClick={onTabClick}>
                  <TabPane
                    tab={t("kidzbiz.summary")}
                    key="1"
                    className="biz-provider-summary-tab"
                  >
                    {!isBlocked && (
                      <ButtonLink
                        className={"bsl-4"}
                        style={{ marginTop: "8px" }}
                        onClick={() => setEditDetails(true)}
                      >
                        <i
                          className={bizIcons.EDIT}
                          style={{ marginRight: "8px", fontSize: "20px" }}
                        />
                        {t("kidzbiz.editSummary")}
                      </ButtonLink>
                    )}

                    <TableComponent
                      broken={true}
                      grid={true}
                      noSeparators={true}
                      pagination={false}
                      propsTableColumns={tableOrgDetailsTitleColumns(
                        t,
                        user?.userAttributes?.landline,
                      )}
                      propsTableData={tableOrgDetailsTitleData({
                        city: "Hong Kong",
                        mobilePhone: user?.userAttributes?.mobile,
                        landlinePhone: user?.userAttributes?.landline,
                        contactPersonFirstName: user?.userAttributes?.firstName,
                        contactPersonLastName: user?.userAttributes?.lastName,
                        email: user?.userAttributes?.email,
                        id: 1,
                      })}
                    />
                    <Divider
                      style={{ marginTop: "0px", marginBottom: "0px" }}
                    />
                    {roles !== Roles.PARTIAL && (
                      <>
                        <TableComponent
                          broken={true}
                          grid={true}
                          noSeparators={true}
                          pagination={false}
                          propsTableColumns={tableOrgDetailsFundsColumns(
                            {
                              transactionVolume:
                                user?.transactionSummary
                                  ?.transactionVolume12m || "0",
                              takeRate: user?.businessUnitAttributes?.takeRate,
                              kidsEnrolled:
                                user?.transactionSummary?.numOfKidsEnrolled ||
                                "0",
                              numberOfCourses:
                                user?.transactionSummary?.numOfCourses || "0",
                              numberOfCancellationsRefunds:
                                user?.transactionSummary
                                  ?.numOfCancellationsRefunds || "0",
                              id: 1,
                            },
                            t,
                            userType,
                          )}
                          propsTableData={tableOrgDetailsFundsData({
                            accruedPayoutMtd:
                              user?.transactionSummary?.accruedPayoutMtd || "0",
                            transactionVolume:
                              user?.transactionSummary?.transactionVolume12m ||
                              "0",
                            takeRate: user?.businessUnitAttributes?.takeRate,
                            kidsEnrolled:
                              user?.transactionSummary?.numOfKidsEnrolled ||
                              "0",
                            numberOfCourses:
                              user?.transactionSummary?.numOfCourses || "0",
                            numberOfCancellationsRefunds:
                              user?.transactionSummary
                                ?.numOfCancellationsRefunds || "0",
                            id: 1,
                          })}
                        />
                      </>
                    )}
                    <div className="biz-provider-locations">
                      <div className="tsl-3" />
                      <div className="tsl-5" />
                      <div className={"flex-space-between"}>
                        <div className={"semi-bold "}>
                          {" "}
                          {t("kidzbiz.listLocations")}
                        </div>
                        {!isBlocked && roles !== Roles.PARTIAL && (
                          <div>
                            <ButtonLink onClick={() => setAddLocation(true)}>
                              <i
                                className={bizIcons.ADDCIRCLE}
                                style={{ marginRight: "8px", fontSize: "16px" }}
                              />
                              {t("kidzbiz.addNew")}
                            </ButtonLink>
                          </div>
                        )}
                      </div>
                      <Divider style={{ marginBottom: "0px" }} />

                      <TableComponent
                        broken={true}
                        pagination={false}
                        propsTableColumns={tableLocationsColumns(
                          false,
                          locationEditComponent,
                        )}
                        propsTableData={locations?.filter(
                          (lo: any) =>
                            ![0, -2].includes(Number(lo?.address?.id)) &&
                            !!lo?.address,
                        )}
                      />
                    </div>
                  </TabPane>
                  <TabPane tab={t("kidzbiz.details")} key="2">
                    {!isBlocked && roles !== Roles.PARTIAL && (
                      <ButtonLink
                        className={"bsl-4"}
                        style={{ marginTop: "8px" }}
                        onClick={() => setEditInfo(true)}
                      >
                        <i
                          className={bizIcons.EDIT}
                          style={{ marginRight: "8px", fontSize: "20px" }}
                        />
                        {t("kidzbiz.editDetails")}
                      </ButtonLink>
                    )}
                    <div className={"tsl-4"}>
                      <ViewMoreBlock
                        title={t("kidzbiz.website")}
                        contentClassName="text-color-secondary"
                      >
                        {user?.providerAttributes?.websiteUrl?.trim()}
                      </ViewMoreBlock>
                    </div>
                    <div className={"tsl-4"}>
                      <ViewMoreBlock
                        title={t("kidzbiz.organizationDescription")}
                        contentClassName="text-color-secondary"
                      >
                        {user?.providerAttributes?.description}
                      </ViewMoreBlock>
                    </div>

                    <Divider className={"tsl-4 bsl-4"} />
                    <div>
                      <h3>{t("signUp.step4.rescheduling_outcomes")}</h3>
                      <ViewMoreBlock
                        title={t("signUp.step4.course_cancellation_by_Kidzbiz")}
                        contentClassName="text-color-secondary"
                      >
                        {reschedulingOutcome?.courseCancelledByKidzbiz
                          ? t(
                              "signUp.step4." +
                                reschedulingOutcome?.courseCancelledByKidzbiz,
                            )
                          : "-"}
                      </ViewMoreBlock>
                    </div>

                    <div className={"tsl-4"}>
                      <ViewMoreBlock
                        title={t(
                          "signUp.step4.course_cancellation_by_customer",
                        )}
                        contentClassName="text-color-secondary"
                      >
                        {reschedulingOutcome?.courseCancelledByCustomer
                          ? t(
                              "signUp.step4." +
                                reschedulingOutcome?.courseCancelledByCustomer,
                            )
                          : "-"}
                      </ViewMoreBlock>
                    </div>

                    <div className={"tsl-4"}>
                      <ViewMoreBlock
                        title={t("signUp.step4.class_cancellation_by_Kidzbiz")}
                        contentClassName="text-color-secondary"
                      >
                        {reschedulingOutcome?.classCancelledByKidzbiz
                          ? t(
                              "signUp.step4." +
                                reschedulingOutcome?.classCancelledByKidzbiz,
                            )
                          : "-"}
                      </ViewMoreBlock>
                    </div>

                    <div className={"tsl-4"}>
                      <ViewMoreBlock
                        title={t(
                          "signUp.step4.compensated_absences_by_customer",
                        )}
                        contentClassName="text-color-secondary"
                      >
                        {(reschedulingOutcome?.compensatedAbsencesByCustomerPercentage ||
                          0) + "%"}
                      </ViewMoreBlock>
                    </div>
                    {reschedulingOutcome?.compensatedAbsencesByCustomerPercentage >
                      0 && (
                      <div className={"tsl-4"}>
                        <ViewMoreBlock
                          title={t(
                            "signUp.step4.compensated_for_customer_absences",
                          )}
                          contentClassName="text-color-secondary"
                        >
                          {reschedulingOutcome?.compensationForCustomerAbsence
                            ? t(
                                "signUp.step4." +
                                  reschedulingOutcome?.compensationForCustomerAbsence,
                              )
                            : "-"}
                        </ViewMoreBlock>
                      </div>
                    )}

                    <Divider className={"tsl-4"} />
                    <div className={"tsl-4"}>
                      <ViewMoreBlock
                        title={t("signUp.step4.allPurchaseConditionsPolicies")}
                        contentClassName="text-color-secondary"
                      >
                        {policiesText?.[0]?.content}
                      </ViewMoreBlock>
                    </div>
                    <Divider style={{ marginBottom: "0px" }} />
                    <div className={"tsl-4"}>
                      <ViewMoreBlock
                        title={t(
                          "signUp.step4.purchaseConditionsPoliciesPlink",
                        )}
                      >
                        {policiesUrl?.map((items: any, key: number) => {
                          return (
                            <a
                              target="_blank"
                              style={{ marginRight: 15 }}
                              className="link "
                              key={key}
                              href={items.content}
                              rel="noreferrer"
                            >
                              <span className="link-text no-select">
                                {items.name}
                              </span>
                            </a>
                          );
                        })}
                      </ViewMoreBlock>
                    </div>
                  </TabPane>

                  {(isVerified || isBlocked) && roles !== Roles.PARTIAL ? (
                    <TabPane tab={t("kidzbiz.employees")} key="3">
                      <div className={"tab-table-block"}>
                        {!isBlocked && (
                          <div
                            className={
                              "kidzbiz-linked-title tab-table-block-head no-search"
                            }
                          >
                            <div className={"title flex-align-items"}>
                              <ButtonLink
                                style={{ marginTop: "8px", marginRight: 35 }}
                                onClick={() => setAddEmployees(true)}
                              >
                                <i
                                  className={bizIcons.ADDCIRCLE}
                                  style={{
                                    marginRight: "8px",
                                    fontSize: "20px",
                                  }}
                                />
                                {t("kidzbiz.addEmployee")}
                              </ButtonLink>
                              <Link
                                to={AppRoutes.faqDetail("signup7")}
                                style={{ marginTop: "8px" }}
                                target="_blank"
                              >
                                {t("kidzbiz.goToFAQs")}
                              </Link>
                            </div>
                          </div>
                        )}
                        <TableComponent
                          emptyTableContent={{
                            text: t("messages.employee_empty"),
                          }}
                          broken={true}
                          pagination={false}
                          propsTableColumns={tableEmployeesColumns(
                            t,
                            false,
                            employeesEditComponent,
                            employeesDeleteComponent,
                          )}
                          propsTableData={employees}
                        />
                      </div>
                    </TabPane>
                  ) : (
                    ""
                  )}
                  {(isVerified || isBlocked) && roles !== Roles.PARTIAL ? (
                    <TabPane
                      tab={t("kidzbiz.linkedAccounts")}
                      key="4"
                      className="biz-linked-account"
                    >
                      <div
                        className={"tab-table-block biz-linked-account-buttons"}
                      >
                        {!isBlocked && (
                          <div
                            className={
                              "kidzbiz-linked-title tab-table-block-head no-search"
                            }
                          >
                            <div className={"title flex-align-items"}>
                              {userType === UserType.MASTER && (
                                <ButtonLink
                                  style={{ marginTop: "8px", marginRight: 35 }}
                                  onClick={() => setAddLinkedKbiz(true)}
                                >
                                  <i
                                    className={bizIcons.ADDCIRCLE}
                                    style={{
                                      marginRight: "8px",
                                      fontSize: "20px",
                                    }}
                                  />
                                  {t("kidzbiz.addLinkedAccount")}
                                </ButtonLink>
                              )}
                              <Link
                                to={AppRoutes.faqDetail("signup3")}
                                style={{ marginTop: "8px" }}
                                target="_blank"
                              >
                                {t("kidzbiz.goToFAQs")}
                              </Link>
                            </div>
                            {userType === UserType.MASTER &&
                              dataLinkAccount?.linkedProviders?.length > 0 && (
                                <SatellitesFilters onApply={updateFilter} />
                              )}
                          </div>
                        )}

                        {userType === UserType.MASTER ? (
                          <>
                            {dataLinkAccount?.linkedProviders?.length === 0 &&
                            dataLinkAccount?.invitations?.length > 0 ? (
                              ""
                            ) : (
                              <TableComponent
                                emptyTableContent={{
                                  text: t("messages.linkedAccount_empty"),
                                }}
                                broken={true}
                                pagination={false}
                                propsTableColumns={tableOrgLinkedColumns(
                                  t,
                                  userType,
                                  linkedAccoutEditComponent,
                                  linkedAccoutReSendComponent,
                                )}
                                propsTableData={dataLinkAccountFilter}
                              />
                            )}
                            {!filter &&
                              dataLinkAccount?.invitations?.length > 0 && (
                                <TableComponent
                                  broken={true}
                                  pagination={false}
                                  propsTableColumns={tableInvitationsLinkedColumns(
                                    t,
                                    userType,
                                  )}
                                  propsTableData={dataLinkAccount?.invitations}
                                />
                              )}
                          </>
                        ) : (
                          <>
                            <TableComponent
                              broken={true}
                              pagination={false}
                              propsTableColumns={tableOrgLinkedColumns(
                                t,
                                userType,
                                linkedAccoutEditComponent,
                                linkedAccoutReSendComponent,
                              )}
                              propsTableData={[dataLinkAccount]}
                            />
                          </>
                        )}
                      </div>
                    </TabPane>
                  ) : (
                    ""
                  )}
                </Tabs>
              </div>
            </div>
          </div>
        )}
      </LayoutPage>
    );
  }),
);
export default withRouter(KidzBiz);
