import React from "react";

export const tableCookiesColumns = () => {
  return [
    {
      title: "Cookies",
      dataIndex: "Cookies",
      key: "Cookies",
      width: "100px",
    },
    {
      title: "Description",
      dataIndex: "Description",
      key: "Description",
    },
  ];
};
export const tableCookiesData = () => {
  return [
    {
      Cookies: "Functionality cookies",
      Description:
        "These cookies allow us to manage the Website and ensure its proper functioning. They are essential to help you navigate around this Website and provide you with an improved surfing experience such as avoiding the necessity for repeatedly logging into the Wesbite. We also use these cookies for fraud detection and prevention purposes, and to monitor adherence by activity providers with the terms and conditions of the Kidzbiz Agreement.",
    },
    {
      Cookies: "Analytics cookies",
      Description:
        "These cookies allow us to measure and analyse how you use our Websites, apps and mobile platforms, to improve both its functionality and your experience.",
    },
    {
      Cookies: "Advertising cookies",
      Description:
        "These cookies may be used to deliver ads relevant to you. They also limit the number of times that you see an ad and help us measure the effectiveness of our marketing campaigns. We may also use the information obtained via these cookies to serve you with advertising that may be of interest to you based on your past online behaviour. We may share this information with other parties, including our partners.",
    },
    {
      Cookies: "Social media cookies",
      Description:
        "These cookies are used when you share information using a social media sharing button on the Website. The social network will record that you have done this. This information may be linked to targeting/advertising activities.",
    },
  ];
};
