import React from "react";
import "./index.less";
import { Button } from "antd";
import { ButtonProps } from "antd/lib/button/button";

export const ButtonWhite = (props: ButtonProps) => {
  return (
    <Button
      {...props}
      style={{ backgroundColor: "#fff", ...props.style }}
      className={`button-white no-select cursor-pointer ${props.className}`}
    >
      {props.children}
    </Button>
  );
};
