import { Divider, Tabs } from "antd";
import { HeadSeo } from "components/HeadSeo";
import { PAGE_SIZE } from "config";
import { FORMAT_DATE } from "constants/commons";
import { CourseStatus, pageableRezo } from "constants/type.interface";
import { omit } from "lodash";
import { toJS } from "mobx";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { ViewCustomerCourse } from "pages/courses/viewCustomerCourse";
import { PayCustomersFilters } from "pages/payments/customerPaymentsTab/filters";
import { PayViewTransaction } from "pages/payments/payViewTransaction";
import { ViewCustomerReview } from "pages/reviews/viewCustomerReview";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useCurrentUser } from "scripts/custom-hooks/useCurrentUser";
import { DEVICES, useDevices } from "scripts/custom-hooks/useDevices";
import courseStore, { pageable } from "scripts/mobx/courseStore";
import {
  customerCoursepageable,
  CustomersStore,
} from "scripts/mobx/customersStore";
import providerOrdersStore from "scripts/mobx/providerOrdersStore";
import reviewsStore, {
  pageable as pageableReview,
} from "scripts/mobx/reviewsStore";
import { userStoreContext } from "scripts/mobx/userStore";
import ButtonLink from "../../../components/ButtonLink";
import { LayoutPage } from "../../../components/Layout";
import TableComponent from "../../../components/TableComponent/TableComponent";
import { bizIcons } from "../../../constants/bizConstants";
import "./index.less";
import {
  tableCoursesData,
  tableCustomerCoursesColumns,
  tableCustomerDetailsColumns,
  tableKidsColumns,
  tablePayCustomersColumns,
  tablePayCustomersData,
  tableReviewsColumns,
} from "./tableGenerator";
const { TabPane } = Tabs;

interface IViewCustomerProps extends RouteComponentProps {
  customersStore?: CustomersStore;
}

export const TRANSCATIONS = [
  {
    id: 1,
    amountReceived: 30000,
    createDate: moment().format(),
    courseCode: "#BBCC",
    type: CourseStatus.Pending,
  },
  {
    id: 2,
    amountReceived: 29300,
    createDate: moment().format(),
    courseCode: "#BB22",
    type: CourseStatus.Pending,
  },
  {
    id: 3,
    amountReceived: 17000,
    createDate: moment().format(),
    courseCode: "#DDBB",
    type: CourseStatus.Pending,
  },
  {
    id: 4,
    amountReceived: 22000,
    createDate: moment().format(),
    courseCode: "#BC34",
    type: CourseStatus.Pending,
  },
];

const ViewCustomer = inject("customersStore")(
  observer((props: IViewCustomerProps) => {
    const { customersStore } = props;
    const device = useDevices();
    const { t } = useTranslation("base");
    const user = useCurrentUser();
    const userStore = useContext(userStoreContext);
    // @ts-ignore
    const id = props.match.params.id;
    const history = props.history;
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isBroken, setIsBroken] = useState<boolean>(false);
    const [isView, setView] = useState<boolean>(false);
    const [dataId, setDataId] = useState<string>();
    const [customersOrdersData, setCustomersOrdersData] = useState<any>();
    const customerData = toJS(customersStore?.customer);
    const filterCustomersOrdersModel = toJS(
      providerOrdersStore?.customersFilterModel,
    );

    //Mobx Data
    const courseAllList = toJS(customersStore?.courseList);
    const pRData = toJS(reviewsStore?.reviewsData);

    const getCustomersOrders = (filter: any) => {
      setIsLoading(true);
      providerOrdersStore
        ?.getCustomersOrders({ ...filter, customerId: [id] })
        .then((res) => {
          setCustomersOrdersData(res);
          setIsLoading(false);
        });
    };

    useEffect(() => {
      customersStore?.getProviderCustomerById(Number(id));
      getCustomersOrders(filterCustomersOrdersModel);
    }, []);

    const getCourses = async (filter?: any) => {
      setIsLoading(true);
      await customersStore
        ?.getCustomerCourses(Number(id), filter)
        .finally(() => {
          setIsLoading(false);
        });
    };

    const getReviews = async (filter?: any) => {
      setIsLoading(true);
      await reviewsStore?.getReviewsOfCustomer(id, filter).finally(() => {
        setIsLoading(false);
      });
    };

    useEffect(() => {
      (async () => {
        getCourses({ pageable: customerCoursepageable });
      })();
    }, []);

    useEffect(() => {
      (async () => {
        getReviews({ pageable: pageableReview });
      })();
    }, []);

    const onPageChangeHandlerCourses = (page: any) => {
      getCourses({
        pageable: {
          ...pageable,
          pageNumber: page - 1,
        },
      });
    };

    const onPageChangeHandlerReviews = (page: any) => {
      getReviews({
        pageable: {
          ...pageable,
          pageNumber: page - 1,
        },
      });
    };

    useEffect(() => {
      if (
        device &&
        (device === DEVICES.MOBILE ||
          device === DEVICES.TABLET_PORT ||
          device === DEVICES.TABLET_LAND ||
          device === DEVICES.DESKTOP) &&
        !isBroken
      ) {
        setIsBroken(true);
      } else if (
        device &&
        device !== DEVICES.MOBILE &&
        device !== DEVICES.TABLET_PORT &&
        device !== DEVICES.TABLET_LAND &&
        device !== DEVICES.DESKTOP &&
        isBroken
      ) {
        setIsBroken(false);
      }
    }, [device, isBroken]);

    const ActionButton = (data: any) => {
      return (
        <ButtonLink
          onClick={() => {
            setDataId(data?.id?.toString());
            setTimeout(() => {
              setView(true);
            }, 400);
          }}
        >
          {t("bizBase.view", {
            defaultValue: "View",
          })}
          <i
            className="ri-arrow-drop-right-line"
            style={{ fontSize: "16px" }}
          />
        </ButtonLink>
      );
    };

    const mapTableData = useCallback(() => {
      const lists = toJS(customersStore?.courseList);
      const items = lists?.[0]?.data;
      const mapper = (el: any) => {
        return tableCoursesData(el, t);
      };
      return items?.map(mapper);
    }, [customersStore?.courseList]);

    const name =
      customerData?.firstName && customerData?.lastName
        ? customerData?.firstName + " " + customerData?.lastName
        : "";

    const onClear = () => {
      setDataId("");
      setView(false);
    };

    const updatePaymentFilter = (data?: any) => {
      let params = {
        ...filterCustomersOrdersModel,
        pageable: pageableRezo,
      };

      if (data?.type) {
        Object.assign(params, {
          type: data?.type,
        });
      } else {
        params = omit(params, ["type"]);
      }

      if (data?.dateFrom) {
        Object.assign(params, {
          dateFrom: moment(data?.dateFrom)?.format(FORMAT_DATE.query),
        });
      } else {
        params = omit(params, ["dateFrom"]);
      }
      if (data?.dateTo) {
        Object.assign(params, {
          dateTo: moment(data?.dateTo)?.format(FORMAT_DATE.query),
        });
      } else {
        params = omit(params, ["dateTo"]);
      }

      if (data?.satelliteIds) {
        Object.assign(params, {
          providerId: data?.satelliteIds,
        });
      } else {
        params = omit(params, ["providerId"]);
      }

      if (!params?.keyword) {
        params = omit(params, ["keyword"]);
      }

      setTimeout(() => {
        getCustomersOrders(params);
      }, 200);
    };

    const onCustomersOrdersPageChangeHandler = (page?: any) => {
      getCustomersOrders({
        ...filterCustomersOrdersModel,
        pageable: {
          ...filterCustomersOrdersModel?.pageable,
          pageNumber: page - 1,
        },
      });
    };

    const courseList = courseAllList?.[0];

    return (
      <>
        <HeadSeo title={name || t("menu.customers")} />
        <LayoutPage defMenuItem={"customers"}>
          <div className="single-course">
            <div className={"back-block  bsl-4"}>
              <ButtonLink onClick={() => history.push("/customers")}>
                <i
                  style={{ fontSize: "20px", paddingRight: "8px" }}
                  className={bizIcons.ARROWLEFT}
                />
                {t("customers.backToCustomers")}
              </ButtonLink>
            </div>
            <h1 className="biz-tabs-title bsl-4">{name}</h1>
            <div className="biz-tabs-content">
              <div>
                <Tabs
                  defaultActiveKey="1"
                  size={"small"}
                  onTabClick={() => onClear()}
                >
                  <TabPane tab={t("courses.details")} key="1">
                    <TableComponent
                      broken={true}
                      grid={true}
                      noSeparators={true}
                      pagination={false}
                      propsTableColumns={tableCustomerDetailsColumns(
                        userStore.currency,
                      )}
                      propsTableData={[customerData]}
                    />

                    <div className="tsl-3" />
                    <div className="tsl-5" />
                    <div className={"flex-space-between"}>
                      <div className={"semi-bold "}>
                        {t("customers.listOfKids")}
                      </div>
                    </div>
                    <Divider style={{ marginBottom: "0px" }} />
                    <TableComponent
                      broken={true}
                      pagination={false}
                      propsTableColumns={tableKidsColumns(t)}
                      propsTableData={customerData?.children || []}
                    />
                  </TabPane>
                  <TabPane tab={t("courses.courses")} key="2">
                    {isView ? (
                      <ViewCustomerCourse
                        id={Number(dataId)}
                        onBack={() => {
                          setDataId("");
                          setView(false);
                        }}
                      />
                    ) : (
                      <TableComponent
                        broken={isBroken}
                        pagination={
                          pRData?.totalCount > PAGE_SIZE
                            ? {
                                total: courseList?.totalCount || 0,
                                pageSize: courseList?.pageSize || 10,
                                page: courseList?.pageNumber + 1 || 1,
                                onChange: onPageChangeHandlerCourses,
                              }
                            : false
                        }
                        propsTableColumns={tableCustomerCoursesColumns({
                          t,
                          isBroken,
                          isLinked: user?.isLinked,
                          ActionButton,
                        })}
                        propsTableData={mapTableData()}
                      />
                    )}
                  </TabPane>

                  <TabPane tab={t("menu.reviews")} key="3">
                    {isView ? (
                      <ViewCustomerReview
                        id={Number(dataId)}
                        onBack={() => onClear()}
                      />
                    ) : (
                      <TableComponent
                        style={{ opacity: `${isLoading ? "0.4" : 1}` }}
                        broken={isBroken}
                        emptyTableContent={{
                          text: t("reviews.empty_list"),
                        }}
                        isLoading={isLoading}
                        propsTableColumns={tableReviewsColumns(
                          t,
                          isBroken,
                          user?.isLinked,
                          ActionButton,
                        )}
                        propsTableData={
                          pRData?.data?.map((el: any) => {
                            return { ...el, key: Math.random() };
                          }) || undefined
                        }
                        pagination={
                          pRData?.totalCount > PAGE_SIZE
                            ? {
                                total: pRData?.totalCount,
                                pageSize: pRData?.pageSize,
                                page: pRData?.pageNumber + 1,
                                onChange: onPageChangeHandlerReviews,
                              }
                            : false
                        }
                      />
                    )}
                  </TabPane>

                  <TabPane tab={t("customers.transactions")} key="5">
                    <div className={"tab-table-block"}>
                      {isView ? (
                        <PayViewTransaction
                          id={Number(dataId)}
                          onBack={() => onClear()}
                        />
                      ) : (
                        <div className="biz-transaction-filters">
                          <div className={"bsl-5 tsl-5 tab-table-block"}>
                            <div
                              className={
                                "payment-tabs-title no-title tab-table-block-head"
                              }
                            >
                              <PayCustomersFilters
                                onApply={updatePaymentFilter}
                                userType={user?.isLinked}
                              />
                            </div>
                            <TableComponent
                              emptyTableContent={{
                                text: t("customers.no_transactions"),
                              }}
                              broken={isBroken}
                              propsTableColumns={tablePayCustomersColumns(
                                t,
                                isBroken,
                                user?.isLinked,
                                ActionButton,
                              )}
                              propsTableData={tablePayCustomersData(
                                customersOrdersData?.data || [],
                              )}
                              pagination={{
                                total: customersOrdersData?.totalCount,
                                pageSize: customersOrdersData?.pageSize,
                                page: customersOrdersData?.pageNumber + 1,
                                onChange: onCustomersOrdersPageChangeHandler,
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </div>
        </LayoutPage>
      </>
    );
  }),
);

export default withRouter(ViewCustomer);
