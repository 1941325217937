import React from "react";
import { Table } from "antd";
import EmptyTableContent from "../EmptyTableContent";
import { observer } from "mobx-react-lite";

interface IEmptyTableContent {
  text?: string;
  linkUrl?: string;
  imageSrc?: string;
  linkText?: string;
  className?: string;
}

interface IProps {
  grid?: boolean;
  style?: any;
  isLoading?: boolean;
  broken?: boolean;
  noSeparators?: boolean;
  pagination?: false | object;
  propsTableColumns?: any;
  propsTableData?: any;
  emptyTableContent?: IEmptyTableContent;
  rowSelection?: any;
  className?: string;
}

const TableComponent = observer((props: IProps) => {
  const {
    isLoading,
    propsTableColumns,
    propsTableData,
    broken,
    grid,
    style,
    pagination,
    noSeparators,
    emptyTableContent,
    rowSelection,
    className,
  } = props;
  return (
    <Table
      locale={{ emptyText: <EmptyTableContent {...emptyTableContent} /> }}
      loading={isLoading}
      showHeader={!broken}
      style={style}
      columns={propsTableColumns}
      dataSource={propsTableData}
      rowKey={(res) => res?.id}
      className={`${
        broken ? (grid ? "block-grid-table" : "block-table") : ""
      } ${noSeparators ? "disable-bottom-border" : ""} ${className}`}
      pagination={
        pagination === false
          ? false
          : pagination
          ? { ...pagination, position: ["bottomLeft"], simple: true }
          : { position: ["bottomLeft"], simple: true }
      }
      rowSelection={rowSelection}
    />
  );
});

export default TableComponent;
