export const CITY_DATA = [
  {
    value: "Hong Kong Island",
    suburbs: [
      {
        value: "Central and Western",
      },
      {
        value: "Eastern",
      },
      {
        value: "Southern",
      },
      {
        value: "Wan Chai",
      },
    ],
  },
  {
    value: "Kowloon",
    suburbs: [
      {
        value: "Kowloon City",
      },
      {
        value: "Kwun Tong",
      },
      {
        value: "Sham Shui Po",
      },
      {
        value: "Wong Tai Sin",
      },
      {
        value: "Yau Tsim Mong",
      },
    ],
  },

  {
    value: "New Territories",
    suburbs: [
      {
        value: "Islands",
      },
      {
        value: "Kwai Tsing",
      },
      {
        value: "North",
      },
      {
        value: "Sai Kung",
      },
      {
        value: "Sha Tin",
      },
      {
        value: "Tai Po",
      },
      {
        value: "Tsuen Wan",
      },
      {
        value: "Tuen Mun",
      },
      {
        value: "Yuen Long",
      },
    ],
  },
];

// according to https://www.auctionjia.com.sg/blog/40-everything-you-need-to-know-about-singapore-s-28-districts
export const SINGAPORE_CITY_DATA = [
  {
    value: "Singapore",
    name: "Singapore",
    suburbs: [
      {
        value: "D1 (Raffles Place, Cecil, Marina, People's Park)",
        name: "D1 (Raffles Place, Cecil, Marina, People's Park)",
      },
      {
        value: "D2 (Anson, Tanjong Pagar)",
        name: "D2 (Anson, Tanjong Pagar)",
      },
      {
        value: "D3 (Queenstown, Tiong Bahru, Redhill)",
        name: "D3 (Queenstown, Tiong Bahru, Redhill)",
      },
      {
        value: "D4 (Telok Blangah, Harbourfront, Sentosa)",
        name: "D4 (Telok Blangah, Harbourfront, Sentosa)",
      },
      {
        value: "D5 (Pasir Panjang, Hong Leong Garden, Clementi New Town)",
        name: "D5 (Pasir Panjang, Hong Leong Garden, Clementi New Town)",
      },
      {
        value: "D6 (High Street, City Hall, Clarke Quay, Beach Road)",
        name: "D6 (High Street, City Hall, Clarke Quay, Beach Road)",
      },
      {
        value: "D7 (Middle Road, Golden Mile)",
        name: "D7 (Middle Road, Golden Mile)",
      },
      {
        value: "D8 (Little India, Farrer Park)",
        name: "D8 (Little India, Farrer Park)",
      },
      {
        value: "D9 (Orchard, Cairnhill, River Valley, Scotts Road)",
        name: "D9 (Orchard, Cairnhill, River Valley, Scotts Road)",
      },
      {
        value: "D10 (Ardmore, Bukit Timah, Holland Road, Tanglin)",
        name: "D10 (Ardmore, Bukit Timah, Holland Road, Tanglin)",
      },
      {
        value: "D11 (Watten Estate, Novena, Thomson, Newton)",
        name: "D11 (Watten Estate, Novena, Thomson, Newton)",
      },
      {
        value: "D12 (Balestier, Toa Payoh)",
        name: "D12 (Balestier, Toa Payoh)",
      },
      {
        value: "D13 (Macpherson, Braddell, Potong Pasir)",
        name: "D13 (Macpherson, Braddell, Potong Pasir)",
      },
      {
        value: "D14 (Geylang, Eunos)",
        name: "D14 (Geylang, Eunos)",
      },
      {
        value: "D15 (Katong, Joo Chiat, Amber Road, East Coast Road, Siglap)",
        name: "D15 (Katong, Joo Chiat, Amber Road, East Coast Road, Siglap)",
      },
      {
        value: "D16 (Bedok, Upper East Coast, Eastwood, Kew Drive)",
        name: "D16 (Bedok, Upper East Coast, Eastwood, Kew Drive)",
      },
      {
        value: "D17 (Loyang, Changi)",
        name: "D17 (Loyang, Changi)",
      },
      {
        value: "D18 (Tampines, Pasir Ris)",
        name: "D18 (Tampines, Pasir Ris)",
      },
      {
        value: "D19 (Serangoon Garden, Hougang, Punggol)",
        name: "D19 (Serangoon Garden, Hougang, Punggol)",
      },
      {
        value: "D20 (Bishan, Ang Mo Kio, Thomson Road)",
        name: "D20 (Bishan, Ang Mo Kio, Thomson Road)",
      },
      {
        value: "D21 (Upper Bukit Timah, Clementi Park, Ulu Pandan)",
        name: "D21 (Upper Bukit Timah, Clementi Park, Ulu Pandan)",
      },
      {
        value: "D22 (Jurong)",
        name: "D22 (Jurong)",
      },
      {
        value: "D23 (Hillview, Dairy Farm, Bukit Panjang, Choa Chu Kang)",
        name: "D23 (Hillview, Dairy Farm, Bukit Panjang, Choa Chu Kang)",
      },
      {
        value: "D24 (Lim Chu Kang, Tengah)",
        name: "D24 (Lim Chu Kang, Tengah)",
      },
      {
        value: "D25 (Kranji, Woodgrove, Woodlands, Admiralty)",
        name: "D25 (Kranji, Woodgrove, Woodlands, Admiralty)",
      },
      {
        value: "D26 (Upper Thomson, Springleaf, Mandai)",
        name: "D26 (Upper Thomson, Springleaf, Mandai)",
      },
      {
        value: "D27 (Yishun, Sembawang, Admiralty)",
        name: "D27 (Yishun, Sembawang, Admiralty)",
      },
      {
        value: "D28 (Seletar, Yio Chu Kang)",
        name: "D28 (Seletar, Yio Chu Kang)",
      },
    ],
  },
];
