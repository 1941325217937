/**
 * config for feature toggling
 * https://martinfowler.com/articles/feature-toggles.html
 */

type ExperimentalType = "on_demand_location";

type PartialRecord<K extends keyof any, T> = {
  [P in K]?: T;
};

export const experimentals: PartialRecord<ExperimentalType, boolean> = {
  on_demand_location: false,
};
