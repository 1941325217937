import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import {
  Languages,
  LanguageStore,
  languageStoreContext,
} from "../../../scripts/mobx/languageStore";
import EnPrivacyPolicy from "./en";
import ZhPrivacyPolicy from "./zh";

interface ILingualBlock extends React.HTMLAttributes<HTMLElement> {
  languageStore?: LanguageStore;
}

const PrivacyPolicy = inject("languageStore")(
  observer((props: ILingualBlock) => {
    const language = useContext(languageStoreContext);
    return (
      <>
        {language?.lang === Languages.ZH ? (
          <ZhPrivacyPolicy />
        ) : (
          <EnPrivacyPolicy />
        )}
      </>
    );
  }),
);

export default PrivacyPolicy;
