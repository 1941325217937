import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import {
  Languages,
  LanguageStore,
  languageStoreContext,
} from "scripts/mobx/languageStore";
import EnFAQs from "./en";
import ZhFAQs from "./zh";

interface ILingualBlock extends React.HTMLAttributes<HTMLElement> {
  languageStore?: LanguageStore;
}

const FAQs = inject("languageStore")(
  observer((props: ILingualBlock) => {
    const language = useContext(languageStoreContext);

    return <>{language?.lang === Languages.ZH ? <ZhFAQs /> : <EnFAQs />}</>;
  }),
);

export default FAQs;
