import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import {
  Languages,
  LanguageStore,
  languageStoreContext,
} from "../../../scripts/mobx/languageStore";
import EnCookiePolicy from "./en";
import ZhCookiePolicy from "./zh";

interface ILingualBlock extends React.HTMLAttributes<HTMLElement> {
  languageStore?: LanguageStore;
}

const CookiePolicy = inject("languageStore")(
  observer((props: ILingualBlock) => {
    const language = useContext(languageStoreContext);
    return (
      <>
        {language?.lang === Languages.ZH ? (
          <ZhCookiePolicy />
        ) : (
          <EnCookiePolicy />
        )}
      </>
    );
  }),
);

export default CookiePolicy;
