import { Button, Modal, notification, Spin, Typography } from "antd";
import { HeadSeo } from "components/HeadSeo";
import {
  CourseBodyParams,
  CourseStatus,
  CourseTab,
  KidsAgeType,
} from "constants/type.interface";
import { AppRoutes } from "helpers/app.routes";
import Cookies from "js-cookie";
import { toJS } from "mobx";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Prompt } from "react-router";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useCurrentVerify } from "scripts/custom-hooks/useCurrentUser";
import { CourseStore } from "scripts/mobx/courseStore";
import { userStoreContext } from "scripts/mobx/userStore";
import ButtonLink from "../../../../components/ButtonLink";
import StepsBizi from "../../../../components/StepsBizi";
import { bizIcons } from "../../../../constants/bizConstants";
import { ManCourseCreateStep1 } from "./Step1";
import { ManCourseCreateStep2 } from "./Step2";
import { ManCourseCreateStep3 } from "./Step3";
import QueryString from "constants/query-string";
import { createStatus } from "..";

const { Title } = Typography;

export const minimumStripeAmount = (currency: string): number => {
  switch (currency) {
    case "HKD":
      return 4;
    case "USD":
      return 0.5;
    default:
      return 0;
  }
};

interface IProps extends RouteComponentProps {
  data?: any;
  courseStore?: CourseStore;
  isLoading: boolean;
  isEdit?: boolean;
  clearDataToSet: () => void;
}
const { confirm } = Modal;

export const ManCourseCreateWrapper = withRouter(
  inject("courseStore")(
    observer((props: IProps) => {
      const { history, courseStore, isEdit, data } = props;
      const { t } = useTranslation("base");
      const isVerified = useCurrentVerify();
      const userStore = useContext(userStoreContext);

      // @ts-ignore
      const id = props.match.params.id;
      const courseId = data?.id || id;
      const courseDataVal = toJS(courseStore?.courseCreateDataVal);

      const status = id ? courseDataVal?.status : CourseStatus.Draft;

      const [isLoading, setLoading] = useState<boolean>(false);
      const [step, setStep] = useState<number>(1);
      const [shouldBlockNavigation, setShouldBlockNavigation] = useState(true);

      const publishChecked = isVerified ? true : false;

      const idToBack = useRef();

      React.useEffect(() => {
        if (courseId) {
          setLoading(true);
          courseStore
            ?.getDetailCourse(Number(courseId))
            .finally(() => setLoading(false));
        }
      }, []);

      const setPublishChecked = (val: boolean) => {
        courseStore?.updateCourseCreateData({ isPublished: val });
      };
      function showConfirmToDelete() {
        confirm({
          title: t("courses.deleteCourse"),
          content: t("courses.deleteCourseMsg"),
          autoFocusButton: null,
          icon: "",
          okText: t("bizBase.delete"),
          cancelText: t("bizBase.cancel"),
          onOk() {
            if (idToBack.current) {
              setShouldBlockNavigation(false);
              //Function Delete
              history.push(AppRoutes.courses);
            }
          },
        });
      }

      const onSaveDraft = (data: any) => {
        const params: CourseBodyParams = {
          name: data?.step1.name,
          code: data?.step1?.code,
          category: data?.step1?._category,
          subCategory: data?.step1?._subCategory,
          locationId: data?.step1?._location,
          ageFrom:
            data?.step1?.kidsAgeFromType === KidsAgeType.Years
              ? data?.step1?.kidsAgeFrom * 12
              : data?.step1?.kidsAgeFrom,
          ageTo:
            data?.step1?.kidsAgeToType === KidsAgeType.Years
              ? data?.step1?.kidsAgeTo * 12
              : data?.step1?.kidsAgeTo,
          imageUrl: data?.step1?.imageUrl,
          classes: toJS(courseStore?.scheduleClass),
          description: data?.step3?.briefDescription,
          pricePerClass: data?.step3?.totalPrice,
          minClassesForSale: data?.step3?.minClassesForSale,
          capacity: data?.step3?.availableClassHeadcount,
          publishCourse: false,
        };

        if (id) {
          // @ts-ignore
          //Function update course
          setShouldBlockNavigation(false);
          courseStore
            ?.updateCourse(id, params)
            .then((res) => {
              if (res?.validationMs?.length > 0) {
                notification.error({
                  message: t("messages.update_course_error_due_to", {
                    dueTo: res?.validationMs?.join(", "),
                  }),
                });
              } else {
                notification.success({
                  message: t("courses.save_draft_course_sucess"),
                });
                // setTimeout(() => {
                //   history.push(AppRoutes.courses + "?tab=Draft");
                // }, 200);
                setTimeout(() => {
                  history.push(AppRoutes.courseDetail(id));
                }, 300);
              }
            })
            .catch((error: any) => {
              if (error?.data?.validationMs?.length > 0) {
                notification.error({
                  message: t("messages.update_course_error_due_to", {
                    dueTo: error?.data?.validationMs?.join(", "),
                  }),
                });
              } else {
                notification.error({
                  message:
                    error?.data?.message ||
                    error?.message ||
                    t("courses.save_draft_course_error"),
                });
              }
            });
          return;
        }
      };

      const nextStep = async () => {
        if (step < 3) {
          setStep(step + 1);
        } else if (step === 3) {
          const datas = toJS(courseStore?.courseCreateDataVal);
          const publishCourse = datas?.step3?.publishACourse;
          const params: CourseBodyParams = {
            name: datas?.step1.name,
            code: datas?.step1?.code,
            category: datas?.step1?._category,
            subCategory: datas?.step1?._subCategory,
            locationId: datas?.step1?._location,
            ageFrom:
              datas?.step1?.kidsAgeFromType === KidsAgeType.Years
                ? datas?.step1?.kidsAgeFrom * 12
                : datas?.step1?.kidsAgeFrom,
            ageTo:
              datas?.step1?.kidsAgeToType === KidsAgeType.Years
                ? datas?.step1?.kidsAgeTo * 12
                : datas?.step1?.kidsAgeTo,
            imageUrl: datas?.step1?.imageUrl,
            classes: toJS(courseStore?.scheduleClass),
            description: datas?.step3?.briefDescription,
            pricePerClass: datas?.step3?.totalPrice,
            minClassesForSale: datas?.step3?.minClassesForSale,
            capacity: datas?.step3?.availableClassHeadcount,
            publishCourse,
          };

          // validation for minimum course price when provider doesn't have a valid payment methods
          const minimumCoursePrice =
            (datas?.step3?.totalPrice || 0) *
            (datas?.step3?.minClassesForSale || 0);

          if (
            minimumCoursePrice < minimumStripeAmount(userStore.currency) &&
            userStore.paymentMethods.length < 1
          ) {
            notification.error({
              message: t("courses.minimum_course_price_error"),
            });
            return;
          }

          if (id) {
            //Function update course
            setShouldBlockNavigation(false);
            try {
              const res = await courseStore?.updateCourse(id, params);
              if (res?.validationMs?.length > 0) {
                notification.error({
                  message: t("messages.update_course_error_due_to", {
                    dueTo: res?.validationMs?.join(", "),
                  }),
                });
              } else {
                notification.success({
                  message: t("messages.update_course_sucess"),
                });
                setTimeout(() => {
                  history.push(AppRoutes.courseDetail(id));
                }, 200);
              }
            } catch (error: any) {
              let errorMessage =
                error?.data?.message ||
                error?.message ||
                t("messages.update_course_error");

              if (error?.data?.validationMs?.length > 0) {
                errorMessage = t("messages.update_course_error_due_to", {
                  dueTo: error?.data?.validationMs?.join(", "),
                });
              }

              if (
                [
                  "com.bizibuz.common.exception.ProviderPaymentMethodException",
                  "com.bizibuz.common.exception.CoursePriceIsTooLowException",
                ].includes(error?.data?.error)
              ) {
                errorMessage = (
                  <div>
                    In order to create free trial classes, you must enter a
                    valid payment method{" "}
                    <ButtonLink
                      onClick={() => {
                        history.push({
                          pathname: AppRoutes.courseCreate,
                          search: QueryString.stringify({
                            action: createStatus.ADD_PAYMENT_METHOD,
                          }),
                        });
                      }}
                    >
                      <span style={{ fontSize: 16 }}>here.</span>
                    </ButtonLink>
                  </div>
                );

                if (params.classes.length > 1) {
                  errorMessage =
                    "A course with multiple classes cannot be created for zero cost";
                }
              }

              notification.error({
                message: errorMessage,
              });
            }
          } else {
            //Function Create course
            setShouldBlockNavigation(false);
            try {
              await courseStore?.createCourse(params);

              const successMessage = publishCourse
                ? t("messages.course_published_success")
                : t("messages.create_draft_course_sucess");

              notification.success({
                message: successMessage,
              });

              setTimeout(() => {
                history.push(
                  AppRoutes.courses +
                    "?tab=" +
                    (publishCourse ? CourseTab.Current : CourseTab.Draft),
                );
              }, 200);
            } catch (error: any) {
              const defaultErrorMessage = publishCourse
                ? t("messages.course_published_error")
                : t("messages.create_draft_course_error");

              let errorMessage =
                error?.data?.message || error?.message || defaultErrorMessage;

              if (
                [
                  "com.bizibuz.common.exception.ProviderPaymentMethodException",
                  "com.bizibuz.common.exception.CoursePriceIsTooLowException",
                ].includes(error?.data?.error)
              ) {
                errorMessage = (
                  <div>
                    In order to create free trial classes, you must enter a
                    valid payment method{" "}
                    <ButtonLink
                      onClick={() => {
                        history.push({
                          pathname: AppRoutes.courseCreate,
                          search: QueryString.stringify({
                            action: createStatus.ADD_PAYMENT_METHOD,
                          }),
                        });
                      }}
                    >
                      <span style={{ fontSize: 16 }}>here.</span>
                    </ButtonLink>
                  </div>
                );

                if (params.classes.length > 1) {
                  errorMessage =
                    "A course with multiple classes cannot be created for zero cost";
                }
              }

              notification.error({
                message: errorMessage,
                duration: 10,
              });
            }
          }
        }
      };
      const prevStep = () => {
        if (step > 1) {
          setStep(step - 1);
        }
      };

      useEffect(() => {
        window.onbeforeunload = shouldBlockNavigation ? () => true : null;
      });

      const titlePage = isEdit
        ? t("courses.editCourse", {
            defaultValue: "Edit course",
          })
        : step === 3
        ? t("courses.addNewCourse", {
            defaultValue: "Add new course",
          })
        : t("courses.createACourse", {
            defaultValue: "Create a course",
          });
      if (courseId && isLoading) return <Spin size="large" />;
      //
      // id ? AppRoutes.courseDetail(id) : AppRoutes.courses,
      return (
        <>
          <HeadSeo title={titlePage} />
          <div className={"create-courses content-with-table"}>
            <Prompt
              when={shouldBlockNavigation}
              message={t("messages.leaves_page")}
            />
            <div className={"back-block  bsl-4"}>
              <ButtonLink
                onClick={() =>
                  history.push(
                    AppRoutes.courses + "?tab=" + Cookies.get("coursesTab"),
                  )
                }
              >
                <i
                  style={{ fontSize: "20px", paddingRight: "8px" }}
                  className={bizIcons.ARROWLEFT}
                />
                {t("courses.backToCourses", {
                  defaultValue: "Back to Courses",
                })}
              </ButtonLink>
            </div>
            <div className={isEdit ? "flex-space-between form-width" : ""}>
              <Title level={1}>{titlePage}</Title>
              {!!isEdit &&
                [CourseStatus.Draft, CourseStatus.Pending]?.includes(
                  status,
                ) && (
                  <ButtonLink
                    onClick={() => {
                      showConfirmToDelete();
                    }}
                  >
                    <i className={`${bizIcons.DELETE} fs-20 mr-8`} />
                    {t("courses.delete", {
                      defaultValue: "Delete",
                    })}
                  </ButtonLink>
                )}
            </div>

            <div className={"bsl-3 tsl-5"} style={{ marginBottom: "32px" }}>
              {/* {t("courses.noteCourseUnderTitle", {
              defaultValue:
                "To publish the course fill in all required* fields.",
            })} */}
            </div>

            <StepsBizi total={2} active={step - 1} />

            {courseDataVal?.validationMs?.length > 0 ? (
              <p className="tsl-4 text-red">
                {courseDataVal?.validationMs?.join(", ")}
              </p>
            ) : null}
            {step === 0 && (
              <div className={"flex-center"}>
                <Button className={"loader-from-button"} loading={true} />
              </div>
            )}
            {step === 1 && (
              <ManCourseCreateStep1
                onSaveDraft={onSaveDraft}
                nextStep={nextStep}
                publishChecked={publishChecked}
                dataCourse={courseDataVal}
                courseStatus={status}
              />
            )}
            {step === 2 && (
              <ManCourseCreateStep2
                nextStep={nextStep}
                prevStep={prevStep}
                publishChecked={publishChecked}
                dataCourse={courseDataVal}
                courseStatus={status}
              />
            )}
            {step === 3 && (
              <ManCourseCreateStep3
                nextStep={nextStep}
                prevStep={prevStep}
                setPublishChecked={setPublishChecked}
                isEdit={isEdit}
                publishChecked={publishChecked}
                dataCourse={courseDataVal}
                courseStatus={status}
              />
            )}
          </div>
        </>
      );
    }),
  ),
);
