import React, { useEffect, useState } from "react";
import IntervalLinePoint from "./IntervalLinePoint";
import { inject, observer } from "mobx-react";
import { ReportsStore } from "../../../../scripts/mobx/reportsStore";
import GreySquare from "../../../../components/GreySquare";
import emptyTableSVG from "../../../../assets/courses-empty.svg";
import { ChartFilters } from "./filters";
import { useTranslation } from "react-i18next";

interface IProps {
  autoFit?: boolean;
  colors?: string[];
  reportsStore?: ReportsStore;
}

const RecurringCustomersRevenueRatio = inject("reportsStore")(
  observer((props: IProps) => {
    const { autoFit } = props;
    const { reportsStore } = props;
    const { t } = useTranslation();
    const [data, setData] = useState<any[] | undefined>();
    // const [data, setData] = useState<ProviderReportCustomersAndRevenueListItemModel[] | undefined>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
      reportsStore?.getReportRecurringCustomersAndRevenue({}).then((el) => {
        setData(
          el?.items?.map((el: any) => {
            return {
              ...el,
              month: `${el.year}-${String(el.month).padStart(2, "0")}`,
            };
          }),
        );
      });
    }, []);

    const updateFilter = (filterObject?: any) => {
      setIsLoading(true);
      reportsStore
        ?.getReportRecurringCustomersAndRevenue({ ...filterObject })
        .then((el) => {
          setData(
            el?.items?.map((el: any) => {
              return {
                ...el,
                month: `${el.year}-${String(el.month).padStart(2, "0")}`,
              };
            }),
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    return (
      <div className={"chart-block tsl-4 bsl-4"}>
        <div className={"chart-block-header bsl-3"}>
          <div className={"chart-block-title title"}>
            {t("reports.recurring_customers_revenue_ratio", {
              defaultValue: "Recurring Customers / Revenue ratio",
            })}
          </div>
          <ChartFilters t={t} onApply={updateFilter} />
        </div>
        <div className={"chart-wrapper"}>
          {data?.length && <IntervalLinePoint autoFit={autoFit} data={data} />}
          {!data?.length && <GreySquare imageSrc={emptyTableSVG} />}
        </div>
      </div>
    );
  }),
);

export default RecurringCustomersRevenueRatio;
