import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import i18n from "i18n";
import React, { useContext } from "react";
import AddPaymentModal, { IAddPaymentModalProps } from "./AddPaymentModal";

import "./index.less";
import { observer } from "mobx-react-lite";
import { userStoreContext } from "scripts/mobx/userStore";

const stripeHongKongPromise = loadStripe(
  process.env.RAZZLE_STRIPE_KEY_HONG_KONG ?? "",
);
const stripeSingaporePromise = loadStripe(
  process.env.RAZZLE_STRIPE_KEY_SINGAPORE ?? "",
);

const AddPaymentMethod = observer((props: IAddPaymentModalProps) => {
  const userStore = useContext(userStoreContext);
  return (
    <Elements
      key={i18n.language}
      stripe={
        userStore.isSGProvider ? stripeSingaporePromise : stripeHongKongPromise
      }
    >
      <AddPaymentModal {...props} />
    </Elements>
  );
});

export default AddPaymentMethod;
