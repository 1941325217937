import React, { useEffect, useState } from "react";
import {
  CHECKBLOCKTYPES,
  IChkBlockProps,
  IControlProps,
} from "components/CheckBoxes/ChkBlock";
import { Filters } from "../../../../../components/Filters";
import { observer } from "mobx-react";
import ButtonLink from "../../../../../components/ButtonLink";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { TFunction } from "react-i18next";

const getTableFiltersData: (t: TFunction<"translation">) => IChkBlockProps = (
  t,
) => {
  return {
    dateFromTo: {
      title: t("bookings.dateFromTo"),
      combined: true,
      controlsList: [
        {
          value: "",
          active: true,
          default: true,
          placeholder: "bizBase.all",
        },
        {
          value: "",
          markId: "dateFrom",
          type: CHECKBLOCKTYPES.DATE,
          dependsOn: {
            field: {
              name: "dateFromTo",
              id: 2,
            },
            condition: (data) => {
              const { value, current } = data;
              return value && current && current > value?.startOf("day");
            },
          },
          placeholder: "bookings.dateFrom",
        },
        {
          value: "",
          markId: "dateTo",
          type: CHECKBLOCKTYPES.DATE,
          placeholder: "bookings.dateTo",
          dependsOn: {
            field: {
              name: "dateFromTo",
              id: 1,
            },
            condition: (data) => {
              const { value, current } = data;
              return value && current && current < value?.startOf("day");
            },
          },
        },
      ],
    },
  };
};

interface ICoursesFiltersProps extends RouteComponentProps {
  onApply: (data: any) => void;
  t: any;
}
export const ChartFilters = withRouter(
  observer((props: ICoursesFiltersProps): React.ReactElement => {
    const { t, onApply } = props;

    const [tableFiltersData, setTableFiltersData] = useState<IChkBlockProps>();
    const [isFilterActive, setIsFilterActive] = useState<boolean>(false);

    useEffect(() => {
      const tableFiltersData = getTableFiltersData(t);
      (async () => {
        await setTableFiltersData(tableFiltersData);
      })();
    }, []);

    const demapFilterData = (data: any): any => {
      const _data = {};

      Object.keys(data).forEach((key) => {
        if (!data[key].combined) {
          _data[key] = data[key].controlsList?.map(
            (el: IControlProps) => el.value,
          );
        } else {
          data[key].controlsList?.forEach((el: IControlProps) => {
            if (el.markId) {
              _data[el.markId] = el.value;
            }
          });
        }
      });

      return _data;
    };

    const onApplyFilters = (data: any) => {
      const _data = { ...demapFilterData(data) };
      onApply(_data);
    };

    return tableFiltersData ? (
      <Filters
        onApply={onApplyFilters}
        filterBlocks={tableFiltersData}
        isFilterActive={isFilterActive}
      />
    ) : (
      <div>
        <ButtonLink disabled={true}>
          {t("bizBase.filters", {
            defaultValue: "Filters",
          })}
          <i
            className={"ri-arrow-drop-down-line"}
            style={{ fontSize: "20px" }}
          />
        </ButtonLink>
      </div>
    );
  }),
);
