import React, { useContext, useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { ReportsStore } from "../../../../scripts/mobx/reportsStore";
import GreySquare from "../../../../components/GreySquare";
import emptyTableSVG from "../../../../assets/courses-empty.svg";
import { ChartFilters } from "./filters";
import CustomerSpendByKidAgeChart from "./CircleChart";
import { useTranslation } from "react-i18next";
import { languageStoreContext } from "scripts/mobx/languageStore";

interface IProps {
  autoFit?: boolean;
  colors?: string[];
  reportsStore?: ReportsStore;
}

const CustomerSpendByCategory = inject("reportsStore")(
  observer((props: IProps) => {
    const { autoFit } = props;
    const { reportsStore } = props;
    const { t } = useTranslation();
    const [data, setData] = useState<any[] | undefined>();
    // const [data, setData] = useState<ProviderReportCustomersAndRevenueListItemModel[] | undefined>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const language = useContext(languageStoreContext);

    const onResetData = (data: any[]) => {
      setData(
        data?.map((el: any) => {
          return { ...el, category: t("categories." + el?.category) };
        }) || undefined,
      );
    };
    useEffect(() => {
      reportsStore?.getReportCustomerSpendByCategoryChart({}).then((el) => {
        onResetData(el?.items || undefined);
      });
    }, [language?.lang]);

    const updateFilter = (filterObject?: any) => {
      setIsLoading(true);
      reportsStore
        ?.getReportCustomerSpendByCategoryChart({ ...filterObject })
        .then((el) => {
          onResetData(el?.items || undefined);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    return (
      <div className={"chart-block tsl-4 bsl-4"}>
        <div className={"chart-block-header bsl-3"}>
          <div className={"chart-block-title title"}>
            {t("reports.customer_spend_by_kid_category_chart", {
              defaultValue: "Customer spend by kid category chart",
            })}
          </div>
          <ChartFilters t={t} onApply={updateFilter} />
        </div>
        <div className={"chart-wrapper"}>
          {data?.length && (
            <CustomerSpendByKidAgeChart autoFit={autoFit} data={data} />
          )}
          {!data?.length && <GreySquare imageSrc={emptyTableSVG} />}
        </div>
      </div>
    );
  }),
);

export default CustomerSpendByCategory;
