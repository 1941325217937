import React, { useCallback, useState } from "react";
import ButtonLink from "../../../components/ButtonLink";
import SaveButtonText from "../../../components/SaveButtonText";
import { Button, Form, Input, message, Select, Row, Col, Divider } from "antd";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { bizIcons } from "../../../constants/bizConstants";
import {
  CHECKBLOCKTYPES,
  countOfValuesEnum,
  IChkBlockProps,
} from "../../../components/CheckBoxes/ChkBlock";
import { CheckBoxes } from "../../../components/CheckBoxes";
import { TFunction } from "react-i18next";

interface IEditKidzBizDetailsProps extends RouteComponentProps {
  onBack: () => void;
  onShare: (data: any) => Promise<any>;
}
const mockData = (t: TFunction<"translation">): IChkBlockProps => {
  return {
    dateFromTo: {
      title: t("bookings.dateFromTo"),
      combined: true,
      controlsList: [
        {
          value: "",
          active: true,
          default: true,
          placeholder: "bizBase.all",
        },
        {
          value: "",
          markId: "dateFrom",
          type: CHECKBLOCKTYPES.DATE,
          dependsOn: {
            field: {
              name: "dateFromTo",
              id: 2,
            },
            condition: (data) => {
              const { value, current } = data;
              return value && current && current > value?.startOf("day");
            },
          },
          placeholder: "bookings.dateFrom",
        },
        {
          value: "",
          markId: "dateTo",
          type: CHECKBLOCKTYPES.DATE,
          placeholder: "bookings.dateTo",
          dependsOn: {
            field: {
              name: "dateFromTo",
              id: 1,
            },
            condition: (data) => {
              const { value, current } = data;
              return value && current && current < value?.startOf("day");
            },
          },
        },
      ],
    },
    reportType: {
      title: t("Report"),
      countOfValues: countOfValuesEnum.Single,
      controlsList: [
        {
          value: 0,
          placeholder: "bizBase.all",
          active: true,
          default: true,
        },
        {
          value: 1,
          placeholder: "Kidzbiz report",
        },
        {
          value: 2,
          placeholder: "Customers report",
        },
        {
          value: 3,
          placeholder: "Summary table report",
        },
      ],
    },
    type: {
      title: t("Type"),
      countOfValues: countOfValuesEnum.Single,

      controlsList: [
        {
          placeholder: "CSV",
          value: 0,
          default: true,
          active: true,
        },
        // {
        //   placeholder: "PDF",
        //   value: "1",
        //   default: true,
        //   active: true,
        // },
      ],
    },
  };
};

const ShareReportPage = observer((props: IEditKidzBizDetailsProps) => {
  const { onBack, onShare } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [chkData, setChkData] = useState<any | undefined>(mockData);

  const [form] = Form.useForm();

  const deMapData = (data: any) => {
    const _data = {};

    Object.keys(data).forEach((el) => {
      let active: any[] | any = [];
      data[el].controlsList.forEach((el: any) => {
        if (el.active) {
          if (el.type === CHECKBLOCKTYPES.DATE) {
            active.push(el.value.toDate());
          } else {
            active = el.value;
          }
        }
      });
      _data[el] = active;
    });
    return {
      ..._data,
    };
  };
  const onFinish = async (values: any) => {
    setIsLoading(true);
    const _values = deMapData(chkData);

    try {
      await form.validateFields(Object.keys(values));
      await onShare({
        ...values,
        ..._values,
      });
    } catch (e: any) {
      const { _message } = e;
      message.error(_message);
      setIsLoading(false);
    }
  };

  const validateForm = useCallback(
    (field?: string) => {
      form
        .validateFields(field ? [field] : undefined)
        .then(
          (r) => {
            setIsDisabled(false);
          },
          (rej) => {
            setIsDisabled(true);
          },
        )
        .catch((e) => {
          const { _message } = e;
          message.error(_message || "Some error occurred");
        });
    },
    [isDisabled],
  );

  const onChangeCheckBoxes = (data: any) => {
    setChkData({
      ...chkData,
      ...data,
    });
  };
  return (
    <div>
      <div className="content-wrapper content-with-table">
        <ButtonLink onClick={() => onBack()}>
          <i className={`${bizIcons.ARROWLEFT} fs-20 mr-8`} />
          Back to Reports
        </ButtonLink>
        <div className="auth-header bsl-4">
          <div>
            <span className={"auth-header_title"}>Share</span>
          </div>
        </div>
        <div className="auth-form">
          <Form
            form={form}
            onLoad={() => validateForm()}
            name="normal_login"
            className="login-form form-grid-layout"
            onFinish={onFinish}
          >
            <Row>
              <Col xs={24}>
                <CheckBoxes data={chkData} onChange={onChangeCheckBoxes} />
              </Col>
            </Row>
            <Row>
              <Divider />
              <Col xs={24} md={12}>
                <Form.Item className="bsl-4">
                  <div className="label-wrapper">
                    <label htmlFor="email">Enter email*</label>
                  </div>

                  <Form.Item
                    name="email"
                    rules={[
                      {
                        type: "email",
                        message: "Please check email format",
                      },
                      {
                        required: true,
                        message: "Please fill in email",
                      },
                    ]}
                  >
                    <Input
                      id={"email"}
                      prefix={<i className="ri-mail-line mr-10 fs-20" />}
                      placeholder="Email"
                      size={"middle"}
                      onChange={(event) => {
                        validateForm("email");
                      }}
                    />
                  </Form.Item>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col xs={24} md={12}>
                <Form.Item>
                  <Button
                    className="full-width fs-16 tsl-4"
                    type="primary"
                    htmlType="submit"
                    loading={isLoading}
                    disabled={isDisabled || isLoading}
                    icon={
                      <SaveButtonText withIcon={false}>
                        <>
                          <i className={`${bizIcons.SHARE} mr-8 fs-20`} />
                          Share report
                        </>
                      </SaveButtonText>
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </div>
  );
});

export default withRouter(ShareReportPage);
