import { Popover } from "antd";
import { AppRoutes } from "helpers/app.routes";
import React from "react";
import { Link } from "react-router-dom";
import ButtonLink from "../../components/ButtonLink";
import { bizIcons as tb } from "../../constants/bizConstants";

export const tableCustomersColumns = (t: any, broken?: boolean) => {
  const columns = [
    {
      title: t("bizBase.name"),
      dataIndex: "name",
      key: "name",
      render: (text: React.ReactNode, record: any) => {
        const fullName = record?.firstName + " " + record?.lastName;
        return (
          <div>
            <Popover
              overlayClassName={`popover-ellipsis ${
                broken || !fullName ? "display-none" : ""
              }`}
              content={fullName}
              trigger="hover"
            >
              <div className="ellipsis">{fullName}</div>
            </Popover>
            {broken && (
              <Link to={AppRoutes.customerId(record.id)}>
                <ButtonLink style={{ cursor: "pointer", fontWeight: 400 }}>
                  {t("bizBase.view")}
                  <i
                    className="ri-arrow-drop-right-line"
                    style={{ fontSize: "16px" }}
                  />
                </ButtonLink>
              </Link>
            )}
          </div>
        );
      },
    },
    {
      title: (
        <div>
          <i key={Math.random()} className={tb.USER5} />
          {t("customers.childName")}
        </div>
      ),
      dataIndex: "childName",
      key: "childName",
      render: (text: React.ReactNode, record: any) => {
        const names = record?.children
          ?.map((el: any) => el?.firstName + " " + el?.lastName)
          .join(", ");
        return (
          <>
            {names && (
              <Popover
                overlayClassName={`popover-ellipsis ${
                  !names ? "display-none" : ""
                }`}
                content={names}
                trigger="hover"
              >
                <div className="ellipsis mr-8">
                  {broken && (
                    <i
                      className={tb.USER5}
                      style={{ fontSize: "16px", marginRight: "8px" }}
                    />
                  )}
                  {names}
                </div>
              </Popover>
            )}
          </>
        );
      },
    },
    {
      title: (
        <div>
          <i key={Math.random()} className={tb.SMARTPHONE} />
          {t("bizBase.phone")}
        </div>
      ),
      dataIndex: "mobile",
      key: "mobile",
      render: (text: any) =>
        text && (
          <Popover
            overlayClassName={`popover-ellipsis ${
              broken || !text ? "display-none" : ""
            }`}
            content={text}
            trigger="hover"
          >
            <div className="ellipsis">
              {broken && (
                <i
                  className={tb.SMARTPHONE}
                  style={{ fontSize: "16px", marginRight: "8px" }}
                />
              )}
              {text.replace("+852", "+852 ")}
            </div>
          </Popover>
        ),
    },
    {
      title: (
        <div>
          <i key={Math.random()} className={tb.MAIL} />
          {t("bizBase.email")}
        </div>
      ),
      key: "email",
      dataIndex: "email",
      render: (text: any) =>
        text && (
          <Popover
            overlayClassName={`popover-ellipsis ${
              broken || !text ? "display-none" : ""
            }`}
            content={text}
            trigger="hover"
          >
            <div className="ellipsis">
              {broken && (
                <i
                  className={tb.MAIL}
                  style={{ fontSize: "16px", marginRight: "8px" }}
                />
              )}
              {text}
            </div>
          </Popover>
        ),
    },
    {
      title: (
        <div>
          <i key={Math.random()} className={tb.BUILDING} />
          {t("bizBase.location")}
        </div>
      ),
      ellipsis: !broken,
      dataIndex: "location",
      key: "location",
      render: (text: any) =>
        text && (
          <Popover
            overlayClassName={`popover-ellipsis ${
              broken || !text ? "display-none" : ""
            }`}
            content={text}
            trigger="hover"
          >
            <div className="ellipsis">
              {broken && (
                <i
                  className={tb.BUILDING}
                  style={{ fontSize: "16px", marginRight: "8px" }}
                />
              )}
              {text}
            </div>
          </Popover>
        ),
    },
    {
      title: (
        <div className={`${!broken ? "flex-align-items none-ellipsis" : ""}`}>
          <i key={Math.random()} className={tb.GLOBE} />
          {t("customers.language_preference")}
        </div>
      ),
      ellipsis: !broken,
      dataIndex: "preferredLanguage",
      key: "preferredLanguage",
      render: (text: any) =>
        text && (
          <Popover
            overlayClassName={`popover-ellipsis ${
              broken || !text ? "display-none" : ""
            }`}
            content={text}
            trigger="hover"
          >
            <div className="ellipsis">
              {broken && (
                <i
                  className={tb.GLOBE}
                  style={{ fontSize: "16px", marginRight: "8px" }}
                />
              )}
              {t("languages." + text)}
            </div>
          </Popover>
        ),
    },
  ];

  if (!broken) {
    columns.push({
      // @ts-ignore
      width: "120px",
      title: t("bizBase.action"),
      key: "action",
      dataIndex: "action",
      render: (text: React.ReactNode, record: any) => {
        return (
          <Link to={AppRoutes.customerId(record.id)}>
            <ButtonLink style={{ cursor: "pointer" }}>
              {t("bizBase.view")}
              <i
                className="ri-arrow-drop-right-line"
                style={{ fontSize: "16px" }}
              />
            </ButtonLink>
          </Link>
        );
      },
    });
  }

  return columns;
};
