import ButtonLink from "components/ButtonLink";
import {
  CHECKBLOCKTYPES,
  countOfValuesEnum,
  IChkBlockProps,
  IControlProps,
} from "components/CheckBoxes/ChkBlock";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import { CITY_DATA } from "pages/signup/interface";
import React, { useEffect, useState } from "react";
import { TFunction, useTranslation } from "react-i18next";
import courseStore from "scripts/mobx/courseStore";
import providerStore from "scripts/mobx/providerStore";
import { Filters } from "../../../../components/Filters";
import { filterModels } from "../filters/filterModels";
import { experimentals } from "config/experimentals";

// @ts-ignore
const getTableFiltersData: (
  userType: boolean,
  getSuburbList: any,
  categories: any,
  providers: any,
  t: TFunction<"translation">,
) => IChkBlockProps = (userType, getSuburbList, categories, providers, t) => {
  const filters = {
    categoryId: {
      title: t("courses.category"),
      countOfValues: countOfValuesEnum.Single,
      onChange: async (all: any, currentNewData: any) => {
        const catId = currentNewData.categoryId.controlsList.find(
          (el: IControlProps) => {
            return el.active;
          },
        )["value"];

        const subCatList = categories?.filter((el: any) => el.id === catId)?.[0]
          ?.subCategories;

        const _controlsList = subCatList
          ? subCatList.map((el: any) => {
              return {
                value: el.id,
                placeholder: "subCategories." + el.name,
                active: false,
                default: false,
              };
            })
          : [];

        if (_controlsList.length) {
          _controlsList.unshift({
            value: "",
            placeholder: "bizBase.all",
            active: true,
            default: true,
          });
        }

        return {
          ...all,
          // @ts-ignore
          subCategory: { ...all.subCategory, controlsList: _controlsList },
        };
      },
      controlsList: [
        {
          value: "",
          active: true,
          default: true,
          placeholder: "bizBase.all",
        },
        ...categories?.map((el: any) => {
          return {
            value: el.id,
            placeholder: "categories." + el.name,
            active: false,
            default: false,
          };
        }),
      ],
    },
    subCategory: {
      title: t("courses.subCategory"),
      countOfValues: countOfValuesEnum.Array,
    },

    Age: {
      title: t("courses.age"),
      countOfValues: countOfValuesEnum.Array,
      controlsList: [
        {
          value: "",
          active: true,
          default: true,
          placeholder: "bizBase.all",
        },
        ...filterModels.age,
      ],
    },

    suburb: {
      title: t("bizBase.location"),
      countOfValues: countOfValuesEnum.Array,
      controlsList: [
        {
          value: "",
          active: true,
          default: true,
          placeholder: "bizBase.all",
        },
        {
          value: "0",
          active: false,
          default: false,
          placeholder: "courses.online",
        },
        {
          value: "-3",
          active: false,
          default: false,
          placeholder: "bizBase.onDemand",
        },
        {
          value: "-2",
          active: false,
          default: false,
          placeholder: "bizBase.parentHome",
        },
        ...getSuburbList,
      ].filter((item) => {
        if (experimentals.on_demand_location) return true;
        return item.value !== "-3";
      }),
    },

    dateFromTo: {
      title: t("bookings.dateFromTo"),
      combined: true,
      controlsList: [
        {
          value: "",
          active: true,
          default: true,
          placeholder: "bizBase.all",
        },
        {
          value: toJS(courseStore?.dataFilters?.dateForm),
          markId: "dateFrom",
          type: CHECKBLOCKTYPES.DATE,
          dependsOn: {
            field: {
              name: "dateFromTo",
              id: 2,
            },
            condition: (data: any) => {
              const { value, current } = data;
              return value && current && current > value?.startOf("day");
            },
          },
          placeholder: "bookings.dateFrom",
        },
        {
          value: toJS(courseStore?.dataFilters?.dateTo),
          markId: "dateTo",
          type: CHECKBLOCKTYPES.DATE,
          placeholder: "bookings.dateTo",
          dependsOn: {
            field: {
              name: "dateFromTo",
              id: 1,
            },
            condition: (data: any) => {
              const { value, current } = data;
              return value && current && current < value?.startOf("day");
            },
          },
        },
      ],
    },

    providerId: {
      title: t("menu.kidzbiz"),
      countOfValues: countOfValuesEnum.Array,
      controlsList: userType && providers,
    },
    status: {
      title: t("bizBase.status"),
      countOfValues: countOfValuesEnum.Array,
      controlsList: [
        {
          value: "",
          active: true,
          default: true,
          placeholder: "bizBase.all",
        },
        ...filterModels.status?.map((el: any) => {
          return {
            value: el.value,
            placeholder: "courses.status." + el.value,
            active: false,
            default: false,
          };
        }),
      ],
    },
  };
  return filters;
};

interface ICoursesFiltersProps {
  onApply: (data: any) => void;
  userType: boolean;
}
export const CurrentCoursesFilters = observer(
  (props: ICoursesFiltersProps): React.ReactElement => {
    const { onApply, userType } = props;
    const { t } = useTranslation();
    const [tableFiltersData, setTableFiltersData] = useState<IChkBlockProps>(
      toJS(courseStore?.dataFilters),
    );

    useEffect(() => {
      (async () => {
        const suburbs: any[] = [];
        CITY_DATA.forEach((el) => {
          suburbs.push(...el.suburbs);
        });

        const newSuburbs = suburbs.map((el: any) => {
          return { value: el.value, placeholder: "suburbs." + el.value };
        });
        await providerStore?.getProviders();
        await courseStore.getCategories().then((res) => {
          const tableFiltersData = getTableFiltersData(
            userType,
            newSuburbs,
            res || [],
            toJS(providerStore?.providerList)?.map((el: any) => {
              return {
                value: el?.id,
                placeholder: el?.legalName,
                active: false,
                default: false,
              };
            }),
            t,
          );

          // tslint:disable-next-line:forin
          for (const filterKey in tableFiltersData) {
            /** ADDING 'ALL' BUTTONS */
            if (
              tableFiltersData[filterKey]?.controlsList &&
              // @ts-ignore
              !tableFiltersData[filterKey]?.controlsList[0]?.default
            ) {
              // @ts-ignore
              tableFiltersData[filterKey].controlsList.unshift({
                value: "",
                placeholder: "bizBase.all",
                active: true,
                default: true,
              });
            }

            setTableFiltersData(tableFiltersData);
          }
        });
      })();
    }, []);

    const demapFilterData = (data: any): any => {
      const _data = {};

      Object.keys(data).forEach((key) => {
        if (!data[key].combined) {
          _data[key] = data[key].controlsList?.map(
            (el: IControlProps) => el.value,
          );
        } else {
          data[key].controlsList?.forEach((el: IControlProps) => {
            if (el.markId) {
              _data[el.markId] = el.value;
            }
          });
        }
      });

      return _data;
    };

    const onApplyFilters = (data: any) => {
      const _data = { ...demapFilterData(data) };
      onApply(_data);
    };

    const onSetFilter = (data: any) => {
      setTableFiltersData({ ...tableFiltersData, ...data });
      courseStore?.setFilters({ ...tableFiltersData, ...data });
    };

    return tableFiltersData ? (
      <Filters
        onSetFilter={onSetFilter}
        onApply={onApplyFilters}
        onReset={() => courseStore?.setFilters()}
        filterBlocks={tableFiltersData}
        filterModel={toJS(courseStore?.dataFilters)}
      />
    ) : (
      <div>
        <ButtonLink disabled={true}>
          {t("bizBase.filters", {
            defaultValue: "Filters",
          })}
          <i
            className={"ri-arrow-drop-down-line"}
            style={{ fontSize: "20px" }}
          />
        </ButtonLink>
      </div>
    );
  },
);
