import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const options = {
    cleanCode: true,
    debug: false,
    defaultNS: 'base',
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false, // not needed for react!!
        format: (value, format, lng) => {
            if (format === 'uppercase') {
                return value.toUpperCase();
            }
            return value;
        },
        formatSeparator: ',',
    },
    lazy: process.env.NODE_ENV !== 'development',
    load: 'languageOnly',
    ns: ['base'],
    saveMissing: process.env.NODE_ENV === 'development',
    wait: process && !process.release,
};

// for browser use http backend to load translations and browser lng detector
if (process && !process.release) {
    i18n
        .use(Backend)
        .use(initReactI18next)
        .use(LanguageDetector);
}

// initialize if not already initialized
if (!i18n.isInitialized) {
    i18n.init(options);
}

export default i18n;