import { PAGE_SIZE } from "config";
import { DETAIL_API_URL } from "constants/apiUrl";
import requestApi from "helpers/requestApi";
import { action, computed, makeObservable, observable } from "mobx";
import { createContext } from "react";
import { pageable } from "./courseStore";

export const customerCoursepageable = {
  pageNumber: 0,
  pageSize: PAGE_SIZE,
  sortField: "id",
  sortDirection: "desc",
};

export class CustomersStore {
  constructor() {
    makeObservable(this);
  }

  @observable
  public customersData: any = null;

  @observable
  public customer: any = [];

  @observable
  public filterModel: any = {
    pageable: pageable,
  };

  @observable
  public filterModelCourseCustomer: any = customerCoursepageable;

  @observable
  public errorHandler: string = "";

  @observable
  public dataFilters: any = null;

  @observable
  public courseList: any = null;

  @action
  public async setFilters(filter?: any) {
    this.dataFilters = filter;
  }

  @action
  public async getProviderCustomers(filter?: any) {
    this.filterModel = filter;
    const params = {
      pageable: pageable,
    };

    const result = await requestApi.post(DETAIL_API_URL.CUSTOMER_LIST, {
      ...params,
      ...filter,
    });
    if (result?.status === 200) {
      this.customersData = result?.data || [];
      return Promise.resolve(result?.data);
    } else {
      return Promise.reject();
    }
  }

  @action
  public async getProviderCourseCustomers(id: string | number, filter?: any) {
    this.filterModelCourseCustomer = filter;
    const params = {
      ...customerCoursepageable,
    };

    const result = await requestApi.post(DETAIL_API_URL.COURSE_CUSTOMER(id), {
      ...params,
      ...filter,
    });
    if (result?.status === 200) {
      this.customersData = result?.data || [];
      return Promise.resolve(result?.data);
    } else {
      return Promise.reject();
    }
  }

  @action
  public async updateCustomersFilterData(data?: any) {
    //
  }

  @action
  public async getProviderCustomerById(id: string | number) {
    const result = await requestApi.get(DETAIL_API_URL.CUSTOMER_DETAIL(id));
    if (result?.status === 200) {
      this.customer = result?.data || [];
      return Promise.resolve(result?.data);
    } else {
      return Promise.reject();
    }
  }

  @action
  public async exportCustomers() {
    //
  }

  @action
  public async getCustomerCourses(id: string | number, filter?: any) {
    const params = {
      pageable: customerCoursepageable,
    };

    const result = await requestApi.post(DETAIL_API_URL.CUSTOMER_COURSE(id), {
      ...params,
      ...filter,
    });
    if (result?.status === 200) {
      this.courseList = result?.data;
      return Promise.resolve(result?.data);
    } else {
      return Promise.reject();
    }
  }

  @action
  public async clearFilter() {
    this.filterModel = {
      pageable: pageable,
    };
  }

  @computed
  public get getData() {
    return this.customersData;
  }

  @computed
  public get getCustomerData() {
    return this.customer;
  }
}

const customersStore = new CustomersStore();

export const customersStoreContext = createContext(customersStore);
export default customersStore;
