import React from "react";
import { Typography } from "antd";
import { tableAgreeColumns, tableAgreeData } from "./tableGenerator";
import TableComponent from "../../../../components/TableComponent/TableComponent";
const { Title } = Typography;

const KidzbizAgreement = () => {
  return (
    <>
      <Title>Kidzbiz Agreement</Title>

      <div>
        <div className={"bsl-3 tsl-3"}>
          <b>Parties to the Agreement</b>
        </div>
        <div className={"bsl-3"}>
          By creating a login, you are agreeing to be bound by the terms of this
          Kidzbiz Agreement ("Agreement"), effective on the date of your login
          creation (“Effective Date”), between Bizibuz Hong Kong Limited or any
          group companies affiliated with Bizibuz Hong Kong Limited ("Bizibuz")
          and yourself (the "Activity Provider"). Bizibuz and the Activity
          Provider are collectively referred to herein as the "Parties”. For the
          avoidance of doubt, this Kidzbiz Agreement also applies to the
          respective holding companies, subsidiaries, affiliates, agents,
          partners, licensors, representatives, officers, directors and
          employees of Bizibuz and the Activity Provider.
        </div>
        <div className={"bsl-3 tsl-3"}>
          <b>Background</b>
        </div>
        <div className={"bsl-3"}>
          Bizibuz is engaged in the business of being a digital marketplace for
          educational, athletic or cultural activities to children through the
          operation of www.bizibuz.com (the “Website”), its various subdomains
          including but not limited to the Kidzbiz subdomain located at
          kidzbiz.bizibuz.com (the “Kidzbiz Portal”) and potentially also other
          Bizibuz branded apps (together, the “Bizibuz Platform”). Being a
          digital marketplace means that Bizibuz provides the digital
          infrastructure for Activity Providers to upload information regarding
          their business and advertise courses for sale to the general public.
          Bizibuz makes available to Activity Providers various tools to analyse
          their business performance across metrics such as promotional
          offerings, customer demographics, courses and venues, and analyse web
          traffic metrics. Bizibuz also provides the digital infrastructure for
          the general public to build and track the proficiency profile of
          children, and to schedule, purchase and review courses (comprised of
          one class or a series of classes) (collectively, “Activities”) from
          Activity Providers.
        </div>
        <div className={"bsl-3"}>
          The Activity Provider warrants it provides educational, athletic or
          cultural activities to children. By entering into this Agreement, the
          Activity Provider is enrolling in the Bizibuz’s Activity Provider pool
          and engaging Bizibuz as a marketplace intermediary to offer for sale
          Activities to customers of the Website. The Activity Provider is
          neither an agent, partner, joint venture, in a fiduciary relationship
          with nor employee of Bizibuz. The relationship between Bizibuz and the
          Activity Provider is merely that of an independent contractor solely
          for the purposes and to the extent set forth in this Agreement.
        </div>
        <div className={"bsl-3"}>
          An Activity Provider agrees it has a choice to enrol in the Bizibuz
          Platform via creation of a Bizibuz login whereby the account is
          entirely managed by one account associated with the Activity Provider
          (“Stand-alone Account”) or via creation of a Bizibuz login which
          allows for management of some parts of the account by more than one
          account (a “Linked Account” concept). This may be relevant for (i)
          Activity Providers that wish to delegate course creation and
          management to specific employees, (ii) to franchise arrangements, or
          (iii) where there is a single venue that is subcontracted out to
          numerous Activity Providers. The Linked Account operates such that one
          Activity Provider (the “Inviter”) first creates its own login and
          subsequently invites other accounts (the “Invitee(s)”) to have
          read-write access over certain portions of the account.
        </div>
        <div className={"bsl-3"}>
          Only an Inviter or their designated employees has read-write
          capabilities over the organisation profile and branding, and can
          implement promotions that are based on bulk purchase of Activities
          through the Inviter brand. By accepting an invitation from an Inviter,
          an Invitee is agreeing to be bound by such details managed by an
          Inviter.
        </div>
        <div className={"bsl-3"}>
          An Invitee has the ability to upload a class schedule, receive and
          respond to customer booking requests, onboard a Stripe account to
          receive monthly payments from the Bizibuz Platform, can implement
          promotions that are specific to the Activities they are uploading and
          managing, and has flexibility to set purchase conditions and policies
          such as cancellation, absence, weather, media consent, safety etc.
        </div>

        <div className={"bsl-3"}>
          By extending an invitation to an Invitee, an Inviter is agreeing to be
          bound by details managed by an Invitee including course specifics,
          customer signups, course promotions and payment details. An Inviter
          has visibility over all Invitee actions undertaken on the Bizibuz
          Platform including associated business performance analytics. An
          Invitee only has visibility over their own actions and relevant
          business performance analytics undertaken on the Bizibuz Platform. The
          reference to relevant Activity Provider below, is an acknowledgement
          of functionally different user rights associated with Inviter and
          Invitee accounts.
        </div>

        <div className={"bsl-3"}>
          An Activity Provider understands they cannot have both a Stand-Alone
          Account login and a Linked Account login. This Agreement applies
          equally irrespective of whether the Bizibuz login is based on a
          Stand-Alone Account or Linked Account, and accordingly applies to both
          Inviter, Invitee and Stand-Alone Accounts.
        </div>

        <div className={"bsl-3"}>
          For good and valuable consideration, the adequacy of which is hereby
          acknowledged, the Parties agree as follows:
        </div>
      </div>
      <div className={"bizibuz-policies-lists"}>
        <ol>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
              Commencement
            </Title>
            <ol>
              <li>
                This Agreement commences on the Effective Date and will continue
                until it is terminated by either Party in accordance with Clause
                8 below.
              </li>
            </ol>
          </li>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
              Services to be Performed by Bizibuz
            </Title>
            <div className="bsl-4 tsl-4">
              On a best efforts basis Bizibuz will:
            </div>
            <ol>
              <li>
                Display information relevant to carrying out services outlined
                above as provided by the Activity Provider on the Website. An
                Activity Provider that is deemed eligible by Bizibuz to
                participate in an automated course upload program (“Automated
                Course Upload”) will be enrolled upon the initial agreement of
                the assumptions that apply to such uploads.
              </li>
              <li>
                Receive booking requests from customers for classes and
                activities (each an “Activity booking”) through the web-based
                ordering system, and will communicate each such booking to the
                relevant Activity Provider in a timely fashion through the
                Kidzbiz Portal or other agreed notification method for
                acceptance or modification by the Activity Provider.
              </li>
              <li>
                Endeavour to remit corresponding amounts owed under Clause 4.5
                on a monthly basis to the relevant Activity Provider through a
                third party payment platform such as Stripe.
              </li>
              <li>
                Periodically run marketing campaigns that promote and encourage
                customer traffic to the Website.
              </li>
              <li>
                Make available to each Activity Provider the business
                performance statistics that summarize the relevant Activity
                Provider’s business conducted through the Bizibuz Platform.
              </li>
            </ol>
          </li>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
              Services to be Performed by the Activity Provider
            </Title>
            <ol>
              <li>
                The Activity Provider will upload information regarding its
                business which may include, but is not limited to, its venues,
                cancellation/absence/weather policies and Activities to the
                Bizibuz Platform through the Kidzbiz Portal, and the Activity
                Provider will keep such content current, accurate and
                appropriate.
              </li>
              <li>
                The Activity Provider agrees to be responsible for notifying
                Bizibuz in a timely fashion of any change to the assumptions
                being used for the Automated Course Upload.
              </li>
              <li>
                Upon receipt of an Activity order from a customer, the relevant
                Activity Provider (being the Activity Provider that uploaded the
                Activity onto the Bizibuz Platform) shall either accept or
                modify the booking request as soon as possible through the
                Activity Provider portal. If the relevant Activity Provider has
                not accepted or modified a booking request within the lesser of
                120 hours after booking or 2 hours prior to the start of the
                next scheduled performance of that Activity, the booking will be
                automatically rejected and thereby cancelled.
              </li>
              <li>
                Upon accepting a booking, the relevant Activity Provider will
                subsequently perform the Activity for the child on whose behalf
                the Activity was purchased according to the highest possible
                standard of quality.
              </li>
            </ol>
          </li>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
              Payments
            </Title>
            <ol>
              <li>
                <u>Bizibuz Fee.</u>
                <br /> Bizibuz charges a fee based on a percentage of the
                consideration paid by the customer for the respective Activity
                booked through the Website for multi class courses or a flat fee
                for single class bookings (the “Bizibuz Fee”). The Pricing
                Schedule is located in Appendix 1.
              </li>
              <li>
                <u>Cancellation of Activity.</u>
                <br /> The Activity Provider or the customer can cancel an
                Activity booked by a customer through the Bizibuz Website.
                <br />
                If an Activity is cancelled, the customer will be treated in
                accordance with elections made by the Activity Provider under an
                area called “Rescheduling Outcomes” at the time the customer
                booked the Activity. If the Activity Provider elects to issue a
                refund, the customer will receive a payment from Bizibuz. If the
                Activity Provider elects to issue a credit, the customer will
                automatically have the credit applied at future checkout against
                courses purchased from the same Activity Provider. <br />
                If the Activity is cancelled by the Activity Provider and is a
                course or booking for a course with status of “In progress” (has
                at least one accepted booking), Bizibuz will charge the Activity
                Provider a Cancellation Fee per cancelled course attendee per
                cancelled course. <br />
                It is the responsibility of the Activity Provider to ensure
                their purchase conditions and policies are consistent with the
                Activity Provider’s compensation elections made under
                “Rescheduling Outcomes”. To the extent there is any
                inconsistency, the Activity provider understands that their
                elections made under “Rescheduling Outcomes” will govern the
                compensation provided to the customer under the Bizibuz
                platform. In all cases, the Activity Provider acknowledges it is
                their responsibility to come to a satisfactory resolution for
                any schedule or Activity detail changes that may impact their
                customers at terms that are no worse than the relevant Activity
                Provider’s stated policies and elections made under
                “Rescheduling Outcomes”.
              </li>
              <li>
                <u>Non-Response.</u> <br />
                If an Activity Provider fails to respond to a customer booking
                request through the Activity Provider portal either by failing
                to accept or modify the requested booking details, Bizibuz may
                charge the Activity Provider a Non Response Fee per booking at
                the time of the booking auto cancellation.
              </li>
              <li>
                <u>Booking Rejection.</u> <br />
                If an Activity Provider rejects a customer booking request or
                where a booking is automatically rejected under Clause 3.3, the
                customer will receive a refund for all unattended classes from
                Bizibuz and the Activity Provider will accordingly not receive
                any relevant consideration paid by the customer for unattended
                Activities. There will be no Bizibuz Fee levied on the relevant
                Activity Provider if the booking was for a multi class course
                only.
              </li>
              <li>
                <u>Monthly Remittance.</u>
                <br /> Bizibuz will endeavour to remit the aggregate accrued
                consideration paid by the customer associated with the Activity
                Provider (revenue earned in respect of classes that were
                attended in that month) less the Bizibuz Fee (applicable to
                classes that occurred in that month), less any Cancellation Fees
                on a monthly basis to the Activity Provider. The Activity
                Provider is responsible for paying any monthly account or
                withdrawal fees levied by Stripe or the third party payment
                platform. Where there is insufficient consideration paid by
                customers in any given month to cover the applicable fees,
                Bizibuz reserves the right to set off the fees against
                consideration paid by customers received in future months.
              </li>
              <li>
                Bizibuz reserves the right to change the Bizibuz Fee,
                Cancellation Fee or Non Response Fee amounts at any time.
              </li>
            </ol>
          </li>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
              Indemnification
            </Title>
            <div className="bsl-4 tsl-4">
              The Activity Provider agrees to indemnify and hold harmless
              Bizibuz against any losses, claims, damages, liabilities or
              expenses (including the cost of investigating and defending any
              claims and legal and counsel fees (on a full indemnity basis)
              incurred in connection therewith), joint or several, direct or
              indirect, suffered or incurred by:
            </div>
            <ol>
              <li>
                Bizibuz or any customer that has purchased or booked Activities
                on the Website, by reason of any injury to person or damage to
                property caused by the negligence or misconduct of the Activity
                Provider, its employees, agents or independent contractors,
                arising or resulting from a breach by the Activity Provider of
                the Kidzbiz Agreement or performance of an Activity purchased on
                the Website by the Activity Provider, its employees, agents or
                independent contractors. The Activity Provider further agrees
                Bizibuz shall not be liable, directly or indirectly, for any
                shortcomings of regulatory, legal or administrative standards of
                safety, licensing including employment qualifications, mistakes,
                defamation, libel, slander, omissions, falsehoods or profanity
                by the Activity Provider or connected with their performance of
                the stated Activity;
              </li>
              <li>
                Any third party due to or arising out of the Activity Provider’s
                access to the Bizibuz Platform, use of the Bizibuz Platform or
                its Services, or the infringement of any intellectual property
                or other right of any person or entity;
              </li>
              <li>
                The Activity Provider as a result of any comments, reviews,
                actions or omissions by any member of the general public arising
                out of their access to the Bizibuz Platform, the failure of
                Bizibuz to deliver correspondence or payment in a timely fashion
                to the Activity Provider through the Bizibuz Platform, any
                interrupted functioning or availability of the Bizibuz Platform,
                the loss of any data from the Bizibuz Platform or any security
                breach of the Bizibuz Platform or the Activity Provider’s
                account.
              </li>
            </ol>
          </li>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
              Warranties, Representations and Undertakings
            </Title>
            <ol>
              <li>
                The Activity Provider warrants that it, its employees, agents or
                independent contractors, and the premises used for performance
                of the Activity, are appropriately licensed and meet all safety,
                legal and regulatory requirements for the purpose of performing
                the relevant Activities.
              </li>
              <li>
                The Activity Provider further represents it has adequate public
                liability insurance and employee compensation insurance.
              </li>
              <li>
                The Activity Provider warrants and undertakes to Bizibuz that
                they own or have the necessary rights, licenses, consents,
                releases and/or permissions to use and authorize Bizibuz to use
                all copyright, trademark or other proprietary or intellectual
                property rights in any content they upload on to the Bizibuz
                Platform. By uploading such content, the Activity Provider
                grants Bizibuz a license for use of the content on the Bizibuz
                Platform. The copying, transmission, reproduction, replication,
                translation, publication, broadcast, modification, licensing,
                sale, creation of derivative works from such content by the
                general public accessing the Website is strictly prohibited
                under the Terms and Conditions of the Bizibuz Platform without
                the express written consent of the intellectual property owner.
                However, it is the responsibility of the Activity Provider to
                attach a copyright notice to such content when uploaded to the
                Bizibuz Platform.
              </li>
              <li>
                The Activity Provider is solely responsible for withholding,
                filing, and reporting all taxes, duties and other governmental
                assessments associated with their activity in connection with
                using the Bizibuz Platform.
              </li>
              <li>
                The Activity Provider is solely responsible for creating backup
                copies of and replacing any content they post or store on the
                Website at their cost and expense. The Activity Provider
                understands that by deactivating their Bizibuz account,
                delinking their account from an Inviter or Invitee, or if
                Bizibuz blocks the Activity Provider from accessing the Bizibuz
                Platform, the Activity Provider may lose the ability to access
                historic data reflecting their use of the Bizibuz Platform. The
                Activity Provider is also solely responsible for maintaining the
                confidentiality of their password.
              </li>
              <li>
                The Activity Provider warrants and undertakes when they agree to
                make alternative arrangements with a customer after the
                cancellation of an Activity, that those arrangements leave the
                customer no worse off than they would have been under the
                relevant cancellation or absence policy related to the Activity.
              </li>
              <li>
                An Inviter warrants it understands and accepts responsibility
                for extending an invitation to an Invitee under a Linked Account
                as it pertains to allowing an Invitee to upload class schedules
                under the brand of the Inviter, receive and respond to customer
                booking requests, nominate a bank account to receive monthly
                payments from the Bizibuz Platform, and implement promotions
                that are specific to the Activities they are uploading and
                managing. An Invitee warrants it understands and accepts
                responsibility for accepting an invitation from an Inviter as it
                pertains to allowing an Inviter to control the organization
                branding, operational policies, and bulk Activity purchase
                discounts that apply to the Invitee, and by granting visibility
                over all actions by an Invitee to the Inviter including data on
                their customers, transactions and business performance. Each
                Inviter and Invitee warrant and undertake to Bizibuz that there
                is a separate agreement between the Inviter and each
                corresponding Invitee governing the nature, roles and
                responsibilities of both in relation to each other. To the
                extent the actions or omissions of either the Inviter or Invitee
                in connection with their use of the Bizibuz Platform is contrary
                to that separate agreement, each Inviter and each corresponding
                Invitee agrees to indemnify and hold harmless Bizibuz against
                any losses, claims, damages, liabilities or expenses (including
                the cost of investigating and defending any claims and all legal
                and counsel fees (on a full indemnity basis) incurred in
                connection therewith), joint or several, direct or indirect,
                suffered or incurred by Bizibuz, the Inviter or its Invitees, or
                a third party.
              </li>
            </ol>
          </li>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
              Additional Covenants & Obligations
            </Title>
            <ol>
              <li>
                <u>Accurate Activity Information.</u> The Activity Provider
                agrees to keep all information about the Activities uploaded
                either by themselves or under the Automated Course Upload
                program as current and accurate as possible, particularly in
                regards to the schedule, pricing, promotions and availability of
                spots for booking.
              </li>
              <li>
                <u>Active Course Content.</u> The Activity Provider covenants to
                regularly upload new courses in order to maintain an active
                presence on the digital marketplace.
              </li>

              <li>
                <u>Exclusivity.</u> While the Agreement is in effect, the
                Activity Provider agrees to exclusively use Bizibuz as the sole
                digital marketplace for its Activities, where digital
                marketplace refers to a website that facilitates direct booking
                and payment for Activities and excludes the Activity Provider’s
                own website.
              </li>
              <li>
                <u>Lowest Offer Price.</u> The Activity Provider agrees to offer
                Activities through the Bizibuz Platform at a price that is equal
                to or less than the lowest price offered either directly or
                indirectly to customers. In accordance, the Activity Provider
                agrees to implement or grants permission to Bizibuz to implement
                promotions that might be available to customers away from the
                Bizibuz platform, and also implement such promotions on the
                Bizibuz platform. The form of the promotion implementation
                should be in as close a form as possible to the form offered
                away from the Bizibuz platform. Similarly in accordance, if a
                customer sources a lower price for a course away from the
                Bizibuz Platform, the Activity Provider agrees to lower the
                price of the course on the Bizibuz Platform in order to match
                the price away.
              </li>
              <li>
                <u>No Inducement.</u> The Activity Provider, its employees,
                agents or independent contractors, agree not to solicit, induce,
                or influence any prior customer of the Bizibuz Platform to no
                longer make use of the marketplace for ordering Activities and
                instead use a direct or indirect alternative means of ordering
                Activities. Bizibuz reserves the right to use cookies to track
                compliance by Activity Providers with this provision. See
                Bizibuz’s Cookie Policy for more information.
              </li>
              <li>
                <u>Bizibuz Fee.</u> The Activity Provider covenants with Bizibuz
                that where a prior customer of the Bizibuz Platform is found to
                have purchased Activities directly from the Activity Provider
                within 3 months of ceasing to use the Bizibuz Platform for
                similar order behavior, the Activity Provider shall pay Bizibuz
                as liquidated damages a sum equal to the Bizibuz Fee receivable
                by Bizibuz had the customer paid such for the respective
                Activity as though the Activity were booked through the Bizibuz
                Platform.
              </li>
              <li>
                <u>Assignment.</u> Neither Party shall assign any of its rights
                and obligations under this Agreement without the prior approval
                of the other Party. The Parties to this Agreement together with
                their respective successors and permitted assigns shall continue
                to be subject to this Agreement.
              </li>
              <li>
                <u>Confidential Information.</u> The terms of this Agreement are
                confidential and may not be disseminated further by the Activity
                Provider, its employees, agents or independent contractors
                without the written consent of Bizibuz unless required by law.
              </li>
            </ol>
          </li>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
              Term and Termination
            </Title>
            <ol>
              <li>
                Either Party can terminate the Agreement at any time. An
                Activity Provider can opt out of the Kidzbiz Agreement by
                requesting that the Bizibuz team deactivate their Bizibuz
                Platform login. Bizibuz reserves the right to block an Activity
                Provider from the Bizibuz Platform and terminate the Agreement
                at any time with no notice if the Activity Provider fails to
                perform any of the services outlined in Clause 3 or breaches any
                warranties, representations, undertakings or covenants in
                Clauses 6 and 7 above. The Activity Provider understands that
                being blocked from the Bizibuz Platform means losing certain
                functionality and visibility over areas of the Platform.
                Bizibuz’s rights and the Activity Provider’s obligations under
                Clauses 7.6, 7.7 and 7.8 shall survive the termination of this
                Agreement and continue to have effect.
              </li>
            </ol>
          </li>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
              Entire Agreement
            </Title>
            <ol>
              <li>
                This Agreement constitutes the entire Agreement between the
                Parties and supersedes and extinguishes all previous drafts,
                Agreements, arrangements and understandings between them whether
                written or oral, relating to its subject matter.
              </li>
              <li>
                Each and every obligation under this Agreement shall be treated
                as a separate obligation and shall be severally enforceable as
                such. In the event of any obligation or obligations being or
                becoming unenforceable in whole or in part, they shall be deemed
                to be deleted from this Agreement and any such deletion shall
                not affect the enforceability of the remaining provisions of
                this Agreement.
              </li>
            </ol>
          </li>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
              Governing Law
            </Title>
            <ol>
              <li>
                This Agreement shall be governed by and construed in accordance
                with the laws of the Hong Kong Special Administrative Region of
                the People’s Republic of China. Any disputes arising from this
                Agreement shall be resolved by arbitration administered by the
                Hong Kong International Arbitration Centre, under the Hong Kong
                International Arbitration Centre Administered Arbitration Rules
                then in force. The arbitration proceeds shall be conducted in
                the English language and the seat of arbitration is Hong Kong.
              </li>
              <li>
                No failure or neglect of either Party to exercise any right,
                power or privilege hereunder or under law shall constitute a
                waiver of that right, power or privilege. All waivers must be
                obtained in writing from the Party granting the waiver for it to
                be effective.
              </li>
              <li>
                The Parties agree that in the event of breach or threatened
                breach of this Agreement, remedies to either Party are not
                restricted to law and damages, and other relief, including but
                not limited to, injunctive orders may be available.
              </li>
            </ol>
          </li>
        </ol>

        <div className={"tsl-3 bsl-3"}>Last updated on: 2 July 2023.</div>

        <div className={"bsl-3 tsl-3"}>
          <div>
            <b>Appendix 1</b>
          </div>
          <div className={"bsl-3"}>Pricing Schedule</div>
          <TableComponent
            propsTableColumns={tableAgreeColumns()}
            propsTableData={tableAgreeData()}
            pagination={false}
          />
          <div className={"tsl-3"}>
            {/* * Gross transaction volume is defined as the aggregate gross
            customer spend.
            <div>
              ** Non profit organizations are entitled to the above rate on a
              permanent basis.
            </div> */}
            <div>
              Bizibuz reserves the right to change pricing rates at any time
              without notice. The fixed introductory fee is charged upfront and
              is not conditional upon attendance by the customer.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default KidzbizAgreement;
