import { message, Tabs, Typography } from "antd";
import EmptyTableContent from "components/EmptyTableContent";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import ButtonLink from "../../components/ButtonLink";
import { LayoutPage } from "../../components/Layout";
import { bizIcons } from "../../constants/bizConstants";
import { DEVICES, useDevices } from "../../scripts/custom-hooks/useDevices";
import reportsStore from "../../scripts/mobx/reportsStore";
import Bot3ByCustomersTableBlock from "./Bot3ByCustomers";
import Bot3BySpendTableBlock from "./Bot3BySpend";
import AgeSpentRatio from "./Charts/AgeSpentRatio";
import CategorySpentRatio from "./Charts/CategorySpentRatio";
import CustomersAndRevenueSummary from "./Charts/CustomersAndRevenueSummary";
import CustomerCharacteristics from "./Charts/CustomersCharacteristics";
import CustomersRevenueRatioChart from "./Charts/CustomersRevenueRatio";
import CustomerSpendByChildAge from "./Charts/CustomersSpendByChildAge";
import CustomerSpendByCategory from "./Charts/CustomersSpendByChildCategory";
import OfflineOnlineSpendSplitByChildAge from "./Charts/OfflineOnlineSpendSplitByChildAge";
import OfflineOnlineSpendSplitByCourseCategory from "./Charts/OfflineOnlineSpendSplitByCourseCategory";
import RecurringCustomersRevenueRatio from "./Charts/RecurringCustomersRevenueRatio";
import "./index.less";
import ShareReportPage from "./ShareReportPage";
import Top3ByCustomersTableBlock from "./Top3ByCustomers";
import Top3BySpendTableBlock from "./Top3BySpend";

const { TabPane } = Tabs;
const { Title } = Typography;
const Reports = inject("reportsStore")(
  observer(() => {
    const { t } = useTranslation();
    const device = useDevices();
    const [isBroken, setIsBroken] = useState<boolean | undefined>();
    const [isShare, setIsShare] = useState<boolean | undefined>();

    useEffect(() => {
      if (
        device &&
        (device === DEVICES.MOBILE || device === DEVICES.TABLET_PORT) &&
        (isBroken === false || isBroken === undefined)
      ) {
        setIsBroken(true);
      } else if (
        device &&
        device !== DEVICES.MOBILE &&
        device !== DEVICES.TABLET_PORT &&
        (isBroken || isBroken === undefined)
      ) {
        setIsBroken(false);
      }

      if (isBroken !== undefined) {
        console.log("to do isBroken");
      }
    }, [device, isBroken]);

    const onBack = () => {
      setIsShare(false);
    };

    const onShare = async (data: any) => {
      await reportsStore?.shareReport(data).then((el) => {
        message.success(
          t("reports.share_message", {
            defaultValue: "Reports are shared, check your email.",
          }),
        );
      });
      setIsShare(false);
    };

    return (
      <LayoutPage defMenuItem={"reports"}>
        {!isShare && (
          <div className="single-course">
            <div className={"flex-space-between"}>
              <Title className={"bsl-4"} level={1}>
                {t("menu.reports", { defaultValue: "Reports" })}
              </Title>
            </div>
            <div className="biz-tabs-content">
              <div>
                <Tabs defaultActiveKey="1" size={"small"}>
                  <TabPane
                    tab={t("reports.summary", { defaultValue: "Summary" })}
                    key="1"
                  >
                    <CustomersAndRevenueSummary
                      autoFit={device !== DEVICES.MOBILE}
                    />
                    <CustomerSpendByChildAge
                      autoFit={device !== DEVICES.MOBILE}
                    />
                    <CustomerSpendByCategory
                      autoFit={device !== DEVICES.MOBILE}
                    />
                    <CustomerCharacteristics
                      autoFit={device !== DEVICES.MOBILE}
                    />
                    <AgeSpentRatio autoFit={device !== DEVICES.MOBILE} />
                    <CategorySpentRatio autoFit={device !== DEVICES.MOBILE} />
                    <OfflineOnlineSpendSplitByChildAge
                      autoFit={device !== DEVICES.MOBILE}
                    />
                    <OfflineOnlineSpendSplitByCourseCategory
                      autoFit={device !== DEVICES.MOBILE}
                    />
                  </TabPane>
                  <TabPane
                    tab={t("reports.details", { defaultValue: "Details" })}
                    key="2"
                  >
                    <CustomersRevenueRatioChart
                      autoFit={device !== DEVICES.MOBILE}
                    />

                    <RecurringCustomersRevenueRatio
                      autoFit={device !== DEVICES.MOBILE}
                    />

                    <Top3BySpendTableBlock />
                    <div className={"tsl-2"} />
                    <Bot3BySpendTableBlock />
                    <div className={"tsl-2"} />
                    <Top3ByCustomersTableBlock />
                    <div className={"tsl-2"} />
                    <Bot3ByCustomersTableBlock />
                  </TabPane>
                  <TabPane
                    tab={t("reports.googleAnalytics", {
                      defaultValue: "Google Analytics",
                    })}
                    key="3"
                  >
                    <EmptyTableContent />
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </div>
        )}
        {isShare && <ShareReportPage onBack={onBack} onShare={onShare} />}
      </LayoutPage>
    );
  }),
);

export default withRouter(Reports);
