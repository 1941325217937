import {
  Button,
  Col,
  Form,
  Input,
  message,
  notification,
  Row,
  Select,
} from "antd";
import ButtonLink from "components/ButtonLink";
import { COUNTRY_DATA, PhoneCode } from "components/PhoneCode";
import SaveButtonText from "components/SaveButtonText";
import { bizIcons } from "constants/bizConstants";
import { Country } from "country-state-city";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCurrentUser } from "scripts/custom-hooks/useCurrentUser";
import helpers from "scripts/helpers";
import employeeStore, { EmployeeType } from "scripts/mobx/employeeStore";
import { Roles } from "scripts/mobx/userStore";
import EditLocations from "../../locations/editLocation";
import "./index.less";
const { Option } = Select;

interface IEditKidzBizDetailsProps {
  index: number;
  data: any;
  onBack: () => void;
}

const EditEmployeesDetail = inject()(
  // "providerStore",
  // "dictionaryStore",
  observer((props: IEditKidzBizDetailsProps) => {
    const { data, onBack, index } = props;
    const { t } = useTranslation("base");
    const user = useCurrentUser();

    const [editLocation, setEditLocation] = useState(false);
    const [locationData, setLocationData] = useState<any>();
    const [isLoading, setIsLoading] = useState(false);
    const fieldSize = location.pathname === "/signup" ? 24 : 12;
    const [form] = Form.useForm();

    const onClickEditLocationData = (data: any) => {
      const location = {
        ...data.record,
      };
      setLocationData(location);
      setEditLocation(true);
    };

    const onEditLocationData = async (data: any) => {
      const updateData = {
        ...data,
        stateProvinceId: data.stateProvinceId,
      };
      setEditLocation(false);
    };

    const locationEditComponent = (data: any) => (
      <ButtonLink onClick={() => onClickEditLocationData(data)}>
        Edit
        <i
          className={bizIcons.ARROWRIGHT}
          style={{ marginLeft: "8px", fontSize: "16px" }}
        />
      </ButtonLink>
    );

    const onDeleteLocationData = async () => {
      setEditLocation(false);
    };

    const onFinish = (values: any) => {
      const country = Country.getCountryByCode(values?.codeMobile);
      const codeMobile = values?.codeMobile ? `+${country?.phonecode}` : "+852";
      const param: EmployeeType = {
        accessType: values?.accessType || Roles.PARTIAL,
        email: values?.email || "",
        firstName: values?.firstName || "",
        lastName: values?.lastName || "",
        mobile: values?.mobile ? codeMobile + values?.mobile : "",
      };
      form
        .validateFields(Object.keys(values))
        .then(async () => {
          employeeStore
            .editEmployee(param)
            .then(() => {
              message.success(
                t("messages.employee_updated_successfully", {
                  defaultValue: "Employee updated successfully",
                }),
              );
              onBack();
            })
            .catch((error) => {
              helpers.throwErrors(
                t,
                [],
                error?.data?.error,
                error?.data?.message,
              );
            });
          setIsLoading(false);
        })
        .catch((e) => {
          const { _message } = e;
          console.log(e);
          message.error(_message || t("server_errors." + "Bad Request"));
          setIsLoading(false);
        });
    };

    const initialValues = useMemo(() => {
      const country = COUNTRY_DATA.find((item) =>
        item.name.includes(user?.providerAttributes?.country),
      );

      const mCountryCode = country?.isoCode ?? "HK";
      const mobilePhone = (data?.mobile ?? "").replace(country?.phonecode, "");

      return {
        ...data,
        mobile: mobilePhone,
        codeMobile: mCountryCode,
      };
    }, [data, user]);

    return (
      <div>
        {editLocation ? (
          <EditLocations
            data={locationData}
            editLocationData={onEditLocationData}
            deleteLocation={onDeleteLocationData}
            onBack={() => setEditLocation(false)}
          />
        ) : (
          <div className="linked-accounts content-wrapper content-with-table">
            <ButtonLink onClick={() => onBack()}>
              <i className={`${bizIcons.ARROWLEFT} fs-20 mr-8`} />
              {t("kidzbiz.backToKidzbiz")}
            </ButtonLink>

            <div className="content">
              <div className="auth-header bsl-4">
                <div className="header-title">
                  <div>
                    <span className={"auth-header_title"}>
                      {t("kidzbiz.editEmployees")}
                    </span>
                  </div>
                </div>
              </div>

              <div className="auth-form">
                <Form
                  form={form}
                  name="normal_login"
                  className="login-form form-grid-layout"
                  initialValues={initialValues}
                  onFinish={onFinish}
                >
                  <Row gutter={24}>
                    <Col xs={24} md={12}>
                      <div className="bsl-4">
                        <div className="label-wrapper">
                          <label htmlFor="firstName">
                            {t("kidzbiz.firstName")}*
                          </label>
                        </div>

                        <Form.Item
                          name="firstName"
                          rules={[
                            {
                              required: true,
                              message: t("messages.required_field"),
                            },
                          ]}
                        >
                          <Input
                            id={"firstName"}
                            maxLength={45}
                            placeholder={t("kidzbiz.firstName")}
                            readOnly={isLoading}
                            size={"large"}
                          />
                        </Form.Item>
                      </div>
                    </Col>
                    <Col xs={24} md={12}>
                      <div className="bsl-4">
                        <div className="label-wrapper">
                          <label htmlFor="lastName">
                            {t("kidzbiz.lastName")}*
                          </label>
                        </div>

                        <Form.Item
                          name="lastName"
                          rules={[
                            {
                              required: true,
                              message: t("messages.required_field"),
                            },
                          ]}
                        >
                          <Input
                            id={"lastName"}
                            maxLength={45}
                            placeholder={t("kidzbiz.lastName")}
                            readOnly={isLoading}
                            size={"large"}
                          />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col xs={24} md={12}>
                      <div className="bsl-4">
                        <div className="label-wrapper">
                          <label htmlFor="email">{t("kidzbiz.email")}*</label>
                        </div>

                        <Form.Item
                          name="email"
                          rules={[
                            {
                              required: true,
                              message: t("messages.required_field"),
                            },
                            {
                              pattern:
                                /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
                              message: t("messages.invalid_email_format"),
                            },
                          ]}
                        >
                          <Input
                            id={"email"}
                            maxLength={45}
                            placeholder={t("kidzbiz.email")}
                            readOnly={isLoading}
                            size={"large"}
                            disabled
                          />
                        </Form.Item>
                      </div>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item className="bsl-4">
                        <div className="label-wrapper">
                          <label htmlFor="mobile">
                            {t("kidzbiz.mobilePhone")}*
                          </label>
                        </div>

                        <Form.Item
                          name="mobile"
                          rules={[
                            {
                              required: true,
                              message: t("messages.required_field", {
                                defaultValue:
                                  "Please fill in all required fields",
                              }),
                            },
                            {
                              min: 8,
                              message: t("messages.employee_mobile_invalid", {
                                min: 8,
                              }),
                            },
                          ]}
                        >
                          <Input
                            maxLength={8}
                            placeholder={t("signUp.step2.enterMobilePhone", {
                              defaultValue: "Enter mobile phone",
                            })}
                            readOnly={isLoading}
                            onKeyPress={(e: any) => {
                              if (e.key) {
                                if (!new RegExp(/^[0-9]$/g).test(e.key)) {
                                  e.preventDefault();
                                }
                              }
                            }}
                            prefix={
                              <>
                                <i className={`${bizIcons.SMARTPHONE} mr-6`} />
                                <PhoneCode name="codeMobile" disabled />
                              </>
                            }
                            size={"large"}
                          />
                        </Form.Item>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col xs={24} md={12}>
                      <div className="bsl-4">
                        <label htmlFor="email">{t("kidzbiz.accessType")}</label>
                        <Form.Item
                          name="accessType"
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <Select
                            placeholder={t("kidzbiz.accessType")}
                            optionFilterProp="children"
                          >
                            <Option value={Roles.FULL}>
                              {t("kidzbiz.full")}
                            </Option>
                            <Option value={Roles.PARTIAL}>
                              {t("kidzbiz.partial")}
                            </Option>
                          </Select>
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>

                  <Row gutter={24}>
                    <Col xs={24} md={12}>
                      <Form.Item>
                        <Button
                          className="full-width fs-16 tsl-4"
                          type="primary"
                          htmlType="submit"
                          loading={isLoading}
                          icon={
                            <SaveButtonText>{t("bizBase.save")}</SaveButtonText>
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }),
);

export default EditEmployeesDetail;
