import {
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Spin,
} from "antd";
import ButtonLink from "components/ButtonLink";
import { COUNTRY_DATA, PhoneCode } from "components/PhoneCode";
import SaveButtonText from "components/SaveButtonText";
import { bizIcons } from "constants/bizConstants";
import { City, Country, State } from "country-state-city";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { CITY_DATA, SINGAPORE_CITY_DATA } from "pages/signup/interface";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCurrentUser } from "scripts/custom-hooks/useCurrentUser";
import { getLanguage } from "scripts/custom-hooks/useLanguage";
import { Languages } from "scripts/mobx/languageStore";
import locationsStore, { LocationType } from "scripts/mobx/locationStore";
import "./index.less";
const { confirm } = Modal;

const { Option } = Select;

interface IAddLocationProps {
  onBack: () => void;
  data: any;
  editLocationData: (data: LocationType) => void;
  deleteLocation: () => void;
}

const EditLocationComponent = inject("locationsStore")(
  observer((props: IAddLocationProps) => {
    const { t } = useTranslation("base");
    const lang = getLanguage();

    const [isLoading, setIsLoading] = useState(false);
    const user = useCurrentUser();

    const [form] = Form.useForm();
    const { editLocationData, onBack, deleteLocation, data } = props;

    const country = useMemo(() => {
      const countryCode =
        COUNTRY_DATA.find((item) =>
          item.name.includes(user?.providerAttributes?.country ?? "Hong Kong"),
        )?.isoCode ?? "HK";
      const selectedCountry = Country.getCountryByCode(countryCode);
      return selectedCountry;
    }, []);

    const getStatesList = useCallback((isoCode) => {
      if (!isoCode) return [];
      if (isoCode === "HK")
        return CITY_DATA.map((item) => ({
          name: item.value,
          value: item.value,
        }));

      if (isoCode === "SG") return SINGAPORE_CITY_DATA;
      return State.getStatesOfCountry(isoCode).map((item) => ({
        name: item.name,
        value: item.isoCode,
      }));
    }, []);

    // interpolate states by country
    const states = useMemo(() => getStatesList(country?.isoCode), [country]);

    const getCitiesList = useCallback((isoCode, stateCode) => {
      if (!isoCode || !stateCode) return [];
      if (isoCode === "HK")
        return CITY_DATA.find((el) => el.value === stateCode)?.suburbs ?? [];

      if (isoCode === "SG") return SINGAPORE_CITY_DATA[0].suburbs;
      return City.getCitiesOfState(isoCode, stateCode).map((item) => ({
        value: item.name,
      }));
    }, []);

    const handleStateChanged = (value: string) => {
      // set default suburb after changing state
      const mCities = getCitiesList(country?.isoCode, value);
      form.setFieldsValue({
        suburb: mCities?.[0]?.value,
      });
    };

    const fieldSize = location.pathname === "/signup" ? 24 : 12;

    const onFinish = (values: any) => {
      const country = Country.getCountryByCode(values?.codeMobile);
      const codeMobile = values?.codeMobile ? `+${country?.phonecode}` : "+852";
      const param: LocationType = {
        id: data?.id,
        name: values?.name,
        address: {
          addressLine: values?.address || "",
          country: country?.name || "",
          area: values?.area || "",
          suburb: values?.suburb || "",
        },
        email: values?.email,
        mobile: values?.phoneNumber ? codeMobile + values?.phoneNumber : "",
        deleted: false,
      };
      setIsLoading(true);
      form
        .validateFields(Object.keys(values))
        .then(
          async () => {
            await editLocationData(param);
            // await setIsLoading(false);
          },
          (e) => {
            const { _message } = e;
            console.log(e);

            message.error(
              _message ||
                t("messages.not_valid_input_data", {
                  defaultValue: "Not valid input data",
                }),
            );
            setIsLoading(false);
          },
        )
        .catch((e) => {
          const { _message } = e;
          console.log(e);
          message.error(
            _message ||
              t("messages.unrecognized_error", {
                defaultValue: "Unrecognized error",
              }),
          );
          setIsLoading(false);
        });
    };

    const onDelete = () => {
      confirm({
        title: ``,
        content: `Are you sure you want to permanently remove this location?`,
        autoFocusButton: null,
        icon: "",
        okText: "Yes",
        cancelText: "No",
        onOk() {
          setIsLoading(true);
          deleteLocation();
        },
      });
    };

    const initialValues = useMemo(() => {
      const country = COUNTRY_DATA.find((item) =>
        item.name.includes(user?.providerAttributes?.country),
      );

      const mCountryCode = country?.isoCode ?? "HK";
      const phoneNumber = (data?.phoneNumber ?? "").replace(
        country?.phonecode,
        "",
      );

      return {
        ...data,
        phoneNumber,
        codeMobile: mCountryCode,
      };
    }, [data, user]);

    return (
      <div
        className={location.pathname === "/signup" ? "auth" : "single-course"}
      >
        <div className="content-wrapper content-with-table">
          <ButtonLink onClick={() => onBack()}>
            <i className={`${bizIcons.ARROWLEFT} fs-20 mr-8`} />
            {t("kidzbiz.back")}
          </ButtonLink>
          <div className="auth-header bsl-4">
            <div className="header-title">
              <span className={"auth-header_title"}>
                {t("kidzbiz.editLocationData")}
              </span>
              <ButtonLink onClick={onDelete}>
                <i className={`${bizIcons.DELETE} fs-20 mr-8`} />
                {t("bizBase.delete")}
              </ButtonLink>
            </div>
          </div>
          <div className="auth-form">
            <Spin spinning={isLoading} size="large">
              <Form
                form={form}
                name="normal_login"
                className="login-form form-grid-layout"
                initialValues={initialValues}
                onFinish={onFinish}
              >
                <Row>
                  <Col xs={24}>
                    <Form.Item className="bsl-4">
                      <div className="label-wrapper">
                        <label htmlFor="email">
                          {t("kidzbiz.locationName")}*
                        </label>
                      </div>
                      <Form.Item
                        name="name"
                        rules={[
                          {
                            required: true,
                            message: "Please fill in all required fields",
                          },
                        ]}
                        initialValue={data.name}
                      >
                        <Input
                          id={"name"}
                          placeholder={t("kidzbiz.locationName")}
                          readOnly={isLoading}
                          size={"large"}
                        />
                      </Form.Item>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={24}>
                  <Col xs={24} md={fieldSize}>
                    <Form.Item className="bsl-4">
                      <div className="label-wrapper">
                        <label htmlFor="address">
                          {t("kidzbiz.locationAddress")}*
                        </label>
                      </div>
                      <Form.Item
                        name="address"
                        // initialValue={data.address}
                        rules={[
                          {
                            required: true,
                            message: "Please fill in all required fields",
                          },
                        ]}
                      >
                        <Input.TextArea
                          id={"address"}
                          maxLength={100}
                          placeholder={t("kidzbiz.locationAddress")}
                          autoSize={{ minRows: 5, maxRows: 5 }}
                        />
                      </Form.Item>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={fieldSize}>
                    <Form.Item className="bsl-4">
                      <div className="label-wrapper">
                        <label htmlFor="area">
                          {t("signUp.step2.area", {
                            defaultValue: "Area",
                          })}
                        </label>
                      </div>
                      <Form.Item name="area">
                        <Select
                          placeholder={t("signUp.step2.enterArea", {
                            defaultValue: "Enter area",
                          })}
                          suffixIcon={
                            <i className="ri-arrow-drop-down-line fs-20" />
                          }
                          onChange={handleStateChanged}
                        >
                          {states.map((city: { value: string }) => {
                            return (
                              <Option key={city.value} value={city.value}>
                                {city.value}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Form.Item>

                    <Form.Item shouldUpdate>
                      {() => {
                        const cities = getCitiesList(
                          country?.isoCode,
                          form.getFieldValue("area"),
                        );
                        return (
                          <Form.Item className="bsl-4">
                            <div className="label-wrapper">
                              <label htmlFor="suburb">
                                {t("signUp.step2.suburb", {
                                  defaultValue: "Suburb",
                                })}
                              </label>
                            </div>
                            <Form.Item name="suburb">
                              <Select
                                placeholder={t("signUp.step2.enterSuburb", {
                                  defaultValue: "Enter suburb",
                                })}
                                suffixIcon={
                                  <i className="ri-arrow-drop-down-line fs-20" />
                                }
                              >
                                {cities.map((suburb) => (
                                  <Option
                                    key={suburb.value}
                                    value={suburb.value}
                                  >
                                    {suburb.value}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Form.Item>
                        );
                      }}
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={24}>
                  <Col xs={24} md={fieldSize}>
                    <Form.Item className="bsl-4">
                      <div className="label-wrapper">
                        <label htmlFor="email">{t("kidzbiz.email")}</label>
                      </div>
                      <Form.Item
                        name="email"
                        rules={[
                          {
                            pattern:
                              /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
                            message: t("messages.invalid_email_format"),
                          },
                        ]}
                        // initialValue={data.email}
                      >
                        <Input
                          id={"email"}
                          maxLength={45}
                          prefix={<i className="ri-mail-line mr-10 fs-20" />}
                          placeholder={t("kidzbiz.email")}
                          size={"large"}
                        />
                      </Form.Item>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={fieldSize}>
                    <Form.Item className="bsl-4">
                      <div className="label-wrapper">
                        <label htmlFor="phoneNumber">
                          {t("kidzbiz.mobilePhone")}
                        </label>
                      </div>

                      <Form.Item
                        name="phoneNumber"
                        rules={[
                          {
                            min: 8,
                            message: t("messages.employee_mobile_invalid", {
                              min: 8,
                            }),
                          },
                        ]}
                        // initialValue={data.mobile}
                      >
                        <Input
                          maxLength={8}
                          placeholder={t("signUp.step2.enterMobilePhone", {
                            defaultValue: "Enter mobile phone",
                          })}
                          readOnly={isLoading}
                          onKeyPress={(e: any) => {
                            if (e.key) {
                              if (!new RegExp(/^[0-9]$/g).test(e.key)) {
                                e.preventDefault();
                              }
                            }
                          }}
                          prefix={
                            <>
                              <i className={`${bizIcons.SMARTPHONE} mr-6`} />
                              <PhoneCode name="codeMobile" disabled />
                            </>
                          }
                          size={"large"}
                        />
                      </Form.Item>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={24}>
                  <Col xs={24}>
                    <Form.Item>
                      <Button
                        className="full-width fs-16 tsl-4"
                        type="primary"
                        htmlType="submit"
                        loading={isLoading}
                        icon={
                          <SaveButtonText>{t("bizBase.save")}</SaveButtonText>
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Spin>
          </div>
        </div>
      </div>
    );
  }),
);

const EditLocationWrapper = (props: IAddLocationProps) => (
  <EditLocationComponent {...props} />
);

export default EditLocationWrapper;
