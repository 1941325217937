import { Button, Form, FormProps, Input, message, Select } from "antd";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import ButtonLink from "../../../components/ButtonLink";
import { bizIcons } from "../../../constants/bizConstants";

const { Option } = Select;

interface IAddLinkedKidzbiz extends RouteComponentProps {
  onBack: () => void;
  onAdd: (data: any) => void;
  providerStore?: any;
}

const AddLink = inject()(
  observer((props: IAddLinkedKidzbiz) => {
    const { onBack, onAdd } = props;
    const { t } = useTranslation("base");

    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm();
    const onFinish = async (values: FormProps) => {
      setIsLoading(true);
      try {
        await form.validateFields(Object.keys(values));
        onAdd(values);
        setIsLoading(false);
        onBack();
      } catch (e) {
        setIsLoading(false);
      }
    };
    return (
      <div>
        <div className="content-wrapper content-with-table">
          <ButtonLink onClick={() => onBack()}>
            <i className={`${bizIcons.ARROWLEFT} fs-20 mr-8`} />
            {t("kidzbiz.back")}
          </ButtonLink>
          <div className="auth-header bsl-4">
            <div>
              <span className={"auth-header_title"}>
                {t("signUp.addPolicies.title", {
                  type: "Add",
                })}
              </span>
            </div>
          </div>
          <div className="auth-form">
            <Form
              form={form}
              name="normal_login"
              className="login-form"
              onFinish={onFinish}
            >
              <Form.Item className="bsl-4">
                <div className="label-wrapper">
                  <label htmlFor="name">
                    {t("signUp.addPolicies.linkName", {
                      defaultValue: "Link name",
                    })}
                    *
                  </label>
                </div>
                <Form.Item
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: t("messages.required_field", {
                        defaultValue: "Please fill in all required fields",
                      }),
                    },
                  ]}
                >
                  <Input
                    id={"name"}
                    placeholder={t("signUp.addPolicies.linkName", {
                      defaultValue: "Link name",
                    })}
                    readOnly={isLoading}
                    size={"large"}
                    maxLength={60}
                  />
                </Form.Item>
              </Form.Item>
              <Form.Item className="bsl-4">
                <div className="label-wrapper">
                  <label htmlFor="content">
                    {t("signUp.addPolicies.enterLink", {
                      defaultValue: "Enter link",
                    })}
                    *
                  </label>
                </div>
                <Form.Item
                  name="content"
                  rules={[
                    {
                      required: true,
                      message: t("messages.required_field", {
                        defaultValue: "Please fill in all required fields",
                      }),
                    },
                    {
                      pattern:
                        /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
                      message: t("messages.url_format", {
                        defaultValue: "Please check url format",
                      }),
                    },
                  ]}
                >
                  <Input
                    id={"content"}
                    required={true}
                    placeholder={t("signUp.addPolicies.link", {
                      defaultValue: "link",
                    })}
                    readOnly={isLoading}
                    size={"large"}
                    maxLength={150}
                  />
                </Form.Item>
              </Form.Item>
              <Form.Item>
                <Button
                  className="full-width fs-16 tsl-4"
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                  icon={
                    <div className={"flex-center"}>
                      <i className={`${bizIcons.SAVE} mr-8 fs-20`} />
                      {t("signUp.addPolicies.saveLink", {
                        defaultValue: "Save link",
                      })}
                    </div>
                  }
                />
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    );
  }),
);

export default withRouter(AddLink);
