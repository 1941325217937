import { createContext } from "react";
import { action, computed, observable, toJS } from "mobx";

import { FilterType } from "constants/type.interface";
import { PAGE_SIZE } from "config";

export class ProviderReviewsStore {
  @observable
  private reviewsDataList: any = {};

  @observable
  private filterData: FilterType = {
    page: 1,
    pageSize: PAGE_SIZE,
  };

  @action
  public async getReviewsList(filterData?: any) {
    const result = {
      statusCode: 200,
      data: {
        items: [],
        totalRecords: 0,
        ...toJS(this.filterData),
      },
    };

    if (result?.statusCode === 200) {
      this.reviewsDataList = result.data || {};
      return Promise.resolve(result.data);
    } else {
      return Promise.reject();
    }
  }
  @action
  public async getReviewInfo(reviewId: number): Promise<any> {
    try {
      const result = {
        statusCode: 200,
        data: [],
      };

      if (result?.statusCode === 200) {
        // this.reviewsDataList = result.data || {};
        return Promise.resolve(result.data);
      } else {
        return Promise.reject();
      }
    } catch (e) {
      return Promise.reject();
    }
  }
  @action
  public async replyToReview(reviewId: number, reply: string): Promise<any> {
    try {
      const requestParameters: {
        reviewId: number;
        ProviderReviewReplyModel: { reply: string };
      } = {
        reviewId,
        ProviderReviewReplyModel: { reply },
      };
      const result = { statusCode: 200, data: {} };

      if (result?.statusCode === 200) {
        return Promise.resolve(result.data);
      } else {
        return Promise.reject();
      }
    } catch (e) {
      return Promise.reject();
    }
  }

  @action
  public async clearAll() {
    this.reviewsDataList = {};
  }

  @computed
  public get reviewsData() {
    return this.reviewsDataList;
  }
}

const providerReviewsStore = new ProviderReviewsStore();

export const providerReviewsStoreContext = createContext(providerReviewsStore);
export default providerReviewsStore;
