import React, { useState } from "react";
import ButtonLink from "../../../../components/ButtonLink";
import { Divider, Modal, notification } from "antd";
import { NotificationType, NotificationModule } from "constants/type.interface";
import { useTranslation } from "react-i18next";
import bookingsStore, { BookingStatus } from "scripts/mobx/bookingsStore";
import helpers from "scripts/helpers";
import { useHistory } from "react-router";
import { AppRoutes } from "helpers/app.routes";
const { confirm } = Modal;

interface IProps {
  onAccept: (id: number) => void;
  data: NotificationType[];
}
export const DataBlock = (props: IProps) => {
  const { data, onAccept } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean | undefined>(true);

  const onSuccess = (status: BookingStatus) => {
    setTimeout(() => {
      setIsLoading(false);
      notification.success({
        message: t(`bookings.status_request.${status}_success`),
      });
    }, 300);
  };

  const showConfirm = (status: BookingStatus, id: number, notiId: number) => {
    confirm({
      // title: `${str[0].toUpperCase() + str.slice(1)} the verification request`,
      // content: `Do you really want to ${str} this verification request?`,
      title: t(`bookings.status_request.${status}_title`),
      content: t(`bookings.status_request.${status}_message`),
      autoFocusButton: null,
      icon: "",
      okText: t(`bookings.status_request.${status}_button`),
      cancelText: t("bizBase.cancel"),
      onOk() {
        onAccept(notiId);
        setIsLoading(true);
        if (status === "ACCEPTED") {
          bookingsStore
            ?.acceptBooking(Number(id))
            .then(() => {
              onSuccess(status);
            })
            .catch((error) => {
              helpers.throwErrors(
                t,
                [],
                error?.data?.error,
                error?.data?.message,
              );
            });
        } else {
          bookingsStore
            ?.rejectBooking(Number(id))
            .then(() => {
              onSuccess(status);
            })
            .catch((error) => {
              helpers.throwErrors(
                t,
                [],
                error?.data?.error,
                error?.data?.message,
              );
            });
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return (
    <div className={"flex-center"} style={{ flexDirection: "column" }}>
      {/* <Divider /> */}
      {data?.map((el: NotificationType) => {
        return (
          <div
            key={Math.random()}
            // className={"flex-center"}
            style={{ width: "100%" }}
          >
            <div
              className={"flex-space-between"}
              style={{
                width: "100%",
                flexDirection: "row",
                gap: "8px",
                padding: "16px 24px 0 24px",
              }}
            >
              <div
                className={"semi-bold"}
                style={{ fontSize: "16px", lineHeight: "24px" }}
              >
                {t("notifications.types." + el?.type?.toLocaleLowerCase())}
              </div>
              <div>
                <ButtonLink
                  disabled={el?.status === "UNSEEN" ? false : true}
                  onClick={() => {
                    // @ts-ignore
                    onAccept(el.id);
                  }}
                >
                  {t("notifications.iread")}
                </ButtonLink>
              </div>
            </div>
            <div
              style={{ padding: "16px 24px" }}
              className={"text-color-secondary"}
              dangerouslySetInnerHTML={{
                __html: `<div>${t(
                  "notifications.messages." + el?.type?.toLocaleLowerCase(),
                  {
                    link: AppRoutes.faqDetail("other7"),
                    legalName: el?.content?.organizationLegalName || "",
                    customerName: el?.content?.customerName || "",
                    courseName:
                      (el?.content?.courseName || "") +
                      " " +
                      (el?.content?.courseCode || ""),
                  },
                )}</div>`,
              }}
            />
            {el.type && (
              <>
                {[NotificationModule.new_booking_request]?.includes(
                  el.type,
                ) && (
                  <div
                    className="flex-align-items"
                    style={{ padding: "0 24px" }}
                  >
                    <ButtonLink
                      onClick={() =>
                        showConfirm(BookingStatus.Accepted, el?.refId, el?.id)
                      }
                    >
                      <i
                        className={"ri-checkbox-circle-line"}
                        style={{ fontSize: "16px", marginRight: "4px" }}
                      />
                      {t("bizBase.accept")}
                    </ButtonLink>
                    <div
                      className={"flex align-center"}
                      onClick={() =>
                        showConfirm(BookingStatus.Rejected, el?.refId, el?.id)
                      }
                      style={{ marginLeft: 15 }}
                    >
                      <i
                        className={"ri-close-circle-line"}
                        style={{ fontSize: "16px", marginRight: "4px" }}
                      />
                      {t("bizBase.decline")}
                    </div>
                  </div>
                )}

                {[NotificationModule.pending_booking_requests]?.includes(
                  el.type,
                ) && (
                  <div style={{ padding: "0 24px" }}>
                    <ButtonLink
                      onClick={() => {
                        onAccept(el?.id);
                        history.push(AppRoutes.bookings);
                      }}
                    >
                      {t("notifications.view_bookings")}
                    </ButtonLink>
                  </div>
                )}

                {[NotificationModule.customer_review]?.includes(el.type) && (
                  <div style={{ padding: "0 24px" }}>
                    <ButtonLink
                      onClick={() => {
                        onAccept(el?.id);
                        history.push(AppRoutes.reviews);
                      }}
                    >
                      {t("notifications.view_reviews")}
                    </ButtonLink>
                  </div>
                )}

                {[
                  NotificationModule.account_linkage,
                  NotificationModule.linkage_invite_expiry,
                ]?.includes(el.type) && (
                  <div style={{ padding: "0 24px" }}>
                    <ButtonLink
                      onClick={() => {
                        onAccept(el?.id);
                        history.push("/" + "?action=linked_account");
                      }}
                    >
                      {t("notifications.view_linked_account")}
                    </ButtonLink>
                  </div>
                )}
              </>
            )}

            <Divider />
          </div>
        );
      })}
    </div>
  );
};
