import { Button, Divider, Input, notification, Spin, Typography } from "antd";
import { FORMAT_DATE } from "constants/commons";
import { observer } from "mobx-react-lite";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCurrentUser } from "scripts/custom-hooks/useCurrentUser";
import helpers from "scripts/helpers";
import reviewsStore from "scripts/mobx/reviewsStore";
import ButtonLink from "../../../components/ButtonLink";
import TableComponent from "../../../components/TableComponent/TableComponent";
import { bizIcons } from "../../../constants/bizConstants";
import providerReviewsStore from "../../../scripts/mobx/providerReviewsStore";
import { tableViewReviewColumns, tableViewReviewData } from "../tableGenerator";
const { Title } = Typography;

type Props = {
  id: number;
  onBack?: () => void;
};
export const ViewCustomerReview = observer((props: Props) => {
  const { id, onBack } = props;
  const { t } = useTranslation();
  const user = useCurrentUser();
  const [isReply, setIsReply] = useState(false);
  const [replyValue, setReplyValue] = useState<undefined | string>(undefined);
  const [reviewInfo, setReviewInfo] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);

  const getReviewDetail = async () => {
    setIsLoading(true);
    reviewsStore
      ?.getReviewById(id)
      .then((_data) => {
        setReviewInfo(_data);
        setTimeout(() => setIsLoading(false), 300);
      })
      .catch((error) => {
        setIsLoading(false);
        helpers.throwErrors(t, [], error?.data?.error, error?.data?.message);
      });
  };

  const makeAndSendReply = async () => {
    setIsReply(false);
    try {
      notification.success({
        message: t("reviews.replyPublished"),
      });
    } catch {
      notification.error({
        message: t("reviews.replyNotPublished"),
      });
    }
    if (replyValue) {
      setIsReply(false);
      await reviewsStore.replyToReview(id, replyValue);
      await getReviewDetail();
    }
  };

  useEffect(() => {
    getReviewDetail();
  }, []);

  return (
    <>
      {isLoading && (
        <div className="flex-center antd-spiner">
          <Spin size="large" />
        </div>
      )}
      {!isReply ? (
        <Spin spinning={isLoading} size="large">
          <div className={""}>
            <div>
              <div className={"back-block tsl-4"}>
                <ButtonLink onClick={() => onBack?.()}>
                  <i
                    style={{ fontSize: "20px", paddingRight: "8px" }}
                    className={bizIcons.ARROWLEFT}
                  />
                  {t("reviews.backToReviews")}
                </ButtonLink>
              </div>
              {!!reviewInfo?.customer?.firstName &&
                !!reviewInfo?.customer?.lastName && (
                  <Title level={1} className={"title tsl-4"}>
                    {reviewInfo?.customer?.firstName +
                      " " +
                      reviewInfo?.customer?.lastName}
                  </Title>
                )}
            </div>
            <Divider style={{ marginBottom: "0px" }} />
            <TableComponent
              broken={true}
              grid={true}
              noSeparators={true}
              pagination={false}
              propsTableColumns={tableViewReviewColumns(t, user?.isLinked)}
              propsTableData={tableViewReviewData(reviewInfo)}
            />
            <div>
              <Divider />

              {!!reviewInfo?.content && (
                <div className={"bsl-4"}>
                  <div style={{ marginBottom: "4px" }}>
                    <Title level={5}>{t("reviews.glows_and_grows")}</Title>
                  </div>
                  <div className="text-color-secondary">
                    {reviewInfo?.content}
                  </div>
                </div>
              )}
            </div>
            {!reviewInfo?.userReplyReviews &&
              reviewInfo?.course?.legalName ===
                user?.providerAttributes?.legalName && (
                <div>
                  <ButtonLink
                    onClick={() => {
                      setIsReply(true);
                    }}
                  >
                    <i className={`${bizIcons.MESSAGE} fs-20 mr-8`} />
                    {t("reviews.replyToReview")}
                  </ButtonLink>
                </div>
              )}
            {!!reviewInfo?.userReplyReviews &&
              reviewInfo?.userReplyReviews?.map((el: any) => {
                const fullname =
                  el?.userAttributes?.firstName +
                  " " +
                  el?.userAttributes?.lastName;
                return (
                  <div key={el.id} className="bsl-5">
                    <Divider />
                    <div className={"bsl-4"}>
                      <div style={{ marginBottom: "4px" }}>
                        <Title level={5}>{el?.legalName || fullname}</Title>
                      </div>
                      <div className={"text-color-secondary bsl-4"}>
                        {el?.createdDate
                          ? helpers.formatDateTime(el.createdDate)
                          : ""}
                      </div>
                      <div>{el?.content}</div>
                    </div>
                  </div>
                );
              })}
          </div>
        </Spin>
      ) : (
        <div className={""}>
          <div>
            <div className={"back-block tsl-4"}>
              <ButtonLink
                onClick={() => {
                  setIsReply(false);
                }}
              >
                <i
                  style={{ fontSize: "20px", paddingRight: "8px" }}
                  className={bizIcons.ARROWLEFT}
                />
                {t("reviews.backToReview")}
              </ButtonLink>
            </div>
            <Title level={2} className={"title tsl-4 bsl-4"}>
              {t("reviews.replyToReview")}
            </Title>
          </div>
          <div>
            <Input.TextArea
              placeholder="Enter reply"
              autoSize={{ minRows: 3, maxRows: 3 }}
              onChange={(e) => setReplyValue(e.target.value)}
              value={replyValue}
              // onPressEnter={() => validateForm('location')}
            />

            <Button
              className="fs-16"
              type="primary"
              style={{ width: "100%", marginTop: "32px" }}
              disabled={!replyValue}
              onClick={() => {
                makeAndSendReply();
              }}
            >
              <div className={"flex-center"}>
                <i className={`${bizIcons.MAILSEND} mr-8 fs-20`} />
                {t("reviews.publishReply")}
              </div>
            </Button>
          </div>
        </div>
      )}
    </>
  );
});
