import { DETAIL_API_URL } from "constants/apiUrl";
import requestApi from "helpers/requestApi";
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
  toJS,
} from "mobx";
import { createContext } from "react";
import {
  PaymentMethodInfomation,
  getPaymentMethods,
} from "scripts/apis/payment";

export interface PasswordUpdateType {
  currentPassword: string;
  newPassword: string;
}

export enum Roles {
  PARTIAL = "PARTIAL",
  FULL = "FULL",
  ADMIN = "ADMIN",
}

export enum UserType {
  MASTER = "MASTER",
  SATELLITE = "SATELLITE",
}

export enum ProviderStatus {
  UNVERIFIED_STRIPE = "UNVERIFIED_STRIPE", // Stripe Pending
  UNVERIFIED_BIZIBUZ = "UNVERIFIED_BIZIBUZ", //Verification Pending
  REJECTED = "REJECTED", // Rejected
  VERIFIED = "VERIFIED", // Verified
  BLOCKED = "BLOCKED", //Blocked
}

export class UserStore {
  constructor() {
    makeObservable(this);
  }

  @observable
  public userData: any = {
    currentUser: null,
    step3Data: null,
    policies: null,
    keycloakInfo: null,
    roles: [],
  };

  @observable
  private reschedulingOutcome: any = {};

  @observable
  public errorHandler: string = "";

  // user's payment method informations
  @observable
  public paymentMethods: Array<PaymentMethodInfomation> = [];

  @computed
  public get currency() {
    return this.userData.currentUser?.preferCurrency ?? "HKD";
  }

  @action
  public async getPaymentMethods() {
    const res = await getPaymentMethods();

    runInAction(() => {
      this.paymentMethods = res;
    });
  }

  @action
  public async getKeycloakInfo(res: any) {
    if (res) {
      this.userData.keycloakInfo = res;
      this.userData.roles = process.env.RAZZLE_KEYCLOAK_CLIENT_ID
        ? res?.resourceAccess?.[process.env.RAZZLE_KEYCLOAK_CLIENT_ID || ""]
            ?.roles
        : null;
    } else {
      this.userData.keycloakInfo = null;
      this.userData.roles = [];
    }
  }

  @action
  public async getKeycloakRole(res: any[]) {
    if (res) {
      this.userData.roles = res;
    } else {
      this.userData.roles = [];
    }
  }

  @action
  public async getUserProfile() {
    const result = await requestApi.get(DETAIL_API_URL.USERS_LOGIN_PROFILE);
    if (result?.status === 200 || !!result?.data) {
      this.userData.currentUser = result?.data;
      return Promise.resolve(result?.data);
    } else {
      this.userData.currentUser = null;
      this.errorHandler = result?.data?.message;
      return Promise.reject();
    }
  }

  @action
  public async userRegister(params: {
    firstName: string;
    lastName: string;
    mobile: string;
    landline: string | null;
  }) {
    const result = await requestApi.post(DETAIL_API_URL.USERS_REGISTER, params);
    try {
      this.getUserProfile().then((res: any) => {
        // only request to mailer lite on production env
        if (process.env.RAZZLE_ENV === "production")
          requestApi.post(DETAIL_API_URL.MAILERLITE_SUBSCRIBERS, {
            groupId: process.env.RAZZLE_MAILERLITE_GROUP || "",
            name: `${res?.userAttributes?.firstName} ${res?.userAttributes?.lastName}`,
            email: res?.userAttributes?.email,
            phoneNumber: params.mobile,
          });
      });
      return Promise.resolve();
    } catch {
      this.errorHandler = result?.data?.message;
      this.userData = { ...this.userData };
      return Promise.reject();
    }
  }
  @action
  public async userUpdate(params: any) {
    const users = toJS(this.userData.currentUser);
    if (!!users?.userAttributes) {
      const result = await requestApi.put(DETAIL_API_URL.USERS_UPDATE, params);
      if (result) {
        this.getUserProfile();
      } else {
        this.userData = { ...this.userData };
      }
    } else {
      this.userRegister(params);
    }
  }
  @action
  public async createMaster(params: any) {
    const result = await requestApi.post(DETAIL_API_URL.CREATE_MASTER, params, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    if (result?.status === 200 || !!result?.data) {
      this.getUserProfile();
      this.userData.step3Data = result?.data;
    } else {
      this.userData = { ...this.userData };
    }
  }
  @action
  public async createSatellite(params: any) {
    const result = await requestApi.post(
      DETAIL_API_URL.CREATE_SATELLITE,
      params,
      {
        headers: { "Content-Type": "multipart/form-data" },
      },
    );
    if (result?.status === 200 || !!result?.data) {
      this.getUserProfile();
      this.userData.step3Data = result?.data;
    } else {
      this.userData = { ...this.userData };
    }
  }
  @action
  public async setAdditionalInfo(params: any) {
    const users = toJS(this.userData.currentUser);
    if (users?.providerAttributes?.id) {
      const result = await requestApi.put(
        DETAIL_API_URL.SET_ADDITIONAL_INFO,
        params,
      );
      if (result) {
        await this.getUserProfile();
        await this.getProviderCurrentPolicies();
        await this.getReschedulingOutcome();
      } else {
        this.userData = { ...this.userData };
      }
    }
  }
  @action
  public async getProviderCurrentPolicies() {
    const result = await requestApi.get(DETAIL_API_URL.PROVIDER_GET_POLICIES());
    if (result) {
      this.userData.policies = result?.data;
    } else {
      this.userData = { ...this.userData };
    }
  }
  @action
  public async updateProvider(params: any) {
    const result = await requestApi.post(
      DETAIL_API_URL.UPDATE_PROVIDER,
      params,
    );
    if (result) {
      this.getUserProfile();
    } else {
      this.userData = { ...this.userData };
    }
  }
  @action
  public async setProviderVerified(params?: any) {
    const result = await requestApi.put(DETAIL_API_URL.SET_VERIFIED, params);
    if (result) {
      this.getUserProfile();
    } else {
      this.userData = { ...this.userData };
    }
  }
  @action
  public async updateUserPassword(params: PasswordUpdateType) {
    const result = await requestApi.put(
      DETAIL_API_URL.USERS_UPDATE_PASSWORD,
      params,
    );
    if (result) {
      this.getUserProfile();
    } else {
      this.userData = { ...this.userData };
    }
  }
  @action
  public async getStripeAccountOnboard() {
    const result = await requestApi.get(DETAIL_API_URL.STRIPE_ACCOUNT_ONBOARD);
    if (result) {
      this.getUserProfile();
      return Promise.resolve(result);
    } else {
      this.userData = { ...this.userData };
      return Promise.reject();
    }
  }

  @action
  public async getReschedulingOutcome() {
    const result = await requestApi.get(DETAIL_API_URL.RESCHEDULING_OUTCOME);
    if (result?.status === 200) {
      this.reschedulingOutcome = result?.data;
      return Promise.resolve(result);
    } else {
      return Promise.reject();
    }
  }

  @action
  public async changeEmailVerification(localeLang: string, newEmail: string) {
    const result = await requestApi.put(
      DETAIL_API_URL.CHANGE_EMAIL_VERIFICATION,
      {
        localeLang,
        newEmail,
      },
    );
    if (result?.status === 200) {
      return Promise.resolve(result);
    } else {
      return Promise.reject();
    }
  }

  @action
  public async updateUserEmail(newEmail: string, verificationCode: string) {
    const result = await requestApi.put(DETAIL_API_URL.UPDATE_USER_EMAIL, {
      verificationCode,
      newEmail,
    });
    if (result?.status === 200) {
      return Promise.resolve(result);
    } else {
      return Promise.reject();
    }
  }

  @action
  public async getTimezone() {
    const result = await requestApi.get(
      `${DETAIL_API_URL.COMMON_CONFIGURATION}?type=TIMEZONE`,
    );

    if (result?.status === 200) {
      return Promise.resolve(result?.data?.data);
    } else {
      return Promise.reject();
    }
  }

  @action
  public async getCurrency() {
    const result = await requestApi.get(
      `${DETAIL_API_URL.COMMON_CONFIGURATION}?type=CURRENCY`,
    );

    if (result?.status === 200) {
      return Promise.resolve(result?.data?.data);
    } else {
      return Promise.reject();
    }
  }

  @computed
  public get getUserData() {
    return this.userData.currentUser;
  }

  @computed
  public get reschedulingOutcomeData() {
    return this.reschedulingOutcome;
  }

  @computed
  public get isSGProvider() {
    const user = toJS(this?.userData?.currentUser);
    return (user?.userAttributes?.mobile || "").includes("+65");
  }
}

const userStore = new UserStore();

export const userStoreContext = createContext(userStore);
export default userStore;
