import React from "react";
import { bizIcons } from "../../constants/bizConstants";

interface IProps extends React.HTMLAttributes<HTMLElement> {
  withIcon?: boolean;
}

const SaveButtonText = ({withIcon = true, children}: IProps) => (
  <div className={"flex-center"}>
    {withIcon && <i className={`${bizIcons.SAVE} mr-8 fs-20`} />}
    {children ? children : "Save changes"}
  </div>
);

export default SaveButtonText;
