import { Form, Select } from "antd";
import { Country } from "country-state-city";
import React, { useRef } from "react";
import "./index.less";

// const SUPPORT_STRIPE_COUNTRIES_CODE = [
//   "AU",
//   "AT",
//   "BE",
//   "BR",
//   "BG",
//   "CA",
//   "HR",
//   "CY",
//   "CZ",
//   "DK",
//   "EE",
//   "FI",
//   "FR",
//   "DE",
//   "GI",
//   "GR",
//   "HK",
//   "HU",
//   "IN",
//   "IE",
//   "IT",
//   "JP",
//   "LV",
//   "LI",
//   "LT",
//   "LU",
//   "MY",
//   "MT",
//   "MX",
//   "NL",
//   "NZ",
//   "NO",
//   "PL",
//   "PT",
//   "RO",
//   "SG",
//   "SK",
//   "SI",
//   "ES",
//   "SE",
//   "CH",
//   "AE",
//   "GB",
//   "US",
//   // requesting
//   "ID",
//   "TH",
//   "PH",
// ];

const SUPPORTED_COUNTRIES_CODE = ["HK", "SG"];

export const COUNTRY_DATA = Country.getAllCountries()
  .filter((item) =>
    SUPPORTED_COUNTRIES_CODE.includes(item.isoCode.toUpperCase()),
  )
  .map((el) => {
    const phone = el.phonecode || "0";
    const addPlus = "+" + phone;
    return {
      ...el,
      phonecode: phone.includes("+") ? phone : addPlus,
    };
  });

type Props = {
  name: string;
  disabled?: boolean;
  onChange?: (val: string) => void;
};
export const PhoneCode = ({ name, onChange, disabled }: Props) => {
  const filterRef = useRef<string>("");

  return (
    <Form.Item name={name} className="phone-select" noStyle>
      <Select
        popupClassName="phone-select-dropdown"
        showSearch
        onChange={onChange}
        filterOption={(input, option) => {
          filterRef.current = input;

          return (
            (option?.children?.toString() as string)
              ?.toLowerCase()
              ?.indexOf(input?.toLowerCase()) >= 0
          );
        }}
        optionLabelProp="label"
        disabled={disabled}
        onInputKeyDown={({ key }) => {
          // to select the first option when tab key is pressed
          if (filterRef.current && key === "Tab") {
            const filteredCountries = COUNTRY_DATA.filter((item) =>
              item.phonecode.includes(filterRef.current),
            );
            if (filteredCountries.length)
              onChange?.(filteredCountries[0].phonecode);
          }
        }}
        suffixIcon={<div />}
        bordered={false}
      >
        {COUNTRY_DATA.map((item) => (
          <Select.Option
            key={item.isoCode}
            value={item.isoCode}
            label={item.phonecode}
          >
            {item.name + " / " + item.phonecode}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};
