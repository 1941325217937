import { LoadingOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  message,
  notification,
  Row,
  Select,
} from "antd";
import ButtonLink from "components/ButtonLink";
import { CourseStatus, KidsAgeType } from "constants/type.interface";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import AddLocationWrapper from "pages/kidzBiz/locations/addLocation";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useCurrentLocation,
  useCurrentUser,
} from "scripts/custom-hooks/useCurrentUser";
import helpers from "scripts/helpers";
import courseStore from "scripts/mobx/courseStore";
import locationsStore from "scripts/mobx/locationStore";
import ImageUploader from "../../../../../components/ImageUploader";
import { bizIcons } from "../../../../../constants/bizConstants";

const { Option } = Select;

interface IProps {
  onSaveDraft: (data: any) => void;
  nextStep: () => void;
  publishChecked: boolean;
  dataCourse: any;
  courseStatus: CourseStatus;
}

export const ManCourseCreateStep1 = observer((props: IProps) => {
  const { onSaveDraft, nextStep, publishChecked, dataCourse, courseStatus } =
    props;

  const { t } = useTranslation();
  const user = useCurrentUser();
  const categories = toJS(courseStore?.categoryData);
  const isDisabled = [CourseStatus.Draft, CourseStatus.Pending].includes(
    courseStatus,
  )
    ? false
    : true;
  const getOptionsByRange: (r: KidsAgeType) => any[] = (r: KidsAgeType) => {
    switch (r) {
      case KidsAgeType.Months:
        return new Array(23).fill(1).map((e, i) => i + e);

      case KidsAgeType.Years:
        return new Array(18).fill(2).map((e, i) => i + e);

      default:
        return [];
    }
  };
  const [isLoading, setIsLoading] = useState(false);

  const [range, setRange] = useState<KidsAgeType>(
    dataCourse?.step1?.kidsAgeFromType || KidsAgeType.Years,
  );
  const [rangeTo, setRangeTo] = useState<KidsAgeType>(
    dataCourse?.step1?.kidsAgeToType || KidsAgeType.Years,
  );

  const [kidsAgeFrom, setKidsAgeFrom] = useState(
    dataCourse?.step1?.kidsAgeFrom,
  );
  const [kidsAgeTo, setKidsAgeTo] = useState(dataCourse?.step1?.kidsAgeTo);

  const [imageUrl, setImageUrl] = useState<any>(dataCourse?.step1?.imageUrl);

  const [rangeOptionsList, setRangeOptionsList] = useState<any[]>(
    getOptionsByRange(range),
  );

  const [rangeToOptionsList, setRangeToOptionsList] = useState<any[]>(
    getOptionsByRange(range),
  );

  const [ageFromIndex, setAgeFromIndex] = useState<number>(
    typeof kidsAgeFrom === "number" ? rangeOptionsList.indexOf(kidsAgeFrom) : 0,
  );
  const [isAddLocation, setAddLocation] = useState<boolean>(false);

  useEffect(() => {
    setRangeOptionsList(getOptionsByRange(range));
  }, [range]);

  useEffect(() => {
    setRangeToOptionsList(getOptionsByRange(rangeTo));
  }, [rangeTo]);

  const [form] = Form.useForm();

  const onFinish = async () => {
    courseStore.updateCourseCreateData({
      step1: form.getFieldsValue(),
    });
    await nextStep();
  };

  const saveDraft = async () => {
    form
      .validateFields()
      .then(async () => {
        courseStore.updateCourseCreateData({
          step1: form.getFieldsValue(),
        });
        onSaveDraft?.({
          ...dataCourse,
          step1: form.getFieldsValue(),
        });
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };

  const uploadButton = (isUploading: boolean) => {
    return (
      <div>
        <div style={{ height: "24px" }} className={"flex-center"}>
          {isUploading ? (
            <LoadingOutlined />
          ) : (
            <i className={`${bizIcons.IMAGE} fs-20 text-color-secondary`} />
          )}
        </div>
        <div
          className={"text-color-secondary"}
          style={{ marginTop: 8, width: "100%" }}
        >
          {t("signUp.step4.uploadImage", {
            defaultValue: "Upload image",
          })}
        </div>
      </div>
    );
  };

  const handleKidsAgeFromSelect = (option: any) => {
    setKidsAgeFrom(option);
    setAgeFromIndex(rangeOptionsList.indexOf(option));

    if (!kidsAgeTo || kidsAgeTo <= option) {
      const newKidsAgeTo = rangeToOptionsList?.filter(
        (e, i) => i > rangeOptionsList.indexOf(option),
      )?.[0];
      setKidsAgeTo(newKidsAgeTo);
      form.setFieldsValue({
        kidsAgeTo: newKidsAgeTo,
      });
    }
  };

  const handleKidsAgeToSelect = (option: any) => {
    setKidsAgeTo(option);

    if (!kidsAgeFrom || kidsAgeFrom >= option) {
      const newKidsAgeFrom = rangeOptionsList?.[0];
      setKidsAgeFrom(newKidsAgeFrom);
      setAgeFromIndex(0);
      form.setFieldsValue({
        kidsAgeFrom: newKidsAgeFrom,
      });
    }
  };

  const onCheckBeforeUpload = (messages: string[]) => {
    messages.map((m) => message.error(m));
  };

  const onUploadChanged = (file: File) => {
    // console.log(file);
    helpers.getBase64(file).then((res) => {
      setImageUrl(res);
    });
    const formData = new FormData();
    formData.append("files", file);
    courseStore?.uploadImage(formData).then((res) => {
      form.setFieldsValue({
        imageUrl: res?.data?.[0]?.url,
      });
    });
  };
  const category = categories?.filter(
    (el) => el.name === dataCourse?.category,
  )?.[0];

  const subCategory = category?.subCategories?.filter(
    (el: any) => el.name === dataCourse?.subCategory,
  )?.[0];

  const locationCurent = useCurrentLocation(false);

  const onAddLocationData = async (data: any) => {
    const addData = [
      ...locationCurent,
      ...data,
      // stateProvinceId: data.stateProvinceId,
    ];
    locationsStore
      .putLocation(addData)
      .then(() => {
        message.success(
          t("messages.location_successfully_created", {
            defaultValue: "Location successfully created",
          }),
        );
      })
      .catch((error) => {
        if (error?.data?.error) {
          notification.error({
            message: error?.data?.error
              ? t("error?.data?.error")
              : t("server_errors." + "Bad Request"),
          });
        } else {
          notification.error({ message: t("server_errors." + "Bad Request") });
        }
      });
    setAddLocation(false);
  };

  return (
    <>
      <Form
        form={form}
        name="course-create-step-1"
        className="course-create-form tsl-4 form-grid-layout"
        initialValues={{
          name: dataCourse?.step1?.name || "",
          code: dataCourse?.step1?.code || "",
          _category: dataCourse?.step1?._category || undefined,
          _subCategory: dataCourse?.step1?._subCategory || undefined,
          _location: dataCourse?.step1?._location,
          kidsAgeFrom,
          kidsAgeTo,
          kidsAgeFromType: range,
          kidsAgeToType: rangeTo,
          ageGroup: dataCourse?.step1?.ageGroup || undefined,
          imageUrl: dataCourse?.step1?.imageUrl || "",
        }}
        onFinish={onFinish}
      >
        <Row gutter={24}>
          <Col xs={24} md={12}>
            <div className="bsl-4">
              <div className="label-wrapper">
                <label htmlFor="name">{t("courses.courseName")}*</label>
              </div>
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: t("messages.required_field", {
                      defaultValue: "Please fill in all required fields",
                    }),
                  },
                ]}
              >
                <Input.TextArea
                  id={"name"}
                  maxLength={50}
                  placeholder={t("courses.enterCourseName")}
                  autoSize={{ minRows: 3, maxRows: 3 }}
                  disabled={isDisabled}
                />
              </Form.Item>
            </div>
          </Col>

          <Col xs={24} md={12}>
            <div className="bsl-4">
              <div className="label-wrapper">
                <label htmlFor="code">{t("courses.courseCode")}</label>
              </div>

              <Form.Item name="code">
                <Input
                  id={"code"}
                  maxLength={30}
                  onKeyPress={(e: any) => {
                    if (e.key) {
                      if (!new RegExp(/^[A-Za-z0-9\-]+$/g).test(e.key)) {
                        e.preventDefault();
                      }
                    }
                  }}
                  prefix={<i className={`${bizIcons.CODE} mr-10 fs-20`} />}
                  size={"large"}
                  placeholder={t("courses.enterCourseCode")}
                  disabled={isDisabled}
                />
              </Form.Item>
            </div>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col xs={24} md={12}>
            <div className="bsl-4">
              <div className="label-wrapper">
                <label htmlFor="_category">{t("courses.category")}*</label>
              </div>
              <Form.Item
                name="_category"
                rules={[
                  {
                    required: true,
                    message: t("messages.required_field", {
                      defaultValue: "Please fill in all required fields",
                    }),
                  },
                ]}
              >
                <Select
                  suffixIcon={<i className="ri-arrow-drop-down-line fs-20" />}
                  placeholder={t("courses.selectCategory")}
                  disabled={isDisabled}
                >
                  {categories?.map((el: any) => (
                    <Select.Option key={el.id} value={el.id}>
                      {t("categories." + el.name)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item className="bsl-4" shouldUpdate={true}>
              {() => {
                return (
                  <>
                    <div className="label-wrapper">
                      <label htmlFor="_subCategory">
                        {t("courses.subCategory")}*
                      </label>
                    </div>
                    <Form.Item
                      name="_subCategory"
                      rules={[
                        {
                          required: true,
                          message: t("messages.required_field", {
                            defaultValue: "Please fill in all required fields",
                          }),
                        },
                      ]}
                    >
                      <Select
                        disabled={
                          form.getFieldValue("_category") ? isDisabled : true
                        }
                        suffixIcon={
                          <i className="ri-arrow-drop-down-line fs-20" />
                        }
                        placeholder={t("courses.selectSubCategory")}
                      >
                        {categories
                          .filter(
                            (item) =>
                              item.id === form.getFieldValue("_category"),
                          )?.[0]
                          ?.subCategories?.map(
                            (el: { id: string; name: string }) => (
                              <Select.Option key={el.id} value={el.id}>
                                {t("subCategories." + el.name)}
                              </Select.Option>
                            ),
                          )}
                      </Select>
                    </Form.Item>
                  </>
                );
              }}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col xs={24} md={12}>
            <div className="bsl-4">
              <div className="flex-space-between label-wrapper">
                <label htmlFor="_location">{t("courses.location")}*</label>
                <ButtonLink
                  type="button"
                  onClick={() => setAddLocation((prev) => !prev)}
                >
                  <i
                    className={bizIcons.ADDCIRCLE}
                    style={{ marginRight: "3px", fontSize: "15px" }}
                  />
                  {t("kidzbiz.addLocation")}
                </ButtonLink>
              </div>
              <Form.Item
                name="_location"
                rules={[
                  {
                    required: true,
                    message: t("messages.required_field", {
                      defaultValue: "Please fill in all required fields",
                    }),
                  },
                ]}
              >
                <Select
                  placeholder={t("courses.selectLocation")}
                  suffixIcon={<i className="ri-arrow-drop-down-line fs-20" />}
                  onChange={(val, option) => {
                    if ("key" in option) {
                      courseStore.updateCourseCreateData({
                        providerLocationId: option.key,
                      });
                    }
                  }}
                >
                  {locationCurent?.map((el: any) => {
                    return (
                      <Select.Option key={el.id} value={el.id}>
                        {el.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </div>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              name="ageGroup"
              className="kids-age-from-to bsl-4"
              rules={[
                {
                  required: true,
                  message: t("messages.required_field", {
                    defaultValue: "Please fill in all required fields",
                  }),
                },
              ]}
            >
              <div className="label-wrapper">
                <label htmlFor="kidsAgeFrom">
                  {t("courses.kidsAgeFromTo")}*
                </label>
              </div>
              <Form.Item>
                <Input.Group compact={true}>
                  <Form.Item
                    name="kidsAgeFrom"
                    className="kids-age-from"
                    style={{
                      width: "25%",
                      textAlign: "center",
                    }}
                  >
                    <Select
                      id={"kidsAgeFrom"}
                      suffixIcon={
                        <i className="ri-arrow-drop-down-line fs-20" />
                      }
                      size={"middle"}
                      placeholder={t("courses.From")}
                      style={{
                        width: "100%",
                        textAlign: "left",
                      }}
                      onSelect={(e: any) => {
                        form.setFieldsValue({ ageGroup: 1 });
                        handleKidsAgeFromSelect(e);
                      }}
                      disabled={isDisabled}
                    >
                      {rangeOptionsList.map((e, i) => {
                        if (i === rangeOptionsList?.length - 1) return false;
                        return (
                          <Option key={Math.random()} value={e}>
                            {e}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="kidsAgeFromType"
                    className="kids-age-to"
                    style={{
                      width: "25%",
                      textAlign: "center",
                    }}
                  >
                    <Select
                      id={"kidsAgeFromType"}
                      suffixIcon={
                        <i className="ri-arrow-drop-down-line fs-20" />
                      }
                      size={"middle"}
                      style={{
                        width: "100%",
                        textAlign: "left",
                      }}
                      onChange={(e: string) => {
                        let newKidsAgeTo = rangeToOptionsList?.[0];
                        if (e === form.getFieldValue("kidsAgeToType")) {
                          newKidsAgeTo = rangeToOptionsList?.filter(
                            (e, i) => i > ageFromIndex,
                          )?.[0];
                        }
                        if (kidsAgeFrom >= kidsAgeTo) {
                          form.setFieldsValue({
                            kidsAgeTo: newKidsAgeTo,
                          });
                          setKidsAgeTo(newKidsAgeTo);
                        }

                        if (e === KidsAgeType.Months) {
                          setRange(KidsAgeType.Months);
                          form.setFieldsValue({
                            kidsAgeFromType: KidsAgeType.Months,
                          });
                        } else {
                          setRange(KidsAgeType.Years);
                          setRangeTo(KidsAgeType.Years);
                          form.setFieldsValue({
                            kidsAgeFromType: KidsAgeType.Years,
                            kidsAgeToType: KidsAgeType.Years,
                          });
                        }
                      }}
                      dropdownClassName="kids-age-type-drowndown"
                      disabled={isDisabled}
                    >
                      <Option value="Years">{t("courses.Years")}</Option>
                      <Option value="Months">{t("courses.Months")}</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="kidsAgeTo"
                    className="kids-age-to"
                    style={{
                      width: "25%",
                      textAlign: "center",
                    }}
                  >
                    <Select
                      size={"middle"}
                      suffixIcon={
                        <i className="ri-arrow-drop-down-line fs-20" />
                      }
                      id={"kidsAgeTo"}
                      placeholder={t("courses.To")}
                      style={{
                        width: "100%",
                        textAlign: "left",
                      }}
                      onSelect={(e: any) => {
                        kidsAgeFrom && form.setFieldsValue({ ageGroup: 1 });
                        handleKidsAgeToSelect(e);
                      }}
                      disabled={isDisabled}
                    >
                      {form.getFieldValue("kidsAgeFromType") ===
                      form.getFieldValue("kidsAgeToType")
                        ? rangeToOptionsList
                            .filter((e, i) => i > ageFromIndex)
                            .map((e, i) => (
                              <Option key={Math.random()} value={e}>
                                {e}
                              </Option>
                            ))
                        : rangeToOptionsList?.map((e, i) => (
                            <Option key={Math.random()} value={e}>
                              {e}
                            </Option>
                          ))}
                    </Select>
                  </Form.Item>
                  <Form.Item noStyle shouldUpdate>
                    {() => {
                      return (
                        <Form.Item
                          name="kidsAgeToType"
                          className="kids-age-type"
                          style={{
                            width: "25%",
                            textAlign: "center",
                          }}
                        >
                          <Select
                            id={"kidsAgeToType"}
                            suffixIcon={
                              <i className="ri-arrow-drop-down-line fs-20" />
                            }
                            size={"middle"}
                            style={{
                              width: "100%",
                              textAlign: "left",
                            }}
                            onChange={(e: string) => {
                              if (e === KidsAgeType.Months) {
                                setRangeTo(KidsAgeType.Months);
                                setRange(KidsAgeType.Months);

                                if (kidsAgeFrom >= kidsAgeTo) {
                                  const newKidsAgeTo =
                                    rangeToOptionsList?.filter(
                                      (e, i) => i > ageFromIndex,
                                    )?.[0];
                                  setKidsAgeTo(newKidsAgeTo);
                                  form.setFieldsValue({
                                    kidsAgeTo: newKidsAgeTo,
                                  });
                                }
                                form.setFieldsValue({
                                  kidsAgeFromType: KidsAgeType.Months,
                                  kidsAgeToType: KidsAgeType.Months,
                                });
                              } else {
                                setRangeTo(KidsAgeType.Years);
                                form.setFieldsValue({
                                  kidsAgeToType: KidsAgeType.Years,
                                });
                                if (kidsAgeFrom >= kidsAgeTo) {
                                  setKidsAgeTo(rangeToOptionsList?.[0]);
                                  form.setFieldsValue({
                                    kidsAgeTo: rangeToOptionsList?.[0],
                                  });
                                }
                              }
                            }}
                            dropdownClassName="kids-age-type-drowndown"
                            disabled={isDisabled}
                          >
                            <Option value="Years">{t("courses.Years")}</Option>
                            <Option value="Months">
                              {t("courses.Months")}
                            </Option>
                          </Select>
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item noStyle name="imageUrl">
          <Input type="hidden" />
        </Form.Item>
        <Row gutter={24}>
          <Col xs={24} md={12}>
            <Form.Item
              help={
                <div className="flex-space-between">
                  <span>
                    {t("courses.helpUploadImageCourse", {
                      size: 1,
                    })}
                  </span>
                </div>
              }
              rules={[
                {
                  required: true,
                  message: t("messages.required_field", {
                    defaultValue: "Please fill in all required fields",
                  }),
                },
              ]}
              name="imageUrl"
              className={"bsl-4"}
            >
              <div className="label-wrapper">
                <label htmlFor="courseImg">
                  {t("courses.imageOfCourse", {
                    defaultValue: "Image of course",
                  })}
                  *
                </label>
              </div>
              <ImageUploader
                name={t("courses.imageOfCourse", {
                  defaultValue: "Image of course",
                })}
                imageUrl={imageUrl}
                onCheckBeforeUpload={onCheckBeforeUpload}
                uploadButtonFunc={uploadButton}
                onUploadChanged={onUploadChanged}
                disabled={isDisabled}
              />
            </Form.Item>
          </Col>
        </Row>
        {/* {!!imageUrl && dataCourse?.status === CourseStatus.Draft && (
          <Form.Item noStyle shouldUpdate>
            {() => {
              return (
                <div className="flex-align-items">
                  <Form.Item name="sameUpload" valuePropName="checked">
                    <Checkbox>{t("courses.sameUpload")}</Checkbox>
                  </Form.Item>
                </div>
              );
            }}
          </Form.Item>
        )} */}

        <Row gutter={24}>
          {dataCourse?.status === CourseStatus.Draft && (
            <Col xs={24} md={8}>
              <Form.Item>
                <Button
                  className="full-width fs-16 tsl-4"
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                  onClick={() => saveDraft()}
                >
                  <div className={"flex-center"}>{t("bizBase.save_draft")}</div>
                </Button>
              </Form.Item>
            </Col>
          )}

          <Col xs={24} md={8}>
            <Form.Item>
              <Button
                className="full-width fs-16 tsl-4"
                type="primary"
                htmlType="submit"
                loading={isLoading}
              >
                <div className={"flex-center"}>
                  {t("bizBase.nextStep", { defaultValue: "Next step " })}
                  <i className="ri-arrow-right-line ml-10 fs-20" />
                </div>
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Drawer
        placement="right"
        maskClosable={false}
        closable={false}
        onClose={() => setAddLocation((prev) => !prev)}
        visible={isAddLocation}
        className="drawer-form-add-locations"
      >
        <AddLocationWrapper
          userId={user?.providerAttributes?.id}
          addLocationData={onAddLocationData}
          onBack={() => setAddLocation((prev) => !prev)}
        />
      </Drawer>
    </>
  );
});
