import {
  Button,
  Col,
  Form,
  Input,
  message,
  notification,
  Radio,
  Row,
  Select,
  Slider,
} from "antd";
import SaveButtonText from "components/SaveButtonText";
import TableComponent from "components/TableComponent/TableComponent";
import { RescheduleType } from "constants/type.interface";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import helpers from "scripts/helpers";
import userStore from "scripts/mobx/userStore";
import ButtonLink from "../../../components/ButtonLink";
import { bizIcons } from "../../../constants/bizConstants";
import { tablePoliciesLinksColumns } from "../tableGenerator";
import AddLink from "./addLink";
import EditLink from "./editLink";

interface IProps {
  info: any;
  onBack: () => void;
  onEdit: (data: any) => Promise<void>;
  policiesText: any;
  policiesUrl: any;
  reschedulingOutcome: any;
}

const EditInfo = inject()(
  observer((props: IProps) => {
    const {
      onBack,
      onEdit,
      info,
      policiesText,
      policiesUrl,
      reschedulingOutcome,
    } = props;
    const { t } = useTranslation("base");

    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [editLink, setEditLink] = useState(false);
    const [linkData, setLinkData] = useState<any[]>([]);
    const [addLink, setAddLink] = useState(false);
    const [policieData, setPolicieData] = useState<any>();
    const [editLinkIndex, setEditLinkIndex] = useState<number>(1);

    const [form] = Form.useForm();

    useEffect(() => {
      setLinkData(policiesUrl);
    }, []);

    const onFinish = async (values: any) => {
      setIsLoading(true);
      try {
        await form.validateFields(Object.keys(values));
        onEdit({ ...values }).then(() => {
          setIsLoading(false);
        });

        const rootPolicies = values?.policies
          ? [
              {
                ordinal: 1,
                contentType: "TEXT",
                name: "All purchase conditions/policies",
                content: values?.policies || "",
              },
            ]
          : [];

        const newPolicies: any[] =
          linkData?.map((item, index) => {
            return {
              ordinal: item?.ordinal || index + 2,
              contentType: "URL",
              name: item?.name || "",
              content: item?.content,
            };
          }) || [];

        const params = {
          description: values.briefDescription || "",
          policies: rootPolicies.concat(newPolicies),
          reschedulingOutcome: {
            courseCancelledByKidzbiz: values?.courseCancellationByKidzbiz,
            courseCancelledByCustomer: values?.courseCancellationByCustomer,
            classCancelledByKidzbiz: values?.classCancellationByKidzbiz,
            compensatedAbsencesByCustomerPercentage:
              values?.compensatedAbsencesByCustomer,
            compensationForCustomerAbsence:
              values?.compensatedAbsencesByCustomer > 0
                ? values?.compensatedForCustomerAbsences
                : RescheduleType.CREDIT,
          },
        };
        if (values.webSite?.trim()) {
          Object.assign(params, {
            websiteUrl: values.method + values.webSite?.trim() || "",
          });
        }
        userStore
          ?.setAdditionalInfo(params)
          .then(() => {
            setIsLoading(false);
            message.success(
              t("messages.update_successful", {
                defaultValue: "Update successful",
              }),
            );
            // nextStep();
          })
          .catch((error) => {
            setIsLoading(false);
            helpers?.throwErrors(
              t,
              ["com.bizibuz.core.service.provider.InvalidWebsiteUrlException"],
              error?.data?.error,
              error?.data?.message,
            );
          });
      } catch (e: any) {
        const { _message } = e;
        message.error(_message);
        setIsLoading(false);
      }
    };

    const validateForm = useCallback(
      (field?: string) => {
        form
          .validateFields(field ? [field] : undefined)
          .then(
            (r) => {
              setIsDisabled(false);
            },
            (rej) => {
              setIsDisabled(true);
            },
          )
          .catch((e) => {
            const { _message } = e;
            message.error(
              _message ||
                t("messages.some_error_occurred", {
                  defaultValue: "Some error occurred",
                }),
            );
          });
      },
      [isDisabled],
    );

    const onAddPolicyLink = (index?: number) => () => {
      //Update provider info: form.getFieldsValue()
      // setEditPolicyLinkIndex(index);
      setAddLink(true);
    };

    const onClickEditLinkData = (data: any) => {
      console.log("onClickEditLocationData:", data);
      const link = {
        ...data.record,
      };
      setPolicieData(link);
      setEditLink(true);
      setEditLinkIndex(data?.index);
    };

    const linkEditComponent = (data: any) => (
      <span
        className="link link-text no-select"
        onClick={() => onClickEditLinkData(data)}
        style={{ cursor: "pointer" }}
      >
        <span className="link-text no-select">
          {t("kidzbiz.edit")}
          <i
            className={bizIcons.ARROWRIGHT}
            style={{ marginLeft: "8px", fontSize: "16px" }}
          />
        </span>
      </span>
    );

    const onBackInfo = () => {
      setAddLink(false);
      setEditLink(false);
    };
    const onAddLink = (data: any) => {
      console.log("add", data);
      const newPolicy = {
        content: data?.content,
        name: data?.name,
      };
      setLinkData([...linkData, newPolicy]);
    };

    const oneEditLink = async (data: any) => {
      const _temp = [...linkData];
      _temp[editLinkIndex] = data;
      setLinkData(_temp);
    };
    const text = info?.providerAttributes?.websiteUrl?.trim();
    const findIndexMethod = text?.search("://");
    const newUrl =
      findIndexMethod >= 0
        ? info?.providerAttributes?.websiteUrl?.trim().split("://")
        : [null, text]; // for https use url..split("://")[0];
    // console.log(findIndexMethod, newUrl);

    return (
      <div>
        {addLink ? (
          <AddLink onBack={onBackInfo} onAdd={onAddLink} />
        ) : editLink ? (
          <EditLink
            onBack={onBackInfo}
            onEdit={oneEditLink}
            linkInfo={policieData}
          />
        ) : (
          <div className="content-wrapper content-with-table">
            <ButtonLink onClick={() => onBack()}>
              <i className={`${bizIcons.ARROWLEFT} fs-20 mr-8`} />
              {t("kidzbiz.backToKidzbiz")}
            </ButtonLink>
            <div className="auth-header bsl-4">
              <div>
                <span className={"auth-header_title"}>{t("menu.kidzbiz")}</span>
              </div>
            </div>
            <div className="auth-form edit-info-provider">
              <Form
                form={form}
                onLoad={() => validateForm()}
                name="normal_login"
                className="login-form form-grid-layout edit-detail-kidzbiz"
                initialValues={{
                  briefDescription: info?.providerAttributes?.description,
                  policies: policiesText?.[0]?.content,
                  method: newUrl?.[0] ? newUrl?.[0] + "://" : "http://",
                  webSite: newUrl?.[1]?.trim() || "",
                  courseCancellationByKidzbiz:
                    reschedulingOutcome?.courseCancelledByKidzbiz || undefined,
                  courseCancellationByCustomer:
                    reschedulingOutcome?.courseCancelledByCustomer || undefined,
                  classCancellationByKidzbiz:
                    reschedulingOutcome?.classCancelledByKidzbiz || undefined,
                  compensatedAbsencesByCustomer:
                    reschedulingOutcome?.compensatedAbsencesByCustomerPercentage,
                  compensatedForCustomerAbsences:
                    reschedulingOutcome?.compensationForCustomerAbsence ||
                    undefined,
                }}
                onFinish={onFinish}
              >
                <Row className="bsl-4">
                  <Col span={24}>
                    <Form.Item className="bsl-4 website-row">
                      <div className="label-wrapper">
                        <label htmlFor="Website">
                          {t("kidzbiz.enterWebsite")}
                        </label>
                      </div>
                      <Form.Item
                        name="webSite"
                        rules={[
                          {
                            required: false,
                            message: t("messages.required_field"),
                            whitespace: true,
                          },
                          {
                            pattern:
                              /(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}/,
                            message: t("server_errors.invalid_website_url"),
                          },
                        ]}
                      >
                        <Input
                          id={"webSite"}
                          prefix={
                            <>
                              <i className={`${bizIcons.GLOBE} mr-6`} />
                              <Form.Item
                                name="method"
                                className="website-select"
                              >
                                <Select dropdownClassName="website-select-dropdown">
                                  <Select.Option value="http://">
                                    http://
                                  </Select.Option>
                                  <Select.Option value="https://">
                                    https://
                                  </Select.Option>
                                </Select>
                              </Form.Item>
                            </>
                          }
                          placeholder={t("kidzbiz.enterWebsite")}
                          maxLength={100}
                          readOnly={isLoading}
                          onBlur={() => validateForm("webSite")}
                          onPressEnter={() => validateForm("webSite")}
                          size={"large"}
                        />
                      </Form.Item>
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <div className="bsl-4">
                      <div className="label-wrapper">
                        <label htmlFor="briefDescription">
                          {t("kidzbiz.organizationDescription")}*
                        </label>
                        <div className={"text-color-secondary small"}>
                          {t("kidzbiz.peInec")}
                        </div>
                      </div>
                      <Form.Item
                        name="briefDescription"
                        rules={[
                          {
                            required: true,
                            message: t("messages.required_field"),
                          },
                          {
                            max: 5000,
                            message: t(
                              "server_errors.com.bizibuz.core.service.provider.LimitCharacters",
                              {
                                defaultValue:
                                  "Please reduce the number of characters",
                              },
                            ),
                          },
                        ]}
                      >
                        <Input.TextArea
                          id={"briefDescription"}
                          placeholder={t("kidzbiz.eod")}
                          readOnly={isLoading}
                          autoSize={{ minRows: 6, maxRows: 6 }}
                          onChange={() => validateForm()}
                        />
                      </Form.Item>
                      <div className={"text-color-secondary small"}>
                        {t("kidzbiz.maxNumber")} 5000
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <h3>{t("signUp.step4.rescheduling_outcomes")}</h3>
                    <Form.Item className="bsl-5 reschedule-row">
                      <div className="label-wrapper">
                        <label htmlFor="courseCancellationByKidzbiz">
                          {t("signUp.step4.course_cancellation_by_Kidzbiz")}*
                        </label>
                      </div>
                      <Form.Item
                        name="courseCancellationByKidzbiz"
                        rules={[
                          {
                            required: true,
                            message: t("messages.required_field"),
                          },
                        ]}
                      >
                        <Radio.Group>
                          <Radio value={RescheduleType.REFUND}>
                            {t("signUp.step4." + RescheduleType.REFUND)}
                          </Radio>
                          <Radio value={RescheduleType.CREDIT}>
                            {t("signUp.step4." + RescheduleType.CREDIT)}
                          </Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Form.Item>

                    <Form.Item className="bsl-5 reschedule-row">
                      <div className="label-wrapper">
                        <label htmlFor="courseCancellationByCustomer">
                          {t("signUp.step4.course_cancellation_by_customer")}*
                        </label>
                      </div>
                      <Form.Item
                        name="courseCancellationByCustomer"
                        rules={[
                          {
                            required: true,
                            message: t("messages.required_field"),
                          },
                        ]}
                      >
                        <Radio.Group>
                          <Radio value={RescheduleType.REFUND}>
                            {t("signUp.step4." + RescheduleType.REFUND)}
                          </Radio>
                          <Radio value={RescheduleType.CREDIT}>
                            {t("signUp.step4." + RescheduleType.CREDIT)}
                          </Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Form.Item>

                    <Form.Item className="bsl-5 reschedule-row">
                      <div className="label-wrapper">
                        <label htmlFor="classCancellationByKidzbiz">
                          {t("signUp.step4.class_cancellation_by_Kidzbiz")}*
                        </label>
                      </div>
                      <Form.Item
                        name="classCancellationByKidzbiz"
                        rules={[
                          {
                            required: true,
                            message: t("messages.required_field"),
                          },
                        ]}
                      >
                        <Radio.Group>
                          <Radio value={RescheduleType.REFUND}>
                            {t("signUp.step4." + RescheduleType.REFUND)}
                          </Radio>
                          <Radio value={RescheduleType.CREDIT}>
                            {t("signUp.step4." + RescheduleType.CREDIT)}
                          </Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Form.Item>

                    <Form.Item className="bsl-5 reschedule-row">
                      <div className="label-wrapper">
                        <label htmlFor="compensatedAbsencesByCustomer">
                          {t("signUp.step4.compensated_absences_by_customer")}*
                        </label>
                        <div className={"text-color-secondary small"}>
                          {t(
                            "signUp.step4.compensated_absences_by_customer_note",
                          )}
                        </div>
                      </div>
                      <div style={{ paddingLeft: 10, paddingRight: 15 }}>
                        <Form.Item
                          name="compensatedAbsencesByCustomer"
                          rules={[
                            {
                              required: true,
                              message: t("messages.required_field"),
                            },
                          ]}
                        >
                          <Slider
                            marks={{
                              0: "0%",
                              25: "25%",
                              50: "50%",
                              75: "75%",
                              100: "100%",
                            }}
                            step={25}
                          />
                        </Form.Item>
                      </div>
                    </Form.Item>

                    <Form.Item noStyle shouldUpdate>
                      {() => {
                        return form.getFieldValue(
                          "compensatedAbsencesByCustomer",
                        ) ? (
                          <Form.Item className="bsl-4 reschedule-row">
                            <div className="label-wrapper">
                              <label htmlFor="compensatedForCustomerAbsences">
                                {t(
                                  "signUp.step4.compensated_for_customer_absences",
                                )}
                                *
                              </label>
                            </div>
                            <div style={{ paddingLeft: 10, paddingRight: 15 }}>
                              <Form.Item
                                name="compensatedForCustomerAbsences"
                                rules={[
                                  {
                                    required:
                                      form.getFieldValue(
                                        "compensatedAbsencesByCustomer",
                                      ) > 0
                                        ? true
                                        : false,
                                    message: t("messages.required_field"),
                                  },
                                ]}
                              >
                                <Radio.Group>
                                  <Radio value={RescheduleType.REFUND}>
                                    {t("signUp.step4." + RescheduleType.REFUND)}
                                  </Radio>
                                  <Radio value={RescheduleType.CREDIT}>
                                    {t("signUp.step4." + RescheduleType.CREDIT)}
                                  </Radio>
                                </Radio.Group>
                              </Form.Item>
                            </div>
                          </Form.Item>
                        ) : null;
                      }}
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <div className="tsl-3 bsl-4">
                      <div className="label-wrapper">
                        <label htmlFor="policies">
                          {t("signUp.step4.allPurchaseConditionsPolicies")}
                        </label>
                        {/* <div className={"text-color-secondary small"}>
                          {t("kidzbiz.peInec")}
                            </div> */}
                        <div className={"text-color-secondary small"}>
                          {t("signUp.step4.pleaseEnterLang")}
                        </div>
                        <div className={"text-color-secondary small bsl-5"}>
                          {t("signUp.step4.allPurchaseConditionsPolicies_note")}
                        </div>
                      </div>
                      <Form.Item name="policies">
                        <Input.TextArea
                          id={"policies"}
                          readOnly={isLoading}
                          placeholder={t("kidzbiz.ecp")}
                          autoSize={{ minRows: 6, maxRows: 6 }}
                          maxLength={2000}
                          onChange={() => validateForm()}
                        />
                      </Form.Item>
                      <div className={"text-color-secondary small"}>
                        {t("kidzbiz.maxNumber")} 2000
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row className="bsl-4">
                  <Col span={24}>
                    <div className="label-wrapper flex-space-between">
                      {t("signUp.step4.purchaseConditionsPoliciesPlink", {
                        defaultValue: "Purchase conditions/policies links",
                      })}
                      <span
                        className="link link-text no-select"
                        onClick={onAddPolicyLink()}
                        style={{ cursor: "pointer" }}
                      >
                        <span className="link-text no-select">
                          {t("signUp.step4.addLink", {
                            defaultValue: "Add link",
                          })}
                          <i
                            className={bizIcons.ARROWRIGHT}
                            style={{ marginLeft: "8px", fontSize: "16px" }}
                          />
                        </span>
                      </span>
                    </div>
                    <TableComponent
                      broken={true}
                      pagination={false}
                      propsTableColumns={tablePoliciesLinksColumns(
                        false,
                        linkEditComponent,
                      )}
                      propsTableData={linkData}
                    />
                  </Col>
                </Row>

                <Row gutter={24}>
                  <Col span={24}>
                    <Form.Item shouldUpdate>
                      {() => {
                        return (
                          <Button
                            className="full-width fs-16 tsl-4"
                            type="primary"
                            htmlType="submit"
                            loading={isLoading}
                            icon={
                              <SaveButtonText>
                                {t("bizBase.saveChanges", {
                                  defaultValue: "Save changes",
                                })}
                              </SaveButtonText>
                            }
                          />
                        );
                      }}
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        )}
      </div>
    );
  }),
);

export default EditInfo;
