import {
  Button,
  Divider,
  Modal,
  notification,
  Spin,
  Tabs,
  Tooltip,
  Typography,
} from "antd";
import { ButtonWhite } from "components/ButtonWhite";
import { PAGE_SIZE } from "config";
import { FORMAT_DATE } from "constants/commons";
import {
  CancelType,
  CourseStatus,
  PreferredLanguage,
} from "constants/type.interface";
import { AppRoutes } from "helpers/app.routes";
import Cookies from "js-cookie";
import { uniqBy } from "lodash";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import moment from "moment";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  useCurrentUser,
  userBlocked,
  useType,
} from "scripts/custom-hooks/useCurrentUser";
import helpers from "scripts/helpers";
import courseStore from "scripts/mobx/courseStore";
import customersStore from "scripts/mobx/customersStore";
import { Languages, languageStoreContext } from "scripts/mobx/languageStore";
import { UserType } from "scripts/mobx/userStore";
import noData from "../../../assets/no-data.svg";
import ButtonLink from "../../../components/ButtonLink";
import { Link } from "../../../components/Link";
import TableComponent from "../../../components/TableComponent/TableComponent";
import { ViewMoreBlock } from "../../../components/viewMoreBlock";
import { bizIcons, bizIcons as tb } from "../../../constants/bizConstants";
import { ICustomerChildData } from "../interface";
import {
  tableCourseCustomerChildrensColumns,
  tableCourseCustomerChildrensData,
  tableCourseCustomersColumns,
  tableCourseCustomersData,
  tableCourseDetailsPriceColumns,
  tableCourseDetailsPriceData,
  tableCourseDetailsTitleColumn,
  tableCourseDetailsTitleData,
  tableCourseLocationColumns,
  tableCourseScheduleColumns,
  tableCourseScheduleData,
  tableCoursesPromColumns,
  tableCoursesPromotionsData,
} from "../tableGenerator";
import {
  isSpecialLocation,
  translatedKeyForSpecialLocation,
} from "constants/courseConstants";

const { TabPane } = Tabs;
const { Title } = Typography;
const { confirm } = Modal;

interface ICustomerData {
  id?: string | number;
  kidName?: string;
  Suburb?: string;
  Phone?: string;
  Email?: string;
  ChildName?: any[];
  RefundDate?: number[] | null;
}

interface IProps {
  id: number;
  onBack?: () => void;
}

export const ViewCustomerCourse = observer((props: IProps) => {
  const { id, onBack } = props;
  const history = useHistory();
  const { t } = useTranslation("base");
  const user = useCurrentUser();
  const isBlocked = userBlocked();
  const userType = useType();
  const language = useContext(languageStoreContext);

  const mapCustomerChildrensData = (data: ICustomerChildData[]) => {
    return data.map((el) => tableCourseCustomerChildrensData(el));
  };
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isCustomerCancel, setCustomerCancel] = useState<boolean>(false);
  const [checked, setChecked] = useState<number[]>([]);
  const [chooseCustomer, setChooseCustomer] = useState<any[]>([]);
  const [isLoadingCancel, setLoadingCancel] = useState<boolean>(false);
  const [bookingReschedulingOutcomeText, setBookingReschedulingOutcomeText] =
    useState<string>();
  const [tabKey, setTabKey] = useState<any>("1");
  const dataCourse = toJS(courseStore?.courseCreateDataVal);
  const customers = toJS(customersStore?.customersData);
  const customerFilterModel = toJS(customersStore?.filterModelCourseCustomer);

  const getCustomers = (filter: any) => {
    customersStore?.getProviderCourseCustomers(id, {
      ...filter,
    });
  };

  React.useEffect(() => {
    if (id) {
      courseStore?.getDetailCourse(id).then(() => setLoading(false));
      getCustomers({
        ...customerFilterModel,
      });
    }
  }, [id]);

  const mapCustomersData = (data: ICustomerData[]) => {
    return data?.map((el) => tableCourseCustomersData(el, dataCourse?.classes));
  };

  const cancelCourseForKidButton = (
    data: ICustomerChildData[],
    bookingReschedulingOutcome: any,
  ) => {
    setBookingReschedulingOutcomeText(
      bookingReschedulingOutcome?.courseCancelledByKidzbiz,
    );
    setChooseCustomer(data);
    if (data?.length === 1) {
      setChecked([data?.[0]?.id]);
    }
    setTimeout(() => {
      setCustomerCancel((prev) => !prev);
    }, 400);
  };

  const detailCustomerCourseForKidButton = (dataid: string | number) => {
    history.push(AppRoutes.customerId(dataid));
  };

  const onChecked = (child: number) => {
    let newChecked = [...checked];
    const index = checked?.findIndex((el) => el === child);
    if (index >= 0) {
      // @ts-ignore
      newChecked.remove(child);
    } else {
      newChecked = [...newChecked, child];
    }
    setChecked(newChecked);
  };

  const onCancel = (type: "DELETE" | "CANCEL") => {
    courseStore
      ?.cancelCourse(Number(id), [])
      .then((res) => {
        if (type === "DELETE") {
          notification.success({
            message: t("courses.delete_confirm_success"),
          });
        } else {
          if (res?.containsClassIn3h) {
            notification.success({
              message: t(
                "server_errors.com.bizibuz.core.service.course.ClassCancellationException",
              ),
            });
          } else {
            notification.success({
              message: t("courses.cancel_confirm_success"),
            });
          }
        }
        // onBack?.();
      })
      .catch((error) => {
        helpers.throwErrors(
          t,
          ["com.bizibuz.core.service.course.ClassCancellationException"],
          error?.data?.error,
          error?.data?.message,
        );
      });
  };

  const showConfirmForDelete = (name?: string) => {
    confirm({
      title: t("courses.delete_confirm_text"),
      content: "",
      autoFocusButton: null,
      icon: "",
      okText: t("bizBase.ok"),
      cancelText: t("bizBase.abort"),
      onOk() {
        onCancel("DELETE");
      },
    });
  };
  const reschedulingOutcomesText = t(
    "signUp.step4." + bookingReschedulingOutcomeText,
  )?.toLocaleLowerCase();

  const showConfirmForCancelDetail = (name?: string) => {
    confirm({
      title: t("courses.cancel_confirm_text"),
      content: (
        <span
          style={{
            fontSize: 15,
            fontWeight: 700,
            marginBottom: 8,
            color: "#222222",
          }}
        >
          {t("courses.cancellation_description")}
        </span>
      ),
      autoFocusButton: null,
      icon: "",
      okText: t("bizBase.ok"),
      cancelText: t("bizBase.abort"),
      onOk() {
        onCancel("CANCEL");
      },
    });
  };

  const onPageChangeHandler = (page?: any) => {
    getCustomers({
      ...customerFilterModel,
      pageNumber: page - 1,
    });
  };

  const showConfirmForCancelClass = (classes?: any) => {
    confirm({
      title: t("rescheduingOutcomes.class_cancellation_title"),
      content: (
        <span
          style={{
            fontSize: 15,
            fontWeight: 700,
            marginBottom: 8,
            color: "#222222",
          }}
        >
          {t("rescheduingOutcomes.class_cancellation_message")}
        </span>
      ),
      autoFocusButton: null,
      icon: "",
      okText: t("bizBase.ok"),
      cancelText: t("bizBase.abort"),
      onOk() {
        courseStore
          ?.cancelClass(id, classes?.id, language?.lang?.toLocaleLowerCase())
          .then(() =>
            notification.success({
              message: t("rescheduingOutcomes.class_cancelled_success"),
            }),
          )
          .catch((err: any) => {
            setLoadingCancel(false);
            helpers.throwErrors(t, [], err?.data?.error, "");
          });
      },
    });
  };

  const exportCustomerFile = () => {
    const dataCSV = customers?.data?.map((el: any) => {
      const params = {};
      el?.children?.forEach((c: any, index: number) => {
        const i = index + 1;
        Object.assign(params, {
          [t("exports.child_name_first", {
            i,
          })]: c?.firstName,
          [t("exports.child_name_last", {
            i,
          })]: c?.lastName,
          [t("exports.child_name_dob", {
            i,
          })]: helpers.formatDate(helpers?.getServerDate(c?.dateOfBirth)),
          [t("exports.child_name_medical", {
            i,
          })]: c?.medicalIssues,
        });
      });

      return {
        [t("exports.customer_first_name")]: el?.firstName,
        [t("exports.customer_last_name")]: el?.lastName,
        [t("exports.mobile_phone_number")]: el?.mobile,
        [t("exports.email")]: el?.email,
        // Suburb: el?.suburb,
        [t("exports.location")]: el?.location,
        [t("exports.language_preference")]:
          el?.preferredLanguage === PreferredLanguage.English
            ? "English"
            : "Chinese",
        ...params,
      };
    });
    helpers.exportCourseCustomers(
      {
        name: dataCourse?.name,
        code: dataCourse?.code,
        status: t("courses.status." + dataCourse?.status),
        labelName: t("courses.courseName"),
        labelCode: t("courses.courseCode"),
        labelStatus: t("courses.courseStatus"),
      },
      dataCSV,
      "Course_Customers_Export",
    );
  };

  //Change tab
  const onTabClick = (key: any) => {
    setTabKey(key);
    if (id) {
      courseStore?.getDetailCourse(id).then(() => setLoading(false));
      if (key === "2") {
        getCustomers({
          ...customerFilterModel,
        });
      }
    }
  };

  return (
    <>
      {isLoading && (
        <div className="flex-center antd-spiner">
          <Spin size="large" />
        </div>
      )}
      <div className={"back-block  bsl-4"}>
        <ButtonLink onClick={() => onBack?.()}>
          <i
            style={{ fontSize: "20px", paddingRight: "8px" }}
            className={bizIcons.ARROWLEFT}
          />
          {t("courses.backToCourses")}
        </ButtonLink>
      </div>
      <div className="biz-tabs-title bsl-4">
        <div className="avatar">
          <div
            className="avatar-img"
            style={{
              backgroundImage: `url("${
                dataCourse?.image_url || dataCourse?.imageUrl
              }")`,
            }}
          />
        </div>
        <div>
          <h1 style={{ marginBottom: "8px" }}>{dataCourse?.name || ""}</h1>

          <div className={"flex"} style={{ gap: "16px" }}>
            <div className={"flex"}>
              <i key={Math.random()} className={`${tb.CODE} fs-16 mr-8`} />
              <Title className={"flex-center text-color-secondary"} level={4}>
                {dataCourse?.code || "-"}
              </Title>
            </div>
            <div className={"flex-align-items"}>
              <i key={Math.random()} className={`${tb.INFO} fs-16 mr-8`} />
              <Title className={"flex-center text-color-secondary"} level={4}>
                {/* {t("courses.noStatus")} */}

                {t(
                  "courses.status." +
                    (dataCourse?.status || CourseStatus.Draft),
                )}
              </Title>
            </div>
          </div>
        </div>
      </div>
      <div className="biz-tabs-content">
        <div>
          <Tabs
            activeKey={tabKey}
            size={"small"}
            onTabClick={onTabClick}
            destroyInactiveTabPane={true}
          >
            <TabPane tab={t("courses.details")} key="1">
              {user?.providerAttributes?.id === dataCourse?.providerId && (
                <div className="course-detail-actions flex">
                  {!isBlocked &&
                    [CourseStatus.Draft]?.includes(dataCourse?.status) &&
                    (dataCourse?.hasError ? (
                      <Tooltip
                        placement="topLeft"
                        title={t("courses.bulk_note")}
                      >
                        <ButtonLink
                          className={`mt-8 bsl-4 mr-25 text-red`}
                          onClick={() => {
                            history.push({
                              pathname: AppRoutes.courseEdit(id),
                              state: { type: "edit" },
                            });
                          }}
                        >
                          <i
                            className={bizIcons.EDIT}
                            style={{ fontSize: "20px", marginRight: 6 }}
                          />
                          {t("bizBase.edit")}
                        </ButtonLink>
                      </Tooltip>
                    ) : (
                      <ButtonLink
                        className={`mt-8 bsl-4 mr-25`}
                        onClick={() => {
                          history.push({
                            pathname: AppRoutes.courseEdit(id),
                            state: { type: "edit" },
                          });
                        }}
                      >
                        <i
                          className={bizIcons.EDIT}
                          style={{ fontSize: "20px", marginRight: 6 }}
                        />
                        {t("bizBase.edit")}
                      </ButtonLink>
                    ))}

                  {!isBlocked &&
                    ![
                      CourseStatus.Draft,
                      CourseStatus.Completed,
                      CourseStatus.Blocked,
                      CourseStatus.Canceled,
                    ]?.includes(dataCourse?.status) && (
                      <ButtonLink
                        className={`mt-8 bsl-4 mr-25`}
                        onClick={() => {
                          history.push({
                            pathname: AppRoutes.courseEdit(id),
                            state: { type: "edit" },
                          });
                        }}
                      >
                        <i
                          className={bizIcons.EDIT}
                          style={{ fontSize: "20px", marginRight: 6 }}
                        />
                        {t("bizBase.edit")}
                      </ButtonLink>
                    )}

                  {!isBlocked &&
                    [CourseStatus.Draft, CourseStatus.Pending]?.includes(
                      dataCourse?.status,
                    ) && (
                      <div className={"course-detail-action mt-8 bsl-4 mr-25"}>
                        <ButtonLink onClick={() => showConfirmForDelete()}>
                          <i
                            className={bizIcons.DELETE}
                            style={{ fontSize: "20px", marginRight: 6 }}
                          />
                          {t("bizBase.delete")}
                        </ButtonLink>
                      </div>
                    )}
                  {!isBlocked &&
                    [CourseStatus.InProgress, CourseStatus.Blocked]?.includes(
                      dataCourse?.status,
                    ) && (
                      <div className="mt-8 bsl-4 course-detail-action">
                        <ButtonLink
                          onClick={() => showConfirmForCancelDetail()}
                        >
                          <i
                            className={bizIcons.LOGOUT}
                            style={{ fontSize: "20px", marginRight: 6 }}
                          />
                          {t("bizBase.cancel")}
                        </ButtonLink>
                      </div>
                    )}
                </div>
              )}

              {dataCourse?.validationMs?.length > 0 ? (
                <p className="text-red">
                  {dataCourse?.validationMs?.join(", ")}
                </p>
              ) : null}

              {[CourseStatus.Canceled]?.includes(dataCourse?.status) && (
                <>
                  {dataCourse?.cancelBy === CancelType.ADMIN && (
                    <p className="text-red" style={{ marginBottom: 3 }}>
                      {t("courses.admin_course_cancellation") +
                        " - " +
                        helpers.formatDate(
                          helpers.getServerDate(dataCourse?.cancelDate),
                        )}
                    </p>
                  )}

                  {dataCourse?.cancelBy === CancelType.KIDZBIZ && (
                    <p className="text-red" style={{ marginBottom: 3 }}>
                      {t("courses.kidzbiz_course_cancellation") +
                        " - " +
                        helpers.formatDate(
                          helpers.getServerDate(dataCourse?.cancelDate),
                        )}
                    </p>
                  )}
                </>
              )}

              <TableComponent
                broken={true}
                grid={true}
                noSeparators={true}
                pagination={false}
                // TODO: ADD CREATED BY ME SIGN(GIVE ARG TO tableCourseDetailsTitleColumn)
                propsTableColumns={tableCourseDetailsTitleColumn(
                  false,
                  user?.isLinked,
                )}
                propsTableData={tableCourseDetailsTitleData(t, dataCourse)}
              />
              <Divider style={{ marginTop: "0px", marginBottom: "0px" }} />
              <TableComponent
                broken={true}
                grid={true}
                noSeparators={true}
                pagination={false}
                propsTableColumns={tableCourseDetailsPriceColumns()}
                propsTableData={tableCourseDetailsPriceData(dataCourse)}
              />
              <div className="tsl-3" />
              <div className="tsl-5" />
              <div className={"semi-bold "}> {t("courses.location")}</div>
              <Divider />

              {isSpecialLocation(dataCourse) && (
                <div className={"flex-align-items"}>
                  <i
                    className={`${bizIcons.BUILDING}`}
                    style={{ fontSize: "16px", marginRight: "8px" }}
                  />{" "}
                  <span className={"text-color-secondary"}>
                    {translatedKeyForSpecialLocation(dataCourse)
                      ? t(translatedKeyForSpecialLocation(dataCourse))
                      : ""}
                  </span>
                </div>
              )}
              <div className="course-location">
                {dataCourse?.locations?.[0]?.address?.id > 0 && (
                  <TableComponent
                    broken={true}
                    pagination={false}
                    propsTableColumns={tableCourseLocationColumns()}
                    propsTableData={[...dataCourse?.locations] || []}
                  />
                )}
              </div>

              <ViewMoreBlock
                title={t("courses.courseDescription")}
                style={{ marginBottom: "32px", marginTop: "32px" }}
              >
                <span className={"text-color-secondary"}>
                  {dataCourse?.description || t("courses.noDescription")}
                </span>
              </ViewMoreBlock>
            </TabPane>
            {![CourseStatus.Draft]?.includes(dataCourse?.status) && (
              <TabPane tab={t("courses.customers")} key="2">
                {userType === UserType.MASTER && customers?.data?.length > 0 && (
                  <ButtonLink
                    onClick={() => exportCustomerFile()}
                    className="bsl-4"
                  >
                    <i
                      className={bizIcons.FILEDOWNLOAD}
                      style={{ fontSize: "20px", marginRight: 8 }}
                    />
                    {t("bookings.exportThisList")}
                  </ButtonLink>
                )}
                <TableComponent
                  broken={true}
                  pagination={
                    customers?.totalCount > PAGE_SIZE
                      ? {
                          total: customers?.totalCount,
                          pageSize: customers?.pageSize,
                          page: customers?.pageNumber + 1,
                          onChange: onPageChangeHandler,
                        }
                      : false
                  }
                  propsTableColumns={tableCourseCustomersColumns(
                    isBlocked,
                    t,
                    cancelCourseForKidButton,
                    detailCustomerCourseForKidButton,
                    user?.providerAttributes?.id === dataCourse?.providerId,
                    !isBlocked &&
                      [CourseStatus.InProgress, CourseStatus.Blocked]?.includes(
                        dataCourse?.status,
                      ),
                  )}
                  propsTableData={mapCustomersData(customers?.data || [])}
                />

                {false && (
                  <div
                    className={"flex-center tsl-4 bsl-4"}
                    style={{ padding: "32px 0", flexDirection: "column" }}
                  >
                    <div style={{ marginBottom: "4px" }}>
                      {t("courses.empty.no_customer")}
                    </div>
                    {/*<div className={"bsl-4"}>*/}
                    {/*  <Link to={"/promotion/create"}>*/}
                    {/*    Create a course promotion?*/}
                    {/*  </Link>*/}
                    {/*</div>*/}
                    <div>
                      <img src={noData} alt="no data image" />
                    </div>
                  </div>
                )}
              </TabPane>
            )}
            <TabPane tab={t("courses.schedule")} key="3">
              {dataCourse?.validationMs?.length > 0 ? (
                <p className="text-red">
                  {dataCourse?.validationMs?.join(", ")}
                </p>
              ) : null}
              <TableComponent
                broken={true}
                grid={true}
                noSeparators={true}
                pagination={false}
                propsTableColumns={tableCourseScheduleColumns(t)}
                propsTableData={tableCourseScheduleData({
                  ...dataCourse?.schedule,
                  NumberOfClasses:
                    dataCourse?.numberOfClasses || dataCourse?.classes?.length,
                  startDate: dataCourse?.startDate
                    ? helpers.formatDate(
                        helpers.getServerDate(dataCourse?.startDate),
                      )
                    : undefined,
                  endDate: dataCourse?.endDate
                    ? helpers.formatDate(
                        helpers.getServerDate(dataCourse?.endDate),
                      )
                    : undefined,
                })}
              />
              <Divider />
              <div className="td-title flex align-center bsl-4">
                <i
                  className={tb.CALENDARTODO}
                  style={{ fontSize: "16px", marginRight: "8px" }}
                />
                {t("courses.classDates")}
              </div>
              <div className="schedule-wrapper">
                {dataCourse?.classes?.length &&
                  dataCourse?.classes.map((el: any, index: number) => {
                    const elmDate = moment(
                      helpers.getServerDate(el?.date) + " " + el?.startTime,
                      FORMAT_DATE.queryFull,
                    ).format();

                    const currentDate = moment();
                    let minutes = Math.abs(
                      currentDate.diff(elmDate, "minutes"),
                    );

                    if (moment(elmDate)?.isBefore(currentDate)) {
                      minutes = 0;
                    }
                    return (
                      <div className={"dashed-block"} key={Math.random()}>
                        <div
                          className={"text-color-secondary"}
                          style={{ textTransform: "capitalize" }}
                        >
                          {index + 1}.{" "}
                          {el?.dayOfWeek?.toLowerCase() + " - " + el?.date
                            ? helpers.formatDate(
                                helpers.getServerDate(el?.date),
                              )
                            : "-"}
                        </div>
                        <div
                          className={
                            "dashed-block-divider text-color-secondary"
                          }
                        />
                        <div className={"dashed-block-value"}>
                          {`${
                            (el?.startTime?.slice(0, 5) || "...") +
                            " - " +
                            (el?.endTime?.slice(0, 5) || "...")
                          }`}{" "}
                        </div>

                        {!isBlocked &&
                          [
                            CourseStatus.InProgress,
                            CourseStatus.Blocked,
                          ]?.includes(dataCourse?.status) && (
                            <>
                              {!!el?.cancelDate ? (
                                <span
                                  className="text-color-secondary"
                                  style={{ marginLeft: 13 }}
                                >
                                  {t("courses.status.CANCELLED")}
                                </span>
                              ) : (
                                <ButtonLink
                                  onClick={() => showConfirmForCancelClass(el)}
                                  style={{ marginLeft: 32 }}
                                  disabled={minutes > 0 ? false : true}
                                >
                                  {t("bizBase.cancel")}
                                </ButtonLink>
                              )}
                            </>
                          )}
                      </div>
                    );
                  })}
              </div>
              {courseStore?.courseCreateDataVal?.schedule?.days?.length && (
                <Divider />
              )}
            </TabPane>
            {![CourseStatus.Draft]?.includes(dataCourse?.status) && (
              <TabPane tab={t("courses.promotions")} key="4">
                {!!dataCourse?.promotions?.length && (
                  <TableComponent
                    broken={true}
                    pagination={false}
                    propsTableColumns={tableCoursesPromColumns(isBlocked, t)}
                    propsTableData={tableCoursesPromotionsData(
                      t,
                      user?.providerAttributes?.legalName,
                      dataCourse?.promotions,
                    )}
                  />
                )}

                {!dataCourse?.promotions?.length && (
                  <div
                    className={"flex-center tsl-4 bsl-4"}
                    style={{ padding: "32px 0", flexDirection: "column" }}
                  >
                    <div style={{ marginBottom: "4px" }}>
                      {t("courses.empty.no_promotion", {
                        defaultValue: "There are no promotions for this course",
                      })}
                    </div>
                    {!isBlocked && (
                      <div className={"bsl-4"}>
                        <Link
                          target=""
                          to={AppRoutes.promotionsAdd + "/course"}
                        >
                          {t("courses.createACoursePromotion", {
                            defaultValue: "Create a course promotion?",
                          })}
                        </Link>
                      </div>
                    )}

                    <div>
                      <img src={noData} alt="no data image" />
                    </div>
                  </div>
                )}
              </TabPane>
            )}
          </Tabs>
        </div>
      </div>

      <Modal
        centered
        title=""
        visible={isCustomerCancel}
        closable={false}
        onCancel={() => {
          setCustomerCancel((prev) => !prev);
          setChecked([]);
        }}
        footer={false}
        wrapClassName="courses-modal-customer"
      >
        <div className="px-24 confirm-message">
          <h3>{t("courses.cancel_customer_confirm_text")}</h3>
        </div>
        <p
          className="px-24 text-center"
          style={{
            fontSize: 15,
            fontWeight: 700,
            marginBottom: 8,
            color: "#000",
          }}
        >
          {t("courses.cancellation_fees_may_be_incurred", {
            type: reschedulingOutcomesText,
          })}
        </p>

        {chooseCustomer?.length > 1 ? (
          <h5 className="px-24 confirm-message-title">
            {t("courses.chooseChild")}
          </h5>
        ) : (
          ""
        )}

        <div className="px-24 bsl-5">
          <TableComponent
            broken={true}
            pagination={false}
            propsTableColumns={tableCourseCustomerChildrensColumns(
              true,
              checked,
              onChecked,
              chooseCustomer?.length > 1 ? false : true,
            )}
            propsTableData={mapCustomerChildrensData(
              uniqBy(chooseCustomer, "id") || [],
            )}
          />
        </div>

        <div className="px-24 courses-cancel-button">
          <div className="flex-space-between">
            <ButtonWhite
              className="fs-16 tsl-4 flex-1 mr-25"
              onClick={() => {
                setCustomerCancel((prev) => !prev);
                setChecked([]);
              }}
              style={{
                width: "auto",
                minWidth: "auto",
                paddingLeft: 20,
                paddingRight: 20,
              }}
              type="primary"
            >
              <div className={"flex-center"}>{t("bizBase.abort")}</div>
            </ButtonWhite>
            <Button
              className="fs-16 tsl-4 flex-1"
              type="primary"
              style={{ width: "auto", paddingLeft: 20, paddingRight: 20 }}
              htmlType="button"
              onClick={() => {
                setLoadingCancel(true);
                courseStore
                  ?.cancelCourse(Number(id), checked)
                  .then((res) => {
                    if (res?.containsClassIn3h) {
                      notification.success({
                        message: t(
                          "server_errors.com.bizibuz.core.service.course.ClassCancellationException",
                        ),
                      });
                    } else {
                      notification.success({
                        message: t("courses.cancellationSuccessful"),
                      });
                    }

                    getCustomers({
                      ...customerFilterModel,
                    });

                    setTimeout(() => {
                      setCustomerCancel((prev) => !prev);
                      setChecked([]);
                      setLoadingCancel(false);
                    }, 400);
                  })
                  .catch((err: any) => {
                    setLoadingCancel(false);
                    helpers.throwErrors(
                      t,
                      [
                        "com.bizibuz.core.service.course.ClassCancellationException",
                      ],
                      err?.data?.error,
                      "",
                    );
                  });
              }}
              icon={
                <div className={"flex-center"}>
                  <>{t("bizBase.yes")}</>
                </div>
              }
              loading={isLoadingCancel}
              disabled={checked?.length > 0 ? false : true}
            />
          </div>
        </div>
      </Modal>
    </>
  );
});
