import React, { useEffect, useState } from "react";
import {
  CHECKBLOCKTYPES,
  countOfValuesEnum,
  IChkBlockProps,
  IControlProps,
} from "components/CheckBoxes/ChkBlock";
import { Filters } from "../../../../components/Filters";
import { observer } from "mobx-react";
import ButtonLink from "../../../../components/ButtonLink";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { filterModels } from "./filterModels";
import providerStore from "../../../../scripts/mobx/providerStore";
import { TFunction, useTranslation } from "react-i18next";
import providerOrdersStore from "scripts/mobx/providerOrdersStore";
import { toJS } from "mobx";

const getTableFiltersData: (
  userType: boolean,
  t: TFunction<"translation">,
) => IChkBlockProps = (userType, t) => {
  const params = {
    type: {
      title: t("payments.type"),
      countOfValues: countOfValuesEnum.Array,
      controlsList: [
        {
          value: "",
          active: true,
          default: true,
          placeholder: "bizBase.all",
        },
        ...filterModels.type?.map((el: any) => {
          return {
            value: el.value,
            placeholder: "signUp.step4." + el.value,
            active: false,
            default: false,
          };
        }),
      ],
    },
    dateFromTo: {
      title: t("bookings.dateFromTo"),
      combined: true,
      controlsList: [
        {
          value: "",
          active: true,
          default: true,
          placeholder: "bizBase.all",
        },
        {
          value: "",
          markId: "dateFrom",
          type: CHECKBLOCKTYPES.DATE,
          dependsOn: {
            field: {
              name: "dateFromTo",
              id: 2,
            },
            condition: (data: any) => {
              const { value, current } = data;
              return value && current && current > value?.startOf("day");
            },
          },
          placeholder: "bookings.dateFrom",
        },
        {
          value: "",
          markId: "dateTo",
          type: CHECKBLOCKTYPES.DATE,
          placeholder: "bookings.dateTo",
          dependsOn: {
            field: {
              name: "dateFromTo",
              id: 1,
            },
            condition: (data: any) => {
              const { value, current } = data;
              return value && current && current < value?.startOf("day");
            },
          },
        },
      ],
    },
  };
  if (userType) {
    Object.assign(params, {
      satelliteIds: {
        title: t("menu.kidzbiz"),
        countOfValues: countOfValuesEnum.Array,
        controlsList: [
          {
            value: "",
            active: true,
            default: true,
            placeholder: "bizBase.all",
          },
        ],
      },
    });
  }
  return params;
};

interface ICoursesFiltersProps extends RouteComponentProps {
  onApply: (data: any) => void;
  userType: boolean;
}
export const PayCustomersFilters = withRouter(
  observer((props: ICoursesFiltersProps): React.ReactElement => {
    const { onApply, userType } = props;
    const { t } = useTranslation();

    const [tableFiltersData, setTableFiltersData] = useState<IChkBlockProps>(
      toJS(providerOrdersStore?.dataFilters),
    );

    useEffect(() => {
      const tableFiltersData = getTableFiltersData(userType, t);
      (async () => {
        const satellitesList = await providerStore?.getProviders();
        if (satellitesList?.length) {
          const _slist = satellitesList.map((el: any) => {
            return {
              value: el.id,
              placeholder: el.legalName,
              active: false,
              default: false,
            };
          });
          _slist.forEach((el: any) => {
            tableFiltersData["satelliteIds"]?.controlsList?.push(el);
          });
          await setTableFiltersData(tableFiltersData);
        }
      })();
    }, []);

    const demapFilterData = (data: any): any => {
      const _data = {};

      Object.keys(data).forEach((key) => {
        if (!data[key].combined) {
          _data[key] = data[key].controlsList?.map(
            (el: IControlProps) => el.value,
          );
        } else {
          data[key].controlsList?.forEach((el: IControlProps) => {
            if (el.markId) {
              _data[el.markId] = el.value;
            }
          });
        }
      });

      return _data;
    };

    const onApplyFilters = (data: any) => {
      const _data = { ...demapFilterData(data) };
      onApply(_data);
    };

    const onSetFilter = (data: any) => {
      setTableFiltersData({ ...tableFiltersData, ...data });
      providerOrdersStore?.setFilters({ ...tableFiltersData, ...data });
    };

    return tableFiltersData ? (
      <Filters
        onApply={onApplyFilters}
        filterBlocks={tableFiltersData}
        onSetFilter={onSetFilter}
        onReset={() => providerOrdersStore?.setFilters()}
        filterModel={toJS(providerOrdersStore?.dataFilters)}
      />
    ) : (
      <div>
        <ButtonLink disabled={true}>
          {t("bizBase.filters", {
            defaultValue: "Filters",
          })}
          <i
            className={"ri-arrow-drop-down-line"}
            style={{ fontSize: "20px" }}
          />
        </ButtonLink>
      </div>
    );
  }),
);
