import React from "react";
import moment from "moment";
import ButtonLink from "../../components/ButtonLink";
import { bizIcons, bizIcons as tb } from "../../constants/bizConstants";
import { Link } from "react-router-dom";
import helpers from "../../scripts/helpers";
import { AppRoutes } from "helpers/app.routes";
import { PromotionType } from "./data";
import { UserType } from "scripts/mobx/userStore";
import { formatPrice } from "scripts/utils/formatPrice";

export const tablePromotionsColumns = (
  t: any,
  ActionButton: (type: string, data: any) => void,
) => {
  const colData = [
    {
      dataIndex: "name",
      key: "name",
      render: (text: any, record: any) =>
        text && (
          <div className="flex-space-between">
            {text}
            {ActionButton("bulk", record)}
          </div>
        ),
    },
    {
      key: "type",
      dataIndex: "type",
      render: (text: any, record: any) =>
        record.type && (
          <div>
            <i
              className={tb.GIFT2}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            {t("promo." + record.type)}
          </div>
        ),
    },
    {
      key: "dateTodo",
      dataIndex: "dateTodo",
      render: (text: any, record: any) => {
        const { effectiveFrom, effectiveTo } = record;
        const dateFrom = helpers.formatDate(
          helpers.getServerDate(effectiveFrom),
        );
        const dateTo = helpers.formatDate(helpers.getServerDate(effectiveTo));

        return (
          <div>
            <i
              className={tb.CALENDARTODO}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            {dateFrom}
            {" - "}
            {dateTo}
          </div>
        );
      },
    },
    {
      key: "coursesCount",
      dataIndex: "coursesCount",
      render: (text: any, record: any) => {
        const label =
          record?.coursesCount === 1 ? t("promo.course") : t("menu.courses");
        return record.type === PromotionType.MinCourse ? (
          <div>
            <i
              className={tb.BOOKOPEN}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            {(record?.threshold || 0) + " " + label}
          </div>
        ) : (
          <div>
            <i
              className={tb.PRICE}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            {formatPrice(Number(record?.threshold || 0), {
              currency: record?.currency ?? "HKD",
            })}
          </div>
        );
      },
    },
    {
      key: "discount",
      dataIndex: "discount",
      render: (text: any, record: any) => {
        return (
          <div>
            <i
              className={tb.CARD2}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            {record?.discountPercentage}%
          </div>
        );
      },
    },
  ];

  return colData;
};

export const tablePromotionsCourseColumns = (
  t: any,
  isLinked: boolean,
  ActionButton: (type: string, data: any) => void,
) => {
  const colData = [
    {
      dataIndex: "name",
      key: "name",
      render: (text: any, record: any) =>
        text && (
          <div className="flex-space-between">
            {text}
            {ActionButton("course", record)}
          </div>
        ),
    },

    {
      key: "type",
      dataIndex: "type",
      render: (text: any, record: any) =>
        record.type && (
          <div>
            <i
              className={tb.GIFT2}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            {record.type === PromotionType.Discount ? (
              <>{record?.discountPercentageOrValue}%</>
            ) : (
              t("promo." + record.type)
            )}
          </div>
        ),
    },
    {
      key: "dateTodo",
      dataIndex: "dateTodo",
      render: (text: any, record: any) => {
        const { effectiveFrom, effectiveTo } = record;
        const dateFrom = helpers.formatDate(
          helpers.getServerDate(effectiveFrom),
        );
        const dateTo = helpers.formatDate(helpers.getServerDate(effectiveTo));

        return (
          <div>
            <i
              className={tb.CALENDARTODO}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            {dateFrom}
            {" - "}
            {dateTo}
          </div>
        );
      },
    },
    {
      key: "coursesCount",
      dataIndex: "coursesCount",
      render: (text: any, record: any) => {
        const label =
          record?.courseCount === 1 ? t("promo.course") : t("menu.courses");
        return (
          <div>
            <i
              className={tb.BOOKOPEN}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            {(record?.courseCount || 0) + " " + label}
          </div>
        );
      },
    },
  ];

  if (isLinked) {
    colData.splice(1, 0, {
      key: "kidzbiz",
      dataIndex: "kidzbiz",
      render: (text: any, record: any) => {
        return (
          !!record?.courseIdCodeNameList?.[0]?.providerLegalName && (
            <div>
              <i
                className={tb.BUILDING}
                style={{ fontSize: "16px", marginRight: "8px" }}
              />
              {record?.courseIdCodeNameList?.[0]?.providerLegalName}
            </div>
          )
        );
      },
    });
  }

  return colData;
};
