import React, { useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Divider, Typography } from "antd";
import ButtonLink from "../../../components/ButtonLink";
import { bizIcons } from "../../../constants/bizConstants";
import { useQueryParams } from "scripts/custom-hooks/useQueryParams";
import { useTranslation } from "react-i18next";
const { Title } = Typography;

interface IProps
  extends RouteComponentProps,
    React.HTMLAttributes<HTMLElement> {
  title: string;
  id?: string;
}

export const ViewBlock = (props: IProps) => {
  const { history, title, id } = props;
  const { t } = useTranslation();
  const params = useQueryParams();
  const tabId = params.get("id");

  const [isOpen, setIsOpen] = useState(false);

  const changeOpenHandler = async () => {
    await setIsOpen(!isOpen);
  };

  React.useEffect(() => {
    if (tabId && tabId === id) {
      setIsOpen(!isOpen);
    }
  }, []);

  return (
    <div id={id} className={"faq-text-block"} style={{ marginTop: "32px" }}>
      <div className={"flex-space-between"} style={{ marginBottom: "32px" }}>
        <Title level={3}>{title}</Title>{" "}
        <ButtonLink onClick={changeOpenHandler} style={{ marginLeft: "16px" }}>
          {t("bizBase.view")}
          <i
            className={isOpen ? bizIcons.ARROWUP : bizIcons.ARROWDOWN}
            style={{ fontSize: "16px" }}
          />
        </ButtonLink>
      </div>
      <div style={{ display: isOpen ? "block" : "none" }}>{props.children}</div>
      <Divider />
    </div>
  );
};

export default withRouter(ViewBlock);
