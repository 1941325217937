import { SwapRightOutlined } from "@ant-design/icons";
import { Form, Input, Popover } from "antd";
import { bizIcons } from "constants/bizConstants";
import { DayOfWeek } from "constants/type.interface";
import { observer } from "mobx-react";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { checkTime, formatTime, getSelectionTime } from "./data-time";
import "./index.less";

interface IProps {
  form: any;
  name: DayOfWeek;
  onChange?: (start?: string, end?: string) => void;
  disabled?: boolean;
  value?: {
    startTime: string;
    endTime: string;
  };
}

export const REGEX = new RegExp(/^[0-9]+$/g);

export const TimePickerComponent = observer((props: IProps) => {
  const { form, name, onChange, disabled, value } = props;
  const { t } = useTranslation("base");
  const ref1 = React.useRef(null);
  const ref2 = React.useRef(null);
  const popoverRef = React.useRef(null);
  const [startTime, setStartTime] = React.useState<string>(
    value?.startTime || "",
  );
  const [endTime, setEndTime] = React.useState<string>(value?.endTime || "");
  const [error, serError] = React.useState<string>();
  const [visible, setVisible] = React.useState<boolean>(false);
  const times = getSelectionTime();

  const scrollLeft = (index: number) => {
    const left = document.getElementById(name + "left");
    if (index > 0) {
      const leftItem = document.getElementById(
        name + "block-left-item" + index,
      );
      if (leftItem?.offsetTop) {
        left?.scrollTo(0, leftItem?.offsetTop);
      }
    } else {
      left?.scrollTo(0, 0);
    }
  };

  const scrollRight = (index: number) => {
    const right = document.getElementById(name + "right");
    if (index > 0) {
      const rightItem = document.getElementById(
        name + "block-right-item" + index,
      );
      if (rightItem?.offsetTop) {
        right?.scrollTo(0, rightItem?.offsetTop);
      }
    } else {
      right?.scrollTo(0, 0);
    }
  };

  const content = (
    <div className="flex-align-items course-dropdown-items">
      <div className="course-dropdown-item" id={name + "left"}>
        {times?.map((el, i) => {
          if (i === times?.length - 1 || i === times?.length - 2) return false;
          return (
            <div
              id={name + "block-left-item" + i}
              className={`block-item-time ${startTime === el ? "active" : ""}`}
              key={el}
              onClick={() => {
                serError("");
                setStartTime(el);
                onChange?.(el, "");
                if (
                  !endTime ||
                  (endTime && checkTime(el, endTime)) ||
                  el === endTime
                ) {
                  setEndTime(times?.[i + 4]);
                  onChange?.(el, times?.[i + 4]);
                  scrollRight(i + 4);
                } else {
                  scrollRight(times.indexOf(endTime));
                }

                setTimeout(() => {
                  setVisible(false);
                }, 100);
              }}
            >
              {el}
            </div>
          );
        })}
      </div>
      <div className="course-dropdown-item right" id={name + "right"}>
        {times?.map(
          (el, i) =>
            i > 3 && (
              <div
                id={name + "block-right-item" + i}
                className={`block-item-time ${endTime === el ? "active" : ""}`}
                key={el}
                onClick={() => {
                  serError("");
                  setEndTime(el);
                  onChange?.(startTime, el);
                  if (
                    !startTime ||
                    (startTime && checkTime(startTime, el)) ||
                    el === startTime
                  ) {
                    setStartTime(times?.[i - 4]);
                    onChange?.(times?.[i - 4], el);
                    scrollLeft(i - 4);
                  } else {
                    scrollLeft(times.indexOf(startTime));
                  }
                  setTimeout(() => {
                    setVisible(false);
                  }, 100);
                }}
              >
                {el}
              </div>
            ),
        )}
      </div>
    </div>
  );
  const REGEX = new RegExp(
    /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/g,
  );
  return (
    <Form.Item
      name={name}
      className="course-time-item"
      rules={[
        {
          required: error ? true : false,
          message: t("messages.required_field", {
            defaultValue: "Please fill in all required fields",
          }),
        },
      ]}
    >
      <div
        className={`course-time-picker ${
          error === "has_error" ? "error" : ""
        } ${disabled ? "disabled" : ""}`}
      >
        <div className="flex-align-items flex-1">
          <div className="course-start-time">
            <Input
              ref={ref1}
              value={startTime || ""}
              onChange={(e) => {
                const newTime = e?.target?.value;
                serError("");
                setStartTime(newTime);
                if (newTime && !REGEX.test(newTime)) {
                  serError("has_error");
                } else {
                  onChange?.(newTime, endTime);
                  if (!endTime && newTime?.length) {
                    (ref2 as any)?.current?.focus();
                  }
                }
              }}
              onBlur={() => {
                if (!startTime && !endTime) {
                  serError("");
                  onChange?.("", "");
                } else if (!startTime || !endTime) {
                  serError("has_error");
                } else {
                  if (endTime && checkTime(startTime, endTime)) {
                    const newEndTime = moment(startTime, formatTime)
                      .add("minute", 60)
                      .format(formatTime);
                    setEndTime(newEndTime);
                    onChange?.(startTime, newEndTime);
                  }
                }
              }}
              maxLength={5}
              placeholder={t("courses.startTime")}
              disabled={disabled}
            />
          </div>
          <SwapRightOutlined style={{ color: "#9d9d9d", fontSize: 16 }} />
          <div className="course-end-time">
            <Input
              ref={ref2}
              value={endTime || ""}
              onChange={(e) => {
                const newTime = e?.target?.value;
                serError("");
                setEndTime(newTime);
                if (newTime && !REGEX.test(newTime)) {
                  serError("has_error");
                } else {
                  onChange?.(startTime, newTime);
                  if (!startTime && newTime?.length) {
                    (ref1 as any)?.current?.focus();
                  }
                }
              }}
              onBlur={() => {
                console.log(startTime, endTime);
                if (!startTime && !endTime) {
                  serError("");
                  onChange?.("", "");
                } else if (!startTime || !endTime) {
                  serError("has_error");
                } else {
                  if (
                    (startTime && checkTime(startTime, endTime)) ||
                    startTime === endTime
                  ) {
                    const newStartTime = moment(endTime, formatTime)
                      .add("minute", -60)
                      .format(formatTime);
                    setStartTime(newStartTime);
                    onChange?.(newStartTime, endTime);
                  }
                }
              }}
              maxLength={5}
              placeholder={t("courses.endTime")}
              disabled={disabled}
            />
          </div>
        </div>
        <Popover
          placement="bottomRight"
          title={false}
          content={content}
          trigger="click"
          visible={visible}
          onVisibleChange={(visible) => {
            if (!disabled) {
              setVisible(visible);
            }
          }}
        >
          <div className="course-time-icon">
            <i className={bizIcons.TIMELINE2} />
          </div>
        </Popover>
      </div>
    </Form.Item>
  );
});
