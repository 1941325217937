import React from "react";

export const tableCookiesColumns = () => {
  return [
    {
      title: "Cookies",
      dataIndex: "Cookies",
      key: "Cookies",
      width: "100px",
    },
    {
      title: "描述",
      dataIndex: "Description",
      key: "Description",
    },
  ];
};
export const tableCookiesData = () => {
  return [
    {
      Cookies: "功能cookies",
      Description:
        "這些cookies允許我們管理網站並確保其正常運行。它們對於幫助您瀏覽本網站並為您提供更好的網絡體驗至關重要，例如避免重複登錄網站。我們還使用這些cookie進行欺詐檢測和預防，並監控活動提供商是否遵守Kidzbiz協議的條款和條件。",
    },
    {
      Cookies: "分析 cookies",
      Description:
        "這些cookie允許我們測量和分析您如何使用我們的網站、應用和移動平台，以改善其功能和您的體驗。",
    },
    {
      Cookies: "廣告cookies",
      Description:
        "這些cookie被用來發送與您相關的廣告。它們還可以限制您看到廣告的次數，並幫助我們衡量營銷活動的有效性。我們也可能使用通過這些cookies獲得的信息，根據您以往的在線行為，為您提供您可能感興趣的廣告。我們可能會與其他各方分享這些信息，包括我們的合作夥伴。",
    },
    {
      Cookies: "社交媒體cookies",
      Description:
        "當您使用網站上的社交媒體分享按鈕分享信息時，就會用到這些cookie。社交網絡會記錄下您的行為。這些信息可能與目標/廣告活動相關聯。",
    },
  ];
};
