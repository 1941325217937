import { Typography } from "antd";
import { HeadSeo } from "components/HeadSeo";
import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { LayoutPage } from "../../components/Layout";
import "./index.less";
const { Title } = Typography;

const Tutorial = observer((props: RouteComponentProps) => {
  const { t } = useTranslation();
  return (
    <>
      <HeadSeo title={t("tutorial.title")} />
      <LayoutPage>
        <div className={"content-with-table"}>
          <Title level={1} style={{ marginBottom: "32px" }}>
            {t("tutorial.title")}
          </Title>
          <div>{t("tutorial.description")}</div>
          <div className="tutorial-video-container bsl-2">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/pjZw1yMrFXI"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen={true}
            />
          </div>
        </div>
      </LayoutPage>
    </>
  );
});

export default withRouter(Tutorial);
