import React from "react";
import "./index.less";

export const UpScrollArrow = () => (
  <div
    className={`up-scroll-arrow no-select`}
    onClick={() =>
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      })
    }
  >
    <i className="ri-arrow-up-s-line" />
  </div>
);
