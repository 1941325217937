import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Select,
} from "antd";
import { AutoCompleteSearchCourses } from "components/FormBlocks/AutoCompleteSearchCourses";
import { HeadSeo } from "components/HeadSeo";
import { FORMAT_DATE } from "constants/commons";
import { PromoBodyParams, PromotionTab } from "constants/type.interface";
import { AppRoutes } from "helpers/app.routes";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useCurrentUser } from "scripts/custom-hooks/useCurrentUser";
import helpers from "scripts/helpers";
import { PromotionsStore } from "scripts/mobx/promotionsStore";
import ButtonLink from "../../../components/ButtonLink";
import { LayoutPage } from "../../../components/Layout";
import { bizIcons } from "../../../constants/bizConstants";
import { PromotionType } from "../data";
import "./index.less";

interface IAddPromotionsProps extends RouteComponentProps {
  promotionsStore?: PromotionsStore;
}

const AddPromotionsCourse = inject("promotionsStore")(
  observer((props: IAddPromotionsProps) => {
    const { history, match, promotionsStore } = props;
    const { t } = useTranslation();
    const user = useCurrentUser();
    // @ts-ignore
    const id = props.match.params.id;

    const isView = match.path === "/promotions/view-course/:id?";

    const [isLoading, setIsLoading] = useState(false);
    const [startDate, setStartDate] = useState<any>();
    const [form] = Form.useForm();
    const [editData, setEditData] = useState<any>();

    useEffect(() => {
      if (id) {
        promotionsStore
          ?.getCoursePromotionById(Number(id))
          .then((data: any) => {
            setEditData(data);
            setPromType(data?.type);
          });
      }
    }, [id]);

    const handleErrors = (error: any) => {
      const key = error?.data?.error;
      const messageText = error?.data?.message;

      const errorType = [
        "COURSE_NOT_OWNED_BY_PROVIDER",
        "COURSE_HAS_ONLY_ONE_CLASS",
        "EFFECTIVE_TO_AFTER_SECOND_LAST_CLASS_DATE",
        "PROMOTION_PERIOD_OVERLAPPED",
        "com.bizibuz.core.service.common.InvalidInputException",
        "com.bizibuz.core.service.common.DateRangeOverlapException",
        "com.bizibuz.core.service.course.PromotionNoCourseAppliableException",
      ];

      if (
        key === "com.bizibuz.core.service.course.PromotionValidationException"
      ) {
        /**
         * try to parse message text to get the type of error
         * if could not parse, then show the error message
         * if could parse, then show the translated error message based on the type of error
         **/
        try {
          const parsedMessage = JSON.parse(messageText);

          if (errorType.includes(parsedMessage?.[0]?.errorType)) {
            message.error(t("server_errors." + parsedMessage?.[0]?.errorType));
          } else {
            message.error(t("server_errors." + "Bad Request"));
          }
        } catch (e) {
          message.error(messageText);
        }
      } else {
        helpers.throwErrors(t, errorType, key, messageText);
      }
    };

    const handleSubmit = async (values: any) => {
      const isAllCourse = !!values?.courses?.filter(
        (el: any) => el?.id === -1,
      )?.[0];
      const params: PromoBodyParams = {
        name: values?.name,
        type: values?.type,
        discountPercentageOrValue: parseFloat(
          Number(values?.discountPercentageOrValue || 0)?.toFixed(1),
        ),
        effectiveFrom: moment(values?.startDate).format(FORMAT_DATE.query),
        effectiveTo: moment(values?.endDate).format(FORMAT_DATE.query),
      };
      try {
        isAllCourse
          ? Object.assign(params, {
              applyToAllCourse: isAllCourse,
            })
          : Object.assign(params, {
              courseName: values?.courses?.map((el: any) => el?.id),
              applyToAllCourse: isAllCourse,
            });
        if (id) {
          await promotionsStore?.updateCoursePromo({
            id: editData?.id,
            ...params,
          });
        } else {
          await promotionsStore?.createCoursePromo(params);
        }

        message.success(id ? t("promo.updated") : t("promo.added"));
        setTimeout(
          () => history.push(AppRoutes.promotions + "?tab=COURSE"),
          200,
        );
      } catch (error: any) {
        console.log(error, JSON.stringify(error), 3);
        setIsLoading(false);
        handleErrors(error);
      }
      setIsLoading(false);
    };

    const [promType, setPromType] = useState<any>();

    const onFinish = (values: any) => {
      setIsLoading(true);
      form
        .validateFields([Object.keys(values)])
        .finally(() => handleSubmit(values));
    };

    const handleDelete = async () => {
      Modal.confirm({
        content: t("bizBase.confirm_delete"),
        async onOk() {
          try {
            await promotionsStore?.deletePromotionById(Number(id));
            message.success(t("promo.deleted"));
            history.push(AppRoutes.promotions + "?tab=COURSE");
          } catch (error: any) {
            message.error(
              error?.data?.message || t("server_errors." + "Bad Request"),
            );
          }
        },
      });
    };

    const handleFormValuesChange = (changedValues: any) => {
      const formFieldName = Object.keys(changedValues)[0];
      if (formFieldName === "startDate") {
        form.setFieldsValue({ endDate: undefined });
      }
    };

    useEffect(() => form.resetFields(), [editData]);

    const courses = editData?.courseName?.map((el: any) => {
      return {
        label: el,
        subLabel: el,
        value: el,
        id: el,
      };
    });

    console.log({ courses });

    return (
      <>
        <HeadSeo
          title={
            isView
              ? t("promo.viewPromotion")
              : id
              ? t("promo.editPromotion")
              : t("promo.addCoursePromotion")
          }
        />
        <LayoutPage defMenuItem={"promotions"}>
          <div className="content-wrapper content-with-table">
            <ButtonLink
              onClick={() => history.push(AppRoutes.promotions + "?tab=COURSE")}
            >
              <i className={`${bizIcons.ARROWLEFT} fs-20 mr-8`} />
              {t("promo.backToPromotions")}
            </ButtonLink>
            <div className="auth-header bsl-4">
              <div className="auth-form">
                <h1 className={"auth-header_title"}>
                  {isView
                    ? t("promo.viewPromotion")
                    : id
                    ? t("promo.editPromotion")
                    : t("promo.addCoursePromotion")}
                </h1>
                {!isView && id && (
                  <ButtonLink onClick={handleDelete}>
                    <i className={`${bizIcons.DELETE} fs-20 mr-8`} />
                    {t("bizBase.delete")}
                  </ButtonLink>
                )}
              </div>
            </div>
            <div className="auth-form">
              <Form
                form={form}
                name="normal_login"
                className="login-form"
                initialValues={{
                  name: editData?.name,
                  type: editData?.type,
                  discountPercentageOrValue:
                    editData?.discountPercentageOrValue || 1,
                  startDate: editData?.effectiveFrom
                    ? moment(
                        helpers.getServerDate(editData?.effectiveFrom),
                        FORMAT_DATE.query,
                      )
                    : undefined,
                  endDate: editData?.effectiveTo
                    ? moment(
                        helpers.getServerDate(editData?.effectiveTo),
                        FORMAT_DATE.query,
                      )
                    : undefined,
                  courses: courses || [],
                }}
                onValuesChange={handleFormValuesChange}
                onFinish={onFinish}
              >
                <Form.Item className="bsl-4">
                  <div className="label-wrapper">
                    <label htmlFor="courses">
                      {t("promo.promotedCourse")}*
                    </label>
                  </div>

                  <Form.Item>
                    <AutoCompleteSearchCourses
                      initCourses={courses || []}
                      fieldName="courses"
                      formInstance={form}
                      isView={Boolean(id)}
                    />
                  </Form.Item>
                  <Form.Item
                    noStyle
                    name="courses"
                    rules={[
                      {
                        required: true,
                        message: t("messages.required_field"),
                      },
                    ]}
                  />
                </Form.Item>
                <Form.Item className="bsl-4">
                  <div className="label-wrapper">
                    <label htmlFor="name">{t("promo.promotionName")}*</label>
                  </div>
                  <Form.Item
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: t("messages.required_field"),
                      },
                    ]}
                  >
                    <Input
                      id={"name"}
                      placeholder={t("promo.enterPromotionName")}
                      readOnly={isLoading || isView}
                      size={"large"}
                      disabled={isView}
                    />
                  </Form.Item>
                </Form.Item>
                <Form.Item className="bsl-4">
                  <div className="label-wrapper">
                    <label htmlFor="type">{t("promo.promotionType")}*</label>
                  </div>
                  <Form.Item
                    name="type"
                    rules={[
                      {
                        required: true,
                        message: t("messages.required_field"),
                      },
                    ]}
                  >
                    <Select
                      placeholder={t("promo.selectPromotionType")}
                      suffixIcon={
                        <i className="ri-arrow-drop-down-line fs-20" />
                      }
                      onChange={(e: PromotionType) => setPromType(e)}
                      disabled={isView}
                    >
                      <Select.Option value={PromotionType.Discount}>
                        {t("promo.Discount")}
                      </Select.Option>
                      <Select.Option value={PromotionType.FreeClass}>
                        {t("promo.FreeClass")}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Form.Item>
                {promType === PromotionType.Discount && (
                  <Form.Item className="bsl-4">
                    <div className="label-wrapper">
                      <label htmlFor="discountPercentageOrValue">
                        {t("promo.Discount")}*
                      </label>
                    </div>{" "}
                    <div className="course-total-price min">
                      <span className="course-total-price-unit">%</span>
                      <Form.Item
                        name="discountPercentageOrValue"
                        rules={[
                          {
                            required: true,
                            message: t("messages.required_field"),
                          },
                        ]}
                      >
                        <InputNumber
                          id={"discountPercentageOrValue"}
                          placeholder={"Enter discount amount"}
                          style={{ width: "100%" }}
                          maxLength={5}
                          min={1}
                          max={100}
                          decimalSeparator="."
                          readOnly={isLoading || isView}
                          disabled={isView}
                          size={"large"}
                        />
                      </Form.Item>
                    </div>
                  </Form.Item>
                )}
                <Form.Item className="bsl-4">
                  <div className="label-wrapper">
                    <label htmlFor="startDate">{t("courses.dateFrom")}*</label>
                  </div>

                  <Form.Item
                    name="startDate"
                    rules={[
                      {
                        required: true,
                        message: t("messages.required_field"),
                      },
                    ]}
                  >
                    <DatePicker
                      name={"startDate"}
                      style={{ width: "100%" }}
                      showToday={false}
                      disabledDate={(current) => {
                        return current && current < moment().startOf("day");
                      }}
                      placeholder={t("courses.enterStartDate")}
                      onChange={(value) => {
                        setStartDate(value);

                        form.setFieldsValue({
                          endDate: value
                            ? moment(value).add(1, "days")
                            : undefined,
                        });
                      }}
                      size={"large"}
                      inputReadOnly={isView}
                      disabled={isView}
                    />
                  </Form.Item>
                </Form.Item>
                <Form.Item className="bsl-4">
                  <div className="label-wrapper">
                    <label htmlFor="endDate">{t("courses.dateTo")}*</label>
                  </div>

                  <Form.Item
                    name="endDate"
                    rules={[
                      {
                        required: true,
                        message: t("messages.required_field"),
                      },
                    ]}
                  >
                    <DatePicker
                      name={"endDate"}
                      style={{ width: "100%" }}
                      showToday={false}
                      disabledDate={(current) => {
                        const value = startDate ? moment(startDate) : undefined;
                        return value
                          ? current && current < value?.startOf("day")
                          : current && current < moment().startOf("day");
                      }}
                      placeholder={t("promo.enterEndDate")}
                      size={"large"}
                      inputReadOnly={isView}
                      disabled={isView}
                    />
                  </Form.Item>
                </Form.Item>{" "}
                {!isView && (
                  <Form.Item>
                    <Button
                      className="full-width fs-16 tsl-4"
                      style={{ marginBottom: "32px" }}
                      type="primary"
                      htmlType="submit"
                      loading={isLoading}
                      disabled={isLoading}
                      icon={
                        <div className={"flex-center"}>
                          {!!editData && (
                            <i className={`${bizIcons.SAVE} mr-8 fs-20`} />
                          )}
                          {!!editData ? "Save changes" : "Create a promotion"}
                        </div>
                      }
                    />
                  </Form.Item>
                )}
              </Form>
            </div>
          </div>
        </LayoutPage>
      </>
    );
  }),
);

export default withRouter(AddPromotionsCourse);
