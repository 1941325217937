import {
  Button,
  Col,
  Form,
  Input,
  message,
  notification,
  Row,
  Select,
} from "antd";
import ImageUploader from "components/ImageUploader";
import { COUNTRY_DATA, PhoneCode } from "components/PhoneCode";
import SaveButtonText from "components/SaveButtonText";
import { City, Country, State } from "country-state-city";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { CITY_DATA, SINGAPORE_CITY_DATA } from "pages/signup/interface";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRoles, useType } from "scripts/custom-hooks/useCurrentUser";
import { getLanguage } from "scripts/custom-hooks/useLanguage";
import helpers from "scripts/helpers";
import { Languages } from "scripts/mobx/languageStore";
import { Roles, UserStore, UserType } from "scripts/mobx/userStore";
import ButtonLink from "../../../components/ButtonLink";
import { bizIcons } from "../../../constants/bizConstants";
const { Option } = Select;

interface IStoreProps {
  data: any;
  onBack: () => void;
  onEdit: (data: any) => Promise<void>;
  dictionaryStore?: any;
  userStore?: UserStore;
}

const EditKidzBizDetails = inject("userStore")(
  observer((props: IStoreProps) => {
    const { onBack, data, userStore } = props;
    const { t } = useTranslation("base");
    const userType = useType();
    const userRole = useRoles();

    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [imageOrganization, setImageOrganization] = useState<any>(null);
    const isCheckMaster =
      userType === UserType.MASTER && userRole !== Roles.PARTIAL;

    const [logoImage, setLogoImage] = useState(
      `${
        data?.providerAttributes.logoImageUrl?.match(/data:image\/(.*);base64,/)
          ? ""
          : data?.providerAttributes.logoImageUrl
      }`,
    );

    const [logo, setLogo] = useState<File>();
    const [form] = Form.useForm();
    const [countryCode, setCountryCode] = useState<string>(
      COUNTRY_DATA.find((item) =>
        item.name.includes(data?.providerAttributes?.country ?? "Hong Kong"),
      )?.isoCode ?? "HK",
    );

    const country = useMemo(() => {
      const selectedCountry = Country.getCountryByCode(countryCode);
      return selectedCountry;
    }, [data, countryCode]);

    const getStatesList = useCallback((isoCode) => {
      if (!isoCode) return [];
      if (isoCode === "HK")
        return CITY_DATA.map((item) => ({
          name: item.value,
          value: item.value,
        }));

      if (isoCode === "SG") return SINGAPORE_CITY_DATA;
      return State.getStatesOfCountry(isoCode).map((item) => ({
        name: item.name,
        value: item.isoCode,
      }));
    }, []);

    // interpolate states by country
    const states = useMemo(() => getStatesList(country?.isoCode), [country]);

    const [selectedStateCode, setSelectedStateCode] = useState<string>();

    const getCitiesList = useCallback((isoCode, stateCode) => {
      if (!isoCode) return [];
      if (isoCode === "HK")
        return CITY_DATA.find((el) => el.value === stateCode)?.suburbs ?? [];

      if (isoCode === "SG") return SINGAPORE_CITY_DATA[0].suburbs;
      if (!stateCode) return [];
      return City.getCitiesOfState(isoCode, stateCode).map((item) => ({
        value: item.name,
      }));
    }, []);

    const handleStateChanged = (value: string) => {
      // set default suburb after changing state
      const mCities = getCitiesList(country?.isoCode, value);
      form.setFieldsValue({
        suburb: mCities?.[0]?.value,
      });

      setSelectedStateCode(value);
    };

    // interpolate cities by state
    const cities = useMemo(
      () => getCitiesList(country?.isoCode, selectedStateCode),
      [country, selectedStateCode],
    );

    const onFinish = async (values: any) => {
      setIsLoading(true);
      try {
        await form.validateFields(Object.keys(values));
        const country = Country.getCountryByCode(values?.codeMobile);
        const codeMobile = values?.codeMobile
          ? `+${country?.phonecode}`
          : "+852";
        const codeLandline = values?.codeMobile
          ? `+${country?.phonecode}`
          : "+852";

        userStore
          ?.userUpdate({
            firstName: values?.contactPersonFirstName || "",
            lastName: values?.contactPersonLastName || "",
            mobile: codeMobile + values?.mobilePhone,
            landline: values?.landlinePhone
              ? codeLandline + values?.landlinePhone
              : null,
          })
          .then(() => {
            if (!isCheckMaster) {
              message.success(
                t("messages.update_successful", {
                  defaultValue: "Update successful",
                }),
              );
              setIsLoading(false);
              onBack();
            }
          })
          .catch((error) => {
            setIsLoading(false);
            helpers.throwErrors(
              t,
              [],
              error?.data?.error,
              error?.data?.message,
            );
          });

        const formData = new FormData();
        formData.append("legalName", values.organizationLegalName || "");
        formData.append("tradeName", values.organizationTradeName || "");
        formData.append("country", country?.name || "Hong Kong");
        formData.append("area", values.area || "");
        formData.append("suburb", values.suburb || "");

        if (imageOrganization && imageOrganization !== "remove") {
          formData.append("deleteExistingLogoFile", `${false}`);
          formData.append("logoFile", imageOrganization);
        } else if (imageOrganization === "remove") {
          formData.append("deleteExistingLogoFile", `${true}`);
        } else {
          formData.append("deleteExistingLogoFile", `${false}`);
        }
        if (isCheckMaster) {
          userStore
            ?.updateProvider(formData)
            .then(() => {
              message.success(
                t("messages.update_successful", {
                  defaultValue: "Update successful",
                }),
              );
              setIsLoading(false);
              onBack();
            })
            .catch((error) => {
              helpers.throwErrors(
                t,
                [],
                error?.data?.error,
                error?.data?.message,
              );
            });
        }
      } catch (e: any) {
        const { _message } = e;
        message.error(_message);
        setIsLoading(false);
      }
    };

    const validateForm = useCallback(
      (field?: string) => {
        form
          .validateFields(field ? [field] : undefined)
          .then(
            () => {
              setIsDisabled(false);
            },
            () => {
              setIsDisabled(true);
            },
          )
          .catch((e) => {
            const { _message } = e;
            message.error(
              _message ||
                t("messages.some_error_occurred", {
                  defaultValue: "Some error occurred",
                }),
            );
          });
      },
      [isDisabled],
    );

    useEffect(() => {
      if (logo) {
        helpers.getBase64(logo).then((imageData: any) => {
          setLogoImage(imageData);
        });
      }
    }, [logo]);

    const onUploadChanged = (file: File) => {
      setImageOrganization(file);
      validateForm("logo");
      setLogo(file);
    };

    const onCheckBeforeUpload = (messages: string[]) => {
      messages.map((m) => message.error(m));
    };

    const handleRemoveLogo = () => {
      setLogoImage("");
      setImageOrganization("remove");
    };

    const handlePhoneChanged = (value: string) => {
      form.setFieldsValue({
        codeMobile: value,
        codeLandline: value,
      });

      setCountryCode(value);

      // set default state by area
      const mStates = getStatesList(value);
      form.setFieldsValue({
        area: mStates?.[0].value,
      });
      setSelectedStateCode(mStates?.[0].value);
    };

    const initialValue = useMemo(() => {
      const country = COUNTRY_DATA.find((item) =>
        item.name.includes(data?.providerAttributes?.country ?? "Hong Kong"),
      );

      const mCountryCode = country?.isoCode ?? "HK";
      const mobilePhone = (data?.userAttributes?.mobile ?? "").replace(
        country?.phonecode,
        "",
      );
      const landlinePhone = (data?.userAttributes?.landline ?? "").replace(
        country?.phonecode,
        "",
      );

      return {
        organizationLegalName: data?.providerAttributes?.legalName,
        organizationTradeName: data?.businessUnitAttributes?.tradeName,
        contactPersonFirstName: data?.userAttributes?.firstName,
        contactPersonLastName: data?.userAttributes?.lastName,
        codeMobile: mCountryCode,
        mobilePhone: mobilePhone,
        codeLandline: mCountryCode,
        landlinePhone: landlinePhone,
        area: data?.providerAttributes?.area,
        suburb: data?.providerAttributes?.suburb,
      };
    }, [data]);

    return (
      <div>
        <div className="content-wrapper content-with-table">
          <ButtonLink onClick={() => onBack()}>
            <i className={`${bizIcons.ARROWLEFT} fs-20 mr-8`} />
            {t("kidzbiz.backToKidzbiz")}
          </ButtonLink>
          <div className="auth-header bsl-4">
            <div>
              <span className={"auth-header_title"}>
                {t("kidzbiz.editDetails")}
              </span>
            </div>
          </div>
          <div className="auth-form">
            <Form
              form={form}
              onLoad={() => validateForm()}
              name="normal_login"
              className="login-form form-grid-layout"
              initialValues={initialValue}
              onFinish={onFinish}
            >
              <Row>
                <Col span={24}>
                  <div className="bsl-4">
                    <Form.Item
                      className={"bsl-4"}
                      name={"logo"}
                      help={t("signUp.step4.markerLogo")}
                    >
                      <div className="label-wrapper">
                        <label htmlFor="logo">{t("kidzbiz.logo")}</label>
                      </div>
                      <ImageUploader
                        name="logo"
                        imageUrl={logoImage}
                        onCheckBeforeUpload={onCheckBeforeUpload}
                        onUploadChanged={onUploadChanged}
                        disabled={isCheckMaster ? false : true}
                      />
                    </Form.Item>
                    {isCheckMaster && (
                      <span onClick={handleRemoveLogo} className="removeLogo">
                        <i
                          style={{ fontSize: "16px" }}
                          className={`${bizIcons.DELETE}`}
                        />
                      </span>
                    )}
                  </div>
                </Col>
              </Row>

              <Row gutter={24}>
                <Col xs={24} md={12}>
                  <Form.Item className="bsl-4">
                    <div className="label-wrapper">
                      <label htmlFor="LegalName">
                        {t("kidzbiz.legalName")}*
                      </label>
                    </div>
                    <Form.Item name="organizationLegalName">
                      <Input.TextArea
                        id={"organizationLegalName"}
                        maxLength={45}
                        disabled={true}
                        autoSize={{ minRows: 3, maxRows: 3 }}
                        onBlur={() => validateForm()}
                      />
                    </Form.Item>
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item className="bsl-4">
                    <div className="label-wrapper">
                      <label htmlFor="organizationTradeName">
                        {t("kidzbiz.tradeName")}
                      </label>
                    </div>
                    <Form.Item name="organizationTradeName">
                      <Input.TextArea
                        id={"organizationTradeName"}
                        placeholder={t("kidzbiz.enterTradeName", {
                          defaultValue: "Enter Trade Name",
                        })}
                        maxLength={45}
                        readOnly={isLoading}
                        autoSize={{ minRows: 3, maxRows: 3 }}
                        onBlur={() => validateForm()}
                        disabled={isCheckMaster ? false : true}
                      />
                    </Form.Item>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={24}>
                <Col xs={24} md={12}>
                  <Form.Item className="bsl-4">
                    <div className="label-wrapper">
                      <label htmlFor="area">
                        {t("signUp.step2.area", {
                          defaultValue: "Area",
                        })}
                      </label>
                    </div>
                    <Form.Item name="area">
                      <Select
                        placeholder={t("signUp.step2.enterArea", {
                          defaultValue: "Enter area",
                        })}
                        suffixIcon={
                          <i className="ri-arrow-drop-down-line fs-20" />
                        }
                        onChange={handleStateChanged}
                        disabled={!isCheckMaster}
                      >
                        {states.map((city: { value: string }) => {
                          return (
                            <Option key={city.value} value={city.value}>
                              {city.value}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item className="bsl-4">
                    <div className="label-wrapper">
                      <label htmlFor="suburb">{t("kidzbiz.enterSuburb")}</label>
                    </div>
                    <Form.Item name="suburb">
                      <Select
                        placeholder={t("kidzbiz.enterSuburb")}
                        suffixIcon={
                          <i className="ri-arrow-drop-down-line fs-20" />
                        }
                        onChange={() => validateForm("suburb")}
                        disabled={!isCheckMaster}
                      >
                        {cities.map((suburb) => (
                          <Option key={suburb.value} value={suburb.value}>
                            {suburb.value}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={24}>
                <Col xs={24} md={12}>
                  <Form.Item className="bsl-4">
                    <div className="label-wrapper">
                      <label htmlFor="contactPersonFirstName">
                        {t("kidzbiz.cpfn")}*
                      </label>
                    </div>

                    <Form.Item
                      name="contactPersonFirstName"
                      rules={[
                        {
                          required: true,
                          message: t("messages.required_field", {
                            defaultValue: "Please fill in all required fields",
                          }),
                        },
                        {
                          pattern: /^[a-zA-Z ]+$/,
                          message: "First name should contain only letters",
                        },
                      ]}
                    >
                      <Input
                        id={"contactPersonFirstName"}
                        maxLength={45}
                        placeholder={t("kidzbiz.enterContactPersonFirstName", {
                          defaultValue: "Enter contact Person First Name",
                        })}
                        readOnly={isLoading}
                        onBlur={() => validateForm("contactPersonFirstName")}
                        onPressEnter={() =>
                          validateForm("contactPersonFirstName")
                        }
                        size={"large"}
                      />
                    </Form.Item>
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item className="bsl-4">
                    <div className="label-wrapper">
                      <label htmlFor="contactPersonLastName">
                        {t("kidzbiz.cpln")}*
                      </label>
                    </div>

                    <Form.Item
                      name="contactPersonLastName"
                      rules={[
                        {
                          required: true,
                          message: t("messages.required_field", {
                            defaultValue: "Please fill in all required fields",
                          }),
                        },
                        {
                          pattern: /^[a-zA-Z ]+$/,
                          message: "First name should contain only letters",
                        },
                      ]}
                    >
                      <Input
                        id={"contactPersonLastName"}
                        maxLength={45}
                        placeholder={t("kidzbiz.enterContactPersonLastName", {
                          defaultValue: "Enter contact Person Last Name",
                        })}
                        readOnly={isLoading}
                        onBlur={() => validateForm("contactPersonLastName")}
                        onPressEnter={() =>
                          validateForm("contactPersonLastName")
                        }
                        size={"large"}
                      />
                    </Form.Item>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={24}>
                <Col xs={24} md={12}>
                  <Form.Item className="bsl-4">
                    <div className="label-wrapper">
                      <label htmlFor="mobilePhone">{t("kidzbiz.cpmp")}*</label>
                    </div>

                    <Form.Item
                      name="mobilePhone"
                      rules={[
                        {
                          required: true,
                          message: t("messages.required_field", {
                            defaultValue: "Please fill in all required fields",
                          }),
                        },
                        {
                          min: 8,
                          message:
                            "Mobile number cannot be less than 8 characters",
                        },
                      ]}
                    >
                      <Input
                        maxLength={8}
                        placeholder={t("signUp.step2.enterMobilePhone", {
                          defaultValue: "Enter mobile phone",
                        })}
                        readOnly={isLoading}
                        onKeyPress={(e: any) => {
                          if (e.key) {
                            if (!new RegExp(/^[0-9]$/g).test(e.key)) {
                              e.preventDefault();
                            }
                          }
                        }}
                        prefix={
                          <>
                            <i className={`${bizIcons.SMARTPHONE} mr-6`} />
                            <PhoneCode
                              name="codeMobile"
                              onChange={handlePhoneChanged}
                              disabled
                            />
                          </>
                        }
                        size={"large"}
                      />
                    </Form.Item>
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item className="bsl-4">
                    <div className="label-wrapper">
                      <label htmlFor="landlinePhone">{t("kidzbiz.cplp")}</label>
                    </div>

                    <Form.Item
                      name="landlinePhone"
                      rules={[
                        {
                          min: 8,
                          message: t("messages.phone_invalid_min", {
                            name: "Landline",
                            min: 8,
                          }),
                        },
                      ]}
                    >
                      <Input
                        id={"landlinePhone"}
                        maxLength={8}
                        placeholder={t("kidzbiz.enter_cplp")}
                        readOnly={isLoading}
                        onKeyPress={(e: any) => {
                          if (e.key) {
                            if (!new RegExp(/^[0-9]$/g).test(e.key)) {
                              e.preventDefault();
                            }
                          }
                        }}
                        prefix={
                          <>
                            <i className={`${bizIcons.SMARTPHONE} mr-6`} />
                            <PhoneCode
                              name="codeLandline"
                              onChange={handlePhoneChanged}
                              disabled
                            />
                          </>
                        }
                        size={"large"}
                      />
                    </Form.Item>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={24}>
                <Col xs={24}>
                  <Form.Item>
                    <Button
                      className="full-width fs-16 tsl-4"
                      type="primary"
                      htmlType="submit"
                      loading={isLoading}
                      icon={
                        <SaveButtonText>
                          {t("bizBase.saveChanges", {
                            defaultValue: "Save changes",
                          })}
                        </SaveButtonText>
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    );
  }),
);

export default EditKidzBizDetails;
