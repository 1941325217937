import { Checkbox, Popover } from "antd";
import { FORMAT_DATE } from "constants/commons";
import {
  CancelType,
  CourseStatus,
  KidsAgeType,
} from "constants/type.interface";
import { take, uniq, uniqBy } from "lodash";
import moment from "moment";
import { getAgeChild } from "pages/customers/viewCustomer/tableGenerator";
import { PromotionType } from "pages/promotions/data";
import React from "react";
import { useTranslation } from "react-i18next";
import { formatPrice } from "scripts/utils/formatPrice";
import bizibuzSVG from "../../assets/icons/bizibuz.svg";
import CalendarCancel from "../../assets/icons/calendar-cancel-line.svg";
import ButtonLink from "../../components/ButtonLink";
import { Link } from "../../components/Link";
import { bizIcons as tb } from "../../constants/bizConstants";
import helpers from "../../scripts/helpers";
import { CLASS_DATES, ICustomerChildData } from "./interface";

export const getKidsAge = (age: number) => {
  let kidsAge = age;
  let type = KidsAgeType.Months;
  if (age > 23 && age % 12 === 0) {
    type = KidsAgeType.Years;
    kidsAge = age / 12;
  }
  return {
    age: kidsAge,
    type,
  };
};

export const tableCourseDetailsTitleColumn = (
  createdByMe: boolean,
  isLinked: boolean,
) => {
  const { t } = useTranslation();
  // useRoles() !== Roles.PARTIAL &&
  const firstColData = createdByMe
    ? [
        {
          dataIndex: "",
          key: "",
        },
      ]
    : [
        {
          dataIndex: "Kidzbiz",
          key: "Kidzbiz",
          render: (text: string) =>
            isLinked &&
            text && (
              <div className="course-detail-first">
                <div className="td-title flex align-center">
                  <i
                    className={tb.BUILDING}
                    style={{ fontSize: "16px", marginRight: "8px" }}
                  />
                  {t("menu.kidzbiz", { defaultValue: "Kidzbiz" })}
                </div>
                {text}
              </div>
            ),
          colSpan: 5,
        },
      ];
  const columns = [
    {
      key: "Category",
      dataIndex: "Category",
      render: (text: any) =>
        text && (
          <div>
            <div className="td-title flex align-center">
              <i
                className={tb.UL}
                style={{ fontSize: "16px", marginRight: "8px" }}
              />
              {t("courses.category", { defaultValue: "Category" })}
            </div>
            {t("categories." + text) || "-"}
          </div>
        ),
    },
    {
      key: "SubCategory",
      dataIndex: "SubCategory",
      render: (text: any, record: any) => {
        return (
          text && (
            <div>
              <div className="td-title flex align-center">
                <i
                  className={tb.LISTCHECK2}
                  style={{ fontSize: "16px", marginRight: "8px" }}
                />
                {t("courses.subCategory", { defaultValue: "Sub category" })}
              </div>
              {t("subCategories." + text) || "-"}
            </div>
          )
        );
      },
    },
    {
      key: "AgeRange",
      dataIndex: "AgeRange",
      render: (text: any) => (
        <div>
          <div className="td-title flex align-center">
            <i
              className={tb.AGE}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            {t("courses.ageRange", { defaultValue: "Age range" })}
          </div>
          {text || "-"}
        </div>
      ),
    },
    {
      dataIndex: "DateFromTo",
      key: "DateFromTo",
      render: (text: any) => (
        <div>
          <div className="td-title flex align-center">
            <i
              className={tb.CALENDARTODO}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />

            {t("courses.dateFromTo", { defaultValue: "Date from - to" })}
          </div>
          {text || "-"}
        </div>
      ),
    },
  ];

  return [...firstColData, ...columns];
};
export const tableCourseDetailsTitleData = (t: any, data: any) => {
  return [
    {
      Kidzbiz: data?.provider?.legalName || "-",
      DateFromTo: `${
        data?.startDate
          ? helpers.formatDate(helpers.getServerDate(data?.startDate))
          : "..."
      } - ${
        data?.endDate
          ? helpers.formatDate(helpers.getServerDate(data?.endDate))
          : "..."
      }`,
      AgeRange: `${getKidsAge(data?.minAgeMonth)?.age} ${t(
        "courses." + getKidsAge(data?.minAgeMonth)?.type?.toLowerCase(),
      )} - ${getKidsAge(data?.maxAgeMonth)?.age} ${t(
        "courses." + getKidsAge(data?.maxAgeMonth)?.type?.toLowerCase(),
      )}`,
      Category: data?.category,
      SubCategory: data?.subCategory,
      id: data?.id,
      key: data?.id,
    },
  ];
};

export const tableCourseDetailsPriceColumns = () => {
  const { t } = useTranslation();

  const columns = [
    {
      dataIndex: "",
      key: "",
    },
    {
      dataIndex: "NumberOfClasses",
      key: "NumberOfClasses",
      render: (text: any, record: any) => (
        <div>
          <div className="td-title flex align-center">
            <i
              className={tb.BOOKOPEN}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            {t("courses.numberClassesTotalRemaing")}
          </div>
          {helpers.formatNumber(Number(text || 0))} /{" "}
          {helpers.formatNumber(Number(record?.NumberOfClassesRemaining || 0))}
        </div>
      ),
    },
    {
      key: "TotalPrice",
      dataIndex: "TotalPrice",
      render: (text: any, record: any) => {
        return (
          <div>
            <div className="td-title flex align-center">
              <i
                className={tb.PRICE}
                style={{ fontSize: "16px", marginRight: "8px" }}
              />

              {t("courses.coursePriceTotalRemaing")}
            </div>
            {
              <div className="course-prices">
                {record?.totalPriceAfterPromoted > 0 &&
                Math.abs(
                  (record?.totalPrice || 0) -
                    (record?.totalPriceAfterPromoted || 0),
                ) > 0.05 ? (
                  <>
                    <del>{formatPrice(record?.totalPrice)}</del>
                    <ins style={{ color: "red" }}>
                      {formatPrice(record?.totalPriceAfterPromoted)}
                    </ins>
                  </>
                ) : (
                  <ins>{formatPrice(record?.totalPrice)}</ins>
                )}{" "}
                /{" "}
                {record?.remainingPriceAfterPromoted > 0 &&
                Math.abs(
                  (record?.remainingPrice || 0) -
                    (record?.remainingPriceAfterPromoted || 0),
                ) > 0.05 ? (
                  <>
                    <del>{formatPrice(record?.remainingPrice)}</del>
                    <ins style={{ color: "red" }}>
                      {formatPrice(record?.remainingPriceAfterPromoted)}
                    </ins>
                  </>
                ) : (
                  <ins>{formatPrice(record?.remainingPrice)}</ins>
                )}
              </div>
            }
          </div>
        );
      },
    },
    {
      key: "MinClassesForSale",
      dataIndex: "MinClassesForSale",
      render: (text: any) => (
        <div>
          <div className="td-title flex align-center">
            <i
              className={tb.PRICE2}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            {t("courses.minClassesForSale", {
              defaultValue: "Min classes for sale",
            })}
          </div>
          {helpers.formatNumber(Number(text || 0))}
        </div>
      ),
    },
    {
      key: "MaxClassHeadcount",
      dataIndex: "MaxClassHeadcount",
      render: (text: any) => (
        <div>
          <div className="td-title flex align-center">
            <i
              className={tb.PRICE2}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />

            {t("courses.maxClassHeadcount", {
              defaultValue: "Max class headcount",
            })}
          </div>
          {helpers.formatNumber(Number(text || 0))}
        </div>
      ),
    },
    {
      key: "BizibuzSignups",
      dataIndex: "BizibuzSignups",
      render: (text: any) =>
        text ? (
          <div>
            <div className="td-title flex align-center">
              <img
                src={bizibuzSVG}
                style={{ height: "16px", marginRight: "8px" }}
                alt="biz icon"
              />

              {t("courses.bizibuzSignUps", {
                defaultValue: "Bizibuz signups",
              })}
            </div>
            {text || "0"}
          </div>
        ) : null,
    },
    {
      key: "ExternalSignups",
      dataIndex: "ExternalSignups",
      render: (text: any) =>
        text && (
          <div>
            <div className="td-title flex align-center">
              <i
                className={tb.SHARE}
                style={{ fontSize: "16px", marginRight: "8px" }}
              />
              {t("courses.externalSignUps", {
                defaultValue: "External sign ups",
              })}
            </div>
            {text || "0"}
          </div>
        ),
    },
    {
      key: "pendingBookingCount",
      dataIndex: "pendingBookingCount",
      render: (text: any) => (
        <div>
          <div className="td-title flex align-center">
            <i
              className={tb.CHECKBOXCIRCLE}
              style={{ color: "#ffcc33", fontSize: "16px", marginRight: "8px" }}
            />
            {t("courses.pendingBookings")}
          </div>
          {text || "0"}
        </div>
      ),
    },
    {
      key: "acceptedBookingCount",
      dataIndex: "acceptedBookingCount",
      render: (text: any) => (
        <div>
          <div className="td-title flex align-center">
            <i
              className={tb.CHECKBOXCIRCLE}
              style={{ color: "green", fontSize: "16px", marginRight: "8px" }}
            />
            {t("courses.acceptedBookings")}
          </div>
          {text || "0"}
        </div>
      ),
    },
  ];

  return columns;
};
export const tableCourseDetailsPriceData = (data: any) => {
  return [
    {
      remainingPrice: data?.remainingPrice,
      remainingPriceAfterPromoted: data?.remainingPriceAfterPromoted,
      totalPrice: data?.totalPrice,
      totalPriceAfterPromoted: data?.totalPriceAfterPromoted,
      NumberOfClassesRemaining: data?.remainingNumberOfClasses,
      NumberOfClasses: data?.numberOfClasses,
      TotalPrice: data?.pricePerClass || 0,
      PricePerHour: data?.pricePerHour || "0",
      PriceForAllPeriod: helpers.getNumberWithSpaces(data?.totalPrice),
      MaxClassHeadcount: data?.availableClassHeadcount || data?.capacity,
      MinClassesForSale: data?.minClassesSales,
      BizibuzSignups: data?.bizibuzSignups || "",
      ExternalSignups: data?.externalSignups,
      promotions: data?.promotions,
      pendingBookingCount: data?.pendingBookingCount || 0,
      acceptedBookingCount: data?.acceptedBookingCount,
      id: data?.id,
      key: data?.id,
    },
  ];
};

export const tableCoursesColumns = (dat: {
  tab?: string;
  isLinked?: boolean;
  isBroken?: boolean;
  forCopy?: boolean;
  copyCourseHandler?: (id: number) => void;
  rowSelection?: boolean;
  selectedRowKeys?: number[];
  onSelectedRowKeys?: (data: any) => void;
  providerId?: number;
  copyLinkClipboard: (
    id: number,
    name: string,
    type: "iframe" | "link",
  ) => void;
  checkAll?: boolean;
  checkThisPage?: boolean;
  isVerified?: boolean;
  HeadingSelectOption?: () => void;
}) => {
  const {
    tab,
    isLinked,
    isBroken,
    forCopy,
    copyCourseHandler,
    rowSelection,
    selectedRowKeys,
    onSelectedRowKeys,
    providerId,
    copyLinkClipboard,
    checkThisPage,
    checkAll,
    isVerified,
    HeadingSelectOption,
  } = dat;
  const { t } = useTranslation();

  const columns = [
    {
      title: (
        <Popover
          overlayClassName={`popover-ellipsis`}
          content={t("courses.courseName", {
            defaultValue: "Course name",
          })}
          trigger="hover"
        >
          <div className="ellipsis">
            {t("courses.courseName", {
              defaultValue: "Course name",
            })}
          </div>
        </Popover>
      ),
      dataIndex: "courseName",
      key: "courseName",
      // width: !isBroken ? "195px" : undefined,
      render: (text: string, record: any) => {
        return (
          <div>
            <div>
              <div className="flex-align-items">
                {isVerified &&
                  isBroken &&
                  [CourseStatus.Draft, CourseStatus.Pending].includes(
                    record?.status,
                  ) && (
                    <div className="mr-20">
                      <Checkbox
                        checked={
                          selectedRowKeys?.includes(Number(record.id))
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          onSelectedRowKeys?.(record.id);
                        }}
                      />
                    </div>
                  )}
                <Popover
                  overlayClassName={`popover-ellipsis ${
                    isBroken || !text ? "display-none" : ""
                  }`}
                  content={text}
                  trigger="hover"
                >
                  <div className="ellipsis text-color">{text}</div>
                </Popover>
              </div>
              {record?.courseCode && (
                <Popover
                  overlayClassName={`popover-ellipsis ${
                    isBroken || !record?.courseCode ? "display-none" : ""
                  }`}
                  content={record?.courseCode}
                  trigger="hover"
                >
                  <div
                    className="ellipsis flex tsl-5 text-color-secondary"
                    style={{ fontSize: 12, lineHeight: "16px" }}
                  >
                    <i
                      className={tb.LISTCHECK2}
                      style={{ fontSize: "15px", marginRight: "4px" }}
                    />
                    {record?.courseCode}
                  </div>
                </Popover>
              )}
            </div>
            {isBroken &&
              (forCopy ? (
                providerId === record?.providerId && (
                  <ButtonLink
                    onClick={() =>
                      copyCourseHandler && copyCourseHandler(record.id)
                    }
                    className={record?.hasError ? "text-red" : ""}
                  >
                    {t("bizBase.copy", {
                      defaultValue: "Copy",
                    })}
                    <i
                      className="ri-arrow-drop-right-line"
                      style={{ fontSize: "16px" }}
                    />
                  </ButtonLink>
                )
              ) : (
                <div className="flex-align-items">
                  <Link
                    to={`${"/view-course/" + record.id}`}
                    className={record?.hasError ? "text-red" : ""}
                  >
                    {t("bizBase.view", {
                      defaultValue: "View",
                    })}
                    <i
                      className="ri-arrow-drop-right-line"
                      style={{ fontSize: "16px" }}
                    />
                  </Link>

                  {/* {!record?.hasError && rowSelection && isVerified ? (
                    providerId === record?.providerId && (
                      <div style={{ marginLeft: 15 }}>
                        <Checkbox
                          checked={
                            selectedRowKeys?.includes(Number(record.id))
                              ? true
                              : false
                          }
                          onChange={(e) => {
                            onSelectedRowKeys?.(record.id);
                          }}
                        />
                      </div>
                    )
                  ) : (
                    <div style={{ marginLeft: 15, width: 16, height: 16 }} />
                  )} */}
                </div>
              ))}
          </div>
        );
      },
    },
    {
      title: (
        <Popover
          overlayClassName={`popover-ellipsis`}
          content={t("courses.category", {
            defaultValue: "Category",
          })}
          trigger="hover"
        >
          <div
            className={`${!isBroken ? "flex-align-items none-ellipsis" : ""}`}
          >
            <i key={Math.random()} className={tb.UL} />
            {t("courses.category", {
              defaultValue: "Category",
            })}
          </div>
        </Popover>
      ),
      key: "category",
      dataIndex: "category",
      // width: !isBroken ? "120px" : undefined,
      render: (text: any) => (
        <Popover
          overlayClassName={`popover-ellipsis ${
            isBroken || !text ? "display-none" : ""
          }`}
          content={text}
          trigger="hover"
        >
          <div className="ellipsis">
            {isBroken && (
              <i
                className={tb.UL}
                style={{ fontSize: "16px", marginRight: "8px" }}
              />
            )}
            {text || "-"}
          </div>
        </Popover>
      ),
    },
    {
      title: (
        <Popover
          overlayClassName={`popover-ellipsis`}
          content={t("courses.subCategory", {
            defaultValue: "Sub category",
          })}
          trigger="hover"
        >
          <div
            className={`${!isBroken ? "flex-align-items none-ellipsis" : ""}`}
          >
            <i key={Math.random()} className={tb.LISTCHECK2} />
            {t("courses.subCategory", {
              defaultValue: "Sub category",
            })}
          </div>
        </Popover>
      ),
      key: "subCategory",
      dataIndex: "subCategory",
      // width: !isBroken ? "120px" : undefined,
      render: (text: any) => (
        <Popover
          overlayClassName={`popover-ellipsis ${
            isBroken || !text ? "display-none" : ""
          }`}
          content={text}
          trigger="hover"
        >
          <div className="ellipsis">
            {isBroken && (
              <i
                className={tb.LISTCHECK2}
                style={{ fontSize: "16px", marginRight: "8px" }}
              />
            )}
            {text || "-"}
          </div>
        </Popover>
      ),
    },
    {
      title: (
        <Popover
          overlayClassName={`popover-ellipsis`}
          content={t("courses.age", {
            defaultValue: "Age",
          })}
          trigger="hover"
        >
          <div className="ellipsis">
            <i key={Math.random()} className={tb.AGE} />
            {t("courses.age", {
              defaultValue: "Age",
            })}
          </div>
        </Popover>
      ),
      key: "ageRange",
      dataIndex: "ageRange",
      render: (text: any) => (
        <Popover
          overlayClassName={`popover-ellipsis ${
            isBroken || !text ? "display-none" : ""
          }`}
          content={text}
          trigger="hover"
        >
          <div className="ellipsis">
            {isBroken && (
              <i
                className={tb.AGE}
                style={{ fontSize: "16px", marginRight: "8px" }}
              />
            )}
            {text || "-"}
          </div>
        </Popover>
      ),
    },
    {
      title: (
        <Popover
          overlayClassName={`popover-ellipsis`}
          content={t("courses.date", {
            defaultValue: "Date",
          })}
          trigger="hover"
        >
          <div className="ellipsis">
            <i key={Math.random()} className={tb.CALENDARTODO} />
            {t("courses.date", {
              defaultValue: "Date",
            })}
          </div>
        </Popover>
      ),
      key: "date",
      dataIndex: "date",
      // width: !isBroken ? "120px" : undefined,
      render: (text: any, record: any) => (
        <Popover
          overlayClassName={`popover-ellipsis ${
            isBroken || (!record.startDate && !record.endDate)
              ? "display-none"
              : ""
          }`}
          content={`${
            record.startDate ? helpers.formatDate(record.startDate) : "..."
          } - ${record.endDate ? helpers.formatDate(record.endDate) : "..."}`}
          trigger="hover"
        >
          <div className="ellipsis">
            {isBroken && (
              <i
                className={tb.CALENDARTODO}
                style={{ fontSize: "16px", marginRight: "8px" }}
              />
            )}
            {record.startDate || record.endDate
              ? `${
                  record.startDate
                    ? helpers.formatDate(record.startDate)
                    : "..."
                } - ${
                  record.endDate ? helpers.formatDate(record.endDate) : "..."
                }`
              : "-"}
          </div>
        </Popover>
      ),
    },

    {
      title: (
        <Popover
          overlayClassName={`popover-ellipsis`}
          content={t("bizBase.status", {
            defaultValue: "Status",
          })}
          trigger="hover"
        >
          <div className="ellipsis">
            <i key={Math.random()} className={tb.INFO} />
            {t("bizBase.status", {
              defaultValue: "Status",
            })}
          </div>
        </Popover>
      ),
      key: "status",
      dataIndex: "status",
      // width: tab === "Current" ? "120px" : undefined,
      ellipsis: !isBroken,
      render: (text: any) => (
        <Popover
          overlayClassName={`popover-ellipsis ${
            isBroken || !text ? "display-none" : ""
          }`}
          content={t("courses.status." + text)}
          trigger="hover"
        >
          <div className={"ellipsis"}>
            {isBroken && text && (
              <i
                className={tb.INFO}
                style={{ fontSize: "16px", marginRight: "8px" }}
              />
            )}
            {t("courses.status." + text)}
          </div>
        </Popover>
      ),
    },
  ];
  const finIndexCourseName = columns?.findIndex(
    (el) => el.key === "courseName",
  );

  if (isVerified && !isBroken && tab && ["Current", "Draft"]?.includes(tab)) {
    columns.splice(finIndexCourseName, 0, {
      // @ts-ignore
      width: !isBroken ? "80px" : undefined,
      title: <>{HeadingSelectOption?.()}</>,
      key: "selectRow",
      dataIndex: "selectRow",
      render: (text: React.ReactNode, record: any) => {
        return (
          <>
            {[CourseStatus.Draft, CourseStatus.Pending].includes(
              record?.status,
            ) && (
              <div className="flex-center">
                <Checkbox
                  checked={
                    selectedRowKeys?.includes(Number(record.id)) ? true : false
                  }
                  onChange={(e) => {
                    onSelectedRowKeys?.(record.id);
                  }}
                />
              </div>
            )}
          </>
        );
      },
    });
  }
  // &&  useRoles() !== Roles.PARTIAL;
  // if (isLinked) {
  const finIndexCoursCategory = columns?.findIndex(
    (el) => el.key === "category",
  );
  const elm = {
    title: (
      <Popover
        overlayClassName={`popover-ellipsis`}
        content={t("menu.kidzbiz", {
          defaultValue: "Kidzbiz",
        })}
        trigger="hover"
      >
        <div className="ellipsis">
          <i key={Math.random()} className={tb.BUILDING} />
          {t("menu.kidzbiz", {
            defaultValue: "Kidzbiz",
          })}
        </div>
      </Popover>
    ),
    key: "organizationLegalName",
    dataIndex: "organizationLegalName",
    render: (text: any) =>
      text && (
        <Popover
          overlayClassName={`popover-ellipsis ${
            isBroken || !text ? "display-none" : ""
          }`}
          content={text}
          trigger="hover"
        >
          <div className="ellipsis">
            {isBroken && (
              <i
                className={tb.BUILDING}
                style={{ fontSize: "16px", marginRight: "8px" }}
              />
            )}
            {text || "-"}
          </div>
        </Popover>
      ),
  };
  columns.splice(finIndexCoursCategory, 0, elm);
  // }

  const finIndexStatus = columns?.findIndex((el) => el.key === "status");

  if (tab && ["Current", "Past"]?.includes(tab)) {
    const accepted: any = {
      title: (
        <Popover
          overlayClassName={`popover-ellipsis`}
          content={t("courses.acceptedBookings")}
          trigger="hover"
        >
          <div
            className={`${!isBroken ? "flex-align-items none-ellipsis" : ""}`}
          >
            <i
              key={Math.random()}
              className={tb.CHECKBOXCIRCLE}
              style={{ color: "green" }}
            />
            {t("courses.acceptedBookings")}
          </div>
        </Popover>
      ),
      key: "acceptedBookings",
      dataIndex: "acceptedBookings",
      ellipsis: !isBroken,
      // width: !isBroken ? "110px" : undefined,
      render: (text: any) => (
        <Popover
          overlayClassName={`popover-ellipsis ${
            isBroken || !text ? "display-none" : ""
          }`}
          content={text}
          trigger="hover"
        >
          <div
            className={"ellipsis"}
            style={!isBroken ? { marginLeft: 25 } : {}}
          >
            {isBroken && (
              <i
                className={tb.CHECKBOXCIRCLE}
                style={{
                  color: "green",
                  fontSize: "16px",
                  marginRight: "8px",
                }}
              />
            )}
            {text}
          </div>
        </Popover>
      ),
    };
    columns.splice(finIndexStatus, 0, accepted);
  }

  if (tab && ["Current"]?.includes(tab)) {
    const pending: any = {
      title: (
        <Popover
          overlayClassName={`popover-ellipsis`}
          content={t("courses.pendingBookings")}
          trigger="hover"
        >
          <div
            className={`${!isBroken ? "flex-align-items none-ellipsis" : ""}`}
          >
            <i
              key={Math.random()}
              className={tb.CHECKBOXCIRCLE}
              style={{ color: "#ffcc33" }}
            />
            {t("courses.pendingBookings")}
          </div>
        </Popover>
      ),
      key: "pendingBookings",
      dataIndex: "pendingBookings",
      ellipsis: !isBroken,
      // width: !isBroken ? "110px" : undefined,
      render: (text: any) => (
        <Popover
          overlayClassName={`popover-ellipsis ${
            isBroken || !text ? "display-none" : ""
          }`}
          content={text}
          trigger="hover"
        >
          <div
            className={"ellipsis"}
            style={!isBroken ? { marginLeft: 25 } : {}}
          >
            {isBroken && (
              <i
                className={tb.CHECKBOXCIRCLE}
                style={{
                  color: "#ffcc33",
                  fontSize: "16px",
                  marginRight: "8px",
                }}
              />
            )}
            {text}
          </div>
        </Popover>
      ),
    };

    const finIndexAccepted = columns?.findIndex(
      (el) => el.key === "acceptedBookings",
    );
    columns.splice(
      finIndexAccepted > -1 ? finIndexAccepted : finIndexStatus,
      0,
      pending,
    );
  }

  if (tab === "Current") {
    columns.push({
      // @ts-ignore
      // width: !isBroken ? "120px" : undefined,

      title: (
        <div className="flex-align-items">
          <Popover
            overlayClassName={`popover-ellipsis`}
            content={t("courses.shareLink", {
              defaultValue: "Share link",
            })}
            trigger="hover"
          >
            <div
              className={`${!isBroken ? "flex-align-items none-ellipsis" : ""}`}
            >
              <i className="ri-share-line"></i>
              {t("courses.shareLink", {
                defaultValue: "Share link",
              })}
            </div>
          </Popover>
        </div>
      ),
      key: "share_link",
      dataIndex: "share_link",
      render: (text: React.ReactNode, record: any) => {
        const link = `${process.env.RAZZLE_CUSTOMER_URL}/courses/${
          record?.id
        }/${record?.courseName.split(" ").join("-")}`;
        return (
          <div>
            {isBroken && (
              <span style={{ marginRight: 8 }}>
                {t("courses.shareLink", { defaultValue: "Share link" })}
              </span>
            )}
            <Popover
              overlayClassName={`popover-ellipsis`}
              // placement="topLeft"
              content={
                <span
                  style={{ fontSize: "12px", lineHeight: "14px" }}
                >{`<iframe src="${link}" title="${record?.courseName}"></iframe>`}</span>
              }
              trigger="hover"
            >
              <span
                className="cursor-pointer"
                style={{ marginRight: 5, fontSize: "16px" }}
                onClick={() =>
                  copyLinkClipboard(record?.id, record?.courseName, "iframe")
                }
              >
                <i className="ri-code-box-line"></i>
              </span>
            </Popover>
            <Popover
              overlayClassName={`popover-ellipsis`}
              // placement="topLeft"
              content={
                <span
                  style={{ fontSize: "12px", lineHeight: "14px" }}
                >{`${link}`}</span>
              }
              trigger="hover"
            >
              <span
                className="cursor-pointer"
                style={{ marginRight: 5, fontSize: "16px" }}
                onClick={() =>
                  copyLinkClipboard(record?.id, record?.courseName, "link")
                }
              >
                <i className="ri-links-fill"></i>
              </span>
            </Popover>
          </div>
        );
      },
    });
  }

  if (!isBroken && ((forCopy && isVerified && tab !== "Draft") || !forCopy)) {
    columns.push({
      // @ts-ignore
      width: !isBroken ? "100px" : undefined,

      title: (
        <div className="flex-align-items">
          <Popover
            overlayClassName={`popover-ellipsis`}
            content={t("bizBase.action", {
              defaultValue: "Action",
            })}
            trigger="hover"
          >
            <div className="ellipsis">
              {t("bizBase.action", {
                defaultValue: "Action",
              })}
            </div>
          </Popover>
        </div>
      ),
      key: "action",
      dataIndex: "action",
      render: (text: React.ReactNode, record: any) => {
        return (
          <>
            {forCopy ? (
              providerId === record?.providerId && (
                <ButtonLink
                  onClick={() =>
                    copyCourseHandler && copyCourseHandler(record.id)
                  }
                  className={record?.hasError ? "text-red" : ""}
                >
                  {t("bizBase.copy", {
                    defaultValue: "Copy",
                  })}
                  <i
                    className="ri-arrow-drop-right-line"
                    style={{ fontSize: "16px" }}
                  />
                </ButtonLink>
              )
            ) : (
              <div className="flex-align-items">
                <Link
                  to={`${"/view-course/" + record.id}`}
                  className={record?.hasError ? "text-red" : ""}
                >
                  {t("bizBase.view", {
                    defaultValue: "View",
                  })}
                  <i
                    className="ri-arrow-drop-right-line"
                    style={{ fontSize: "16px" }}
                  />
                </Link>
              </div>
            )}
          </>
        );
      },
    });
  }
  return columns;
};

export const tableCoursesData = (data: any = {}, t: any) => {
  return {
    hasError: data?.hasError,
    courseName: data?.name,
    organizationLegalName: data?.legalName,
    startDate: data?.dateStart
      ? helpers.formatDate(helpers.getServerDate(data?.dateStart))
      : null,
    endDate: data?.dateEnd
      ? helpers.formatDate(helpers.getServerDate(data?.dateEnd))
      : null,
    ageRange: `${getKidsAge(data.ageFrom)?.age} ${t(
      "courses." + getKidsAge(data.ageFrom)?.type?.toLowerCase(),
    )} - ${getKidsAge(data.ageTo)?.age} ${t(
      "courses." + getKidsAge(data.ageTo)?.type?.toLowerCase(),
    )}`,
    category: data?.category ? t("categories." + data?.category) : "-",
    subCategory: data?.subCategory
      ? t("subCategories." + data?.subCategory)
      : "-",
    courseCode: data.code,
    status: data?.status || CourseStatus.Draft,
    pendingBookings: data?.pendingBookingCount || 0,
    acceptedBookings: data?.acceptedBookingCount || 0,
    providerId: data?.providerId,
    id: data.id,
    key: data.id,
  };
};

export const tableCourseScheduleColumns = (t: any) => {
  const columns = [
    {
      dataIndex: "",
      key: "",
    },
    {
      dataIndex: "dateFromTo",
      key: "dateFromTo",
      render: (text: any) => (
        <div>
          <div className="td-title flex align-center">
            <i
              className={tb.CALENDARTODO}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            {t("courses.dateFromTo", {
              defaultValue: "Date from - to",
            })}
          </div>
          {text || "-"}
        </div>
      ),
    },
    // {
    //   key: "NumberOfClasses",
    //   dataIndex: "NumberOfClasses",
    //   render: (text: any) => (
    //     <div>
    //       <div className="td-title flex align-center">
    //         <i
    //           className={tb.BOOKOPEN}
    //           style={{ fontSize: "16px", marginRight: "8px" }}
    //         />
    //         {t("courses.numberOfClasses", {
    //           defaultValue: "Number of classes",
    //         })}
    //       </div>
    //       {text || "-"}
    //     </div>
    //   ),
    // },
    // {
    //   key: "excludedDates",
    //   dataIndex: "excludedDates",
    //   render: (text: any) => (
    //     <div>
    //       <div className="td-title flex align-center">
    //         <i
    //           className={tb.CALENDARTODO}
    //           style={{ fontSize: "16px", marginRight: "8px" }}
    //         />
    //         {t("courses.classDates")}
    //       </div>
    //       {text || "-"}
    //     </div>
    //   ),
    // },
  ];

  return columns;
};

export const tableCourseScheduleData = (data: any) => {
  return [
    {
      NumberOfClasses: data?.NumberOfClasses || "0",
      dateFromTo: `${
        data.startDate ? helpers.formatDate(data.startDate) : "..."
      } - ${data.endDate ? helpers.formatDate(data.endDate) : "..."}`,
      excludedDates: take(CLASS_DATES, Number(data?.NumberOfClasses))
        ?.map((el: any, index: number) => {
          return moment(el?.date, FORMAT_DATE.query).format("DD MMM");
        })
        .join(", "),
      id: 13,
      key: 13,
    },
  ];
};

const getCancelClasses = (bookingCustomerChildList: any[]) => {
  let arr: any[] = [];
  bookingCustomerChildList
    ?.filter(
      (el: any) =>
        ![CancelType.ADMIN, CancelType.KIDZBIZ, CancelType.CUSTOMER]?.includes(
          el?.cancelType,
        ),
    )
    ?.forEach((item: any) => {
      item.classes?.forEach((e: any) => {
        if (e?.status === "CANCELLED") {
          arr = [
            ...arr,
            {
              ...e,
              customerChild: item?.firstName + " " + item?.lastName,
            },
          ];
        }
      });
    });
  return arr;
};

export const tableCourseCustomersColumns = (
  isBlocked: boolean,
  t: any,
  CancelElement: (data: any[], bookingReschedulingOutcome: any) => void,
  DetailElement: (id: string | number) => void,
  isOwn?: boolean,
  isCancel?: boolean,
) => {
  const columns = [
    {
      title: t("bizBase.name", {
        defaultValue: "Name",
      }),
      dataIndex: "customer",
      key: "customer",
      // ellipsis: !broken,
      render: (text: React.ReactNode, record: any) => {
        return (
          <div>
            <Popover
              overlayClassName={`popover-ellipsis ${
                !text ? "display-none" : ""
              }`}
              content={text}
              trigger="hover"
            >
              <div className="ellipsis">{text}</div>
            </Popover>
          </div>
        );
      },
    },
    {
      title: (
        <div>
          <i key={Math.random()} className={tb.HOME3} />
          {t("bizBase.location")}
        </div>
      ),
      dataIndex: "suburb",
      key: "suburb",
      render: (text: any) => (
        <Popover
          overlayClassName={`popover-ellipsis ${!text ? "display-none" : ""}`}
          content={text}
          trigger="hover"
        >
          <div className="ellipsis">
            <i
              className={tb.BUILDING}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            {text}
          </div>
        </Popover>
      ),
    },
    {
      title: (
        <div>
          <i key={Math.random()} className={tb.SMARTPHONE} />
          {t("bizBase.phone", {
            defaultValue: "Phone",
          })}
        </div>
      ),
      dataIndex: "phone",
      key: "phone",
      render: (text: any) => (
        <Popover
          overlayClassName={`popover-ellipsis ${!text ? "display-none" : ""}`}
          content={text}
          trigger="hover"
        >
          <div className="ellipsis">
            {text && (
              <i
                className={tb.SMARTPHONE}
                style={{ fontSize: "16px", marginRight: "8px" }}
              />
            )}
            {text}
          </div>
        </Popover>
      ),
    },
    {
      title: (
        <div>
          <i key={Math.random()} className={tb.MAIL} />
          {t("bizBase.email", {
            defaultValue: "Email",
          })}
        </div>
      ),
      key: "email",
      dataIndex: "email",
      render: (text: any) => (
        <Popover
          overlayClassName={`popover-ellipsis ${!text ? "display-none" : ""}`}
          content={text}
          trigger="hover"
        >
          <div className="ellipsis">
            {text && (
              <i
                className={tb.MAIL}
                style={{ fontSize: "16px", marginRight: "8px" }}
              />
            )}
            {text}
          </div>
        </Popover>
      ),
    },
    {
      title: (
        <div>
          <i key={Math.random()} className={tb.MAIL} />
          {t("bizBase.childName", {
            defaultValue: "Child name",
          })}
        </div>
      ),
      key: "childName",
      dataIndex: "childName",
      render: (text: any, record: any) => (
        <>
          {uniqBy(record?.childName, "id")?.map((item: any) => {
            const fullName = item?.firstName + " " + item?.lastName;
            return (
              <Popover
                key={item.id}
                overlayClassName={`popover-ellipsis ${
                  !fullName ? "display-none" : ""
                }`}
                content={fullName}
                trigger="hover"
              >
                <div className="ellipsis mr-10 bsl-5">
                  {fullName && (
                    <i
                      className={tb.USER5}
                      style={{ fontSize: "16px", marginRight: "8px" }}
                    />
                  )}
                  {fullName}
                  {!item?.medicalIssues ? "" : ", " + item?.medicalIssues}
                </div>
              </Popover>
            );
          })}
        </>
      ),
    },
    {
      key: "Cancellation",
      dataIndex: "Cancellation",
      className: "full-width",
      render: (text: any, record: any) => {
        const cancelByAdmin = record?.childName?.filter(
          (el: any) => el?.cancelType === CancelType.ADMIN,
        );
        const cancelByKidzbiz = record?.childName?.filter(
          (el: any) => el?.cancelType === CancelType.KIDZBIZ,
        );
        const cancelByCustomer = record?.childName?.filter(
          (el: any) => el?.cancelType === CancelType.CUSTOMER,
        );

        const cancelClasses = getCancelClasses(record?.childName);
        return (
          <div
            className="text-color-secondary"
            style={{
              display:
                cancelByKidzbiz
                  ?.concat(cancelByCustomer)
                  ?.concat(cancelClasses)
                  ?.concat(cancelByAdmin)?.length > 0
                  ? "block"
                  : "none",
            }}
          >
            {cancelByAdmin?.length > 0 && (
              <div className="bsl-5 flex-align-items">
                <img
                  src={CalendarCancel}
                  alt=""
                  style={{ marginRight: 8, height: 16, opacity: 0.6 }}
                />
                {t("courses.admin_course_cancellation_for_childs", {
                  childs: cancelByAdmin
                    ?.map((el: any) => el?.firstName + " " + el?.lastName)
                    ?.join(", "),
                }) +
                  " - " +
                  uniq(
                    cancelByAdmin?.map(
                      (el: any) =>
                        !!el?.cancelDate &&
                        moment(el?.cancelDate).format(FORMAT_DATE.dateDot),
                    ),
                  )?.join(", ")}
              </div>
            )}

            {cancelByKidzbiz?.length > 0 && (
              <div className="bsl-5 flex-align-items">
                <img
                  src={CalendarCancel}
                  alt=""
                  style={{ marginRight: 8, height: 16, opacity: 0.6 }}
                />
                {t("courses.kidzbiz_course_cancellation_for_childs", {
                  childs: cancelByKidzbiz
                    ?.map((el: any) => el?.firstName + " " + el?.lastName)
                    ?.join(", "),
                }) +
                  " - " +
                  uniq(
                    cancelByKidzbiz?.map(
                      (el: any) =>
                        !!el?.cancelDate &&
                        moment(el?.cancelDate).format(FORMAT_DATE.dateDot),
                    ),
                  )?.join(", ")}
              </div>
            )}
            {cancelByCustomer?.length > 0 && (
              <div className="bsl-5 flex-align-items">
                <img
                  src={CalendarCancel}
                  alt=""
                  style={{ marginRight: 8, height: 16, opacity: 0.6 }}
                />
                {t("rescheduingOutcomes.customer_course_cancellation_for", {
                  childs: cancelByCustomer
                    ?.map((el: any) => el?.firstName + " " + el?.lastName)
                    ?.join(", "),
                }) +
                  " - " +
                  uniq(
                    cancelByCustomer?.map(
                      (el: any) =>
                        !!el?.cancelDate &&
                        moment(el?.cancelDate).format(FORMAT_DATE.dateDot),
                    ),
                  )?.join(", ")}
              </div>
            )}

            {cancelClasses?.length > 0 &&
              record?.classes?.map((el: any) => {
                const current = cancelClasses?.filter(
                  (c) => c?.classId === el?.id,
                );
                if (current?.length === 0) return null;
                return (
                  <div key={el?.id} className="bsl-5 flex-align-items">
                    <img
                      src={CalendarCancel}
                      alt=""
                      style={{ marginRight: 8, height: 16, opacity: 0.6 }}
                    />
                    {t("rescheduingOutcomes.absence_requested_for", {
                      childs: current
                        ?.map((el: any) => el?.customerChild)
                        ?.join(", "),
                    }) +
                      " - " +
                      moment(helpers.getServerDate(el?.date)).format(
                        FORMAT_DATE.dateDot,
                      )}
                  </div>
                );
              })}
          </div>
        );
      },
    },

    {
      key: "Action",
      dataIndex: "Action",
      className: "full-width",
      render: (text: any, allData: any) => {
        const cancelByAdmin = allData?.childName?.filter(
          (el: any) => el?.cancelType === CancelType.ADMIN,
        );

        const cancelByKidzbiz = allData?.childName?.filter(
          (el: any) => el?.cancelType === CancelType.KIDZBIZ,
        );
        const cancelByCustomer = allData?.childName?.filter(
          (el: any) => el?.cancelType === CancelType.CUSTOMER,
        );
        const allCancel = cancelByKidzbiz
          ?.concat(cancelByCustomer)
          ?.concat(cancelByAdmin);
        return (
          !isBlocked && (
            <div
              className={"full-width"}
              style={{
                marginBottom: "32px",
                maxWidth: "800px",
                width: "calc(100vw - 100px)",
              }}
            >
              <ButtonLink
                onClick={() => {
                  DetailElement(allData?.id || 1);
                }}
                className="mr-25"
              >
                {t("courses.details")}
              </ButtonLink>
              {isOwn &&
                isCancel &&
                allCancel?.length < allData?.childName?.length && (
                  <ButtonLink
                    onClick={() => {
                      CancelElement(
                        allData?.childName || [],
                        allData?.bookingReschedulingOutcome,
                      );
                    }}
                  >
                    {t("bizBase.cancel")}
                  </ButtonLink>
                )}
            </div>
          )
        );
      },
    },
  ];

  return columns;
};
export const tableCourseCustomersData = (data?: any, classes?: any) => {
  return {
    customer: data.firstName + " " + data?.lastName,
    suburb: data.suburb,
    phone: data.mobile,
    email: data.email,
    childName: data?.children,
    classes,
    refundDate: data?.RefundDate,
    bookingReschedulingOutcome: data?.bookingReschedulingOutcome,
    id: data.id,
    key: Math.random(),
  };
};

export const tableCoursesPromColumns = (isBlocked: boolean, t: any) => {
  const colData = [
    {
      dataIndex: "promotionName",
      key: "promotionName",
    },
    {
      key: "discount",
      dataIndex: "discount",
      render: (text: any, record: any) =>
        text && (
          <div>
            <i
              className={tb.GIFT2}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            {text}
          </div>
        ),
    },
    {
      key: "dateFromTo",
      dataIndex: "dateFromTo",
      render: (text: any) =>
        text && (
          <div>
            <i
              className={tb.CALENDARTODO}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            {text}
          </div>
        ),
    },
    {
      key: "Action",
      dataIndex: "Action",
      className: "full-width",
      render: (a: any, record: any) =>
        !isBlocked &&
        record?.isLegalName && (
          <div
            className={"full-width"}
            style={{
              marginTop: "13px",
              marginBottom: "9px",
            }}
          >
            <Link to={`/promotions/edit-course/${record.id}`}>
              {t("courses.goToPromotion", {
                defaultValue: "Go to Promotion",
              })}
            </Link>
          </div>
        ),
    },
  ];

  return colData;
};

export const tableCoursesPromotionsData = (
  t: any,
  legalName: string,
  data: any[],
) => {
  return data.map((el) => {
    return {
      promotionName: el?.name || "-",
      discount: `${
        el?.type === PromotionType.FreeClass
          ? t("promo." + el?.type)
          : el?.discountPercentageOrValue + "%"
      }`,
      dateFromTo: `${
        el?.effectiveFrom
          ? helpers.formatDate(helpers.getServerDate(el?.effectiveFrom))
          : "..."
      } - ${
        el?.effectiveTo
          ? helpers.formatDate(helpers.getServerDate(el?.effectiveTo))
          : "..."
      }`,
      isLegalName:
        legalName === el?.courseIdCodeNameList?.[0]?.providerLegalName
          ? true
          : false,
      // TODO: NEED PROMOTIONS ID FROM SERVER HERE:
      id: el?.id,
      key: Math.random() + el?.id,
    };
  });
};

export const tableCourseLocationColumns = (
  broken?: boolean,
  ActionEdit?: React.FC<{ record: any }>,
) => {
  const columns = [
    {
      dataIndex: "name",
      key: "name",
      render: (text: any, record: any) => (
        <div>
          <i
            className={tb.PIN}
            style={{ fontSize: "16px", marginRight: "8px" }}
          />
          {text || "-"}
        </div>
      ),
    },
    {
      key: "stateProvince",
      dataIndex: "stateProvince",
      render: (value: any, record: any) => (
        <div>
          <i
            className={tb.BUILDING}
            style={{ fontSize: "16px", marginRight: "8px" }}
          />
          {[record?.address?.suburb, record?.address?.area]?.join(", ")}
        </div>
      ),
    },
    {
      key: "email",
      dataIndex: "email",
      render: (text: any, record: any) =>
        !!text && (
          <div>
            <i
              className={tb.MAIL}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            {text || "-"}
          </div>
        ),
    },
    {
      key: "mobile",
      dataIndex: "mobile",
      render: (text: any, record: any) =>
        !!text && (
          <div>
            <i
              className={tb.SMARTPHONE}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            {text || "-"}
          </div>
        ),
    },
  ];

  return columns;
};
//CHILDRENS

export const tableCourseCustomerChildrensColumns = (
  isChecked?: boolean,
  checked?: number[],
  onChecked?: (val: number) => void,
  isOnce?: boolean,
) => {
  const { t } = useTranslation();
  const columns = [
    {
      dataIndex: "name",
      key: "name",
      render: (text: React.ReactNode, record: any) => {
        return (
          <div className="flex-align-items">
            <Popover
              overlayClassName={`popover-ellipsis ${
                !text ? "display-none" : ""
              }`}
              content={text}
              trigger="hover"
            >
              <div className="ellipsis">{text}</div>
            </Popover>
            {isChecked && !isOnce && (
              <Checkbox
                checked={checked?.includes(record?.id)}
                onClick={() => onChecked?.(record?.id)}
              />
            )}
          </div>
        );
      },
    },
    {
      dataIndex: "age",
      key: "age",
      render: (text: any, record: any) => (
        <Popover
          overlayClassName={`popover-ellipsis ${!text ? "display-none" : ""}`}
          content={text}
          trigger="hover"
        >
          <div className="ellipsis">
            <i
              className={tb.AGE}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            {Number(text) > 0
              ? text + " " + t("courses.years")
              : record?.month + " " + t("courses.months")}
          </div>
        </Popover>
      ),
    },
    {
      dataIndex: "birthday",
      key: "birthday",
      render: (text: any) =>
        !isChecked && (
          <Popover
            overlayClassName={`popover-ellipsis ${!text ? "display-none" : ""}`}
            content={text}
            trigger="hover"
          >
            <div className="ellipsis">
              {text && (
                <i
                  className={tb.DATECHECK}
                  style={{ fontSize: "16px", marginRight: "8px" }}
                />
              )}
              {text}
            </div>
          </Popover>
        ),
    },
    {
      key: "school",
      dataIndex: "school",
      render: (text: any) => (
        <Popover
          overlayClassName={`popover-ellipsis ${!text ? "display-none" : ""}`}
          content={text}
          trigger="hover"
        >
          <div className="ellipsis">
            {text && (
              <i
                className={tb.COMMUNITY}
                style={{ fontSize: "16px", marginRight: "8px" }}
              />
            )}
            {text}
          </div>
        </Popover>
      ),
    },
    {
      key: "medical",
      dataIndex: "medical",
      render: (text: any) =>
        !isChecked && (
          <Popover
            overlayClassName={`popover-ellipsis ${!text ? "display-none" : ""}`}
            content={text}
            trigger="hover"
          >
            <div className="ellipsis">
              {text && (
                <i
                  className={tb.BOOKHEALTH}
                  style={{ fontSize: "16px", marginRight: "8px" }}
                />
              )}
              {text}
            </div>
          </Popover>
        ),
    },
  ];

  return columns;
};
export const tableCourseCustomerChildrensData = (data?: any) => {
  console.log(getAgeChild(data?.dateOfBirth)?.year);
  return {
    name: data.firstName + " " + data?.lastName,
    age: getAgeChild(data?.dateOfBirth)?.year,
    month: getAgeChild(data?.dateOfBirth)?.month,
    birthday: data?.dateOfBirth
      ? helpers.formatDate(helpers?.getServerDate(data.dateOfBirth))
      : "",
    school: data.school,
    medical: data.medical,
    id: data?.id,
    key: Math.random(),
  };
};
