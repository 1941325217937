import React, { useCallback, useContext, useState } from "react";
import "./index.less";
import { LayoutPage } from "../../../components/Layout";
import { observer } from "mobx-react-lite";
import ButtonLink from "../../../components/ButtonLink";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { AccountSettingsRoute } from "../../../routes";
import { Button, Form, Input, message, notification } from "antd";
import { RuleObject } from "antd/lib/form";
import { useTranslation } from "react-i18next";
import userStore, { PasswordUpdateType } from "scripts/mobx/userStore";

const ChangePassword = observer((props: RouteComponentProps) => {
  const { history } = props;
  const { t } = useTranslation("base");

  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled1, setIsDisabled1] = useState(true);
  const [isDisabled2, setIsDisabled2] = useState(true);
  const [step, setStep] = useState(1);
  const [form] = Form.useForm();
  const [currentPassword, setCurrentPassword] = useState("");

  const handleOnBack = () => history.push("/account-settings");

  const onFinish = (values: any) => {
    form
      .validateFields([Object.keys(values)])
      .then(async () => {
        setCurrentPassword(values.oldPassword);
        if (step === 1) {
          await setIsLoading(false);
          await setStep(2);
        } else if (step === 2) {
          if (values.newPassword !== currentPassword) {
            const model: PasswordUpdateType = {
              newPassword: values.newPassword,
              currentPassword: currentPassword,
            };
            userStore
              ?.updateUserPassword(model)
              .then(() => {
                message.success(
                  t("accountSettings.messages.password_changed_successfully", {
                    defaultValue: "Password changed successfully",
                  }),
                );
                setTimeout(() => handleOnBack(), 300);
              })
              .catch((error) => {
                console.log(error);

                if (
                  error?.data?.error ===
                  "com.bizibuz.core.service.iam.AuthorizationException"
                ) {
                  notification.error({
                    message: error?.data?.error
                      ? t(
                          "accountSettings.messages.current_password_is_wrong",
                          { defaultValue: "Current password is wrong" },
                        )
                      : t("messages.some_error_occurred", {
                          defaultValue: "Some error occurred",
                        }),
                  });
                } else {
                  notification.error({
                    message: t("server_errors." + "Bad Request"),
                  });
                }
              });
          }
          await setIsLoading(false);
        }
      })
      .catch((e) => {
        const { _message } = e;
        message.error(_message || e.message);
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const validateForm1 = useCallback(
    (field?: string) => {
      form
        .validateFields(field ? [field] : undefined)
        .then(
          (r) => {
            setIsDisabled1(false);
          },
          (rej) => {
            setIsDisabled1(true);
          },
        )
        .catch((e) => {
          const { _message } = e;
          message.error(
            _message ||
              t("messages.some_error_occurred", {
                defaultValue: "Some error occurred",
              }),
          );
        });
    },
    [isDisabled1],
  );
  const validateForm2 = useCallback(
    (field?: string) => {
      form
        .validateFields(field ? [field] : undefined)
        .then(
          (r) => {
            setIsDisabled2(false);
          },
          (rej) => {
            setIsDisabled2(true);
          },
        )
        .catch((e) => {
          const { _message } = e;
          message.error(
            _message ||
              t("messages.some_error_occurred", {
                defaultValue: "Some error occurred",
              }),
          );
        });
    },
    [isDisabled2],
  );
  return (
    <LayoutPage>
      <div className="account-settings-change-password biz-view-content ">
        <div className="biz-back-btn">
          <ButtonLink
            onClick={() => {
              history.push(AccountSettingsRoute.path);
            }}
          >
            <i className={"ri-arrow-left-s-line"} />
            {t("accountSettings.BackToAccountSettings", {
              defaultValue: "Back to Account Settings",
            })}
          </ButtonLink>
        </div>
        <div className="head-title bsl-4">
          {t("accountSettings.changePassword", {
            defaultValue: "Change Password",
          })}
        </div>
        <div className={"bsl-4"} />
        {step === 1 && (
          <Form
            form={form}
            onLoad={() => validateForm1()}
            name="change-password-step-1"
            className="change-password-form"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
          >
            <Form.Item className="bsl-4">
              <div className="label-wrapper">
                <label htmlFor="oldPassword">
                  {t("accountSettings.enterOldPassword", {
                    defaultValue: "Enter old password",
                  })}
                </label>
              </div>
              <Form.Item
                name="oldPassword"
                rules={[
                  {
                    required: true,
                    message: t("accountSettings.messages.required_password", {
                      defaultValue: "Please input your Password!",
                    }),
                  },
                ]}
              >
                <Input.Password
                  id={"oldPassword"}
                  prefix={<i className="ri-lock-2-line mr-10 fs-20" />}
                  type="password"
                  onBlur={() => validateForm1("oldPassword")}
                  readOnly={isLoading}
                  size={"large"}
                  iconRender={(visible) =>
                    visible ? (
                      <span
                        style={{
                          fontSize: "18px",
                          lineHeight: "18px",
                          color: "rgba(34, 34, 34, 0.35)",
                        }}
                      >
                        <i className="ri-eye-off-line fs-20" />
                      </span>
                    ) : (
                      <span
                        style={{
                          fontSize: "18px",
                          lineHeight: "18px",
                          color: "rgba(34, 34, 34, 0.35)",
                        }}
                      >
                        <i className="ri-eye-line fs-20" />
                      </span>
                    )
                  }
                  placeholder={t("accountSettings.password", {
                    defaultValue: "Password",
                  })}
                />
              </Form.Item>
            </Form.Item>

            <Form.Item>
              <Button
                className="full-width fs-16"
                type="primary"
                htmlType="submit"
                loading={isLoading}
                disabled={isDisabled1 || isLoading}
                icon={
                  <div className={"flex-center"}>
                    {t("accountSettings.changePassword2", {
                      defaultValue: "Change password",
                    })}{" "}
                    <i className="ri-arrow-right-line ml-10 fs-20" />
                  </div>
                }
              />
            </Form.Item>
          </Form>
        )}
        {step === 2 && (
          <Form
            form={form}
            onLoad={() => validateForm2()}
            name="change-password2"
            className="change-password-form"
            onFinish={onFinish}
          >
            <Form.Item className="bsl-4">
              <div className="label-wrapper">
                <label htmlFor="newPassword">
                  {t("accountSettings.enterNewPassword", {
                    defaultValue: "Enter new Password",
                  })}
                </label>
              </div>
              <Form.Item
                name="newPassword"
                rules={[
                  {
                    required: true,
                    message: t("accountSettings.messages.required_password", {
                      defaultValue: "Please input your Password!",
                    }),
                  },
                ]}
              >
                <Input.Password
                  id={"newPassword"}
                  prefix={<i className="ri-lock-2-line mr-10" />}
                  type="password"
                  size={"middle"}
                  readOnly={isLoading}
                  onBlur={() => validateForm2("newPassword")}
                  iconRender={(visible) =>
                    visible ? (
                      <span
                        style={{
                          fontSize: "18px",
                          lineHeight: "18px",
                          color: "rgba(34, 34, 34, 0.35)",
                        }}
                      >
                        <i className="ri-eye-off-line" />
                      </span>
                    ) : (
                      <span
                        style={{
                          fontSize: "18px",
                          lineHeight: "18px",
                          color: "rgba(34, 34, 34, 0.35)",
                        }}
                      >
                        <i className="ri-eye-line" />
                      </span>
                    )
                  }
                  placeholder={t("accountSettings.enterPassword", {
                    defaultValue: "Enter password",
                  })}
                />
              </Form.Item>
            </Form.Item>
            <Form.Item className="bsl-4">
              <div className="label-wrapper">
                <label htmlFor="confirmNewPassword">
                  {t("accountSettings.confirmNewPassword", {
                    defaultValue: "Confirm new password",
                  })}
                </label>
              </div>
              <Form.Item
                name="confirmNewPassword"
                dependencies={["newPassword"]}
                rules={[
                  {
                    required: true,
                    message: t(
                      "accountSettings.messages.required_password_confirm",
                      { defaultValue: "Please confirm your password!" },
                    ),
                  },
                  ({ getFieldValue }) => {
                    return {
                      validator(rule: RuleObject, value: any) {
                        if (!value || getFieldValue("newPassword") === value) {
                          return Promise.resolve();
                        }

                        return Promise.reject(
                          "The two passwords that you entered do not match!",
                        );
                      },
                    };
                  },
                ]}
              >
                <Input.Password
                  id={"confirmNewPassword"}
                  prefix={<i className="ri-lock-2-line mr-10" />}
                  type="password"
                  size={"middle"}
                  readOnly={isLoading}
                  onBlur={() => validateForm2("confirmNewPassword")}
                  iconRender={(visible) =>
                    visible ? (
                      <span
                        style={{
                          fontSize: "18px",
                          lineHeight: "18px",
                          color: "rgba(34, 34, 34, 0.35)",
                        }}
                      >
                        <i className="ri-eye-off-line" />
                      </span>
                    ) : (
                      <span
                        style={{
                          fontSize: "18px",
                          lineHeight: "18px",
                          color: "rgba(34, 34, 34, 0.35)",
                        }}
                      >
                        <i className="ri-eye-line" />
                      </span>
                    )
                  }
                  placeholder={t("accountSettings.enterPasswordAgain", {
                    defaultValue: "Enter password again",
                  })}
                />
              </Form.Item>
            </Form.Item>
            <Form.Item>
              <Button
                style={{
                  width: "100%",
                  lineHeight: "16px",
                  fontSize: "16px",
                }}
                type="primary"
                htmlType="submit"
                loading={isLoading}
                icon={
                  <>
                    {t("accountSettings.changePassword2", {
                      defaultValue: "Change password",
                    })}{" "}
                    <i className="ri-arrow-right-line ml-10" />
                  </>
                }
              />
            </Form.Item>
          </Form>
        )}
      </div>
    </LayoutPage>
  );
});

export default withRouter(ChangePassword);
