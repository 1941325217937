function getPosition(options?: PositionOptions): Promise<GeolocationPosition> {
  return new Promise((resolve, reject) =>
    navigator.geolocation.watchPosition(resolve, reject, options),
  );
}

export const suggestLocation = async () => {
  let mLocation: "Other" | "Hong Kong" | "Singapore" = "Other";

  try {
    const location = await getPosition();
    const isInHongKong =
      location &&
      location.coords.latitude >= 22.1365 &&
      location.coords.latitude <= 22.5659 &&
      ((location.coords.longitude >= -246.182 &&
        location.coords.longitude <= 245.5002) ||
        (location.coords.longitude >= 113.818 &&
          location.coords.longitude <= 114.4998));
    if (isInHongKong) mLocation = "Hong Kong";

    // https://latitudelongitude.org/sg/
    // https://stackoverflow.com/questions/38213451/twitter4j-geo-streaming-latitude-longitude-for-singapore-and-beyond
    const isInSingapore =
      location &&
      location.coords.latitude >= 1.1158 &&
      location.coords.latitude <= 1.47062 &&
      location.coords.longitude >= 103.618248 &&
      location.coords.longitude <= 104.40847;

    if (isInSingapore) mLocation = "Singapore";
  } catch (error) {}

  return mLocation;
};
