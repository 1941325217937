import { Spin } from "antd";
import { HeadSeo } from "components/HeadSeo";
import { PaymentStatus } from "constants/type.interface";
import { AppRoutes } from "helpers/app.routes";
import Cookies from "js-cookie";
import { observer } from "mobx-react-lite";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import helpers from "scripts/helpers";
import providerOrdersStore from "scripts/mobx/providerOrdersStore";
import ButtonLink from "../../../components/ButtonLink";
import { LayoutPage } from "../../../components/Layout";
import { bizIcons } from "../../../constants/bizConstants";
import PayViewKidzbizComponent from "../payViewKidzbizComponent";

const PayViewKidzbizPage = observer((props: RouteComponentProps) => {
  const { history, match } = props;
  const { t } = useTranslation();
  const [detailsData, setDetailsData] = useState<any>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // @ts-ignore
  const id = props.match.params.id;
  // @ts-ignore
  const code = props.match.params.code;

  useEffect(() => {
    setIsLoading(true);
    providerOrdersStore
      ?.getOrgPayoutDetailsById(Number(id), code)
      .then((el: any) => {
        setDetailsData(el);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        helpers.throwErrors(t, [], error?.data?.error, error?.data?.message);
      });
  }, []);

  return (
    <>
      <HeadSeo title="" />
      <LayoutPage defMenuItem={"payments"}>
        <Spin spinning={isLoading} size="large">
          <div className="content-with-table">
            <div className={"back-block  bsl-4"}>
              <ButtonLink
                onClick={() =>
                  history.push(
                    AppRoutes.payments + "?tab=" + Cookies.get("paymentTab"),
                  )
                }
              >
                <i
                  style={{ fontSize: "20px", paddingRight: "8px" }}
                  className={bizIcons.ARROWLEFT}
                />
                {t("payments.back_to_payments")}
              </ButtonLink>
            </div>
            <PayViewKidzbizComponent
              t={t}
              _data={detailsData}
              providerId={Number(id)}
              code={code}
            />
          </div>
        </Spin>
      </LayoutPage>
    </>
  );
});

export default withRouter(PayViewKidzbizPage);
