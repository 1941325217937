import QueryString from "constants/query-string";
import { AppRoutes } from "helpers/app.routes";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useState } from "react";
import { RouteComponentProps, useHistory, withRouter } from "react-router-dom";
import {
  useCurrentVerify,
  userBlocked,
} from "scripts/custom-hooks/useCurrentUser";
import { useQueryParams } from "scripts/custom-hooks/useQueryParams";
import courseStore from "scripts/mobx/courseStore";
import locationsStore from "scripts/mobx/locationStore";
import { CreateCourseBaseComponent } from "./CreateCourseBaseComponent";
import { CreateCourseByCopy } from "./CreateCourseByCopy";
import { CreateCourseByXLS } from "./CreateCourseByXLS";
import "./index.less";
import { ManCourseCreateWrapper } from "./ManCourseCreateWrapper";
import AddPaymentMethod from "./AddPaymentMethod";

interface IProps extends RouteComponentProps {
  backToCourseHandler: () => void;
  goToFAQHandler: () => void;
  isLoading: boolean;
  isEdit?: boolean;
  editId?: number;
}

export enum createStatus {
  MANUALLY = "MANUALLY",
  BYCOPY = "BYCOPY",
  BYXLS = "BYXLS",
  BYRECOGNITION = "BYRECOGNITION",
  ADD_PAYMENT_METHOD = "add_payment_method",
}

interface IData {
  id?: number;
}

export const CreateCoursesWrapper = withRouter(
  inject("userStore")(
    observer((props: IProps) => {
      const { backToCourseHandler, goToFAQHandler, isLoading, isEdit, editId } =
        props;
      const history = useHistory();
      const isBlocked = userBlocked();
      const isVerified = useCurrentVerify();
      const queryParams = useQueryParams();
      const [status, setStatus] = useState<string | undefined>();
      const [dataToSet, setDataToSet] = useState<IData | undefined>();
      useEffect(() => {
        if (isBlocked && !isEdit) {
          history.push(AppRoutes.courses);
        }
      }, [isBlocked]);

      useEffect(() => {
        locationsStore.getLocation();
        courseStore.getCategories();
        if (isEdit && editId) {
          setDataToSet({ id: editId });
          setStatus(createStatus.MANUALLY);
        }
      }, []);

      useEffect(() => {
        if (!!queryParams.get("action")) {
          setStatus(queryParams.get("action") || "");
        }
      }, [queryParams.get("action")]);

      const setStatusHandler = useCallback(
        (_status?: createStatus) => {
          setStatus(_status);
          history.replace({
            pathname: location?.pathname,
            search: QueryString.stringify({
              action: _status,
            }),
          });
        },
        [status],
      );

      const clearDataToSet = () => {
        setDataToSet(undefined);
      };

      const copyCourseHandler = async (id: number) => {
        await setDataToSet({ id });
        await setStatus(createStatus.MANUALLY);
      };

      const backToCourseCreateHandler = () => {
        setStatus(undefined);
      };

      return (
        <>
          {(!status || status === createStatus.ADD_PAYMENT_METHOD) && (
            <CreateCourseBaseComponent
              backToCourseHandler={backToCourseHandler}
              setStatusHandler={setStatusHandler}
              goToFAQHandler={goToFAQHandler}
              isLoading={isLoading}
            />
          )}
          {status === createStatus.MANUALLY && (
            <ManCourseCreateWrapper
              isLoading={isLoading}
              data={dataToSet}
              isEdit={isEdit}
              clearDataToSet={clearDataToSet}
            />
          )}
          {isVerified && status === createStatus.BYCOPY && (
            <CreateCourseByCopy
              isLoading={isLoading}
              copyCourseHandler={copyCourseHandler}
              backToCourseCreateHandler={backToCourseCreateHandler}
            />
          )}
          {isVerified && status === createStatus.BYXLS && (
            <CreateCourseByXLS
              backToCourseCreateHandler={backToCourseCreateHandler}
            />
          )}

          <AddPaymentMethod
            onCancel={() => setStatusHandler()}
            visible={status === createStatus.ADD_PAYMENT_METHOD}
          />
        </>
      );
    }),
  ),
);
