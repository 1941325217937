import React, { useState } from "react";
import { LayoutPage } from "../../components/Layout";
import { Divider, Typography } from "antd";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import ButtonLink from "../../components/ButtonLink";
import { bizIcons } from "../../constants/bizConstants";
import UnverifiedBlock from "./unverifiedBlock";
import { useCurrentUser } from "scripts/custom-hooks/useCurrentUser";

interface IKidzBizProps extends RouteComponentProps {
  providerStore?: any;
}
const { Title } = Typography;

const VerifyPage = inject("userStore")(
  observer((props: IKidzBizProps) => {
    const { history, providerStore } = props;
    const user = useCurrentUser();  
    const vSt = user?.providerAttributes?.verified;

    return (
      <LayoutPage
        defMenuItem={"kidzbiz"}
      >
        <div className="content-with-table">
          <div className={"back-block bsl-4"}>
            <ButtonLink onClick={() => history.goBack()}>
              <i
                style={{ fontSize: "20px", paddingRight: "8px" }}
                className={bizIcons.ARROWLEFT}
              />
              Back
            </ButtonLink>
          </div>
          <div className={"bsl-4"}>
            <Title level={2}>Request verification</Title>
          </div>
          {!vSt && (
            <div className={"text-color-secondary bsl-4"}>
              Please provide your 7-digit incorporation number and upload a
              certified copy of your incorporation certificate. Please ensure
              these details match, and that the document is legible and all
              edges are visible. Your information will be kept confidential and
              is required for platform participation.
              <Divider />
            </div>
          )}
          {!vSt && <UnverifiedBlock />}
        </div>
      </LayoutPage>
    );
  }),
);

export default withRouter(VerifyPage);
