import React, { useEffect, useState } from "react";
import { Typography } from "antd";
import TableComponent from "../../../components/TableComponent/TableComponent";
import { observer } from "mobx-react-lite";
import { DEVICES, useDevices } from "../../../scripts/custom-hooks/useDevices";
import { tableReviewsColumns } from "../tableGenerator";
import providerReviewsStore from "../../../scripts/mobx/providerReviewsStore";
import { ReviewsFilters } from "../filters";
import { toJS } from "mobx";
import { useTranslation } from "react-i18next";
import { HeadSeo } from "components/HeadSeo";
import { useCurrentUser } from "scripts/custom-hooks/useCurrentUser";
import reviewsStore, { pageable } from "scripts/mobx/reviewsStore";
import { omit } from "lodash";
import { FORMAT_DATE } from "constants/commons";
import moment from "moment";
import { PAGE_SIZE } from "config";
const { Title } = Typography;

export const ContentWithTableReviews = observer(() => {
  const { t } = useTranslation();
  const user = useCurrentUser();
  const [isBroken, setIsBroken] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean | undefined>(true);
  const device = useDevices();
  const pRData = toJS(reviewsStore.reviewsData);
  const filterModel = toJS(reviewsStore?.filterModel);

  const getReviews = async (filter?: any) => {
    setIsLoading(true);
    await reviewsStore?.getReviews(filter).finally(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    (async () => {
      getReviews({ ...filterModel });
    })();
  }, []);

  useEffect(() => {
    if (
      device &&
      (device === DEVICES.MOBILE ||
        device === DEVICES.TABLET_PORT ||
        device === DEVICES.TABLET_LAND) &&
      !isBroken
    ) {
      setIsBroken(true);
    } else if (
      device &&
      device !== DEVICES.MOBILE &&
      device !== DEVICES.TABLET_PORT &&
      device !== DEVICES.TABLET_LAND &&
      isBroken
    ) {
      setIsBroken(false);
    }
  }, [device]);

  const onPageChangeHandler = (page: any) => {
    getReviews({
      ...filterModel,
      pageable: {
        ...filterModel?.pageable,
        pageNumber: page - 1,
      },
    });
  };
  const updateFilter = (data?: any) => {
    console.log(data);

    let params = {
      ...filterModel,
      pageable: pageable,
    };

    if (data?.dateFrom) {
      Object.assign(params, {
        dateFrom: moment(data?.dateFrom)?.format(FORMAT_DATE.query),
      });
    } else {
      params = omit(params, ["dateFrom"]);
    }
    if (data?.dateTo) {
      Object.assign(params, {
        dateTo: moment(data?.dateTo)?.format(FORMAT_DATE.query),
      });
    } else {
      params = omit(params, ["dateTo"]);
    }

    if (data?.categories) {
      Object.assign(params, {
        category: data?.categories,
      });
    } else {
      params = omit(params, ["category"]);
    }

    if (data?.suburbs) {
      Object.assign(params, {
        location: data?.suburbs,
      });
    } else {
      params = omit(params, ["location"]);
    }

    if (data?.providerId) {
      Object.assign(params, {
        provider: data?.providerId,
      });
    } else {
      params = omit(params, ["provider"]);
    }

    setTimeout(() => {
      getReviews(params);
    }, 200);
  };

  return (
    <>
      <HeadSeo title={t("menu.reviews")} />
      <div className={`content-with-table`}>
        <div className={`cwt_head  no-search`}>
          <Title className={"title"}>{t("menu.reviews")}</Title>
          <ReviewsFilters onApply={updateFilter} userType={user?.isLinked} />
        </div>
        <div className={"cwt_table tsl-4"}>
          <TableComponent
            style={{ opacity: `${isLoading ? "0.4" : 1}` }}
            broken={isBroken}
            emptyTableContent={{
              text: t("reviews.empty_list"),
            }}
            isLoading={isLoading}
            propsTableColumns={tableReviewsColumns(t, isBroken, user?.isLinked)}
            propsTableData={
              pRData?.data?.map((el: any) => {
                return { ...el, key: Math.random() };
              }) || undefined
            }
            pagination={
              pRData?.totalCount > PAGE_SIZE
                ? {
                    total: pRData?.totalCount,
                    pageSize: pRData?.pageSize,
                    page: pRData?.pageNumber + 1,
                    onChange: onPageChangeHandler,
                  }
                : false
            }
          />
        </div>
      </div>
    </>
  );
});
