import { createContext } from "react";
import { action, computed, observable } from "mobx";
import { NotificationStatus, NotificationType } from "constants/type.interface";
import requestApi from "helpers/requestApi";
import { DETAIL_API_URL } from "constants/apiUrl";

export class NotificationsStore {
  public isConnected: boolean = false;

  @observable
  public notificationSettings: any = {};

  @observable
  private notifications: NotificationType[] = [];

  @action
  public connectNotificationsHub() {
    //
  }

  @action
  public async getNotificationSettings(): Promise<any> {
    const result = await requestApi.get(DETAIL_API_URL.NOTI_SETTING);
    if (result?.status === 200) {
      // console.log(result.data);
      const data = {};
      result.data?.forEach((el: any) => {
        Object.assign(data, {
          [el?.type + "_IN_APP"]: el?.inAppSetting,
          [el?.type + "_EMAIL"]: el?.emailSetting,
          [el?.type + "_SMS"]: el?.smsSetting,
        });
      });
      const datas = { settings: result?.data, initData: data };
      this.notificationSettings = datas;
      return Promise.resolve(datas);
    } else {
      return Promise.reject();
    }
  }

  @action
  public async updateNotificationSettings(data: any): Promise<any> {
    const result = await requestApi.put(
      DETAIL_API_URL.NOTI_SETTING_UPDATE,
      data,
    );
    if (result?.status === 200) {
      return Promise.resolve();
    } else {
      return Promise.reject();
    }
  }

  @action
  public async getNotifications(): Promise<any> {
    const result = await requestApi.get(DETAIL_API_URL.NOTI_LIST);
    if (result?.status === 200) {
      this.notifications = result.data;
      return Promise.resolve(result?.data);
    } else {
      return Promise.reject();
    }
  }

  @action
  public async notificationsMarkRead(id: number): Promise<any> {
    const result = await requestApi.put(DETAIL_API_URL.NOTI_SET_STATUS(id));
    if (result?.status === 200) {
      // this.getNotifications();
      const list = await requestApi.get(DETAIL_API_URL.NOTI_LIST);
      return Promise.resolve(list?.data);
    } else {
      return Promise.reject();
    }
  }

  @computed
  public get notificationData() {
    return this.notifications;
  }
}

export const notificationsStoreContext = createContext(NotificationsStore);
const notificationsStore = new NotificationsStore();

export default notificationsStore;
