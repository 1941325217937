export const LOCATION_ONLINE_ID = 0;
export const LOCATION_PARENT_HOME_ID = -2;
export const LOCATION_ON_DEMAND_ID = -3;

export function isSpecialLocation(course: any) {
  return [
    LOCATION_ON_DEMAND_ID,
    LOCATION_PARENT_HOME_ID,
    LOCATION_ONLINE_ID,
  ].includes(course?.locations?.[0]?.address?.id);
}

export function translatedKeyForSpecialLocation(course: any) {
  switch (course?.locations?.[0]?.address?.id) {
    case LOCATION_ONLINE_ID:
      return "courses.online";
    case LOCATION_PARENT_HOME_ID:
      return "bizBase.parentHome";
    case LOCATION_ON_DEMAND_ID:
      return "bizBase.onDemand";
    default:
      return "";
  }
}

export function isOnDemandCourse(course: any) {
  return course?.locations?.[0]?.address?.id === LOCATION_ON_DEMAND_ID;
}
