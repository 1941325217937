import { PAGE_SIZE } from "config";
import { DETAIL_API_URL } from "constants/apiUrl";
import { ResponseStatusCode } from "constants/commons";
import { PromoBodyParams, PromotionTab } from "constants/type.interface";
import requestApi from "helpers/requestApi";
import { concat } from "lodash";
import { action, computed, makeObservable, observable, toJS } from "mobx";
import { createContext } from "react";
import { UserType } from "./userStore";

export class PromotionsStore {
  constructor() {
    makeObservable(this);
  }

  @observable
  private promotionsData: any = [];

  @observable
  private coursePromotionsData: any = [];

  @observable
  private filterData = { page: 1, pageSize: PAGE_SIZE };

  @observable
  public errorHandler: string = "";

  @observable
  public filterModel: any = {
    pageable: {
      pageNumber: 1,
      pageSize: PAGE_SIZE,
      sortField: "createdAt",
      sortDirection: "desc",
    },
    state: "Current",
  };

  @action
  public async getPromotions(filter?: any) {
    const result = await requestApi.get(DETAIL_API_URL.PROMOTION_LIST, filter);
    if (result?.status === 200) {
      this.promotionsData = {
        items: result?.data,
      };
      return Promise.resolve(result?.data);
    } else {
      return Promise.reject();
    }
  }

  @action
  public async getCoursePromotions(filter?: any) {
    const result = await requestApi.get(
      DETAIL_API_URL.PROMOTION_COURSE_LIST,
      filter,
    );
    if (result?.status === 200) {
      const master =
        result?.data?.providerPromotions?.map((el: any) => {
          return {
            ...el,
            isEdit: true,
          };
        }) || [];
      const satellite = result?.data?.satelliteProviderPromotions
        ? result?.data?.satelliteProviderPromotions?.map((el: any) => {
            return {
              ...el,
              isEdit: false,
            };
          })
        : [];
      this.coursePromotionsData = {
        items: master.concat(satellite),
      };
      return Promise.resolve(result?.data);
    } else {
      return Promise.reject();
    }
  }

  @action
  public async clearPromotions() {
    this.promotionsData = {
      items: [],
    };
    this.coursePromotionsData = {
      items: [],
    };
  }

  @action
  public async getPromotionById(id: number) {
    const result = await requestApi.get(DETAIL_API_URL.PROMOTION_LIST);
    if (result?.status === 200) {
      const dataPromo = result?.data;
      const data = dataPromo.filter((el: any) => el.id === id)?.[0];
      if (data) {
        return Promise.resolve(data);
      } else {
        return Promise.reject(result);
      }
    }
  }

  @action
  public async getCoursePromotionById(id: number) {
    const result = await requestApi.get(
      `${DETAIL_API_URL.PROMOTION_COURSE}/${id}`,
    );

    if (result?.status === 200) {
      const data = result?.data;
      if (data) {
        return Promise.resolve(data);
      } else {
        return Promise.reject(result);
      }
    }
  }

  //CREATE PROMOTION BUK

  @action
  public async createPromo(params: PromoBodyParams) {
    const result = await requestApi.post(DETAIL_API_URL.PROMOTION_ADD, params);
    if (result?.status === 200) {
      return Promise.resolve(result);
    } else {
      return Promise.reject(result);
    }
  }

  //UPDATE PROMOTION BUK
  @action
  public async updatePromo(params: PromoBodyParams) {
    const result = await requestApi.put(DETAIL_API_URL.PROMOTION_EDIT, params);
    if (result?.status === 200) {
      return Promise.resolve(result);
    } else {
      return Promise.reject(result);
    }
  }

  @action
  public async deletePromotionById(data: any) {
    //
    const result = await requestApi.put(DETAIL_API_URL.PROMOTION_DELETE, data, {
      headers: { "content-type": "application/json" },
    });
    if (result?.status === 200) {
      await this.getPromotions();
      return Promise.resolve();
    } else {
      return Promise.reject();
    }
  }

  //CREATE PROMOTION BUK
  @action
  public async createCoursePromo(params: PromoBodyParams) {
    const result = await requestApi.post(
      DETAIL_API_URL.PROMOTION_COURSE_ADD,
      params,
    );
    if (result?.status === 200) {
      return Promise.resolve(result);
    } else {
      return Promise.reject();
    }
  }

  //UPDATE PROMOTION BUK
  @action
  public async updateCoursePromo(params: PromoBodyParams) {
    const result = await requestApi.put(
      DETAIL_API_URL.PROMOTION_COURSE_EDIT,
      params,
    );
    if (result?.status === 200) {
      return Promise.resolve(result);
    } else {
      return Promise.reject();
    }
  }

  @action
  public async deleteCoursePromotionById(data: any) {
    //
    const result = await requestApi.put(
      DETAIL_API_URL.PROMOTION_COURSE_DELETE,
      data,
      { headers: { "content-type": "application/json" } },
    );
    if (result?.status === 200) {
      await this.getCoursePromotions();
      return Promise.resolve();
    } else {
      return Promise.reject();
    }
  }
}

const promotionsStore = new PromotionsStore();

export const promotionsStoreContext = createContext(promotionsStore);
export default promotionsStore;
