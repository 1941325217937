import { DETAIL_API_URL } from "constants/apiUrl";
import requestApi from "helpers/requestApi";

export type PaymentMethodInfomation = {
  id: string;
  cardBrand: string;
  nameOnCard: string;
  lastDigits: string;
  expMonth: number;
  expYear: number;
};

export const deletePaymentMethod = async (id: string) => {
  const result = await requestApi.delete(DETAIL_API_URL.PAYMENT_METHOD, {
    data: {
      id,
    },
  });

  if (result?.status !== 200) throw result;
};

export const getPaymentMethods = async (): Promise<
  Array<PaymentMethodInfomation>
> => {
  const result = await requestApi.get(DETAIL_API_URL.PAYMENT_METHOD);

  if (result?.status !== 200) throw result;

  return result?.data;
};

export const postPaymentMethod = async (id: string, _subdirectory: string) => {
  const result = await requestApi.post(DETAIL_API_URL.PAYMENT_METHOD, {
    id,
    // subdirectory,
  });

  if (result?.status !== 200) throw result;
};
