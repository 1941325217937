import React from "react";

let bizcharts: any;
// @ts-ignore
if (process.browser) {
  // tslint:disable-next-line:no-var-requires
  bizcharts = require("bizcharts");
}

interface IProps {
  colors?: string[];
  data?: any;
  autoFit?: boolean;
}

const IntervalLinePoint = (props: IProps) => {
  const { data } = props;
  const colors = ["#5B8FF9", "#62DAAB"];
  const chartProps =
    props.autoFit === true
      ? {
          autoFit: true,
          key: "fit",
        }
      : {
          key: "no-fit",
          width: 600,
          forceFit: true,
        };

  const scale = {
    organizarionRecurringRevenue: {
      // tickCount: 10,
      alias: "Kidzbiz recurring  revenue",
    },
    numberOfRecurringCustomers: {
      alias: "Number of recurring customers",
    },
  };

  return (
    <div className={"chart-wrapper"}>
      {/*// @ts-ignore*/}
      {process.browser && (
        <bizcharts.Chart
          {...chartProps}
          padding={"auto"}
          height={400}
          data={data}
          scale={scale}
        >
          <bizcharts.Tooltip shared={true} />
          <bizcharts.Interval
            position="month*organizarionRecurringRevenue"
            color={colors[0]}
          />
          <bizcharts.Line
            position="month*numberOfRecurringCustomers"
            color={colors[1]}
            size={3}
          />
          <bizcharts.Point
            position="month*numberOfRecurringCustomers"
            color={colors[1]}
            size={5}
            shape="circle"
          />
        </bizcharts.Chart>
      )}
    </div>
  );
};

export default IntervalLinePoint;
