import { LoadingOutlined } from "@ant-design/icons";
import { Button, Form, Input, message } from "antd";
import { observer } from "mobx-react-lite";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import ImageUploader from "../../../components/ImageUploader";
import { bizIcons } from "../../../constants/bizConstants";
import helpers from "../../../scripts/helpers";

const UnverifiedBlock = observer(() => {
  const { t } = useTranslation();

  const [isDisabled, setIsDisabled] = useState(true);
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  // const imageURL = providerStore?.providerInfo?.verificationFile?.path || undefined;
  const [logoImage, setLogoImage] = useState();
  const [certImageBlob, setCertImageBlob] = useState<File | undefined>();
  // `${
  //     (imageURL?.match(/data:image\/(.*);base64,/) ? "" : apiHost + "/") +
  //     imageURL
  // }`,
  const onFinish = useCallback(
    (values: any) => {
      setIsLoading(true);
      form
        .validateFields([Object.keys(values)])
        .then(async () => {
          if (logoImage) {
            // const success = await providerStore.validateProvider({
            //   ...values,
            //   certificate: certImageBlob,
            // });
            // if (success) {
            //   message.success("Validate form successfully sent.");
            //   await providerStore.getCurrentProviderInfo();
            // }
          }
        })
        .then(() => {
          setIsLoading(false);
        })
        .catch((e) => {
          const { _message } = e;
          console.log(e);
          /// message.error(_message || "Some error occurred");
          setIsLoading(false);
        });
    },
    [logoImage, certImageBlob],
  );

  const validateForm = useCallback(
    (field?: string) => {
      form
        .validateFields(field ? [field] : undefined)
        .then(
          async (r) => {
            if (logoImage) {
              setIsDisabled(false);
            }
          },
          (rej) => {
            setIsDisabled(true);
          },
        )
        .catch((e) => {
          const { _message } = e;
          message.error(
            _message ||
              t("messages.some_error_occurred", {
                defaultValue: "Some error occurred",
              }),
          );
        });
    },
    [isDisabled, logoImage, certImageBlob],
  );

  const onUploadChanged = (file: File) => {
    if (file) {
      helpers.getBase64(file).then(async (imageData: any) => {
        setLogoImage(imageData);
        setCertImageBlob(file);
        form.validateFields(["companyRegistrationNumber"]).then((res) => {
          setIsDisabled(false);
        });
      });
    }
  };

  const uploadButton = (isUploading: boolean) => {
    return (
      <div>
        <div style={{ height: "24px" }} className={"flex-center"}>
          {isUploading ? (
            <LoadingOutlined />
          ) : (
            <i className={`${bizIcons.IMAGE} fs-20 text-color-secondary`} />
          )}
        </div>
        <div
          className={"text-color-secondary"}
          style={{ marginTop: 8, width: "100%" }}
        >
          Upload image
        </div>
      </div>
    );
  };

  const onCheckBeforeUpload = (messages: string[]) => {
    messages.map((m) => message.error(m));
  };

  const beforeUpload = (file: any): boolean | PromiseLike<void> => {
    return new Promise((res, rej) => {
      const messages: string[] = [];
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        messages.push("You can only upload JPG/PNG file!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        messages.push("Max size of the image is 2 Mb");
      }

      if (isJpgOrPng && isLt2M) {
        res();
      } else {
        rej();
      }
    });
  };

  return (
    <Form
      form={form}
      onLoad={() => validateForm()}
      name="normal_verify"
      className="login-form"
      // initialValues={{
      //   remember: true,
      // }}
      onFinish={onFinish}
    >
      <Form.Item className={"bsl-4"}>
        <div className="label-wrapper">
          <label htmlFor="companyRegistrationNumber">
            Company 7-digit incorporation number*
          </label>
        </div>

        <Form.Item
          name="companyRegistrationNumber"
          rules={[
            {
              required: true,
              message: "Please input your registration number!",
            },
            {
              min: 7,
              message: "Consists of 7 characters",
            },
            // {
            //   max: 7,
            //   message: "Consists of 7 characters",
            // },
          ]}
        >
          <Input
            id={"companyRegistrationNumber"}
            placeholder="Enter registration number"
            readOnly={isLoading}
            maxLength={7}
            onKeyPress={(e: any) => {
              if (e.key) {
                if (!new RegExp(/^[0-9]+$/g).test(e.key)) {
                  e.preventDefault();
                }
              }
            }}
            onBlur={() => validateForm("companyRegistrationNumber")}
            onPressEnter={() => validateForm("companyRegistrationNumber")}
            size={"large"}
          />
        </Form.Item>
      </Form.Item>
      <Form.Item help="Jpeg, jpg, png. Max size 1 MB." className={"bsl-4"}>
        <div className="label-wrapper">
          <label htmlFor="certImage">
            Upload certified certificate of incorporation*
          </label>
        </div>
        <ImageUploader
          accept={"image/png, image/jpeg"}
          name="Image of certificate"
          imageUrl={logoImage}
          onCheckBeforeUpload={onCheckBeforeUpload}
          onBeforeUpload={beforeUpload}
          uploadButtonFunc={uploadButton}
          onUploadChanged={onUploadChanged}
        />
      </Form.Item>
      <Form.Item style={{ marginTop: "32px" }}>
        <Button
          className="full-width fs-16"
          type="primary"
          htmlType="submit"
          loading={isLoading}
          disabled={isDisabled || isLoading}
          icon={
            <div className={"flex-center"}>
              <i className="ri-mail-send-line fs-20 mr-10" /> Send for
              verification
            </div>
          }
        />
      </Form.Item>
    </Form>
  );
});

export default UnverifiedBlock;
