import { PAGE_SIZE } from "config";
import { DETAIL_API_URL } from "constants/apiUrl";
import {
  FilterType,
  pageableFirst,
  pageableRezo,
} from "constants/type.interface";
import requestApi from "helpers/requestApi";
import { take } from "lodash";
import { action, computed, observable, toJS } from "mobx";
import { createContext } from "react";

export class ProviderOrdersStore {
  @observable
  private customersOrders: any = {};

  @observable
  public dataBalances: any = null;

  @observable
  public customersFilterModel: any = {
    pageable: pageableRezo,
  };

  @observable
  private orgPayoutsOrders: any = {};

  // TODO: HERE UPDATE TYPE AFTER BACKEND CHANGES
  @observable
  public orgPayoutsFilterModel: any = {
    pageable: pageableRezo,
  };

  @observable
  public dataFilters: any = null;

  @action
  public async setFilters(filter?: any) {
    this.dataFilters = filter;
  }

  @action
  public async getCustomersOrders(filter: any) {
    this.customersFilterModel = filter;
    const params = {
      pageable: pageableRezo,
    };

    const result = await requestApi.post(DETAIL_API_URL.PAYMENT_LIST, {
      ...params,
      ...filter,
    });

    if (result?.status === 200) {
      this.customersOrders = result?.data;
      return Promise.resolve(result?.data);
    } else {
      return Promise.reject();
    }
  }

  @action
  public async exportCustomersList() {
    const result = await requestApi.post(
      DETAIL_API_URL.PAYMENT_EXPORT_LIST,
      toJS(this.customersFilterModel),
    );
    if (result?.status === 200) {
      return Promise.resolve(result?.data);
    } else {
      return Promise.reject();
    }
  }
  @action
  public async exportCustomerById(id?: number) {
    if (!id) {
      return;
    }
    //
  }

  @action
  public async getCustomerDetailsById(id: number): Promise<any> {
    const result = await requestApi.get(DETAIL_API_URL.PAYMENT_DETAIL(id));
    if (result?.status === 200) {
      return Promise.resolve(result?.data);
    } else {
      return Promise.reject();
    }
  }

  @action
  public async createBalance() {
    const result = await requestApi.post(
      DETAIL_API_URL.CREATE_BALANCE,
      {},
      {
        headers: { "Content-Type": "application/json" },
      },
    );

    if (result?.status === 200) {
      this.dataBalances = result?.data;
      return Promise.resolve(result?.data);
    } else {
      return Promise.reject();
    }
  }

  //KIDZBIZ TAB
  @action
  public async getOrgPayoutDetailsById(id: number, code: string): Promise<any> {
    const result = await requestApi.get(
      DETAIL_API_URL.ORG_PAYMENT_DETAIL(id, code),
    );
    if (result?.status === 200) {
      return Promise.resolve(result?.data);
    } else {
      return Promise.reject();
    }
  }

  @action
  public async getOrgPayoutsOrders(filter?: any) {
    this.orgPayoutsFilterModel = filter;
    const params = { pageable: pageableRezo };

    const result = await requestApi.post(
      DETAIL_API_URL.ORG_PAYMENT_LIST,
      { ...params, ...filter },
      {
        headers: { "Content-Type": "application/json" },
      },
    );

    if (result?.status === 200) {
      this.orgPayoutsOrders = result?.data;
      return Promise.resolve(result?.data);
    } else {
      return Promise.reject();
    }
  }

  @action
  public async exportPayoutGeneric(
    providerId: number,
    code: string,
  ): Promise<any> {
    const result = await requestApi.get(
      DETAIL_API_URL.PAYOUT_EXPORT_GENERIC(providerId, code),
    );
    if (result?.status === 200) {
      return Promise.resolve(result?.data);
    } else {
      return Promise.reject();
    }
  }

  @action
  public async exportPayoutXero(
    providerId: number,
    code: string,
  ): Promise<any> {
    const result = await requestApi.get(
      DETAIL_API_URL.PAYOUT_EXPORT_XERO(providerId, code),
    );
    if (result?.status === 200) {
      return Promise.resolve(result?.data);
    } else {
      return Promise.reject();
    }
  }

  @action
  public async clearAll() {
    this.customersOrders = {};
    this.customersFilterModel = {
      pageable: pageableRezo,
    };
    this.orgPayoutsOrders = {};
    this.orgPayoutsFilterModel = {
      pageable: pageableRezo,
    };
  }

  @computed
  public get customersOrdersData() {
    return this.customersOrders;
  }
  @computed
  public get orgPayoutsOrdersData() {
    return this.orgPayoutsOrders;
  }
}

const providerOrdersStore = new ProviderOrdersStore();

export const providerOrdersStoreContext = createContext(providerOrdersStore);
export default providerOrdersStore;
