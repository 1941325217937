import React, { useContext } from "react";
import { LayoutPage } from "../../components/Layout";
import imageSVG from "../../assets/image-403.svg";
import GreySquare from "../../components/GreySquare";
import { Button } from "antd";
import { RouteComponentProps, withRouter } from "react-router-dom";
import bizibuzSVG from "../../assets/icons/bizibuz.svg";
import { observer } from "mobx-react-lite";

const NoAccess = observer((props: RouteComponentProps) => {
  const { history } = props;

  const text = "Sorry, you don't have access to this page";
  const getButton = () => (
    <Button
      className="flex-center"
      type="primary"
      onClick={() => history.push("/")}
    >
      <div className={"flex-center"}>
        <img className={`mr-8`} src={bizibuzSVG} alt={"Home page icon"} />
        Go to Home page
      </div>
    </Button>
  );

  return (
    <LayoutPage>
      <GreySquare text={text} imageSrc={imageSVG} getButton={getButton} />
    </LayoutPage>
  );
});

export default withRouter(NoAccess);
