import { CourseStatus } from "constants/type.interface";

export const filterModels = {
    age: [
        { value: "0-2", placeholder: "0-2" },
        { value: "3-5", placeholder: "3-5" },
        { value: "6-8", placeholder: "6-8" },
        { value: "9-11", placeholder: "9-11" },
        { value: "12-14", placeholder: "12-14" },
        { value: "15-18", placeholder: "15-18" },
    ],
    status: [
        { value: CourseStatus.Pending },
        { value: CourseStatus.InProgress },
        { value: CourseStatus.Blocked },
    ],
};