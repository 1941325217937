import { PAGE_SIZE } from "config";
import { DETAIL_API_URL } from "constants/apiUrl";
import requestApi from "helpers/requestApi";
import { action, computed, makeObservable, observable } from "mobx";
import { createContext } from "react";

export const pageable = {
  pageNumber: 0,
  pageSize: PAGE_SIZE,
};
export class ReviewsStore {
  constructor() {
    makeObservable(this);
  }

  @observable
  public reviewsData: any = null;

  @observable
  public reviewData: any = [];

  @observable
  public filterModel: any = {
    pageable: pageable,
  };

  @observable
  public errorHandler: string = "";

  @observable
  public dataFilters: any = null;

  @action
  public async setFilters(filter?: any) {
    this.dataFilters = filter;
  }

  @action
  public async getReviews(filter?: any) {
    this.filterModel = filter;
    const params = {
      pageable: pageable,
    };

    const result = await requestApi.post(DETAIL_API_URL.REVIEW_LIST, {
      ...params,
      ...filter,
    });
    if (result?.status === 200) {
      this.reviewsData = result?.data || [];
      return Promise.resolve(result?.data);
    } else {
      return Promise.reject();
    }
  }

  @action
  public async getReviewById(id: string | number) {
    const result = await requestApi.get(DETAIL_API_URL.REVIEW_DETAIL(id));
    if (result?.status === 200) {
      this.reviewData = result?.data || [];
      return Promise.resolve(result?.data);
    } else {
      return Promise.reject();
    }
  }

  @action
  public async replyToReview(
    reviewId: number,
    replyContent: string,
  ): Promise<any> {
    const result = await requestApi.put(DETAIL_API_URL.REVIEW_REPLY(reviewId), {
      replyContent,
    });
    if (result?.status === 200) {
      this.reviewData = result?.data || [];
      this.getReviewById(reviewId);
      return Promise.resolve(result?.data);
    } else {
      return Promise.reject();
    }
  }

  @action
  public async getReviewsOfCustomer(customerId: string | number, filter?: any) {
    this.filterModel = filter;
    const params = {
      pageable: pageable,
    };

    const result = await requestApi.post(
      DETAIL_API_URL.REVIEW_OF_CUSTOMER(customerId),
      {
        ...params,
        ...filter,
      },
    );
    if (result?.status === 200) {
      this.reviewsData = result?.data || [];
      return Promise.resolve(result?.data);
    } else {
      return Promise.reject();
    }
  }

  @action
  public async exportCustomers() {
    //
  }

  @action
  public async clearFilter() {
    this.filterModel = {
      pageable: pageable,
    };
  }

  @computed
  public get getReviewsData() {
    return this.reviewsData;
  }

  @computed
  public get getReviewData() {
    return this.reviewData;
  }
}

const reviewsStore = new ReviewsStore();

export const reviewsStoreContext = createContext(reviewsStore);
export default reviewsStore;
