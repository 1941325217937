import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Select,
} from "antd";
import { HeadSeo } from "components/HeadSeo";
import { FORMAT_DATE } from "constants/commons";
import { PromoBodyParams, PromotionTab } from "constants/type.interface";
import { AppRoutes } from "helpers/app.routes";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import helpers from "scripts/helpers";
import { PromotionsStore } from "scripts/mobx/promotionsStore";
import { userStoreContext } from "scripts/mobx/userStore";
import ButtonLink from "../../../components/ButtonLink";
import { LayoutPage } from "../../../components/Layout";
import { bizIcons } from "../../../constants/bizConstants";
import { PromotionType } from "../data";
import "./index.less";

interface IAddPromotionsProps extends RouteComponentProps {
  promotionsStore?: PromotionsStore;
}

const AddPromotions = inject("promotionsStore")(
  observer((props: IAddPromotionsProps) => {
    const { history, match, promotionsStore } = props;
    const userStore = useContext(userStoreContext);
    const { t } = useTranslation();
    // @ts-ignore
    const id = props.match.params.id;
    const isView = match.path === "/promotions/view-bulk/:id?";

    const [isLoading, setIsLoading] = useState(false);
    const [startDate, setStartDate] = useState<any>();
    const [form] = Form.useForm();
    const [editData, setEditData] = useState<any>();

    useEffect(() => {
      if (id) {
        promotionsStore?.getPromotionById(Number(id)).then((data: any) => {
          setEditData(data);
          setPromType(data?.type);
        });
      }
    }, [id]);

    const handleSubmit = async (values: any) => {
      const params: PromoBodyParams = {
        name: values?.name,
        type: values?.type,
        threshold: values?.threshold,
        discountPercentage: parseFloat(
          Number(values?.discountPercentage || 0)?.toFixed(1),
        ),
        effectiveFrom: moment(values?.startDate).format(FORMAT_DATE.query),
        effectiveTo: moment(values?.endDate).format(FORMAT_DATE.query),
        currency: values?.currency ?? "HKD",
      };

      try {
        if (id) {
          await promotionsStore?.updatePromo({
            id: editData?.id,
            ...params,
          });
        } else {
          await promotionsStore?.createPromo(params);
        }

        message.success(id ? t("promo.updated") : t("promo.added"));
        setTimeout(() => history.push(AppRoutes.promotions + "?tab=BULK"), 200);
      } catch (error: any) {
        const key = error?.data?.error;
        const message = error?.data?.message;
        const errorType = [
          "com.bizibuz.core.service.common.DateRangeOverlapException",
          "com.bizibuz.core.service.common.InvalidInputException",
        ];
        helpers.throwErrors(t, errorType, key, message);
      }
      setIsLoading(false);
    };

    const [promType, setPromType] = useState<any>();

    const onFinish = (values: any) => {
      setIsLoading(true);
      form
        .validateFields([Object.keys(values)])
        .then(
          () => handleSubmit(values),
          (e) => {
            const { _message } = e;
            message.error(
              _message ||
                t("messages.not_valid_input_data", {
                  defaultValue: "Not valid input data",
                }),
            );
            setIsLoading(false);
          },
        )
        .catch((e) => {
          const { _message } = e;
          console.log(e);
          message.error(
            _message ||
              t("messages.some_error_occurred", {
                defaultValue: "Some error occurred",
              }),
          );
          setIsLoading(false);
        });
    };

    const handleDelete = () => {
      Modal.confirm({
        content: t("bizBase.confirm_delete"),
        async onOk() {
          try {
            await promotionsStore?.deletePromotionById(Number(id));
            message.success(t("promo.deleted"));
            history.push(AppRoutes.promotions);
          } catch (error: any) {
            message.error(
              error?.data?.message || t("server_errors." + "Bad Request"),
            );
          }
        },
      });
    };

    const handleFormValuesChange = (changedValues: any) => {
      const formFieldName = Object.keys(changedValues)[0];
      if (formFieldName === "startDate") {
        form.setFieldsValue({ endDate: undefined });
      }
    };

    useEffect(() => form.resetFields(), [editData]);

    return (
      <>
        <HeadSeo
          title={
            isView
              ? t("promo.viewPromotion")
              : id
              ? t("promo.editPromotion")
              : t("promo.addBulkPromotion")
          }
        />
        <LayoutPage defMenuItem={"promotions"}>
          <div className="content-wrapper content-with-table">
            <ButtonLink onClick={() => history.push("/promotions")}>
              <i className={`${bizIcons.ARROWLEFT} fs-20 mr-8`} />
              {t("promo.backToPromotions")}
            </ButtonLink>
            <div className="auth-header bsl-4">
              <div className="auth-form">
                <h1 className={"auth-header_title"}>
                  {isView
                    ? t("promo.viewPromotion")
                    : id
                    ? t("promo.editPromotion")
                    : t("promo.addBulkPromotion")}
                </h1>
                {!isView && id && (
                  <ButtonLink onClick={handleDelete}>
                    <i className={`${bizIcons.DELETE} fs-20 mr-8`} />
                    {t("bizBase.delete")}
                  </ButtonLink>
                )}
              </div>
            </div>
            <div className="auth-form">
              <Form
                form={form}
                name="normal_login"
                className="login-form"
                initialValues={{
                  name: editData?.name,
                  type: editData?.type,
                  threshold: editData?.threshold,
                  discountPercentage: editData?.discountPercentage || undefined,
                  startDate: editData?.effectiveFrom
                    ? moment(
                        helpers.getServerDate(editData?.effectiveFrom),
                        FORMAT_DATE.query,
                      )
                    : undefined,
                  endDate: editData?.effectiveTo
                    ? moment(
                        helpers.getServerDate(editData?.effectiveTo),
                        FORMAT_DATE.query,
                      )
                    : undefined,
                  currency: editData?.currency ?? "HKD",
                }}
                onValuesChange={handleFormValuesChange}
                onFinish={onFinish}
              >
                <Form.Item className="bsl-4">
                  <div className="label-wrapper">
                    <label htmlFor="name">{t("promo.promotionName")}*</label>
                  </div>
                  <Form.Item
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: t("messages.required_field"),
                      },
                    ]}
                  >
                    <Input
                      id={"name"}
                      placeholder={t("promo.enterPromotionName")}
                      readOnly={isLoading}
                      size={"large"}
                      disabled={isView}
                    />
                  </Form.Item>
                </Form.Item>

                <Form.Item className="bsl-4">
                  <div className="label-wrapper">
                    <label htmlFor="type">{t("promo.promotionType")}*</label>
                  </div>
                  <Form.Item
                    name="type"
                    rules={[
                      {
                        required: true,
                        message: t("messages.required_field"),
                      },
                    ]}
                  >
                    <Select
                      placeholder={t("promo.selectPromotionType")}
                      suffixIcon={
                        <i className="ri-arrow-drop-down-line fs-20" />
                      }
                      onChange={(e: PromotionType) => setPromType(e)}
                      disabled={isView}
                    >
                      <Select.Option value={PromotionType.MinCourse}>
                        {t("promo.minPurchasedCourses")}
                      </Select.Option>
                      <Select.Option value={PromotionType.MinSpend}>
                        {t("promo.minimumSpend")}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Form.Item>
                {promType === PromotionType.MinCourse && (
                  <Form.Item className="bsl-4">
                    <div className="label-wrapper">
                      <label htmlFor="minimumCourses">
                        {t("promo.minimumCourses")}*
                      </label>
                    </div>
                    <Form.Item
                      name="threshold"
                      rules={[
                        {
                          required:
                            promType === PromotionType.MinCourse ? true : false,
                          message: t("messages.required_field"),
                        },
                        {
                          type: "integer",
                          message: t("courses.validMinClasses"),
                        },
                      ]}
                    >
                      <InputNumber
                        id={"threshold"}
                        placeholder={t("promo.enterMinimumCourses")}
                        style={{ width: "100%" }}
                        readOnly={isLoading}
                        min={0}
                        max={60}
                        size={"large"}
                        disabled={isView}
                      />
                    </Form.Item>
                  </Form.Item>
                )}

                {promType === PromotionType.MinSpend && (
                  <Form.Item className="bsl-4">
                    <div className="label-wrapper">
                      <label htmlFor="threshold">
                        {t("promo.minimumSpend")}*
                      </label>
                    </div>
                    <div className="bulk-total-price">
                      {/* <span className="course-total-price-unit">
                        {userStore.currency}
                      </span> */}
                      <Form.Item
                        name="threshold"
                        rules={[
                          {
                            required:
                              promType === PromotionType.MinSpend
                                ? true
                                : false,
                            message: t("messages.required_field"),
                          },
                          {
                            type: "integer",
                            message: t("courses.validMinClasses"),
                          },
                        ]}
                      >
                        <InputNumber
                          id={"threshold"}
                          placeholder={t("promo.enterMinimumSpend")}
                          style={{ width: "100%" }}
                          readOnly={isLoading}
                          size={"large"}
                          min={0}
                          max={100000}
                          formatter={(value) =>
                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }
                          parser={(value) =>
                            value
                              ? value.replace(/\$\s?|(,*)/g, "")
                              : ("" as any)
                          }
                          disabled={isView}
                          addonBefore={
                            <Form.Item name="currency" noStyle>
                              <Select
                                style={{ minWidth: 80 }}
                                suffixIcon={
                                  <i className="ri-arrow-drop-down-line fs-20 mt-8" />
                                }
                                disabled={isView}
                              >
                                <Select.Option value="HKD">HKD</Select.Option>
                                <Select.Option value="SGD">SGD</Select.Option>
                              </Select>
                            </Form.Item>
                          }
                        />
                      </Form.Item>
                    </div>
                  </Form.Item>
                )}

                <Form.Item className="bsl-4">
                  <div className="label-wrapper">
                    <label htmlFor="discountPercentage">
                      {t("promo.Discount")}*
                    </label>
                  </div>{" "}
                  <div className="course-total-price min">
                    <span className="course-total-price-unit">%</span>
                    <Form.Item
                      name="discountPercentage"
                      rules={[
                        {
                          required: true,
                          message: t("messages.required_field"),
                        },
                      ]}
                    >
                      <InputNumber
                        id={"discountPercentage"}
                        placeholder={"Enter discount amount"}
                        style={{ width: "100%" }}
                        maxLength={5}
                        formatter={(value) =>
                          `${value}`.replace(/\B(?=(\d{2})+(?!\d))/g, ".")
                        }
                        parser={(value) =>
                          value ? value.replace(/\$\s?|(,*)/g, "") : ("" as any)
                        }
                        min={1}
                        max={100}
                        decimalSeparator="."
                        readOnly={isLoading}
                        size={"large"}
                        disabled={isView}
                      />
                    </Form.Item>
                  </div>
                </Form.Item>
                <Form.Item
                  className="bsl-4"
                  rules={[
                    {
                      required: true,
                      message: t("messages.required_field"),
                    },
                  ]}
                >
                  <div className="label-wrapper">
                    <label htmlFor="startDate">{t("courses.dateFrom")}*</label>
                  </div>

                  <Form.Item name="startDate">
                    <DatePicker
                      name={"startDate"}
                      style={{ width: "100%" }}
                      showToday={false}
                      disabledDate={(current) => {
                        return current && current < moment().startOf("day");
                      }}
                      placeholder={t("courses.enterStartDate")}
                      onChange={(value) => {
                        setStartDate(value);
                      }}
                      size={"large"}
                      disabled={isView}
                    />
                  </Form.Item>
                </Form.Item>
                <Form.Item
                  className="bsl-4"
                  rules={[
                    {
                      required: true,
                      message: t("messages.required_field"),
                    },
                  ]}
                >
                  <div className="label-wrapper">
                    <label htmlFor="endDate">{t("courses.dateTo")}*</label>
                  </div>

                  <Form.Item name="endDate">
                    <DatePicker
                      name={"endDate"}
                      style={{ width: "100%" }}
                      showToday={false}
                      disabledDate={(current) => {
                        const value = startDate ? moment(startDate) : undefined;
                        return value
                          ? current && current < value?.startOf("day")
                          : current && current < moment().startOf("day");
                      }}
                      placeholder={t("promo.enterEndDate")}
                      size={"large"}
                      disabled={isView}
                    />
                  </Form.Item>
                </Form.Item>
                {!isView && (
                  <Form.Item>
                    <Button
                      className="full-width fs-16 tsl-4"
                      style={{ marginBottom: "32px" }}
                      type="primary"
                      htmlType="submit"
                      loading={isLoading}
                      disabled={isLoading}
                      icon={
                        <div className={"flex-center"}>
                          {!!editData && (
                            <i className={`${bizIcons.SAVE} mr-8 fs-20`} />
                          )}
                          {!!editData ? "Save changes" : "Create a promotion"}
                        </div>
                      }
                    />
                  </Form.Item>
                )}
              </Form>
            </div>
          </div>
        </LayoutPage>
      </>
    );
  }),
);

export default withRouter(AddPromotions);
