import { FORMAT_DATE } from "constants/commons";
import moment from "moment";
import React from "react";
import helpers from "scripts/helpers";

export const tableScheduleColumn = (
  t: any,
  ActionButton?: (record: any) => void,
) => {
  const columns = [
    {
      dataIndex: "no",
      key: "no",
      render: (text: any, record: any, index: number) => <div>{index + 1}</div>,
    },
    {
      title: t("courses.date"),
      dataIndex: "date",
      key: "date",
      render: (text: any, record: any) => (
        <div style={{ textTransform: "capitalize" }}>
          {record?.dayOfWeek?.toLowerCase() +
            " - " +
            helpers.formatDate(text) || "-"}
        </div>
      ),
    },
    {
      title: t("courses.From"),
      dataIndex: "startTime",
      key: "startTime",
      render: (text: any) => <div>{text || "-"}</div>,
    },
    {
      title: t("courses.To"),
      key: "endTime",
      dataIndex: "endTime",
      render: (text: any) => <div>{text || "-"}</div>,
    },
    {
      key: "action",
      dataIndex: "action",
      render: (text: any, record: any) => ActionButton?.(record),
    },
  ];

  return [...columns];
};
