import React, { useCallback, useEffect, useMemo, useState } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import ButtonLink from "components/ButtonLink";
import { bizIcons } from "constants/bizConstants";
import {
  Button,
  Form,
  Input,
  message,
  Select,
  Row,
  Col,
  notification,
  Spin,
} from "antd";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { FieldData } from "rc-field-form/lib/interface";
import { IOptionItem } from "components/AutoCompleteInput";
import helpers from "scripts/helpers";
import SaveButtonText from "components/SaveButtonText";
import { useTranslation } from "react-i18next";
import { Languages } from "scripts/mobx/languageStore";
import { getLanguage } from "scripts/custom-hooks/useLanguage";
import { CITY_DATA, SINGAPORE_CITY_DATA } from "pages/signup/interface";
import { COUNTRY_DATA, PhoneCode } from "components/PhoneCode";
import locationsStore, { LocationType } from "scripts/mobx/locationStore";
import { useCurrentUser } from "scripts/custom-hooks/useCurrentUser";
import { City, Country, State } from "country-state-city";

const { Option } = Select;

interface IAddLocationProps extends RouteComponentProps {
  onBack: () => void;
  addLocationData: (data: any) => void;
  userId: any;
}

const fieldsRequried = {
  name: true,
  address: true,
  stateProvinceId: true,
};

const AddLocationComponent = inject("locationsStore")(
  observer((props: IAddLocationProps) => {
    const { onBack, addLocationData, userId } = props;
    const { t } = useTranslation("base");
    const lang = getLanguage();

    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [suburbItems, setSuburbItems] = useState<any[]>([]);
    const user = useCurrentUser();

    const [form] = Form.useForm();

    const fieldSize = location.pathname === "/signup" ? 24 : 12;

    const country = useMemo(() => {
      const countryCode =
        COUNTRY_DATA.find((item) =>
          item.name.includes(user?.providerAttributes?.country ?? "Hong Kong"),
        )?.isoCode ?? "HK";
      const selectedCountry = Country.getCountryByCode(countryCode);
      return selectedCountry;
    }, []);

    const getStatesList = useCallback((isoCode) => {
      if (!isoCode) return [];
      if (isoCode === "HK")
        return CITY_DATA.map((item) => ({
          name: item.value,
          value: item.value,
        }));

      if (isoCode === "SG") return SINGAPORE_CITY_DATA;
      return State.getStatesOfCountry(isoCode).map((item) => ({
        name: item.name,
        value: item.isoCode,
      }));
    }, []);

    // interpolate states by country
    const states = useMemo(() => getStatesList(country?.isoCode), [country]);

    const [selectedStateCode, setSelectedStateCode] = useState<string>();

    const getCitiesList = useCallback((isoCode, stateCode) => {
      if (!isoCode || !stateCode) return [];
      if (isoCode === "HK")
        return CITY_DATA.find((el) => el.value === stateCode)?.suburbs ?? [];

      if (isoCode === "SG") return SINGAPORE_CITY_DATA[0].suburbs;
      return City.getCitiesOfState(isoCode, stateCode).map((item) => ({
        value: item.name,
      }));
    }, []);

    const handleStateChanged = (value: string) => {
      // set default suburb after changing state
      const mCities = getCitiesList(country?.isoCode, value);
      form.setFieldsValue({
        suburb: mCities?.[0]?.value,
      });

      setSelectedStateCode(value);
    };

    // interpolate cities by state
    const cities = useMemo(
      () => getCitiesList(country?.isoCode, selectedStateCode),
      [country, selectedStateCode],
    );

    const onFinish = (values: any) => {
      setIsLoading(true);
      form
        .validateFields([
          Object.keys({
            ...values,
            phoneNumber: helpers.getPhoneWithChCode(values.phoneNumber),
          }),
        ])
        .then(
          async () => {
            const country = Country.getCountryByCode(values?.codeMobile);
            const codeMobile = values?.codeMobile
              ? `+${country?.phonecode}`
              : "+852";
            const param: LocationType = {
              name: values?.name,
              address: {
                addressLine: values?.address || "",
                country: country?.name || "",
                area: values?.area || "",
                suburb: values?.suburb || "",
              },
              email: values?.email,
              mobile: values?.phoneNumber
                ? codeMobile + values?.phoneNumber
                : "",
              deleted: false,
            };
            await addLocationData([param]);
          },
          (e) => {
            const { _message } = e;
            console.log(e);

            message.error(
              _message ||
                t("messages.not_valid_input_data", {
                  defaultValue: "Not valid input data",
                }),
            );
            setIsLoading(false);
          },
        )
        .catch((e) => {
          const { _message } = e;
          console.log(e);
          message.error(
            _message ||
              t("messages.some_error_occurred", {
                defaultValue: "Some error occurred",
              }),
          );
          setIsLoading(false);
        });
    };

    const onErrorsCheck = (fields: FieldData[], allfields: FieldData[]) => {
      const hasErrors = allfields.some(
        (field: FieldData) =>
          (field.errors && field.errors?.length > 0) ||
          (fieldsRequried[field.name[0]] && field.value === undefined),
      );

      setIsDisabled(hasErrors);
    };

    const initialValues = useMemo(() => {
      const country = COUNTRY_DATA.find((item) =>
        item.name.includes(user?.providerAttributes?.country),
      );

      const mCountryCode = country?.isoCode ?? "HK";

      return {
        codeMobile: mCountryCode,
      };
    }, [user]);

    return (
      <div
        className={location.pathname === "/signup" ? "auth" : "single-course"}
      >
        <div className="content-wrapper content-with-table">
          <ButtonLink
            onClick={() => {
              form.resetFields();
              onBack();
            }}
          >
            <i className={`${bizIcons.ARROWLEFT} fs-20 mr-8`} />
            {t("kidzbiz.back")}
          </ButtonLink>
          <div className="auth-header bsl-4">
            <div>
              <span className={"auth-header_title"}>
                {t("kidzbiz.addLocation")}
              </span>
            </div>
          </div>
          <div className="auth-form">
            <Spin spinning={isLoading} size="large">
              <Form
                form={form}
                name="normal_login"
                className="login-form form-grid-layout"
                onFieldsChange={onErrorsCheck}
                onFinish={onFinish}
                initialValues={initialValues}
              >
                <Row>
                  <Col xs={24}>
                    <div className="bsl-4">
                      <div className="label-wrapper">
                        <label htmlFor="name">
                          {t("kidzbiz.locationName")}*
                        </label>
                      </div>
                      <Form.Item
                        className="bsl-4"
                        name="name"
                        rules={[
                          {
                            required: true,
                            message: "Please fill in all required fields",
                          },
                        ]}
                      >
                        <Input
                          id={"name"}
                          placeholder={t("kidzbiz.locationName")}
                          readOnly={isLoading}
                          size={"large"}
                        />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>

                <Row gutter={24}>
                  <Col xs={24} md={fieldSize}>
                    <div className="bsl-4">
                      <div className="label-wrapper">
                        <label htmlFor="address">
                          {t("kidzbiz.locationAddress")}*
                        </label>
                      </div>
                      <Form.Item
                        name="address"
                        rules={[
                          {
                            required: true,
                            message: "Please fill in all required fields",
                          },
                        ]}
                      >
                        <Input.TextArea
                          id={"address"}
                          maxLength={100}
                          placeholder={t("kidzbiz.locationAddress")}
                          autoSize={{ minRows: 5, maxRows: 5 }}
                        />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col xs={24} md={fieldSize}>
                    <Form.Item className="bsl-4">
                      <div className="label-wrapper">
                        <label htmlFor="area">
                          {t("signUp.step2.area", {
                            defaultValue: "Area",
                          })}
                          *
                        </label>
                      </div>
                      <Form.Item
                        name="area"
                        rules={[
                          {
                            required: true,
                            message: "Please fill in all required fields",
                          },
                        ]}
                      >
                        <Select
                          placeholder={t("signUp.step2.enterArea", {
                            defaultValue: "Enter area",
                          })}
                          suffixIcon={
                            <i className="ri-arrow-drop-down-line fs-20" />
                          }
                          onChange={handleStateChanged}
                        >
                          {states.map((city: { value: string }) => {
                            return (
                              <Option key={city.value} value={city.value}>
                                {city.value}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Form.Item>
                    <Form.Item className="bsl-4">
                      <div className="label-wrapper">
                        <label htmlFor="suburb">
                          {t("signUp.step2.suburb", {
                            defaultValue: "Suburb",
                          })}
                          *
                        </label>
                      </div>
                      <Form.Item
                        name="suburb"
                        rules={[
                          {
                            required: true,
                            message: "Please fill in all required fields",
                          },
                        ]}
                      >
                        <Select
                          placeholder={t("signUp.step2.enterSuburb", {
                            defaultValue: "Enter suburb",
                          })}
                          suffixIcon={
                            <i className="ri-arrow-drop-down-line fs-20" />
                          }
                        >
                          {cities.map((suburb) => (
                            <Option key={suburb.value} value={suburb.value}>
                              {suburb.value}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={24}>
                  <Col xs={24} md={fieldSize}>
                    <div className="bsl-4">
                      <div className="label-wrapper">
                        <label htmlFor="email">{t("kidzbiz.email")}</label>
                      </div>
                      <Form.Item
                        name="email"
                        rules={[
                          {
                            pattern:
                              /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
                            message: t("messages.invalid_email_format"),
                          },
                        ]}
                      >
                        <Input
                          id={"email"}
                          maxLength={45}
                          prefix={<i className="ri-mail-line mr-10 fs-20" />}
                          placeholder={t("kidzbiz.email")}
                          readOnly={isLoading}
                          size={"large"}
                        />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col xs={24} md={fieldSize}>
                    <div className="bsl-4">
                      <div className="label-wrapper">
                        <label htmlFor="phoneNumber">
                          {t("kidzbiz.mobilePhone")}
                        </label>
                      </div>

                      <Form.Item
                        name="phoneNumber"
                        rules={[
                          {
                            min: 8,
                            message: t("messages.employee_mobile_invalid", {
                              min: 8,
                            }),
                          },
                        ]}
                        // initialValue={data.mobile}
                      >
                        <Input
                          maxLength={8}
                          placeholder={t("signUp.step2.enterMobilePhone", {
                            defaultValue: "Enter mobile phone",
                          })}
                          readOnly={isLoading}
                          onKeyPress={(e: any) => {
                            if (e.key) {
                              if (!new RegExp(/^[0-9]$/g).test(e.key)) {
                                e.preventDefault();
                              }
                            }
                          }}
                          prefix={
                            <>
                              <i className={`${bizIcons.SMARTPHONE} mr-6`} />
                              <PhoneCode name="codeMobile" disabled />
                            </>
                          }
                          size={"large"}
                        />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>

                <Row gutter={24}>
                  <Col xs={24}>
                    <Form.Item>
                      <Button
                        className="full-width fs-16 tsl-4"
                        type="primary"
                        htmlType="submit"
                        loading={isLoading}
                        icon={
                          <SaveButtonText>
                            {t("kidzbiz.addLocation")}
                          </SaveButtonText>
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Spin>
          </div>
        </div>
      </div>
    );
  }),
);

const AddLocationWrapper = withRouter((props: IAddLocationProps) => (
  <AddLocationComponent {...props} />
));

export default AddLocationWrapper;
