import { Button, Form, Input, notification } from "antd";
import { ErrorText } from "components/ErrorText";
import { COUNTRY_DATA, PhoneCode } from "components/PhoneCode";
import { COUNTRY_CODE_LOCAL_STORAGE } from "constants/commons";
import { inject, observer } from "mobx-react";
import { FieldData } from "rc-field-form/lib/interface";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { getLanguage } from "scripts/custom-hooks/useLanguage";
import helpers from "scripts/helpers";
import { Languages } from "scripts/mobx/languageStore";
import { UserStore } from "scripts/mobx/userStore";
import StepsBizi from "../../../components/StepsBizi";
import { bizIcons } from "../../../constants/bizConstants";
import { suggestLocation } from "scripts/utils/suggestLocation";

const REGEX_PHONE = new RegExp(/^\d{8,12}$/g);

export type Step2Props = {
  contactPersonFirstName: string;
  contactPersonLastName: string;
  codeMobile?: string;
  mobilePhone: string;
  codeLandline?: string;
  landlinePhone?: string;
};

const fieldsRequried = {
  contactPersonFirstName: true,
  contactPersonLastName: true,
  mobile: true,
};

interface IStoreProps {
  nextStep: () => void;
  history: any;
  location: any;
  userStore?: UserStore;
}
const Step2Form = inject("userStore")(
  observer((props: IStoreProps) => {
    const { nextStep, userStore } = props;

    const { t } = useTranslation("base");
    const lang = getLanguage();
    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [form] = Form.useForm<Step2Props>();
    const [errorText, setErrorText] = useState<string>();

    // keycloak.init({onLoad: "check-sso"});

    const onFinish = async (values: Step2Props) => {
      setIsLoading(true);
      try {
        await form.validateFields(Object.keys(values));
        const country = COUNTRY_DATA.find(
          (el) => el.isoCode === values?.codeMobile,
        );
        const phonecode = country?.phonecode ?? "+852";

        localStorage.setItem(
          COUNTRY_CODE_LOCAL_STORAGE,
          country?.isoCode || "",
        );

        userStore
          ?.userRegister({
            firstName: values.contactPersonFirstName,
            lastName: values.contactPersonLastName,
            mobile: `${phonecode}${values.mobilePhone}`,
            landline: values?.landlinePhone
              ? phonecode + values?.landlinePhone
              : null,
          })
          .then((res) => {
            setErrorText("");
            nextStep();
          })
          .catch((error) => {
            helpers.throwErrors(
              t,
              [
                "Forbidden",
                "com.bizibuz.core.service.user.UserExistsException",
                "com.bizibuz.core.service.provider.LegalNameExistsException",
                "com.bizibuz.core.service.provider.UserNotFoundException",
                "com.bizibuz.core.service.provider.BusinessUnitNotFoundException",
                "com.bizibuz.core.service.provider.ProviderNotFoundException",
                "com.bizibuz.core.service.user.EmailInUsedException",
              ],
              error?.data?.error,
              "",
            );
          });
        setIsLoading(false);
      } catch {
        setIsLoading(false);
      }
    };

    const onErrorsCheck = (fields: FieldData[], allfields: FieldData[]) => {
      setErrorText("");
      const hasErrors = allfields.some(
        (field: FieldData) =>
          (field.errors && field.errors?.length > 0) ||
          (fieldsRequried[field.name[0]] && field.value === undefined),
      );

      setIsDisabled(hasErrors);
    };

    const [countryCode, setCountryCode] = useState<string>("HK");
    const handlePhoneChanged = (value: string) => {
      form.setFieldsValue({
        codeMobile: value,
        codeLandline: value,
      });
      setCountryCode(value);
    };

    const minPhone = 8;
    const maxPhone = countryCode === "HK" ? 8 : 12;

    useEffect(() => {
      const initFormData = async () => {
        const location = await suggestLocation();

        form.setFieldsValue({
          contactPersonLastName: "",
          codeMobile: location === "Singapore" ? "SG" : "HK",
          mobilePhone: "",
          codeLandline: location === "Singapore" ? "SG" : "HK",
          landlinePhone: "",
        });
      };

      initFormData();
    }, []);

    return (
      <Form
        form={form}
        name="normal_login"
        className="login-form tsl-4 bsl-2"
        initialValues={{
          contactPersonFirstName: "",
          contactPersonLastName: "",
          codeMobile: "SG",
          mobilePhone: "",
          codeLandline: "SG",
          landlinePhone: "",
        }}
        onFieldsChange={onErrorsCheck}
        onFinish={onFinish}
      >
        <div className="formtext-wrapper tsl-4 bsl-4">
          {t("signUp.step2.desc", {
            defaultValue:
              "Add contact info to let customers get in touch with you.",
          })}
        </div>
        <Form.Item className="bsl-4">
          <div className="label-wrapper tsl-4">
            <label htmlFor="contactPersonFirstName">
              {t("signUp.step2.labelFirstName", {
                defaultValue: "Contact person first name",
              })}
              *
            </label>
          </div>
          <Form.Item
            name="contactPersonFirstName"
            rules={[
              {
                required: true,
                message: t("messages.required_field", {
                  defaultValue: "Please fill in all required fields",
                }),
              },
              // {
              //   pattern:
              //     /^[a-zA-Z \u3000-\u303F\u3040-\u309F\u30A0-\u30FF\uFF00-\uFFEF\u4E00-\u9FAF\u2605-\u2606\u2190-\u2195\u203B]+$/,
              //   message: t("messages.contain_only_letters", {
              //     name: "First name",
              //   }),
              // },
            ]}
          >
            <Input
              id={"contactPersonFirstName"}
              placeholder={t("signUp.enterFirstName", {
                defaultValue: "Enter first name",
              })}
              maxLength={60}
              readOnly={isLoading}
              size={"large"}
            />
          </Form.Item>
        </Form.Item>
        <Form.Item className="bsl-4">
          <div className="label-wrapper">
            <label htmlFor="contactPersonLastName">
              {t("signUp.step2.labelLastName", {
                defaultValue: "Contact person last name",
              })}
              *
            </label>
          </div>

          <Form.Item
            name="contactPersonLastName"
            rules={[
              {
                required: true,
                message: t("messages.required_field", {
                  defaultValue: "Please fill in all required fields",
                }),
              },
              // {
              //   pattern:
              //     /^[a-zA-Z \u3000-\u303F\u3040-\u309F\u30A0-\u30FF\uFF00-\uFFEF\u4E00-\u9FAF\u2605-\u2606\u2190-\u2195\u203B]+$/,
              //   message: t("messages.contain_only_letters", {
              //     name: "Last name",
              //   }),
              // },
            ]}
          >
            <Input
              id={"contactPersonLastName"}
              placeholder={t("signUp.enterLastName", {
                defaultValue: "Enter last name",
              })}
              maxLength={60}
              readOnly={isLoading}
              size={"large"}
              autoComplete="off"
            />
          </Form.Item>
        </Form.Item>
        <Form.Item className="bsl-4">
          <div className="label-wrapper">
            <label htmlFor="mobilePhone">
              {t("signUp.step2.enterMobilePhone", {
                defaultValue: "Enter mobile phone",
              })}
              *
            </label>
          </div>
          <Form.Item
            name="mobilePhone"
            rules={[
              {
                required: true,
                message: t("messages.required_field", {
                  defaultValue: "Please fill in all required fields",
                }),
              },
              {
                pattern: REGEX_PHONE,
                message: t("messages.phone_invalid", {
                  name: "mobile",
                }),
              },
              {
                min: minPhone,
                message: t("messages.phone_invalid_min", {
                  name: "Mobile number",
                  min: minPhone,
                }),
              },
            ]}
          >
            <Input
              maxLength={maxPhone}
              placeholder={t("signUp.step2.enterMobilePhone", {
                defaultValue: "Enter mobile phone",
              })}
              readOnly={isLoading}
              addonBefore={
                <>
                  <i
                    className={`${bizIcons.SMARTPHONE}`}
                    style={{ position: "relative", top: 2, marginRight: 8 }}
                  />
                  <PhoneCode name="codeMobile" onChange={handlePhoneChanged} />
                </>
              }
              size={"large"}
              autoComplete="off"
            />
          </Form.Item>
        </Form.Item>
        <Form.Item className="bsl-4">
          <div className="label-wrapper">
            <label htmlFor="landlinePhone">
              {t("signUp.step2.enterLandlinePhone", {
                defaultValue: "Enter landline phone",
              })}
            </label>
          </div>
          <Form.Item
            name="landlinePhone"
            rules={[
              {
                pattern: REGEX_PHONE,
                message: t("messages.phone_invalid", {
                  name: "landline",
                }),
              },
              {
                min: minPhone,
                message: t("messages.phone_invalid_min", {
                  name: "Landline",
                  min: minPhone,
                }),
              },
            ]}
          >
            <Input
              id={"landlinePhone"}
              maxLength={maxPhone}
              placeholder={t("signUp.step2.enterLandlinePhone", {
                defaultValue: "Enter landline phone",
              })}
              readOnly={isLoading}
              addonBefore={
                <>
                  <i
                    className={`${bizIcons.SMARTPHONE}`}
                    style={{ position: "relative", top: 2, marginRight: 8 }}
                  />
                  <PhoneCode name="codeMobile" onChange={handlePhoneChanged} />
                </>
              }
              size={"large"}
              autoComplete="off"
            />
          </Form.Item>
        </Form.Item>

        <Form.Item>
          <Button
            className="full-width fs-16 tsl-4"
            type="primary"
            htmlType="submit"
            loading={isLoading}
            disabled={isDisabled || isLoading}
            icon={
              <div className={"flex-center"}>
                {t("signUp.nextStep", {
                  defaultValue: "Next step",
                })}
                <i className="ri-arrow-right-line ml-8 fs-20" />
              </div>
            }
          />
        </Form.Item>
        <ErrorText msg={errorText} />
      </Form>
    );
  }),
);

interface IPropsWrapper extends RouteComponentProps {
  nextStep: () => void;
}

const Step2FormWrapper = withRouter((props: IPropsWrapper) => {
  const { history, nextStep, location } = props;
  const { t } = useTranslation("base");
  return (
    <div className="auth">
      <div className="content-wrapper">
        <div className="auth-header bsl-4">
          <div>
            <span className={"auth-header_title"}>
              {t("signUp.step2.title", {
                defaultValue: "Contacts",
              })}
            </span>
          </div>
        </div>
        <div className="auth-steps bsl-4">
          <StepsBizi total={4} active={1} />
        </div>

        <div className="auth-form">
          <Step2Form
            nextStep={nextStep}
            history={history}
            location={location}
          />
        </div>
      </div>
    </div>
  );
});
export default Step2FormWrapper;
