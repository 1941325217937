import { Divider, Modal, notification, Spin } from "antd";
import Title from "antd/lib/typography/Title";
import ButtonLink from "components/ButtonLink";
import TableComponent from "components/TableComponent/TableComponent";
import { bizIcons } from "constants/bizConstants";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { userBlocked, useType } from "scripts/custom-hooks/useCurrentUser";
import { getLanguage } from "scripts/custom-hooks/useLanguage";
import helpers from "scripts/helpers";
import { Languages } from "scripts/mobx/languageStore";
import linkedAccountStore from "scripts/mobx/linkedAccountStore";
import ViewLocation from "../../locations/viewLocation";
import {
  tableLocationsColumns,
  tableOrgDetailsFundsColumns,
  tableOrgDetailsFundsData,
  tableOrgDetailsTitleColumns,
  tableOrgDetailsTitleData,
} from "../../tableGenerator";
import "./index.less";
const { confirm } = Modal;

interface IEditKidzBizDetailsProps {
  data: any;
  onBack: () => void;
  providerStore?: any;
  dictionaryStore?: any;
  takeRate?: any;
}

const EditLinkedKidzbizDetail = inject("languageStore")(
  observer((props: IEditKidzBizDetailsProps) => {
    const { data, takeRate, onBack } = props;
    const lang = getLanguage();
    const { t } = useTranslation("base");
    const userType = useType();
    const isBlocked = userBlocked();

    const [editLocation, setEditLocation] = useState(false);
    const [locationData, setLocationData] = useState<any>();
    const [isLoading, setLoading] = useState<boolean>(false);

    const onClickEditLocationData = (data: any) => {
      console.log("onClickEditLocationData:", data);
      const location = {
        ...data.record,
      };
      setLocationData(location);
      setEditLocation(true);
    };

    const locationEditComponent = (data: any) => (
      <ButtonLink onClick={() => onClickEditLocationData(data)}>
        {t("bizBase.view")}
        <i
          className={bizIcons.ARROWRIGHT}
          style={{ marginLeft: "8px", fontSize: "16px" }}
        />
      </ButtonLink>
    );

    const locations = data?.locations
      ?.filter((el: any) => ![0, -2]?.includes(el?.address?.id))
      ?.map((item: any) => {
        const param = {
          name: item?.name,
          email: item?.email,
          phoneNumber: item?.mobile,
          address: item?.address?.addressLine,
          area: item?.address?.area,
          suburb: item?.address?.suburb,
          country: item?.address?.country,
          postalCode: item?.address?.postalCode,
          id: item?.id,
        };
        return param;
      }, []);

    const onDelinkAccount = () => {
      setLoading(true);
      linkedAccountStore
        ?.delinkAccount(data?.id)
        .then(() => {
          notification.success({
            message: t("kidzbiz.delink_success"),
          });
          setTimeout(() => {
            setLoading(false);
            onBack();
          }, 300);
        })
        .catch((err) => {
          helpers.throwErrors(t, [], err?.data?.error, "");
        });
    };

    const showConfirmForDelink = () => {
      confirm({
        title: t("kidzbiz.are_you_sure_you_want_to_delink_the_account"),
        content: t("kidzbiz.delink_note"),
        autoFocusButton: null,
        icon: "",
        okText: t("kidzbiz.delink"),
        cancelText: t("bizBase.cancel"),
        onOk() {
          onDelinkAccount();
        },
        // onCancel()
      });
    };
    const onClickDelinkPropvider = async () => {
      // if (linkedProvider && linkedProvider?.id) {
      //   await providerStore?.delinkProviderRequest(linkedProvider?.id);
      showConfirmForDelink();
    };

    return (
      <div>
        {editLocation ? (
          <ViewLocation
            data={locationData}
            onBack={() => setEditLocation(false)}
          />
        ) : (
          <Spin spinning={isLoading} size="large">
            <div className="linked-accounts content-wrapper content-with-table">
              <ButtonLink onClick={() => onBack()}>
                <i className={`${bizIcons.ARROWLEFT} fs-20 mr-8`} />
                {t("kidzbiz.backToKidzbiz")}
              </ButtonLink>

              <div className="content">
                <div className="bsl-4 flex">
                  <div className={"avatar"}>
                    <div
                      className="avatar-img"
                      style={{
                        backgroundImage: `url(${
                          (data?.logoImageUrl?.match(/data:image\/(.*);base64,/)
                            ? ""
                            : "") + data?.logoImageUrl
                        })`,
                      }}
                    />
                  </div>
                  <div>
                    <div>
                      <Title level={2}>
                        {data?.firstName + " " + data?.lastName}
                      </Title>
                    </div>

                    <Title
                      level={4}
                      className={"text-color-secondary"}
                      style={{ marginTop: "4px" }}
                    >
                      {data?.legalName}
                    </Title>
                  </div>
                </div>
                {!isBlocked && (
                  <div
                    style={{
                      paddingTop: "13px",
                      marginTop: "16px",
                      fontWeight: 500,
                    }}
                  >
                    <ButtonLink onClick={onClickDelinkPropvider}>
                      <i
                        className={`${bizIcons.UNLINK}`}
                        style={{ marginRight: "8px", fontSize: "16px" }}
                      />
                      {t("kidzbiz.delinkLinked")}
                    </ButtonLink>
                  </div>
                )}

                <TableComponent
                  broken={true}
                  grid={true}
                  noSeparators={true}
                  pagination={false}
                  propsTableColumns={tableOrgDetailsTitleColumns(t)}
                  propsTableData={tableOrgDetailsTitleData({
                    contactPersonFirstName: data?.firstName,
                    contactPersonLastName: data?.lastName,
                    mobilePhone: data?.mobile,
                    landlinePhone: data?.landline,
                    email: data?.email,
                  })}
                />
                <Divider style={{ marginTop: "0px", marginBottom: "0px" }} />
                <TableComponent
                  broken={true}
                  grid={true}
                  noSeparators={true}
                  pagination={false}
                  propsTableColumns={tableOrgDetailsFundsColumns(
                    {
                      transactionVolume:
                        data?.transactionSummary?.transactionVolume12m || "0",
                      takeRate: takeRate,
                      kidsEnrolled:
                        data?.transactionSummary?.numOfKidsEnrolled || "0",
                      numberOfCourses:
                        data?.transactionSummary?.numOfCourses || "0",
                      numberOfCancellationsRefunds:
                        data?.transactionSummary?.numOfCancellationsRefunds ||
                        "0",
                      id: 1,
                    },
                    t,
                    userType,
                  )}
                  propsTableData={tableOrgDetailsFundsData({
                    accruedPayoutMtd:
                      data?.transactionSummary?.accruedPayoutMtd || "0",
                    transactionVolume:
                      data?.transactionSummary?.transactionVolume12m || "0",
                    takeRate: takeRate,
                    kidsEnrolled:
                      data?.transactionSummary?.numOfKidsEnrolled || "0",
                    numberOfCourses:
                      data?.transactionSummary?.numOfCourses || "0",
                    numberOfCancellationsRefunds:
                      data?.transactionSummary?.numOfCancellationsRefunds ||
                      "0",
                    id: 1,
                  })}
                />
                <div className="tsl-3" />
                <div className="tsl-5" />
                <div className={"flex-space-between"}>
                  <div className={"semi-bold "}>
                    {" "}
                    {t("kidzbiz.listLocations")}
                  </div>
                </div>
                <Divider style={{ marginBottom: "0px" }} />
                <TableComponent
                  broken={true}
                  pagination={false}
                  propsTableColumns={tableLocationsColumns(
                    false,
                    locationEditComponent,
                  )}
                  propsTableData={locations}
                />
              </div>
            </div>
          </Spin>
        )}
      </div>
    );
  }),
);

export default EditLinkedKidzbizDetail;
