import keycloak from "helpers/keycloak";
import i18n from "i18n";
import { inject, observer } from "mobx-react";
import React from "react";
import { Redirect, Route } from "react-router-dom";
import {
  useCurrentUser,
  useCurrentVerify,
} from "scripts/custom-hooks/useCurrentUser";

const PrivateRoute = inject("userStore")(
  observer((props: any) => {
    const { component: Component, userStore, withVerifying, ...rest } = props;
    const user = useCurrentUser();
    const isVerified = useCurrentVerify();
    const lang = "en"; // i18n.language.toLocaleLowerCase() === "zh" ? "zh-CN" : "en";

    React.useEffect(() => {
      if (!keycloak?.authenticated) {
        keycloak.login({ locale: lang });
      }
    }, []);

    return (
      <Route
        {...rest}
        render={(props) => {
          if (!user) {
            return <Redirect to="/" />;
          }
          if (!isVerified && withVerifying) {
            return <Redirect to="/403" />;
          }
          return <Component {...props} />;
        }}
      />
    );
  }),
);

export default PrivateRoute;
