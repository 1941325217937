import React from "react";
import { Typography } from "antd";
const { Title } = Typography;

const EnPrivacyPolicy = () => {
  return (
    <>
      <Title>Privacy Policy</Title>
      <div className={"bizibuz-policies-lists"}>
        <ol>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
              Your Agreement
            </Title>
            <ol>
              <li>
              Bizibuz Hong Kong Limited and its affiliated companies (“we”, “us”, “our” or “Bizibuz”) is the owner and operator of www.bizibuz.com (the “Website”), its various subdomains including but not limited to the Kidzbiz subdomain located at kidzbiz.bizibuz.com (the “Kidzbiz Portal”) and potentially also other Bizibuz branded apps (together, the “Bizibuz Platform”), are committed to protecting and respecting your personal data privacy and complying with data protection principles and provisions under applicable laws.
              </li>
              <li>
              We may collect, process, use and disclose your information when you use the Bizibuz Platform and the services offered by Bizibuz or the third party education companies or operators (the “Activity Providers”) (together, the “Services”) as described in this Privacy Policy. “You”, “your” and “user” when used in this Privacy Policy includes any person who accesses the Bizibuz Platform or uses the Services.
              </li>
              <li>
              This Privacy Policy sets out the basis and terms upon which Bizibuz collects, processes, uses and/or discloses your information that is obtained from you when you access the Bizibuz Platform and/or use the Services. Such information may include personal information relating to or linked to (a) specific individual(s) such as name, residential address, telephone number, email address, date of birth of family members, medical conditions or any such information we have requested and you have provided through the Bizibuz Platform ('Personal Information').
              </li>
              <li>
                <b>Please read this Privacy Policy carefully.</b> By visiting the Bizibuz Platform, you are consenting to the collection, processing, usage and disclosure of your Personal Information as set out in this Privacy Policy.

              </li>
            </ol>
          </li>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
              Scope of Terms
            </Title>
            <ol>
              <li>
              Bizibuz reserves the right to update, amend or modify the terms of this Privacy Policy or any part of it without prior notice, and your continued access of Bizibuz Platform or use of the Services signifies your acceptance of the updated, amended or modified Privacy Policy, unless if the changes reduce your rights in which case we will ask for your consent. If you do not agree to all the terms and conditions in this Privacy Policy and/or any subsequent updates, amendments or modifications thereto, you must stop accessing or otherwise using the Bizibuz Platform and the Services. Accordingly, please visit this page if you wish to access and view the current version of this Privacy Policy.
              </li>
            </ol>
          </li>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
              Collection of Information
            </Title>
            <ol>
              <li>
              We may collect Personal Information about you that you provide to us while using the Bizibuz Platform and information about how you use the Bizibuz Platform including when you set up your account ('User Account'), visit the Bizibuz Platform or book educational activities or otherwise use the Services. Providing your Personal Information to Bizibuz is always on a voluntary basis. However, we might not be able to provide you with certain Services if you choose not to give us your Personal Information. For example, we cannot facilitate the booking of an educational activity if we cannot use your participating child’s birth date to confirm age appropriateness of the activity. 
              </li>
              <li>
              When you open a User Account on the Bizibuz Platform or amend any information associated with your User Account, we may collect your Personal Information, such as your name, email address, username, password and telephone number.
              </li>
              <li>
              When you visit the Bizibuz Platform (even if you have not set up a User Account or logged in), book educational activities or use the Services, we may collect and process certain information (which may contain your Personal Information or may contain non-personally identifiable information but nevertheless linked to your Personal Information) including but not limited to those set out below: <br />
              (i) Copies of correspondence (whether by e-mail, instant or personal messaging or otherwise) between you and us, and between you and the Activity Providers; <br />
(ii) Information necessary to complete your educational activity bookings with Activity Providers Operators (including but not limited to date of birth of participating children, relevant medical conditions, parent contact details);<br />
(iii) Details of your usage of the Bizibuz Platform (including traffic data, location data and length of user sessions); 
(iv) Feedback on and responses to surveys conducted by Bizibuz relating to the Services and newsletters which may be published, circulated or distributed by Bizibuz; <br />
(v) Information automatically collected and stored in our server or the server of our third party services provider by using or accessing to the Bizibuz Platform (including the log-in name and password for your User Account, your computers Internet Protocol (IP) address, browser type, browser information, device information (including unique device identifier), pages visited, previous or subsequent sites visited).

              </li>
              <li>
              When you use your mobile devices to visit Bizibuz Platform, we may collect information about you and your mobile device as stated above. This can include location information if you have consented and configured your mobile device to send us such information. Such location information will be used in the ways and for the purposes as stated in this Privacy Policy. For example, we may use your location to suggest nearby Services or provide personalised content. You can change your mobile device’s privacy settings at any time to deactivate the function of sharing location information. 

              </li>
              <li>Given you are purchasing educational activities for your children through the Bizibuz Platform, we may request personal information about your children. You should obtain all requisite consent of such individuals and ensure they are aware of, understand and accept this Privacy Policy prior to providing their personal data to Bizibuz.</li>
            </ol>
          </li>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
              Storage of Information
            </Title>
            <ol>
              <li>
              The Personal Information and other data we collect from you may be transferred to, processed, and stored in our servers or the servers of our third party services providers. Your Personal Information will be stored as long as is necessary to fulfil any of the purposes described in this Privacy Policy, or to comply with any (i) contractual requirement that are necessary for the provision of the Services, and (ii) legal, tax or accounting requirement.
When it is no longer necessary for us to process your Personal Information, we will either delete or anonymise the data or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information. We will endeavor to anonymise or aggregate your data if we intend to use it for analytical purposes or trend analysis.

              </li>
              <li>
              Bizibuz will use reasonable endeavours to maintain appropriate physical, electronic and organisational procedures to ensure that your Personal Information and other data is treated securely and in accordance with this Privacy Policy, and to protect such data against unauthorized access or unauthorized alteration, disclosure or destruction of data.
Once we have received your information, we will use strict procedures and security features to try to prevent unauthorized access. Bizibuz does not give any representation, warranty or undertaking that the Personal Information you provide to us will be secure at all times, and to the extent Bizibuz has fulfilled its obligations under no circumstances shall Bizibuz be responsible for any losses, damages, costs and expenses which you may suffer or incur arising from unauthorised access to or use of your Personal Information. All payment transactions carried out by us or our chosen third-party provider of payment processing services will be encrypted using online encryption technology. You are responsible for keeping your chosen password confidential and not to share your password with any third party.
              </li>
            </ol>
          </li>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
              Usage of Information
            </Title>
            <ol>
              <li>
              We process the Personal Information collected in as far as necessary for facilitating the provision of Services to you and on the basis of our legitimate interests, which are the further improvement of the Services and for direct marketing purposes. For example, Bizibuz will use Personal Information and other data collected through the Bizibuz Platform or when making purchases of the Services to fulfil your booking and purchase of the Services, to continually improve the Bizibuz Platform and the Services, and to contact you in relation to the Services. This includes using your Personal Information or such other data to achieve faster booking acceptances, fewer cancellations, better customer support and to provide you with timely notice of new Services and special offers.
              </li>
              <li>
              From time to time, we may also make use of your Personal Information to contact you for feedback on your use of the Bizibuz Platform, to assist us in improving the Bizibuz Platform, or to offer special savings or promotions to you, where you have indicated your consent to receiving such communications. If you would prefer not to receive notices of special savings or promotions, you may simply opt-out from receiving them by replying to us through the hyperlink provided in these notices. 
              </li>
            </ol>
          </li>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
              Disclosure of Information
            </Title>
            <ol>
              <li>
              We may from time to time share and disclose your Personal Information and other data to third parties. The circumstances under which such sharing and disclosure will take place may include without limitation, the following: <br />
              (i) To successfully complete your booking requests or to otherwise implement our Terms of Use, we may share your information with Activity Providers who deliver Services that have been purchased by you through the Bizibuz Platform.<br />
(ii) If you are an Activity Provider, to any user in connection with the Services you are offering.<br />
(iii) To our third party service providers (including Google Analytics), which we engage amongst others for the performance of certain services on our behalf, such as web hosting services, data analysis, marketing, market research, and to otherwise provide you with customer service.<br />
(iv) If and to the extent required by any applicable law, order of court or requests by any governmental authority to make such disclosure.<br />
(v) Within the Bizibuz group of companies. In case of a corporate transaction, in connection with the sale, merger, acquisition, or other corporate reorganization or restructuring of our corporation, your Personal Information may be disclosed, shared or transferred to the new controlling entity or its authorised third party for carrying on our business.<br />
(vi) To our advisors, agencies or other parties concerned in order to protect the rights and property of Bizibuz.<br />
(vii) In any other case, to any third parties with your prior written consent.
              </li>
              <li>
              We may also share aggregate or anonymous information with relevant third parties, including our advertisers. Such information does not contain any Personal Information and will not identify you personally. 
              </li>
              <li>
              There may be links present on the Bizibuz Platform which could result in you leaving the Bizibuz Platform and/or being taken to other third party websites. You should note that any Personal Information that you provide to these third party websites are not subject to this Privacy Policy, and Bizibuz is not liable for any losses, damages, costs or expenses which you may suffer or incur in connection with you providing or making available Personal Information or other data to such third party websites.

              </li>
            </ol>
          </li>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
              Cookies
            </Title>
            <ol>
              <li>
              Cookies are widely used in order to make websites work, or work more efficiently. When you visit our Website, we collect some of your Personal Information transmitted to us by your browser via cookies. This enables you to access the Bizibuz Platform and helps us to create better user experience for you. You will find more details about cookies and similar technologies that we use, in our Cookies Policy.
              </li>
            </ol>
          </li>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
              Your rights
            </Title>
            <ol>
              <li>
              You may at all times access or correct your Personal Information through the Bizibuz Platform via the user portal, under the “My Profile” area of the main navigation panel. Where mandatory under applicable legislation, you may also request restriction of processing of your Personal Information or object to processing by sending your request or objection by email to info@bizibuz.com. You may also request a copy of the information that we hold about you by sending your request by email to info@bizibuz.com.
              </li>
              <li>
              Please contact us via the contact details mentioned below if you have a complaint regarding the processing of your Personal Information. When handling any of these requests described above, we have the right to check the identity of the requester to ensure that he/she is the person entitled to make the request.
              </li>
            </ol>
          </li>
        </ol>
        <div className={"tsl-3"}>
          <div>
            <b>Inquiries</b>
          </div>
          <div>
          If you have any questions about this Privacy Policy, please contact us by email at info@bizibuz.com.
          </div>
        </div>
        <div className={"bsl-3 tsl-3"}>Last updated on: 22 April 2021.</div>
      </div>
    </>
  );
};

export default EnPrivacyPolicy;
