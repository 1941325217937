import React, { useCallback, useContext, useState } from "react";
import "./index.less";
import { LayoutPage } from "../../../components/Layout";
import { observer } from "mobx-react-lite";
import ButtonLink from "../../../components/ButtonLink";
import { AccountSettingsRoute } from "../../../routes";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Button, Form, Input, message, notification } from "antd";
import { useTranslation } from "react-i18next";
import userStore from "scripts/mobx/userStore";
import Cookies from "js-cookie";
import keycloak from "helpers/keycloak";
import { useRoles } from "scripts/custom-hooks/useCurrentUser";
import { setConfigAxios } from "helpers/requestApi";

const ChangeEmail = observer((props: RouteComponentProps) => {
  const { history } = props;
  const { t } = useTranslation("base");
  const text1 = t("messages.unrecognized_error", {
    defaultValue: "Unrecognized error",
  });
  // const providerStore = useContext(providerStoreContext);

  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isVerified, setVerified] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>();
  const [keyMessage, setKeyMessage] = useState<string>();
  const [form] = Form.useForm();
  const [formVerify] = Form.useForm();

  const onFinish = (values: any) => {
    setIsLoading(true);
    form
      .validateFields([Object.keys(values)])
      .then(() => {
        userStore
          ?.changeEmailVerification("en", values.newEmail)
          .then(() => {
            setIsLoading(false);
            notification.success({
              message: t("profile.sended_code", {
                email: values.newEmail,
              }),
            });
            setTimeout(() => setVerified(true), 300);
          })
          .catch((error) => {
            setIsLoading(false);
            const errorType = [
              "com.bizibuz.core.service.user.EmailInUsedException",
              "com.bizibuz.core.service.user.UserNotFoundException",
              "com.bizibuz.core.service.user.BizibuzEmailException",
            ];
            let text = t("server_errors." + "Bad Request");
            if (errorType.includes(error?.data?.error)) {
              text = t("profile." + error?.data?.error);
            }
            setErrorText(text);
          });
      })
      .catch((e) => {
        const { _message } = e;
        console.log(e);
        message.error(_message || text1);
        setIsLoading(false);
      });
  };

  const validateForm = useCallback(
    (field?: string) => {
      form
        .validateFields(field ? [field] : undefined)
        .then(
          (r) => {
            setIsDisabled(false);
          },
          (rej) => {
            setIsDisabled(true);
          },
        )
        .catch((e) => {
          const { _message } = e;
          message.error(_message || text1);
        });
    },
    [isDisabled],
  );

  const onLogoutButton = async () => {
    Cookies.set("react-token", "undefined");
    Cookies.set("react-refresh-token", "undefined");
    setConfigAxios();
    keycloak.logout();
  };

  const onUpdateEmail = (values: any) => {
    setKeyMessage("");
    setIsLoading(true);
    form
      .validateFields([Object.keys(values)])
      .then(() => {
        userStore
          ?.updateUserEmail(form.getFieldValue("newEmail"), values?.code)
          .then(() => {
            message.success(
              t("accountSettings.messages.email_changed_success", {
                defaultValue:
                  "Email address changed successfully. Please login with the new email address.",
              }),
            );

            setTimeout(() => onLogoutButton(), 400);
          })
          .catch((error) => {
            setIsLoading(false);
            const errorType = [
              "com.bizibuz.core.service.user.EmailInUsedException",
              "com.bizibuz.core.service.user.UserNotFoundException",
              "com.bizibuz.core.service.user.EmailChangeRequestVerificationException",
            ];
            let text = t("server_errors." + "Bad Request");
            if (errorType.includes(error?.data?.error)) {
              text = t("server_errors." + error?.data?.error);
            }
            setErrorText(text);
            setKeyMessage(error?.data?.error);
          });
      })
      .catch((e) => {
        const { _message } = e;
        message.error(_message || e.message);
        setIsLoading(false);
      });
  };

  return (
    <LayoutPage>
      <div className="account-settings-change-email biz-view-content">
        <div className="biz-back-btn">
          <ButtonLink
            onClick={() => {
              history.push(AccountSettingsRoute.path);
            }}
          >
            <i className={"ri-arrow-left-s-line"} />
            {t("accountSettings.BackToAccountSettings", {
              defaultValue: "Back to Account Settings",
            })}
          </ButtonLink>
        </div>
        <div className="head-title bsl-4">
          {isVerified
            ? t("profile.verification_code", {
                defaultValue: "Verification code",
              })
            : t("profile.changeEmail", { defaultValue: "Change Email" })}
        </div>
        <div className={"bsl-4"} />
        {isVerified ? (
          <Form
            form={formVerify}
            onLoad={() => validateForm()}
            name="change-email"
            className="change-email-form"
            initialValues={{
              remember: true,
            }}
            onFinish={onUpdateEmail}
            onFieldsChange={() => setErrorText("")}
          >
            <Form.Item className="bsl-4">
              <Form.Item
                name="code"
                rules={[
                  {
                    required: true,
                    message: t("profile.please_enter_code", {
                      defaultValue: "Please enter the verification code",
                    }),
                  },
                ]}
                extra={
                  keyMessage ===
                    "com.bizibuz.core.service.user.EmailChangeRequestVerificationException" && (
                    <ButtonLink
                      style={{ marginTop: 6 }}
                      onClick={() => onFinish(form.getFieldsValue())}
                    >
                      {t("profile.resend", {
                        defaultValue: "Resend",
                      })}
                    </ButtonLink>
                  )
                }
              >
                <Input
                  id={"code"}
                  prefix={<i className="ri-mail-line mr-10 fs-20" />}
                  placeholder={t("profile.enter_code", {
                    defaultValue: "Enter verification code",
                  })}
                  readOnly={isLoading}
                  onChange={() => validateForm("code")}
                  size={"large"}
                  onKeyPress={(e: any) => {
                    if (e.key) {
                      if (!new RegExp(/^[0-9]$/g).test(e.key)) {
                        e.preventDefault();
                      }
                    }
                  }}
                />
              </Form.Item>
            </Form.Item>

            <Form.Item>
              <Button
                className="full-width fs-16"
                type="primary"
                htmlType="submit"
                loading={isLoading}
                disabled={isDisabled}
                icon={
                  <div className={"flex-center"}>
                    {t("bizBase.submit", { defaultValue: "Submit" })}
                    <i className="ri-arrow-right-line ml-10 fs-20" />
                  </div>
                }
              />
            </Form.Item>
          </Form>
        ) : (
          <Form
            form={form}
            onLoad={() => validateForm()}
            name="change-email"
            className="change-email-form"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFieldsChange={() => setErrorText("")}
          >
            <Form.Item className="bsl-4">
              <Form.Item
                name="newEmail"
                rules={[
                  {
                    type: "email",
                    message: t("accountSettings.messages.email_format", {
                      defaultValue: "The input is not valid E-mail!",
                    }),
                  },
                  {
                    required: true,
                    message: t("accountSettings.messages.required_email", {
                      defaultValue: "Please input new E-mail!",
                    }),
                  },
                ]}
              >
                <Input
                  id={"newEmail"}
                  prefix={<i className="ri-mail-line mr-10 fs-20" />}
                  placeholder={t("kidzbiz.enterEmail", {
                    defaultValue: "Enter email",
                  })}
                  readOnly={isLoading}
                  onChange={() => validateForm("newEmail")}
                  size={"large"}
                />
              </Form.Item>
            </Form.Item>

            <Form.Item>
              <Button
                className="full-width fs-16"
                type="primary"
                htmlType="submit"
                loading={isLoading}
                disabled={isDisabled || isLoading}
                icon={
                  <div className={"flex-center"}>
                    {t("accountSettings.changeEmail", {
                      defaultValue: "Change email",
                    })}
                    <i className="ri-arrow-right-line ml-10 fs-20" />
                  </div>
                }
              />
            </Form.Item>
          </Form>
        )}
        {!!errorText && <p className="error-text"> {errorText}</p>}
      </div>
    </LayoutPage>
  );
});

export default withRouter(ChangeEmail);
