import React, { ReactElement } from "react";
import ButtonLink from "../../components/ButtonLink";
import { bizIcons, bizIcons as tb } from "../../constants/bizConstants";
import { Rating } from "../../components/RatingStars";
import { Link } from "react-router-dom";
import helpers from "../../scripts/helpers";
import { AppRoutes } from "helpers/app.routes";
import { Popover } from "antd";
import moment from "moment";
import { FORMAT_DATE } from "constants/commons";

export const tableReviewsColumns = (
  t: any,
  broken: boolean,
  isLinked?: boolean,
) => {
  const columns = [
    {
      title: t("bizBase.name"),
      dataIndex: "customerName",
      key: "customerName",
      render: (text: React.ReactNode, record: any) => {
        const fullName =
          record?.customer?.firstName + " " + record?.customer?.lastName;
        return (
          <div>
            <Popover
              overlayClassName={`popover-ellipsis ${
                broken || !fullName ? "display-none" : ""
              }`}
              content={fullName}
              trigger="hover"
            >
              <div className="ellipsis">{fullName}</div>
            </Popover>
            {broken && (
              <Link to={AppRoutes.reviewId(record.id)}>
                <ButtonLink
                  style={{
                    cursor: "pointer",
                    marginLeft: "16px",
                    fontWeight: 400,
                  }}
                >
                  {t("bizBase.view")}
                  <i
                    className={`${bizIcons.ARROWRIGHT}`}
                    style={{ fontSize: "16px" }}
                  />
                </ButtonLink>
              </Link>
            )}
          </div>
        );
      },
    },
    {
      title: (
        <div>
          <i key={Math.random()} className={tb.STAR} />
          {t("reviews.rating")}
        </div>
      ),
      key: "rating",
      dataIndex: "rating",
      render: (text: any) => {
        return (
          <div>
            {text && (
              <div style={{ marginRight: "8px", display: "inline-block" }}>
                <Rating value={text} showCount={true} disabled={true} />
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: (
        <div>
          <i key={Math.random()} className={tb.DATECHECK} />
          {t("bizBase.date")}
        </div>
      ),
      dataIndex: "timestamp",
      key: "timestamp",
      render: (text: any, record: any) => (
        <div>
          {broken && (
            <i
              className={tb.DATECHECK}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
          )}
          {record?.createdDate
            ? helpers.formatDateTime(record?.createdDate)
            : "-"}
        </div>
      ),
    },
    {
      title: (
        <div>
          <i key={Math.random()} className={tb.BOOKOPEN} />
          {t("reviews.courseName")}
        </div>
      ),
      key: "courseName",
      dataIndex: "courseName",
      render: (text: any, record: any) => {
        const name = record?.course?.name;
        return (
          <Popover
            overlayClassName={`popover-ellipsis ${
              broken || !name ? "display-none" : ""
            }`}
            content={name}
            trigger="hover"
          >
            <div className="ellipsis">
              {broken && name && (
                <i
                  className={tb.BOOKOPEN}
                  style={{ fontSize: "16px", marginRight: "8px" }}
                />
              )}
              {name}
            </div>
          </Popover>
        );
      },
    },
    {
      title: (
        <div>
          <i key={Math.random()} className={tb.UL} />
          {t("reviews.courseCategory")}
        </div>
      ),
      key: "courseCategory",
      dataIndex: "courseCategory",
      render: (text: any, record: any) => {
        const code = record?.course?.category;
        return (
          <Popover
            overlayClassName={`popover-ellipsis ${
              broken || !code ? "display-none" : ""
            }`}
            content={code}
            trigger="hover"
          >
            <div className="ellipsis">
              {broken && code && (
                <i
                  className={tb.UL}
                  style={{ fontSize: "16px", marginRight: "8px" }}
                />
              )}
              {t("categories." + code)}
            </div>
          </Popover>
        );
      },
    },
  ];

  if (isLinked) {
    const finIndexStatus = columns?.findIndex(
      (el) => el.key === "courseCategory",
    );
    const elm = {
      title: (
        <div>
          <i key={Math.random()} className={tb.BUILDING} />
          {t("menu.kidzbiz")}
        </div>
      ),
      key: "legalName",
      dataIndex: "legalName",
      render: (text: any, record: any) => {
        const legalName = record?.course?.legalName;
        return (
          <Popover
            overlayClassName={`popover-ellipsis ${
              broken || !legalName ? "display-none" : ""
            }`}
            content={legalName}
            trigger="hover"
          >
            <div className="ellipsis">
              {broken && legalName && (
                <i
                  className={tb.BUILDING}
                  style={{ fontSize: "16px", marginRight: "8px" }}
                />
              )}
              {legalName}
            </div>
          </Popover>
        );
      },
    };
    columns.splice(finIndexStatus + 1, 0, elm);
  }

  if (!broken) {
    columns.push({
      title: t("bizBase.action"),
      key: "action",
      dataIndex: "action",
      render: (text: React.ReactNode, record: any) => (
        // ActionElement HERE
        <Link to={AppRoutes.reviewId(record.id)}>
          <ButtonLink
            style={{
              cursor: "pointer",
            }}
          >
            {t("bizBase.view")}
            <i
              className="ri-arrow-drop-right-line"
              style={{ fontSize: "16px" }}
            />
          </ButtonLink>
        </Link>
      ),
    });
  }

  return columns;
};

export const tableViewReviewColumns = (t: any, isLinked: boolean) => {
  const columns = [
    {
      dataIndex: "",
      key: "",
    },
    {
      dataIndex: "TimestampWritten",
      key: "TimestampWritten",
      render: (text: any) => (
        <div>
          <div className="td-title flex align-center">
            <i
              className={tb.DATECHECK}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            {t("reviews.timestampWritten")}
          </div>
          {text || "-"}
        </div>
      ),
    },
    {
      key: "Rating",
      dataIndex: "Rating",
      render: (text: any) => (
        <div>
          <div className="td-title flex align-center">
            <i
              className={tb.STAR}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            {t("reviews.rating")}
          </div>
          {text || "-"}
        </div>
      ),
    },
    {
      key: "Email",
      dataIndex: "Email",
      render: (text: any) => (
        <div>
          <div className="td-title flex align-center">
            <i
              className={tb.MAIL}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            {t("bizBase.email")}
          </div>
          {text || "-"}
        </div>
      ),
    },
    {
      key: "Suburb",
      dataIndex: "Suburb",
      render: (text: any) => (
        <div>
          <div className="td-title flex align-center">
            <i
              className={tb.BUILDING}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            {t("bizBase.location")}
          </div>
          {text || "-"}
        </div>
      ),
    },
    {
      key: "CourseName",
      dataIndex: "CourseName",
      render: (text: any) => (
        <div>
          <div className="td-title flex align-center">
            <i
              className={tb.BOOKOPEN}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            {t("reviews.courseName")}
          </div>
          {text || "-"}
        </div>
      ),
    },
    {
      key: "CourseCategory",
      dataIndex: "CourseCategory",
      render: (text: any) => (
        <div>
          <div className="td-title flex align-center">
            <i
              className={tb.BOOKOPEN}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            {t("reviews.courseCategory")}
          </div>
          {text ? t("categories." + text) : "-"}
        </div>
      ),
    },
    {
      key: "KidzbizName",
      dataIndex: "KidzbizName",
      render: (text: any) =>
        text &&
        isLinked && (
          <div>
            <div className="td-title flex align-center">
              <i
                className={tb.BUILDING4}
                style={{ fontSize: "16px", marginRight: "8px" }}
              />
              {t("menu.kidzbiz")}
            </div>
            {text || "-"}
          </div>
        ),
    },
  ];

  return columns;
};

export const tableViewReviewData = (data: any = {}) => {
  return [
    {
      TimestampWritten: helpers.formatDateTime(data.createDate),
      Rating: data?.rating?.toFixed(1),
      Email: data?.customer?.email,
      Suburb: data?.course?.locations?.[0]?.name,
      CourseName: data?.course?.name,
      CourseCategory: data?.course?.category,
      KidzbizName: data?.course?.legalName,
      id: data.id,
      key: data.id,
    },
  ];
};
