import { PAGE_SIZE } from "config";

export enum CourseTab {
  Current = "Current",
  Draft = "Draft",
  Past = "Past",
}

export enum CourseStatus {
  Draft = "DRAFT",
  Pending = "PENDING",
  InProgress = "IN_PROGRESS",
  Canceled = "CANCELLED",
  Completed = "COMPLETED",
  Blocked = "BLOCKED",
}

export enum CourseType {
  Online = "Online",
  Offline = "Offline",
}

export enum KidsAgeType {
  Months = "Months",
  Years = "Years",
}

export enum DayOfWeek {
  Sunday = "SUNDAY",
  Monday = "MONDAY",
  Tuesday = "TUESDAY",
  Wednesday = "WEDNESDAY",
  Thursday = "THURSDAY",
  Friday = "FRIDAY",
  Saturday = "SATURDAY",
}

export interface ClassDatesType {
  date: string;
  startTime: string;
  endTime: string;
  dayOfWeek: DayOfWeek | string;
}

export interface CourseBodyParams {
  name: string;
  code: string;
  category: string;
  subCategory: string;
  locationId: number;
  ageFrom: number;
  ageTo: number;
  imageUrl: string;
  classes: ClassDatesType[];
  description: string;
  pricePerClass: number;
  minClassesForSale: number;
  capacity: number;
  publishCourse: boolean;
}

export interface PromoBodyParams {
  id?: string;
  name?: string;
  type: string;
  threshold?: number;
  discountPercentage?: number;
  effectiveFrom: string;
  effectiveTo: string;
  courseIds?: number[];
  discountPercentageOrValue?: number;
  applyToAllCourse?: boolean;
  currency?: string;
}

export enum PromotionTab {
  Bulk = "BULK",
  Course = "COURSE",
}

export interface FilterType {
  page?: number;
  pageNumber?: number;
  pageSize?: number;
  sortField?: string;
  sortDirection?: string;
}

export enum NotificationPlatforms {
  IN_APP = "IN_APP",
  EMAIL = "EMAIL",
  SMS = "SMS",
}

export enum NotificationStatus {
  Unreaded = "Unreaded",
  Readed = "Readed",
}

export enum NotificationModule {
  account_blockage = "ACCOUNT_BLOCKAGE",
  blockage_removal = "BLOCKAGE_REMOVAL",
  new_booking_request = "NEW_BOOKING_REQUEST",
  pending_booking_requests = "PENDING_BOOKING_REQUESTS",
  booking_cancellation = "BOOKING_CANCELLATION",
  course_cancellation = "COURSE_CANCELLATION",
  outdated_booking_cancellation = "OUTDATED_BOOKING_CANCELLATION",
  customer_review = "CUSTOMER_REVIEW",
  review_removal = "REVIEW_REMOVAL",
  account_linkage = "ACCOUNT_LINKAGE",
  linkage_invite_expiry = "LINKAGE_INVITE_EXPIRY",
  account_linkage_removal = "ACCOUNT_LINKAGE_REMOVAL",
  employee_account_creation = "EMPLOYEE_ACCOUNT_CREATION",
  employee_account_deactivation = "EMPLOYEE_ACCOUNT_DEACTIVATION",
  course_cancellation_for_provider = "COURSE_CANCELLATION_FOR_PROVIDER",
  class_cancellation_for_provider = "CLASS_CANCELLATION_FOR_PROVIDER",
}

export interface NotificationType {
  id: number;
  refId: number;
  title: string;
  message?: string;
  type?: NotificationModule;
  status?: string;
  content?: {
    customerName?: string;
    courseName?: string;
    courseCode?: string;
    organizationLegalName?: string;
    link?: string;
  };
}

export enum RescheduleType {
  REFUND = "REFUND",
  CREDIT = "CREDIT",
  NO_COMPENSATION = "NO_COMPENSATION",
  PAYMENT = "PAYMENT",
  PAYMENT_CREDIT = "PAYMENT_CREDIT",
  PROVIDER_CREDIT = "PROVIDER_CREDIT",
  ALIPAY = "ALIPAY",
  WECHAT_PAY = "WECHAT_PAY",
  CREDIT_CARD = "CREDIT_CARD",
  CANCELLATION = "CANCELLATION",
}

export enum PaymentStatus {
  PAID = "PAID",
  PENDING = "PENDING",
}

export enum PaymentTemplate {
  GENERIC = "GENERIC",
  XERO = "XERO",
}

export enum PreferredLanguage {
  "English" = "ENGLISH",
  "Chinese" = "CHINESE",
}

export const pageableRezo: FilterType = {
  pageNumber: 0,
  pageSize: PAGE_SIZE,
  sortField: "id",
  sortDirection: "desc",
};
export const pageableFirst: FilterType = {
  pageNumber: 1,
  pageSize: PAGE_SIZE,
  sortField: "id",
  sortDirection: "desc",
};

export enum CancelType {
  ADMIN = "ADMIN",
  KIDZBIZ = "KIDZBIZ",
  CUSTOMER = "CUSTOMER",
}
