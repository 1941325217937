import { createContext } from "react";
import { action, computed, makeObservable, observable } from "mobx";
import i18n from "i18n";
import Cookies from "js-cookie";

export interface ILangData {
  lang: Languages;
}

export enum Languages {
  EN = "EN",
  ZH = "ZH",
}

export class LanguageStore {
  constructor() {
    makeObservable(this);
  }

  @observable
  private langData: ILangData = {
    lang: Languages.EN,
  };

  @action
  public setLang(lg: Languages) {
    if (this.langData.lang !== lg) {
      const lang = lg === Languages.ZH ? "zh-CN" : "en";
      if (process.env.RAZZLE_ENV === "development") {
        Cookies.set("KEYCLOAK_LOCALE", lang, { sameSite: "strict" });
      } else {
        Cookies.set("KEYCLOAK_LOCALE", lang, { domain: ".bizibuz.com" });
      }
      // i18n.changeLanguage(lg);
      i18n.changeLanguage("en");
      this.langData.lang = lg;
    }
  }

  @action
  public clearLang() {
    this.langData.lang = Languages.EN;
  }

  @computed
  public get lang() {
    return this.langData.lang;
  }
}

const languageStore = new LanguageStore();

export const languageStoreContext = createContext(languageStore);
export default languageStore;
