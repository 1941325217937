import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { hydrate } from "react-dom";
import App from "./App";
import { useSSR } from "react-i18next";

const BaseApp = () => {
  // const initialI18nStore = window["initialI18nStore"];
  // const initialLanguage = window["initialLanguage"];

  // useSSR(initialI18nStore, initialLanguage);
  return (
    <Suspense fallback={<div>loading...</div>}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Suspense>
  );
};

hydrate(<BaseApp />, document.getElementById("root"));

if (module.hot) {
  module.hot.accept();
}
