import { Button, Col, DatePicker, Form, Input, notification, Row } from "antd";
import { FORMAT_DATE } from "constants/commons";
import { DayOfWeek } from "constants/type.interface";
import { isEqual, sortBy } from "lodash";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import helpers from "scripts/helpers";
import courseStore from "scripts/mobx/courseStore";
import { ButtonWhite } from "../../../../../components/ButtonWhite";
import { formatTime } from "./data-time";
import { TimePickerComponent } from "./TimePicker";

interface IProps {
  excludedDates: any[];
  scheduleClass: any[];
  data?: any;
  onClear?: () => void;
}

export const FormDate = observer((props: IProps) => {
  const { excludedDates, scheduleClass, data, onClear } = props;
  const { t } = useTranslation("base");
  const [form] = Form.useForm();

  const checkTimeBetween = (date: string, dataTime: string) => {
    // var time = moment() gives you current time. no format required.
    const time = moment(dataTime, formatTime);
    let flag = false;
    scheduleClass.forEach((el) => {
      const beforeTime = moment(el?.startTime, formatTime),
        afterTime = moment(el?.endTime, formatTime);
      if (
        date === moment(el?.date).format(FORMAT_DATE.query) &&
        (time.isBetween(beforeTime, afterTime) ||
          dataTime === el?.startTime ||
          dataTime === el?.endTime)
      ) {
        flag = true;
      } else {
        flag = false;
      }
    });
    return flag;
  };

  const onFinish = (values: any) => {
    const newScheduleClass = [...scheduleClass];
    const dateForm = moment(values?.date).format(FORMAT_DATE.query);
    const finIndex = newScheduleClass?.findIndex((el) => isEqual(el, data));
    if (finIndex !== -1) {
      const item = {
        ...values,
        date: dateForm,
        dayOfWeek: moment(values.date).format("dddd")?.toUpperCase(),
      };
      newScheduleClass[finIndex] = item;
      const newItem = {
        dayOfWeek: item?.dayOfWeek,
        date: item?.date,
        startTime: item?.startTime,
        endTime: item?.endTime,
      };

      if (isEqual(newItem, data)) {
        onClear?.();
      } else if (
        checkTimeBetween(dateForm, values?.startTime) ||
        checkTimeBetween(dateForm, values?.endTime)
      ) {
        notification.error({
          message: t("courses.same_classes"),
        });
      } else {
        if (
          toJS(courseStore?.durationTime) !==
          helpers.getDurationTimes(values?.startTime, values?.endTime)
        ) {
          notification.error({
            message: t("courses.same_classes"),
          });
        } else {
          courseStore.updateClassDates(sortBy(newScheduleClass, ["date"]));
          onClear?.();
        }
      }
    } else {
      onClear?.();
    }
  };

  return (
    <>
      <Form
        form={form}
        name="course-create-step-2-edit-class-dates"
        className="course-create-form tsl-3 form-grid-layout edit-class-dates"
        initialValues={{
          date: moment(data?.date),
          startTime: data?.startTime,
          endTime: data?.endTime,
        }}
        onFinish={onFinish}
      >
        <Form.Item className="bsl-4">
          <div className="label-wrapper">
            <label htmlFor="date">{t("courses.date")}*</label>
          </div>

          <Form.Item
            name="date"
            rules={[
              {
                required: true,
                message: t("messages.required_field"),
              },
            ]}
          >
            <DatePicker
              id={"date"}
              style={{ width: "100%" }}
              showToday={false}
              format={"DD.MM.YY"}
              disabledDate={(current) => {
                return (
                  (current && current < moment().startOf("day")) ||
                  excludedDates.find(
                    (date: string) =>
                      moment(date).format(FORMAT_DATE.query) ===
                      moment(current).format(FORMAT_DATE.query),
                  )
                );
              }}
              placeholder={t("courses.enterDate")}
              size={"large"}
            />
          </Form.Item>
        </Form.Item>
        <Form.Item name="startTime" style={{ display: "none" }}>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item name="endTime" style={{ display: "none" }}>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item shouldUpdate>
          {() => {
            return (
              <>
                <div className="label-wrapper">
                  <label htmlFor="date">{t("courses.time")}*</label>
                </div>
                <div className={"flex-space-between bsl-4"}>
                  <TimePickerComponent
                    form={form}
                    name={DayOfWeek.Monday}
                    onChange={(start, end) => {
                      form.setFieldsValue({ startTime: start, endTime: end });
                    }}
                    value={{
                      startTime: data?.startTime || "",
                      endTime: data?.endTime || "",
                    }}
                    disabled={false}
                  />
                </div>
              </>
            );
          }}
        </Form.Item>
        <Row gutter={24}>
          <Col xs={24} md={12}>
            <Form.Item>
              <div className="flex-align-items tsl-4">
                <ButtonWhite
                  className="fs-16 tsl-4 mr-25"
                  onClick={() => onClear?.()}
                  style={{
                    width: "auto",
                    minWidth: "auto",
                    paddingLeft: 20,
                    paddingRight: 20,
                  }}
                  type="primary"
                >
                  <div className={"flex-center"}>
                    <i className="ri-arrow-left-line mr-8 fs-20" />
                    {t("bizBase.back")}
                  </div>
                </ButtonWhite>
                <Button
                  className="fs-16 tsl-4"
                  type="primary"
                  style={{
                    width: "auto",
                    paddingLeft: 20,
                    paddingRight: 20,
                  }}
                  htmlType="submit"
                  icon={
                    <div className={"flex-center"}>
                      <>
                        {t("bizBase.save")}
                        <i className="ri-arrow-right-line ml-8 fs-20" />
                      </>
                    </div>
                  }
                />
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
});
