import ButtonLink from "components/ButtonLink";
import {
  CHECKBLOCKTYPES,
  countOfValuesEnum,
  IChkBlockProps,
  IControlProps,
} from "components/CheckBoxes/ChkBlock";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import { CITY_DATA } from "pages/signup/interface";
import React, { useContext, useEffect, useState } from "react";
import { TFunction, useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useCurrentUser } from "scripts/custom-hooks/useCurrentUser";
import courseStore from "scripts/mobx/courseStore";
import customersStore from "scripts/mobx/customersStore";
import locationsStore from "scripts/mobx/locationStore";
import { userStoreContext } from "scripts/mobx/userStore";
import { Filters } from "../../../../components/Filters";
import { filterModels } from "./filterModels";
import { experimentals } from "config/experimentals";

// @ts-ignore
const getTableFiltersData: (
  getSuburbList?: any,
  locations?: any,
  categories?: any[],
  t?: TFunction<"translation">,
  currency?: string,
) => IChkBlockProps = (getSuburbList, locations, categories, t, currency) => {
  return {
    // categoryId: {
    //   title: "courses.category",
    //   countOfValues: countOfValuesEnum.Single,
    //   onChange: async (all: any, currentNewData: any) => {
    //     const catId = currentNewData.categoryId.controlsList.find(
    //       (el: IControlProps) => {
    //         return el.active;
    //       },
    //     )["value"];

    //     const subCatList = categories?.filter((el) => el.id === catId)?.[0]
    //       ?.subCategories;

    //     const _controlsList = subCatList
    //       ? subCatList.map((el: any) => {
    //           return {
    //             value: el.value,
    //             placeholder: "subCategories." + el.name,
    //             active: false,
    //             default: false,
    //           };
    //         })
    //       : [];

    //     if (_controlsList.length) {
    //       _controlsList.unshift({
    //         value: "",
    //         placeholder: "bizBase.all",
    //         active: true,
    //         default: true,
    //       });
    //     }
    //     return {
    //       ...all,
    //       // @ts-ignore
    //       subCategory: { ...all.subCategory, controlsList: _controlsList },
    //     };
    //   },
    //   controlsList: [
    //     {
    //       value: "",
    //       active: true,
    //       default: true,
    //       placeholder: "bizBase.all",
    //     },
    //     ...(categories || [])?.map((el) => {
    //       return {
    //         value: el.id,
    //         placeholder: "categories." + el.name,
    //         active: false,
    //         default: false,
    //       };
    //     }),
    //   ],
    // },
    // subCategory: {
    //   title: "courses.subCategory",
    //   countOfValues: countOfValuesEnum.Array,
    // },
    Age: {
      title: t?.("courses.age"),
      countOfValues: countOfValuesEnum.Array,
      controlsList: [
        {
          value: "",
          active: true,
          default: true,
          placeholder: "bizBase.all",
        },
        ...filterModels.age,
      ],
    },
    suburb: {
      title: t?.("bizBase.location"),
      countOfValues: countOfValuesEnum.Array,
      controlsList: [
        {
          value: "",
          active: true,
          default: true,
          placeholder: "bizBase.all",
        },
        {
          value: "online",
          active: false,
          default: false,
          placeholder: "courses.online",
        },
        {
          value: "-3",
          active: false,
          default: false,
          placeholder: "bizBase.onDemand",
        },
        {
          value: "-2",
          active: false,
          default: false,
          placeholder: "bizBase.parentHome",
        },
        ...getSuburbList,
      ].filter((item) => {
        if (experimentals.on_demand_location) return true;
        return item.value !== "-3";
      }),
    },

    // dateFromTo: {
    //   title: "bookings.dateFromTo",
    //   combined: true,
    //   controlsList: [
    //     {
    //       value: "",
    //       active: true,
    //       default: true,
    //       placeholder: "bizBase.all",
    //     },
    //     {
    //       value: "",
    //       markId: "dateFrom",
    //       type: CHECKBLOCKTYPES.DATE,
    //       dependsOn: {
    //         field: {
    //           name: "dateFromTo",
    //           id: 2,
    //         },
    //         condition: (data: any) => {
    //           const { value, current } = data;
    //           return value && current && current > value?.startOf("day");
    //         },
    //       },
    //       placeholder: "bookings.dateFrom",
    //     },
    //     {
    //       value: "",
    //       markId: "dateTo",
    //       type: CHECKBLOCKTYPES.DATE,
    //       placeholder: "bookings.dateTo",
    //       dependsOn: {
    //         field: {
    //           name: "dateFromTo",
    //           id: 1,
    //         },
    //         condition: (data: any) => {
    //           const { value, current } = data;
    //           return value && current && current < value?.startOf("day");
    //         },
    //       },
    //     },
    //   ],
    // },
    transactionVolume: {
      title: `${t?.("filters.transactionVolume")} (${currency || "VND"})`,
      countOfValues: countOfValuesEnum.Array,
      controlsList: [
        {
          value: "",
          active: true,
          default: true,
          placeholder: "bizBase.all",
        },
        ...filterModels.transactionVolume,
      ],
    },
  };
};
interface ICoursesFiltersProps extends RouteComponentProps {
  onApply: (data: any) => void;
}
export const CustomersFilters = withRouter(
  observer((props: ICoursesFiltersProps): React.ReactElement => {
    const { onApply } = props;
    const { t } = useTranslation();
    const [tableFiltersData, setTableFiltersData] = useState<IChkBlockProps>(
      toJS(customersStore?.dataFilters),
    );

    const userStore = useContext(userStoreContext);

    useEffect(() => {
      (async () => {
        const locationData = CITY_DATA?.map((el: any) => {
          return { value: el.value, placeholder: "area." + el.value };
        });

        const suburbs: any[] = [];
        CITY_DATA.forEach((el) => {
          suburbs.push(...el.suburbs);
        });

        const newSuburbs = suburbs.map((el: any) => {
          return { value: el.value, placeholder: "suburbs." + el.value };
        });

        await courseStore.getCategories().then((res) => {
          const tableFiltersData = getTableFiltersData(
            newSuburbs,
            locationData,
            res || [],
            t,
            userStore.currency,
          );

          // tslint:disable-next-line:forin
          for (const filterKey in tableFiltersData) {
            /** ADDING 'ALL' BUTTONS */
            if (
              tableFiltersData[filterKey]?.controlsList &&
              // @ts-ignore
              !tableFiltersData[filterKey]?.controlsList[0]?.default
            ) {
              // @ts-ignore
              tableFiltersData[filterKey].controlsList.unshift({
                value: "",
                placeholder: "bizBase.all",
                active: true,
                default: true,
              });
            }

            setTableFiltersData(tableFiltersData);
          }
        });
      })();
    }, []);

    const demapFilterData = (data: any): any => {
      const _data = {};

      Object.keys(data).forEach((key) => {
        if (!data[key].combined) {
          _data[key] = data[key].controlsList?.map(
            (el: IControlProps) => el.value,
          );
        } else {
          data[key].controlsList?.forEach((el: IControlProps) => {
            if (el.markId) {
              _data[el.markId] = el.value;
            }
          });
        }
      });

      return _data;
    };

    const onApplyFilters = (data: any) => {
      const _data = { ...demapFilterData(data) };
      onApply(_data);
    };

    const onSetFilter = (data: any) => {
      setTableFiltersData({ ...tableFiltersData, ...data });
      customersStore?.setFilters({ ...tableFiltersData, ...data });
    };

    return tableFiltersData ? (
      <Filters
        onSetFilter={onSetFilter}
        onApply={onApplyFilters}
        onReset={() => customersStore?.setFilters()}
        filterBlocks={tableFiltersData}
        filterModel={toJS(customersStore?.dataFilters)}
      />
    ) : (
      <div>
        <ButtonLink disabled={true}>
          {t("bizBase.filters", {
            defaultValue: "Filters",
          })}
          <i
            className={"ri-arrow-drop-down-line"}
            style={{ fontSize: "20px" }}
          />
        </ButtonLink>
      </div>
    );
  }),
);
