import React, { FC, useState, useCallback, useEffect } from "react";
import { Input, Spin, Divider } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import AutoCompleteInput from "../../AutoCompleteInput";
import { bizIcons } from "constants/bizConstants";

import "./index.less";
import courseStore from "scripts/mobx/courseStore";
import { AppRoutes } from "helpers/app.routes";
import { useTranslation } from "react-i18next";
import { debounce, take, uniqBy } from "lodash";
import e from "express";
import ButtonLink from "components/ButtonLink";

export const AutoCompleteSearchCourses: FC<any> = ({
  fieldName,
  formInstance,
  initCourses = [],
  isView,
}) => {
  const { t } = useTranslation();
  const [courseIdsList, setCourseIdsList] = useState<any[]>([]);
  const [selectedCourseList, setSelectedCourseList] =
    useState<any[]>(initCourses);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isNotFound, setIsNotFound] = useState<boolean>(false);
  const [count, setCount] = useState<number>(6);

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin={true} />;

  const searchCourses = useCallback(async (keyword) => {
    setIsLoading(true);
    courseStore?.searchCourses({ keyword }).then((response) => {
      const items: any[] = response.map((el: any) => ({
        label: el,
        subLabel: el,
        value: el,
        id: el,
      }));
      const ids = selectedCourseList?.map((el) => el.id);
      setIsNotFound(!!!items.length);
      setCourseIdsList(
        !!!items.length
          ? []
          : [
              {
                id: -1,
                name: t("courses.all_course"),
                label: t("courses.all_course"),
                subLabel: t("courses.all_course"),
                value: -1,
              },
              ...items?.filter((el) => !ids?.includes(el.id)),
            ],
      );
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    if (initCourses.length > 0) {
      setSelectedCourseList(initCourses);
    }
  }, [initCourses]);

  const debounceSeachCourses = useCallback(debounce(searchCourses, 1000), []);

  const handleSelectCourses = (item: any) => {
    let newArr = [...selectedCourseList];
    const findIndex = newArr.findIndex((el) => el.id === item.id);

    if (item.id === -1) {
      newArr = [item];
    } else if (findIndex == -1) {
      newArr = uniqBy([...newArr?.filter((el) => el.id !== -1), item], "id");
    }
    setSelectedCourseList(newArr);
    formInstance.setFieldsValue({
      [fieldName]: newArr,
    });
  };

  const removeCourseHandler = useCallback(
    (val: any) => {
      const _tempArr = [...selectedCourseList];
      // @ts-ignore
      _tempArr.remove(val);
      setSelectedCourseList(_tempArr);
      formInstance.setFieldsValue({
        [fieldName]: _tempArr,
      });
    },
    [selectedCourseList],
  );
  const selectedIds = selectedCourseList?.map((el) => el.id);

  return (
    <>
      {!isView && (
        <AutoCompleteInput
          items={courseIdsList?.filter((el) => !selectedIds?.includes(el.id))}
          fieldName={fieldName}
          onSearchValue={debounceSeachCourses}
          onSelected={(item) => handleSelectCourses(item)}
          formInstance={formInstance}
          allowClear={true}
        >
          <Input
            placeholder={"Enter course name or code"}
            className="input-autocomplete"
            suffix={
              isLoading ? (
                <Spin className="spinner" indicator={antIcon} />
              ) : (
                <i className={"ri-search-line mr-8 fs-20 color-grey"} />
              )
            }
            size={"large"}
          />
        </AutoCompleteInput>
      )}
      {isNotFound && (
        <span className="not-found-err">
          {t("courses.empty.courses_not_found")}
        </span>
      )}
      {selectedCourseList.length > 0 && (
        <div
          className="flex selected-container"
          style={{ marginTop: !isView ? 0 : "10px", marginBottom: 6 }}
        >
          {!isView && (
            <Divider style={{ marginTop: "10px", marginBottom: 6 }} />
          )}
          {take(selectedCourseList, count).map((el: any) => (
            <div
              className={
                "full-width flex cursor-pointer hovering selected-container_item"
              }
              key={Math.random()}
            >
              <div className="content">
                <div>{el.id === -1 ? t("courses.all_course") : el.id}</div>
              </div>
              {!isView && (
                <div
                  style={{ marginLeft: "4px" }}
                  onClick={() => removeCourseHandler(el)}
                >
                  <i className={`${bizIcons.CLOSE} fs-16`} />
                </div>
              )}
            </div>
          ))}
          {selectedCourseList?.length > 6 &&
            count < selectedCourseList?.length && (
              <div className="full-width bsl-5 tsl-5 text-center">
                <span
                  className="link no-select"
                  onClick={() => setCount((prev) => prev + 10)}
                >
                  <span className={"link-text no-select"}>
                    {t("bizBase.load_more", { defaultValue: "Load more" })}
                  </span>
                </span>
              </div>
            )}

          {!isView && <Divider />}
        </div>
      )}
    </>
  );
};
