import { action } from "mobx";
import {
  Bot3ByCustomers,
  Bot3Spend,
  ReportCustomerCharacteristicsChart,
  ReportCustomersAndRevenue,
  ReportCustomersAndRevenueSummary,
  ReportCustomerSpendByCategoryChart,
  ReportCustomerSpendByChildAge,
  ReportCustomerSpendByCourseCategory,
  ReportCustomerSpendByKidAgeChart,
  ReportOfflineOnlineSpendSplitByChildAge,
  ReportOfflineOnlineSpendSplitByCourseCategory,
  ReportRecurringCustomersAndRevenue,
  Top3ByCustomers,
  Top3Spend,
} from "pages/reports/data";
import { createContext } from "react";

export class ReportsStore {
  @action
  public async getReportCustomersAndRevenueSummary(filterData?: any) {
    try {
      const resultData: any = { status: 200 };

      if (resultData?.status === 200) {
        return Promise.resolve({
          items: resultData?.data || ReportCustomersAndRevenueSummary,
        });
      } else {
        return Promise.reject();
      }
    } catch (error) {
      return Promise.reject();
    }
  }

  @action
  public async getReportRecurringCustomersAndRevenue(filterData?: any) {
    try {
      const resultData: any = { status: 200 };

      if (resultData?.status === 200) {
        return Promise.resolve({
          items: resultData?.data || ReportRecurringCustomersAndRevenue,
        });
      } else {
        return Promise.reject();
      }
    } catch (error) {
      return Promise.reject();
    }
  }

  @action
  public async getReportCustomerSpendByKidAgeChart(filterData?: any) {
    try {
      const resultData: any = { status: 200 };

      if (resultData?.status === 200) {
        return Promise.resolve(
          resultData?.data || ReportCustomerSpendByKidAgeChart,
        );
      } else {
        return Promise.reject();
      }
    } catch (error) {
      return Promise.reject();
    }
  }
  @action
  public async getReportCustomerCharacteristicsChart(filterData?: any) {
    try {
      const resultData: any = { status: 200 };

      if (resultData?.status === 200) {
        return Promise.resolve(
          resultData?.data || ReportCustomerCharacteristicsChart,
        );
      } else {
        return Promise.reject();
      }
    } catch (error) {
      return Promise.reject();
    }
  }
  @action
  public async getReportCustomerSpendByChildAge(filterData?: any) {
    try {
      const resultData: any = { status: 200 };

      if (resultData?.status === 200) {
        return Promise.resolve({
          items: resultData?.data || ReportCustomerSpendByChildAge,
        });
      } else {
        return Promise.reject();
      }
    } catch (error) {
      return Promise.reject();
    }
  }
  @action
  public async getReportCustomerSpendByCourseCategory(filterData?: any) {
    try {
      const resultData: any = { status: 200 };

      if (resultData?.status === 200) {
        return Promise.resolve({
          items: resultData?.data || ReportCustomerSpendByCourseCategory,
        });
      } else {
        return Promise.reject();
      }
    } catch (error) {
      return Promise.reject();
    }
  }
  @action
  public async getReportOfflineOnlineSpendSplitByChildAge(filterData?: any) {
    try {
      const resultData: any = { status: 200 };

      if (resultData?.status === 200) {
        return Promise.resolve({
          items: resultData?.data || ReportOfflineOnlineSpendSplitByChildAge,
        });
      } else {
        return Promise.reject();
      }
    } catch (error) {
      return Promise.reject();
    }
  }
  @action
  public async getReportOfflineOnlineSpendSplitByCourseCategory(
    filterData?: any,
  ) {
    try {
      const resultData: any = { status: 200 };

      if (resultData?.status === 200) {
        return Promise.resolve({
          items:
            resultData?.data || ReportOfflineOnlineSpendSplitByCourseCategory,
        });
      } else {
        return Promise.reject();
      }
    } catch (error) {
      return Promise.reject();
    }
  }

  @action
  public async getReportLocationCustomerSpend(filterData?: any) {
    try {
      const resultData: any = { status: 200 };

      if (resultData?.status === 200) {
        return Promise.resolve(resultData?.data);
      } else {
        return Promise.reject();
      }
    } catch (error) {
      return Promise.reject();
    }
  }

  @action
  public async getReportLocationCustomerNumbers(filterData?: any) {
    try {
      const resultData: any = { status: 200 };

      if (resultData?.status === 200) {
        return Promise.resolve(resultData?.data);
      } else {
        return Promise.reject();
      }
    } catch (error) {
      return Promise.reject();
    }
  }
  @action
  public async getReportsReportLocationNumberOfCourses(filterData?: any) {
    try {
      const resultData: any = { status: 200 };

      if (resultData?.status === 200) {
        return Promise.resolve(resultData?.data);
      } else {
        return Promise.reject();
      }
    } catch (error) {
      return Promise.reject();
    }
  }
  @action
  public async getReportCustomerSpendByCategoryChart(filterData?: any) {
    try {
      const resultData: any = { status: 200 };

      if (resultData?.status === 200) {
        return Promise.resolve({
          items: resultData?.data || ReportCustomerSpendByCategoryChart,
        });
      } else {
        return Promise.reject();
      }
    } catch (error) {
      return Promise.reject();
    }
  }

  @action
  public async getReportCustomersAndRevenue(filterData?: any) {
    try {
      const resultData: any = { status: 200 };

      if (resultData?.status === 200) {
        return Promise.resolve({
          items: resultData?.data || ReportCustomersAndRevenue,
        });
      } else {
        return Promise.reject();
      }
    } catch (error) {
      return Promise.reject();
    }
  }

  @action
  public async getLocationSummaryTable(filterData?: any) {
    try {
      const resultData: any = { status: 200 };

      if (resultData?.status === 200) {
        return Promise.resolve(resultData?.data);
      } else {
        return Promise.reject();
      }
    } catch (error) {
      return Promise.reject();
    }
  }

  @action
  public async getTop3SpendTable(filterData?: any) {
    try {
      const resultData: any = { status: 200 };

      if (resultData?.status === 200) {
        return Promise.resolve({ items: resultData?.data || Top3Spend });
      } else {
        return Promise.reject();
      }
    } catch (error) {
      return Promise.reject();
    }
  }
  @action
  public async getBot3SpendTable(filterData?: any) {
    try {
      const resultData: any = { status: 200 };

      if (resultData?.status === 200) {
        return Promise.resolve({ items: resultData?.data || Bot3Spend });
      } else {
        return Promise.reject();
      }
    } catch (error) {
      return Promise.reject();
    }
  }

  @action
  public async getTop3CustomersTable(filterData?: any) {
    try {
      const resultData: any = { status: 200 };

      if (resultData?.status === 200) {
        return Promise.resolve({ items: resultData?.data || Top3ByCustomers });
      } else {
        return Promise.reject();
      }
    } catch (error) {
      return Promise.reject();
    }
  }

  @action
  public async getBot3CustomersTable(filterData?: any) {
    try {
      const resultData: any = { status: 200 };

      if (resultData?.status === 200) {
        return Promise.resolve({ items: resultData?.data || Bot3ByCustomers });
      } else {
        return Promise.reject();
      }
    } catch (error) {
      return Promise.reject();
    }
  }
  @action
  public async shareReport(data?: any) {
    try {
      const requestParams = { ...(data || {}) };
      const resultData: any = { status: 200 };
      //   await this.getAPI().apiProviderReportsShareReportPost(
      //   requestParams,
      // );
      if (resultData?.status === 200) {
        return Promise.resolve(resultData.data);
      } else {
        throw new Error(
          "Error in sharing report, statusCode: " + resultData?.statusCode,
        );
      }
    } catch (error) {
      return Promise.reject();
    }
  }

  @action
  public async clearAll() {
    // this.reports = {
    //   reportCustomersAndRevenueSummary: {},
    // };
  }

  // @computed
  // public get subCatListData() {
  //  return this.reports;
  // }
}

const reportsStore = new ReportsStore();

export const reportsStoreContext = createContext(reportsStore);
export default reportsStore;
