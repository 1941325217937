import { Divider } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  useCurrentUser,
  userBlocked,
  useRoles,
} from "scripts/custom-hooks/useCurrentUser";
import { Roles } from "scripts/mobx/userStore";
import ButtonLink from "../../components/ButtonLink";
import { LayoutPage } from "../../components/Layout";
import "./index.less";

const AccountSettings = () => {
  const { t } = useTranslation("base");
  const roles = useRoles();
  const user = useCurrentUser();
  const isBlocked = userBlocked();
  return (
    <LayoutPage>
      <div className="account-settings">
        <div className={"as-form"}>
          <div className="title" style={{ marginBottom: "32px" }}>
            {t("accountSettings.accountSettings", {
              defaultValue: "Account Settings",
            })}
          </div>
          <div className={"as-block"}>
            <div className={"as-block-title"}>
              <div className="as-block-text">
                <i className="ri-mail-line" style={{ fontSize: "20px" }} />{" "}
                {t("bizBase.email", { defaultValue: "Email" })}
              </div>
              <div className="as-block-action">
                {!isBlocked && roles === Roles.FULL && (
                  <Link to="/account-settings/change-email">
                    <ButtonLink>
                      {t("accountSettings.change", { defaultValue: "Change" })}
                      <i
                        className="ri-arrow-right-s-line"
                        style={{ fontSize: "16px" }}
                      />
                    </ButtonLink>
                  </Link>
                )}
              </div>
            </div>
            <div className={"as-block-body"}>{user?.userAttributes?.email}</div>
            <Divider />
          </div>
          <div className={"as-block"}>
            <div className={"as-block-title"}>
              <div className="as-block-text">
                <i className="ri-lock-line" style={{ fontSize: "20px" }} />
                {t("accountSettings.password", { defaultValue: "Password" })}
              </div>
              {!isBlocked && (
                <div className="as-block-action">
                  <Link to="/account-settings/change-password">
                    <ButtonLink>
                      {t("accountSettings.change", { defaultValue: "Change" })}
                      <i
                        className="ri-arrow-right-s-line"
                        style={{ fontSize: "16px" }}
                      />
                    </ButtonLink>
                  </Link>
                </div>
              )}
            </div>
            <div className={"as-block-body password"}>
              {/* {Array(user.providerInfo.passwordCount)} */}
              {Array(8)
                .fill(".")
                .map((el) => el)}
            </div>
            <Divider />
          </div>
        </div>
      </div>
    </LayoutPage>
  );
};

export default AccountSettings;
