export enum tableTypes {
  CUSTOMERS = "Customers",
  REVIEWS = "Reviews",
}

export enum tableBookingStatus {
  PENDING = "PENDING",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED", // never to show
}

export const bizIcons = {
  AGE: "ri-user-4-line",
  ARROWDOWN: "ri-arrow-drop-down-line",
  ARROWUP: "ri-arrow-drop-up-line",
  ARROWRIGHT: "ri-arrow-right-s-line",
  ARROWLEFT: "ri-arrow-left-s-line",
  TIMELINE: "ri-arrow-left-s-line",
  EDIT2: "ri-edit-2-line",
  EYE: "ri-eye-line",
  LOADER: "ri-loader-4-line",
  INFO: "ri-information-line",
  GIFT2: "ri-gift-2-line",
  CODE: "ri-barcode-box-line",
  FILEDOWNLOAD: "ri-file-download-line",
  DATECHECK: "ri-calendar-check-line",
  ADDCIRCLE: "ri-add-circle-line",
  DELETE: "ri-delete-bin-2-line",
  CHECKBOXCIRCLE: "ri-checkbox-circle-line",
  BOOKHEALTH: "ri-health-book-line",
  COUPON: "ri-coupon-line",
  COPY: "ri-file-copy-2-line",
  EXCEL: "ri-file-excel-2-line",
  RECOGNITION: "ri-character-recognition-line",
  BOOK: "ri-book-mark-line",
  MAIL: "ri-mail-line",
  PIN: "ri-map-pin-2-line",
  COMMUNITY: "ri-community-line",
  UL: "ri-list-unordered",
  LISTCHECK2: "ri-list-check-2",
  STAR: "ri-star-line",
  UNLINK: "ri-link-unlink",
  CALENDAR: "ri-calendar-line",
  SMARTPHONE: "ri-smartphone-line",
  PHONE: "ri-phone-line",
  PRICE: "ri-price-tag-3-line",
  PRICE2: "ri-price-tag-2-line",
  PROFILE: "ri-profile-circle-line",
  USER5: "ri-user-5-line",
  USER: "ri-user-line",
  USER4: "ri-user-4-line",
  CARD2: "ri-bank-card-2-line",
  CARD: "ri-bank-card-line",
  NOTIFICATION: "ri-notification-2-line",
  CLOSE: "ri-close-line",
  MAILSEND: "ri-mail-send-line",
  EDIT: "ri-edit-box-line",
  GLOBE: "ri-global-line",
  MESSAGE: "ri-message-2-line",
  IMAGE: "ri-image-2-line",
  TWITTER: "ri-twitter-line",
  TEAM: "ri-team-line",
  BUILDING: "ri-building-line",
  SHARE: "ri-share-box-line",
  HOME3: "ri-home-3-line",
  HOTEL: "ri-hotel-line",
  BUILDING4: "ri-building-4-line",
  SAVE: "ri-save-line",
  LINKEDIN: "ri-linkedin-box-line",
  YOUTUBE: "ri-youtube-line",
  INSTAGRAM: "ri-instagram-line",
  KEY: "ri-key-2-line",
  FACEBOOK: "ri-facebook-circle-line",
  BOOKOPEN: "ri-book-open-line",
  BASKET: "ri-shopping-basket-line",
  LOGOUT: "ri-logout-box-line",
  LOCK: "ri-lock-line",
  CHAT: "ri-chat-3-line",
  LOGIN: "ri-login-box-line",
  CALENDARTODO: "ri-calendar-todo-line",
  SETTINGS: "ri-settings-3-line",
  WALLET: "ri-wallet-3-line",
  SEARCH: "ri-search-line",
  TIMELINE2: "ri-time-line",
  CHECK: "ri-check-line",
  USER_NORMAL: "ri-user-line",
  USER3: "ri-user-3-line",
};

export const PRICING = "15%";
