import React from "react";
import { Typography } from "antd";
const { Title } = Typography;

const ZhTermsAndConditions = () => {
  return (
    <>
      <Title>条款和条件</Title>
      <div className={"bizibuz-policies-lists"}>
        <ol>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
            您的协議
            </Title>
            <ol>
              <li>
              Bizibuz香港有限公司及其附屬公司(“我們”、“我們的”或“Bizibuz”) 是www.bizibuz.com(“網站”)的所有者和經營者,其多個子域名包括但不限於kidzbiz.bizibuz.com(“Kidzbiz平台”)中的Kidzbiz子域名和潛在的其他Bizibuz品牌應用(统稱“Bizibuz平台”)。在使用Bizibiz平台和由Bizibuz或有Bizibuz登錄的第三方教育公司或運營商(统稱 “活動提供商”)提供的服務(統稱“服務”)之前，請仔細閱讀本條款和條件。在本條款和條件中使用的“用戶”，是指瀏覽Bizibuz平台的任何人(不包含活動提供商)。 “您”和“您的”同指活動提供商和用戶。任何違反本條款和條件的行為都可能導致您使用Bizibuz平台的權利被終止或暫停。本條款和條件適用於所有Bizibuz活動提供商和全球用戶。
              </li>
            </ol>
          </li>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
            條款和條件變更
            </Title>
            <ol>
              <li>
              Bizibuz有權自行決定在任意時刻修改本條款和條件的任何部分而無需事先通知。如條款和條件發生變更，Bizibuz將把修改後的內容發布到本頁面，並在本頁底部註明條款和條件的最後修改日期。您有義務定期查看條款和條件，檢查條款和條件是否產生更改，並查看相關修改。
              </li>

              <li>
              在此類修改完成後，您繼續使用Bizibuz平台即表示您接受修改後的條款和條件。如您不同意遵守修改後的條款和條件，請勿使用、瀏覽或繼續使用Bizibuz平台和/或服務。
              </li>
              <li>
              在使用Bizibuz平台和服務時，您應遵守適用於可能會不時發佈在與此類服務有關的頁面的其他條款以及Bizibuz的隱私政策(以下簡稱“隱私政策”)。所有此類條款在此明確納入本條款和條件中。
              </li>
            </ol>
          </li>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
            服務描述
            </Title>
            <ol>
              <li>
              Bizibuz是一個兒童課外活動數字化市場。 Bizibuz平台允許用戶使用一系列過濾器搜索活動提供商，對比活動提供商的多種定性和定量因素，預訂和購買活動提供商出售的教育活動，並統一管理付款和課程表。 Bizibuz平台允許活動提供商發佈公司和/或教育活動信息，用於提高知名度、吸引新客戶和銷售教育活動。
              </li>

              <li>
              本服務包括但不限於Bizibuz或活動提供商在Bizibuz平台上進行的活動，以及在Bizibuz平台上購買但由活動提供商在非Bizibuz平台的論壇上提供的教育活動。
              </li>
            </ol>
          </li>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
            使用Bizibuz平台和服務
            </Title>
            <ol>
              <li>
              作為使用Bizibuz平台及其服務的條件，您應保證: <br />
              (i) 您在本網站提供的所有資料均真實、準確、最新及完整; <br />
(ii) 如果您註冊了一個Bizibuz賬戶，您應保護您的賬戶信息，並對除您以外的任何人使用該賬戶的行為進行監督並負有完全負責; <br />
(iii) 您必須年滿18歲方可註冊帳戶並使用我們的網站;和 <br />
(iv) 您擁有此合法協議權限及按照本協議條款和條件使用本網站的合法權力。
              </li>

              <li>
              除非您已年滿十八歲，並具備訂立有約束力合約的法律能力，否則您不得使用本網站。如有未成年人或任何其他個人通過您的帳戶在我方網站上進行任何互動，您將承擔法律責任。如果您授權第三方使用您的帳戶，您有責任確保其了解本條款和條件以及服務所適用的其他條款和條件，並確保其遵守相應條款和條件。
              </li>
              <li>
              作為使用Bizibuz平台及其服務的條件，您進一步同意，不得: <br />
              (i) 未經我方明確的書面同意，使用任何機器人、爬蟲、抓取器或其他自動化手段或任何人工流程，因任何目瀏覽、監控或複製本網站的任何內容或信息; <br />
(ii) 違反本網站任何自動排除標頭的限制，或繞過或規避為防止或限制瀏覽本網站而採取的其他措施; <br />
(iii) 採取任何在我方判斷下可對我們的基礎設施造成或可能造成不合理或不成比例的巨大負荷的行動; <br />
(iv) 未經我方書面許可，出於任何目的深度鏈接到本網站的任何部分; <br />
(v) 在未經我方事先書面授權的情況下，“框架”、“鏡像”或將本網站的任何部分植入任何其他網站;或 <br />
(vi) 試圖修改、翻譯、改編、編輯、反編譯、反彙編或反向工程Bizibuz使用的與本網站或本服務有關的任何軟件程序。<br />

              </li>
              <li>
              此外，在允許您鏈接至Bizibuz平台及其內容和信息前，Bizibuz保留要求事先獲得書面同意的權利。
              </li>
              <li>
              Bizibuz有權自行決定在任意時刻更改、暫停或停止瀏覽本網站和/或任何服務，包括任何功能、數據庫或內容的可用性。 Bizibuz不保證服務將始終可用或不間斷，如因任何原因導致服務在某時間或某時段不可用，Bizibuz不對您承擔任何責任。 Bizibuz也可能在任何時候以任何理由對某些服務施加限製或條件，或限制您瀏覽本網站和本服務，而不另行通知或承擔法律責任。 Bizibuz保留酌情拒絕註冊或取消Bizibuz帳戶的權利。一旦賬戶被取消或封鎖，您使用服務、瀏覽Bizibuz平台和任何內容的權利將被立即終止。本條款和條件的所有條款，就其本質而言，應當、將會在終止後繼續有效，包括但不限於所有權條款、保證免責聲明以及責任限制和免除。
              </li>
            </ol>
          </li>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
            Bizibuz平台和内容
            </Title>
            <ol>
              <li>
              Bizibuz平台上與Bizibuz或活動提供商相關的內容和信息(包括但不限於消息、數據、信息、文章、圖像、插圖、文本、聲音、照片、圖形、視頻、地圖、圖標、軟件、網站的外觀和感受，包括所有頁面標題、自定義圖形、按鈕圖標和腳本、服務商標、Bizibuz的商標和/或商業外觀、代碼和/或其他材料)，以及用於提供此類內容和信息的基礎架構，根據所適用的版權法、國際公約和其他知識產權法，受版權和作為集體作品和/或彙編的其他知識產權保護。與Bizibuz平台有關的所有知識產權均屬於Bizibuz或已被許可給Bizibuz在Bizibuz平台上使用。未經Bizibuz事先書面許可或版權聲明中確定的相關知識產權所有者的明確書面同意，嚴格禁止複制、傳播、重現、翻譯、出版、廣播、修改、授權、出售、創作衍生作品、展示、發布、存儲或重新分發本網站、其內容或其任何部分的衍生作品。
              </li>

              <li>
              Bizibuz不能保證您在使用Bizibuz平台過程中可能與其交互的任何其他用戶的身份。 Bizibuz不保證、陳述、擔保或承諾(無論明示或暗示) 以何種方式提供的任何信息是或仍然是準確、完整和最新，或在任何時候適合或適用於任何目的。 Bizibuz對網站上其他用戶和活動提供商所提供信息的真實性、質量或準確性不承擔任何責任，此信息僅為方便和信息目的而提供。閣下對本網站所載資料的任何信賴均由您自行承擔風險，並由您全權負責因此而導致的任何損害或損失。
              </li>
              <li>
              Bizibuz無責。活動提供商在Bizibuz平台上提供的活動或發布、存儲、您或任何第三方上傳的內容，包括但不限於任何失誤或遺漏、錯誤、中傷、誹謗、詆毀、不作為、謊言、淫穢、任何色情或褻瀆內容,或內容的任何損失或損害,或因使用或接觸發布的任何內容、電子郵件、瀏覽、傳播,或通過Bizibuz平台提供的其他方式而產生的任何損失或損害時，Bizibuz概不承擔責任。
              </li>
            </ol>
          </li>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
            用戶提交與行為
            </Title>
            <ol>
              <li>
              在本網站上通過電子郵件、發布評價或評論或在本網站上傳信息,包括任何評價、照片、視頻、問題、評論、建議、想法或類似的其他提交(統稱“提交”)的用戶,授予Bizibuz及其附屬公司非獨家的、在世界範圍內免版稅的、永久的、可轉讓的、不可撤銷的和完整次級許可權利，出於任何目的，在全球各地當前已存在或以後將出現的任意媒體上，使用、複製、修改、改編、翻譯, 發布、出版、創作衍生作品並公開展示和執行這些提交。用戶同意並授權Bizibuz根據不時生效的Bizibuz隱私政策使用相關個人資料。用戶承認並同意，您在Bizibuz平台上所做的任何提交都是非保密和專有的。 Bizibuz沒有義務為您提交的內容支付任何費用，也無義務在內容提交到Bizibuz平台後提供編輯、刪除或以其他修改機會。 Bizibuz沒有義務將內容的作者權歸屬於您。
              </li>

              <li>
              特別針對用戶發布的評論而言，您特此授予Bizibuz平台的每個活動提供商非獨家授權，瀏覽您通過Bizibuz平台發布的任何評價，並使用、修改、複製、分發、準備該評論的衍生作品和展示該評論。 Bizibuz沒有義務強制第三方就該評價作出任何形式的歸屬說明。
              </li>
              <li>
              您進一步授權Bizibuz依法追究違反本條款和條件而侵犯您或Bizibuz權利的任何人或實體。如有必要，Bizibuz可參與任何索賠或訴訟的辯護與和解談判​​。未經Bizibuz事先書面同意，您不得作出任何可能對Bizibuz的權利或義務產生不利影響的和解。 Bizibuz保留自付費用並在通知您時，對任何索賠或訴訟進行專有辯護和控制的權利。
              </li>
              <li>
              Bizibuz不編輯或無義務審查用戶或活動提供商在Bizibuz平台，以及通過任何聊天室、公告牌或其他通信論壇上傳、發布或分發的內容，也不會為此類信息或觀點負責或承擔責任。儘管如此，Bizibuz保留出於任何理由自行決定在發布之前審查提交內容、刪除任何信息、用戶消息和/或網站內容的權利，無需另行通知用戶或相關活動提供商。
              </li>
              <li>
              您或第三方使用Bizibuz平台，包括但不限於使用討論論壇、公告欄、評價服務或其他論壇，發布或回應活動提供商的評論或其他內容、消息和材料時，由您自行承擔風險。您對您上傳、發布、發送郵件、傳輸或以其他方式傳播的所有提交內容負全部責任，並對在Bizibuz平台上發布或刊發您提交內容的後果負全部責任。
              </li>
              <li>
              Bizibuz不對其用戶或活動提供商在Bizibuz平台上提供的任何聲明、陳述或內容負責。作為使用Bizibuz平台及其服務的條件，您明確同意不通過Bizibuz平台發布、上傳、傳輸、分發、存儲、創建或以其他方式發布以下提交：
                <br />
                (i) 侵害或侵犯任何第三方的知識產權或專有權利、宣傳權或隱私權或其他權利； <br />
(ii) 違反任何法律、法規、條例或規則; <br />
(iii) 虛假、誤導、有害、明顯冒犯、欺詐性、欺騙性、威脅性、辱罵性、騷擾性、侵權、誹謗、粗俗、淫穢、有損名譽、其他令人反感的內容; <br />
(iv) 涉及競賽、抽彩、易貨和傳銷; <br />
(v) 構成誹謗或假冒任何個人或實體，包括但不限於其他用戶或Bizibuz的任何員工或代表; <br />
(vi) 含有病毒、損壞的數據、特洛伊木馬、蠕蟲、定時炸彈病毒或其他有害的電腦代碼、文件或程序; <br />
(vii) 在隱藏的頁面中包含受限內容;或 <br />
(viii) 包含在Bizibuz自行判斷下的以下內容或指向以下內容的鏈接，(a)違反前述條款,(b)令人反感,(c)不相關,(d)限製或抑制其他任何人使用或享受本網站,或(e) 可能使Bizibuz、其附屬公司或用戶遭受任何類型的傷害或責任。<br />

              </li>
              <li>
              在任何情況下，Bizibuz對因使用或依賴或無法使用本網站、其內容、服務、建議、產品或其任何部分，或在此包含的任何錯誤、中傷、誹謗、污衊、遺漏、虛假或褻瀆行為所引起的任何直接、間接、特殊、偶發或後續損害，包括但不限於數據或利潤損失，概不負責。根據適用法律的最大可能範圍內，我們對本網站、其內容、服務及其任何部分不作明示或暗示的保證，包括但不限於對適銷性、特定用途的適用性、不侵權或其他侵犯權利的默示保證。我們亦不為用戶或活動提供商提供的任何意見、推薦或建議做背書。
              </li>
              <li>
              對於用戶或活動提供商在Bizibuz平台上或與其服務相關的任何作為或不作為，Bizibuz不承擔任何責任。您聲明、保證並向Bizibuz承諾，您不得: <br />
              (i) 進行任何可能導致終止您瀏覽或使用Bizibuz平台和/或服務權利的欺詐、濫用或其他非法活動; <br />
(ii) 發布或傳送或導致發布或傳送任何旨在從Bizibuz平台的任何其他用戶處獲取密碼、帳戶或私人信息的通信或教唆請求; <br />
(iii) 違反任何電腦網絡的安全、破解密碼或安全加密碼、轉移或儲存非法資料(包括可能被視為具有威脅性或淫穢的資料)，或從事任何明確禁止的非法活動; <br />
(iv) 在Bizibuz平台運行郵件列表、郵件群發系統,或任何其他形式的自動回复程序,或“垃圾郵件”,或當您在未登錄Bizibuz平台時運行或激活的進程,或乾擾Bizibuz平台的基礎設施正常工作或施加不合理的工作負載; <br />
(v) 使用手動或自動化軟件、設備或其他流程“抓取”、“留存”或“爬取”Bizibuz平台的任何頁面;或 <br />
(vi) 反編譯、反向工程或以其他方法試圖獲取Bizibuz平台的源代碼。 <br />
              </li>
            </ol>
          </li>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
            活動提供商的服務履行
            </Title>
            <ol>
              <li>
              對於在Bizibuz平台上所購買服務的履行或交付時，活動提供商本身的任何作為或不作為，Bizibuz不承擔任何責任。 Bizibuz不對此類服務的適宜性、真實性、安全性或質量負責。用戶自行負責對活動提供商進行盡職調查，並自行在Bizibuz平台上選擇合適的教育活動進行購買。
              </li>

              <li>
              用戶承認，對於因在Bizibuz平台上所購服務的履行或交付; 或監管、法律方面的任何缺陷或安全管理標準，包括就業資格在內的許可、錯誤、中傷、誹謗、污衊、遺漏、虛假信息，或活動提供商的褻瀆行為或與其履行或提供服務有關的行為，而造成的任何損害或損失，Bizibuz不承擔直接或間接的責任。
              </li>
              <li>
              雖然活動供應方應向Bizibuz提供準確、最新的服務價格和可用性信息，但Bizibuz無法保證所有這些數據均準確和隨時更新。
              </li>
            </ol>
          </li>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
            更多通信
            </Title>
            <ol>
              <li>
              通過在Bizibuz平台上完成教育活動預訂，用戶同意接收預訂的確認及其接受、拒絕或取消信息（以電子郵件、Whatsapp/SMS消息和/或應用通知的形式），以及活動完成後邀請您對課程評價的信息。進行評價為可選項。為清晰起見，確認和評價電子郵件為交易性質，並非您可退訂的新聞諮詢或營銷郵件的一部分。
              </li>

              <li>
              儘管我們努力提供包括確認和評價信息在內的所有通信，Bizibuz並不能保證所有消息的交付，且對於因未能向用戶或活動提供商發送或接收此類通信而造成或聲稱造成的任何損害或損失，Bizibuz不承擔直接或間接的責任。
              </li>
            </ol>
          </li>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
            其他用戶聲明、保證和承諾
            </Title>
            <ol>
              <li>
              您將全權負責扣繳、備案和報告與使用Bizibuz平台和/或其服務相關的所有稅收、關稅和其他政府稅款。
              </li>
              <li>
              您將全權負責並自費創建您在本網站上發布或儲存的任何內容備份副本和替換。
              </li>
              <li>
              您將全權負責對您的密碼進行保密。您必須立即通知Bizibuz關於任何未經授權使用您的帳戶、用戶名或密碼以及其他任何違反安全的行為，並確保您在每次課程結束時登出您的帳戶。您必須採取措施確保他人無法使用您的密碼和帳戶。對於因您未能遵守此要求或在您知情或不知情的情況下使用您的帳戶、用戶名或密碼而引起的任何損失或損害，Bizibuz概不負責。同時，因他人使用您的帳戶、用戶名或密碼而給我們或其他方造成的損失時，您或將承擔責任。
              </li>
              <li>
              您同意，作為本條款和條件或瀏覽和使用Bizibuz平台的結果，您與Bizibuz及其控股公司、子公司和/或關聯公司之間不構成任何合資、代理、合夥、僱傭關係、信託責任或特殊關係。
              </li>
            </ol>
          </li>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
            隱私政策
            </Title>
            <ol>
              <li>
              Bizibuz堅信保護用戶和活動提供商的隱私。請查看已併入到本條款和條件中的Bizibuz隱私政策。您接受本條款和條件即構成您接受並同意我們隱私政策的約束。
              </li>
            </ol>
          </li>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
            賠償
            </Title>
            <ol>
              <li>
              您應賠償並保護Bizibuz、我們的控股公司、子公司、關聯公司、代理、合作夥伴、授權人、代表、管理人員、董事和僱員、第三方供應商(包括我們關聯公司各自的管理人員、董事、代理、合作夥伴、員工、授權人、代表、第三方供應商和活動供應方)免受損害，包括但不限於，出於或因您瀏覽Bizibuz平台、使用Bizibuz平台或其服務時違反本條款和條件、或您或任何第三方使用您的Bizibuz登錄而侵犯任何個人或實體的任何知識產權或其他權利而導致的任何第三方提出的所有損害、責任、和解、費用和律師費、索賠或要求。如有必要，Bizibuz可參與任何索賠或訴訟的抗辯以及任何和解談判。未經Bizibuz的事先書面批准，您不得做出任何可能對Bizibuz的權利或義務產生不利影響的和解。 Bizibuz保留自費並在通知您後承擔獨家辯護和調控任何索賠或訴訟的權利。
              </li>
              <li>
              活動提供商同意保護Bizibuz不受傷害，並對用戶的任何評論、評論或行為作出賠償。您同意賠償Bizibuz並承擔因活動提供商疏於履行服務而引起的責任。我方保留自費承擔對任何需要您賠償事項的獨家辯護和調控的權利，在此情況下，您同意完全配合該抗辯並主張任何可用的抗辯。
              </li>
            </ol>
          </li>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
            責任免除
            </Title>
            <ol>
              <li>
              在任何情況下，Bizibuz、Bizibuz平台、其控股公司、子公司、關聯公司、管理人員、董事和/或員工均不對因或基於以下原因的或由此產生的任何利潤損失或任何間接的、相應的、特殊的、偶然的或懲罰性損害負責: (a) Bizibuz平台; 或(b) 本條款和條件; 或(c) 您或第三方對本條款和條件的任何違反; 或(d) 使用Bizibuz平台或Bizibuz或任何第三方在Bizibuz平台上提供的服務; 或(e) 即使我們被告知有可能發生此類損害的任何情況下，用戶之間的任何實際或未遂的通信或交易。
              </li>
              <li>
              此限制和排除條款適用，而無需考慮損害是否來自:  <br />
              (i) 違反合同; <br />
ii) 違反保證; <br />
(iii) 嚴格責任; <br />
(iv) 侵權; <br />
(v) 過失; 或 <br />
(vi) 任何其他訴訟原因，在適用法律未禁止此排除和限制的最大限度內。 <br />
              </li>
              <li>
              本Bizibuz平台，包括在Bizibuz平台上提供的或通其可瀏覽的所有內容、軟件、功能、材料和信息，均按“現狀”提供。在適用法律允許的最大範圍內，Bizibuz不作任何明示或暗示的陳述或保證，包括但不限於Bizibuz平台上的內容或通過其可瀏覽的材料、信息和功能、第三方的任何服務或超文本鏈接，或與通過Bizibuz平台傳輸敏感信息有關的任何安全破壞。
              </li>
              <li>
              您承認並同意，任何傳輸到或來自Bizibuz平台的傳輸均不屬於機密信息，您的通信可能被他人讀取或攔截。您進一步承認並同意，通過向Bizibuz提交通信信息和在Bizibuz平台上發布信息，您與Bizibuz之間不存在保密、受託、合同暗示或其他關係，但依據本條款和條件除外。
              </li>
              <li>
              您承認並同意您不會讓或尋求讓Bizibuz負責任何第三方提供的內容，您進一步承認並同意Bizibuz並非與任何活動提供商或第三方所提供服務相關的任何交易方。對於任何活動提供商或第三方在Bizibuz平台上所提供服務的旅行，Bizibuz不具有控制權，也不保證其合法性或安全性。
              </li>
            </ol>
          </li>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
            補救措施
            </Title>
            <ol>
              <li>
              如您不滿意Bizibuz平台、不同意本條款和條件的任何部分,或有其他任何爭議或索賠或反對Bizibuz或本條款和條件或Bizibuz平台的用戶，您針對Bizibuz的唯一且專屬補救措施是停止使用Bizibuz平台。
              </li>
            </ol>
          </li>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
            與第三方互動
            </Title>
            <ol>
              <li>
              本網站可能包含指向包括但不限於活動提供商的外部第三方網站的超鏈接或引用。任何此類超鏈接或引用僅為方便您而提供，並不構成Bizibuz對此類第三方網站或其內容的認可或推薦。我們對第三方網站無控制權，對其中包含的任何內容、材料或信息不承擔任何責任。 Bizibuz不對活動提供商提供的任何材料、鏈接或信息、外部網站上的任何內容以及第三方提供商在Bizibuz平台上發布的推廣或廣告承擔任何責任。
              </li>
              <li>
              您進一步承認並同意,對於因使用或依賴任何此類內容、廣告、產品、服務或任何第三方網站的其他材料或其中包含的任何錯誤、中傷、誹謗、造謠、遺漏、謊言、淫穢、色情、或褻瀆而造成或聲稱造成的任何損害或損失，我方概不負責。同時，您使用第三方網站時或將受該第三方網站的條款和條件的約束。在與任何第三方進行在線或離線交易前，您應該進行您認為必要或適當的調查。
              </li>
              <li>
              如本網站上的用戶之間或用戶與包括活動提供商在內的任何第三方之間發生爭議，您理解並同意Bizibuz沒有義務參與。在您與一個或多個其他用戶或第三方產生爭議時, 包括已知和未知、疑似或未疑似、披露或未披露,引起的或此類爭端有關的任何方式，您在此免除Bizibuz、其控股公司、子公司、關聯公司、管理人員、董事、僱員、代理、和繼任者被索賠、訴訟和損害(實際和間接)的責任。
              </li>
            </ol>
          </li>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
            適用法律和爭議解決
            </Title>
            <ol>
            本條款及條件受中華人民共和國香港特別行政區法律管理並按其解釋。因本協議引起的任何爭議應交由香港國際仲裁中心按當時有效的香港國際仲裁中心仲裁規則進行仲裁解決。仲裁過程應以英語進行，仲裁地點為香港。
            </ol>
          </li>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
            其他事項
            </Title>
            <ol>
              <li>
              如發現本條款和條件的任何規定不可執行或無效，則應在必要的最小範圍內限製或消除該規定，以使本條款和條件在其他條款仍然具有完全的效力和可執行性。
              </li>
              <li>
              在無Bizibuz事先書面同意下，您不得對本條款和條件進行分配、轉讓或二次授權，否則Bizibuz可在不事先通知您的情況下轉讓、分配或委託本條款和條件及其權利和義務。
              </li>
              <li>
              本條款和條件以英語起草並解釋。本條款和條件之翻譯僅供參考。如英語版本和翻譯版本之間存在不一致，則以英語版本為準。
              </li>
            </ol>
          </li>
          <li className="first-lvl">
            <Title level={3} className="sub-header">
            聯繫信息
            </Title>
            <ol>
              <li>
              請通過info@bizibuz.com聯繫Bizibuz，舉報任何違反本條款和條件的行為或詢問與本條款和條件有關的問題。
              </li>
            </ol>
          </li>
        </ol>

        <div className={"tsl-3 bsl-3"}>最後更新於: 2021年4月22日。</div>
      </div>
    </>
  );
};

export default ZhTermsAndConditions;
