import { createContext } from "react";
import { action, computed, makeObservable, observable } from "mobx";
import requestApi from "helpers/requestApi";
import { DETAIL_API_URL } from "constants/apiUrl";

export interface EmployeeType {
  id?: number;
  accessType: "FULL" | "PARTIAL";
  email?: string;
  firstName?: string;
  lastName?: string;
  mobile?: string;
}

export class EmployeeStore {
  constructor() {
    makeObservable(this);
  }

  @observable
  public employeeData: any = [];

  @observable
  public errorHandler: string = "";

  @action
  public async getEmployee() {
    const result = await requestApi.get(DETAIL_API_URL.GET_EMPLOYEE);
    if (result?.status === 200 || !!result?.data) {
      this.employeeData = result?.data;
      return Promise.resolve(result?.data);
    } else {
      this.errorHandler = result?.data?.message;
      return Promise.reject();
    }
  }

  public async addEmployee(param: any) {
    const result = await requestApi.post(DETAIL_API_URL.ADD_EMPLOYEE, param);
    if (result) {
      this.getEmployee();
      return Promise.resolve();
    } else {
      this.employeeData = { ...this.employeeData };
      return Promise.reject();
    }
  }

  public async editEmployee(param: any) {
    const result = await requestApi.put(DETAIL_API_URL.EDIT_EMPLOYEE, param);
    if (result) {
      this.getEmployee();
    } else {
      this.employeeData = { ...this.employeeData };
    }
  }

  public async deleteEmployee(data: string) {
    const result = await requestApi.put(
      DETAIL_API_URL.DEACTIVATE_EMPLOYEE,
      { email: data },
      { headers: { "content-type": "application/json" } },
    );
    if (result) {
      this.getEmployee();
    } else {
      this.employeeData = { ...this.employeeData };
    }
  }

  @computed
  public get getLocationData() {
    return this.employeeData;
  }
}

const employeeStore = new EmployeeStore();

export const employeeStoreContext = createContext(employeeStore);
export default employeeStore;
