import { Col, Divider, Row } from "antd";
import ButtonLink from "components/ButtonLink";
import { ViewMoreBlock } from "components/viewMoreBlock";
import { bizIcons } from "constants/bizConstants";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import "./index.less";

interface IAddLocationProps {
  onBack: () => void;
  data: any
}

const ViewLocationComponent = inject()(
  observer((props: IAddLocationProps) => {
    const { t } = useTranslation("base");
    
    const {
      onBack,
      data,
    } = props;

    const fieldSize = location.pathname === "/signup" ? 24 : 12;

    return (
      <div
        className={location.pathname === "/signup" ? "auth" : "single-course"}
      >
        <div className="content-wrapper content-with-table">
          <ButtonLink onClick={() => onBack()}>
            <i className={`${bizIcons.ARROWLEFT} fs-20 mr-8`} />
            {t("kidzbiz.back")}
          </ButtonLink>
          
          <div className="auth-form">
              <Row className={"tsl-4"}>
                <Col xs={24}>
                    <ViewMoreBlock title={t("kidzbiz.locationName")}>
                        {data?.name}
                    </ViewMoreBlock>
                </Col>
              </Row>
              <Divider style={{ marginBottom: "16px" }} />
              <Row gutter={24} className={"tsl-4"}>
                <Col xs={24} md={fieldSize}>
                  <ViewMoreBlock title={t("kidzbiz.locationAddress")}>
                    {data?.address}
                  </ViewMoreBlock>
                </Col>
                <Col xs={24} md={fieldSize}>
                  <ViewMoreBlock title={t("signUp.step2.area", {defaultValue : "Area"})}>
                    {data?.area}
                  </ViewMoreBlock>
                  <Divider style={{ marginBottom: "16px" }} />
                  <ViewMoreBlock title={t("signUp.step2.suburb", {defaultValue : "Suburb"})}>
                    {data?.suburb}
                  </ViewMoreBlock>
                </Col>
              </Row>
              <Divider style={{ marginBottom: "16px" }} />
              <Row gutter={24}>
                <Col xs={24} md={fieldSize}>
                  <ViewMoreBlock title={t("kidzbiz.email", {defaultValue : "Email"})}>
                    {data?.email}
                  </ViewMoreBlock>
                  
                </Col>
                <Col xs={24} md={fieldSize}>
                  <ViewMoreBlock title={t("kidzbiz.mobilePhone", {defaultValue : "Mobile phone"})}>
                    {data?.phoneNumber}
                  </ViewMoreBlock>
                  
                </Col>
              </Row>
          </div>
        </div>
      </div>
    );
  }),
);

const ViewLocationWrapper = (props: IAddLocationProps) => (
  <ViewLocationComponent {...props} />
);

export default ViewLocationWrapper;

