import { Popover } from "antd";
import { Rating } from "components/RatingStars";
import { CourseStatus } from "constants/type.interface";
import moment from "moment";
import { getKidsAge } from "pages/courses/tableGenerator";
import React from "react";
import { useTranslation } from "react-i18next";
import helpers from "scripts/helpers";
import { formatPrice } from "scripts/utils/formatPrice";
import { bizIcons, bizIcons as tb } from "../../../constants/bizConstants";

export const tableCustomerDetailsColumns = (currency: string) => {
  const { t } = useTranslation("base");
  const columns = [
    {
      dataIndex: "",
      key: "",
    },
    {
      key: "mobile",
      dataIndex: "mobile",
      render: (text: any) => (
        <div>
          <div className="td-title flex align-center">
            <i
              className={tb.SMARTPHONE}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            {t("customers.mobilePhone")}
          </div>
          {text || "-"}
        </div>
      ),
    },
    {
      key: "landline",
      dataIndex: "landline",
      render: (text: any) =>
        text && (
          <div>
            <div className="td-title flex align-center">
              <i
                className={tb.SMARTPHONE}
                style={{ fontSize: "16px", marginRight: "8px" }}
              />
              {t("customers.landlinePhone")}
            </div>
            {text || "-"}
          </div>
        ),
    },
    {
      key: "email",
      dataIndex: "email",
      render: (text: any) => (
        <div>
          <div className="td-title flex align-center">
            <i
              className={tb.MAIL}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            {t("bizBase.email")}
          </div>
          {text || "-"}
        </div>
      ),
    },
    {
      key: "location",
      dataIndex: "location",
      render: (text: any, record: any) => (
        <div>
          <div className="td-title flex align-center">
            <i
              className={tb.BUILDING}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            {t("bizBase.location")}
          </div>
          {text || record?.suburb || "-"}
        </div>
      ),
    },
    {
      key: "preferredLanguage",
      dataIndex: "preferredLanguage",
      render: (text: any) => (
        <div>
          <div className="td-title flex align-center">
            <i
              className={tb.GLOBE}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            {t("customers.language_preference")}
          </div>
          {text ? t("languages." + text) : "-"}
        </div>
      ),
    },
    {
      key: "lifetimeSpend",
      dataIndex: "lifetimeSpend",
      render: (text: any) => (
        <div>
          <div className="td-title flex align-center">
            <i
              className={tb.PRICE}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            {t("filters.transactionVolume")}
          </div>
          {formatPrice(text, { currency })}
        </div>
      ),
    },
  ];

  return columns;
};

export const getAgeChild = (birthday: string) => {
  const age = Math.abs(moment().diff(birthday, "years"));
  const currentMonth = Number(moment().format("MM"));
  const birthMonth = Number(moment(birthday).format("MM"));
  let month = 0;
  if (age === 0) {
    month = currentMonth - birthMonth;
  }
  return {
    year: age,
    month,
  };
};

export const tableKidsColumns = (t: any) => {
  const columns = [
    {
      dataIndex: "name",
      key: "name",
      render: (age: any, record: any) => {
        return record?.firstName + " " + record?.lastName;
      },
    },
    {
      dataIndex: "age",
      key: "age",
      render: (text: any, record: any) => {
        const age = getAgeChild(
          helpers?.getServerDate(record?.dateOfBirth) || "",
        );
        return (
          <div>
            <i
              className={tb.USER4}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            {"Age " +
              (age.year > 0
                ? age.year + " " + t("courses.years")
                : age.month + " " + t("courses.months")) || "-"}
          </div>
        );
      },
    },
    {
      key: "birthday",
      dataIndex: "birthday",
      render: (text: any, record?: any) => (
        <div>
          <i
            className={tb.DATECHECK}
            style={{ fontSize: "16px", marginRight: "8px" }}
          />
          {helpers.formatDate(helpers?.getServerDate(record?.dateOfBirth))}
        </div>
      ),
    },
    {
      key: "school",
      dataIndex: "school",
      render: (text: any) => (
        <div>
          <i
            className={tb.COMMUNITY}
            style={{ fontSize: "16px", marginRight: "8px" }}
          />
          {text || "-"}
        </div>
      ),
    },
    {
      key: "medicalIssues",
      dataIndex: "medicalIssues",
      render: (text: any) => (
        <div>
          <i
            className={tb.BOOKHEALTH}
            style={{ fontSize: "16px", marginRight: "8px" }}
          />
          {text || "-"}
        </div>
      ),
    },
  ];

  return columns;
};

export const tableCustomerCoursesColumns = (dat: {
  t?: any;
  isBroken?: boolean;
  isLinked?: boolean;
  ActionButton?: (data: any) => void;
}) => {
  const { isBroken, t, isLinked, ActionButton } = dat;
  const columns = [
    {
      title: (
        <Popover
          overlayClassName={`popover-ellipsis`}
          content={t("courses.courseName", {
            defaultValue: "Course name",
          })}
          trigger="hover"
        >
          <div className="ellipsis">
            {t("courses.courseName", {
              defaultValue: "Course name",
            })}
          </div>
        </Popover>
      ),
      dataIndex: "courseName",
      key: "courseName",
      width: !isBroken ? "160px" : undefined,
      render: (text: React.ReactNode, record: any) => {
        return (
          <div>
            <div>
              <Popover
                overlayClassName={`popover-ellipsis ${
                  isBroken || !text ? "display-none" : ""
                }`}
                content={text}
                trigger="hover"
              >
                <div className="ellipsis text-color">{text}</div>
              </Popover>
              {!!record?.courseCode && (
                <Popover
                  overlayClassName={`popover-ellipsis ${
                    isBroken || !record?.code ? "display-none" : ""
                  }`}
                  content={text}
                  trigger="hover"
                >
                  <div
                    className="ellipsis flex tsl-5 text-color-secondary"
                    style={{ fontSize: 12, lineHeight: "16px" }}
                  >
                    <i
                      className={tb.LISTCHECK2}
                      style={{ fontSize: "15px", marginRight: "4px" }}
                    />
                    {record?.courseCode}
                  </div>
                </Popover>
              )}
            </div>

            {isBroken && ActionButton?.(record)}
          </div>
        );
      },
    },
    {
      title: (
        <Popover
          overlayClassName={`popover-ellipsis`}
          content={t("courses.category", {
            defaultValue: "Category",
          })}
          trigger="hover"
        >
          <div
            className={`${!isBroken ? "flex-align-items none-ellipsis" : ""}`}
          >
            <i key={Math.random()} className={tb.UL} />
            {t("courses.category", {
              defaultValue: "Category",
            })}
          </div>
        </Popover>
      ),
      key: "category",
      dataIndex: "category",
      ellipsis: !isBroken,
      // width: !isBroken ? "120px" : undefined,
      render: (text: any) => (
        <Popover
          overlayClassName={`popover-ellipsis ${
            isBroken || !text ? "display-none" : ""
          }`}
          content={text}
          trigger="hover"
        >
          <div
            className={`${!isBroken ? "flex-align-items none-ellipsis" : ""}`}
          >
            {isBroken && (
              <i
                className={tb.UL}
                style={{ fontSize: "16px", marginRight: "8px" }}
              />
            )}
            {text || "-"}
          </div>
        </Popover>
      ),
    },
    {
      title: (
        <Popover
          overlayClassName={`popover-ellipsis`}
          content={t("courses.subCategory", {
            defaultValue: "Sub category",
          })}
          trigger="hover"
        >
          <div
            className={`${!isBroken ? "flex-align-items none-ellipsis" : ""}`}
          >
            <i key={Math.random()} className={tb.LISTCHECK2} />
            {t("courses.subCategory", {
              defaultValue: "Sub category",
            })}
          </div>
        </Popover>
      ),
      key: "subCategory",
      dataIndex: "subCategory",
      ellipsis: !isBroken,
      // width: !isBroken ? "120px" : undefined,
      render: (text: any) => (
        <Popover
          overlayClassName={`popover-ellipsis ${
            isBroken || !text ? "display-none" : ""
          }`}
          content={text}
          trigger="hover"
        >
          <div className="ellipsis">
            {isBroken && (
              <i
                className={tb.LISTCHECK2}
                style={{ fontSize: "16px", marginRight: "8px" }}
              />
            )}
            {text || "-"}
          </div>
        </Popover>
      ),
    },
    {
      title: (
        <Popover
          overlayClassName={`popover-ellipsis`}
          content={t("courses.age", {
            defaultValue: "Age",
          })}
          trigger="hover"
        >
          <div
            className={`${!isBroken ? "flex-align-items none-ellipsis" : ""}`}
          >
            <i key={Math.random()} className={tb.AGE} />
            {t("courses.age", {
              defaultValue: "Age",
            })}
          </div>
        </Popover>
      ),
      key: "ageRange",
      dataIndex: "ageRange",
      ellipsis: !isBroken,
      // width: !isBroken ? "70px" : undefined,
      render: (text: any) => (
        <Popover
          overlayClassName={`popover-ellipsis ${
            isBroken || !text ? "display-none" : ""
          }`}
          content={text}
          trigger="hover"
        >
          <div className="ellipsis">
            {isBroken && (
              <i
                className={tb.AGE}
                style={{ fontSize: "16px", marginRight: "8px" }}
              />
            )}
            {text || "-"}
          </div>
        </Popover>
      ),
    },
    {
      title: (
        <Popover
          overlayClassName={`popover-ellipsis`}
          content={t("courses.date", {
            defaultValue: "Date",
          })}
          trigger="hover"
        >
          <div
            className={`${!isBroken ? "flex-align-items none-ellipsis" : ""}`}
          >
            <i key={Math.random()} className={tb.CALENDARTODO} />
            {t("courses.date", {
              defaultValue: "Date",
            })}
          </div>
        </Popover>
      ),
      key: "date",
      dataIndex: "date",
      ellipsis: !isBroken,
      // width: !isBroken ? "120px" : undefined,
      render: (text: any, record: any) => (
        <Popover
          overlayClassName={`popover-ellipsis ${
            isBroken || (!record.startDate && !record.endDate)
              ? "display-none"
              : ""
          }`}
          content={`${
            record.startDate ? helpers.formatDate(record.startDate) : "..."
          } - ${record.endDate ? helpers.formatDate(record.endDate) : "..."}`}
          trigger="hover"
        >
          <div className="ellipsis">
            {isBroken && (
              <i
                className={tb.CALENDARTODO}
                style={{ fontSize: "16px", marginRight: "8px" }}
              />
            )}
            {record.startDate || record.endDate
              ? `${
                  record.startDate
                    ? helpers.formatDate(record.startDate)
                    : "..."
                } - ${
                  record.endDate ? helpers.formatDate(record.endDate) : "..."
                }`
              : "-"}
          </div>
        </Popover>
      ),
    },

    {
      title: (
        <Popover
          overlayClassName={`popover-ellipsis`}
          content={t("courses.enrolled_childrens")}
          trigger="hover"
        >
          <div
            className={`${!isBroken ? "flex-align-items none-ellipsis" : ""}`}
          >
            <i
              key={Math.random()}
              className={tb.CHECKBOXCIRCLE}
              style={{ color: "#ffcc33" }}
            />
            {t("courses.enrolled_childrens")}
          </div>
        </Popover>
      ),
      key: "enrolled_childrens",
      dataIndex: "enrolled_childrens",
      ellipsis: !isBroken,
      // width: !isBroken ? "110px" : undefined,
      render: (text: any) => (
        <Popover
          overlayClassName={`popover-ellipsis ${
            isBroken || !text ? "display-none" : ""
          }`}
          content={text}
          trigger="hover"
        >
          <div className={"ellipsis"}>
            {isBroken && (
              <i
                className={tb.USER5}
                style={{
                  color: "#ffcc33",
                  fontSize: "16px",
                  marginRight: "8px",
                }}
              />
            )}
            {text}
          </div>
        </Popover>
      ),
    },

    {
      title: (
        <Popover
          overlayClassName={`popover-ellipsis`}
          content={t("bizBase.status", {
            defaultValue: "Status",
          })}
          trigger="hover"
        >
          <div
            className={`${!isBroken ? "flex-align-items none-ellipsis" : ""}`}
          >
            <i key={Math.random()} className={tb.INFO} />
            {t("bizBase.status", {
              defaultValue: "Status",
            })}
          </div>
        </Popover>
      ),
      key: "status",
      dataIndex: "status",
      ellipsis: !isBroken,
      // width: !isBroken ? "90px" : undefined,
      render: (text: any) => (
        <Popover
          overlayClassName={`popover-ellipsis ${
            isBroken || !text ? "display-none" : ""
          }`}
          content={t("courses.status." + text)}
          trigger="hover"
        >
          <div className={"ellipsis"}>
            {isBroken && text && (
              <i
                className={tb.INFO}
                style={{ fontSize: "16px", marginRight: "8px" }}
              />
            )}
            {t("courses.status." + text)}
          </div>
        </Popover>
      ),
    },
  ];
  if (isLinked) {
    const elmt: any = {
      title: (
        <Popover
          overlayClassName={`popover-ellipsis`}
          content={t("menu.kidzbiz", {
            defaultValue: "Kidzbiz",
          })}
          trigger="hover"
        >
          <div className="ellipsis">
            <i key={Math.random()} className={tb.BUILDING} />
            {t("menu.kidzbiz", {
              defaultValue: "Kidzbiz",
            })}
          </div>
        </Popover>
      ),
      key: "organizationLegalName",
      dataIndex: "organizationLegalName",
      render: (text: any, record: any) =>
        text && (
          <Popover
            overlayClassName={`popover-ellipsis`}
            content={text}
            trigger="hover"
          >
            <div className="ellipsis">
              {isBroken && (
                <i
                  className={tb.BUILDING}
                  style={{ fontSize: "16px", marginRight: "8px" }}
                />
              )}
              {text || "-"}
            </div>
          </Popover>
        ),
    };
    columns.splice(1, 0, elmt);
  }

  if (!isBroken) {
    columns.push({
      // @ts-ignore
      width: !isBroken ? "100px" : undefined,

      title: (
        <Popover
          overlayClassName={`popover-ellipsis`}
          content={t("bizBase.action", {
            defaultValue: "Action",
          })}
          trigger="hover"
        >
          <div className="ellipsis">
            {t("bizBase.action", {
              defaultValue: "Action",
            })}
          </div>
        </Popover>
      ),
      key: "action",
      dataIndex: "action",
      render: (text: React.ReactNode, record: any) => {
        return <>{ActionButton?.(record)}</>;
      },
    });
  }

  return columns;
};

export const tableCoursesData = (data: any = {}, t: any) => {
  return {
    courseName: data?.name,
    provider: data?.provider,
    organizationLegalName: data?.legalName || "",
    startDate: data?.dateStart
      ? helpers.formatDate(helpers.getServerDate(data?.dateStart))
      : null,
    endDate: data?.dateEnd
      ? helpers.formatDate(helpers.getServerDate(data?.dateEnd))
      : null,
    ageRange: `${getKidsAge(data.minAgeMonth)?.age} ${t(
      "courses." + getKidsAge(data.minAgeMonth)?.type?.toLowerCase(),
    )} - ${getKidsAge(data.maxAgeMonth)?.age} ${t(
      "courses." + getKidsAge(data.maxAgeMonth)?.type?.toLowerCase(),
    )}`,
    category: data?.category ? t("categories." + data?.category) : "-",
    subCategory: data?.subCategory
      ? t("subCategories." + data?.subCategory)
      : "-",
    courseCode: data?.code || "",
    status:
      data?.bookingStatus === CourseStatus.Pending
        ? CourseStatus.Pending
        : data?.courseStatus,
    pendingBookings: data?.pendingBookings || 0,
    acceptedBookings: data?.acceptedBookings || 0,
    enrolled_childrens:
      data?.customerChildren?.map((el: any) => el?.name).join(", ") || "",
    id: data?.id,
    key: data?.id,
  };
};

export const tableReviewsColumns = (
  t: any,
  broken: boolean,
  isLinked?: boolean,
  ActionButton?: (data: any) => void,
) => {
  const columns: any = [
    {
      title: (
        <div>
          <i key={Math.random()} className={tb.BOOKOPEN} />
          {t("reviews.courseName")}
        </div>
      ),
      key: "courseName",
      dataIndex: "courseName",
      render: (text: any, record: any) => (
        <div className="ellipsis">
          {broken && text && (
            <i
              className={tb.BOOKOPEN}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
          )}
          {record?.course?.name}
        </div>
      ),
    },
    {
      title: (
        <div>
          <i key={Math.random()} className={tb.UL} />
          {t("reviews.courseCategory")}
        </div>
      ),
      key: "courseCategory",
      dataIndex: "courseCategory",
      render: (text: any, record: any) => (
        <div className="ellipsis">
          {broken && record?.course?.category && (
            <i
              className={tb.UL}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
          )}
          {record?.course?.category
            ? t("categories." + record?.course?.category)
            : "-"}
        </div>
      ),
    },
    {
      title: (
        <div>
          <i key={Math.random()} className={tb.STAR} />
          {t("reviews.rating")}
        </div>
      ),
      key: "rating",
      dataIndex: "rating",
      render: (text: any) => {
        return (
          <div>
            {text && (
              <div style={{ marginRight: "8px", display: "inline-block" }}>
                <Rating value={text} showCount={true} disabled={true} />
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: (
        <div>
          <i key={Math.random()} className={tb.DATECHECK} />
          {t("bizBase.date")}
        </div>
      ),
      dataIndex: "timestamp",
      key: "timestamp",
      render: (text: any, record: any) => (
        <div>
          {broken && (
            <i
              className={tb.DATECHECK}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
          )}
          {record?.createdDate
            ? helpers.formatDateTime(record?.createdDate)
            : "-"}
        </div>
      ),
    },
  ];

  if (isLinked) {
    const elm = {
      title: (
        <div>
          <i key={Math.random()} className={tb.BUILDING} />
          {t("menu.kidzbiz")}
        </div>
      ),
      key: "legalName",
      dataIndex: "legalName",
      render: (text: any, record: any) => {
        const legalName = record?.course?.legalName;
        return (
          <Popover
            overlayClassName={`popover-ellipsis ${
              broken || !legalName ? "display-none" : ""
            }`}
            content={legalName}
            trigger="hover"
          >
            <div className="ellipsis">
              {broken && legalName && (
                <i
                  className={tb.BUILDING}
                  style={{ fontSize: "16px", marginRight: "8px" }}
                />
              )}
              {legalName}
            </div>
          </Popover>
        );
      },
    };
    columns.splice(0, 0, elm);
  }

  if (!broken) {
    columns.push({
      title: <div>{t("bizBase.action")}</div>,
      key: "action",
      dataIndex: "action",
      render: (text: any, record: any) => ActionButton?.(record),
    });
  }

  return columns;
};

export const tableProviderTransactionsColumns = (t: any) => {
  const columns = [
    {
      dataIndex: "Name",
      key: "Name",
      className: "pb-4",
    },
    {
      key: "Date",
      dataIndex: "Date",
      render: (text: any) => (
        <div>
          <i
            className={bizIcons.DATECHECK}
            style={{ fontSize: "16px", marginRight: "8px" }}
          />
          {text || "-"}
        </div>
      ),
    },
    {
      key: "Status",
      dataIndex: "Status",
      render: (text: any) =>
        text && (
          <div>
            <i
              className={bizIcons.CARD}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            {t("courses.status." + text)}
          </div>
        ),
    },
    {
      key: "Course",
      dataIndex: "Course",
      render: (text: any) => (
        <div>
          <i
            className={bizIcons.BOOKOPEN}
            style={{ fontSize: "16px", marginRight: "8px" }}
          />
          {text || "-"}
        </div>
      ),
    },
  ];

  return columns;
};
export const tableProviderTransactionsData = (data: any[]) => {
  return data?.map((el) => {
    return {
      Name: el.amountReceived ? formatPrice(el.amountReceived) : undefined,
      Date: el.createDate ? helpers.formatDateTime(el.createDate) : "",
      Status: el.type,
      Course: el.courseCode || "-",
      id: el.id,
      key: el.id,
    };
  });
};

export const tablePayCustomersColumns = (
  t: any,
  isBroken: boolean,
  isLinked: boolean,
  ActionButton: (data: any) => void,
) => {
  const columns = [
    {
      title: (
        <div>
          <i key={Math.random()} className={tb.DATECHECK} />
          {t("payments.date")}
        </div>
      ),
      dataIndex: "buyDate",
      key: "buyDate",
      width: isBroken ? undefined : "250px",
      render: (text: any, record: any) => (
        <div>
          {text}
          {isBroken && ActionButton?.(record)}
        </div>
      ),
    },
    {
      title: (
        <div>
          <i key={Math.random()} className={tb.CARD} />
          {t("payments.type")}
        </div>
      ),
      dataIndex: "Status",
      key: "Status",
      width: isBroken ? undefined : "250px",
      render: (text: any) => (
        <div>
          {isBroken && (
            <i
              className={tb.CARD}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
          )}
          {text ? t("signUp.step4." + text) : "-"}
        </div>
      ),
    },
    {
      title: (
        <div>
          <i key={Math.random()} className={tb.PRICE} />
          {t("payments.amount")}
        </div>
      ),
      dataIndex: "price",
      key: "price",
      width: isBroken ? undefined : "250px",
      render: (text: any) => (
        <div>
          {isBroken && (
            <i
              className={tb.PRICE}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
          )}

          {text}
        </div>
      ),
    },
  ];

  if (isLinked) {
    const finIndexStatus = columns?.findIndex((el) => el.key === "price");
    const elm = {
      title: (
        <div>
          <i key={Math.random()} className={tb.BUILDING} />
          {t("menu.kidzbiz")}
        </div>
      ),
      key: "legalName",
      dataIndex: "legalName",
      width: isBroken ? undefined : "250px",
      render: (text: any) =>
        text && (
          <div className="ellipsis">
            {isBroken && (
              <i
                className={tb.BUILDING}
                style={{ fontSize: "16px", marginRight: "8px" }}
              />
            )}
            {text}
          </div>
        ),
    };
    columns.splice(finIndexStatus + 1, 0, elm);
  }

  if (!isBroken) {
    columns.push({
      title: <div>{t("bizBase.action")}</div>,
      key: "action",
      dataIndex: "action",
      width: undefined,
      render: (text: string, record: any) => <>{ActionButton(record)}</>,
    });
  }

  return columns;
};
export const tablePayCustomersData = (data?: any[]) => {
  return data?.map((el: any) => {
    return {
      Name: el?.customerName || "",
      price: formatPrice(el?.amount),
      buyDate: el.date ? helpers.formatDateTime(el.date) : undefined,
      Status: el?.type,
      legalName: el?.providerName || "",
      id: el?.id,
      key: el?.id,
    };
  });
};
