import { Link } from "components/Link";
import { RescheduleType } from "constants/type.interface";
import { AppRoutes } from "helpers/app.routes";
import { observer } from "mobx-react-lite";
import { getKidsAge } from "pages/courses/tableGenerator";
import { PromotionType } from "pages/promotions/data";
import React, { useMemo } from "react";
import helpers from "scripts/helpers";
import { formatPrice } from "scripts/utils/formatPrice";
import { bizIcons as tb } from "../../../constants/bizConstants";

interface IProps {
  t: any;
  data: any;
  type: RescheduleType;
}

export const PayViewCustomerCourseComponent = observer((props: IProps) => {
  const { t, data, type } = props;

  const promotions: string = useMemo(() => {
    const bulkPromotions = data?.bulkPromotion
      ? t("payments.bulk_percent_off", {
          percent: data?.bulkPromotion?.discountPercentage || 0,
        })
      : "";

    const coursePromotions = data?.coursePromotion
      ? data?.coursePromotion?.type === PromotionType.FreeClass
        ? t("payments.course_free_class")
        : t("payments.course_percent_off", {
            percent: data?.coursePromotion?.discountPercentageOrValue || 0,
          })
      : "";

    return [bulkPromotions, coursePromotions].filter((x) => x).join(", ");
  }, [data]);

  const bizibuzDiscount: number = data?.bizibuzPromotionDiscountAmount || 0;

  const displayedDate = useMemo(() => {
    if (type === RescheduleType.CANCELLATION) {
      return data?.classCancelDate?.length > 1 &&
        data?.classCancelDate?.[0] !== data?.classCancelDate?.[1]
        ? helpers.formatDate(data?.classCancelDate?.[0]) +
            " - " +
            helpers.formatDate(data?.classCancelDate?.[1])
        : helpers.formatDate(data?.classCancelDate?.[0]);
    }
    if (type === RescheduleType.PAYMENT)
      return data?.courseDate?.length > 1 &&
        data?.courseDate?.[0] !== data?.courseDate?.[1]
        ? helpers.formatDate(data?.courseDate?.[0]) +
            " - " +
            helpers.formatDate(data?.courseDate?.[1])
        : helpers.formatDate(data?.courseDate?.[0]);

    return data?.courseDate?.length
      ? helpers.formatDate(data?.courseDate?.[0]) +
          " - " +
          helpers.formatDate(data?.courseDate?.[1])
      : "-";
  }, [type, data]);

  return (
    <div className="bsl-4">
      <div className="flex biz-section-pay-view-customer-course">
        <div className="biz-course-element">
          <div className="td-title flex align-center">
            <i
              className={tb.BOOKOPEN}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            {t("courses.courseName")}
          </div>
          {data?.courseName || "-"}
        </div>
        {!!data?.courseCode && (
          <div className="biz-course-element">
            <div className="td-title flex align-center">
              <i
                className={tb.CODE}
                style={{ fontSize: "16px", marginRight: "8px" }}
              />
              {t("courses.courseCode")}
            </div>
            {data?.courseCode}
          </div>
        )}

        <div className="biz-course-element">
          <div className="td-title flex align-center">
            <i
              className={tb.UL}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            {t("courses.category")}
          </div>
          {data?.courseCategory ? t("categories." + data?.courseCategory) : "-"}
        </div>

        <div className="biz-course-element">
          <div className="td-title flex align-center">
            <i
              className={tb.AGE}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            {t("courses.ageRange")}
          </div>
          {`${getKidsAge(data?.minAgeMonth || 0)?.age} ${t(
            "courses." +
              getKidsAge(data?.minAgeMonth || 0)?.type?.toLowerCase(),
          )} - ${getKidsAge(data?.maxAgeMonth || 0)?.age} ${t(
            "courses." +
              getKidsAge(data?.maxAgeMonth || 0)?.type?.toLowerCase(),
          )}`}
        </div>
        {!!type && (
          <>
            <div className="biz-course-element">
              <div className="td-title flex align-center">
                <i
                  className={tb.CALENDARTODO}
                  style={{ fontSize: "16px", marginRight: "8px" }}
                />
                {t("payments.courses.date_" + type)}
              </div>
              {displayedDate}
            </div>
            <div className="biz-course-element">
              <div className="td-title flex align-center">
                <i
                  className={tb.BOOKOPEN}
                  style={{ fontSize: "16px", marginRight: "8px" }}
                />
                {t("payments.courses.numberOfClasses_" + type)}
              </div>
              {data?.transactionType === RescheduleType.CANCELLATION
                ? data?.numberOfClassCancelled
                : data?.numberOfClassPurchased || "-"}
            </div>
          </>
        )}
        <div className="biz-course-element">
          <div className="td-title flex align-center">
            <i
              className={tb.PRICE2}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            {t("payments.courses.pricePerClass")}
          </div>
          {formatPrice(data?.pricePerClass || 0)}
        </div>
        <div className="biz-course-element">
          <div className="td-title flex align-center">
            <i
              className={tb.GIFT2}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            {t("courses.promotions")}
          </div>
          {promotions}
        </div>

        <div className="biz-course-element">
          <div className="td-title flex align-center">
            <i
              className={tb.GIFT2}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            {t("payments.courses.bizibuzDiscount")}
          </div>
          {formatPrice(bizibuzDiscount)}
        </div>

        {data?.children?.map((el: any, index: number) => (
          <div key={el?.toString() + index} className="biz-course-element">
            <div className="td-title flex align-center">
              <i
                className={tb.USER4}
                style={{ fontSize: "16px", marginRight: "8px" }}
              />
              {t("customers.childName") + " " + (index + 1)}
            </div>
            {el?.toString()}
          </div>
        ))}
      </div>
      <div
        className={"full-width"}
        style={{
          marginBottom: "32px",
          maxWidth: "800px",
          width: "calc(100vw - 100px)",
        }}
      >
        <Link to={AppRoutes.courseDetail(data?.courseId)}>
          {t("payments.go_to_course")}
        </Link>
      </div>
    </div>
  );
});
