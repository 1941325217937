import { createContext } from "react";
import { action, computed, makeObservable, observable, toJS } from "mobx";
import requestApi from "helpers/requestApi";
import { DETAIL_API_URL } from "constants/apiUrl";
import { PAGE_SIZE } from "config";
export enum BookingStatus {
  Pending = "PENDING",
  Rejected = "DENIED",
  Accepted = "ACCEPTED",
}

interface IPageData {
  totalRecords?: number;
  filteredRecords?: number;
  page?: number;
  pageSize?: number;
}

export const pageable = {
  pageNumber: 0,
  pageSize: PAGE_SIZE,
  sortField: "createdDate",
  sortDirection: "desc",
};

export const defaultFilter = {
  pageable,
  status: BookingStatus.Pending,
};
export class BookingsStore {
  constructor() {
    makeObservable(this);
  }

  @observable
  private bookingDatas: any = [];

  @observable
  public errorHandler: string = "";

  @observable
  private filterModel: any = defaultFilter;

  @observable
  public dataFilters: any = null;

  @action
  public async setFilters(filter?: any) {
    this.dataFilters = filter;
  }

  @action
  public async getMyBookings(filter?: any) {
    this.filterModel = filter;
    const params = defaultFilter;

    const result = await requestApi.post(DETAIL_API_URL.BOOKING_LIST, {
      ...params,
      ...filter,
      status: filter?.status || params?.status,
    });
    if (result?.status === 200) {
      this.bookingDatas = result?.data || [];
      return Promise.resolve(result?.data);
    } else {
      return Promise.reject();
    }
  }

  @action
  public async rejectBooking(bookingId: number): Promise<any> {
    const result = await requestApi.put(
      DETAIL_API_URL.BOOKING_STATUS(bookingId),
      {
        bookingStatus: BookingStatus.Rejected,
      },
    );
    if (result?.status === 200) {
      return Promise.resolve();
    } else {
      return Promise.reject(result);
    }
  }

  @action
  public async acceptBooking(bookingId: number): Promise<any> {
    const result = await requestApi.put(
      DETAIL_API_URL.BOOKING_STATUS(bookingId),
      {
        bookingStatus: BookingStatus.Accepted,
      },
    );
    if (result?.status === 200) {
      return Promise.resolve();
    } else {
      return Promise.reject(result);
    }
  }

  @action
  public async exportBooking(filter?: any): Promise<any> {
    const result = await requestApi.post(DETAIL_API_URL.BOOKING_EXPORT, filter);
    if (result?.status === 200) {
      return Promise.resolve(result?.data || []);
    } else {
      return Promise.reject();
    }
  }

  @action
  public async clearAll() {
    this.filterModel = defaultFilter;
    this.bookingDatas = [];
  }

  @computed
  public get getBookingDatas() {
    return this.bookingDatas;
  }

  @computed
  public get filterInfo() {
    return this.filterModel;
  }
}

const bookingsStore = new BookingsStore();

export const bookingsStoreContext = createContext(bookingsStore);
export default bookingsStore;
