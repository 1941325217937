import { Button, Form, Input, Modal, Select, Radio, Slider } from "antd";
import ButtonLink from "components/ButtonLink";
import { ErrorText } from "components/ErrorText";
import { COUNTRY_DATA } from "components/PhoneCode";
import StepsBizi from "components/StepsBizi";
import { bizIcons } from "constants/bizConstants";
import { COUNTRY_CODE_LOCAL_STORAGE } from "constants/commons";
import { RescheduleType } from "constants/type.interface";
import { Country } from "country-state-city";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import EditPolicyLink from "pages/editPolicyLink";
import { FieldData } from "rc-field-form/lib/interface";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useCurrentUser } from "scripts/custom-hooks/useCurrentUser";
import helpers from "scripts/helpers";
import { UserStore } from "scripts/mobx/userStore";
import "./index.less";

interface FormProps {
  method?: string;
  website: string;
  briefDescription: string;
  policies?: string;
  courseCancellationByKidzbiz: RescheduleType | undefined;
  courseCancellationByCustomer: RescheduleType | undefined;
  classCancellationByKidzbiz: RescheduleType | undefined;
  compensatedAbsencesByCustomer: number;
  compensatedForCustomerAbsences: RescheduleType | undefined;
  preferCurrency: string;
  defaultTimezone: string;
}
interface IProps {
  nextStep: () => void;
  history: any;
  userStore?: UserStore;
  policies?: any[];
}

const fieldsRequried = {
  briefDescription: true,
  courseCancellationByKidzbiz: true,
  courseCancellationByCustomer: true,
  classCancellationByKidzbiz: true,
  compensatedAbsencesByCustomer: true,
};

const Step4Form = inject("userStore")(
  observer((props: IProps) => {
    const { nextStep, history, userStore } = props;
    const { t } = useTranslation("base");
    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [form] = Form.useForm();
    const user = useCurrentUser();

    const [showPolicyLink, setShowPolicyLink] = useState(false);
    const [editPolicyLinkIndex, setEditPolicyLinkIndex] = useState<number>();
    const [policies, setPolicies] = useState<any[]>([]);
    const [errorText, setErrorText] = useState<string>();

    const onFinish = async (values: FormProps) => {
      setIsLoading(true);
      try {
        await form.validateFields(Object.keys(values));
        const rootPolicies = values?.policies
          ? [
              {
                ordinal: 1,
                contentType: "TEXT",
                name: "All purchase conditions/policies",
                content: values?.policies || "",
              },
            ]
          : [];

        const newPolicies: any[] =
          policies?.map((item, index) => {
            return {
              ordinal: item?.ordinal || index + 2,
              contentType: "URL",
              name: item?.linkname || "",
              content: item?.link,
            };
          }) || [];
        const params = {
          description: values.briefDescription || "",
          policies: rootPolicies.concat(newPolicies),
          reschedulingOutcome: {
            courseCancelledByKidzbiz: values?.courseCancellationByKidzbiz,
            courseCancelledByCustomer: values?.courseCancellationByCustomer,
            classCancelledByKidzbiz: values?.classCancellationByKidzbiz,
            compensatedAbsencesByCustomerPercentage:
              values?.compensatedAbsencesByCustomer,
            compensationForCustomerAbsence:
              values?.compensatedAbsencesByCustomer > 0
                ? values?.compensatedForCustomerAbsences
                : RescheduleType.CREDIT,
          },
          preferCurrency: values.preferCurrency,
          defaultTimezone: values.defaultTimezone,
        };
        if (values.website?.trim()) {
          Object.assign(params, {
            websiteUrl: values.method + values.website?.trim() || "",
          });
        }
        userStore
          ?.setAdditionalInfo(params)
          .then(() => {
            setErrorText("");
            setIsLoading(false);
            // nextStep();
          })
          .catch((error) => {
            setIsLoading(false);
            helpers?.throwErrors(
              t,
              [
                "Forbidden",
                "com.bizibuz.core.service.provider.InvalidWebsiteUrlException",
              ],
              error?.data?.error,
              error?.data?.message,
            );
          });
      } catch (e) {
        setIsLoading(false);
      }
    };

    const onAddPolicyLink = (index?: number) => () => {
      if (policies.length < 5) {
        setEditPolicyLinkIndex(index);
        setShowPolicyLink(true);
      } else {
        Modal.confirm({
          title: t("messages.more_than_5_links"),
          autoFocusButton: null,
          icon: "",
          okText: t("bizBase.ok"),
          okCancel: false,
        });
      }
    };

    const onErrorsCheck = (fields: FieldData[], allfields: FieldData[]) => {
      setErrorText("");
      const hasErrors = allfields.some(
        (field: FieldData) =>
          (field.errors && field.errors?.length > 0) ||
          (fieldsRequried[field.name[0]] && field.value === undefined),
      );

      setIsDisabled(hasErrors);
    };

    // TODO: replace the country state city with data from BE side
    // for extract country from phone number
    const country = useMemo(() => {
      const countryCode =
        Country.getAllCountries().find((item) =>
          user?.providerAttributes?.country?.includes(item.name),
        )?.isoCode || "HK";
      const selectedCountry = Country.getCountryByCode(countryCode);
      return selectedCountry;
    }, [user]);

    // currency and timezone by country iso code
    const [allTimezones, setTimezones] = useState<
      Array<{
        key: string;
        value: string;
      }>
    >([]);
    const [allCurrencies, setCurrencies] = useState<
      Array<{
        key: string;
        value: string;
      }>
    >([]);

    useEffect(() => {
      const loadTimezone = async () => {
        try {
          const data = (await userStore?.getTimezone()) as Array<{
            key: string;
            value: string;
          }>;
          setTimezones(data);
        } catch (error) {
          // do nothing
        }
      };

      const loadCurrency = async () => {
        try {
          const data = (await userStore?.getCurrency()) as Array<{
            key: string;
            value: string;
          }>;
          setCurrencies(data);
        } catch (error) {
          // do nothing
        }
      };

      loadTimezone();
      loadCurrency();
    }, [country]);

    const timezones = useMemo(() => {
      const mTimezones =
        allTimezones.find(
          (item) =>
            country?.name.includes(item.key) ||
            country?.isoCode.includes(item.key),
        )?.value || "";
      return mTimezones ? mTimezones.split(",") : [];
    }, [allTimezones, country]);

    const currencies = useMemo(() => {
      const mCurrencies =
        allCurrencies.find(
          (item) =>
            country?.name.includes(item.key) ||
            country?.isoCode.includes(item.key),
        )?.value || "";

      return mCurrencies ? mCurrencies.split(",") : ["HKD, USD"];
    }, [allCurrencies, country]);

    useEffect(() => {
      form.setFieldsValue({
        preferCurrency: currencies[0],
      });
    }, [currencies]);

    useEffect(() => {
      form.setFieldsValue({
        defaultTimezone: timezones[0],
      });
    }, [timezones]);

    return (
      <>
        {!showPolicyLink ? (
          <Form
            form={form}
            name="normal_login"
            className="login-form tsl-4 signup-step-4"
            initialValues={{
              method: "https://",
              website: "",
              briefDescription: "",
              policies: "",
              courseCancellationByKidzbiz: undefined,
              courseCancellationByCustomer: undefined,
              classCancellationByKidzbiz: undefined,
              compensatedAbsencesByCustomer: 50,
              compensatedForCustomerAbsences: undefined,
            }}
            onFieldsChange={onErrorsCheck}
            onFinish={onFinish}
          >
            <div className="auth">
              <div className="content-wrapper">
                <div className="auth-header bsl-4">
                  <div>
                    <span className={"auth-header_title"}>
                      {t("signUp.step4.title", {
                        defaultValue: "Kidzbiz info",
                      })}
                    </span>
                  </div>
                </div>
                <div className="auth-steps bsl-4">
                  <StepsBizi total={4} active={3} />
                </div>
                <div className="auth-form">
                  <div className="formtext-wrapper tsl-4 bsl-4">
                    {t("signUp.step4.desc", {
                      defaultValue:
                        "Add provider info to let customers know more details about your Kidzbiz.",
                    })}
                  </div>

                  <Form.Item className="bsl-4 website-row">
                    <div className="label-wrapper">
                      <label htmlFor="website">
                        {t("signUp.step4.website", {
                          defaultValue: "Website",
                        })}
                      </label>
                    </div>
                    <Form.Item
                      name="website"
                      rules={[
                        {
                          required: false,
                          message: t("messages.required_field"),
                          whitespace: true,
                        },
                        {
                          pattern:
                            /(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}/,
                          message: t("server_errors.invalid_website_url"),
                        },
                      ]}
                    >
                      <Input
                        prefix={
                          <>
                            <i className={`${bizIcons.GLOBE} mr-6 fs-20`} />
                            <Form.Item name="method" className="website-select">
                              <Select dropdownClassName="website-select-dropdown">
                                <Select.Option value="http://">
                                  http://
                                </Select.Option>
                                <Select.Option value="https://">
                                  https://
                                </Select.Option>
                              </Select>
                            </Form.Item>
                          </>
                        }
                        placeholder={t("signUp.step4.enterWebsite", {
                          defaultValue: "Enter website",
                        })}
                        maxLength={255}
                        readOnly={isLoading}
                        size={"large"}
                      />
                    </Form.Item>
                  </Form.Item>

                  <Form.Item className="bsl-4">
                    <div className="label-wrapper">
                      <label htmlFor="briefDescription">
                        {t("signUp.step4.briefDesc", {
                          defaultValue: "Kidzbiz description",
                        })}
                        *
                      </label>
                      <div className={"text-color-secondary small"}>
                        {t("signUp.step4.pleaseEnterLang", {
                          defaultValue: "Please enter in English and Chinese",
                        })}
                      </div>
                    </div>
                    <Form.Item
                      name="briefDescription"
                      rules={[
                        {
                          required: true,
                          message: t("messages.required_field", {
                            defaultValue: "Please fill in all required fields",
                          }),
                        },
                        {
                          max: 5000,
                          message: t(
                            "server_errors.com.bizibuz.core.service.provider.LimitCharacters",
                            {
                              defaultValue:
                                "Please reduce the number of characters",
                            },
                          ),
                        },
                      ]}
                    >
                      <Input.TextArea
                        id={"briefDescription"}
                        placeholder={t("signUp.step4.enterBriefDesc", {
                          defaultValue: "Enter brief description",
                        })}
                        autoSize={{ minRows: 3, maxRows: 5 }}
                      />
                    </Form.Item>
                    <div className={"text-color-secondary small"}>
                      {t("messages.the_max_number_of_symbols_2000", {
                        defaultValue: "The max number of symbols 5000",
                      })}
                    </div>
                  </Form.Item>

                  <Form.Item className="bsl-4">
                    <div className="label-wrapper">
                      <label htmlFor="preferCurrency">
                        {t("signUp.step4.prefer_currency", {
                          defaultValue: "Payout Currency",
                        })}
                        *
                      </label>
                    </div>
                    <Form.Item
                      name="preferCurrency"
                      rules={[
                        {
                          required: true,
                          message: t("messages.required_field", {
                            defaultValue: "Please fill in all required fields",
                          }),
                        },
                      ]}
                    >
                      <Select
                        placeholder={t("signUp.step4.enter_prefer_currency", {
                          defaultValue: "Enter payout currency",
                        })}
                        suffixIcon={
                          <i className="ri-arrow-drop-down-line fs-20" />
                        }
                      >
                        {currencies.map((currency) => {
                          return (
                            <Select.Option key={currency} value={currency}>
                              {currency}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Form.Item>

                  <Form.Item className="bsl-4">
                    <div className="label-wrapper">
                      <label htmlFor="timezone">
                        {t("signUp.step4.timezone", {
                          defaultValue: "Timezone",
                        })}
                      </label>
                    </div>
                    <Form.Item name="defaultTimezone">
                      <Select
                        placeholder={t("signUp.step4.enter_timezone", {
                          defaultValue: "Enter timezone",
                        })}
                        suffixIcon={
                          <i className="ri-arrow-drop-down-line fs-20" />
                        }
                      >
                        {timezones.map((timezone) => {
                          return (
                            <Select.Option key={timezone} value={timezone}>
                              {timezone}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Form.Item>

                  <h3>{t("signUp.step4.rescheduling_outcomes")}</h3>

                  <Form.Item className="bsl-4 reschedule-row">
                    <div className="label-wrapper">
                      <label htmlFor="courseCancellationByKidzbiz">
                        {t("signUp.step4.course_cancellation_by_Kidzbiz")}*
                      </label>
                    </div>
                    <Form.Item
                      name="courseCancellationByKidzbiz"
                      rules={[
                        {
                          required: true,
                          message: t("messages.required_field"),
                        },
                      ]}
                    >
                      <Radio.Group>
                        <Radio value={RescheduleType.REFUND}>
                          {t("signUp.step4." + RescheduleType.REFUND)}
                        </Radio>
                        <Radio value={RescheduleType.CREDIT}>
                          {t("signUp.step4." + RescheduleType.CREDIT)}
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Form.Item>

                  <Form.Item className="bsl-4 reschedule-row">
                    <div className="label-wrapper">
                      <label htmlFor="courseCancellationByCustomer">
                        {t("signUp.step4.course_cancellation_by_customer")}*
                      </label>
                    </div>
                    <Form.Item
                      name="courseCancellationByCustomer"
                      rules={[
                        {
                          required: true,
                          message: t("messages.required_field"),
                        },
                      ]}
                    >
                      <Radio.Group>
                        <Radio value={RescheduleType.REFUND}>
                          {t("signUp.step4." + RescheduleType.REFUND)}
                        </Radio>
                        <Radio value={RescheduleType.CREDIT}>
                          {t("signUp.step4." + RescheduleType.CREDIT)}
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Form.Item>

                  <Form.Item className="bsl-4 reschedule-row">
                    <div className="label-wrapper">
                      <label htmlFor="classCancellationByKidzbiz">
                        {t("signUp.step4.class_cancellation_by_Kidzbiz")}*
                      </label>
                    </div>
                    <Form.Item
                      name="classCancellationByKidzbiz"
                      rules={[
                        {
                          required: true,
                          message: t("messages.required_field"),
                        },
                      ]}
                    >
                      <Radio.Group>
                        <Radio value={RescheduleType.REFUND}>
                          {t("signUp.step4." + RescheduleType.REFUND)}
                        </Radio>
                        <Radio value={RescheduleType.CREDIT}>
                          {t("signUp.step4." + RescheduleType.CREDIT)}
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Form.Item>

                  <Form.Item className="bsl-4 reschedule-row">
                    <div className="label-wrapper">
                      <label htmlFor="compensatedAbsencesByCustomer">
                        {t("signUp.step4.compensated_absences_by_customer")}*
                      </label>
                      <div className={"text-color-secondary small"}>
                        {t(
                          "signUp.step4.compensated_absences_by_customer_note",
                        )}
                      </div>
                    </div>
                    <div style={{ paddingLeft: 10, paddingRight: 15 }}>
                      <Form.Item
                        name="compensatedAbsencesByCustomer"
                        rules={[
                          {
                            required: true,
                            message: t("messages.required_field"),
                          },
                        ]}
                      >
                        <Slider
                          marks={{
                            0: "0%",
                            25: "25%",
                            50: "50%",
                            75: "75%",
                            100: "100%",
                          }}
                          step={25}
                        />
                      </Form.Item>
                    </div>
                  </Form.Item>

                  <Form.Item noStyle shouldUpdate>
                    {() => {
                      return form.getFieldValue(
                        "compensatedAbsencesByCustomer",
                      ) ? (
                        <Form.Item className="bsl-4 reschedule-row">
                          <div className="label-wrapper">
                            <label htmlFor="compensatedForCustomerAbsences">
                              {t(
                                "signUp.step4.compensated_for_customer_absences",
                              )}
                              *
                            </label>
                          </div>
                          <div style={{ paddingLeft: 10, paddingRight: 15 }}>
                            <Form.Item
                              name="compensatedForCustomerAbsences"
                              rules={[
                                {
                                  required:
                                    form.getFieldValue(
                                      "compensatedAbsencesByCustomer",
                                    ) > 0
                                      ? true
                                      : false,
                                  message: t("messages.required_field"),
                                },
                              ]}
                            >
                              <Radio.Group>
                                <Radio value={RescheduleType.REFUND}>
                                  {t("signUp.step4." + RescheduleType.REFUND)}
                                </Radio>
                                <Radio value={RescheduleType.CREDIT}>
                                  {t("signUp.step4." + RescheduleType.CREDIT)}
                                </Radio>
                              </Radio.Group>
                            </Form.Item>
                          </div>
                        </Form.Item>
                      ) : null;
                    }}
                  </Form.Item>

                  <Form.Item className="tsl-4 bsl-4">
                    <div className="label-wrapper">
                      <label htmlFor="policies">
                        {t("signUp.step4.allPurchaseConditionsPolicies", {
                          defaultValue: "All purchase conditions/policies",
                        })}
                      </label>
                      <div className={"text-color-secondary small"}>
                        {t("signUp.step4.pleaseEnterLang")}
                      </div>
                      <div className={"text-color-secondary small bsl-5"}>
                        {t("signUp.step4.allPurchaseConditionsPolicies_note")}
                      </div>
                    </div>
                    <Form.Item name="policies">
                      <Input.TextArea
                        id={"policies"}
                        placeholder={t("signUp.step4.enterPolicies", {
                          defaultValue: "Enter policies",
                        })}
                        autoSize={{ minRows: 3, maxRows: 5 }}
                        maxLength={2000}
                      />
                    </Form.Item>
                    <div className={"text-color-secondary small"}>
                      {t("messages.the_max_number_of_symbols_2000", {
                        defaultValue: "The max number of symbols 2000",
                      })}
                    </div>
                  </Form.Item>
                  <div className="bsl-4">
                    <div className="label-wrapper flex-space-between">
                      {t("signUp.step4.purchaseConditionsPoliciesPlink", {
                        defaultValue: "Purchase conditions/policies links",
                      })}
                      <ButtonLink onClick={onAddPolicyLink()}>
                        {t("signUp.step4.addLink", {
                          defaultValue: "Add link",
                        })}
                        <i className={`${bizIcons.ARROWRIGHT} fs-20`} />
                      </ButtonLink>
                    </div>
                    <div className="tsl-4 linklist">
                      {policies?.map((link: any, index: number) => (
                        <div key={index} className="linkitem">
                          <div>{link.linkname}</div>
                          <ButtonLink onClick={onAddPolicyLink(index)}>
                            {t("bizBase.edit", {
                              defaultValue: "Edit",
                            })}
                            <i className={`${bizIcons.ARROWRIGHT} fs-20`} />
                          </ButtonLink>
                        </div>
                      ))}
                    </div>
                  </div>
                  <Form.Item shouldUpdate>
                    {() => {
                      return (
                        <Button
                          className="full-width fs-16 tsl-4"
                          type="primary"
                          htmlType="submit"
                          loading={isLoading}
                          disabled={isDisabled || isLoading}
                          icon={
                            <div className={"flex-center"}>
                              {t("signUp.nextStep", {
                                defaultValue: "Next step",
                              })}
                              <i className="ri-arrow-right-line ml-8 fs-20" />
                            </div>
                          }
                        />
                      );
                    }}
                  </Form.Item>
                  <ErrorText msg={errorText} />
                </div>
              </div>
            </div>
          </Form>
        ) : (
          <div className="auth">
            <div className="content-wrapper">
              <div className="auth-form">
                <EditPolicyLink
                  policies={policies}
                  onSetPolicies={(val) => {
                    setPolicies(val);
                  }}
                  onBack={() => setShowPolicyLink(false)}
                  editIndex={editPolicyLinkIndex}
                />
              </div>
            </div>
          </div>
        )}
      </>
    );
  }),
);

interface IPropsWrapper extends RouteComponentProps {
  nextStep: () => void;
}

const Step4FormWrapper = withRouter((props: IPropsWrapper) => {
  const { history, nextStep } = props;
  return <Step4Form nextStep={nextStep} history={history} />;
});
export default Step4FormWrapper;
